import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line
import { useSnackbar } from 'notistack';

import Worker from 'worker-loader!../../content.worker';
import useSaveTopicQuery from '../../api-hooks/topic/useSaveTopic';
import { DraftTopic, DraftTopicTree, TopicContentComponentBodyItem } from '../../api-schemas/topic';
import { convertToSlug, emptyGuid } from '../../helpers/common';
import { addCustomMetricsData, calculateNewTopicAndSlug } from '../../helpers/content';
import useDebounceFn from '../../hooks/useDebounceFn';
import useTimeout from '../../hooks/useTimeout';
import { AccountContext } from '../../contexts/account';
import { DocumentContext } from '../../contexts/document';
import useGetDraftTopicSilently from '../../api-hooks/topic/useGetDraftTopicSilently';

const worker = new Worker();
const topicsToSaveQueue = new Map<
  string,
  {
    savingContent: DraftTopic | null;
    nextContentToSave: DraftTopic | null;
  }
>();
const latestTopicVersionIdsMap = new Map<string, string>();

let shouldCreateNewVersionTimeout: number = 60;

const useSaveTopic = ({
  selectedTopic,
  draftTopicTree = [],
  enabled = true,
}: {
  selectedTopic?: DraftTopic;
  draftTopicTree?: DraftTopicTree;
  enabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { topicId: urlTopicId }: { topicId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const NEW_TOPIC_TITLE = t('topic-new');
  const NEW_TOPIC_PAGE_TITLE = 'New Page';

  const isFirstRender = useRef(true);
  const isFirstSave = useRef(true);

  const [numberOfFailedRequests, setNumberOfFailedRequests] = useState(0);

  const { getTopic } = useGetDraftTopicSilently({
    topicId: selectedTopic?.topicId,
    languageCode: selectedTopic?.languageCode,
  });

  const { selectedLanguage: selectedAccountLanguage } = useContext(AccountContext);
  const { selectedLanguage: selectedDocumentLanguage } = useContext(DocumentContext);

  const {
    saveTopic: saveTopicAPI,
    isSavingTopic,
    isSuccessSavingTopic,
  } = useSaveTopicQuery({
    documentVersionId: selectedTopic?.documentVersionId,
    languageCode: selectedTopic?.languageCode,
    onSuccessCallback: () => {
      getTopic();
    },
    onErrorMessageCallback: (message) => {
      if (message.includes('NotLatestTopicVersion'))
        enqueueSnackbar(t('error.NotLatestTopicVersion'), {
          variant: 'error',
        });
    },
  });

  const { createTimeout, resetTimeout } = useTimeout(() => {
    shouldCreateNewVersionTimeout = 0;
  }, 60000);

  useEffect(() => {
    isFirstRender.current = true;
    isFirstSave.current = true;
  }, [urlTopicId]);

  useEffect(() => {
    shouldCreateNewVersionTimeout = 60;

    if (!isFirstRender.current) {
      createTimeout();
    }
  }, [selectedTopic]);

  useEffect(() => {
    resetConfigs();
  }, [selectedAccountLanguage, selectedDocumentLanguage]);

  useEffect(() => {
    return resetConfigs;
  }, []);

  const resetConfigs = () => {
    topicsToSaveQueue.clear();
    latestTopicVersionIdsMap.clear();

    shouldCreateNewVersionTimeout = 5;
  };

  const replaceCustomHeadingsWithH2 = (element: HTMLDivElement) => {
    const customHeadings = element.querySelectorAll<HTMLElement>('.custom-heading');

    customHeadings.forEach((customHeading) => {
      const h2Element = document.createElement('h2');

      h2Element.innerHTML = customHeading.innerHTML;
      customHeading.replaceWith(h2Element);
    });
  };

  const addIdToH2Elements = (element: HTMLDivElement) => {
    const headings = element.querySelectorAll<HTMLHeadingElement>('h2');

    headings.forEach((heading) => {
      const titleWithoutWhiteSpaces = heading.innerText.replaceAll('&nbsp;', '').replaceAll(' ', '');

      let title = 'Heading 1';

      if (titleWithoutWhiteSpaces.length !== 1) {
        title = heading.innerText;
      }

      const id = convertToSlug(title);

      heading.setAttribute('id', id);
    });
  };

  const handleDeletedComponents = (element: HTMLDivElement, componentBody: TopicContentComponentBodyItem[]) => {
    const components = element.querySelectorAll('section[data-component="true"]');

    let newComponentBody = [...componentBody];

    if (components.length < componentBody.length) {
      newComponentBody = componentBody.filter((componentItem) => {
        return Array.from(components).find((component) => {
          const id = component.getAttribute('data-id');

          if (id) {
            const [componentId, componentItemId] = id.split('_');

            return componentItem.id === componentItemId && componentItem.componentData.componentId === componentId;
          }

          return false;
        });
      });
    }

    return newComponentBody;
  };

  const transformComponentBody = (componentBody: TopicContentComponentBodyItem[] | null) => {
    if(!componentBody) return null;

    return componentBody.map((componentBodyItem) => {
      if(!componentBodyItem.componentData.layout) {
        componentBodyItem.componentData.layout = {
          baseColumnSize: 12
        };
      }

      return componentBodyItem;
    });
  };

  const getImageSources = (element: HTMLDivElement) => {
    const images = element.getElementsByTagName('img');

    const imageSources: string[] = [];

    for (let i = 0; i < images.length; i++) {
      if (images[i]?.src) imageSources.push(images[i]?.src);
    }

    return imageSources;
  };

  const getFirstImageId = (element: HTMLDivElement) => {
    const images = element.getElementsByTagName('img');

    let firstImageId: string | null = null;

    if (images.length && !!images[0].src) {
      const imageURL = new URL(images[0].src);

      if (imageURL.hostname.includes('localhost')) {
        firstImageId = imageURL.pathname;
      } else {
        firstImageId = imageURL.toString();
      }
    }

    return firstImageId;
  };

  const getFinalSlug = ({ topic, slug, title }: { topic: DraftTopic; slug: string; title: string }) => {
    return slug ? convertToSlug(slug) : topic.slug ? convertToSlug(topic.slug) : convertToSlug(title);
  };

  const getTopicDataBeforeSave = (topic: DraftTopic): DraftTopic => {
    const tempDivElement = document.createElement('div');

    const [modifiedTopic, newSlug, newTitle] = calculateNewTopicAndSlug(
      topic,
      topic.contentType === 'Component' ? topic.slug : topic.title,
      draftTopicTree,
      topic.contentType === 'Component' ? NEW_TOPIC_PAGE_TITLE : NEW_TOPIC_TITLE
    );

    tempDivElement.innerHTML =
      topic.contentType !== 'Component'
        ? topic.body === null
          ? `<h1>${newTitle}</h1>`
          : topic.body
        : document.getElementById('component-editor')!.innerHTML;

    replaceCustomHeadingsWithH2(tempDivElement);
    addIdToH2Elements(tempDivElement);

    if (topic.contentType !== 'Component') {
      modifiedTopic.componentBody = handleDeletedComponents(tempDivElement, topic.componentBody);
    }

    const imageSources = getImageSources(tempDivElement);
    const firstImageId = getFirstImageId(tempDivElement);
    const finalSlug = getFinalSlug({
      topic: modifiedTopic,
      slug: newSlug,
      title: newTitle,
    });
    const newBody = tempDivElement.innerHTML;
    const latestTopicVersionId =
      latestTopicVersionIdsMap.get(modifiedTopic.topicId) || modifiedTopic.latestTopicVersionId || emptyGuid();

    const newComponentBody = transformComponentBody(modifiedTopic.componentBody);

    return {
      ...modifiedTopic,
      firstImageId,
      latestTopicVersionId,
      topicVersionId: latestTopicVersionId,
      body: topic.contentType !== 'Component' ? newBody : '',
      slug: finalSlug,
      images: imageSources,
      isHidden: modifiedTopic.isHidden ?? false,
      languageCode: topic.documentId ? selectedDocumentLanguage.code : selectedAccountLanguage,
      contentType: topic.contentType || 'CKEditor5',
      componentBody: newComponentBody,
    };
  };

  const getTopicTextContent = (topicBody: string) => {
    const tempDivElement = document.createElement('div');

    tempDivElement.innerHTML = topicBody;

    return tempDivElement.textContent;
  };

  const getMetricImageSources = (topicBody: string) => {
    const tempDivElement = document.createElement('div');

    tempDivElement.innerHTML = topicBody;

    const images = tempDivElement.querySelectorAll<HTMLImageElement>('img');

    return Array.from(images)
      .map((image) => image.src)
      .join(',');
  };

  const callService = (topicId: string) => {
    const topicQueueItem = topicsToSaveQueue.get(topicId);

    if (topicQueueItem && topicQueueItem.savingContent) {
      saveTopicAPI({
        ...topicQueueItem.savingContent,
        overwrite: isFirstSave.current ? false : Boolean(shouldCreateNewVersionTimeout),
      })
        .then((data: DraftTopic) => {
          const topicQueueItemAfterSave = topicsToSaveQueue.get(topicId);

          latestTopicVersionIdsMap.set(topicId, data.topicVersionId);

          if (topicId === urlTopicId) {
            isFirstSave.current = false;
          }

          setNumberOfFailedRequests(0);

          if (topicQueueItemAfterSave?.nextContentToSave) {
            topicsToSaveQueue.set(topicId, {
              savingContent: {
                ...topicQueueItemAfterSave.nextContentToSave,
                latestTopicVersionId: data.topicVersionId,
                topicVersionId: data.topicVersionId,
              },
              nextContentToSave: null,
            });

            shouldCreateNewVersionTimeout = 60;

            resetTimeout();

            callService(topicId);
          } else {
            topicsToSaveQueue.delete(topicId);
          }
        })
        .catch(() => {
          //Todo: Handle Plan Limitation Dialog

          setNumberOfFailedRequests((prevState) => ++prevState);

          topicsToSaveQueue.delete(topicId);
        });
    }
  };

  const handleSaveTopic = () => {
    if (!selectedTopic?.topicId) {
      return;
    }

    if ((!enabled && selectedTopic.topicId) || isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    if (selectedTopic) {
      const modifiedTopic = getTopicDataBeforeSave(selectedTopic);
      const imageSources = getMetricImageSources(selectedTopic.body);

      worker.postMessage({
        topic: modifiedTopic,
        textContent: getTopicTextContent(modifiedTopic.body),
        body: modifiedTopic.body,
      });

      worker.onerror = (err) => console.log(err);

      return (worker.onmessage = async (e) => {
        const metricsData = e.data;
        const metrics = addCustomMetricsData(modifiedTopic, metricsData, imageSources);
        const topicToSave = { ...modifiedTopic, metrics };
        const topicQueueItem = topicsToSaveQueue.get(topicToSave.topicId);

        if (topicQueueItem && topicQueueItem.savingContent) {
          topicQueueItem.nextContentToSave = topicToSave;

          return;
        }

        topicsToSaveQueue.set(topicToSave.topicId, {
          savingContent: topicToSave,
          nextContentToSave: null,
        });

        callService(topicToSave.topicId);
      });
    }
  };

  useDebounceFn({
    value: selectedTopic,
    callback: handleSaveTopic,
    delay: 2000,
  });

  return {
    isSavingTopic,
    isSuccessSavingTopic,
    numberOfFailedRequests,
    saveTopicAPI,
  };
};

export default useSaveTopic;
