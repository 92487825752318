import React from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import UserAvatar from '../../user-avatar';
import useGetUserNotifications from '../../../api-hooks/user/useGetUserNotifications';
import useReadUserNotification from '../../../api-hooks/user/useReadUserNotification';

const useStyles: any = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0',
    cursor: 'pointer',
  },
  unread: {
    fontWeight: 700,
  },
  circle: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: '#1a73e8',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  title: {
    fontSize: 14,
  },
  dateTime: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const NotificationItem = ({ notification, setNotifications, setAnchorEl }) => {
  const classes = useStyles();
  const history = useHistory();

  const { getNotifications } = useGetUserNotifications();
  const { readUserNotification } = useReadUserNotification();

  const handleOnClick = () => {
    if (notification.extraFields?.accountId) {
      switch (notification.entityType) {
        case 'Publishes':
          history.push(
            `/document/${notification.extraFields.accountId}/${notification.extraFields.documentId}/publish-request/${notification.extraFields.publishRequestId}`
          );
          break;
        case 'Threads':
          if (notification.extraFields.publishRequestId) {
            history.push(
              `/document/${notification.extraFields.accountId}/${notification.extraFields.documentId}/publish-request/${notification.extraFields.publishRequestId}#commentId=${notification.entityId}`
            );
          } else {
            history.push(
              `/document/${notification.extraFields.accountId}/${notification.extraFields.documentId}/${notification.extraFields.topicId}/content?lang=${notification.extraFields.documentLanguageCode}&ver=${notification.extraFields.documentVersionSlug}&commentId=${notification.entityId}`
            );
          }
          break;
        case 'Topics':
          history.push(
            `/document/${notification.extraFields.accountId}/${notification.extraFields.documentId}/${notification.extraFields.topicId}/content?lang=${notification.extraFields.documentLanguageCode}&ver=${notification.extraFields.documentVersionSlug}`
          );
          break;
        default:
          break;
      }
    }
    if (setAnchorEl) setAnchorEl(null);
    readUserNotification({ notificationId: notification.id }).then(() => {
      getNotifications().then((response) => {
        setNotifications(response.data);
      });
    });
  };

  return (
    <div
      className={
        classes.menuItem + ' ' + (!notification.read ? classes.unread : '')
      }
      onClick={handleOnClick}
    >
      <div style={{ marginRight: 10 }}>
        <UserAvatar
          displayName={notification.userName}
          photoUrl={notification.photoUrl}
          size="large"
        />
        {!notification.read ? <span className={classes.circle}></span> : null}
      </div>
      <div className={classes.body}>
        <span className={classes.title}>{notification.title}</span>
        <span className={classes.dateTime}>
          {moment(
            new Date(notification.createDateTime),
            'YYYYMMDDLL'
          ).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
