import React from 'react';
import { Grid, CircularProgress, Typography, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import useGetAccountInvoices from '../../../../api-hooks/account/useGetAccountInvoices';

const styles: any = (theme: Theme) => ({
  invoiceBox: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    alignItems: 'center',
    marginBottom: '10px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: '5px',
  },
  buttonBox: {
    textAlign: 'right',
  },
  icon: {
    marginRight: '5px',
  },
  container: {
    padding: '10px',
  },
  button: {
    display: 'inline-flex',
    padding: '2px 5px 2px 2px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#1a73e8',
    '&:hover': {
      textDecoration: 'none!important',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80px',
    },
  },
});

const Invoices = (props: any) => {
  const { t } = useTranslation();

  const { accountInvoices } = useGetAccountInvoices(
    props.accountConfiguration.id
  );

  const createDate = (timestamp: number) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(timestamp * 1000);
    const month = date.getMonth();
    const day = '0' + date.getDay();
    const year = '0' + date.getFullYear();

    return `${monthNames[month]}, ${day} ${year.substr(1)}`;
  };

  const formatAmount = (amount: string) => {
    const beforeLastThreeDigits = amount.slice(0, -3);
    const lastThreeDigits = amount.slice(-3);

    const formattedBeforeLastThreeDigits = beforeLastThreeDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return `${formattedBeforeLastThreeDigits}.${lastThreeDigits}`;
  }

  const classes = props.classes;

  return (
    <Grid container className={classes.container}>
      <Col xs={24}>
        <Typography
          style={{ marginBottom: 15, marginTop: 15 }}
          component="h1"
          variant="h5"
        >
          {t('invoices-settings-title')}
        </Typography>
      </Col>

      {accountInvoices && accountInvoices.data.length ? (
        accountInvoices.data.map((invoice: any) => {
          return (
            <Col key={invoice.id} xs={24} className={classes.invoiceBox}>
              <Col xs={8} sm={8} md={8}>
                {createDate(invoice.created)}
              </Col>

              <Col xs={8} sm={8} md={8}>
                $ {formatAmount(invoice.amount_due.toString())}
              </Col>

              <Col xs={8} sm={8} md={8} className={classes.buttonBox}>
                <a href={invoice.invoice_pdf} className={classes.button}>
                  <OpenInNewIcon className={classes.icon} />
                  Open
                </a>
              </Col>
            </Col>
          );
        })
      ) : accountInvoices && !accountInvoices.data.length ? (
        <div>You do not have any payment invoice</div>
      ) : (
        <Col xs={24} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Col>
      )}
    </Grid>
  );
};
export default withStyles(styles)(Invoices);
