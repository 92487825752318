import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAccessRequestType } from '../../api-schemas/access';

const createAccessRequest = (data: CreateAccessRequestType) => {
    const body = JSON.stringify(data);

    return axios.post('/api/Access', body, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useCreateAccess(entityId: string) {
    const queryClient = useQueryClient();

    const {
        mutateAsync: createAccess,
        isPending: isCreatingAccess,
        isSuccess: isSuccessCreatingAccess
    } = useMutation({
        mutationFn: createAccessRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['accesses', entityId] });
        }
    });

    return {
        createAccess,
        isCreatingAccess,
        isSuccessCreatingAccess
    }
}