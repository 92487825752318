import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PostAddOutlined as PostAddOutlinedIcon } from '@mui/icons-material';
import { Button, Typography } from 'antd';

import {
  createNewTopicContent,
  createNewTopics,
} from '../../../../../helpers/common';
import {
  DraftTopicTree,
  DraftTopicTreeItem,
} from '../../../../../api-schemas/topic';
import useSaveTopic from '../../../../../api-hooks/topic/useSaveTopic';
import { TopicContext } from '../../../../../contexts/topic';

type NewChildButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
  flatDraftTopicTree: DraftTopicTree;
};

const NewChildButton = ({
  documentVersionId,
  draftTopicTreeItem,
  flatDraftTopicTree,
}: NewChildButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const { t } = useTranslation();
  const { changeTopic } = useContext(TopicContext);

  const { saveTopic, isSavingTopic } = useSaveTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    onSuccessCallback: () => {
      //TODO: Change route based on new topic
    },
  });

  const handleAddTopic = () => {
    const parentTopic: DraftTopicTreeItem & { children: DraftTopicTree } = {
      ...draftTopicTreeItem,
      children: [],
    };

    const newTopic = createNewTopics(
      parentTopic.topicId,
      parentTopic.languageCode,
      flatDraftTopicTree,
      parentTopic.contentType
    );

    //eslint-disable-next-line
    //@ts-expect-error
    parentTopic.children.push(newTopic);

    const newContent = createNewTopicContent(
      parentTopic.topicId,
      parentTopic.languageCode,
      { id: documentVersionId },
      newTopic,
      parentTopic.contentType
    );

    //eslint-disable-next-line
    //@ts-expect-error
    saveTopic(newContent).then(() => {
      changeTopic(newContent.topicId);
    });
  };

  return (
    <Button
      onClick={handleAddTopic}
      disabled={isDeleted || isSavingTopic}
      type="text"
      style={{ display: 'flex', width: '100%' }}
    >
      <PostAddOutlinedIcon style={{ marginRight: 5 }} />

      <Typography>{t('new-child-topic')}</Typography>
    </Button>
  );
};

export default NewChildButton;
