import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { UpdateFeedbackStatusRequestType } from '../../api-schemas/feedback';

const updateFeedbackStatusRequest = (data: UpdateFeedbackStatusRequestType) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/feedback/status', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateFeedbackStatus() {
  const {
    mutateAsync: updateFeedbackStatus,
    isPending: isUpdatingFeedbackStatus,
    isSuccess: isSuccessUpdatingFeedbackStatus,
  } = useMutation({
    mutationFn: updateFeedbackStatusRequest,
  });

  return {
    updateFeedbackStatus,
    isUpdatingFeedbackStatus,
    isSuccessUpdatingFeedbackStatus,
  };
}
