import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../../../progress-bar';
import ProgressBarStep from '../../../progress-bar/progress-bar-step';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { changeRouteBaseOnEditContentMenues } from '../../../../helpers/route';
import { Button, Col } from 'antd';
import { useHistory } from 'react-router-dom';

const AnalyticsTabItem = ({
  category,
  metric,
  variant,
  handleChangeMetric,
  selectedSidePart,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const renderProgressBar = () => {
    if (metric.field !== 'charactersCount') {
      let range = 0;
      if (metric.successValueEndRange === 'Infinity') {
        range = metric.successValueStartRange;
      } else if (metric.warningValueEndRange === 'Infinity') {
        range = metric.warningValueStartRange;
      } else if (metric.errorValueEndRange === 'Infinity') {
        range = metric.errorValueStartRange;
      } else if (
        metric.warningValueEndRange !== null &&
        metric.errorValueEndRange !== null
      ) {
        range =
          metric.successValueEndRange > metric.warningValueEndRange &&
          metric.successValueEndRange > metric.errorValueEndRange
            ? metric.successValueEndRange
            : metric.warningValueEndRange > metric.successValueEndRange &&
                metric.warningValueEndRange > metric.errorValueEndRange
              ? metric.warningValueEndRange
              : metric.errorValueEndRange;
      } else {
        range = metric.successValueEndRange;
      }

      const maxValue =
        metric.field === 'keyphraseInSubheadings'
          ? 100
          : range + (range > 500 ? 300 : range > 10 ? 50 : 5);

      const convertValue = (value: number) => {
        return Math.round(
          ((value < maxValue ? value : maxValue) * 100) / maxValue
        );
      };

      return (
        <ProgressBar
          value={convertValue(
            metric.value >= maxValue ? (95 * maxValue) / 100 : metric.value
          )}
          realValue={metric.value}
          width={200}
        >
          {metric.warningValueStartRange !== null && (
            <ProgressBarStep
              metricMaxValue={maxValue}
              minValue={convertValue(metric.warningValueStartRange)}
              maxValue={
                metric.warningValueEndRange === 'Infinity'
                  ? convertValue(maxValue)
                  : convertValue(metric.warningValueEndRange)
              }
              label={t('warning')}
              color="#ffc107"
            />
          )}
          {metric.errorValueStartRange !== null && (
            <ProgressBarStep
              metricMaxValue={maxValue}
              minValue={convertValue(metric.errorValueStartRange)}
              maxValue={
                metric.errorValueEndRange === 'Infinity'
                  ? convertValue(maxValue)
                  : convertValue(metric.errorValueEndRange)
              }
              label={t('danger')}
              color="#dc3545"
            />
          )}
          {metric.warningValueStartRange === null &&
            metric.errorValueStartRange === null && (
              <ProgressBarStep
                metricMaxValue={maxValue}
                minValue={0}
                maxValue={convertValue(metric.successValueStartRange)}
                label={t('danger')}
                color="#dc3545"
              />
            )}
          {metric.warningValueStartRange === null &&
            metric.errorValueStartRange === null && (
              <ProgressBarStep
                metricMaxValue={maxValue}
                minValue={convertValue(metric.successValueEndRange)}
                maxValue={convertValue(maxValue)}
                label={t('danger')}
                color="#dc3545"
              />
            )}
          <ProgressBarStep
            metricMaxValue={maxValue}
            minValue={convertValue(metric.successValueStartRange)}
            maxValue={
              range === 0
                ? 'Infinity'
                : metric.successValueEndRange === 'Infinity'
                  ? convertValue(maxValue)
                  : convertValue(metric.successValueEndRange)
            }
            label={t('success')}
            color="#28a745"
          />
        </ProgressBar>
      );
    }
  };

  return (
    <Col
      xs={24}
      style={{
        display: 'flex',
        alignItems: selectedSidePart ? 'flexs-start' : 'center',
        marginBottom: 30,
        flexDirection: selectedSidePart ? 'column' : 'row',
      }}
    >
      <Col
        xs={selectedSidePart ? 24 : 16}
        style={{ paddingRight: selectedSidePart ? 0 : 10 }}
      >
        <Col
          xs={24}
          style={{
            display: 'flex',
            marginBottom: 10,
            cursor: selectedSidePart ? 'auto' : 'pointer',
            alignItems: 'center',
          }}
          onClick={() => handleChangeMetric(metric, category)}
        >
          {variant === 'success' ? (
            <CheckCircleIcon style={{ color: '#28a745', marginRight: 10 }} />
          ) : variant === 'warning' ? (
            <ErrorIcon style={{ color: '#ffc107', marginRight: 10 }} />
          ) : (
            <CancelIcon style={{ color: '#dc3545', marginRight: 10 }} />
          )}
          <Typography style={{ fontWeight: 'bold' }}>
            {t(metric.title)}
          </Typography>
          {metric.field === 'metaDescription' && metric.value === false ? (
            <Button
              type="link"
              onClick={() =>
                history.push(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-expect-error
                  changeRouteBaseOnEditContentMenues(
                    'Settings',
                    window.location.pathname.split('/')[4]
                  )[1]
                )
              }
              style={{ border: 'none', padding: '5px' }}
            >
              (Set)
            </Button>
          ) : null}
          {metric.value !== undefined && metric.value !== null ? (
            <span style={{ marginLeft: 5, fontSize: 14, color: '#757575' }}>
              (
              {(typeof metric.value === 'number' &&
                metric.type === 'Boolean' &&
                metric.value !== 0) ||
              (metric.type === 'Number' && metric.numberType === 'percentage')
                ? Math.round(metric.value).toString() + '%'
                : typeof metric.value === 'number'
                  ? Math.round(metric.value).toString()
                  : metric.value.toString()}
              )
            </span>
          ) : null}
        </Col>
        <Col xs={24} style={{ paddingLeft: 34 }}>
          <Typography variant="body2">
            {variant === 'success'
              ? metric.successHintText
              : variant === 'warning'
                ? metric.warningHintText
                : metric.field === 'keyphraseDensity' &&
                    metric.value < metric.successValueStartRange
                  ? metric.errorHintTextMin
                  : metric.field === 'keyphraseDensity' &&
                      metric.value > metric.successValueEndRange
                    ? metric.errorHintTextMax
                    : metric.errorHintText}
          </Typography>
        </Col>
      </Col>
      <Col
        xs={selectedSidePart ? 24 : 8}
        style={{
          display: 'flex',
          justifyContent: selectedSidePart ? 'center' : 'flex-end',
          marginTop: selectedSidePart ? 10 : 0,
        }}
      >
        {metric.type === 'Number' &&
        metric.field !== 'sentenceCount' &&
        metric.field !== 'fleschReadingEase'
          ? renderProgressBar()
          : null}
      </Col>
    </Col>
  );
};

export default AnalyticsTabItem;
