import React, { Fragment, useContext, useState } from 'react';
import { Tooltip, Button, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { DeleteTopicConfirmation } from '../../../delete-confirmation';
import { TopicContext } from '../../../../../contexts/topic';
import useDeleteTopic from '../../../../../api-hooks/topic/useDeleteTopic';
import { DraftTopicTree } from '../../../../../api-schemas/topic';
import { FolderContext } from '../../../../../contexts/folder';

const useStyles: any = makeStyles((theme: Theme) => ({
  button: {
    margin: `${theme.spacing(1)}!important`,
    [theme.breakpoints.down('lg')]: {
      minWidth: '35px',
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    fontSize: 12,
  },
}));

type DeleteButtonPropsType = {
  isDeleted: boolean;
  flatDraftTopicTree?: DraftTopicTree;
};

const DeleteButton = ({
  isDeleted,
  flatDraftTopicTree,
}: DeleteButtonPropsType) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { selectedTopic, changeTopic } = useContext(TopicContext);
  const { selectedFolderId } = useContext(FolderContext);

  const { deleteTopic, isDeletingTopic } = useDeleteTopic({
    documentVersionId: selectedTopic?.documentVersionId,
    languageCode: selectedTopic?.languageCode,
    topicId: selectedTopic?.topicId,
  });

  const classes = useStyles();
  const history = useHistory();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();
  const queryClient = useQueryClient();

  const handleRemove = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleRemoveConfirmed = (deleteAll: boolean) => {
    if (selectedTopic) {
      const selectedTopicVersionNumber = parseFloat(
        selectedTopic.topicVersionNumber
      );

      deleteTopic({
        topicId: selectedTopic.topicId,
        languageCode: deleteAll ? null : selectedTopic.languageCode,
      }).then(async () => {
        if (selectedTopicVersionNumber < 1) {
          if (!documentId) {
            queryClient.removeQueries({
              queryKey: selectedFolderId
                ? ['topics', accountId, selectedFolderId]
                : ['topics', accountId],
            });

            history.push('/');
          } else if (flatDraftTopicTree && flatDraftTopicTree.length > 2) {
            const newTopicId = flatDraftTopicTree.filter(
              (topic) =>
                topic.topicId !== selectedTopic.topicId && topic.parentTopicId
            )[0].topicId;

            changeTopic(newTopicId);
          } else {
            history.push('/');
          }
        }
      });
    }
  };

  return (
    <Fragment>
      <Tooltip
        title="Delete topic"
        aria-label="delete"
        className="hide-on-xlarge"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleRemove}
          className={classes.button}
          style={{ height: '35px', minWidth: '35px', padding: '5px 5px' }}
          disabled={isDeletingTopic || isDeleted}
          id="delete-topic-button"
        >
          <DeleteIcon style={{ width: '0.75em', height: '0.75em' }} />
          {isDeletingTopic && <CircularProgress size={14} />}
        </Button>
      </Tooltip>

      <DeleteTopicConfirmation
        title={'Delete topic'}
        message={`Are you sure you want to delete ${
          selectedTopic?.title ? selectedTopic?.title : selectedTopic?.slug
        }?`}
        isOpen={openDeleteDialog}
        loading={isDeletingTopic}
        languages={selectedTopic?.languages}
        onDialogClosed={handleCloseDeleteDialog}
        onDialogConfirmed={(deleteAll: boolean) => {
          handleRemoveConfirmed(
            selectedTopic?.topicVersionNumber === null ? true : deleteAll
          );
          handleCloseDeleteDialog();
        }}
      />
    </Fragment>
  );
};

export default DeleteButton;
