import React from 'react';
import { Form, Input } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';

const { TextArea } = Input;

interface TextAreaInputProps {
  field: string;
  label?: string;
  value: any;
  onChange: any;
  tooltip?: string;
  showError?: boolean;
  required?: boolean;
  getErrorMessage?: any;
  showCount?: boolean;
  disabled?: boolean;
  maxLength?: number;
  autoSize?: any;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  className?: string;
  layout?: FormLayout;
  id?: string;
  rows?: number;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  id,
  disabled,
  layout,
  field,
  label,
  value,
  onChange,
  tooltip,
  showError,
  required,
  getErrorMessage,
  showCount,
  maxLength,
  autoSize,
  style,
  inputStyle,
  className,
  rows,
}) => {
  return (
    <Form
      layout={layout ? layout : 'vertical'}
      className={showError ? '' : 'hide-alert'}
    >
      <Form.Item
        style={style}
        className={className}
        tooltip={tooltip ? tooltip : null}
        label={label}
        validateStatus={
          showError && getErrorMessage(field) ? 'error' : 'success'
        }
        help={showError && getErrorMessage(field)}
        rules={[
          { required: required, message: showError && getErrorMessage(field) },
        ]}
      >
        <TextArea
          id={id}
          disabled={disabled === true}
          style={inputStyle}
          autoSize={autoSize}
          value={value}
          onChange={onChange}
          showCount={showCount}
          maxLength={maxLength}
          rows={rows}
        />
      </Form.Item>
    </Form>
  );
};

export default TextAreaInput;
