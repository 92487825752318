import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'antd';
import yaml from 'js-yaml';

import StructuredData from '../classes/component';
import YamlEditor from '../../../yaml-editor';
import { StructuredDataContext } from '../../../../contexts/structured-data-context';

const CodeEditor = ({ component, setComponent }) => {
  const [nonEditableComponentData, setNonEditableComponentData] =
    useState<any>();

  const { context } = useContext(StructuredDataContext);

  useEffect(() => {
    if (component && !nonEditableComponentData) {
      let nonEditableJsonData = new StructuredData(
        component.data.componentData,
        component.data.order,
        context
      ).dataProvider.getJSONData();
      if (nonEditableJsonData && nonEditableJsonData.hiddenData)
        nonEditableJsonData = { hiddenData: nonEditableJsonData.hiddenData };
      else nonEditableJsonData = null;
      setNonEditableComponentData(
        nonEditableJsonData ? yaml.dump(nonEditableJsonData) : null
      );
    }
    // eslint-disable-next-line
  }, [component]);

  const getCodeEditorValue = () => {
    const editableJsonData = new StructuredData(
      component.data.componentData,
      component.data.order,
      context
    ).dataProvider.getJSONData();

    if (editableJsonData) {
      delete editableJsonData.hiddenData;
      return yaml
        .dump(editableJsonData)
        .replaceAll(/\\n/g, '\n')
        .replaceAll(/"/g, '')
        .replaceAll(/'/g, '')
        .replaceAll(/&nbsp;>-/g, '')
        .replaceAll(/ >-/g, '');
    }
  };

  const handleOnChange = (value: string | undefined) => {
    if (value) {
      const newComponent = { ...component };
      newComponent['data']['componentData']['content']['content'] =
        nonEditableComponentData ? value + nonEditableComponentData : value;
      setComponent(newComponent);
    }
  };

  if (component) {
    return (
      <Card className="editor-card" bordered>
        <YamlEditor
          schemaUri={`/api/component/version/${component.componentVersion.id}/schema?isPublic=true`}
          data={getCodeEditorValue()}
          handleChangeData={handleOnChange}
          style={{ width: '100%', height: 400 }}
        />
      </Card>
    );
  } else {
    return null;
  }
};

export default CodeEditor;
