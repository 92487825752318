import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Processes } from '../../api-schemas/process';

const getProcessesRequest = (accountId: string) => {
  return axios
    .get<Processes>(`/api/account/${accountId}/process`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetProcesses({
  accountId,
  enabled,
}: {
  accountId: string;
  enabled: boolean;
}) {
  const {
    data,
    refetch: getProcesses,
    isFetching: isLoadingProcesses,
    isError: isErrorLoadingProcesses,
    isSuccess: isSuccessLoadingProcesses,
  } = useQuery<Processes>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['processes', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
    queryFn: () => getProcessesRequest(accountId),
  });

  const processes = useMemo(() => data, [data]);

  return {
    processes,
    isLoadingProcesses,
    isErrorLoadingProcesses,
    isSuccessLoadingProcesses,

    getProcesses,
  };
}
