import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Activity from '../activity';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Col } from 'antd';

const useStyles: any = makeStyles(() => ({
  expansionPanel: {
    '&::before': {
      opacity: 0,
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    '& *': {
      borderRadius: '0!important',
    },
    borderRadius: '0!important',
  },
  panelSummary: {
    '& > div': {
      alignItems: 'center',
    },
    marginTop: 10,
  },
  errorBox: {
    color: 'red',
  },
  expansionSummary: {
    display: 'flex',
    flexFlow: 'wrap',
    flexDirection: 'row',
  },
  addActivityBox: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    cursor: 'pointer',
    maxHeight: 46,
  },
  input: {
    '& *': {
      borderRadius: 0,
    },
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
  stepBox: {
    padding: 10,
    minHeight: 365,
    marginTop: 10,
    marginRight: 100,
    position: 'relative',
    '& > div:first-child': {
      minWidth: 300,
      maxWidth: 300,
      display: 'flex!important',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    display: 'flex!important',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  arrowBox: {
    position: 'absolute',
    right: '-70px',
    top: '50%',
  },
  scrollDiv: {
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#1a73e8',
    },
  },
  stepNumber: {
    color: '#000',
    padding: 5,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #000',
    borderRadius: '50%',
  },
}));

interface StepsProps {
  processData: any;
  processInstance?: any;
  accesses: any[];
  handleOnActivityDataChange?: any;
  handleRemoveStep?: any;
  handleRemoveActivity?: any;
  handleAddActivity?: any;
  viewMode: boolean;
  openDialogOnFirstActivity?: boolean;
  setOpenDialogOnFirstActivity?: any;
}

const Steps: React.FunctionComponent<StepsProps> = ({
  processData,
  processInstance,
  handleOnActivityDataChange,
  accesses,
  handleRemoveStep,
  handleRemoveActivity,
  handleAddActivity,
  viewMode,
  openDialogOnFirstActivity,
  setOpenDialogOnFirstActivity,
}) => {
  const ref = useRef<any>();
  const classes = useStyles();
  const { t } = useTranslation();
  const [listData, setListData] = useState<any>(undefined);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    const listData: any = {
      activities: {},
      columns: {},
      columnOrder: [],
    };
    processData.stepsActivities.map((stepActivities, index) => {
      listData['columns'][`column-${index}`] = {
        id: `column-${index}`,
        title: `column-${index}`,
        data: stepActivities,
        index: index,
        activityIds: [],
      };
      stepActivities.map((activity) => {
        listData['activities'][`activity-${activity.step}-${activity.id}`] =
          activity;
        listData['columns'][`column-${index}`]['activityIds'].push(
          `activity-${activity.step}-${activity.id}`
        );
        return true;
      });
      listData['columnOrder'].push(`column-${index}`);
      return true;
    });
    setListData(listData);
  }, [processData]);

  useEffect(() => {
    ref.current = 'visited';
  }, []);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = listData.columns[source.droppableId];
    const finish = listData.columns[destination.droppableId];

    // Moving from one list to another
    const startTaskIds = Array.from(start.activityIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      activityIds: startTaskIds,
    };

    const finishActivityIds = Array.from(finish.activityIds);
    finishActivityIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      activityIds: finishActivityIds,
    };

    const newState = {
      ...listData,
      columns: {
        ...listData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setListData(newState);
    handleOnActivityDataChange(
      parseInt(draggableId.split('-')[2]),
      'moveActivity',
      Number(destination.droppableId.split('-')[1]),
      Number(source.droppableId.split('-')[1])
    );
  };

  if (viewMode) {
    return (
      <div>
        <div
          style={{ display: 'flex', overflowX: 'scroll', overflowY: 'hidden' }}
          className={classes.scrollDiv}
        >
          {listData?.columnOrder.map((columnId: string) => {
            const column = listData?.columns[columnId];
            const activities = column.activityIds.map(
              (activityId) => listData?.activities[activityId]
            );
            return (
              <div
                key={columnId}
                className={classes.stepBox}
                style={
                  column.index === listData?.columnOrder.length - 1
                    ? { minHeight: 'auto', marginRight: 0 }
                    : { minHeight: 'auto' }
                }
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Col xs={10}></Col>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                      <div className={classes.stepNumber}>
                        {column.index + 1}
                      </div>
                    </Col>
                    <Col xs={10}></Col>
                  </div>
                  {processInstance &&
                    activities.map((activity, index) => {
                      const activityInstance =
                        processInstance.activityInstances.filter(
                          (activityInstance) =>
                            activityInstance.activityId === activity.id
                        )[0];
                      return (
                        <div key={index}>
                          <Activity
                            openDialogOnFirstActivity={
                              openDialogOnFirstActivity
                            }
                            setOpenDialogOnFirstActivity={
                              setOpenDialogOnFirstActivity
                            }
                            key={activity.id}
                            activity={activity}
                            stepActivities={activities}
                            accesses={accesses}
                            viewMode={true}
                            status={activityInstance.activityStatus}
                          />
                        </div>
                      );
                    })}
                </div>
                {column.index !== listData?.columnOrder.length - 1 && (
                  <div className={classes.arrowBox}>
                    <ArrowRightAltIcon style={{ fontSize: 50 }} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            style={{
              display: 'flex',
              overflowX: 'scroll',
              overflowY: 'hidden',
            }}
            className={classes.scrollDiv}
          >
            {listData?.columnOrder.map((columnId: string) => {
              const column = listData?.columns[columnId];
              const activities = column.activityIds.map(
                (activityId) => listData?.activities[activityId]
              );
              return (
                <Droppable key={columnId} droppableId={columnId}>
                  {(provided) => (
                    <div
                      key={columnId}
                      className={classes.stepBox}
                      style={
                        column.index === listData?.columnOrder.length - 1
                          ? { marginRight: 0 }
                          : {}
                      }
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Col xs={10}></Col>
                            <Col xs={4} style={{ textAlign: 'center' }}>
                              <div className={classes.stepNumber}>
                                {column.index + 1}
                              </div>
                            </Col>
                            <Col
                              xs={10}
                              style={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                                paddingRight: 10,
                              }}
                            >
                              <Tooltip arrow title={t<string>('delete-step')}>
                                <DeleteIcon
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '1.2rem',
                                  }}
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setSelectedStep(column.index);
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          </div>
                          {activities.map((activity, index) => {
                            return (
                              <Draggable
                                key={activity.id}
                                draggableId={`activity-${activity.step}-${activity.id}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Activity
                                      openDialogOnFirstActivity={
                                        openDialogOnFirstActivity
                                      }
                                      setOpenDialogOnFirstActivity={
                                        setOpenDialogOnFirstActivity
                                      }
                                      key={activity.id}
                                      activity={activity}
                                      stepActivities={activities}
                                      handleOnActivityDataChange={
                                        handleOnActivityDataChange
                                      }
                                      accesses={accesses}
                                      handleRemoveActivity={
                                        handleRemoveActivity
                                      }
                                      viewMode={false}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                        <Col
                          xs={24}
                          className={classes.addActivityBox}
                          onClick={() => handleAddActivity(column.index)}
                        >
                          <AddIcon />
                          <span>{t('add-rule')}</span>
                        </Col>
                      </div>
                      {column.index !== listData?.columnOrder.length - 1 && (
                        <div className={classes.arrowBox}>
                          <ArrowRightAltIcon style={{ fontSize: 50 }} />
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </DragDropContext>
        <ConfirmationDialog
          isOpen={openDialog}
          onDialogClosed={() => setOpenDialog(false)}
          title={t<string>('delete-step')}
          message={t('delete-step-message')}
          onDialogConfirmed={() => {
            handleRemoveStep(selectedStep);
            setOpenDialog(false);
            setSelectedStep(null);
          }}
        />
      </div>
    );
  }
};

export default Steps;
