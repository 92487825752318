import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { RestoreFromTrash as RestoreFromTrashIcon } from '@mui/icons-material';

import { DraftTopicTreeItem } from '../../../../../api-schemas/topic';
import useRevertTopic from '../../../../../api-hooks/topic/useRevertTopic';

type PublishButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
};

const UndoDeleteButton = ({
  documentVersionId,
  draftTopicTreeItem,
}: PublishButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const { t } = useTranslation();

  const { revertTopic, isRevertingTopic } = useRevertTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    topicId: draftTopicTreeItem.topicId,
  });

  const handleRevert = () => {
    revertTopic({
      topicId: draftTopicTreeItem.topicId,
      targetTopicVersionId: draftTopicTreeItem.latestTopicVersionId,
      languageCode: draftTopicTreeItem.languageCode,
    });
  };

  return (
    <Button
      onClick={handleRevert}
      disabled={!isDeleted || isRevertingTopic}
      type="text"
      style={{ display: 'flex', width: '100%' }}
    >
      <RestoreFromTrashIcon style={{ marginRight: 5 }} />

      <Typography>{t('undo-delete-topic')}</Typography>
    </Button>
  );
};

export default UndoDeleteButton;
