import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdatePublishRequestRequest } from '../../api-schemas/publish-request';

export type UpdatePublishRequestParams = {
  documentId: string;
  languageCode: string;
  publishRequestId: string;
};

const updatePublishRequestRequest = (data: UpdatePublishRequestRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Publishrequest', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdatePublishRequest({
  documentId,
  languageCode,
  publishRequestId,
}: UpdatePublishRequestParams) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updatePublishRequest,
    isPending: isUpdatingPublishRequest,
    isSuccess: isSuccessUpdatingPublishRequest,
  } = useMutation({
    mutationFn: updatePublishRequestRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['publishRequests', documentId, languageCode],
      });

      queryClient.invalidateQueries({
        queryKey: ['publishRequest', publishRequestId],
      });
    },
  });

  return {
    updatePublishRequest,
    isUpdatingPublishRequest,
    isSuccessUpdatingPublishRequest,
  };
}
