import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  CircularProgress,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useTranslation } from 'react-i18next';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useSnackbar } from 'notistack';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Col } from 'antd';

import { ConfirmationDialog } from '../../confirmation-dialog';
import TextInput from '../../inputs/text-input';
import HelpSnippet from '../../help-snippet';
import useCreateAccountToken from '../../../api-hooks/account/useCreateAccountToken';
import useGetAccountTokens from '../../../api-hooks/account/useGetAccountTokens';
import useDeleteAccountToken from '../../../api-hooks/account/useDeleteAccountToken';

const styles: any = (theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  name: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  existingMemberToolbar: {
    marginTop: '30px',
    paddingRight: '14px',
  },
  searchExistingMembers: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  searchExistingMembersIcon: {
    padding: 10,
  },
  searchExistingMembersInput: {
    marginLeft: 8,
    flex: 1,
  },
  newUserBody: {
    padding: '20px',
    marginBottom: '0px',
  },
  borders: {
    borderColor: '#dfdfdf',
    border: '1px solid',
    borderRadius: '4px',
    paddingRight: '0px',
    paddingLeft: '30px',
  },
  listItem: {
    paddingLeft: '10px',
  },
});

const AccountTokensSettings = (props) => {
  const [name, setName] = useState<string>('');
  const [tooltipTitle, setTooltipTitle] = useState<string>('Copy');
  const [selectedToken, setSelectedToken] = useState<any>();
  const [existingTokensSearchPhrase, setExistingTokensSearchPhrase] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { createAccountToken, isCreatingAccountToken } = useCreateAccountToken(
    props.account.id
  );
  const { accountTokens, isLoadingAccountTokens, isErrorLoadingAccountTokens } =
    useGetAccountTokens(props.account.id);
  const { deleteAccountToken, isDeletingAccountToken } = useDeleteAccountToken(
    props.account.id
  );

  const loading =
    isLoadingAccountTokens || isCreatingAccountToken || isDeletingAccountToken;

  const { classes } = props;

  useEffect(() => {
    if (isErrorLoadingAccountTokens) {
      enqueueSnackbar(t('error.get-tokens'), {
        variant: 'error',
      });
    }
  }, [isErrorLoadingAccountTokens]);

  const onNameChanged = (newValue) => {
    setName(newValue);
  };

  const handleAddToken = () => {
    const data = {
      name: name,
      accountId: props.account.id,
      documentIds: [],
    };

    createAccountToken(data)
      .then(() => {
        setName('');
      })
      .catch(() => {
        enqueueSnackbar(t('error.add-token'), {
          variant: 'error',
        });
      });
  };

  const handleDeleteToken = () => {
    deleteAccountToken({
      accountId: props.account.id,
      tokenId: selectedToken.id,
    })
      .then(() => {
        setOpenDeleteDialog(false);
      })
      .catch(() => {
        enqueueSnackbar(t('error.delete-token'), {
          variant: 'error',
        });
      });
  };

  const handleFindExistingTokensChange = (newValue) => {
    setExistingTokensSearchPhrase(newValue);
  };

  const handleClickOpenDeleteDialog = (token) => {
    setSelectedToken(token);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedToken(null);
  };

  const copyValue = (value) => {
    const textarea = document.createElement('textarea');
    textarea.textContent = value;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    setTooltipTitle('Copied!');
    setTimeout(() => {
      setTooltipTitle('Copy');
    }, 1000);
  };

  const renderNewTokenSection = () => {
    return (
      <Grid
        className={`${classes.newUserBody} ${classes.borders}`}
        container
        direction="column"
      >
        <Col style={{ marginBottom: 5, display: 'flex' }}>
          <Typography style={{ marginRight: 5 }}>
            Add new tokens to {props.account.title}
          </Typography>
          <HelpSnippet title={t('token-help-text')} />
        </Col>
        <Col
          xs={24}
          style={{ display: 'flex', alignItems: 'center', margin: 10 }}
        >
          <Col md={16}>
            <TextInput
              field="name"
              value={name}
              onChange={(event) => onNameChanged(event.target.value)}
              showError={false}
              placeholder={t('title')}
            />
          </Col>
          <Col
            xs={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Col>
              <Tooltip title={`Add token`}>
                <span>
                  <IconButton
                    aria-label={`Add token`}
                    disabled={!name.length || loading}
                    onClick={() => handleAddToken()}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Col>
          </Col>
        </Col>
      </Grid>
    );
  };

  const renderSearchedText = (
    text: string,
    searchPhrase: string,
    tokenId: string
  ) => {
    const matches = match(text, searchPhrase);
    const parts = parse(text, matches);

    return (
      <div>
        {parts.map((part: any, index: number) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
        <span style={{ marginLeft: 5 }}>
          <Tooltip title={tooltipTitle} arrow>
            <FileCopyIcon
              style={{
                fontSize: 12,
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'pointer',
              }}
              onClick={() => copyValue(tokenId)}
            />
          </Tooltip>
        </span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Typography
        style={{ margin: '10px 0 15px 0' }}
        component="h1"
        variant="h6"
      >
        {props.name}
      </Typography>
      {renderNewTokenSection()}
      <Toolbar className={classes.existingMemberToolbar}>
        <Typography
          className={classes.name}
          variant="h6"
          color="inherit"
          noWrap
        >
          Existing tokens
        </Typography>
        <div className={classes.grow} />
        <div className={classes.searchExistingMembers}>
          <TextInput
            className={classes.searchExistingMembersInput}
            field="name"
            placeholder={`Find existing token(s) …`}
            value={existingTokensSearchPhrase}
            onChange={(event) =>
              handleFindExistingTokensChange(event.target.value)
            }
            required={false}
            showError={false}
          />
          <IconButton
            className={classes.searchExistingMembersIcon}
            aria-label="Search"
          >
            <SearchIcon />
          </IconButton>
        </div>
      </Toolbar>
      <List>
        {!props.loading &&
          !loading &&
          accountTokens
            ?.filter(
              (token) =>
                token &&
                (!existingTokensSearchPhrase ||
                  token.name.toLowerCase().includes(existingTokensSearchPhrase))
            )
            .map(
              (token) =>
                token && (
                  <span
                    key={token.id}
                    style={{
                      margin: '5px 0',
                      display: 'block',
                      padding: '5px 0',
                    }}
                  >
                    <ListItem className={classes.listItem} divider={true}>
                      <ListItemText
                        primary={renderSearchedText(
                          token.name,
                          existingTokensSearchPhrase,
                          token.id
                        )}
                      />
                      <ListItemSecondaryAction
                        style={{
                          top: '35%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title={`Remove ${token.name}`}>
                          <IconButton
                            aria-label={`Remove ${token.name}`}
                            onClick={() => handleClickOpenDeleteDialog(token)}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </span>
                )
            )}
        {(props.loading || loading || !accountTokens) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
      </List>
      {selectedToken && (
        <ConfirmationDialog
          name={'Remove token?'}
          message={`Are you sure you want to remove ${selectedToken.name} from the ${props.account.title}?`}
          isOpen={openDeleteDialog}
          loading={loading}
          onDialogClosed={handleCloseDeleteDialog}
          onDialogConfirmed={handleDeleteToken}
        />
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(AccountTokensSettings);
