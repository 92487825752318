import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { DocumentService } from '../../../../services/document';
import { DocumentContext } from '../../../../contexts/document';
import { localizeKey } from '../../../../helpers/localize-key';
import { useHistory } from 'react-router-dom';
import { emptyGuid } from '../../../../helpers/common';
import {
  Select,
  Modal,
  Typography as AntTypography,
  Divider,
  Switch,
} from 'antd';
import { Button as AntdButton } from 'antd';
import './styles.css';
import {
  FlagFilled,
  CopyFilled,
  FundFilled,
  EyeFilled,
  DeleteFilled,
} from '@ant-design/icons';
import { GiBrain } from 'react-icons/gi';
import useGetProcesses from '../../../../api-hooks/process/useGetProcesses';
import { useQueryClient } from '@tanstack/react-query';
import { FolderContext } from '../../../../contexts/folder';

const { Option } = Select;
const { Text, Title } = AntTypography;

const styles: any = () => ({
  feedbackForm: {
    boxShadow: 'none',
  },
  panelSummary: {
    marginTop: 10,
    padding: '0 10px',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '& > div': {
      alignItems: 'center',
    },
  },
  expansionSummary: {
    flexDirection: 'row',
    paddingLeft: 7,
  },
  titles: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  LinkedToAccount: {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
});

const AdvancedSettings = (props) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    setSelectedDoc,
    setDocumentTopics,
    setSelectedVersion,
    setSelectedLanguage,
  } = useContext(DocumentContext);
  const { selectedFolderId } = useContext(FolderContext);

  const [linkedToAccount, setLinkedToAccount] = useState<boolean>(true);
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState<boolean>(false);
  const [isDocumentExportEnabled, setIsDocumentExportEnabled] =
    useState<boolean>(false);
  const [showAISearchResult, setShowAISearchResult] = useState<boolean>(false);
  const [isUsingAccountDataForFeedback, setIsUsingAccountDataForFeedback] =
    useState<boolean>(true);
  const [
    isUsingAccountDataForDocumentExport,
    setIsUsingAccountDataForDocumentExport,
  ] = useState<boolean>(true);
  const [
    isUsingAccountDataForAISearchResult,
    setIsUsingAccountDataForAISearchResult,
  ] = useState<boolean>(true);
  const [firstModel, setFirstModel] = useState(null);
  const [openAntdModal, setOpenAntdModal] = useState<boolean>(false);

  const { processes } = useGetProcesses({
    accountId: props.model.accountId,
    enabled: Boolean(!firstModel && props.model && props.selectedDoc),
  });

  const documentService = new DocumentService();

  useEffect(() => {}, []);

  const history = useHistory();

  useEffect(
    () => {
      if (!firstModel && props.model && props.selectedDoc) {
        setFirstModel({ ...props.model });

        editForm();
      }
    },
    // eslint-disable-next-line
    [props.selectedDoc, props.model]
  );

  const editForm = () => {
    const docData = props.selectedDoc;
    // setSubscriptionStatus(docData.account.stripeSubscriptionStatus);
    setFirstModel({ ...docData });
    setLinkedToAccount(!!docData.linkedToAccount);

    if (docData.isFeedbackEnabled === null) {
      setIsFeedbackEnabled(docData.account.isFeedbackEnabled);
      setIsUsingAccountDataForFeedback(true);
    } else {
      setIsFeedbackEnabled(docData.isFeedbackEnabled);
      setIsUsingAccountDataForFeedback(false);
    }

    if (docData.isDocumentExportEnabled === null) {
      setIsDocumentExportEnabled(docData.account.isDocumentExportEnabled);
      setIsUsingAccountDataForDocumentExport(true);
    } else {
      setIsDocumentExportEnabled(docData.isDocumentExportEnabled);
      setIsUsingAccountDataForDocumentExport(false);
    }

    if (docData.showAISearchResult === null) {
      setShowAISearchResult(docData.account.showAISearchResult);
      setIsUsingAccountDataForAISearchResult(true);
    } else {
      setShowAISearchResult(docData.showAISearchResult);
      setIsUsingAccountDataForAISearchResult(false);
    }

    props.setLoading(false);
  };

  const handleLinkToAccountChange = () => {
    const newModel = props.model;
    newModel.linkedToAccount = !linkedToAccount;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
    setLinkedToAccount(!linkedToAccount);
  };

  const handleChangeDocumentExportEnabled = (value) => {
    const newModel = props.model;
    newModel.isDocumentExportEnabled = value === 0 ? null : value === 1;
    setIsUsingAccountDataForDocumentExport(value === 0);
    setIsDocumentExportEnabled(
      value === 0 ? newModel.account.isDocumentExportEnabled : value === 1
    );
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeShowAISearchResult = (value) => {
    const newModel = props.model;
    newModel.showAISearchResult = value === 0 ? null : value === 1;
    setIsUsingAccountDataForAISearchResult(value === 0);
    setShowAISearchResult(
      value === 0 ? newModel.account.showAISearchResult : value === 1
    );
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeFeedbackEnabled = (value) => {
    const newModel = props.model;
    newModel.isFeedbackEnabled = value === 0 ? null : value === 1;
    setIsUsingAccountDataForFeedback(value === 0);
    setIsFeedbackEnabled(
      value === 0 ? newModel.account.isFeedbackEnabled : value === 1
    );
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleProcessChanged = (processId) => {
    const newModel = props.model;
    newModel.processId = processId === '-1' || !processId ? null : processId;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const loadDocuments = async () => {
    props.setLoading(true);

    setSelectedDoc(null);
    setSelectedLanguage(null);
    setSelectedVersion(null);
    setDocumentTopics([]);

    await queryClient.invalidateQueries({ queryKey: ['documents', true] });
    await queryClient.invalidateQueries({
      queryKey: selectedFolderId
        ? ['documents', false, props.model.accountId, selectedFolderId]
        : ['documents', false, props.model.accountId],
    });

    history.push('/');

    props.setLoading(false);
  };

  const handleCloseAntdModal = () => {
    setOpenAntdModal(false);
  };

  const handleRemove = (callback) => {
    callback();
    props.setLoading(true);
    documentService
      .removeDocument(props.selectedDoc.id)
      .then(() => {
        loadDocuments();
      })
      .catch((error) => {
        props.setLoading(false);
        props.enqueueSnackbar(
          error.statusText
            ? t(`error.${localizeKey(error.statusText)}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
  };

  const getOrgProcessTitle = () => {
    const process = processes?.find(
      (process) => process.id === props.selectedDoc?.account.processId
    );
    if (process) {
      return process.title;
    }
    return t('without-process');
  };

  const generateProcessOption = (process) => {
    return <Option value={process.id}>{process.title}</Option>;
  };

  const renderForm = () => {
    return (
      <div>
        <div
          style={{
            border: '1px solid #f0f0f0',
            borderRadius: '5px',
            padding: '10px',
          }}
        >
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ width: '10%' }}>
              <FlagFilled
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Title className="settings-item-title" level={4}>
                {t('feedback')}
              </Title>
              <Text>{t('document-feedback-help')}</Text>
              <Select
                defaultValue={0}
                style={{ width: 350, marginTop: 10, display: 'block' }}
                onChange={handleChangeFeedbackEnabled}
                value={
                  isUsingAccountDataForFeedback ? 0 : isFeedbackEnabled ? 1 : 2
                }
              >
                <Option value={0}>
                  {t('use-account-settings') +
                    ' (' +
                    (props.model.account.isFeedbackEnabled
                      ? t('enable-feedback')
                      : t('disable-feedback')) +
                    ')'}
                </Option>
                <Option value={1}>{t('enable-feedback')}</Option>
                <Option value={2}>{t('disable-feedback')}</Option>
              </Select>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <CopyFilled
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%', display: 'grid' }}>
              <Title className="settings-item-title" level={4}>
                {t('document-export')}
              </Title>
              <Text>{t('document-export-help')}</Text>
              <Select
                defaultValue={0}
                style={{ width: 350, marginTop: 10 }}
                onChange={handleChangeDocumentExportEnabled}
                value={
                  isUsingAccountDataForDocumentExport
                    ? 0
                    : isDocumentExportEnabled
                      ? 1
                      : 2
                }
              >
                <Option value={0}>
                  {t('use-account-settings') +
                    ' (' +
                    (props.model.account.isDocumentExportEnabled
                      ? t('enable-document-export')
                      : t('disable-document-export')) +
                    ')'}
                </Option>
                <Option value={1}>{t('enable-document-export')}</Option>
                <Option value={2}>{t('disable-document-export')}</Option>
              </Select>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <GiBrain
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%', display: 'grid' }}>
              <Title className="settings-item-title" level={4}>
                {t('ai-search-result')}
              </Title>
              <Text>{t('ai-search-result-help')}</Text>
              <Select
                defaultValue={0}
                style={{ width: 350, marginTop: 10 }}
                onChange={handleChangeShowAISearchResult}
                value={
                  isUsingAccountDataForAISearchResult
                    ? 0
                    : showAISearchResult
                      ? 1
                      : 2
                }
              >
                <Option value={0}>
                  {t('use-account-settings') +
                    ' (' +
                    (props.model.account.showAISearchResult
                      ? t('enable-ai-search-result')
                      : t('disable-ai-search-result')) +
                    ')'}
                </Option>
                <Option value={1}>{t('enable-ai-search-result')}</Option>
                <Option value={2}>{t('disable-ai-search-result')}</Option>
              </Select>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <FundFilled
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Title className="settings-item-title" level={4}>
                {t('select-process')}
              </Title>
              <Text style={{ display: 'block' }}>
                {t('select-process-description')}
              </Text>
              <Select
                style={{ width: 350, marginTop: 10 }}
                onChange={handleProcessChanged}
                value={
                  props.model.processId === null ? '-1' : props.model.processId
                }
              >
                <Option value={emptyGuid()}>{t('without-process')}</Option>
                {processes?.map(generateProcessOption)}
                <Option value={'-1'}>
                  {t('use-account-settings') + ` (${getOrgProcessTitle()})`}
                </Option>
              </Select>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <EyeFilled
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Title className="settings-item-title" level={4}>
                {t('link-to-account')}
              </Title>
              <Text style={{ display: 'block' }}>
                {t('link-to-account-help')}
              </Text>
              <Switch
                checked={linkedToAccount}
                style={{ marginTop: 10 }}
                onChange={handleLinkToAccountChange}
              />
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <DeleteFilled
                style={{ fontSize: '30px', color: '#ff4d4f', marginLeft: 10 }}
              />
            </div>
            <div style={{ width: '90%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title className="settings-item-title" level={4}>
                  {t('delete-document')}
                </Title>
                <AntdButton
                  type="primary"
                  danger
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setOpenAntdModal(true);
                  }}
                >
                  {t('rm')}
                </AntdButton>
              </div>

              <Text strong style={{ display: 'block', width: '80%' }}>
                {t('delete-document-text')} {t('delete-document-final-text')}
              </Text>
            </div>
          </div>
        </div>

        <Modal
          title={t('delete-document')}
          open={openAntdModal}
          onCancel={handleCloseAntdModal}
          footer={[
            <Button key="Cancel" onClick={handleCloseAntdModal}>
              {t('cancel')}
            </Button>,
            <AntdButton
              id="delete-button-confirm"
              onClick={() => {
                handleRemove(handleCloseAntdModal);
              }}
              key="Delete"
              type="primary"
              danger
            >
              {t('rm')}
            </AntdButton>,
          ]}
        >
          <p>{t('last-confirmation-delete-document')}</p>
        </Modal>
      </div>
    );
  };

  const contents =
    props.loading || !props.model ? (
      <div style={{ position: 'relative' }}>
        <CircularProgress style={{ marginLeft: '50%', marginTop: '20px' }} />
      </div>
    ) : (
      renderForm()
    );

  return <div>{contents}</div>;
};

export default withStyles(styles)(withSnackbar(AdvancedSettings));
