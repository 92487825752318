import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MoveTopicRequest } from '../../api-schemas/topic';

const moveTopicRequest = (data: MoveTopicRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Topic/Move', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useMoveTopic({
  documentVersionId,
  languageCode,
}: {
  documentVersionId: string;
  languageCode: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: moveTopic,
    isPending: isMovingTopic,
    isSuccess: isSuccessMovingTopic,
  } = useMutation({
    mutationFn: moveTopicRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draftTopicTree', documentVersionId, languageCode],
      });
    },
  });

  return {
    moveTopic,
    isMovingTopic,
    isSuccessMovingTopic,
  };
}
