import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

type ImagePreviewModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  imageUrl: string;
};

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  isOpen,
  onRequestClose,
  imageUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t('image-preview')}
      style={{
        overlay: {
          zIndex: 1000, // Ensure the overlay is on top of other elements
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
          top: '10%',
          left: '10%',
          right: '10%',
          bottom: '10%',
          padding: '0',
          maxWidth: '80%',
          maxHeight: '80%',
          margin: 'auto',
          overflow: 'hidden', // Prevent scrolling
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <img
          src={imageUrl}
          alt="Is Loading..."
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
        <button
          onClick={onRequestClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 10px',
          }}
        >
          {t('close')}
        </button>
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
