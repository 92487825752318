import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { TopicLinksData } from '../../api-schemas/reference-links';

const getSourceTopicLinksRequest = ({
  topicId,
  languageCode,
}: {
  topicId?: string;
  languageCode?: string;
}) => {
  return axios
    .get<TopicLinksData>(`/api/content-processor/topic-link?sourceTopicId=${topicId}&sourceTopicLang=${languageCode}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetSourceTopicLinks({
  topicId,
  languageCode,
}: {
  topicId?: string;
  languageCode?: string;
}) {
  const {
    data,
    refetch: getSourceTopicLinks,
    isFetching: isLoadingSourceTopicLinks,
    isRefetching: isRefetchingSourceTopicLinks,
    isError: isErrorLoadingSourceTopicLinks,
    isSuccess: isSuccessLoadingSourceTopicLinks,
  } = useQuery<TopicLinksData>({
    staleTime: 0,
    queryKey: ['SourceTopicLinks', topicId, languageCode],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(topicId),
    queryFn: () => getSourceTopicLinksRequest({ topicId, languageCode }),
  });

  const SourceTopicLinks = useMemo(() => data, [data]);

  return {
    SourceTopicLinks,
    isLoadingSourceTopicLinks,
    isRefetchingSourceTopicLinks,
    isErrorLoadingSourceTopicLinks,
    isSuccessLoadingSourceTopicLinks,

    getSourceTopicLinks,
  };
}
