import React, { Fragment, useContext, useRef, useState } from 'react';
import {
  EditRounded,
  HistoryRounded,
  SettingsRounded,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  Popover,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from '@mui/material';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { MdPreview } from 'react-icons/md';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import config from 'react-global-configuration';

import { changeRouteBaseOnEditContentMenues } from '../../../../../helpers/route';
import { DocumentContext } from '../../../../../contexts/document';
import ViewLiveButton from '../../../../viewLive';
import { TopicContext } from '../../../../../contexts/topic';
import { getTopicLink } from '../../../../../helpers/common';
import useSyncGoogleDocTopic from '../../../../../api-hooks/topic/useSyncGoogleDocTopic';
import useGetItemType from '../../../../../api-hooks/folder/useGetItemType';
import { AccountContext } from '../../../../../contexts/account';

const MoreButton = ({
  selectedTab,
  setSelectedTab,
  isLanguageEqual,
  setShowBackButton,
  isAlreadyPublished,
  draftTopicTree,
}) => {
  const [menuOpen, setMenoOpen] = useState<any>(null);
  const open = Boolean(menuOpen);
  const { t } = useTranslation();

  const { selectedAccount } = useContext(AccountContext);
  const { selectedDoc, selectedLanguage, selectedVersion } =
    useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const history = useHistory();
  const { hash: selectedSideSection, search } = useLocation();
  const { syncGoogleDocTopic } = useSyncGoogleDocTopic();
  const { getItemType } = useGetItemType(selectedTopic?.id);

  const isDoclessTopic = useRef<boolean>(
    Boolean(selectedTopic && !selectedTopic.documentId)
  );

  const handleClick = (event) => {
    setMenoOpen(event.currentTarget);
  };

  const handleClose = (value: string, sidePartItem: string | null = null) => {
    const returnedResult = changeRouteBaseOnEditContentMenues(
      value,
      selectedTopic?.topicId as string
    );

    if (
      value === 'Settings' ||
      value === 'History' ||
      value === 'Feedback' ||
      value === 'RefLinks'
    ) {
      setShowBackButton(Boolean(returnedResult[0]));
    } else {
      setShowBackButton(false);
    }

    setSelectedTab(value);
    setMenoOpen(null);

    if (sidePartItem) {
      history.push({
        hash: sidePartItem,
        search,
      });
    } else {
      history.push(`${returnedResult[1]}`);
    }
  };

  const handlePreview = () => {
    getItemType(selectedTopic?.topicId).then((itemType) => {
      const topicLink = getTopicLink(
        selectedDoc,
        selectedTopic,
        selectedLanguage,
        false,
        isDoclessTopic.current,
        selectedAccount,
        itemType?.draftId
      );

      const newTab = window.open('', '_blank');

      if (newTab) {
        if (selectedTopic?.contentType === 'GoogleDoc') {
          syncGoogleDocTopic({
            accountId: selectedTopic.accountId,
            googleDriveIds: [selectedTopic.googleDriveId],
          }).then(() => {
            newTab.location.href = topicLink;
          });
        } else {
          newTab.location.href = topicLink;
        }
      }
    });
  };

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        style={{ padding: 0 }}
        id="topic-more-settings-button"
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        id="long-menu"
        anchorEl={menuOpen}
        keepMounted
        open={open}
        onClose={() => setMenoOpen(false)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          className="more-menu-li"
          key={'Edit'}
          onClick={() => handleClose('Edit')}
        >
          <ListItemIcon className="more-menu-icon-item">
            <EditRounded
              style={selectedTab === 'Edit' ? { color: '#1a73e8' } : {}}
            />
          </ListItemIcon>

          <ListItemText
            primary={'Edit Content'}
            style={selectedTab === 'Edit' ? { color: '#1a73e8' } : {}}
          />
        </MenuItem>

        <MenuItem
          className="more-menu-li"
          key={'History'}
          onClick={() => handleClose('History')}
          disabled={
            !isLanguageEqual || selectedTopic?.contentType === 'GoogleDoc'
          }
        >
          <ListItemIcon className="more-menu-icon-item">
            <HistoryRounded
              style={selectedTab === 'History' ? { color: '#1a73e8' } : {}}
            />
          </ListItemIcon>
          <ListItemText
            primary={'History'}
            style={selectedTab === 'History' ? { color: '#1a73e8' } : {}}
          />
        </MenuItem>

        <MenuItem
          className="more-menu-li"
          key={'Settings'}
          onClick={() => handleClose('Settings')}
          disabled={!isLanguageEqual}
          id="topic-settings"
        >
          <ListItemIcon className="more-menu-icon-item">
            <SettingsRounded
              style={selectedTab === 'Settings' ? { color: '#1a73e8' } : {}}
            />
          </ListItemIcon>

          <ListItemText
            primary={'Settings'}
            style={selectedTab === 'Settings' ? { color: '#1a73e8' } : {}}
          />
        </MenuItem>

        {/*<MenuItem*/}
        {/*  className="more-menu-li"*/}
        {/*  key={'Feedback'}*/}
        {/*  onClick={() => handleClose('Feedback')}*/}
        {/*  disabled={!isLanguageEqual}*/}
        {/*>*/}
        {/*  <ListItemIcon className="more-menu-icon-item">*/}
        {/*    <FeedbackIcon*/}
        {/*      style={selectedTab === 'Feedback' ? { color: '#1a73e8' } : {}}*/}
        {/*    />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText*/}
        {/*    primary={'Feedback'}*/}
        {/*    style={selectedTab === 'Feedback' ? { color: '#1a73e8' } : {}}*/}
        {/*  />*/}
        {/*</MenuItem>*/}

        <MenuItem
          className="more-menu-li"
          key={'Metrics'}
          onClick={() => handleClose('Metrics')}
          disabled={
            !isLanguageEqual ||
            selectedTopic?.contentType === 'Component' ||
            !selectedTopic?.metrics
          }
        >
          <ListItemIcon className="more-menu-icon-item">
            <AssessmentIcon
              style={selectedTab === 'Metrics' ? { color: '#1a73e8' } : {}}
            />
          </ListItemIcon>

          <ListItemText
            primary={'Metrics'}
            style={selectedTab === 'Metrics' ? { color: '#1a73e8' } : {}}
          />
        </MenuItem>

        {config.get('environment') === 'stage' && (
          <MenuItem
            className="more-menu-li"
            key={'RefLinks'}
            onClick={() => handleClose('RefLinks')}
            disabled={!isLanguageEqual}
          >
            <ListItemIcon className="more-menu-icon-item">
              <LinkOutlined
                style={selectedTab === 'RefLinks' ? { color: '#1a73e8' } : {}}
              />
            </ListItemIcon>
            <ListItemText
              primary={'Reference Links'}
              style={selectedTab === 'RefLinks' ? { color: '#1a73e8' } : {}}
            />
          </MenuItem>
        )}

        <MenuItem
          className="more-menu-li show-on-large"
          key={'preview'}
          onClick={handlePreview}
          disabled={!isLanguageEqual}
          style={{ display: 'none' }}
        >
          <ListItemIcon className="more-menu-icon-item">
            <MdPreview style={{ fontSize: 24 }} />
          </ListItemIcon>

          <ListItemText primary={'preview'} />
        </MenuItem>

        <ViewLiveButton
          key="View Live"
          document={selectedDoc}
          selectedVersion={selectedVersion}
          selectedLanguage={selectedLanguage}
          selectedTab={selectedTab}
          topics={draftTopicTree}
          isAlreadyPublished={isAlreadyPublished}
          topicViewLive={true}
          disabled={!isLanguageEqual}
        />

        <Divider />

        <MenuItem
          className="more-menu-li"
          key={'Comments'}
          onClick={() => handleClose('Edit', 'comments')}
          disabled={!isLanguageEqual}
        >
          <ListItemIcon className="more-menu-icon-item">
            <ChatIcon
              style={
                selectedSideSection === 'comments' ? { color: '#1a73e8' } : {}
              }
            />
          </ListItemIcon>

          <ListItemText
            primary={'Comments'}
            style={
              selectedSideSection === 'comments' ? { color: '#1a73e8' } : {}
            }
          />
        </MenuItem>

        {selectedTopic?.contentType === 'Component' ? (
          <MenuItem
            className="more-menu-li"
            key={'Components'}
            onClick={() => handleClose('Edit', 'components')}
            disabled={!isLanguageEqual}
          >
            <ListItemIcon className="more-menu-icon-item">
              <ViewCompactIcon
                style={
                  selectedSideSection === 'components'
                    ? { color: '#1a73e8' }
                    : {}
                }
              />
            </ListItemIcon>

            <ListItemText
              primary={'Components'}
              style={
                selectedSideSection === 'components' ? { color: '#1a73e8' } : {}
              }
            />
          </MenuItem>
        ) : !selectedTopic || !selectedTopic.metrics ? (
          <Fragment>
            <Tooltip title={t('metrics-not-available')} arrow>
              <div>
                <MenuItem
                  className="more-menu-li"
                  key={'SEO'}
                  onClick={() => handleClose('Edit', 'SEO')}
                  disabled={true}
                >
                  <ListItemIcon className="more-menu-icon-item">
                    <SearchIcon
                      style={
                        selectedSideSection === 'SEO'
                          ? { color: '#1a73e8' }
                          : {}
                      }
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={'SEO'}
                    style={
                      selectedSideSection === 'SEO' ? { color: '#1a73e8' } : {}
                    }
                  />
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip title={t('metrics-not-available')} arrow>
              <div>
                <MenuItem
                  className="more-menu-li"
                  key={'Readability'}
                  onClick={() => handleClose('Edit', 'readability')}
                  disabled={true}
                >
                  <ListItemIcon className="more-menu-icon-item">
                    <AssignmentTurnedInIcon
                      style={
                        selectedSideSection === 'readability'
                          ? { color: '#1a73e8' }
                          : {}
                      }
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={'Readability'}
                    style={
                      selectedSideSection === 'readability'
                        ? { color: '#1a73e8' }
                        : {}
                    }
                  />
                </MenuItem>
              </div>
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment>
            <MenuItem
              className="more-menu-li"
              key={'SEO'}
              onClick={() => handleClose('Edit', 'SEO')}
              disabled={!isLanguageEqual}
            >
              <ListItemIcon className="more-menu-icon-item">
                <SearchIcon
                  style={
                    selectedSideSection === 'SEO' ? { color: '#1a73e8' } : {}
                  }
                />
              </ListItemIcon>

              <ListItemText
                primary={'SEO'}
                style={
                  selectedSideSection === 'SEO' ? { color: '#1a73e8' } : {}
                }
              />
            </MenuItem>

            <MenuItem
              className="more-menu-li"
              key={'Readability'}
              onClick={() => handleClose('Edit', 'readability')}
              disabled={!isLanguageEqual}
            >
              <ListItemIcon className="more-menu-icon-item">
                <AssignmentTurnedInIcon
                  style={
                    selectedSideSection === 'readability'
                      ? { color: '#1a73e8' }
                      : {}
                  }
                />
              </ListItemIcon>

              <ListItemText
                primary={'Readability'}
                style={
                  selectedSideSection === 'readability'
                    ? { color: '#1a73e8' }
                    : {}
                }
              />
            </MenuItem>
          </Fragment>
        )}
      </Popover>
    </Fragment>
  );
};

export default MoreButton;
