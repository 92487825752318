import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import usePostDriveAuth from '../../../api-hooks/google-auth/usePostDriveAuth';
import { TokenDataSchema } from '../../../api-schemas/google-auth';
import { AccountContext } from '../../../contexts/account';

const OAuth2Callback: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedAccount } = useContext(AccountContext);

  const [tokenData, setTokenData] = useState<TokenDataSchema | null>(null);

  const { postDriveAuth, isSuccessLoadingDriveAuth } = usePostDriveAuth({
    accountId: selectedAccount ? selectedAccount.id : '',
    tokenData: tokenData as TokenDataSchema,
  });

  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');

      if (code && selectedAccount) {
        try {
          const createdTokenObject: TokenDataSchema = {
            code: code,
          };

          setTokenData(createdTokenObject);
        } catch (error) {
          enqueueSnackbar(t('error.get-tokens'), {
            variant: 'error',
          });
        }
      }
    };

    fetchToken();
  }, [location.search, selectedAccount]);

  useEffect(() => {
    if (tokenData && selectedAccount) {
      postDriveAuth();
    }
  }, [tokenData, selectedAccount, postDriveAuth]);

  useEffect(() => {
    if (isSuccessLoadingDriveAuth) {
      window.location.assign('/');
    }
  }, [isSuccessLoadingDriveAuth, history]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'top',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <>
        <Typography variant="h5" sx={{ mb: 8, mt: 10 }}>
          {t('google-Drive-auth')}
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('redirecting')}
        </Typography>
        <CircularProgress />
      </>
    </Box>
  );
};

export default OAuth2Callback;
