import React from 'react';
import { List } from 'antd';

const HelpTexts = ({ data }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      size="small"
      renderItem={(item: any) => (
        <List.Item>
          <List.Item.Meta
            style={{ fontSize: 13 }}
            description={item.description}
          />
        </List.Item>
      )}
    />
  );
};

export default HelpTexts;
