import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Grid,
  InputBase,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import config from 'react-global-configuration';
import { MdDomainVerification } from 'react-icons/md';
import { Typography as AntTypography, Button, Col } from 'antd';

import TextInput from '../../../inputs/text-input';
import useValidateCustomDomainCName from '../../../../api-hooks/custom-domain/useValidateCustomDomainCName';

import './styles.css';

const { Text, Title } = AntTypography;

const styles: any = (theme: Theme) => ({
  mainContent: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  textField: {
    marginRight: '10px',
    width: 'calc(50% - 10px)',
    marginBottom: 0,
  },
  button: {
    minWidth: '60px',
    marginRight: '10px',
  },
  analyticsForm: {
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 0,
    '& > div': {
      alignItems: 'center',
    },
  },
  errorBox: {
    color: 'red',
    marginBottom: '10px',
  },
  helpBox: {
    fontSize: '12px',
    margin: '10px 0',
    color: '#726e6e',
  },
  stepBox: {
    border: '1px solid #8080803d',
    padding: '10px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    height: '300px',
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 5px 10px 5px',
    },
    flexDirection: 'column!important',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '50px',
  },
  numberBox: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#808080ad',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  CNAMEBox: {
    width: '100%',
    border: '1px solid #1a73e8',
    display: 'flex',
    padding: '0 4px',
    alignItems: 'center',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  successBox: {
    padding: '10px 0',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fontSize: '12px',
    color: 'rgb(38, 203, 124)',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    flexDirection: 'column!important',
  },
  inputText: {
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
});

function AccountCustomDomainSettings(props: any) {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const [customDomain, setCustomDomain] = useState<string>('');
  const [accountConfiguration, setAccountConfiguration] = useState<any>({});

  const [CNAMELoading, setCNAMELoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);

  const { validateCName } = useValidateCustomDomainCName(customDomain);

  useEffect(() => {
    (document.getElementById('custom-domain-error') as HTMLElement).innerText =
      '';
    (document.getElementById('CNAME-error') as HTMLElement).innerText = '';
    setShowRefreshButton(false);
    if (props.accountConfiguration.customDomain !== null) {
      setCustomDomain(props.accountConfiguration.customDomain);
    } else if (
      (props.accountConfiguration.id === accountConfiguration.id &&
        customDomain !== '') ||
      props.accountConfiguration.id !== accountConfiguration.id
    ) {
      setCustomDomain('');
    }
    setAccountConfiguration(props.accountConfiguration);
    // eslint-disable-next-line
  }, [props.accountConfiguration]);

  const handleCustomDomainChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCustomDomain(event.target.value);
  };

  const raiseAccountConfigurationChanged = async (
    accountConfiguration: any,
    saveAccount: boolean
  ) => {
    if (props.onAccountConfigurationChanged)
      await props.onAccountConfigurationChanged(
        accountConfiguration,
        saveAccount
      );
  };

  const handleSave = async (incomingCustomDomain: string | null = null) => {
    setLoading(true);
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );
    newAccountConfiguration.customDomain =
      incomingCustomDomain === '' ? incomingCustomDomain : customDomain;
    await raiseAccountConfigurationChanged(newAccountConfiguration, true);
    setLoading(false);
  };

  const copyCNAME = () => {
    const input: any = document.getElementById('CNAME');
    input.select();
    document.execCommand('copy');
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  const checkCustomDomain = () => {
    const customDomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;
    if (
      customDomain.split('.').length === 3 &&
      customDomainRegex.test(customDomain)
    ) {
      (
        document.getElementById('custom-domain-error') as HTMLElement
      ).innerText = '';
      activeBox(2);
    } else {
      (
        document.getElementById('custom-domain-error') as HTMLElement
      ).innerText = t('error.custom-domain');
    }
  };

  const checkCNAME = () => {
    setCNAMELoading(true);
    validateCName()
      .then((result) => {
        setCNAMELoading(false);
        if (result.data?.backendVersion) {
          activeBox(3);

          (document.getElementById('CNAME-error') as HTMLElement).innerText =
            '';

          setShowRefreshButton(false);
        } else {
          (document.getElementById('CNAME-error') as HTMLElement).innerText =
            t('error.CNAME');

          setShowRefreshButton(true);
        }
      })
      .catch(() => {
        setCNAMELoading(false);
        (document.getElementById('CNAME-error') as HTMLElement).innerText =
          t('error.CNAME');
        setShowRefreshButton(true);
      });
  };

  const activeBox = (boxNumber: number) => {
    document.getElementsByClassName('active')[0].classList.remove('active');
    document.getElementsByClassName('deactive')[0].classList.remove('deactive');
    document.getElementsByClassName('deactive')[0].classList.remove('deactive');
    switch (boxNumber) {
      case 1:
        (document.getElementById('firstStep') as HTMLElement).classList.add(
          'active'
        );
        (document.getElementById('secondStep') as HTMLElement).classList.add(
          'deactive'
        );
        (document.getElementById('thirdStep') as HTMLElement).classList.add(
          'deactive'
        );
        break;
      case 2:
        (document.getElementById('secondStep') as HTMLElement).classList.add(
          'active'
        );
        (document.getElementById('firstStep') as HTMLElement).classList.add(
          'deactive'
        );
        (document.getElementById('thirdStep') as HTMLElement).classList.add(
          'deactive'
        );
        break;
      case 3:
        (document.getElementById('thirdStep') as HTMLElement).classList.add(
          'active'
        );
        (document.getElementById('secondStep') as HTMLElement).classList.add(
          'deactive'
        );
        (document.getElementById('firstStep') as HTMLElement).classList.add(
          'deactive'
        );
        break;
      default:
        break;
    }
  };

  const deleteCustomDomain = async () => {
    setDeleteLoading(true);
    await handleSave('');
    setDeleteLoading(false);
  };

  const classes = props.classes;

  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.mainContent}>
        <div style={{ borderRadius: '5px', padding: '10px' }}>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ width: '10%' }}>
              <MdDomainVerification
                style={{ fontSize: '36px', color: '#1a73e8', marginLeft: 14 }}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Title className="settings-item-title" level={4}>
                {t('set-costum-domain')}
              </Title>
              <Text>{t('costum-domain-help')}</Text>
              <div style={{ display: 'flex', marginTop: 15 }}>
                <Col md={8} xs={24}>
                  <Col
                    xs={24}
                    className={classes.stepBox + ' active'}
                    id="firstStep"
                  >
                    <Col xs={24} className={classes.titleBox}>
                      <span className={classes.numberBox}>1</span>
                      <span>{t('connect-custom-domain')}</span>
                    </Col>
                    <Grid className={classes.content}>
                      <TextInput
                        inputStyle={{ width: '100%' }}
                        style={{ width: '100%' }}
                        field="custom_domain"
                        required={true}
                        onChange={handleCustomDomainChange}
                        value={customDomain}
                        placeholder={t('custom-domain-title')}
                        showError={false}
                      />
                      <span className={classes.helpBox}>
                        {t('domain-help')}
                      </span>
                      <span
                        className={classes.errorBox}
                        id="custom-domain-error"
                      ></span>
                    </Grid>
                    <Box>
                      {(!!customDomain || deleteLoading) && (
                        <Button
                          className={classes.button + ' button'}
                          onClick={deleteCustomDomain}
                        >
                          {deleteLoading ? (
                            <CircularProgress
                              style={{ width: '25px', height: '25px' }}
                            />
                          ) : (
                            t('rm')
                          )}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        className={classes.button + ' button'}
                        onClick={() => checkCustomDomain()}
                      >
                        {t('next')}
                      </Button>
                    </Box>
                  </Col>
                </Col>
                <Col md={8} xs={24}>
                  <Col
                    xs={24}
                    className={classes.stepBox + ' deactive'}
                    id="secondStep"
                  >
                    <Col xs={24} className={classes.titleBox}>
                      <span className={classes.numberBox}>2</span>
                      <span>{t('configure-dns')}</span>
                    </Col>
                    <Grid className={classes.content}>
                      {CNAMELoading ? (
                        <CircularProgress />
                      ) : (
                        <span style={{ display: 'contents' }}>
                          <span className={classes.helpBox}>
                            {t('CNAME-help')}
                          </span>
                          <Box
                            className={classes.CNAMEBox}
                            style={{ height: 35 }}
                          >
                            <InputBase
                              className="input-base"
                              value={config.get('CNAME')}
                              inputProps={{ 'aria-label': 'viewer URL' }}
                              id="CNAME"
                              autoFocus
                              readOnly
                            />
                            <div>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied"
                              >
                                <IconButton
                                  type="button"
                                  onClick={() => copyCNAME()}
                                  className={classes.iconButton}
                                  aria-label="search"
                                >
                                  <FileCopyIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Box>
                          <span
                            className={classes.errorBox}
                            style={{
                              fontSize: '12px',
                              marginBottom: 0,
                              textAlign: 'left',
                            }}
                            id="CNAME-error"
                          ></span>
                          {showRefreshButton ? (
                            <Button
                              type="primary"
                              style={{ marginBottom: '10px' }}
                              onClick={() => checkCNAME()}
                            >
                              {t('refresh')}
                            </Button>
                          ) : null}
                        </span>
                      )}
                    </Grid>
                    <Box>
                      <Button
                        className={classes.button + ' button'}
                        onClick={() => activeBox(1)}
                      >
                        {t('prev')}
                      </Button>
                      <Button
                        type="primary"
                        className={classes.button + ' button'}
                        onClick={() => checkCNAME()}
                      >
                        {t('next')}
                      </Button>
                    </Box>
                  </Col>
                </Col>
                <Col md={8} xs={24}>
                  <Col
                    xs={24}
                    className={classes.stepBox + ' deactive'}
                    id="thirdStep"
                  >
                    <Col xs={24} className={classes.titleBox}>
                      <span className={classes.numberBox}>3</span>
                      <span>{t('ready')}</span>
                    </Col>
                    <Grid className={classes.content}>
                      <Grid className={classes.successBox}>
                        <span>
                          <DoneIcon /> {t('custom-domain-success-message')}
                        </span>
                      </Grid>
                    </Grid>
                    <Box>
                      <Button
                        className={classes.button + ' button'}
                        onClick={() => activeBox(2)}
                      >
                        {t('prev')}
                      </Button>
                      <Button
                        type="primary"
                        className={classes.button + ' button'}
                        onClick={() => handleSave()}
                      >
                        {loading ? (
                          <CircularProgress
                            style={{
                              width: '25px',
                              height: '25px',
                              color: '#fff',
                            }}
                          />
                        ) : (
                          t('save')
                        )}
                      </Button>
                    </Box>
                  </Col>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(AccountCustomDomainSettings);
