import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import { Button, Col, Modal, Row, Skeleton } from 'antd';

import ComponentSelectorBox from './component-selector-box';
import ContactUs from '../contact-us';
import { Draggable } from '../topic/component-editor/draggable';
import PreviewSection from '../topic/component-editor/preview-section';
import useGetComponents from '../../api-hooks/component/useGetComponents';

import './index.css';

const ComponentSelector = ({
  onSelectComponent,
  isDraggable = true,
}: {
  onSelectComponent?: (content: string, component: any) => void;
  isDraggable?: boolean;
}) => {
  const [openContactUsDialog, setOpenContactUsDialog] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);

  const { t } = useTranslation();

  const { components, isLoadingComponents } = useGetComponents();

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        handleScroll();
      },
      false
    );
    // eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    const navbar = document.querySelector<HTMLElement>('#navbar');
    if (navbar) {
      const topOffset = window.scrollY;
      const topSectionHeight = navbar.offsetHeight;
      const componentSelectorElement = document.querySelector<HTMLElement>(
        '#component-selector'
      );

      if (componentSelectorElement) {
        if (topOffset > topSectionHeight) {
          componentSelectorElement.style.top = '15px';
        } else {
          componentSelectorElement.style.top = '150px';
        }
      }
    }
  };

  if (isLoadingComponents) {
    return (
      <div>
        {new Array(6).fill(0).map((_, index) => {
          return (
            <div
              key={index}
              style={{
                border: '1px solid #f0f0f0',
                padding: 10,
                margin: '10px 0',
              }}
            >
              <Skeleton active />
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <Row
        style={{ paddingTop: 8, margin: 0 }}
        gutter={16}
        id={isDraggable ? 'component-selector' : ''}
      >
        {isDraggable ? (
          <Droppable
            droppableId="component-selector"
            isDropDisabled={true}
            isCombineEnabled={true}
            direction="horizontal"
          >
            {(provided) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: 'inherit',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {components
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    .map((component, index) => {
                      return (
                        <Draggable
                          key={component.id}
                          id={component.id}
                          index={index}
                          data={component}
                        >
                          <ComponentSelectorBox
                            component={component}
                            setSelectedComponent={setSelectedComponent}
                            onSelectComponent={onSelectComponent}
                          />
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        ) : (
          components
            ?.sort((a, b) => a.title.localeCompare(b.title))
            .map((component, index) => {
              return (
                <Col key={index} span={12} style={{ marginBottom: 10 }}>
                  <ComponentSelectorBox
                    key={component.id}
                    component={component}
                    setSelectedComponent={setSelectedComponent}
                    onSelectComponent={onSelectComponent}
                  />
                </Col>
              );
            })
        )}
        <Button
          style={{
            width: 'calc(100% - 40px)',
            marginLeft: 8,
            marginRight: 8,
            color: 'rgba(0, 0, 0, 0.54)',
            borderColor: '#f0f0f0',
          }}
          onClick={() => setOpenContactUsDialog(true)}
        >
          {t("can't-find-component")}
        </Button>
        <ContactUs
          openDialog={openContactUsDialog}
          closeDialog={() => setOpenContactUsDialog(false)}
        />
        <Modal
          className="preview-component-modal"
          open={selectedComponent !== null}
          title={
            <span>
              <span>{selectedComponent?.title + ' ' + t('preview')}: </span>
              <span style={{ fontSize: 14 }}>
                {selectedComponent?.description}
              </span>
            </span>
          }
          destroyOnClose={true}
          onCancel={() => setSelectedComponent(null)}
          footer={
            <Button onClick={() => setSelectedComponent(null)}>
              {t('close')}
            </Button>
          }
        >
          <PreviewSection
            order={Math.random() * 1000}
            selectedComponentVersion={null}
            component={selectedComponent}
          />
        </Modal>
      </Row>
    );
  }
};

export default ComponentSelector;
