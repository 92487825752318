import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CropLogo from '../../../crop-logo';
import ColorPicker from './color-picker';
import { Select, Typography, Divider, Radio } from 'antd';
import { Tooltip } from 'antd';
import IconPicker from './icon-picker';
import NavbarMenuBuilder from '../../../navbar-menu-builder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PictureFilled, MoonOutlined } from '@ant-design/icons';
import WebIcon from '@mui/icons-material/Web';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './styles.css';
import { BsMenuButtonWide, BsWindow } from 'react-icons/bs';
import SelectInput from '../../../inputs/select-input';
import Preview from './preview';
import ArticlePreview from './schematic-ui/article-preview';
import KnowledgeBasePreview from './schematic-ui/knowledge-base-preview';
import TutorialPreview from './schematic-ui/tutorial-preview';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { CiShare2 } from 'react-icons/ci';

const { Option } = Select;
const { Text, Title } = Typography;

const styles: any = (Style) => ({
  paper: {
    marginTop: Style.spacing(3),
    marginBottom: Style.spacing(3),
    padding: Style.spacing(2),
    [Style.breakpoints.up(600 + parseInt(Style.spacing(3)) * 2)]: {
      marginTop: Style.spacing(6),
      marginBottom: Style.spacing(6),
      padding: Style.spacing(3),
    },
  },
  feedbackForm: {
    boxShadow: 'none',
  },
  panelSummary: {
    padding: '0 10px',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '& > div': {
      alignItems: 'center',
    },
  },
  expansionSummary: {
    flexDirection: 'row',
    paddingLeft: 7,
  },
  inputLabel: {
    marginBottom: 10,
  },
  titles: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  documentThemeConfig: {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: '5px 7px',
    marginLeft: 10,
    '& .MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 20,
    },
  },
});

const StyleSettings = (props) => {
  const { t } = useTranslation();

  const [navbarColor, setNavbarColor] = useState<string>('#fff');
  const [fontColor, setFontColor] = useState<string>('#5f6368');
  const [documentTheme, setDocumentTheme] = useState<string>('Manual');
  const [firstModel, setFirstModel] = useState<any>(null);
  const [navbarVisibilityValue, setNavbarVisibilityValue] = useState<
    string | number
  >('use-org-settings');
  const [styleValue, setStyleValue] = useState<string | number>(
    'use-org-settings'
  );
  const [darkModeVisibilityValue, setDarkModeVisibilityValue] = useState<
    string | number
  >('use-org-settings');
  const [durationToReadVisibilityValue, setDurationToReadVisibilityValue] =
    useState<string | number>('use-org-settings');
  const [shareButtonVisibilityValue, setShareButtonVisibilityValue] = useState<
    string | number
  >('use-org-settings');

  useEffect(
    () => {
      if (props.selectedDoc && props.model && !firstModel) {
        setFirstModel({ ...props.model });
        editForm();
      }
    },
    // eslint-disable-next-line
    [props.selectedDoc, props.model]
  );

  const editForm = () => {
    const docData = props.selectedDoc;
    setDocumentTheme(docData.theme ? docData.theme : 'Manual');
    props.setLoading(false);
    if (docData.colorCode) setNavbarColor(docData.colorCode);
    if (docData.fontColorCode) setFontColor(docData.fontColorCode);
    if (docData.navbarVisibility !== null)
      setNavbarVisibilityValue(docData.navbarVisibility ? 1 : 0);
    if (docData.style !== null)
      setStyleValue(docData.style ? docData.style : 'use-org-settings');
    if (docData.durationToReadVisibility !== null)
      setDurationToReadVisibilityValue(
        docData.durationToReadVisibility ? 1 : 0
      );
    if (docData.shareButtonVisibility !== null)
      setShareButtonVisibilityValue(docData.shareButtonVisibility ? 1 : 0);

    if (docData.darkModeVisibility !== null)
      setDarkModeVisibilityValue(docData.darkModeVisibility ? 1 : 0);
  };

  const handleNavbarColorChange = (color) => {
    const value: string = color.hex;
    const newModel = props.model;
    newModel.colorCode = value;
    setNavbarColor(value);
    setColor([color.rgb.r, color.rgb.g, color.rgb.b]);
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleIconChange = (icon: string) => {
    const newModel = props.model;
    newModel.iconName = icon;
    newModel.iconLibrary = 'mdi';
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleFontColorChange = (color) => {
    const value: string = color.hex;
    const newModel = props.model;
    newModel.fontColorCode = value;
    setFontColor(value);
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeNavbarVisibility = (value) => {
    const newModel = props.model;

    setNavbarVisibilityValue(value);

    newModel.navbarVisibility = value === 1 ? true : value === 0 ? false : null;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleDurationToReadVisibility = (value) => {
    const newModel = props.model;

    setDurationToReadVisibilityValue(value);

    newModel.durationToReadVisibility =
      value === 1 ? true : value === 0 ? false : null;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };
  const handleChangeShareButtonVisibility = (value) => {
    const newModel = props.model;

    setShareButtonVisibilityValue(value);

    newModel.shareButtonVisibility =
      value === 1 ? true : value === 0 ? false : null;

    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeDarkModeVisibility = (value) => {
    const newModel = props.model;

    setDarkModeVisibilityValue(value);

    newModel.darkModeVisibility =
      value === 1 ? true : value === 0 ? false : null;

    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const getforeGroundColor = (rgb) => {
    const rValue: string = rgb[0];
    const gValue: string = rgb[1];
    const bValue: string = rgb[2];
    const color = Math.round(
      (parseInt(rValue) * 299 +
        parseInt(gValue) * 587 +
        parseInt(bValue) * 114) /
        1000
    );
    const textColor: string =
      color > 225
        ? '#000000'
        : color > 200
          ? '#000000c7'
          : color > 150
            ? '#0000008c'
            : color > 125
              ? '#00000052'
              : '#ffffff';
    return textColor;
  };

  const setColor = (color) => {
    const textColor: string = getforeGroundColor(color);
    setFontColor(textColor);
  };

  const resetToDefaultColors = () => {
    setNavbarColor('#fff');
    setFontColor('#5f6368');
    const newModel = props.model;
    newModel.fontColorCode = '#5f6368';
    newModel.colorCode = '#fff';
  };

  const handleDocumentThemeChange = (value) => {
    const newModel = props.model;
    newModel.theme = value;
    setDocumentTheme(value);
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeViewerStyle = (value) => {
    const newModel = props.model;

    setStyleValue(value);

    newModel.style = value === 'use-org-settings' ? null : value;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleChangeViewerNavbarMenuBuilderConfig = (event) => {
    const newModel = props.model;
    newModel.menu =
      event.target.value === 'use-org-settings'
        ? null
        : firstModel?.menu || newModel.menu || newModel.account.menu || '';
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleNavbarDataChange = (value) => {
    const newModel = props.model;
    newModel.menu = value;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const renderForm = () => {
    return (
      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div style={{ width: '10%' }}>
            <PictureFilled
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('document-logo')}
            </Title>
            <Text>{t('document-logo-help')}</Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: 30,
              }}
            >
              <CropLogo
                //eslint-disable-next-line
                //@ts-expect-error
                ref={props.cropLogoRef}
                documentId={props.model ? props.model.id : ''}
                logoSrc={`${localStorage.webApiAddress}document/${
                  props.model ? props.model.id : ''
                }/logo?updateDateTime=${props.model?.updateDateTime}`}
                setIsLogoDeleted={props.setIsLogoDeleted}
                updateDateTime={props.model?.updateDateTime}
                newStyle={true}
                imageUrl={props.imageUrl}
                setImageUrl={props.setImageUrl}
              />
              <Divider type="vertical" style={{ height: 'auto' }} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <IconPicker
                  handleIconChange={handleIconChange}
                  model={props.model}
                />
              </div>
            </div>
            <div style={{ marginTop: 20, textAlign: 'center' }}>
              <Text
                style={{
                  fontSize: 10,
                  color: 'gray',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                {t('document-logo-note')}
              </Text>
            </div>
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <WebIcon
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: -5,
              }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('header-style')}
            </Title>
            <Text>{t('header-style-help')}</Text>
            <div style={{ marginTop: 15 }}>
              <ColorPicker
                navbarColor={navbarColor}
                fontColor={fontColor}
                handleNavbarColorChange={handleNavbarColorChange}
                handleFontColorChange={handleFontColorChange}
                resetToDefaultColors={resetToDefaultColors}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <BsWindow
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: -5,
              }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('navbar-visibility')}
            </Title>
            <Text style={{ display: 'block' }}>
              {t('navbar-visibility-help')}
            </Text>
            <SelectInput
              id="navbar-visibility-select"
              field="style"
              defaultValue="use-org-settings"
              value={navbarVisibilityValue}
              required={true}
              showError={false}
              style={{ width: 350, marginTop: 10 }}
              onChange={handleChangeNavbarVisibility}
            >
              <Option value="use-org-settings">
                {t('use-account-settings')} (
                {props.model.account.navbarVisibility === undefined ||
                props.model.account.navbarVisibility === true
                  ? t('show')
                  : t('hide')}
                )
              </Option>
              <Option value={1}>{t('show')}</Option>
              <Option value={0}>{t('hide')}</Option>
            </SelectInput>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <StyleTwoToneIcon
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: -5,
              }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('document-style')}
            </Title>
            <Text style={{ display: 'block' }}>{t('document-style-help')}</Text>
            <SelectInput
              id="viewer-style-select"
              field="style"
              defaultValue="use-org-settings"
              value={styleValue}
              required={true}
              showError={false}
              style={{ width: 350, marginTop: 10 }}
              onChange={handleChangeViewerStyle}
            >
              <Option value="use-org-settings">
                {t('use-account-settings')} (
                {props.model.account.style === 'Flat' ? t('Flat') : t('Paper')})
              </Option>
              <Option value="Flat">{t('flat-style')}</Option>
              <Option value="Paper">{t('paper-style')}</Option>
            </SelectInput>
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <AssignmentIcon
              style={{ fontSize: '36px', color: '#1a73e8', marginLeft: 6 }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('document-theme')}
            </Title>
            <Text style={{ display: 'block' }}>{t('document-theme-help')}</Text>
            <br />
            <Text>
              <strong>{t('document-theme-with-:')}</strong>{' '}
              {t('document-theme-overall-description')}
            </Text>
            <ul>
              <li>
                <Text>{t('document-theme-li-1')}</Text>
              </li>
              <li>
                <Text>{t('document-theme-li-2')}</Text>
              </li>
            </ul>
            <br />
            <Text>
              <strong>{t('knowledge-base-theme-with-:')}</strong>{' '}
              {t('knowledge-base-theme-overall-description')}
            </Text>
            <br />
            <br />
            <Text>
              <strong>{t('article-theme-with-:')}</strong>{' '}
              {t('article-theme-overall-description')}
            </Text>
            <ul>
              <li>
                <Text>{t('article-theme-li-1')}</Text>
              </li>
              <li>
                <Text>{t('article-theme-li-2')}</Text>
              </li>
            </ul>
            <br />
            <Text>
              <strong>{t('tutorial-theme-with-:')}</strong>{' '}
              {t('tutorial-theme-overall-description')}
            </Text>
            <ul>
              <li>
                <Text>{t('tutorial-theme-li-1')}</Text>
              </li>
            </ul>
            <Select
              defaultValue="Manual"
              style={{ width: 350, marginTop: 10, marginBottom: '10px' }}
              onChange={handleDocumentThemeChange}
              value={documentTheme}
            >
              <Option value="Manual">{t('document-theme')}</Option>
              <Option value="Article">{t('article-theme')}</Option>
              <Option value="KnowledgeBase">{t('knowledgeBase-theme')}</Option>
              <Option value="Tutorial">{t('tutorial-theme')}</Option>
            </Select>
            <br />
            {documentTheme === 'Manual' ? (
              <Preview
                navbarColor={props.navbarColor}
                fontColor={props.fontColor}
              />
            ) : documentTheme === 'Article' ? (
              <ArticlePreview
                navbarColor={props.navbarColor}
                fontColor={props.fontColor}
              />
            ) : documentTheme === 'KnowledgeBase' ? (
              <KnowledgeBasePreview
                navbarColor={props.navbarColor}
                fontColor={props.fontColor}
              />
            ) : documentTheme === 'Tutorial' ? (
              <TutorialPreview />
            ) : (
              ''
            )}
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <BsMenuButtonWide
              style={{ fontSize: '36px', color: '#1a73e8', marginLeft: 6 }}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Title className="settings-item-title" level={4}>
              {t('navbar-menu-builder')}
            </Title>
            <Text style={{ display: 'block' }}>
              {t('navbar-menu-builder-help')}
              <Tooltip title={t('navbar-menu-builder-help')}>
                <a
                  href="https://sonat.com/@sonat/knowledge-base/navbar-menu-builder?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HelpOutlineIcon
                    style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: 10 }}
                  />
                </a>
              </Tooltip>
            </Text>
            <Radio.Group
              style={{ marginBottom: 20, marginTop: 15 }}
              onChange={handleChangeViewerNavbarMenuBuilderConfig}
              defaultValue="use-org-settings"
              buttonStyle="solid"
              value={
                props.model.menu === null
                  ? 'use-org-settings'
                  : 'not-use-org-settings'
              }
            >
              <Radio.Button value="use-org-settings">
                {t('use-org-settings')}
              </Radio.Button>
              <Radio.Button value="not-use-org-settings">
                {t('edit-navbar-menu-settings')}
              </Radio.Button>
            </Radio.Group>
            <NavbarMenuBuilder
              showEditor={
                props.model.menu !== null && props.model.menu !== 'null'
              }
              showPreview={
                (props.model.menu && props.model.menu !== 'null') ||
                (props.model.account.menu &&
                  props.model.account.menu !== 'null')
              }
              data={
                props.model
                  ? props.model.menu === null || props.model.menu === 'null'
                    ? props.model.account.menu
                    : props.model.menu
                  : null
              }
              handleChangeData={handleNavbarDataChange}
            />
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <AiOutlineFieldTime
              style={{
                fontSize: '44px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: -5,
              }}
            />
          </div>
          <div style={{ width: '90%', marginBottom: '20px' }}>
            <Title className="settings-item-title" level={4}>
              {t('duration-to-read-visibility')}
            </Title>
            <Text style={{ display: 'block' }}>
              {t('duration-to-read-visibility-help')}
            </Text>
            <SelectInput
              id="duration-to-read-visibility-select"
              field="style"
              defaultValue="use-org-settings"
              value={durationToReadVisibilityValue}
              required={true}
              showError={false}
              style={{ width: 350, marginTop: 10 }}
              onChange={handleDurationToReadVisibility}
            >
              <Option value="use-org-settings">
                {t('use-account-settings')} (
                {props.model.account.durationToReadVisibility === undefined ||
                props.model.account.durationToReadVisibility === true
                  ? t('show')
                  : t('hide')}
                )
              </Option>
              <Option value={1}>{t('show')}</Option>
              <Option value={0}>{t('hide')}</Option>
            </SelectInput>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <CiShare2
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div style={{ width: '90%', display: 'grid', marginBottom: '20px' }}>
            <Title className="settings-item-title" level={4}>
              {t('share-button-visibility')}
            </Title>

            <Text>{t('share-button-visibility-help')}</Text>

            <SelectInput
              id="share-button-visibility-select"
              field="style"
              defaultValue="use-org-settings"
              value={shareButtonVisibilityValue}
              required={true}
              showError={false}
              style={{ width: 350, marginTop: 10 }}
              onChange={handleChangeShareButtonVisibility}
            >
              <Option value="use-org-settings">
                {t('use-account-settings')} (
                {props.model.account.shareButtonVisibility === undefined ||
                props.model.account.shareButtonVisibility === true
                  ? t('show')
                  : t('hide')}
                )
              </Option>
              <Option value={1}>{t('show')}</Option>
              <Option value={0}>{t('hide')}</Option>
            </SelectInput>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <MoonOutlined
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div style={{ width: '90%', display: 'grid', marginBottom: '20px' }}>
            <Title className="settings-item-title" level={4}>
              {t('dark-mode-visibility')}
            </Title>

            <Text>{t('dark-mode-visibility-help')}</Text>

            <SelectInput
              id="dark-mode-visibility-select"
              field="style"
              defaultValue="use-org-settings"
              value={darkModeVisibilityValue}
              required={true}
              showError={false}
              style={{ width: 350, marginTop: 10 }}
              onChange={handleChangeDarkModeVisibility}
            >
              <Option value="use-org-settings">
                {t('use-account-settings')} (
                {props.model.account.darkModeVisibilityVisibility ===
                  undefined ||
                props.model.account.darkModeVisibilityVisibility === true
                  ? t('show')
                  : t('hide')}
                )
              </Option>
              <Option value={1}>{t('show')}</Option>
              <Option value={0}>{t('hide')}</Option>
            </SelectInput>
          </div>
        </div>
      </div>
    );
  };

  const contents =
    props.loading || !props.model ? (
      <div style={{ position: 'relative' }}>
        <CircularProgress style={{ marginLeft: '50%', marginTop: '20px' }} />
      </div>
    ) : (
      renderForm()
    );

  return <div>{contents}</div>;
};

export default withStyles(styles)(withSnackbar(StyleSettings));
