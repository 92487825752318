import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const addAccountImagesRequest = ({
  accountId,
  data,
}: {
  accountId: string;
  data: FormData;
}) => {
  return axios
    .post(`/api/Account/Image/${accountId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useAddAccountImages(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: addAccountImages,
    isPending: isAddingAccountImages,
    isSuccess: isSuccessAddingAccountImages,
  } = useMutation({
    mutationFn: addAccountImagesRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'images', accountId],
      });
    },
  });

  return {
    addAccountImages,
    isAddingAccountImages,
    isSuccessAddingAccountImages,
  };
}
