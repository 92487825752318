import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const uploadAccountLogoRequest = (data: FormData) => {
  return axios
    .post('/api/Account/Logo', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUploadAccountLogo(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: uploadAccountLogo,
    isPending: isUploadingAccountLogo,
    isError: isErrorUploadingAccountLogo,
    isSuccess: isSuccessUploadingAccountLogo,
  } = useMutation({
    mutationFn: uploadAccountLogoRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'logo', accountId],
      });
    },
  });

  return {
    uploadAccountLogo,
    isUploadingAccountLogo,
    isErrorUploadingAccountLogo,
    isSuccessUploadingAccountLogo,
  };
}
