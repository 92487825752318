import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { PublishRequest } from '../../api-schemas/publish-request';

const getPublishRequestRequest = (publishRequestId: string) => {
  return axios
    .get<PublishRequest>(`/api/PublishRequest/${publishRequestId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetPublishRequest(publishRequestId: string) {
  const {
    data,
    refetch: getPublishRequest,
    isFetching: isLoadingPublishRequest,
    isSuccess: isSuccessLoadingPublishRequest,
  } = useQuery<PublishRequest>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['publishRequest', publishRequestId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () => getPublishRequestRequest(publishRequestId),
  });

  const publishRequest = useMemo(() => data, [data]);

  return {
    publishRequest,
    isLoadingPublishRequest,
    isSuccessLoadingPublishRequest,

    getPublishRequest,
  };
}
