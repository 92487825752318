import React, { useEffect } from 'react';
import WatchersListItem from '../watchers-list-item';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Skeleton from '@mui/material/Skeleton';
import useGetWatchers from '../../../api-hooks/watcher/useGetWatchers';

const WatchersList = ({
  entityType,
  entityId,
  refreshList,
  setRefreshList,
}) => {
  const { watchers, isSuccessLoadingWatchers, isErrorLoadingWatchers } =
    useGetWatchers({
      entityType,
      entityId,
      enabled: Boolean(
        (entityId && refreshList && entityType !== 'Documents') ||
          (entityType === 'Documents' && entityId)
      ),
    });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccessLoadingWatchers) {
      setRefreshList(false);
    }
  }, [isSuccessLoadingWatchers]);

  useEffect(() => {
    if (isErrorLoadingWatchers) {
      enqueueSnackbar(t('error.unknow'), {
        variant: 'error',
      });

      setRefreshList(false);
    }
  }, [isErrorLoadingWatchers]);

  if (watchers === undefined) {
    return [{
      key: 'loading',
      label: <Skeleton width={60} height={20} />,
    }];
  } else if (watchers.length) {
    return watchers.map((watcher) => ({
      key: watcher.id,
      label: <WatchersListItem watcher={watcher} />,
    }));
  } else {
    return [{
      key: 'no-watchers',
      label: (
        <div style={{ margin: '5px 15px 5px 15px' }}>
          <span>{t('no-watcher')}</span>
        </div>
      ),
    }];
  }
};

export default WatchersList;
