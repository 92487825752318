import React, { useContext } from 'react';

import ImageGallery from '../../components/image-gallery';
import { DocumentContext } from '../../contexts/document';

const DocumentImageGalleryPage = () => {
  const { selectedDoc } = useContext(DocumentContext);

  return (
    <ImageGallery entityType="Document" entity={selectedDoc} showBreadcrumb />
  );
};

export default DocumentImageGalleryPage;
