import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { Delete as DeleteOutlinedIcon } from '@mui/icons-material';

import { DraftTopicTreeItem } from '../../../../../api-schemas/topic';
import useDeleteTopic from '../../../../../api-hooks/topic/useDeleteTopic';

type DeleteButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
};

const DeleteButton = ({
  documentVersionId,
  draftTopicTreeItem,
}: DeleteButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const { t } = useTranslation();

  const { deleteTopic, isDeletingTopic } = useDeleteTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    topicId: draftTopicTreeItem.topicId,
  });

  const handleDelete = () => {
    deleteTopic({
      topicId: draftTopicTreeItem.topicId,
      languageCode: draftTopicTreeItem.languageCode,
    });
  };

  return (
    <Button
      onClick={handleDelete}
      disabled={isDeleted || isDeletingTopic}
      type="text"
      style={{ display: 'flex', width: '100%' }}
    >
      <DeleteOutlinedIcon />

      <Typography>{t('delete-topic')}</Typography>
    </Button>
  );
};

export default DeleteButton;
