import { useState, useEffect } from 'react';

import { TColumnSize, TBreakpoint } from '../../component-schemas/component';

const GRIDS_MAP = {
  xl: {
    breakpoint: 1200,
    columnMap: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12
    }
  },
  lg: {
    breakpoint: 992,
    columnMap: {
      1: 2,
      2: 3,
      3: 4,
      4: 5,
      5: 6,
      6: 7,
      7: 8,
      8: 9,
      9: 10,
      10: 11,
      11: 12,
      12: 12
    }
  },
  md: {
    breakpoint: 768,
    columnMap: {
      1: 3,
      2: 4,
      3: 5,
      4: 6,
      5: 7,
      6: 8,
      7: 9,
      8: 10,
      9: 11,
      10: 12,
      11: 12,
      12: 12
    }
  },
  sm: {
    breakpoint: 576,
    columnMap: {
      1: 8,
      2: 9,
      3: 10,
      4: 12,
      5: 12,
      6: 12,
      7: 12,
      8: 12,
      9: 12,
      10: 12,
      11: 12,
      12: 12
    }
  },
  xs: {
    breakpoint: 0,
    columnMap: {
      1: 12,
      2: 12,
      3: 12,
      4: 12,
      5: 12,
      6: 12,
      7: 12,
      8: 12,
      9: 12,
      10: 12,
      11: 12,
      12: 12
    }
  },
}

const BREAK_POINTS = ['xl', 'lg', 'md', 'sm', 'xs'];

type TUseGetComponentClassNameProps = {
  baseSize: TColumnSize;
}

const useGetComponentClassName = ({ baseSize }: TUseGetComponentClassNameProps) => {
  const [className, setClassName] = useState<string>();

  function calcColumnClassName() {
    const classes: string[] = [];

    for(const breakpoint of BREAK_POINTS) {
      const columnSize = GRIDS_MAP[breakpoint as TBreakpoint].columnMap[baseSize];

      classes.push(`col-${breakpoint}-${columnSize}`);
    }

    setClassName(classes.join(' '));
  }

  useEffect(() => {
    calcColumnClassName();
  }, [baseSize]);

  return {
    responsivenessClassName: className
  }
}

export default useGetComponentClassName;