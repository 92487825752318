import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const deleteAccountRequest = ({ accountId }: { accountId: string }) => {
  return axios
  .delete(`/api/Account/${accountId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.auth_token,
    },
    responseType: 'text', 
  })
  .then(() => ({ success: true })); 

};

export default function useDeleteAccount() {

  const {
    mutateAsync: deleteAccount,
    isPending: isDeletingAccount,
    isSuccess: isSuccessDeletingAccount,
  } = useMutation({
    mutationFn: deleteAccountRequest,
  });

  return {
    deleteAccount,
    isDeletingAccount,
    isSuccessDeletingAccount,
  };
}
