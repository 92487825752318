import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CropLogo from '../../../crop-logo';
import SelectInput from '../../../inputs/select-input';
import { Select, Typography as AntTypography, Divider, Button, Tooltip, Radio, Col } from 'antd';
import './styles.css';
import '../styles.css';
import { PictureFilled, PlusOutlined, MoonOutlined } from '@ant-design/icons';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import { MdOutlineDelete } from 'react-icons/md';
import { Input, Box, CircularProgress, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import NavbarMenuBuilder from '../../../navbar-menu-builder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BsMenuButtonWide, BsWindow } from 'react-icons/bs';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { CiShare2 } from 'react-icons/ci';

const { Option } = Select;
const { Text, Title } = AntTypography;

const styles: any = (Style: Theme) => ({
  textField: {
    marginRight: '10px',
    marginBottom: 0,
  },
  paper: {
    marginTop: Style.spacing(3),
    marginBottom: Style.spacing(3),
    padding: Style.spacing(2),
    [Style.breakpoints.up(600 + parseInt(Style.spacing(3)) * 2)]: {
      marginTop: Style.spacing(6),
      marginBottom: Style.spacing(6),
      padding: Style.spacing(3),
    },
  },
  errorBox: {
    color: 'red',
  },
  finalImage: {
    maxWidth: '50%',
    maxHeight: '70px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  button: {
    margin: Style.spacing(1),
  },
  input: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    padding: 5,
    paddingBottom: 10,
    marginBottom: '10px',
    '&::before': {
      border: 'none',
    },
  },
  navbarForm: {
    boxShadow: 'none',
  },
  panelSummary: {
    padding: '0 10px',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '& > div': {
      alignItems: 'center',
    },
  },
  expansionSummary: {
    flexDirection: 'row',
  },
});

function AccountStyleSettings(props: any) {
  const [faviconSrc, setFaviconSrc] = useState<string | ArrayBuffer | null>(null);
  const [accountConfiguration, setAccountConfiguration] = useState<any>({});
  const [showNavbarMenuBuilder, setShowNavbarMenuBuilder] = useState<boolean>(false);
  const [durationToReadVisibilityValue, setDurationToReadVisibilityValue] = useState<boolean>(false);
  const [darkModeVisibilityValue, setDarkModeVisibilityValue] = useState<boolean>(false);
  const [showShareButton, setShowShareButton] = useState<boolean>(true);

  const cropLogoRef = useRef();

  useEffect(() => {
    (document.getElementById('error-file-favicon') as HTMLElement).innerHTML = '';
    if (props.accountConfiguration.favicon !== null) {
      setFaviconSrc(`data:image;base64,${props.accountConfiguration.favicon}`);
    } else {
      setFaviconSrc(null);
    }
    setAccountConfiguration(props.accountConfiguration);
    setShowNavbarMenuBuilder(!!props.accountConfiguration.menu);
    setDurationToReadVisibilityValue(!!props.accountConfiguration.durationToReadVisibility);
    setShowShareButton(!!props.accountConfiguration.shareButtonVisibility);
    setDarkModeVisibilityValue(!!props.accountConfiguration.darkModeVisibility);

    // eslint-disable-next-line
  }, [props.accountConfiguration]);

  const raiseAccountConfigurationChanged = (accountConfiguration: any) => {
    if (props.onAccountConfigurationChanged) props.onAccountConfigurationChanged(accountConfiguration);
  };

  const classes = props.classes;

  const { t } = useTranslation();

  const handleLogoChanged = (file: any) => {
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.accountLogo = file;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const onChangeImage = (event: any) => {
    const errorElementId = 'error-file-favicon';
    const acceptedTypesFavicon = ['image/x-icon', 'image/png', 'image/gif', 'image/vnd.microsoft.icon'];
    event.preventDefault();
    if (acceptedTypesFavicon.indexOf(event.target.files[0].type) < 0) {
      (document.getElementById(errorElementId) as HTMLElement).innerHTML = t('error.fileType');
      event.target.value = '';
      return;
    }
    if (event.target.files[0].size > 300000) {
      (document.getElementById(errorElementId) as HTMLElement).innerHTML = t('error.fileSizeFavicon');
      event.target.value = '';
      return;
    }
    (document.getElementById(errorElementId) as HTMLElement).innerHTML = '';
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.favicon = event.target.files[0];
    raiseAccountConfigurationChanged(newAccountConfiguration);
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    } else {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setFaviconSrc(reader.result);
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
      (document.getElementById(errorElementId) as HTMLElement).innerHTML = '';
    } else {
      (document.getElementById(errorElementId) as HTMLElement).innerHTML = t('error.fileNotSelected');
    }
  };

  const handleViewerStyleChange = (value) => {
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.style = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleNavbarVisibilityChange = (event) => {
    const value = event.target.value;
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.navbarVisibility = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleDurationToReadVisibility = () => {
    const newAccountConfiguration: any = Object.assign({}, accountConfiguration);
    newAccountConfiguration.durationToReadVisibility = !durationToReadVisibilityValue;
    setDurationToReadVisibilityValue(!durationToReadVisibilityValue);
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleDarkModeVisibility = () => {
    const newAccountConfiguration: any = Object.assign({}, accountConfiguration);

    newAccountConfiguration.darkModeVisibility = !darkModeVisibilityValue;

    setDarkModeVisibilityValue(!darkModeVisibilityValue);

    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleNavbarDataChange = (value) => {
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.menu = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleShareButtonVisibilityChange = () => {
    const newAccountConfiguration: any = Object.assign({}, accountConfiguration);
    setShowShareButton(!showShareButton);
    newAccountConfiguration.shareButtonVisibility = !showShareButton;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      document.getElementById('favicon')?.parentElement?.classList.add('faviconParent');
      if (document.getElementById('helpIconFavicon')) {
        clearInterval(interval);
        (document.getElementById('helpIconFavicon') as HTMLElement).addEventListener('mouseover', () => {
          (document.getElementById('faviconTooltip') as HTMLElement).style.opacity = '1';
          (document.getElementById('faviconTooltip') as HTMLElement).style.visibility = 'visible';
          (document.getElementById('faviconTooltip') as HTMLElement).style.display = 'block';
        });

        (document.getElementById('helpIconFavicon') as HTMLElement).addEventListener('mouseleave', () => {
          (document.getElementById('faviconTooltip') as HTMLElement).style.opacity = '0';
          (document.getElementById('faviconTooltip') as HTMLElement).style.visibility = 'hidden';
          (document.getElementById('faviconTooltip') as HTMLElement).style.display = 'none';
        });
      }
    }, 200);
  }, []);

  const openFaviconInput = () => {
    document.getElementById('favicon')?.click();
  };

  const handleRemoveFavicon = () => {
    setFaviconSrc(null);
    props.onAccountConfigurationChanged({
      ...props.accountConfiguration,
      favicon: null,
    });
  };

  const handleShowNavbarMenuBuilder = (event) => {
    const showMenuBuilder = event.target.value;
    setShowNavbarMenuBuilder(showMenuBuilder);

    if (!showMenuBuilder)
      props.onAccountConfigurationChanged({
        ...props.accountConfiguration,
        menu: null,
      });
  };

  return (
    <div id="style-settings">
      <Col xs={24}>
        <div style={{ display: 'flex', marginTop: 10, width: '100%' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <PictureFilled style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 25 }} />
          </div>
          <div
            style={{ paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('org-logo')}
            </Title>
            <Text>{t('org-logo-help')}</Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: 30,
              }}
              className="logo-favicon-container"
            >
              <CropLogo
                //eslint-disable-next-line
                //@ts-expect-error
                finalImageClassName={classes.finalImage}
                inputStyle={{ width: 'calc(100% - 20px)', marginRight: 10 }}
                accountConfiguration={accountConfiguration}
                ref={cropLogoRef}
                handleRemoveLogo={props.handleRemoveLogo}
                handleLogoChanged={handleLogoChanged}
                logoSrc={`${localStorage.webApiAddress}account/${accountConfiguration.id}/logo`}
                insideUploadText="Upload Image"
                newStyle={true}
              />
              <Divider type="vertical" style={{ height: 'auto' }} className="vertical-divider-logo-favicon" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {faviconSrc ? (
                  <div style={{ height: '100%', display: 'flex' }}>
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <div style={{ marginBottom: 20 }}>{t('favicon')}</div>
                      <img
                        className={classes.finalImage}
                        style={{ display: 'inline-block', marginTop: '5px' }}
                        src={faviconSrc.toString()}
                        alt={'Favicon'}
                      />
                      <div style={{ marginTop: 25, marginBottom: 4 }} className="delete-favicon-icon">
                        <MdOutlineDelete
                          style={{
                            color: 'gray',
                            fontSize: 30,
                            cursor: 'pointer',
                          }}
                          onClick={handleRemoveFavicon}
                        />
                      </div>
                    </Box>
                  </div>
                ) : (
                  <>
                    <Button
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: 15,
                        border: '1px dashed #d9d9d9',
                        textAlign: 'center',
                        backgroundColor: '#fafafa',
                      }}
                      onClick={openFaviconInput}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <PlusOutlined />
                        <Text style={{ marginTop: 10 }}>{t('upload-favicon')}</Text>
                      </div>
                    </Button>
                    <Input
                      inputProps={{ accept: '.ico, .png, .gif' }}
                      fullWidth
                      className={classes.input}
                      style={{ width: 'calc(100% - 20px)', height: '62px' }}
                      id="favicon"
                      type="file"
                      name="imageLogo"
                      onChange={(event) => onChangeImage(event)}
                    />
                  </>
                )}
                <div id="error-file-favicon" className={classes.errorBox} style={{ marginTop: 10 }}></div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <BsWindow
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 25,
                marginTop: -5,
              }}
            />
          </div>
          <div
            style={{ paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('navbar-visibility')}
            </Title>
            <Text style={{ display: 'block' }}>{t('navbar-visibility-help')}</Text>

            <Radio.Group
              style={{ marginBottom: 20, marginTop: 15 }}
              onChange={handleNavbarVisibilityChange}
              defaultValue={true}
              buttonStyle="solid"
              value={accountConfiguration.navbarVisibility}
            >
              <Radio.Button value={true}>{t('show')}</Radio.Button>
              <Radio.Button value={false}>{t('hide')}</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <StyleTwoToneIcon
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 25,
                marginTop: -5,
              }}
            />
          </div>
          <div
            style={{ paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('org-style')}
            </Title>
            <Text style={{ display: 'block' }}>{t('org-style-help')}</Text>
            <SelectInput
              id="style"
              field="style"
              required={true}
              showError={false}
              style={{ width: '50%', marginRight: 10, marginTop: 16 }}
              onChange={handleViewerStyleChange}
              value={accountConfiguration.style}
            >
              <Option value="Flat">{t('flat-style')}</Option>
              <Option value="Paper">{t('paper-style')}</Option>
            </SelectInput>
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <BsMenuButtonWide style={{ fontSize: '36px', color: '#1a73e8', marginLeft: 25 }} />
          </div>
          <div
            style={{ marginBottom: '20px', paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <div style={{ width: '95%' }}>
              <Title className="settings-item-title" level={4}>
                {t('navbar-menu-builder')}
              </Title>
              <Text style={{ display: 'block' }}>
                {t('navbar-menu-builder-help')}
                <Tooltip title={t('navbar-menu-builder-help')}>
                  <a
                    href="https://sonat.com/@sonat/knowledge-base/navbar-menu-builder?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HelpOutlineIcon style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: 10 }} />
                  </a>
                </Tooltip>
              </Text>
              <Radio.Group
                style={{ marginBottom: 20, marginTop: 15 }}
                onChange={handleShowNavbarMenuBuilder}
                defaultValue={false}
                buttonStyle="solid"
                value={showNavbarMenuBuilder}
              >
                <Radio.Button value={false}>{t('disable')}</Radio.Button>
                <Radio.Button value={true}>{t('enable')}</Radio.Button>
              </Radio.Group>
              <NavbarMenuBuilder
                showEditor={showNavbarMenuBuilder}
                showPreview={showNavbarMenuBuilder && window.innerWidth > 600}
                data={
                  props.accountConfiguration.menu && props.accountConfiguration.menu !== 'null'
                    ? props.accountConfiguration.menu
                    : null
                }
                handleChangeData={handleNavbarDataChange}
                showPreviewMassage={window.innerWidth <= 600}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <AiOutlineFieldTime style={{ fontSize: '44px', color: '#1a73e8', marginLeft: 25 }} />
          </div>
          <div
            style={{ marginBottom: '20px', paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <div style={{ width: '95%' }}>
              <Title className="settings-item-title" level={4}>
                {t('duration-to-read-visibility')}
              </Title>
              <Text style={{ display: 'block' }}>{t('duration-to-read-visibility-help')}</Text>

              <Radio.Group
                style={{ marginBottom: 20, marginTop: 15 }}
                onChange={handleDurationToReadVisibility}
                defaultValue={true}
                buttonStyle="solid"
                value={durationToReadVisibilityValue}
              >
                <Radio.Button value={true}>{t('show')}</Radio.Button>
                <Radio.Button value={false}>{t('hide')}</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 675 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <CiShare2 style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 25 }} />
          </div>

          <div
            style={{ marginBottom: '20px' }}
            className={
              window.innerWidth > 675 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('share-button-visibility')}
            </Title>

            <Text>{t('share-button-visibility-help')}</Text>

            <Radio.Group
              style={{ display: 'block', marginTop: 10 }}
              onChange={handleShareButtonVisibilityChange}
              defaultValue={true}
              buttonStyle="solid"
              value={showShareButton}
            >
              <Radio.Button value={true}>{t('show')}</Radio.Button>
              <Radio.Button value={false}>{t('hide')}</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div className={window.innerWidth > 700 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <MoonOutlined style={{ fontSize: '44px', color: '#1a73e8', marginLeft: 25 }} />
          </div>

          <div
            style={{ marginBottom: '20px', paddingLeft: 10 }}
            className={
              window.innerWidth > 700 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <div style={{ width: '95%' }}>
              <Title className="settings-item-title" level={4}>
                {t('dark-mode-visibility')}
              </Title>
              <Text style={{ display: 'block' }}>{t('dark-mode-visibility-help')}</Text>

              <Radio.Group
                style={{ marginBottom: 20, marginTop: 15 }}
                onChange={handleDarkModeVisibility}
                defaultValue={true}
                buttonStyle="solid"
                value={darkModeVisibilityValue}
              >
                <Radio.Button value={true}>{t('show')}</Radio.Button>
                <Radio.Button value={false}>{t('hide')}</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Col>

      <Col xs={24}>
        <div className={classes.buttons}>
          <Button
            type="primary"
            size="large"
            onClick={props.handleSaveAccountSettings}
            className={classes.button}
            disabled={props.loading || props.disableSaveButton}
          >
            {props.loading ? <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} /> : t('save')}
          </Button>
        </div>
      </Col>
    </div>
  );
}

export default withStyles(styles)(AccountStyleSettings);
