import React from 'react';
import ManageAccesses from '../../../manage-accesses';
import { withStyles } from '@mui/styles';

const styles: any = (theme) => ({
  documentUserContainer: {
    width: 'auto',
    height: 'calc(100% - 40px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

const ManageDocumentAccesses = ({
  ROLES,
  documentId,
  accesses,
  selectedDoc,
  loadAccesses,
  handleAccessActionChanged,
  handleAddAccesses,
  handleRemoveAccess,
  ...props
}) => {
  const { classes } = props;

  return (
    <div className={classes.documentUserContainer}>
      <ManageAccesses
        tokens={props.tokens}
        documentId={documentId}
        entityType="Document"
        accesses={accesses}
        currentPlan={props.plan}
        handleAccessActionChanged={handleAccessActionChanged}
        ROLES={ROLES}
        isViewerAccess={props.isViewerAccess}
        handleAddAccesses={handleAddAccesses}
        handleRemoveAccess={handleRemoveAccess}
        loadAccesses={loadAccesses}
        entityTitle={selectedDoc?.title}
        title={props.title}
        type={props.type || 'USER'}
      />
    </div>
  );
};

export default withStyles(styles)(ManageDocumentAccesses);
