import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogContentText,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  User,
  EmailAuthProvider,
  updatePassword,
  updateEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  GithubAuthProvider,
  unlink,
} from 'firebase/auth';
import { withSnackbar } from 'notistack';
import { reAuthenticate } from '../../../../helpers/authentication';
import { Col } from 'antd';

const useStyles: any = makeStyles((theme) => ({
  box: {
    display: 'flex!important',
    alignItems: 'center!important',
    [theme.breakpoints.down('sm')]: {
      display: 'block!important',
    },
    marginBottom: '10px!important',
    marginTop: '10px!important',
  },
  button: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center!important',
      marginTop: '10px!important',
    },
    '& > button': {
      textTransform: 'none!important',
    },
    '& > button > span': {
      fontSize: '12px!important',
    },
  },
  title: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  fieldsDialog: {
    '& > div > div': {
      width: '500px!important',
    },
  },
  reAuthenticateDialog: {
    '& > div > div': {
      width: '400px!important',
    },
  },
  helpText: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: 5,
    display: 'block',
  },
}));

const EmailAndPasswordConfiguration = (props) => {
  const [user, setUser] = useState<User | null>(null);
  const [email, setEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isOpenDialogFields, setIsOpenDialogFields] = useState<boolean>(false);
  const [isOpenReAuthenticateDialog, setIsOpenReAuthenticateDialog] =
    useState<boolean>(false);
  const [isOpenPasswordDialog, setIsOpenPasswordDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (props.profileData && user) {
      const authEmail = user.providerData.filter(
        (provider) => provider?.providerId === EmailAuthProvider.PROVIDER_ID
      );
      let email: string | null | undefined = null;
      if (authEmail.length) {
        email = authEmail[0]?.email;
      }
      setEmail(email || props.profileData.email);
      setNewEmail(email || props.profileData.email);
    }
  }, [props.profileData, user]);

  useEffect(() => {
    if (props.user) {
      setLoading(false);
      setUser(props.user);
    } else {
      setLoading(true);
    }
  }, [props.user]);

  const classes = useStyles();

  const onConfirmDialogFields = () => {
    if (newPassword === '' && newEmail === email) {
      props.enqueueSnackbar(t(`error.not-changed`), {
        variant: 'error',
        persist: false,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
      return;
    }
    setIsOpenDialogFields(false);
    setIsOpenReAuthenticateDialog(true);
  };

  const onCloseDialogFields = () => {
    setIsOpenDialogFields(false);
    setNewPassword('');
    setNewEmail(email);
  };

  const onClosePasswordDialog = () => {
    setIsOpenPasswordDialog(false);
    setNewPassword('');
    setPassword('');
    setNewEmail(email);
  };

  const setUpdateEmailError = (message: string) => {
    switch (message) {
      case 'Error: The email address is already in use by another account.':
        props.enqueueSnackbar(t(`error.email-exists`), {
          variant: 'error',
          persist: false,
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
        break;
      default:
        props.enqueueSnackbar(t(`error.unknown`), {
          variant: 'error',
          persist: false,
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
        break;
    }
  };

  const updateUserEmail = (user) => {
    return updateEmail(user, newEmail)
      .then(function () {
        return true;
      })
      .catch(function (error) {
        throw new Error(error);
      });
  };

  const setUpdatePasswordError = (message: string) => {
    switch (message) {
      default:
        props.enqueueSnackbar(t(`error.unknown`), {
          variant: 'error',
          persist: false,
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
        break;
    }
  };

  const updateUserPassword = () => {
    return updatePassword(user as User, newPassword)
      .then(function () {
        return true;
      })
      .catch(function (error) {
        throw new Error(error);
      });
  };

  const handleReAuthenticate = async (provider) => {
    setLoading(true);
    const hasDone = await reAuthenticate(user as User, provider, password);
    if (hasDone) {
      if (newPassword.length) {
        updateUserPassword()
          ?.then(() => {
            if (newEmail !== email) {
              updateUserEmail(user)
                .then(() => {
                  sendEmailVerification(user as User)
                    .then(function () {
                      setLoading(false);
                      setIsOpenPasswordDialog(false);
                      setIsOpenReAuthenticateDialog(false);
                      setNewPassword('');
                      setPassword('');
                      setEmail(newEmail);
                      props.enqueueSnackbar(t(`send-email-verification-text`), {
                        variant: 'success',
                        persist: false,
                        style: { whiteSpace: 'pre-line' },
                        preventDuplicate: true,
                      });
                    })
                    .catch(function (error) {
                      setLoading(false);
                      setUpdateEmailError(error.message);
                    });
                })
                .catch((error) => {
                  setLoading(false);
                  setUpdateEmailError(error.message);
                });
            } else {
              props.enqueueSnackbar(t(`success.update-password`), {
                variant: 'success',
                persist: false,
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
              setLoading(false);
              setIsOpenPasswordDialog(false);
              setIsOpenReAuthenticateDialog(false);
              setNewPassword('');
              setPassword('');
            }
          })
          .catch((error) => {
            setLoading(false);
            setUpdatePasswordError(error.message);
          });
      } else {
        if (newEmail !== email) {
          updateUserEmail(user)
            .then(() => {
              sendEmailVerification(user as User)
                .then(function () {
                  setLoading(false);
                  setIsOpenPasswordDialog(false);
                  setIsOpenReAuthenticateDialog(false);
                  setNewPassword('');
                  setPassword('');
                  setEmail(newEmail);
                  props.enqueueSnackbar(t(`send-email-verification-text`), {
                    variant: 'success',
                    persist: false,
                    style: { whiteSpace: 'pre-line' },
                    preventDuplicate: true,
                  });
                })
                .catch(function (error) {
                  setLoading(false);
                  setUpdateEmailError(error.message);
                });
            })
            .catch((error) => {
              setLoading(false);
              setUpdateEmailError(error.message);
            });
        } else {
          props.enqueueSnackbar(t(`error.not-changed`), {
            variant: 'error',
            persist: false,
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
          setLoading(false);
          setIsOpenPasswordDialog(false);
          setIsOpenReAuthenticateDialog(false);
        }
      }
    } else {
      props.enqueueSnackbar(t(`error.reAuthenticate`), {
        variant: 'error',
        persist: false,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
      setLoading(false);
    }
  };

  const loginOptions = () => {
    return loading ? (
      <CircularProgress />
    ) : (
      <ul style={{ padding: 0 }}>
        {user &&
          user.providerData.filter(
            (provider) =>
              provider?.providerId === GoogleAuthProvider.PROVIDER_ID
          ).length > 0 && (
            <li
              className="firebaseui-list-item"
              style={{ listStyle: 'none', marginBottom: 10 }}
              onClick={() =>
                handleReAuthenticate(GoogleAuthProvider.PROVIDER_ID)
              }
            >
              <button
                className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
                data-provider-id="google.com"
                style={{ backgroundColor: '#ffffff', maxWidth: '100%' }}
                data-upgraded=",MaterialButton"
              >
                <span className="firebaseui-idp-icon-wrapper">
                  <img
                    className="firebaseui-idp-icon"
                    alt=""
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  />
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-long">
                  Sign in with Google
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-short">
                  Google
                </span>
              </button>
            </li>
          )}
        {user &&
          user.providerData.filter(
            (provider) =>
              provider?.providerId === GithubAuthProvider.PROVIDER_ID
          ).length > 0 && (
            <li
              className="firebaseui-list-item"
              style={{ listStyle: 'none', marginBottom: 10 }}
              onClick={() =>
                handleReAuthenticate(GithubAuthProvider.PROVIDER_ID)
              }
            >
              <button
                className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-github firebaseui-id-idp-button"
                data-provider-id="github.com"
                style={{ backgroundColor: '#333333', maxWidth: '100%' }}
                data-upgraded=",MaterialButton"
              >
                <span className="firebaseui-idp-icon-wrapper">
                  <img
                    className="firebaseui-idp-icon"
                    alt=""
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/github.svg"
                  />
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-long">
                  Sign in with GitHub
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-short">
                  GitHub
                </span>
              </button>
            </li>
          )}
        {user &&
          user.providerData.filter(
            (provider) => provider?.providerId === EmailAuthProvider.PROVIDER_ID
          ).length > 0 && (
            <li
              className="firebaseui-list-item"
              style={{ listStyle: 'none', marginBottom: 10 }}
              onClick={() => {
                setIsOpenReAuthenticateDialog(false);
                setIsOpenPasswordDialog(true);
              }}
            >
              <button
                className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
                data-provider-id="password"
                style={{ backgroundColor: '#db4437', maxWidth: '100%' }}
                data-upgraded=",MaterialButton"
              >
                <span className="firebaseui-idp-icon-wrapper">
                  <img
                    className="firebaseui-idp-icon"
                    alt=""
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"
                  />
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-long">
                  Sign in with email
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-short">
                  Email
                </span>
              </button>
            </li>
          )}
      </ul>
    );
  };

  const handleUnLink = () => {
    unlink(user as User, EmailAuthProvider.PROVIDER_ID)
      .then(() => {
        let tempProviderData = user?.providerData;
        tempProviderData = tempProviderData?.filter(
          (provider) => provider?.providerId !== EmailAuthProvider.PROVIDER_ID
        );
        setUser({
          ...user,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-expect-error
          providerData: tempProviderData,
        });
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.unknown`), {
          variant: 'error',
          persist: false,
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  return (
    <React.Fragment>
      <Col xs={24} className={classes.box}>
        <Col xs={24} lg={16}>
          <Col xs={24} className={classes.title}>
            {t('email-password')}
          </Col>
          <Col xs={24} className={classes.text}>
            <Trans i18nKey="update-email-password-text">
              Your email address ({{ email }}) has been set. It will be used for
              account-related notifications and sign-in.
            </Trans>
          </Col>
        </Col>
        <Col xs={24} lg={8} className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 200 }}
            onClick={() => setIsOpenDialogFields(true)}
          >
            {user &&
            user.providerData.filter(
              (provider) =>
                provider?.providerId === EmailAuthProvider.PROVIDER_ID
            ).length > 0 ? (
              t('update-email-password')
            ) : loading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: '#fff' }}
              />
            ) : (
              t('set-email-password')
            )}
          </Button>
          {user &&
            user.providerData.filter(
              (provider) =>
                provider?.providerId === EmailAuthProvider.PROVIDER_ID
            ).length > 0 && (
              <Button
                variant="contained"
                color="primary"
                style={{ width: 200, marginTop: 10, backgroundColor: 'red' }}
                onClick={() => handleUnLink()}
              >
                {t('unlink-account')}
              </Button>
            )}
        </Col>
      </Col>
      <Dialog
        open={isOpenDialogFields}
        onClose={() => onCloseDialogFields()}
        className={classes.fieldsDialog}
      >
        <DialogTitle>{t('update-email-password')}</DialogTitle>
        <DialogContent>
          <Col xs={24}>
            <Col xs={24} style={{ marginBottom: 10 }}>
              <TextField
                fullWidth
                label={t('email')}
                type="email"
                value={newEmail}
                onChange={(event) => setNewEmail(event.target.value)}
              />
              <span className={classes.helpText}>{t('email-help-text')}</span>
            </Col>
            <Col xs={24}>
              <TextField
                fullWidth
                label={t('password')}
                type="password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <span className={classes.helpText}>
                {t('password-help-text')}
              </span>
            </Col>
          </Col>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCloseDialogFields()}
            color="primary"
            autoFocus
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmDialogFields}
            color="primary"
          >
            {t('update')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenReAuthenticateDialog}
        onClose={() => setIsOpenReAuthenticateDialog(false)}
        className={classes.reAuthenticateDialog}
      >
        <DialogTitle>{t('login')}</DialogTitle>
        <DialogContentText style={{ padding: '0 25px' }}>
          {t('reauthenticate-help-text')}
        </DialogContentText>
        <DialogContent>
          <Col xs={24}>{loginOptions()}</Col>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenReAuthenticateDialog(false)}
            color="primary"
            autoFocus
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenPasswordDialog}
        onClose={() => onClosePasswordDialog()}
        className={classes.fieldsDialog}
      >
        <DialogTitle>{t('enter-password')}</DialogTitle>
        <DialogContent>
          <Col xs={24}>
            <TextField
              fullWidth
              label={t('your-password')}
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Col>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClosePasswordDialog()}
            color="primary"
            autoFocus
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleReAuthenticate(EmailAuthProvider.PROVIDER_ID)}
            color="primary"
            disabled={loading}
          >
            {!loading ? (
              t('login')
            ) : (
              <CircularProgress
                style={{ width: 25, height: 25, color: '#fff' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withSnackbar(EmailAndPasswordConfiguration);
