import React, { useEffect, useState, useContext } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  InputBase,
  IconButton,
  useMediaQuery,
  useTheme,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import config from 'react-global-configuration';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fragment } from 'react';
import QRCode from 'qrcode.react';

import { DocumentContext } from '../../contexts/document';
import { TopicContext } from '../../contexts/topic';
import { getLanguageCode } from '../../helpers/route';
import { AccountContext } from '../../contexts/account';
import { getTopicLink } from '../../helpers/common';

const styles: any = (theme) => ({
  linkToViewer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#000',
  },
  icons: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
    marginTop: '1px',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #1a73e8',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  infoText: {
    color: '#000',
  },
});

const ViewLiveButton = (props) => {
  const { t } = useTranslation();

  const [tooltipTitle, setTooltipTitle] = useState<string>(t('not-published'));
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showViewLive, setShowViewLive] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const theme: Theme = useTheme();
  const fullScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));

  const [viewerUrl, setViewerUrl] = React.useState<string>('');

  const { versions } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);
  const { selectedAccount } = useContext(AccountContext);

  const viewerURL: string = config.get('viewerURL');

  const classes = props.classes;

  const handleClose = () => {
    setOpen(false);
  };

  const copyURL = () => {
    const input: any = document.getElementById(
      props.topicViewLive
        ? 'viewerURL'
        : props.documentViewLive
          ? 'documentViewerURL'
          : 'accountViewerURL'
    );

    input.select();

    document.execCommand('copy');

    setOpenTooltip(true);

    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  useEffect(() => {
    if (props.topicViewLive) {
      if (
        props.document &&
        props.selectedVersion &&
        props.selectedLanguage &&
        selectedTopic
      ) {
        if (
          selectedTopic.topicVersionNumber &&
          parseFloat(selectedTopic.topicVersionNumber) >= 1.0
        ) {
          setIsPublished(true);
          const viewerLocation: string = getTopicLink(
            props.document,
            selectedTopic,
            /*props.document.languages.length > 1 ? */ props.selectedLanguage /* : null*/,
            true,
          );
          setViewerUrl(viewerLocation);
          setTooltipTitle(t('share-topic-link'));
        } else {
          setIsPublished(false);
          (
            document.getElementById('visibilityIcon') as HTMLElement
          ).style.filter = 'contrast(0.5)';
          setTooltipTitle(t('not-published'));
        }
      } else if (props.document && selectedTopic && props.selectedLanguage) {
        if (
          selectedTopic &&
          selectedTopic.topicVersionNumber &&
          parseFloat(selectedTopic.topicVersionNumber) >= 1.0
        ) {
          setIsPublished(true);
          setTooltipTitle(t('share-topic-link'));
          const viewerLocation = getTopicLink(
            props.document,
            selectedTopic,
            /*props.document.languages.length > 1 ? */ props.selectedLanguage /* : null*/,
            versions.length > 1 ? props.selectedVersion : null,
            true
          );
          setViewerUrl(viewerLocation);
        }
      } else if (
        selectedAccount &&
        selectedTopic &&
        selectedTopic.topicVersionNumber &&
        parseFloat(selectedTopic.topicVersionNumber) >= 1.0
      ) {
        setIsPublished(true);
        setTooltipTitle(t('share-topic-link'));

        const viewerURL = config.get('viewerURL');
        const viewerLocation = `${viewerURL}${selectedAccount.slug}/${selectedTopic.slug}?lang=${getLanguageCode()}`;

        setViewerUrl(viewerLocation);
      }
    } else if (props.documentViewLive) {
      if (props.selectedLanguage && props.selectedVersion && props.document) {
        const showOrHideViewLive = async (document) => {
          let published: boolean = false;
          await props.topics.map((topic) => {
            if (parseFloat(topic.topicVersionNumber) >= 1.0) {
              published = true;
            }
            return true;
          });
          if (published) {
            setShowViewLive(true);
            if (props.document.account.customDomain) {
              setViewerUrl(
                `https://${props.document.account.customDomain}/${document.slug}${versions.length > 1 ? '/' + props.selectedVersion.slug : ''}${/*document.languages.length > 1 ? */ '?lang=' + props.selectedLanguage.code /* : ""*/}`
              );
            } else {
              setViewerUrl(
                `${viewerURL}${document.account.slug}/${document.slug}${versions.length > 1 ? '/' + props.selectedVersion.slug : ''}${/*document.languages.length > 1 ? */ '?lang=' + props.selectedLanguage.code /* : ""*/}`
              );
            }
            setIsPublished(true);
            setTooltipTitle(t('share-document-link'));
          } else {
            setShowViewLive(false);
          }
        };
        showOrHideViewLive(props.document);
      }
    } else if (props.accountViewLive && props.account) {
      setIsPublished(true);
      setTooltipTitle(t('share-account-link'));
      if (props.account.customDomain) {
        setViewerUrl(`https://${props.account.customDomain}`);
      } else {
        setViewerUrl(`${viewerURL}${props.account.slug}`);
      }
    }
    // eslint-disable-next-line
  }, [props, viewerURL, selectedTopic, selectedAccount]);

  const handleOpenDialog = () => {
    if (isPublished) {
      setOpen(true);
    }
  };

  if (
    (props.documentViewLive && showViewLive) ||
    props.topicViewLive ||
    props.accountViewLive
  ) {
    return (
      <Fragment>
        <Tooltip title={tooltipTitle}>
          <MenuItem
            key={'View Live'}
            selected={props.selectedTab ? props.selectedTab === 3 : false}
            onClick={handleOpenDialog}
            disabled={props.disabled}
            className="more-menu-li"
          >
            <ListItemIcon
              style={props.topicViewLive ? { minWidth: 56 } : { minWidth: 35 }}
            >
              <ShareIcon
                id={
                  props.topicViewLive
                    ? 'visibilityIcon'
                    : props.documentViewLive
                      ? 'documentVisibilityIcon'
                      : 'accountVisibilityIcon'
                }
              />
            </ListItemIcon>
            <ListItemText primary={t('share')} />
          </MenuItem>
        </Tooltip>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Shareable Link'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className={classes.infoText}
              style={{ color: '#000', marginBottom: 10 }}
            >
              {props.topicViewLive
                ? t('view-live-topic-text')
                : props.documentViewLive
                  ? t('view-live-text')
                  : t('account-view-live-text')}
            </DialogContentText>
            <Paper className={classes.root}>
              <InputBase
                className={classes.input}
                value={viewerUrl}
                inputProps={{ 'aria-label': 'viewer URL' }}
                id={
                  props.topicViewLive
                    ? 'viewerURL'
                    : props.documentViewLive
                      ? 'documentViewerURL'
                      : 'accountViewerURL'
                }
                autoFocus
                readOnly
              />
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <IconButton
                    type="button"
                    onClick={() => copyURL()}
                    className={classes.iconButton}
                  >
                    <FileCopyIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </Paper>
            <Grid style={{ marginTop: 15 }}>
              <Typography style={{ marginBottom: 15 }}>
                {t('share-with-qrcode')}
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <QRCode value={viewerUrl} />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
            </Button>
            <a
              href={viewerUrl}
              target="_blank"
              rel="noopener noreferrer"
              id="openViewerForTopic"
              className={classes.linkToViewer}
            >
              <Button color="primary" autoFocus variant="contained">
                <OpenInNewIcon />
                Open
              </Button>
            </a>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(ViewLiveButton);
