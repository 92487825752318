import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { validate as isValidUUID } from 'uuid';

import useGetDocuments from '../../api-hooks/document/useGetDocuments';

const actions = {
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_SELECTED_DOC: 'SET_SELECTED_DOC',
  SET_SELECTED_VERSION: 'SET_SELECTED_VERSION',
  SET_PREVIOUS_VERSION: 'SET_PREVIOUS_VERSION',
  SET_VERSIONS: 'SET_VERSIONS',
  SET_USERS: 'SET_USERS',
  SET_SELECTED_TOPIC: 'SET_SELECTED_TOPIC',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
  SET_PREVIOUS_LANGUAGE: 'SET_PREVIOUS_LANGUAGE',
  SET_TOPICS: 'SET_TOPICS',
  SET_TOPICS_LAN_VERSION: 'SET_TOPICS_LAN_VERSION',
  SET_COPIED_TOPIC_PARENT: 'SET_COPIED_TOPIC_PARENT',
  SET_OPEN_COPY_TOPIC: 'SET_OPEN_COPY_TOPIC',
  SET_OPEN_TOPIC_LINK: 'SET_OPEN_TOPIC_LINK',
};
const initialState: DocumentContextType = {
  documents: [],
  selectedDoc: null,
  documentTopics: [],
  selectedTopic: null,
  versions: [],
  topicsPerLanguageVersion: {},
  users: [],
  selectedVersion: null,
  previousVersionId: null,
  selectedLanguage: null,
  previousLanguageId: null,
  copiedTopicParent: null,
  openCopyTopic: false,
  openTopicLink: false,
  setDocuments: Function,
  setSelectedDoc: Function,
  setSelectedVersion: Function,
  setUsers: Function,
  setVersions: Function,
  setPreviousVersionId: Function,
  setDocumentVersions: Function,
  setSelectedLanguage: Function,
  setPreviousLanguageId: Function,
  setDocumentTopics: Function,
  setSelectedTopic: Function,
  setCopiedTopicParent: Function,
  setOpenCopyTopic: Function,
  setOpenTopicLink: Function,
  setTopicsPerLanguageVersion: Function,
};

type DocumentContextType = {
  selectedDoc: any;
  documentTopics: any;
  selectedTopic: any;
  selectedVersion: any;
  previousVersionId: string | null;
  versions: any;
  users: any;
  selectedLanguage: any;
  previousLanguageId: string | null;
  documents: any;
  copiedTopicParent: any;
  openCopyTopic: boolean;
  openTopicLink: boolean;
  topicsPerLanguageVersion: any;
  setDocuments: (value: any) => void;
  setSelectedDoc: (value: any) => void;
  setSelectedVersion: (value: any) => void;
  setUsers: (value: any) => void;
  setVersions: (value: any) => void;
  setPreviousVersionId: (value: any) => void;
  setDocumentVersions: (value: any) => void;
  setSelectedLanguage: (value: any) => void;
  setPreviousLanguageId: (value: any) => void;
  setDocumentTopics: (value: any) => void;
  setSelectedTopic: (value: any) => void;
  setCopiedTopicParent: (value: any) => void;
  setOpenCopyTopic: (value: any) => void;
  setOpenTopicLink: (value: any) => void;
  setTopicsPerLanguageVersion: (value: any) => void;
};

export const DocumentContext =
  React.createContext<DocumentContextType>(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DOCUMENTS':
      return {
        ...state,
        documents: action.value,
      };
    case 'SET_SELECTED_DOC':
      return {
        ...state,
        selectedDoc: action.value,
      };
    case 'SET_SELECTED_VERSION':
      return {
        ...state,
        selectedVersion: action.value,
      };
    case 'SET_PREVIOUS_VERSION':
      return {
        ...state,
        previousVersionId: action.value,
      };
    case 'SET_VERSIONS':
      return {
        ...state,
        versions: action.value,
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.value,
      };
    case 'SET_SELECTED_LANGUAGE':
      return {
        ...state,
        selectedLanguage: action.value,
      };
    case 'SET_PREVIOUS_LANGUAGE':
      return {
        ...state,
        previousLanguageId: action.value,
      };
    case 'SET_TOPICS':
      return {
        ...state,
        documentTopics: action.value,
      };
    case 'SET_TOPICS_LAN_VERSION':
      return {
        ...state,
        topicsPerLanguageVersion: action.value,
      };
    case 'SET_SELECTED_TOPIC':
      return {
        ...state,
        selectedTopic: action.value,
      };
    case 'SET_COPIED_TOPIC_PARENT':
      return {
        ...state,
        copiedTopicParent: action.value,
      };
    case 'SET_OPEN_COPY_TOPIC':
      return {
        ...state,
        openCopyTopic: action.value,
      };
    case 'SET_OPEN_TOPIC_LINK':
      return {
        ...state,
        openTopicLink: action.value,
      };
    default:
      throw new Error();
  }
};
export function DocumentProvider({ children }) {
  const { documents, isSuccessLoadingDocuments } = useGetDocuments();

  const { pathname } = useLocation();

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const documentId = useMemo(() => {
    const urlThirdParameter = pathname.split('/')[3];

    return isValidUUID(urlThirdParameter) ? urlThirdParameter : undefined;
  }, [pathname]);

  const value = {
    selectedDoc: state.selectedDoc,
    documentTopics: state.documentTopics,
    selectedTopic: state.selectedTopic,
    selectedVersion: state.selectedVersion,
    previousVersionId: state.previousVersionId,
    versions: state.versions,
    users: state.users,
    selectedLanguage: state.selectedLanguage,
    previousLanguageId: state.previousLanguageId,
    documents: state.documents,
    copiedTopicParent: state.copiedTopicParent,
    openCopyTopic: state.openCopyTopic,
    openTopicLink: state.openTopicLink,
    topicsPerLanguageVersion: state.topicsPerLanguageVersion,
    setDocuments: (value) => {
      dispatch({ type: actions.SET_DOCUMENTS, value });
    },
    setSelectedDoc: (value) => {
      dispatch({ type: actions.SET_SELECTED_DOC, value });
    },
    setSelectedVersion: (value) => {
      dispatch({ type: actions.SET_SELECTED_VERSION, value });
    },
    setUsers: (value) => {
      dispatch({ type: actions.SET_USERS, value });
    },
    setVersions: (value) => {
      dispatch({ type: actions.SET_VERSIONS, value });
    },
    setPreviousVersionId: (value) => {
      dispatch({ type: actions.SET_PREVIOUS_VERSION, value });
    },
    setDocumentVersions: (value) => {
      dispatch({ type: actions.SET_VERSIONS, value });
    },
    setSelectedLanguage: (value) => {
      dispatch({ type: actions.SET_SELECTED_LANGUAGE, value });
    },
    setPreviousLanguageId: (value) => {
      dispatch({ type: actions.SET_PREVIOUS_LANGUAGE, value });
    },
    setDocumentTopics: (value) => {
      dispatch({ type: actions.SET_TOPICS, value });
    },
    setSelectedTopic: (value) => {
      dispatch({ type: actions.SET_SELECTED_TOPIC, value });
    },
    setCopiedTopicParent: (value) => {
      dispatch({ type: actions.SET_COPIED_TOPIC_PARENT, value });
    },
    setOpenCopyTopic: (value) => {
      dispatch({ type: actions.SET_OPEN_COPY_TOPIC, value });
    },
    setOpenTopicLink: (value) => {
      dispatch({ type: actions.SET_OPEN_TOPIC_LINK, value });
    },
    setTopicsPerLanguageVersion: (value) => {
      dispatch({ type: actions.SET_TOPICS_LAN_VERSION, value });
    },
  };

  const selectDocument = () => {
    const selectedDocument = documents.find((doc) => doc.id === documentId);

    if (selectedDocument) {
      value.setSelectedDoc(selectedDocument);
    }
  };

  useEffect(() => {
    if (isSuccessLoadingDocuments) {
      value.setDocuments(documents);

      selectDocument();
    }
  }, [isSuccessLoadingDocuments, documents]);

  useEffect(() => {
    selectDocument();
  }, [documentId]);

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
}
