import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteTopicRequest = ({
  topicId,
  languageCode,
}: {
  topicId: string;
  languageCode: string | null;
}) => {
  return axios
    .delete(`/api/Topic/${topicId}${languageCode ? `/${languageCode}` : ''}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteTopic({
  documentVersionId,
  languageCode,
  topicId,
}: {
  documentVersionId?: string;
  languageCode?: string;
  topicId?: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteTopic,
    isPending: isDeletingTopic,
    isSuccess: isSuccessDeletingTopic,
  } = useMutation({
    mutationFn: deleteTopicRequest,
    onSuccess: () => {
      if (documentVersionId && languageCode) {
        queryClient.invalidateQueries({
          queryKey: ['draftTopicTree', documentVersionId, languageCode],
        });
      }

      if (topicId) {
        queryClient.invalidateQueries({
          queryKey: ['draftTopic', topicId, languageCode],
        });

        queryClient.invalidateQueries({
          queryKey: ['draftTopic', 'silent', topicId, languageCode],
        });
      }
    },
  });

  return {
    deleteTopic,
    isDeletingTopic,
    isSuccessDeletingTopic,
  };
}
