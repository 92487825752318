import { useMemo, useEffect, useContext } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Accesses } from '../../api-schemas/access';
import { ProfileContext } from '../../contexts/profile';
import { AccountContext } from '../../contexts/account';

const getAccessesRequest = (entityId: string) => {
  return axios
    .get<Accesses>(`/api/Access/${entityId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccesses(entityId: string) {
  const {
    data,
    refetch: getAccesses,
    isFetching: isLoadingAccesses,
    isSuccess: isSuccessLoadingAccesses,
  } = useQuery<Accesses>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['accesses', entityId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => (entityId ? getAccessesRequest(entityId) : []),
  });

  const accesses = useMemo(() => data || [], [data]);

  const profile = useContext(ProfileContext);
  const { setUserRole } = useContext(AccountContext);

  useEffect(() => {
    if (isSuccessLoadingAccesses && accesses) {
      const user = accesses.find(
        (access) => access.email === profile.profileData?.email
      );
      
      if (user) {
        setUserRole(user.action);
      }
    }
  }, [isSuccessLoadingAccesses, accesses]);

  return {
    accesses,
    isLoadingAccesses,
    isSuccessLoadingAccesses,

    getAccesses,
  };
}
