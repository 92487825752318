import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateUserRequestType } from '../../api-schemas/user';

const updateUserRequest = (data: UpdateUserRequestType) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/User', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateUser(email: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateUser,
    isPending: isUpdatingUser,
    isSuccess: isSuccessUpdatingUser,
    isError: isErrorUpdatingUser,
  } = useMutation({
    mutationFn: updateUserRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', email] });
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });

  return {
    updateUser,

    isUpdatingUser,
    isSuccessUpdatingUser,
    isErrorUpdatingUser,
  };
}
