import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CardMedia,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';

import Screenshot from './screenshot';
import SelectInput from '../inputs/select-input';
import TextInput from '../inputs/text-input';
import TextAreaInput from '../inputs/text-area-input';
import useSendEmail from '../../api-hooks/email/useSendEmail';

const { Option } = Select;

const styles: any = (theme) => ({
  textArea: {
    width: '100%',
    '&:focus': {
      outlineColor: '#1a73e8',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formLabel: {
    marginBottom: 10,
  },
  dialogTitle: {
    display: 'flex!important',
    alignItems: 'center!important',
  },
  errorBox: {
    color: 'red',
  },
  loading: {
    width: '20px!important',
    height: '20px!important',
    color: '#fff',
  },
  screenshot: {
    position: 'fixed',
    zIndex: '100000',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
  },
});

const ContactUs = (props) => {
  const inquiries = [
    {
      id: 0,
      text: 'General inquiry',
    },
    {
      id: 1,
      text: 'Billing and invoicing',
    },
    {
      id: 2,
      text: 'Technical support and security issues',
    },
    {
      id: 3,
      text: 'Sales inquires',
    },
    {
      id: 4,
      text: 'Other',
    },
  ];

  const { classes } = props;
  const { t } = useTranslation();
  const [selectedInquiry, setSelectedInquiry] = useState<string>(
    inquiries[0].text
  );
  const [title, setTitle] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isFirstAttempt, setIsFirstAttempt] = useState<boolean>(true);
  const [screenshotFinalImageUrl, setScreenshotFinalImageUrl] =
    useState<string>('');

  const { sendEmail } = useSendEmail();

  const handleInquiryChanged = (value: string) => {
    setSelectedInquiry(value);
  };

  const handleNameChanged = (value: string) => {
    setName(value);
  };

  const handleTitleChanged = (value: string) => {
    setTitle(value);
  };

  const handleEmailChanged = (value: string) => {
    setEmail(value);
  };

  const handleCompanyNameChanged = (value: string) => {
    setCompanyName(value);
  };

  const handlePhoneNumberChanged = (value: string) => {
    setPhoneNumber(value);
  };

  const handleMessageChanged = (value: string) => {
    setMessage(value);
  };

  const emailValidation = (email: string) => {
    // eslint-disable-next-line
    const pattern = /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(email);
  };

  const textValidation = (text: string) => {
    return !!text.length;
  };

  const dataURItoBlob = (dataURI: string) => {
    let byteString: string;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const handleSend = () => {
    setIsFirstAttempt(false);
    // const isPhoneNumberValid = phoneNumberValidation(phoneNumber);
    const isEmailValid = emailValidation(email);
    const isNameValid = textValidation(name);
    const isTitleValid = textValidation(title);
    const isMessageValid = textValidation(message);
    if (isEmailValid && isNameValid && isMessageValid && isTitleValid) {
      setLoading(true);
      const data = new FormData();
      data.append('Subject', `${selectedInquiry}: ${title}`);
      data.append(
        'Body',
        `Name: ${name}\nEmail: ${email}\n${phoneNumber.length ? 'Phone Number: ' + phoneNumber + '\n' : ''}${companyName.length ? 'Company Name: ' + companyName + '\n' : ''}${message}\n`
      );
      if (screenshotFinalImageUrl.length) {
        const image = dataURItoBlob(screenshotFinalImageUrl);
        data.append(
          'Attachments',
          image,
          `screenshot.${image.type.split('/')[1]}`
        );
      }

      sendEmail(data)
        .then(() => {
          setLoading(false);

          props.closeDialog(false);
        })
        .catch(() => {
          setLoading(false);

          props.enqueueSnackbar(t(`error.sendEmail`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }
  };

  const getErrorMessage = (field: string) => {
    if (!isFirstAttempt) {
      if (field === 'email') {
        return t('error.email');
      } else {
        return t('error.empty_text');
      }
    }
  };

  return (
    <Dialog open={props.openDialog} onClose={() => props.closeDialog(false)}>
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <div style={{ flex: 1 }}>{t('contact-us')}</div>
        <CardMedia
          component="img"
          image={process.env.PUBLIC_URL + '/Logo-white.svg'}
          title="Sonat"
          style={{
            width: '50px',
            height: '25px',
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Row style={{ padding: 8 }}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <SelectInput
              field="Type of inquiry"
              label={t('type-of-inquiry')}
              required={true}
              value={selectedInquiry}
              onChange={(value) => handleInquiryChanged(value)}
            >
              {inquiries.map((inquiry) => {
                return (
                  <Option key={inquiry.id} value={inquiry.text}>
                    {inquiry.text}
                  </Option>
                );
              })}
            </SelectInput>
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextInput
              required={true}
              field="title"
              label={t('title')}
              showError={true}
              getErrorMessage={() => getErrorMessage('title')}
              onChange={(event) => handleTitleChanged(event.target.value)}
              value={title}
            />
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextInput
              required={true}
              field="name"
              label={t('name')}
              showError={true}
              getErrorMessage={() => getErrorMessage('name')}
              onChange={(event) => handleNameChanged(event.target.value)}
              value={name}
            />
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextInput
              required={true}
              field="emailAddress"
              label={t('emailAddress')}
              showError={true}
              getErrorMessage={() => getErrorMessage('email')}
              onChange={(event) => handleEmailChanged(event.target.value)}
              value={email}
            />
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextInput
              field="companyName"
              label={t('companyName')}
              onChange={(event) => handleCompanyNameChanged(event.target.value)}
              showError={false}
              value={companyName}
            />
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextInput
              field="phoneNumber"
              label={t('phoneNumber')}
              onChange={(event) => handlePhoneNumberChanged(event.target.value)}
              type="number"
              value={phoneNumber}
            />
            <div id="phone_number-error" className={classes.errorBox}></div>
          </Col>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <TextAreaInput
              required={true}
              field="message"
              label={t('message')}
              showCount={true}
              autoSize={true}
              showError={true}
              getErrorMessage={() => getErrorMessage('message')}
              onChange={(event) => handleMessageChanged(event.target.value)}
              className={classes.textArea}
              value={message}
            />
          </Col>
          <Screenshot setScreenshotFinalImageUrl={setScreenshotFinalImageUrl} />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeDialog(false)} color="primary">
          <span>Cancel</span>
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          variant="contained"
          autoFocus
        >
          <span>
            {loading ? (
              <CircularProgress className={classes.loading} />
            ) : (
              'Send'
            )}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(withSnackbar(ContactUs));
