import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DeleteAccountImagesRequest } from '../../api-schemas/account';

const deleteAccountImagesRequest = ({
  accountId,
  data,
}: {
  accountId: string;
  data: DeleteAccountImagesRequest;
}) => {
  const body = JSON.stringify(data);

  return axios
    .post(`/api/Account/${accountId}/bulk-delete`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteAccountImages(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteAccountImages,
    isPending: isDeletingAccountImages,
    isSuccess: isSuccessDeletingAccountImages,
  } = useMutation({
    mutationFn: deleteAccountImagesRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'images', accountId],
      });
    },
  });

  return {
    deleteAccountImages,
    isDeletingAccountImages,
    isSuccessDeletingAccountImages,
  };
}
