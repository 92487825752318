import { z } from 'zod';

const AccountLanguage = z.object({
  languageCode: z.string(),
  hasPublishedTopic: z.boolean(),
});

const Account = z.object({
  id: z.string(),
  title: z.string(),
  slug: z.string(),
  email: z.string(),
  trackingId: z.string(),
  customDomain: z.string(),
  favicon: z.string(),
  style: z.string(),
  navbarVisibility: z.boolean(),
  shareButtonVisibility: z.boolean(),
  durationToReadVisibility: z.boolean(),
  darkModeVisibility: z.boolean(),
  googleSiteVerificationId: z.string(),
  bingSiteVerificationId: z.string(),
  googleTagManagerId: z.string(),
  websiteUrl: z.string(),
  numberOfTopics: z.number(),
  planId: z.string(),
  stripeCustomerId: z.string(),
  stripeSubscriptionId: z.string(),
  stripeSubscriptionStatus: z.string(),
  isFeedbackEnabled: z.boolean(),
  isDocumentExportEnabled: z.boolean(),
  createDateTime: z.string(),
  updateDateTime: z.string(),
  createdBy: z.string(),
  updatedBy: z.string(),
  processId: z.string(),
  isPrivate: z.boolean(),
  showAISearchResult: z.boolean(),
  menu: z.string(),
  hasPrivateDoc: z.boolean(),
  hasValidGoogleDriveToken: z.boolean(),
  whitelabel: z.boolean(),
  aiSearchEngineModel: z.string(),
  languages: z.array(AccountLanguage),
  defaultLanguageCode: z.string(),
});

const Accounts = z.array(Account);

const AccountWebAssistantSettings = z.object({
  id: z.string(),
  title: z.string(),
  buttonText: z.string(),
  backgroundColor: z.string(),
  buttonColor: z.string(),
  fontColor: z.string(),
  iconName: z.string(),
  iconLibrary: z.string(),
  panelType: z.string(),
  targetElementSelector: z.string(),
  position: z.string(),
  buttonType: z.string(),
  firstPage: z.string(),
  defaultDocumentId: z.string(),
  accountId: z.string(),
  isEnabled: z.boolean(),
  entity: z.string(),
});

const AccountImage = z.object({
  id: z.string(),
  originalUrl: z.string(),
  previewUrl: z.string().optional(),
  name: z.string(),
  numberOfUse: z.number().optional(),
  modified: z.boolean(),
});

const AccountImages = z.array(AccountImage);

const AccountPaymentMethod = z.object({
  id: z.string(),
  object: z.string(),
  acss_debit: z.string().nullable(),
  affirm: z.string().nullable(),
  afterpay_clearpay: z.string().nullable(),
  alipay: z.string().nullable(),
  au_becs_debit: z.string().nullable(),
  bacs_debit: z.string().nullable(),
  bancontact: z.string().nullable(),
  billing_details: z.object({
    address: z.object({
      city: z.string().nullable(),
      country: z.string().nullable(),
      line1: z.string().nullable(),
      line2: z.string().nullable(),
      postal_code: z.string(),
      state: z.string().nullable(),
    }),
    email: z.string().nullable(),
    name: z.string(),
    phone: z.string().nullable(),
  }),
  blik: z.string().nullable(),
  boleto: z.string().nullable(),
  card: z.object({
    brand: z.string(),
    checks: z.object({
      address_line1_check: z.string().nullable(),
      address_postal_code_check: z.string(),
      cvc_check: z.string(),
    }),
    country: z.string(),
    description: z.string().nullable(),
    exp_month: z.number(),
    exp_year: z.number(),
    fingerprint: z.string(),
    funding: z.string(),
    iin: z.string().nullable(),
    issuer: z.string().nullable(),
    last4: z.string(),
    networks: z.object({
      available: z.array(z.string()),
      preferred: z.string().nullable(),
    }),
    three_d_secure_usage: z.object({
      supported: z.boolean(),
    }),
    wallet: z.string().nullable(),
  }),
  card_present: z.string().nullable(),
  cashapp: z.string().nullable(),
  created: z.number(),
  customer: z.string(),
  customer_balance: z.string().nullable(),
  eps: z.string().nullable(),
  fpx: z.string().nullable(),
  giropay: z.string().nullable(),
  grabpay: z.string().nullable(),
  ideal: z.string().nullable(),
  interac_present: z.string().nullable(),
  klarna: z.string().nullable(),
  konbini: z.string().nullable(),
  link: z.string().nullable(),
  livemode: z.boolean(),
  metadata: z.object({}),
  oxxo: z.string().nullable(),
  p24: z.string().nullable(),
  paynow: z.string().nullable(),
  paypal: z.string().nullable(),
  pix: z.string().nullable(),
  promptpay: z.string().nullable(),
  radar_options: z.string().nullable(),
  sepa_debit: z.string().nullable(),
  sofort: z.string().nullable(),
  type: z.string(),
  us_bank_account: z.string().nullable(),
  wechat_pay: z.string().nullable(),
  zip: z.string().nullable(),
});

const AccountPaymentMethods = z.object({
  paymentMethods: z.object({
    object: z.string(),
    data: z.array(AccountPaymentMethod),
    has_more: z.boolean(),
    url: z.string(),
  }),
  defaultPaymentMethodId: z.string(),
});

const SaveAccountGeneralSettingsRequest = z.object({
  Id: z.string(),
  Title: z.string(),
  Slug: z.string(),
  TrackingId: z.string().optional(),
  Favicon: z.string().optional(),
  CustomDomain: z.string().optional(),
  Style: z.string().optional(),
  NavbarVisibility: z.boolean().optional(),
  ShareButtonVisibility: z.boolean().optional(),
  DurationToReadVisibility: z.boolean().optional(),
  DarkModeVisibility: z.boolean().optional(),
  GoogleSiteVerificationId: z.string().optional(),
  BingSiteVerificationId: z.string().optional(),
  GoogleTagManagerId: z.string().optional(),
  WebsiteUrl: z.string().optional(),
  IsFeedbackEnabled: z.boolean().optional(),
  IsDocumentExportEnabled: z.boolean().optional(),
  ProcessId: z.string().optional(),
  isPrivate: z.boolean().optional(),
  ShowAISearchResult: z.boolean().optional(),
  Menu: z.string().optional(),
  AiSearchEngineModel: z.string().optional(),
});

const AccountInvoice = z.object({
  id: z.string(),
  amount_due: z.number(),
  created: z.string(),
  invoice_pdf: z.string(),
});

const AccountInvoices = z.object({
  data: z.array(AccountInvoice),
});

const AccountToken = z.object({
  name: z.string(),
  id: z.string(),
});

const AccountTokens = z.array(AccountToken);

const GoogleDriveAccountToken = z.object({
  email: z.string().optional(),
});

const GenerateClientSecretRequest = z.object({
  accountId: z.string(),
});

const AddAccountPaymentMethodRequest = z.object({
  accountId: z.string(),
  paymentMethodId: z.string(),
});

const ChangePlanRequest = z.object({
  accountId: z.string(),
  planId: z.string(),
});

const UpdateAccountLanguagesRequest = z.object({
  accountId: z.string(),
  languages: z.array(AccountLanguage),
});

const UpdateAccountDefaultLanguageRequest = z.object({
  accountId: z.string(),
  defaultLanguageCode: z.string(),
});

const CreateAccountRequest = z.object({
  title: z.string(),
  slug: z.string(),
});

const DeleteAccountImagesRequest = z.object({
  imageIds: z.array(z.string()),
});

const UpdateAccountBillingSettingsRequest = z.object({
  id: z.string(),
  email: z.string(),
});

const CreateAccountTokenRequest = z.object({
  name: z.string(),
  accountId: z.string(),
  documentIds: z.array(z.string()),
});

const UpdateAccountPlanRequest = z.object({
  accountId: z.string(),
  planId: z.string(),
});

type Account = z.infer<typeof Account>;
type Accounts = z.infer<typeof Accounts>;
type AccountImage = z.infer<typeof AccountImage>;
type AccountImages = z.infer<typeof AccountImages>;
type AccountWebAssistantSettings = z.infer<typeof AccountWebAssistantSettings>;
type AccountPaymentMethod = z.infer<typeof AccountPaymentMethod>;
type AccountPaymentMethods = z.infer<typeof AccountPaymentMethods>;
type AccountLanguage = z.infer<typeof AccountLanguage>;
type SaveAccountGeneralSettingsRequest = z.infer<
  typeof SaveAccountGeneralSettingsRequest
>;
type GenerateClientSecretRequest = z.infer<typeof GenerateClientSecretRequest>;
type AddAccountPaymentMethodRequest = z.infer<
  typeof AddAccountPaymentMethodRequest
>;
type ChangePlanRequest = z.infer<typeof ChangePlanRequest>;
type UpdateAccountLanguagesRequest = z.infer<
  typeof UpdateAccountLanguagesRequest
>;
type UpdateAccountDefaultLanguageRequest = z.infer<
  typeof UpdateAccountDefaultLanguageRequest
>;
type GoogleDriveAccountToken = z.infer<typeof GoogleDriveAccountToken>;
type AccountInvoice = z.infer<typeof AccountInvoice>;
type AccountInvoices = z.infer<typeof AccountInvoices>;
type CreateAccountRequest = z.infer<typeof CreateAccountRequest>;
type DeleteAccountImagesRequest = z.infer<typeof DeleteAccountImagesRequest>;
type UpdateAccountBillingSettingsRequest = z.infer<
  typeof UpdateAccountBillingSettingsRequest
>;
type CreateAccountTokenRequest = z.infer<typeof CreateAccountTokenRequest>;
type AccountToken = z.infer<typeof AccountToken>;
type AccountTokens = z.infer<typeof AccountTokens>;
type UpdateAccountPlanRequest = z.infer<typeof UpdateAccountPlanRequest>;

export {
  Account,
  Accounts,
  AccountImage,
  AccountImages,
  AccountWebAssistantSettings,
  AccountPaymentMethod,
  AccountPaymentMethods,
  AccountLanguage,
  SaveAccountGeneralSettingsRequest,
  GenerateClientSecretRequest,
  AddAccountPaymentMethodRequest,
  ChangePlanRequest,
  UpdateAccountLanguagesRequest,
  UpdateAccountDefaultLanguageRequest,
  GoogleDriveAccountToken,
  AccountInvoice,
  AccountInvoices,
  CreateAccountRequest,
  DeleteAccountImagesRequest,
  UpdateAccountBillingSettingsRequest,
  CreateAccountTokenRequest,
  AccountToken,
  AccountTokens,
  UpdateAccountPlanRequest,
};
