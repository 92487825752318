import { useEffect, useRef } from 'react';

const useTimeout = (callback: (...args) => any, delay: number) => {
  const savedCallback = useRef<(...args) => void>();
  const timeoutId = useRef<NodeJS.Timer>();

  const handleClearTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  };

  const handleCreateTimeout = () => {
    function callbackFunc() {
      savedCallback.current?.();
    }

    timeoutId.current = setTimeout(callbackFunc, delay);
  };

  const handleResetTimeout = () => {
    handleClearTimeout();
    handleCreateTimeout();
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => handleClearTimeout();
  }, []);

  return {
    createTimeout: handleCreateTimeout,
    clearTimeout: handleCreateTimeout,
    resetTimeout: handleResetTimeout,
  };
};

export default useTimeout;
