import React, { Fragment, useContext, useState } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConfirmationDialog } from '../../../../confirmation-dialog';
import { topicService } from '../../../../../services/topic';
import { useTranslation } from 'react-i18next';
import { DocumentContext } from '../../../../../contexts/document';
import { useSnackbar } from 'notistack';

const useStyles: any = makeStyles(() => ({
  actionIcon: {
    fontSize: 'large',
    marginRight: '7px',
  },
  menuItem: {
    fontSize: '0.9rem',
  },
}));

const DiscardButton = (props) => {
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);

  const { selectedTopic } = useContext(DocumentContext);

  const topicAPI = new topicService();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleDiscard = async (topicId, languageCode) => {
    topicAPI
      .putDiscardTopic(topicId, languageCode)
      .then(async () => {
        props.setLoadingSoftly(true);
        props.loadTopics(null, null, selectedTopic.topicId);
      })
      .catch((error) => {
        if (error.errors && error.errors[0].field === 'Slug') {
          enqueueSnackbar(t(`error.duplicate-slug`), {
            variant: 'error',
          });
        }
        if (error.errors && error.errors[0].code === 'TopicVersionNotFound') {
          enqueueSnackbar(t(`error.deleted-parent`), {
            variant: 'error',
          });
        }
        props.setLoadingSoftly(false);
      });
  };

  return (
    <Fragment>
      <MenuItem
        onClick={() => {
          props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          setOpenDiscardDialog(true);
        }}
        className={classes.menuItem}
        disabled={props.isAlreadyPublished && !props.isDeleted}
      >
        <HistoryIcon className={classes.actionIcon} />
        <Typography variant="inherit">Discard changes</Typography>
      </MenuItem>
      <ConfirmationDialog
        title={'Discard changes'}
        message={t('topic-discard-changes-confirm-message')}
        discardChangesMessage={
          <ul style={{ paddingLeft: '15px' }}>
            {props.isNotPublishedYet && (
              <li>{t('not-published-yet-discard-changes-confirm-message')}</li>
            )}
            {!props.isAlreadyPublished && !props.isNotPublishedYet && (
              <li>
                {t('not-allready-published-discard-changes-confirm-message')}
              </li>
            )}
            {props.isDeleted && (
              <li>{t('undo-delete-discard-changes-confirm-message')}</li>
            )}
          </ul>
        }
        isOpen={openDiscardDialog}
        onDialogClosed={() => setOpenDiscardDialog(false)}
        onDialogConfirmed={() => {
          if (props.isNotPublishedYet) {
            props.onRemove(
              true,
              props.node,
              props.node.topicId || props.node.id
            );
          } else {
            handleDiscard(props.node.topicId, props.node.languageCode);
          }
          setOpenDiscardDialog(false);
        }}
      />
    </Fragment>
  );
};

export default DiscardButton;
