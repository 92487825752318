import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Feedbacks } from '../../api-schemas/feedback';

export type GetFeedbacksParamsType = {
  topicId: string;
  documentVersionId: string;
  documentId: string;
  sortedBy: string;
  rate: number | null;
  languageCode: string;
  topicVersionNumber: string;
  status: number | null;
  pageIndex: number;
  pageSize: number;
};

const getFeedbacksRequest = (params: GetFeedbacksParamsType) => {
  return axios
    .get<Feedbacks>('/api/feedback/search', {
      params,
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetFeedbacks(params?: GetFeedbacksParamsType) {
  const {
    data,
    refetch: getFeedbacks,
    isFetching: isLoadingFeedbacks,
    isSuccess: isSuccessLoadingFeedbacks,
  } = useQuery<Feedbacks>({
    staleTime: 1000 * 60 * 5,
    queryKey: ['feedbacks', params],
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => (params ? getFeedbacksRequest(params) : []),
  });

  const feedbacks = useMemo(() => data || [], [data]);

  return {
    feedbacks,
    isLoadingFeedbacks,
    isSuccessLoadingFeedbacks,

    getFeedbacks,
  };
}
