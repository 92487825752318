import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ItemType } from '../../api-schemas/folder';

const getItemTypeRequest = (typeId?: string, type: string = 'Topics') => {
  return axios
    .get<ItemType>(`/api/folder/item-type`, {
      params: { typeId, type },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetItemType(initialTopicId?: string) {
  const queryClient = useQueryClient();

  const {
    data: itemType,
    isFetching: isLoadingItemType,
    isError: isErrorLoadingItemType,
    isSuccess: isSuccessLoadingItemType,
  } = useQuery<ItemType>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['itemType', initialTopicId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!initialTopicId,
    queryFn: () => getItemTypeRequest(initialTopicId),
  });

  const getItemType = async (newTopicId?: string) => {
    if (!newTopicId) return;
    
    return queryClient.fetchQuery({
      queryKey: ['itemType', newTopicId],
      queryFn: () => getItemTypeRequest(newTopicId),
    });
  };

  return {
    itemType,
    isLoadingItemType,
    isErrorLoadingItemType,
    isSuccessLoadingItemType,
    getItemType, 
  };
}
