import React from 'react';
import { Card } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type RestrictedLabelProps = {
  mHeight?: string;
  contentBgColor?: string;
  textContent?: React.ReactNode; 
  iconFont?: string;
  textFont?: string;
  backgroundColor?: string;
};

const RestrictedLabel: React.FC<RestrictedLabelProps> = ({
  mHeight = 'auto',
  contentBgColor = 'black',
  textContent = 'This is only available for Enterprise users.',
  iconFont = '22px',
  textFont = '14px',
  backgroundColor = 'white',
}) => {
  return (
    <Card
      bordered
      style={{
        width:'100%',
        display: 'inline-block',
        height: mHeight,
        borderRadius: '20px',
        overflow: 'hidden',
        marginTop: '9px',
        backgroundColor: backgroundColor,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ExclamationCircleOutlined
          style={{
            width: '16px',
            fontSize: iconFont,
            color: contentBgColor,
            marginRight: '10px',
          }}
        />
        <span
          style={{
            color: contentBgColor,
            fontSize: textFont,
            display: 'inline-block',
            whiteSpace: 'normal', 
            wordWrap: 'break-word',
          }}
        >
          {textContent}
        </span>
      </div>
    </Card>
  );
};

export default RestrictedLabel;
