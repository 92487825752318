import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { DraftTopic } from '../../api-schemas/topic';
import { emptyGuid } from '../../helpers/common';
import { ErrorResponse } from '../../api-schemas/error';
import useGetDraftTopicTree from './useGetDraftTopicTree';

const updateTopicRequest = (
  data: DraftTopic & { overwrite: boolean; folderId?: string | null }
) => {
  const body = JSON.stringify({ ...data, id: data.topicId });

  return axios
    .put(`/api/Topic?overwrite=${data.overwrite}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useSaveTopic({
  documentVersionId,
  languageCode,
  onSuccessCallback,
  onFailureCallback,
  onErrorMessageCallback,
}: {
  documentVersionId?: string;
  languageCode?: string;
  onSuccessCallback?: () => void;
  onFailureCallback?: () => void;
  onErrorMessageCallback?: (message: string) => void;
}) {
  const { getDraftTopicTree } = useGetDraftTopicTree({
    documentVersionId: documentVersionId,
    languageCode: languageCode,
  });

  const {
    mutateAsync: saveTopic,
    isPending: isSavingTopic,
    isSuccess: isSuccessSavingTopic,
  } = useMutation({
    mutationFn: updateTopicRequest,
    onSuccess: async () => {
      if (documentVersionId && documentVersionId !== emptyGuid()) {
        getDraftTopicTree();
      }

      onSuccessCallback?.();
    },
    onError: async (errorResponse: ErrorResponse) => {
      if (errorResponse) {
        const errorMessage = errorResponse.response?.data?.errors
          .map((err) => {
            return `${err.field}`;
          })
          .join('\n');

        onErrorMessageCallback?.(errorMessage || '');
      }

      onFailureCallback?.();
    },
  });

  return {
    saveTopic,
    isSavingTopic,
    isSuccessSavingTopic,
  };
}
