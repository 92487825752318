import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Dispatch, SetStateAction } from 'react';

import { FolderBreadCrumbItemType } from '../../component-schemas/folder';
import { AccountContext } from '../account';
import { Folder } from '../../api-schemas/folder';

type FolderContextType = {
  selectedFolderId?: string;
  setSelectedFolderId: Dispatch<SetStateAction<string | undefined>>;
  selectedFolders: FolderBreadCrumbItemType[];
  setSelectedFolders: Dispatch<SetStateAction<FolderBreadCrumbItemType[]>>;
};

const initialState: FolderContextType = {
  setSelectedFolderId: () => {},
  selectedFolders: [],
  setSelectedFolders: () => [],
};

export const FolderContext = createContext<FolderContextType>(initialState);

export function FolderProvider({ children }) {
  const { t } = useTranslation();

  const [selectedFolderId, setSelectedFolderId] = React.useState<string>();
  const [selectedFolders, setSelectedFolders] = React.useState<
    FolderBreadCrumbItemType[]
  >([
    {
      title: t('home'),
    },
  ]);

  const { selectedLanguage } = useContext(AccountContext);

  useEffect(() => {
    if (selectedLanguage) {
      setSelectedFolders((prevSelectedFolders) => [
        {
          title: t('home'),
        },
        ...prevSelectedFolders.slice(1).map((folder) => {
          const folderTitle = (folder as Folder).titles.find(
            (title) => title.languageCode === selectedLanguage
          );

          return {
            ...folder,
            title: folderTitle?.title,
          };
        }),
      ]);
    }
  }, [selectedLanguage]);

  const value = {
    selectedFolderId,
    setSelectedFolderId,
    selectedFolders,
    setSelectedFolders,
  };

  return (
    <FolderContext.Provider value={value}>{children}</FolderContext.Provider>
  );
}
