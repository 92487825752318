import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Dropdown, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import WatchersList from '../watchers-list';
import { DocumentContext } from '../../../contexts/document';
import { AiOutlineBell, AiFillBell } from 'react-icons/ai';
import useGetUserSubscriptions from '../../../api-hooks/user/useGetUserSubscriptions';
import useAddUserSubscription from '../../../api-hooks/user/useAddUserSubscription';
import useRemoveUserSubscription from '../../../api-hooks/user/useRemoveUserSubscription';

const useStyles: any = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 11,
  },
  dropDownButton: {
    '& > button:first-child': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 8px',
    },
  },
}));

const WatchSettings = ({
  entityType,
  entityId,
  hasMargin,
  refreshWatchersList,
  setRefreshWatchersList,
}) => {
  const { selectedDoc } = useContext(DocumentContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { subscriptions, isLoadingSubscriptions, isErrorLoadingSubscriptions } =
    useGetUserSubscriptions();
  const { addUserSubscription, isAddingUserSubscription } =
    useAddUserSubscription({ entityType, entityId });
  const { removeUserSubscription, isRemovingUserSubscription } =
    useRemoveUserSubscription({ entityType, entityId });

  const loading =
    isLoadingSubscriptions ||
    isAddingUserSubscription ||
    isRemovingUserSubscription;

  const showErrorMessage = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const showSuccessMessage = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
    });
  };

  useEffect(() => {
    if (isErrorLoadingSubscriptions) {
      showErrorMessage(t('error.unknown'));
    }
  }, [isErrorLoadingSubscriptions]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    switch (entityType) {
      case 'Topics':
        // eslint-disable-next-line no-case-declarations
        const unwatchDocParam = urlParams.get('unwatchDoc');
        if (unwatchDocParam && unwatchDocParam === 'true') {
          handleUnSubscribe(null, selectedDoc.id, true);
        }
        // eslint-disable-next-line no-case-declarations
        const unwatchTopicParam = urlParams.get('unwatchTopic');
        if (unwatchTopicParam && unwatchTopicParam === 'true') {
          handleUnSubscribe(null, null, true);
        }
        break;
      case 'Publishes':
        // eslint-disable-next-line no-case-declarations
        const unwatchPRParam = urlParams.get('unwatch');
        if (unwatchPRParam && unwatchPRParam === 'true') {
          handleUnSubscribe(null, null, true);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  const handleSubscribe = () => {
    addUserSubscription().catch(() => {
      showErrorMessage(t('error.unknown'));
    });
  };

  const handleUnSubscribe = async (
    event: any,
    incomingEntityId: string | null = null,
    callShowSuccessMessage: boolean = false
  ) => {
    const subscriptionId = subscriptions.filter(
      (subscription) => subscription.entityId === (incomingEntityId || entityId)
    )[0]?.id;

    if (subscriptionId) {
      removeUserSubscription(subscriptionId)
        .then(() => {
          setRefreshWatchersList(true);

          if (callShowSuccessMessage) {
            showSuccessMessage(t('success.unsubscribe'));
          }
        })
        .catch(() => {
          showErrorMessage(t('error.unknown'));
        });
    }
  };

  const menuItems = WatchersList({
    entityType,
    entityId,
    refreshList: refreshWatchersList,
    setRefreshList: setRefreshWatchersList,
  });

  if (entityId && !loading) {
    const isSubscribed = subscriptions.filter(
      (subscription) => subscription.entityId === entityId
    ).length;
    const entityTypeTitle =
      entityType === 'Documents'
        ? 'document'
        : entityType === 'Topics'
          ? 'topic'
          : entityType === 'Publishes'
            ? 'publish-request'
            : 'entity';
    return (
      <Dropdown.Button
        style={hasMargin ? { marginRight: 5 } : {}}
        onClick={isSubscribed ? handleUnSubscribe : handleSubscribe}
        className={classes.dropDownButton}
        menu={{ items: menuItems }}
        placement="bottom"
        icon={<DownOutlined />}
      >
        {isSubscribed ? (
          <Tooltip
            placement="bottom"
            title={t<string>('unwatch-help-text') + t(entityTypeTitle)}
          >
            <AiFillBell style={{ fontSize: 14 }} />
          </Tooltip>
        ) : (
          <Tooltip
            placement="bottom"
            title={t<string>('watch-help-text') + t(entityTypeTitle)}
          >
            <AiOutlineBell style={{ fontSize: 14 }} />
          </Tooltip>
        )}
      </Dropdown.Button>
    );
  } else {
    return (
      <Box
        className={classes.button}
        style={hasMargin ? { marginRight: 5 } : {}}
      >
        <Skeleton width={64} height={32} />
      </Box>
    );
  }
};

export default WatchSettings;
