import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { RevalidateData } from '../../api-schemas/reference-links';

const getRevalidateLinksRequest = ({
  sourceTopicId,
  targetTopicId,
}: {
  sourceTopicId?: string;
  targetTopicId?: string;
}) => {
  return axios
    .get<RevalidateData>(
      `/api/content-processor/topic-link/revalidate?sourceTopicId=${sourceTopicId}&targetTopicId=${targetTopicId}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useGetRevalidateLinks({
  sourceTopicId,
  targetTopicId,
}: {
  sourceTopicId?: string;
  targetTopicId?: string;
}) {
  const {
    data,
    refetch: getRevalidateLinks,
    isFetching: isLoadingRevalidateLinks,
    isRefetching: isRefetchingRevalidateLinks,
    isError: isErrorLoadingRevalidateLinks,
    isSuccess: isSuccessLoadingRevalidateLinks,
  } = useQuery<RevalidateData>({
    staleTime: 0,
    queryKey: ['RevalidateLinks', sourceTopicId, targetTopicId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => getRevalidateLinksRequest({ sourceTopicId, targetTopicId }),
    enabled: false,
  });

  const RevalidateLinks = useMemo(() => data, [data]);

  return {
    RevalidateLinks,
    isLoadingRevalidateLinks,
    isRefetchingRevalidateLinks,
    isErrorLoadingRevalidateLinks,
    isSuccessLoadingRevalidateLinks,

    getRevalidateLinks,
  };
}
