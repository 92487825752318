import React, { Fragment, MouseEvent, useState } from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  Collapse,
  Typography,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddPaymentMethod from '../payment-methods/add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { plansData, allFeatures } from './data';
import { useTranslation } from 'react-i18next';
import ContactUs from '../../../contact-us';
import './styles.css';
import { Col, Row } from 'antd';

const styles: any = (theme: Theme) => ({
  info: {
    margin: '20px 0',
    textAlign: 'center',
  },
  currentPlanInfo: {
    color: '#000',
    border: '1px solid #c0bebe',
    backgroundColor: '#e5e1e1',
    padding: '10px 10px',
    borderRadius: '3px',
    textAlign: 'left',
  },
  PlanInfo: {
    fontWeight: 'bold',
    margin: '10px 0 5px 0',
    fontSize: '18px',
  },
  plansBox: {
    margin: '5px 0',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    fontSize: '14px',
  },
  planBox: {
    '& > div': {
      border: '1px solid rgba(0, 0, 0, 0.08)',
      padding: '10px 0!important',
      flex: 'auto!important',
      [theme.breakpoints.down('xs')]: {
        minHeight: '300px',
        marginBottom: '10px',
        borderRight: 'none',
      },
    },
  },
  lastPlanBox: {
    '& > div': {
      border: '1px solid rgba(0, 0, 0, 0.08)',
      padding: '10px 0!important',
      flex: 'auto!important',
      [theme.breakpoints.down('xs')]: {
        minHeight: '300px',
        marginBottom: '10px',
      },
    },
  },
  planName: {
    fontWeight: 'bold',
    minHeight: '50px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '50px',
    },
    padding: '0 10px',
    fontSize: '20px',
    marginBottom: '5px',
  },
  planPrice: {
    fontWeight: 'bold',
    padding: '0 10px',
    fontSize: '18px',
  },
  planPriceLabel: {
    padding: '0 10px',
    fontSize: '16px',
    minHeight: 75,
    [theme.breakpoints.down('md')]: {
      minHeight: 110,
    },
    marginBottom: '25px',
  },
  paymentPrice: {
    marginBottom: '5px',
  },
  price: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  paymentDescription: {
    // minHeight: "150px",
    [theme.breakpoints.down('xs')]: {
      minHeight: '90px',
    },
    padding: '0 10px',
  },
  description: {
    display: 'block',
    color: '#bebcbc',
  },
  featuresHeader: {
    fontWeight: 'bold',
    marginBottom: '5px',
    padding: '0 10px',
  },
  features: {
    minHeight: '185px',
    textAlign: 'left',
    transition: 'height 0.15s ease;',
    [theme.breakpoints.down('xs')]: {
      minHeight: '100px',
    },
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  button: {
    textAlign: 'center',
  },
  paymentButton: {
    backgroundColor: '#2979ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2979ff',
    },
    width: '100%',
    textTransform: 'initial',
  },
  doneIcon: {
    fill: '#59ce9f!important',
    marginRight: '5px',
    fontSize: '20px',
  },
  clearIcon: {
    fill: '#fc8368',
    marginRight: '5px',
    fontSize: '20px',
  },
  plansParent: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    placeItems: 'center',
  },
  learnMore: {
    marginTop: '10px',
    textAlign: 'center',
  },
  seeAllFeatures: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    margin: '10px 0',
  },
  cardContent: {
    padding: '0',
  },
  seeAllButton: {
    fontSize: '12px',
  },
  currentPlan: {
    padding: '6px 0',
    fontSize: '0.875rem',
    backgroundColor: '#59ce9f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#59ce9f',
    },
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    fontWeight: '500',
    lineHeight: '1.75',
    textTransform: 'initial',
  },
  lowerCase: {
    textTransform: 'initial',
  },
  allFeatures: {
    margin: 'auto',
    marginBottom: '10px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  tableHeader: {
    display: 'flex',
  },
  tableRow: {
    display: 'flex',
    height: '40px',
  },
});

const Plan = (props: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [clickedPlanId, setClickedPlanId] = useState<string | null>(null);
  const [openContactUsForm, setOpenContactUsForm] =
    useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleCloseAlertDialog = () => {
    setOpenAlert(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const changePlan = async (
    event: MouseEvent,
    planId: string,
    isFreePlan: boolean
  ) => {
    if (planId) {
      if (props.paymentMethods && props.paymentMethods.data && !isFreePlan) {
        if (
          props.paymentMethods.data.length &&
          props.paymentMethods.defaultPaymentMethodId === null
        ) {
          setOpenAlert(true);
          setTimeout(() => {
            props.onChange(event, 1);
          }, 3000);
          return;
        } else if (!props.paymentMethods.data.length) {
          setClickedPlanId(planId);
          setOpenPaymentDialog(true);
          return;
        }
      }
      setLoading(true);
      await props.changePlan(planId);
      setLoading(false);
    }
  };

  const classes = props.classes;

  return (
    <Row style={{ display: 'block' }}>
      <Col xs={24}>
        <Typography
          style={{ marginBottom: 15, marginTop: 15 }}
          component="h1"
          variant="h5"
        >
          {t('plan-settings-title')}
        </Typography>
      </Col>
      {props.currentPlanId !== '' && !loading && props.plans.length ? (
        <Fragment>
          <Col xs={24} className={classes.info}>
            <div className={classes.currentPlanInfo}>
              <span>{`You are using the "${props.currentPlanName}" plan. ${props.currentPlanName?.includes('Old') ? t('current-plan-extra-info') : ''}`}</span>
            </div>
          </Col>
          <Col xs={24} className={classes.info}>
            <div className={classes.PlanInfo}>
              <span>Choose the plan that is right for your team</span>
            </div>
            <div>
              <span>Pay by month and cancel at any time</span>
            </div>
          </Col>
          <Col xs={24} className={classes.plansBox}>
            <Col
              xs={24}
              className={classes.plansParent}
              style={{ display: 'flex', alignItems: 'stretch', padding: 0 }}
            >
              <Row
                gutter={16}
                style={{
                  display: 'flex',
                  alignItems: 'stretch',
                  marginBottom: 10,
                }}
              >
                {plansData.map((planInfo, counter) => {
                  const planData = props.plans.find(
                    (plan) => plan.name === planInfo.name
                  );
                  return (
                    <Col
                      key={planInfo.name}
                      span={6}
                      className={
                        counter === plansData.length - 1
                          ? classes.lastPlanBox
                          : classes.planBox
                      }
                    >
                      <Col
                        xs={24}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                        }}
                      >
                        <Col
                          xs={24}
                          className={classes.planName}
                          style={{ flexBasis: 'auto', padding: 0 }}
                        >
                          <span>{planInfo.name}</span>
                        </Col>
                        <Col
                          xs={24}
                          className={classes.planPriceLabel}
                          style={{ flexBasis: 'auto', padding: 0 }}
                        >
                          <span>{planInfo.title}</span>
                        </Col>
                        <Col
                          xs={24}
                          className={classes.planPrice}
                          style={{ flexBasis: 'auto', padding: 0 }}
                        >
                          <span>{planInfo.price}</span>
                        </Col>
                        <Col
                          xs={24}
                          className={classes.planPriceLabel}
                          style={{ flexBasis: 'auto', padding: 0 }}
                        >
                          <span>{planInfo.priceLabel}</span>
                        </Col>
                        <Col xs={24} style={{ padding: 0, flex: 1 }}>
                          <Col xs={24} className={classes.featuresHeader}>
                            <span>Top Features</span>
                          </Col>
                          <Col xs={24} className={classes.features}>
                            {planInfo.topFeatures.map((feature, counter) => {
                              return (
                                <Col
                                  xs={24}
                                  className={classes.feature}
                                  key={counter}
                                >
                                  <DoneIcon className={classes.doneIcon} />
                                  <span>{feature}</span>
                                </Col>
                              );
                            })}
                          </Col>
                        </Col>
                        <Col
                          xs={24}
                          className={classes.button}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexBasis: 'auto',
                          }}
                        >
                          {planData?.id === props.currentPlanId &&
                          !props.currentPlanName.includes('Old') ? (
                            <div className={classes.currentPlan}>
                              <span>Current plan</span>
                            </div>
                          ) : planInfo.name === 'Enterprise Plus' ? (
                            <Button
                              className={classes.paymentButton}
                              onClick={() => setOpenContactUsForm(true)}
                              variant="contained"
                            >
                              <span>Contact Us</span>
                            </Button>
                          ) : (
                            <Button
                              className={classes.paymentButton}
                              onClick={(event) =>
                                changePlan(
                                  event,
                                  planData?.id,
                                  planData?.name === 'Free'
                                )
                              }
                              variant="contained"
                            >
                              <span>Change to this plan</span>
                            </Button>
                          )}
                        </Col>
                      </Col>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col xs={24} className={classes.seeAllFeatures}>
              <Button
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="See all features"
                className={classes.seeAllButton}
              >
                {expanded ? (
                  <div className={classes.actions}>
                    <span>Hide all features</span>
                    <ExpandLessIcon />
                  </div>
                ) : (
                  <div className={classes.actions}>
                    <span>Show all features</span>
                    <ExpandMoreIcon />
                  </div>
                )}
              </Button>
            </Col>
            <Col xs={24} className={classes.allFeatures}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Col xs={24} className={classes.tableHeader + ' tableHeader'}>
                  <Col xs={8}>Feature</Col>
                  {plansData.map((plan, _index) => {
                    return (
                      <Col xs={4} key={_index}>
                        <span>{plan.name}</span>
                      </Col>
                    );
                  })}
                </Col>
                {allFeatures.map((featureCategory, _index) => {
                  return (
                    <Fragment key={_index}>
                      <Col xs={24} className={classes.tableRow + ' tableRow'}>
                        <Grid style={{ fontWeight: 'bold' }}>
                          <span>{featureCategory.title}</span>
                        </Grid>
                      </Col>
                      {featureCategory.data.map((featureData, _index) => {
                        return featureData.type === 'Numerical' ? (
                          <Col
                            key={_index}
                            xs={24}
                            className={classes.tableRow + ' tableRow'}
                          >
                            <Col xs={8}>{featureData.name}</Col>
                            <Col xs={4}>{featureData.free}</Col>
                            <Col xs={4}>{featureData.business}</Col>
                            <Col xs={4}>{featureData.enterprise}</Col>
                            <Col xs={4}>{featureData.enterprisePlus}</Col>
                          </Col>
                        ) : (
                          <Col
                            key={_index}
                            xs={24}
                            className={classes.tableRow + ' tableRow'}
                          >
                            <Col xs={8}>{featureData.name}</Col>
                            <Col xs={4}>
                              {featureData.free ? (
                                <HiCheckCircle className={classes.doneIcon} />
                              ) : (
                                <HiXCircle className={classes.clearIcon} />
                              )}
                            </Col>
                            <Col xs={4}>
                              {featureData.business ? (
                                <HiCheckCircle className={classes.doneIcon} />
                              ) : (
                                <HiXCircle className={classes.clearIcon} />
                              )}
                            </Col>
                            <Col xs={4}>
                              {featureData.enterprise ? (
                                <HiCheckCircle className={classes.doneIcon} />
                              ) : (
                                <HiXCircle className={classes.clearIcon} />
                              )}
                            </Col>
                            <Col xs={4}>
                              {featureData.enterprisePlus ? (
                                <HiCheckCircle className={classes.doneIcon} />
                              ) : (
                                <HiXCircle className={classes.clearIcon} />
                              )}
                            </Col>
                          </Col>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Collapse>
            </Col>
            <Col
              xs={24}
              style={{ textAlign: 'center', marginTop: 60, marginBottom: 30 }}
            >
              <img
                src={process.env.PUBLIC_URL + '/stripe-badge.png'}
                alt="Stripe"
                style={{ width: '40%' }}
              />
            </Col>
            <Dialog
              fullScreen={fullScreen}
              open={openPaymentDialog}
              onClose={handleCloseDialog}
              aria-labelledby="responsive-dialog-title"
              className={classes.dialog}
            >
              <DialogTitle id="responsive-dialog-title">
                <span>{'Add payment method'}</span>
              </DialogTitle>
              <DialogContent>
                <h4>
                  <span>You do not have any payment method</span>
                </h4>
                <AddPaymentMethod
                  accountConfiguration={props.accountConfiguration}
                  changePlan={props.changePlan}
                  planId={clickedPlanId}
                  updatePaymentMethods={props.updatePaymentMethods}
                  setOpenPaymentDialog={setOpenPaymentDialog}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCloseDialog} color="primary">
                  <span>Close</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullScreen={fullScreen}
              open={openAlert}
              onClose={handleCloseAlertDialog}
              aria-labelledby="responsive-dialog-title"
              className={classes.dialog}
            >
              <DialogTitle id="responsive-dialog-title">
                <span>{"You don't have any default payment method"}</span>
              </DialogTitle>
              <DialogContent>
                <h4>
                  <span>Please select a payment method as a default</span>
                </h4>
              </DialogContent>
            </Dialog>
          </Col>
          <ContactUs
            openDialog={openContactUsForm}
            closeDialog={setOpenContactUsForm}
          />
        </Fragment>
      ) : (
        <CircularProgress></CircularProgress>
      )}
    </Row>
  );
};

export default withStyles(styles)(Plan);
