import axios from 'axios';
import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { GoogleDriveAccountToken } from '../../api-schemas/account';

const getGoogleDriveAccountTokenRequest = (accountId: string) => {
  return axios
    .get(`/api/google-drive-integration/account/${accountId}/token`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetGoogleDriveAccountToken(accountId: string) {
  const {
    data,
    refetch: getGoogleDriveAccountToken,
    isFetching: isLoadGoogleDriveAccountToken,
    isRefetching: isRefetchingGoogleDriveAccountToken,
    isError: isErrorLoadingGoogleDriveAccountToken,
    isSuccess: isSuccessLoadingGoogleDriveAccountToken,
  } = useQuery<GoogleDriveAccountToken>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['googleDriveAccountToken'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => getGoogleDriveAccountTokenRequest(accountId),
    enabled: true,
  });

  const googleDriveAccountToken = useMemo(() => data, [data]);

  return {
    googleDriveAccountToken,
    isLoadGoogleDriveAccountToken,
    isRefetchingGoogleDriveAccountToken,
    isErrorLoadingGoogleDriveAccountToken,
    isSuccessLoadingGoogleDriveAccountToken,

    getGoogleDriveAccountToken,
  };
}
