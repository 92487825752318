import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../inputs/text-input';
import { convertToSlug, emptyGuid } from '../../../../helpers/common';
import { Variable } from '../../../../api-schemas/variable';

const ManageVariableDialog = ({
  onOk,
  handleClose,
  accountId,
  isOpen,
  existingVariable,
  loading,
}: {
  onOk: (variable: Variable) => void;
  handleClose: any;
  accountId: string;
  isOpen: boolean;
  loading: boolean;
  existingVariable?: Variable;
}) => {
  const [variable, setVariable] = useState<Variable>(
    existingVariable ?? {
      accountId: accountId,
      title: '',
      value: '',
      slug: '',
      id: emptyGuid(),
    }
  );

  const { t } = useTranslation();

  const handleChangeTitle = (event) => {
    setVariable({
      ...variable,
      title: event.target.value,
      slug: convertToSlug(event.target.value),
    });
  };

  const handleChangeValue = (event) => {
    setVariable({ ...variable, value: event.target.value });
  };

  return (
    <Modal
      bodyStyle={{ minWidth: 500, minHeight: 200 }}
      width={500}
      title={t('addVariable')}
      destroyOnClose
      open={isOpen}
      confirmLoading={loading}
      onCancel={handleClose}
      onOk={() => onOk(variable)}
    >
      <TextInput
        label={t('title')}
        field={'title'}
        value={variable.title}
        onChange={handleChangeTitle}
      />
      <TextInput
        style={{ marginTop: 8 }}
        label={t('value')}
        field={'value'}
        value={variable.value}
        onChange={handleChangeValue}
      />
    </Modal>
  );
};

export default ManageVariableDialog;
