import config from 'react-global-configuration';
import { v4 as uuidv4 } from 'uuid';
import data from '../../components/metrics-analytics/Metrics.json';
import { DraftTopicTree, DraftTopicTreeItem } from '../../api-schemas/topic';

const seoData = data.seo;
const readabilityData = data.readability;

export const convertToSlug = (text: string) => {
  let newText = text;
  if (newText) {
    newText = newText
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/_/g, '-')
      .replace(/&/g, 'and')
      .replace(/\./g, '-')
      .replace(/[^\w-]+/g, '-');
  }
  while (newText.search('--') !== -1) {
    newText = newText.replace('--', '-');
  }
  return newText;
};

export const shortenTitle = (title: string, length: number) => {
  if (title.length < length) return htmlDecode(title);
  else return htmlDecode(title.substring(0, length - 1) + '...');
};

export const getNotReservedTopicIndex = (
  topicSlug: string,
  topics,
  topicId: string
) => {
  const numberRegex = /^\d+$/;
  let maxIndex = 0;
  // Array for storing indexes
  const indexesArray: number[] = [];
  // Variable for checking if there is a topic with the current slug
  let hasOneTopicWithCurrentSlug = false;
  topics?.map((topic) => {
    if (topic.id !== topicId) {
      // Checking if there is a topic with the current slug
      if (!hasOneTopicWithCurrentSlug) {
        const firstNewIndex = topic.slug ? topic.slug.indexOf(topicSlug) : -1;
        const remainingStringFirstNewTopic = topic.slug
          ? topic.slug.substr(firstNewIndex + topicSlug.length)
          : '';
        if (firstNewIndex === 0 && remainingStringFirstNewTopic.length === 0) {
          hasOneTopicWithCurrentSlug = true;
        }
      }
      // Get max index of current slug + 1, as they are in this format : {currentSlug}-{index}
      const index = topic.slug ? topic.slug.indexOf(topicSlug + '-') : -1;
      const remainingString = topic.slug
        ? topic.slug.substr(index + (topicSlug + '-').length)
        : '';
      if (
        index === 0 &&
        remainingString.length &&
        numberRegex.test(remainingString)
      ) {
        indexesArray.push(parseInt(remainingString));
        if (maxIndex <= parseInt(remainingString))
          maxIndex = parseInt(remainingString) + 1;
      }
    }
    return true;
  });

  // Set start index from 1 for {currentSlug}-{index} if there is a topic with the current slug and also there is only one topic with the current topic slug
  if (hasOneTopicWithCurrentSlug && maxIndex === 0) {
    maxIndex = 1;
    return maxIndex;
  }

  if (!hasOneTopicWithCurrentSlug && maxIndex !== 0) {
    return 0;
  }

  // Find a not reserved index between indexes
  for (let i = 1; i < maxIndex; i++) {
    if (indexesArray.indexOf(i) < 0) {
      maxIndex = i;
      break;
    }
  }

  return maxIndex;
};

export const emptyGuid = () => '00000000-0000-0000-0000-000000000000';

export const createNewTopics = (
  parentTopicId: string | null,
  selectedLanguage,
  topicsGlobalVariable,
  topicContentType: string = 'CKEditor5'
) => {
  const topicId = uuidv4();
  const newSlug = topicContentType === 'Component' ? 'new-page' : 'new-topic';
  const maxIndex: number = getNotReservedTopicIndex(
    newSlug,
    topicsGlobalVariable,
    topicId
  );
  const slug = maxIndex ? `${newSlug}-${maxIndex}` : newSlug;
  return {
    children: [],
    parentTopicId: parentTopicId,
    languageCode: selectedLanguage.languageCode,
    title: topicContentType === 'Component' ? 'New Page' : 'New Topic',
    slug: slug,
    topicLanguageCode: 'en',
    documentLanguageCode: 'en',
    id: topicId,
    topicId: topicId,
    topicVersionId: emptyGuid(),
    contentType: topicContentType,
  };
};

export const createNewTopicContent = (
  parentTopicId,
  selectedLanguage,
  selectedVersion,
  topic,
  topicContentType: string = 'CKEditor5'
) => {
  const topicId = topic ? topic.id || topic.topicId : emptyGuid();
  return {
    body:
      topicContentType !== 'Component'
        ? topic?.body ?? '<h1>New Topic</h1><p></p>'
        : '',
    componentBody: [],
    contentType: topicContentType,
    languageCode: selectedLanguage,
    documentVersionId: selectedVersion.id,
    lastModificationDate: new Date().toISOString(),
    lastModifierRef: emptyGuid(),
    parentTopicId: parentTopicId,
    title:
      topicContentType === 'Component'
        ? 'New Page'
        : topic?.title ?? 'New Topic',
    slug: topic.slug,
    topicId: topicId,
    topicVersionId: emptyGuid(),
    overwrite: false,
  };
};

export const createNewAccountDraftTopic = ({
  languageCode,
  accountId,
  contentType,
}) => ({
  body: '<h1>New Topic</h1><p></p>',
  componentBody: [],
  contentType,
  languageCode,
  parentTopicId: null,
  title: 'New Topic',
  slug: 'new-topic',
  topicId: uuidv4(),
  topicVersionId: emptyGuid(),
  isHidden: false,
  accountId,
  overwrite: false,
});

export const isNewTopic = (topics, selectedTopicId: string) => {
  const filteredTopic = topics?.find(
    (topic) =>
      (topic.id || topic.topicId) === selectedTopicId &&
      topic.topicVersionId !== emptyGuid()
  );
  if (filteredTopic) {
    return false;
  } else {
    return true;
  }
};

const findParentTopic = (
  parentTopicId: string,
  draftTopicTree?: DraftTopicTree
): DraftTopicTreeItem | undefined => {
  let parentTopic: DraftTopicTreeItem | undefined;

  for (const draftTopicTreeItem of draftTopicTree ?? []) {
    if (draftTopicTreeItem.topicId === parentTopicId) {
      return draftTopicTreeItem;
    }

    parentTopic = findParentTopic(parentTopicId, draftTopicTreeItem.children);

    if (parentTopic) {
      return parentTopic;
    }
  }

  return undefined;
};

export const findPreviousTopic = (
  draftTopicTreeItem: DraftTopicTreeItem,
  draftTopicTree?: DraftTopicTree
) => {
  const parentTopicId = draftTopicTreeItem?.parentTopicId;
  const parentTopic = findParentTopic(parentTopicId, draftTopicTree);

  let prevTopic: DraftTopicTreeItem;

  parentTopic?.children.map((child) => {
    if (
      child.topicId !== draftTopicTreeItem.topicId &&
      child.topicOrder < draftTopicTreeItem.topicOrder &&
      (!prevTopic ||
        prevTopic.topicOrder === null ||
        child.topicOrder > prevTopic.topicOrder)
    ) {
      prevTopic = child;
    }
  });

  //eslint-disable-next-line
  //@ts-expect-error
  return prevTopic;
};

export const findNextTopic = (
  draftTopicTreeItem: DraftTopicTreeItem,
  draftTopicTree?: DraftTopicTree
) => {
  const parentTopicId = draftTopicTreeItem?.parentTopicId;
  const parentTopic: DraftTopicTreeItem | undefined = findParentTopic(
    parentTopicId,
    draftTopicTree
  );

  let nextTopic: DraftTopicTreeItem;
  if (parentTopic) {
    parentTopic.children.map((child) => {
      if (
        child.topicId !== draftTopicTreeItem.topicId &&
        child.topicOrder < draftTopicTreeItem.topicOrder &&
        (!nextTopic ||
          nextTopic.topicOrder === null ||
          child.topicOrder < nextTopic.topicOrder)
      ) {
        nextTopic = child;
      }
    });
  }

  //eslint-disable-next-line
  //@ts-expect-error
  return nextTopic;
};

export const findNextTopicOnDropAfter = (
  draftTopicTreeItem: DraftTopicTreeItem,
  draftTopicTree?: DraftTopicTree
) => {
  const parentTopicId = draftTopicTreeItem?.parentTopicId;
  const parentTopic: DraftTopicTreeItem | undefined = findParentTopic(
    parentTopicId,
    draftTopicTree
  );

  let nextTopic: DraftTopicTreeItem;
  if (parentTopic) {
    parentTopic.children.map((child) => {
      if (
        child.topicId !== draftTopicTreeItem.topicId &&
        child.topicOrder > draftTopicTreeItem.topicOrder &&
        (!nextTopic ||
          nextTopic.topicOrder === null ||
          child.topicOrder < nextTopic.topicOrder)
      ) {
        nextTopic = child;
      }
    });
  }

  //eslint-disable-next-line
  //@ts-expect-error
  return nextTopic;
};

export const maxTopicOrderOfChild = (parentTopic) => {
  if (!parentTopic || parentTopic.children.length === 0) {
    return null;
  }
  let maxTopicOrder = parentTopic.children[0].topicOrder;
  // eslint-disable-next-line
  parentTopic.children.map((child) => {
    if (child.topicOrder > maxTopicOrder) maxTopicOrder = child.topicOrder;
  });
  return maxTopicOrder;
};

export const findNextSelectedTopicAfterDelete = async (
  topics,
  homeTopic,
  deletingTopic
) => {
  let nextSelectedTopic;
  if (homeTopic.topicId !== deletingTopic.parentTopicId) {
    topics.map((topic) => {
      if (topic.topicId === deletingTopic.parentTopicId)
        nextSelectedTopic = topic;
      return nextSelectedTopic;
    });
  } else {
    //find the first topic to set as the next selected topic
    const sortedTopicList = await topics
      .filter(
        (topic) =>
          topic.parentTopicId === homeTopic.topicId ||
          deletingTopic.topicId === topic.topicId
      )
      .sort((a, b) => {
        return a.topicOrder - b.topicOrder;
      });
    const firstTopic = sortedTopicList[0];
    nextSelectedTopic = firstTopic;
  }
  return nextSelectedTopic;
};

export const getTopicLink = (
  document,
  topic,
  language,
  getPublishedLink: boolean,
  isDoclessTopic: boolean = false,
  account: any = null,
  fullPublishPath: string = ''
) => {
  const viewerURL = config.get('viewerURL');

  if (isDoclessTopic) {
    return account?.customDomain && getPublishedLink
      ? `https://${document?.account.customDomain}/${fullPublishPath ? fullPublishPath.split('/').slice(1).join('/') : topic?.slug}`
      : `${viewerURL}${
          fullPublishPath
            ? fullPublishPath + (getPublishedLink ? '' : '?previewMode=true')
            : (getPublishedLink ? account?.slug : account?.id) +
              '/' +
              (getPublishedLink ? topic.slug : topic.topicId) +
              (getPublishedLink ? '' : '?previewMode=true')
        }`;
  } else {
    const base =
      document?.account.customDomain && getPublishedLink
        ? `https://${document?.account.customDomain}/${fullPublishPath ? fullPublishPath.split('/').slice(1).join('/') : document?.slug}`
        : `${viewerURL}${
            fullPublishPath
              ? fullPublishPath
              : (getPublishedLink
                  ? document?.account.slug
                  : document?.account.id) +
                '/' +
                (getPublishedLink ? document?.slug : topic?.documentVersionId)
          }`;

    const langParam = language ? `?lang=${language.code}` : '';
    const previewParam = getPublishedLink
      ? ''
      : language
        ? '&previewMode=true'
        : '?previewMode=true';

    return `${base}${langParam}${previewParam}`;
  }
};

export const htmlDecode = (text) => {
  const e = document.createElement('textarea');
  e.innerHTML = text;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

export const getTimeDifferenceInString = (
  firstDate: Date,
  secondDate: Date
) => {
  let delta = Math.abs(secondDate.getTime() - firstDate.getTime()) / 1000;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  const seconds = Math.floor(delta % 60);

  if (hours !== 0) {
    return `${hours} hours and ${minutes} minutes and ${seconds} seconds`;
  } else if (minutes !== 0) {
    return `${minutes} minutes and ${seconds} seconds`;
  } else if (seconds >= 10) {
    return `${seconds} seconds`;
  } else {
    return 'just now';
  }
};

export const getTypeTitle = (type, t) => {
  switch (type) {
    case 'APPROVAL':
      return t('Approval');
    case 'ALL_TOPICS_HAVE_IMAGE':
      return t('numberOfImages');
    default:
      return '';
  }
};

export const modifyIncomingProcessData = (processData) => {
  const modifiedProcessData = { ...processData };
  modifiedProcessData.stepsActivities = [];
  modifiedProcessData.activities.map((activity) => {
    if (modifiedProcessData.stepsActivities[activity.step]) {
      modifiedProcessData.stepsActivities[activity.step].push({
        ...activity,
        step: activity.step,
      });
    } else {
      modifiedProcessData.stepsActivities.splice(activity.step, 0, [
        { ...activity, step: activity.step },
      ]);
    }
    return true;
  });
  return modifiedProcessData;
};

export const findTopicByTopicId = (topicId, topicsList) => {
  const newTopics: any[] | null = topicsList;
  return findTopic(newTopics, topicId);
};

export const findTopic = (topics, topicId) => {
  let foundedTopic = null;
  if (Array.isArray(topics)) {
    topics.forEach((topic) => {
      if ((topic.id || topic.topicId) === topicId) {
        foundedTopic = topic;
        return foundedTopic;
      }
      const foundChild = findTopic(topic.children, topicId);
      if (foundChild) {
        return foundChild;
      }
    });
  }

  return foundedTopic;
};

export const showSuccessMessage = (enqueueSnackbar, t) => {
  enqueueSnackbar(t('saved-successfully-message'), {
    variant: 'success',
  });
};

export const showErrorMessage = (enqueueSnackbar, message) => {
  enqueueSnackbar(message, {
    variant: 'error',
  });
};

export const isInvitationEmailOk = (email: string) => {
  // eslint-disable-next-line
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(String(email).toLowerCase());
};

export const calculateBreadCrumb = (
  topicId: string,
  list: any[] = [],
  topics
) => {
  for (let i = 0; i < topics.length; i++) {
    if (topics[i].topicId === topicId && topics[i].parentTopicId !== null) {
      list.push(topics[i]);
      if (topics[i].parentTopicId) {
        return calculateBreadCrumb(topics[i].parentTopicId, list, topics);
      }
    }
  }
  return list;
};

export const getDateTime = (content: any) => {
  const monthShortNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(content.contentUpdateDateTime);
  return `${monthShortNames[date.getMonth()]} ${date.getDay()}, ${date.getFullYear()}`;
};

export const calculateSEOScore = (metrics) => {
  const newData: any = {
    goodResults: [],
    improvements: [],
    errors: [],
  };

  seoData.forEach((metric) => {
    if (
      metric.field !== 'keyphraseDensity' &&
      metric.field !== 'keyphraseInSubheadings'
    ) {
      const value = metrics ? metrics[metric.field] : null;
      if (value !== null && value !== undefined) {
        const newMetric = { ...metric };
        newMetric.value = value;
        if (metric.type === 'Boolean') {
          if (typeof newMetric.value === 'number') {
            if (newMetric.value > 66) {
              newData.goodResults.push(newMetric);
            } else if (newMetric.value < 33) {
              newData.errors.push(newMetric);
            } else {
              newData.improvements.push(newMetric);
            }
          } else {
            if (newMetric.value === metric.successValue) {
              newData.goodResults.push(newMetric);
            } else {
              newData.errors.push(newMetric);
            }
          }
        } else if (metric.type === 'Number') {
          if (
            newMetric.value &&
            metric.successValueStartRange !== undefined &&
            metric.successValueStartRange !== null &&
            metric.successValueEndRange !== undefined &&
            metric.successValueEndRange !== null &&
            newMetric.value >= metric.successValueStartRange &&
            newMetric.value <= metric.successValueEndRange
          ) {
            newData.goodResults.push(newMetric);
          } else if (
            newMetric.value &&
            metric.warningValueStartRange !== undefined &&
            metric.warningValueStartRange !== null &&
            metric.warningValueEndRange !== undefined &&
            metric.warningValueEndRange !== null &&
            newMetric.value >= metric.warningValueStartRange &&
            newMetric.value <= metric.warningValueEndRange
          ) {
            newData.improvements.push(newMetric);
          } else {
            newData.errors.push(newMetric);
          }
        }
      }
    }
  });

  return Math.round(
    ((newData.improvements.length + newData.goodResults.length * 2) /
      (newData.errors.length +
        newData.improvements.length +
        newData.goodResults.length)) *
      50
  );
};

export const calculateReadabilityScore = (metrics) => {
  const newData: any = {
    goodResults: [],
    improvements: [],
    errors: [],
  };

  readabilityData.forEach((metric) => {
    const value = metrics ? metrics[metric.field] : null;
    if (value !== null && value !== undefined) {
      const newMetric = { ...metric };
      newMetric.value = value;
      if (metric.type === 'Boolean') {
        if (typeof newMetric.value === 'number') {
          if (newMetric.value > 66) {
            newData.goodResults.push(newMetric);
          } else if (newMetric.value < 33) {
            newData.errors.push(newMetric);
          } else {
            newData.improvements.push(newMetric);
          }
        } else {
          //eslint-disable-next-line
          //@ts-expect-error
          if (newMetric.value === metric.successValue) {
            newData.goodResults.push(newMetric);
          } else {
            newData.errors.push(newMetric);
          }
        }
      } else if (metric.type === 'Number') {
        if (metric.field === 'fleschReadingEase') {
          if (
            newMetric.value &&
            newMetric.value >= 70 &&
            newMetric.value <= 100
          ) {
            newData.goodResults.push(newMetric);
          } else if (
            newMetric.value &&
            newMetric.value >= 50 &&
            newMetric.value <= 70
          ) {
            newData.improvements.push(newMetric);
          } else {
            newData.errors.push(newMetric);
          }
        } else {
          if (
            newMetric.value &&
            newMetric.value >= metric.successValueStartRange &&
            newMetric.value <= metric.successValueEndRange
          ) {
            newData.goodResults.push(newMetric);
          } else if (
            newMetric.value &&
            metric.warningValueStartRange !== undefined &&
            metric.warningValueStartRange !== null &&
            metric.warningValueEndRange !== undefined &&
            metric.warningValueEndRange !== null &&
            newMetric.value >= metric.warningValueStartRange &&
            newMetric.value <= metric.warningValueEndRange
          ) {
            newData.improvements.push(newMetric);
          } else {
            newData.errors.push(newMetric);
          }
        }
      }
    }
  });

  return Math.round(
    ((newData.improvements.length + newData.goodResults.length * 2) /
      (newData.errors.length +
        newData.improvements.length +
        newData.goodResults.length)) *
      50
  );
};

export const getDocumentMetrics = (doc, versionSlug, languageCode) => {
  if (doc.metrics) {
    const metrics = doc.metrics.find(
      (metrics) =>
        metrics.documentVersionSlug === versionSlug &&
        metrics.languageCode === languageCode
    );
    if (metrics) return metrics.avg;
    else return null;
  }
  return null;
};

export const getImageType = (contentType: string) => {
  let imageType = '';
  switch (contentType) {
    case 'image/x-icon':
      imageType = 'ico';
      break;
    case 'image/png':
      imageType = 'png';
      break;
    case 'image/gif':
      imageType = 'gif';
      break;
    default:
      break;
  }
  return imageType;
};

export const getImageContentType = (base64Data: string) => {
  let contentType = '';
  const encodedContentType = base64Data.substring(0, 3);
  switch (encodedContentType) {
    case 'AAA':
      contentType = 'image/x-icon';
      break;
    case 'iVB':
      contentType = 'image/png';
      break;
    case 'R0lG':
      contentType = 'image/gif';
      break;
    default:
      break;
  }
  return contentType;
};

export const base64toBlob = async (base64Data: string, contentType: string) => {
  const url = `data:${contentType};base64,${base64Data}`;
  return fetch(url)
    .then((res) => {
      return res.blob();
    })
    .then((blobData) => {
      return blobData;
    })
    .catch(() => {
      return null;
    })
    .catch(() => {
      return null;
    });
};

const removeNullValuesFromMenuItem = (item) => {
  Object.entries(item).forEach(([k, v]) => {
    if (v === null) delete item[k];
    else if (typeof v === 'object') removeNullValuesFromMenuItem(item[k]);
  });

  const items = item.items;
  if (items) items.forEach((item) => removeNullValuesFromMenuItem(item));

  return item;
};

export const removeNullValuesFromMenu = (data) => {
  const items = data.items;
  if (items) items.forEach((item) => removeNullValuesFromMenuItem(item));

  return data;
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const list_to_tree = (list) => {
  const map = {};
  const roots: any[] = [];
  let node, i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].topicId] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentTopicId) {
      if (map[node.parentTopicId] !== undefined) {
        //if found a parent for the node
        list[map[node.parentTopicId]].children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true;
  }
  if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform)
  ) {
    return true;
  }
  return false;
};

export function trunc(s: string, n: number) {
  if (s) {
    return s.length > n ? s.substr(0, n - 1) + '...' : s;
  } else {
    return '';
  }
}

export function sleep(ms = 200): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const makeLightColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 8) - hash);
  }
  return 'hsl(' + (hash / 100) * 360 + ', 60%, 90%)';
};
