import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Select } from 'antd';

import SelectInput from '../../inputs/select-input';
import { AccountContext } from '../../../contexts/account';

import './styles.css';

const { Option } = Select;

const SelectAccountDropdown = () => {
  const { accountsData, selectedAccount, setSelectedAccount } =
    useContext(AccountContext);

  const handleAccountChanged = (accountId: string) => {
    const previousAccountId = selectedAccount.id;

    if (previousAccountId === accountId) return;

    const newSelectedAccount = accountsData.find(
      (account) => account.id === accountId
    );

    if (newSelectedAccount) {
      setSelectedAccount(newSelectedAccount);
    }
  };

  if (accountsData && accountsData.length > 0) {
    return (
      <Box display="flex" p={0} m={0}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          id="org-switch-dropdown"
        >
          <SelectInput
            layout="horizontal"
            style={{ minWidth: 200 }}
            field="Role"
            value={selectedAccount?.id}
            onChange={handleAccountChanged}
            disabled={accountsData.length === 1}
          >
            {accountsData.map((account) => (
              <Option key={account.id} value={account.id}>
                {account.title}
              </Option>
            ))}
          </SelectInput>
        </div>
      </Box>
    );
  }

  return null;
};

export default SelectAccountDropdown;
