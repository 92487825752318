import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { withSnackbar } from 'notistack';
import { Col } from 'antd';

const cropper: any = React.createRef();

const styles: any = () => ({
  screenshotBox: {
    textAlign: 'center',
  },
  screenshot: {
    position: 'fixed',
    zIndex: '100000',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
  },
  cropButton: {
    position: 'fixed',
    zIndex: '100001',
    bottom: '20px',
    left: 'calc(50% - 37px)',
  },
  finalScreenshotImage: {
    width: '100%',
    marginBottom: 10,
  },
  deleteButton: {
    color: 'red',
    borderColor: 'red',
    marginLeft: 10,
  },
  loading: {
    width: '25px!important',
    height: '25px!important',
  },
  screenshotButton: {
    minWidth: '175px',
  },
});

const Screenshot = (props) => {
  const { classes } = props;
  const [showScreenshotImage, setShowScreenshotImage] =
    useState<boolean>(false);
  const [screenshotImageUrl, setScreenshotImageUrl] = useState<string>('');
  const [showFinalScreenshotImage, setShowFinalScreenshotImage] =
    useState<boolean>(false);
  const [screenshotFinalImageUrl, setScreenshotFinalImageUrl] =
    useState<string>('');
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [loadingScreenShotLibrary, setLoadingScreenShotLibrary] =
    useState<boolean>(false);

  const takeScreenshot = () => {
    setLoadingScreenShotLibrary(true);
    import('html2canvas').then((html2canvas) => {
      html2canvas
        .default(document.querySelector('#root') as HTMLElement, {
          height: document.body.scrollHeight,
        })
        .then(function (canvas) {
          const base64URL = canvas
            .toDataURL('image/jpeg')
            .replace('image/jpeg', 'image/octet-stream');
          setScreenshotImageUrl(base64URL);
          setShowScreenshotImage(true);
          setLoadingScreenShotLibrary(false);
        });
    });
  };

  const deleteScreenshot = () => {
    setShowFinalScreenshotImage(false);
    setScreenshotFinalImageUrl('');
    setShowScreenshotImage(false);
    setScreenshotImageUrl('');
    props.setScreenshotFinalImageUrl('');
    setShowDeleteButton(false);
  };

  const cropImage = (event) => {
    event.preventDefault();
    if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
      props.enqueueSnackbar("Can't crop image. Please try again", {
        variant: 'error',
      });
      return;
    }
    setScreenshotFinalImageUrl(cropper.current.getCroppedCanvas().toDataURL());
    props.setScreenshotFinalImageUrl(
      cropper.current.getCroppedCanvas().toDataURL()
    );
    setShowScreenshotImage(false);
    setShowFinalScreenshotImage(true);
    setShowDeleteButton(true);
  };

  return (
    <Col xs={24} sm={24} className={classes.screenshotBox}>
      <Cropper
        ref={cropper}
        src={screenshotImageUrl}
        className={classes.screenshot}
        style={showScreenshotImage ? { display: 'block' } : { display: 'none' }}
        background={false}
      />
      <Button
        style={showScreenshotImage ? { display: 'block' } : { display: 'none' }}
        className={classes.cropButton}
        onClick={(event) => {
          cropImage(event);
        }}
        variant="contained"
        color="primary"
      >
        Crop
      </Button>
      <img
        src={screenshotFinalImageUrl}
        alt="screenshot"
        className={classes.finalScreenshotImage}
        style={
          showFinalScreenshotImage ? { display: 'block' } : { display: 'none' }
        }
      />
      <Button
        onClick={() => takeScreenshot()}
        variant="outlined"
        color="primary"
        className={classes.screenshotButton}
      >
        {loadingScreenShotLibrary ? (
          <CircularProgress className={classes.loading} />
        ) : (
          'Take Screenshot'
        )}
      </Button>
      <Button
        onClick={() => deleteScreenshot()}
        variant="outlined"
        style={
          showDeleteButton ? { display: 'inline-block' } : { display: 'none' }
        }
        className={classes.deleteButton}
      >
        Delete Screenshot
      </Button>
    </Col>
  );
};

export default withStyles(styles)(withSnackbar(Screenshot));
