import React, { useContext, useMemo, useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { DraftTopic } from '../../../../api-schemas/topic';
import { AccountContext } from '../../../../contexts/account';
import useDeleteTopic from '../../../../api-hooks/topic/useDeleteTopic';
import usePublishTopic from '../../../../api-hooks/topic/usePublishTopic';
import useGetDraftTopic from '../../../../api-hooks/topic/useGetDraftTopic';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { TopicContext } from '../../../../contexts/topic';
import { FolderContext } from '../../../../contexts/folder';
import useGetTopics from '../../../../api-hooks/topic/useGetTopics';

type MenuItems = MenuProps['items'];

type TopicListItemMenuPropsType = {
  topic: DraftTopic;
};

const TopicListItemMenu = ({ topic }: TopicListItemMenuPropsType) => {
  const topicId = topic.topicId || topic.id;

  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedLanguage, selectedAccount } = useContext(AccountContext);
  const { selectedFolderId } = useContext(FolderContext);
  const { setIsModifyingDoclessTopic } = useContext(TopicContext);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { deleteTopic, isDeletingTopic } = useDeleteTopic({
    topicId,
    languageCode: selectedLanguage,
  });
  const { publishTopic, isPublishingTopic } = usePublishTopic({
    topicId,
    languageCode: selectedLanguage,
  });
  const { getTopic, isLoadingDraftTopic } = useGetDraftTopic({
    topicId,
    languageCode: selectedLanguage as string,
    enabled: false,
    retry: 0,
  });
  const { getTopics } = useGetTopics({
    accountId: selectedAccount?.id || '',
    parentFolderId: selectedFolderId,
    enabled: Boolean(selectedAccount?.id),
  });

  const items = useMemo<MenuItems>(
    () => [
      {
        key: '1',
        label: '',
        children: [
          {
            key: '2',
            label: t('edit-content'),
            onClick: () =>
              history.push(
                `/topic/${topic.accountId}/${topicId}/content?lang=${selectedLanguage}`
              ),
          },
          {
            key: '3',
            label: t('settings'),
            onClick: () =>
              history.push(
                `/topic/${topic.accountId}/${topicId}/Settings?lang=${selectedLanguage}`
              ),
          },
          {
            key: '4',
            label: t('history'),
            onClick: () =>
              history.push(
                `/topic/${topic.accountId}/${topicId}/History?lang=${selectedLanguage}`
              ),
          },
          {
            key: '5',
            label: t('delete'),
            onClick: () => setOpenDeleteDialog(true),
            style: { color: 'red' },
          },
        ],
      },
    ],
    [topic]
  );

  const loading = isLoadingDraftTopic || isDeletingTopic || isPublishingTopic;

  const handleDeleteTopic = () => {
    deleteTopic({
      topicId,
      languageCode: selectedLanguage as string,
    })
      .then(async () => {
        await getTopic()
          .then(async (result) => {
            const deletedTopic = result.data;

            if (deletedTopic) {
              const publishData = {
                languageCode: selectedLanguage as string,
                title: new Date().toLocaleDateString(),
                topicVersionIds: [deletedTopic.latestTopicVersionId],
              };

              await publishTopic(publishData);
            }
          })
          .finally(() => {
            setIsModifyingDoclessTopic(true);

            getTopics();
          });
      })
      .catch(() => {
        enqueueSnackbar(t('error.unknown'), { variant: 'error' });
      })
      .finally(() => {
        setOpenDeleteDialog(false);
      });
  };

  return (
    <>
      <Menu
        style={{ width: 50 }}
        items={items}
        expandIcon={<MoreVert style={{ width: '100%', right: 0 }} />}
        triggerSubMenuAction="click"
      />

      <ConfirmationDialog
        isOpen={openDeleteDialog}
        onDialogClosed={() => setOpenDeleteDialog(false)}
        title={t<string>('delete-topic')}
        message={t('delete-topic-message')}
        onDialogConfirmed={handleDeleteTopic}
        loading={loading}
      />
    </>
  );
};

export default TopicListItemMenu;
