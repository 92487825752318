import React from 'react';
import { Col, Collapse } from 'antd';
import '../styles.css';

const { Panel } = Collapse;

const AnalyticsTab = ({ title, variant, children }) => {
  return (
    <Collapse
      expandIconPosition="end"
      className={variant}
      style={{ marginBottom: 15 }}
    >
      <Panel header={title} key="0">
        <Col
          xs={24}
          style={{ display: 'flex', alignItems: 'center', flexFlow: 'wrap' }}
        >
          {children}
        </Col>
      </Panel>
    </Collapse>
  );
};

export default AnalyticsTab;
