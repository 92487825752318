import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import config from 'react-global-configuration';
import { getAuth } from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';

// Initialize Firebase
const firebaseApp = initializeApp(config.get('firebaseConfig'));

const storage = getStorage();
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export { storage, firebaseApp as default, auth, db };
