import React, { Fragment } from 'react';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const PublishRequestsHistorySkeletonLoading = () => {
  return (
    <List>
      {new Array(10).fill(0).map((item, index) => {
        return (
          <Fragment key={index}>
            <span key={item} style={{ margin: '5px 0' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <Skeleton width="80%" style={{ marginLeft: '10px' }} />
                  }
                  secondary={
                    <Skeleton width="60%" style={{ marginLeft: '10px' }} />
                  }
                />
              </ListItem>
              <Divider />
            </span>
          </Fragment>
        );
      })}
    </List>
  );
};

export default PublishRequestsHistorySkeletonLoading;
