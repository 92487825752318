import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteAccessRequest = (accessId: string) => {
    return axios.delete(`/api/Access/${accessId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useDeleteAccess(entityId: string) {
    const queryClient = useQueryClient();

    const {
        mutateAsync: deleteAccess,
        isPending: isDeletingAccess,
        isSuccess: isSuccessDeletingAccess
    } = useMutation({
        mutationFn: deleteAccessRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['accesses', entityId] });
        }
    });

    return {
        deleteAccess,
        isDeletingAccess,
        isSuccessDeletingAccess
    }
}