import React from 'react';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AccountAdvancedSettings from './advanced';
import AccountCustomDomainSettings from './custom-domain';
import AccountGeneralSettings from './general';
import AccountIntegrationsSettings from './integrations';
import AccountStyleSettings from './style';
import AccountWebAssistantSettings from './web-assistant';
import ManageAccountLanguages from './manage-language';
import GoogleDriveIntegrationSettings from './google-drive-integration';
import DangerZoneIntegrationSettings from './danger-zone';

import './styles.css';

function OrgSettings(props) {
  const { t } = useTranslation();

  const items = [
    {
      label: 'General',
      key: '1',
      children: <AccountGeneralSettings {...props} />,
    },
    {
      label: 'Style',
      key: '2',
      children: <AccountStyleSettings {...props} />,
    },
    {
      label: 'Integrations',
      key: '3',
      children: <AccountIntegrationsSettings {...props} />,
    },
    {
      label: 'Custom Domain',
      key: '4',
      children: <AccountCustomDomainSettings {...props} />,
    },
    {
      label: 'Advanced',
      key: '5',
      children: <AccountAdvancedSettings {...props} />,
    },
    {
      label: 'SaaS Assistant Widget',
      key: '6',
      children: <AccountWebAssistantSettings {...props} />,
    },
    {
      label: 'Languages',
      key: '7',
      children: <ManageAccountLanguages {...props} />,
    },
    {
      label: t('google-drive-tab-label'),
      key: '8',
      children: <GoogleDriveIntegrationSettings {...props} />,
    },
    {
      label: t('danger-zone-tab-label'),
      key: '9',
      children: <DangerZoneIntegrationSettings {...props} />,
    }
  ];

  const selectedTab =
    new URL(window.location.href).searchParams.get('selectedTab') ?? 'general';
  const selectedTabKey =
    items.find((item) => item.label.toLowerCase() === selectedTab)?.key ?? '1';

  const history = useHistory();

  const handleChangeTab = (selectedTabKey: string) => {
    const selectedTabLabel =
      items.find((item) => item.key === selectedTabKey)?.label.toLowerCase() ??
      'general';

    history.push({
      search: `selectedTab=${selectedTabLabel}`,
    });
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={selectedTabKey}
        items={items}
        className="org-settings-tabs"
        onChange={handleChangeTab}
      />
    </div>
  );
}

export default OrgSettings;
