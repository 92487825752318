import DataProvider from '../data-provider';
import StyleProvider from '../style-provider';
import ComponentProvider from '../component-provider';
import { Context } from '../../../../../interfaces';

class Component {
  public index: number;
  public data;
  public dataProvider: DataProvider;
  public styleProvider: StyleProvider;
  public componentProvider: ComponentProvider;
  public context: Context;

  constructor(data: any, index: number, context: Context) {
    this.index = index;
    this.data = data;
    this.context = context;
    this.dataProvider = new DataProvider(this.data.content);
    this.styleProvider = new StyleProvider(this.data.style);
    this.componentProvider = new ComponentProvider(this.data.component);
  }

  addComponentDataToWindow(jsonData: any) {
    if (!window.componentsData) {
      window.componentsData = [];
    }
    
    window.componentsData[this.index] = jsonData;
  }

  styleCreator(): HTMLStyleElement {
    const styles = this.styleProvider.getCSSContent();
    const styleElement = document.createElement('style');
    styleElement.id = `component-style-${this.index}`;
    styleElement.innerHTML = styles;
    return styleElement;
  }

  scriptCreator(): HTMLScriptElement {
    const data = this.dataProvider.getJSONData();
    this.addComponentDataToWindow(data);
    const htmlContent = this.componentProvider.getScriptContent(data, this.index, this.context);

    const script = document.createElement('script');
    script.id = `component-script-${this.index}`;
    script.type = 'module';
    script.innerHTML = htmlContent;
    return script;
  }
}

export default Component;
