import React from 'react';
import { Route } from 'react-router-dom';

import Layout from '../Layout';
import DocumentSettingsPage from './document-settings';
import DocumentContentEditorPage from './document-content-editor';
import DocumentUsersPage from './document-users';
import DocumentVersionPage from './document-version';
import DocumentLanguagePage from './document-language';
import AccountSettingsPage from './account-settings';
import ProfilePage from './profile';
import FeedbackPage from './feedback';
import PublishesPage from './publishes';
import PublishesHistoryPage from './publishes/history';
import PublishPage from './publishes/publish';
import NotificationsPage from './notifications';
import DocumentViewersPage from './document-viewers';
import MetricsAnalyticsPage from './metrics-analytics';
import DocumentImageGalleryPage from './document-image-gallery';
import ViewAnalyticsPage from './analytics/view';
import WriteAnalyticsPage from './analytics/write';
import TopicContentEditorPage from './topic-content-editor';
import GoogleDriveOAuthCallbackPage from './google-drive-ouath-result';
import HomePage from './home';

function Pages() {
  return (
    <Layout>
      <Route exact path="/" render={() => <HomePage />} />
      <Route
        exact
        path="/document/create"
        render={(props) => <DocumentSettingsPage {...props} />}
      ></Route>
      <Route
        exact
        path={[
          '/document/:accountId/:documentId/settings',
          '/document/:accountId/:documentId/settings/:settingsItem',
        ]}
        render={(props) => <DocumentSettingsPage {...props} />}
      ></Route>
      <Route
        path={[
          '/document/:accountId/:documentId/content',
          '/document/:accountId/:documentId/:topicId/content',
          '/document/:accountId/:documentId/:topicId/Settings',
          '/document/:accountId/:documentId/:topicId/History',
          '/document/:accountId/:documentId/:topicId/Feedback',
          '/document/:accountId/:documentId/:topicId/Metrics',
          '/document/:accountId/:documentId/:topicId/RefLinks',
        ]}
        render={(props) => <DocumentContentEditorPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/manage-team"
        render={(props) => <DocumentUsersPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/private-document"
        render={(props) => <DocumentViewersPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/version"
        render={(props) => <DocumentVersionPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/language"
        render={(props) => <DocumentLanguagePage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/images"
        render={() => <DocumentImageGalleryPage />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/publish-request"
        render={(props) => <PublishesPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/publish-history"
        render={() => <PublishesHistoryPage />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/publish-request/:publishId"
        render={(props) => <PublishPage {...props} />}
      ></Route>
      <Route
        path={[
          '/topic/:accountId/:topicId/content',
          '/topic/:accountId/:topicId/Settings',
          '/topic/:accountId/:topicId/History',
          '/topic/:accountId/:topicId/Feedback',
          '/topic/:accountId/:topicId/Metrics',
        ]}
        render={() => <TopicContentEditorPage />}
      ></Route>
      <Route
        exact
        path={[
          '/org/settings',
          '/org/settings/:settingsItem',
          '/org/settings/:settingsItem/:settingsAction',
          '/org/settings/:settingsItem/:processId/:settingsAction',
        ]}
        render={(props) => <AccountSettingsPage {...props} />}
      ></Route>
      <Route
        exact
        path="/notifications"
        render={(props) => <NotificationsPage {...props} />}
      ></Route>
      <Route
        path="/profile"
        render={(props) => <ProfilePage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/feedback"
        render={(props) => <FeedbackPage {...props} />}
      ></Route>
      <Route
        exact
        path="/document/:accountId/:documentId/metrics"
        render={(props) => <MetricsAnalyticsPage {...props} />}
      ></Route>
      <Route
        exact
        path={[
          '/document/:accountId/:documentId/view-analytics',
          '/org/view-analytics',
        ]}
        render={(props) => <ViewAnalyticsPage {...props} />}
      ></Route>
      <Route
        exact
        path={[
          '/document/:accountId/:documentId/write-analytics',
          '/org/write-analytics',
        ]}
        render={(props) => <WriteAnalyticsPage {...props} />}
      ></Route>
      <Route
        exact
        path={[
          '/driveoauthcallback',
        ]}
        render={(props) => <GoogleDriveOAuthCallbackPage {...props} />}
      ></Route>
    </Layout>
  );
}

export default Pages;
