import React from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddPaymentMethod from './add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PaymentMethodsMenu from './menu';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { useTranslation, Trans } from 'react-i18next';
import { Col } from 'antd';

const styles: any = (theme: Theme) => ({
  addButtonBox: {
    marginBottom: '10px',
    textAlign: 'right',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: '5px',
  },
  creditCardBox: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    alignItems: 'center',
    marginBottom: '10px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: '5px',
  },
  actions: {
    textAlign: 'right',
  },
  button: {
    minWidth: '50px!important',
    width: '50px!important',
  },
  divider: {
    width: '100%',
  },
  container: {
    padding: '10px 0',
  },
  dialog: {
    textAlign: 'center',
  },
});

const PaymentMethods = (props: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState<boolean>(false);

  const [showDetachPaymentMethodDialog, setShowDetachPaymentMethodDialog] =
    React.useState<boolean>(false);

  const [currentCreditCardName, setCurrentCreditCardName] = React.useState<
    string | null
  >(null);
  const [currentCreditCardId, setCurrentCreditCardId] = React.useState<
    string | null
  >(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const detachPaymentMethod = async (paymentMethodId: string) => {
    setShowDetachPaymentMethodDialog(false);
    setLoading(true);
    await props.detachPaymentMethod(paymentMethodId);
    updatePaymentMethods();
  };

  const updatePaymentMethods = async () => {
    setOpen(false);
    setLoading(true);
    await props.updatePaymentMethods();
    setLoading(false);
  };

  const changeDefaultPaymentMethod = async (paymentMethodId: string) => {
    setLoading(true);
    await props.changeDefaultPaymentMethod(paymentMethodId);
    setLoading(false);
  };

  const openDetachPaymentMethodDialog = (name: string, id: string) => {
    setShowDetachPaymentMethodDialog(true);
    setCurrentCreditCardName(name);
    setCurrentCreditCardId(id);
  };

  const classes = props.classes;

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Col xs={24}>
        <Typography
          style={{ marginBottom: 15, marginTop: 15 }}
          component="h1"
          variant="h5"
        >
          {t('payment-methods-settings-title')}
        </Typography>
      </Col>
      <Col xs={24} xl={18} className={classes.addButtonBox}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => setOpen(true)}
        >
          Add
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
          className={classes.dialog}
        >
          <DialogTitle id="responsive-dialog-title">
            {'Add payment method'}
          </DialogTitle>
          <DialogContent>
            <AddPaymentMethod
              accountConfiguration={props.accountConfiguration}
              updatePaymentMethods={updatePaymentMethods}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Col>
      {props.paymentMethods &&
      props.paymentMethods.data &&
      props.paymentMethods.data.length &&
      !loading ? (
        props.paymentMethods.data.map((creditCard: any) => {
          return (
            <Col
              key={creditCard.id}
              xs={24}
              xl={18}
              className={classes.creditCardBox}
            >
              <Col xs={24} sm={2} md={2}>
                <CreditCardIcon />
              </Col>
              <Col xs={24} sm={6} md={4}>
                {creditCard.billing_details.name}
              </Col>
              <Col xs={24} sm={6} md={6}>
                **** **** **** {creditCard.card.last4}
              </Col>
              <Col xs={24} sm={6} md={4}>
                Expiration:{' '}
                {`${creditCard.card.exp_month}/${creditCard.card.exp_year}`}
              </Col>
              <Col xs={24} sm={2} md={6}>
                {creditCard.id === props.defaultPaymentMethodId ? (
                  <span>Default</span>
                ) : null}
              </Col>
              <Col xs={24} sm={2} md={2} className={classes.actions}>
                <PaymentMethodsMenu
                  name={creditCard.billing_details.name}
                  id={creditCard.id}
                  changeDefaultPaymentMethod={changeDefaultPaymentMethod}
                  openDetachPaymentMethodDialog={openDetachPaymentMethodDialog}
                />
              </Col>
            </Col>
          );
        })
      ) : props.paymentMethods &&
        props.paymentMethods.data &&
        !props.paymentMethods.data.length &&
        !loading ? (
        <Col
          xs={24}
          xl={18}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          You do not have any payment method
        </Col>
      ) : (
        <Col
          xs={24}
          xl={18}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress />
        </Col>
      )}
      <Col xs={24} xl={18} style={{ textAlign: 'center', marginTop: 60 }}>
        <img
          src={process.env.PUBLIC_URL + '/stripe-badge.png'}
          alt="Stripe"
          style={{ width: '50%' }}
        />
      </Col>
      <ConfirmationDialog
        isOpen={showDetachPaymentMethodDialog}
        onDialogClosed={() => setShowDetachPaymentMethodDialog(false)}
        onDialogConfirmed={() => detachPaymentMethod(currentCreditCardId || '')}
        title={t<string>('delete.paymentMethodTitle')}
        message={
          <Trans i18nKey="delete.paymentMethod">
            {' '}
            Are you sure you want to delete {{ currentCreditCardName }} card ?{' '}
          </Trans>
        }
      />
    </Grid>
  );
};

export default withStyles(styles)(PaymentMethods);
