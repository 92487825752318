import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'react-global-configuration';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Modal } from 'antd';

import CodeEditor from '../component-editor/code-editor';
import Component from '../component-editor/classes/component';
import CKEDITOR from '../../ckeditor5/ckeditor';
import { DocumentContext } from '../../../contexts/document';
import { removeHeadingIds } from '../../../helpers/content';
import { StructuredDataContext } from '../../../contexts/structured-data-context';
import useGetComponents from '../../../api-hooks/component/useGetComponents';

import { getComponentVersionRequest } from '../../../api-hooks/component/useGetComponentVersionWithNumber';
import { TopicContext } from '../../../contexts/topic';
import useDebounceFn from '../../../hooks/useDebounceFn';
import { DraftTopic } from '../../../api-schemas/topic';

import '../../ckeditor5/style.css';

const TOOLBAR_ITEMS = [
  'heading',
  '|',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'code',
  '|',
  'bulletedList',
  'numberedList',
  'todoList',
  '|',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'alignment',
  'indent',
  'outdent',
  '|',
  'link',
  'topicLink',
  '|',
  'subscript',
  'superscript',
  '|',
  'fontFamily',
  'fontSize',
  '|',
  'insertImage',
  'insertTable',
  '|',
  'findAndReplace',
  'undo',
  'redo',
  '|',
  'mediaEmbed',
  'horizontalLine',
  'htmlEmbed',
  'codeBlock',
  'specialCharacters',
  'removeFormat',
  'blockQuote',
  'templateBox',
  'component',
  'variable',
];

const DocerCKEditor = (props) => {
  const viewerURL: string = config.get('viewerURL');

  const { selectedDoc, selectedLanguage, selectedVersion } =
    useContext(DocumentContext);
  const { context } = useContext(StructuredDataContext);
  const { editedTopic, setIsUserModifyingTopic, isPublishingTopic } =
    useContext(TopicContext);

  const isDeleted = editedTopic?.action === 'Deleted';

  const [editorContent, setEditorContent] = useState<any>('');
  const [editItem, setEditItem] = useState<any>(null);
  const [selectedComponentId, setSelectedComponentId] = useState<any>(null);
  const [selectedComponentVersion, setSelectedComponentVersion] =
    useState<any>(null);
  const [lastSelectedTopic, setLastSelectedTopic] = useState<DraftTopic>();

  const { t } = useTranslation();

  const { components } = useGetComponents();

  const imageUploadUrl =
    selectedDoc && editedTopic?.documentId
      ? `/api/resources/image/upload?accountId=${selectedDoc?.accountId}&documentId=${selectedDoc?.id}&userId=${localStorage.getItem('userId')}`
      : editedTopic && !selectedDoc && !editedTopic?.documentId
        ? `/api/resources/image/upload?accountId=${editedTopic?.accountId}&userId=${localStorage.getItem('userId')}`
        : null;

  const editorConfig = {
    toolbar: TOOLBAR_ITEMS,
    blockToolbar: TOOLBAR_ITEMS,

    removePlugins: ['MediaEmbedToolbar'],

    fontFamily: {
      supportAllValues: true,
      options: [
        'default',
        'Arial, Helvetica, sans-serif',
        'Courier New, Courier, monospace',
        'Georgia, serif',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Tahoma, Geneva, sans-serif',
        'Times New Roman, Times, serif',
        'Verdana, Geneva, sans-serif',
        'Barcode', // custom bar code font
      ],
    },

    image: {
      // Configure the available styles.
      styles: ['alignLeft', 'alignCenter', 'alignRight', 'inline'],

      upload: {
        types: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'svg+xml'],
      },

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:25',
          label: '25%',
          value: '25',
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
      ],
    },
    component: {
      openComponentsList: () => {
        props.openComponentsListModal();
      },
    },
    variable: {
      openVariablesList: () => {
        props.openVariablesListModal();
      },
    },
    topicLink: {
      list: props.topics,
      document: selectedDoc,
      viewerURL: viewerURL,
      selectedVersion: selectedVersion,
      selectedLanguage: selectedLanguage,
      topicLinkRenderer: () => {
        props.setGoingToOpenTopicLink(true);
      },
    },
    mediaEmbed: {
      MediaEmbedToolbar: true,
      previewsInData: true,
    },
    // for uploading images from clipboard
    imageUpload: {
      uploadUrl: imageUploadUrl,
      allowMultipleUpload: true,
      types: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'svg+xml'],
    },
    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: imageUploadUrl,
      types: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'svg+xml'],
    },
    title: {
      placeholder: t('topic-title-placeholder'),
    },
    placeholder: t('topic-content-placeholder'),
    language: 'en',
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
        'toggleTableCaption',
      ],
    },
    licenseKey: '',
    codeBlock: {
      languages: [
        {
          language: 'plaintext',
          label: 'Plain text',
          class: 'language-plaintext',
        },
        { language: 'c', label: 'C', class: 'language-c' },
        { language: 'cs', label: 'C#', class: 'language-cs' },
        { language: 'cpp', label: 'C++', class: 'language-cpp' },
        { language: 'css', label: 'CSS', class: 'language-css' },
        { language: 'diff', label: 'Diff', class: 'language-diff' },
        { language: 'html', label: 'HTML', class: 'language-html' },
        { language: 'java', label: 'Java', class: 'language-java' },
        { language: 'javascript', label: 'JavaScript', class: 'language-js' },
        { language: 'php', label: 'PHP', class: 'language-php' },
        { language: 'python', label: 'Python', class: 'language-py' },
        { language: 'ruby', label: 'Ruby', class: 'language-ruby' },
        { language: 'typescript', label: 'TypeScript', class: 'language-ts' },
        { language: 'xml', label: 'XML', class: 'language-xml' },
      ],
    },
    templateBoxesConfig: {
      variants: ['example', 'note', 'caution', 'warning', 'danger', 'extra'],
    },
  };

  useDebounceFn({
    value: editorContent,
    callback: () => {
      if (editorContent !== removeHeadingIds(editedTopic?.body)) {
        props.handleTopicDataChange(editorContent, 'body');
      }
    },
    delay: 500,
  });

  useDebounceFn({
    value: editorContent,
    callback: () => {
      if (editorContent !== removeHeadingIds(editedTopic?.body))
        setIsUserModifyingTopic(true);
    },
    delay: 150,
  });

  const makeEditorFullHeight = (editor) => {
    if (editor) {
      if (editor && editor.editing) {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            'height',
            window.innerHeight - 185 + 'px',
            editor.editing.view.document.getRoot()
          );
        });
        window.addEventListener('resize', () => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              'height',
              window.innerHeight - 185 + 'px',
              editor.editing.view.document.getRoot()
            );
          });
        });
      }
    }
  };

  useEffect(() => {
    if (
      !editorContent ||
      lastSelectedTopic?.topicId !== editedTopic?.topicId ||
      lastSelectedTopic?.languageCode !== editedTopic?.languageCode
    ) {
      setLastSelectedTopic(editedTopic);
      setEditorContent(editedTopic?.body || '');
    }

    removeEditComponentButtons();

    setTimeout(() => {
      addEditComponentButtons();
    }, 1000);

    // eslint-disable-next-line
  }, [editedTopic]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const getModalTitle = () => {
    const component = components?.find(
      (component) => component.id === editItem?.componentVersion.componentId
    );
    return (component ? component.title + ' ' : '') + t('settings');
  };

  const removeEditComponentButtons = () => {
    const editComponentButtons = document.querySelectorAll(
      '#CKEditorWrapper .edit-component-button'
    );

    editComponentButtons.forEach((component) => {
      component.remove();
    });
  };

  const addEditComponentButtons = () => {
    const components = document.querySelectorAll<HTMLElement>(
      '#CKEditorWrapper section[data-component="true"]'
    );

    components.forEach((component) => {
      const editButtonElement = createEditButtonElement(
        component.getAttribute('data-id') ?? '',
        component
      );

      if (editButtonElement) {
        document
          .querySelector('#CKEditorWrapper')
          ?.appendChild(editButtonElement);
      }
    });
  };

  const createEditButtonElement = (
    id: string,
    componentElement: HTMLElement
  ) => {
    const [componentId, componentItemId] = id.split('_');
    const component = editedTopic?.componentBody.find(
      (componentItem) =>
        componentItem.id === componentItemId &&
        componentItem.componentData.componentId === componentId
    );

    if (component) {
      const editButtonElement = document.createElement('button');

      editButtonElement.onmouseenter = (event) => {
        event.stopPropagation();
        event.preventDefault();

        editButtonElement.style.opacity = '1';
      };

      componentElement.onmouseenter = () => {
        editButtonElement.style.opacity = '1';
      };

      componentElement.onmouseleave = () => {
        editButtonElement.style.opacity = '0';
      };

      document.querySelector<HTMLElement>('.ck.ck-content')!.onscroll = () => {
        if (componentElement.parentElement) {
          const topOffset =
            componentElement.offsetTop -
            componentElement.parentElement.scrollTop +
            20;

          if (topOffset >= 0) {
            editButtonElement.style.top = topOffset + 'px';
          } else {
            editButtonElement.style.top = '200px';
          }
        }
      };

      editButtonElement.addEventListener('click', async function (e) {
        e.preventDefault();
        e.stopPropagation();

        this.setAttribute('disabled', 'true');
        this.innerHTML =
          '<div class="spinner-border" style="color: white; width: 1.5rem; height: 1.5rem;" role="status"></div>';

        const componentVersion = await getComponentVersionRequest(
          componentId,
          component.componentData.componentVersionNumber.toString()
        );

        this.removeAttribute('disabled');
        this.innerHTML = 'Edit';

        setSelectedComponentId(id);
        setSelectedComponentVersion(componentVersion);
        setEditItem({ data: component, componentVersion });
      });

      editButtonElement.style.position = 'absolute';
      editButtonElement.style.right = '20px';

      if (componentElement.parentElement) {
        const topOffset =
          componentElement.offsetTop -
          componentElement.parentElement.scrollTop +
          20;

        if (topOffset >= 0) {
          editButtonElement.style.top = topOffset + 'px';
        } else {
          editButtonElement.style.top = '200px';
        }
      }

      editButtonElement.classList.add('edit-component-button');

      editButtonElement.setAttribute('data-id', id);
      editButtonElement.setAttribute('data-cke-ignore-events', 'true');

      editButtonElement.textContent = 'Edit';

      return editButtonElement;
    }

    return null;
  };

  const handleSaveChanges = () => {
    if (editedTopic) {
      const newComponentBody = [...editedTopic.componentBody];
      const component = newComponentBody?.find(
        (component) => component.id === editItem?.data.id
      );

      if (component) {
        component.componentData.content.content =
          editItem.data.componentData.content.content;

        handleUpdateComponent(component);

        setTimeout(() => {
          props.handleTopicDataChange(
            newComponentBody,
            'componentBody'
          );
          setSelectedComponentId(null);
          setSelectedComponentVersion(null);
          setEditItem(null);
        }, 1000);
      }
    }
  };

  const handleUpdateComponent = (component) => {
    const componentInstance = new Component(
      {
        ...selectedComponentVersion.data,
        content: {
          type: component.componentData.content.type,
          content: component.componentData.content.content
            .replace(/\|\+/g, '')
            .replace(/\\r/g, ''),
        },
      },
      component.order,
      context
    );

    const script = componentInstance.scriptCreator();
    const style = componentInstance.styleCreator();

    document.body.appendChild(script);
    document.body.appendChild(style);

    setTimeout(() => {
      const previewElement = document.getElementById(
        `preview-section-${component.order}`
      );

      if (previewElement) {
        const innerHTML = previewElement.innerHTML;
        const tempElement = document.createElement('div');

        tempElement.innerHTML = props.editorInstance.getData();

        const componentElement = tempElement.querySelector(
          `section[data-id=${CSS.escape(selectedComponentId)}]`
        );

        if (componentElement) {
          componentElement.setAttribute('data-content', innerHTML);

          setEditorContent(tempElement.innerHTML);
        }
      }
    }, 500);
  };

  const handleCancelChanges = () => {
    setSelectedComponentId(null);
    setSelectedComponentVersion(null);
    setEditItem(null);
  };

  if (!editedTopic || (!selectedDoc && editedTopic?.documentId)) {
    return null;
  }

  return (
    <Fragment>
      <CKEditor
        key={editedTopic?.topicId}
        editor={CKEDITOR.ClassicEditor}
        data={editorContent}
        onReady={(editor) => {
          if (editor && editor.editing) {
            // remove all styles except fontWeight, fontStyle, fontSize, textDecoration on paste
            editor.editing.view.document.on('clipboardInput', (evt, data) => {
              const dataTransfer = data.dataTransfer;
              let htmlContent = dataTransfer.getData('text/html');
              if (htmlContent) {
                const tempElement = document.createElement('div');
                tempElement.innerHTML = htmlContent;
                const allElements =
                  tempElement.querySelectorAll<HTMLElement>('*');
                for (let i = 0; i < allElements.length; i++) {
                  const fontWeight = allElements[i].style.fontWeight;
                  const fontStyle = allElements[i].style.fontStyle;
                  const fontSize = allElements[i].style.fontSize;
                  const textDecoration = allElements[i].style.textDecoration;
                  allElements[i].removeAttribute('style');
                  allElements[i].style.fontWeight = fontWeight;
                  allElements[i].style.fontStyle = fontStyle;
                  allElements[i].style.fontSize = fontSize;
                  allElements[i].style.textDecoration = textDecoration;
                }
                htmlContent = tempElement.innerHTML;
                data.content = editor.data.htmlProcessor.toView(htmlContent);
              }
            });
          }
          props.setEditorInstance(editor);
          makeEditorFullHeight(editor);
        }}
        disabled={isDeleted || props.isReader() || isPublishingTopic}
        config={editorConfig}
        onChange={(event, editor) => {
          const content = editor.getData();

          setEditorContent(content);
        }}
      />

      <Modal
        width={720}
        destroyOnClose={true}
        title={getModalTitle()}
        open={editItem !== null}
        afterClose={() => setEditItem(null)}
        onOk={handleSaveChanges}
        onCancel={handleCancelChanges}
      >
        <CodeEditor component={editItem} setComponent={setEditItem} />
      </Modal>

      {editItem !== null ? (
        <div
          id={`preview-section-${editItem.data.order}`}
          style={{ display: 'none' }}
        ></div>
      ) : null}
    </Fragment>
  );
};

export default DocerCKEditor;
