import React, { useState, useEffect, useContext } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Col } from 'antd';

import { AccountContext } from '../../../../contexts/account';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import Steps from '../steps';
import {
  modifyIncomingProcessData,
  showSuccessMessage,
  sleep,
} from '../../../../helpers/common';
import { DocumentContext } from '../../../../contexts/document';
import HelpTexts from '../../../help-texts';
import TextInput from '../../../inputs/text-input';
import useGetProcess from '../../../../api-hooks/process/useGetProcess';
import useDeleteProcess from '../../../../api-hooks/process/useDeleteProcess';
import useCreateProcess from '../../../../api-hooks/process/useCreateProcess';
import useUpdateProcess from '../../../../api-hooks/process/useUpdateProcess';

const useStyles: any = makeStyles(() => ({
  input: {
    '& *': {
      borderRadius: 0,
    },
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
  addStepBox: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%',
  },
}));

interface ProcessData {
  accountId: string;
  title: string;
  stepsActivities: any[];
  activities?: any[];
}

const ManageProcess = (props) => {
  const processId = props.match.params['processId'] || '';

  const { selectedAccount, setSelectedAccount } = useContext(AccountContext);
  const { documents, setDocuments } = useContext(DocumentContext);

  const [processData, setProcessData] = useState<ProcessData>({
    accountId: props.accountConfiguration.id,
    title: '',
    stepsActivities: [],
  });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [openDialogOnFirstActivity, setOpenDialogOnFirstActivity] =
    useState<boolean>(false);

  const { process, isErrorLoadingProcess, isSuccessLoadingProcess } =
    useGetProcess({ processId, enabled: Boolean(processId && props.plan) });
  const { deleteProcess, isDeletingProcess } = useDeleteProcess(
    selectedAccount.id
  );
  const { createProcess, isCreatingProcess } = useCreateProcess(
    selectedAccount.id
  );
  const { updateProcess, isUpdatingProcess } = useUpdateProcess(
    selectedAccount.id
  );

  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleOnDataChange = (item: string, value: any) => {
    setProcessData({ ...processData, [item]: value });
  };

  useEffect(() => {
    if (!processId) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isSuccessLoadingProcess) {
      setLoading(false);
      setProcessData(modifyIncomingProcessData(process));
    }
  }, [isSuccessLoadingProcess]);

  useEffect(() => {
    if (isErrorLoadingProcess) {
      setLoading(false);

      enqueueSnackbar(t('error-get-process'), {
        variant: 'error',
      });

      history.push('/org/settings/processes');
    }
  }, [isErrorLoadingProcess]);

  const handleOnActivityDataChange = (
    id: number,
    item: string,
    value: any,
    step: number
  ) => {
    const tempProcessData = { ...processData };
    const filteredActivities = tempProcessData.stepsActivities[step].filter(
      (activity) => activity.id === id
    );
    if (filteredActivities.length) {
      switch (item) {
        case 'principals':
          // eslint-disable-next-line no-case-declarations
          const newPrincipals: any[] = [];
          value.map((singleValue) => {
            const data = {
              id: singleValue.id || singleValue,
              type: 'USER',
            };
            const filteredPrincipals = newPrincipals.filter(
              (princiapl) => princiapl.id === data.id
            );
            if (filteredPrincipals.length === 0) {
              newPrincipals.push(data);
            } else {
              newPrincipals.splice(
                newPrincipals.indexOf(filteredPrincipals[0]),
                1
              );
            }
            return true;
          });
          filteredActivities[0]['principals'] = newPrincipals;
          break;
        case 'principal':
          // eslint-disable-next-line no-case-declarations
          const filteredPrincipals = filteredActivities[0]['principals'].filter(
            (principal) => principal.id === value
          );
          if (filteredPrincipals.length) {
            filteredActivities[0]['principals'].splice(
              filteredActivities[0]['principals'].indexOf(
                filteredPrincipals[0]
              ),
              1
            );
          }
          break;
        case 'moveActivity':
          tempProcessData.stepsActivities[value].push({
            ...filteredActivities[0],
            step: value,
          });
          tempProcessData.stepsActivities[step].splice(
            tempProcessData.stepsActivities[step].indexOf(
              filteredActivities[0]
            ),
            1
          );
          break;
        case 'requiredApprovals':
          if (value < 1) {
            return;
          }
          break;
        case 'minimumNumberOfImages':
          if (value < 1) {
            return;
          }
          break;
        // eslint-disable-next-line
        default:
          filteredActivities[0][item] = value;
      }
      setProcessData(tempProcessData);
    }
  };

  const handleAddActivity = (step: number) => {
    const newProcessData = { ...processData };
    const filteredStep = newProcessData.stepsActivities[step];
    if (filteredStep) {
      filteredStep.push({
        id: filteredStep.length,
        title: t('untitled'),
        type: 'APPROVAL',
        principals: [],
        requiredApprovals: 1,
        minimumNumberOfImages: 1,
        step: step,
      });
      newProcessData.stepsActivities[step] = filteredStep;
      setProcessData(newProcessData);
      setOpenDialogOnFirstActivity(true);
    }
  };

  const handleAddStep = () => {
    const newProcessData = { ...processData };
    newProcessData.stepsActivities.push([]);
    setProcessData(newProcessData);
  };

  const handleRemoveStep = (step: number) => {
    const newProcessData = { ...processData };
    newProcessData.stepsActivities.slice(step).forEach((stepActivities) => {
      stepActivities.forEach((activity) => {
        activity.step--;
      });
    });
    newProcessData.stepsActivities.splice(step, 1);
    setProcessData(newProcessData);
  };

  const handleRemoveActivity = (id: number, step: number) => {
    const tempProcessData = { ...processData };
    const filteredActivities = tempProcessData.stepsActivities[step].filter(
      (activity) => activity.id === id
    );
    if (filteredActivities.length) {
      tempProcessData.stepsActivities[step].splice(
        tempProcessData.stepsActivities[step].indexOf(filteredActivities[0]),
        1
      );
      setProcessData(tempProcessData);
    }
  };

  const modifyProcessDataForSave = () => {
    const modifiedProcessData: any = { ...processData };
    modifiedProcessData.activities = [];
    modifiedProcessData.stepsActivities.map((stepActivity) => {
      stepActivity.map((activity) => {
        if (activity.id !== undefined && activity.id.toString().length < 4)
          delete activity.id;
        modifiedProcessData.activities.push(activity);
        return true;
      });
      return true;
    });
    delete modifiedProcessData.stepsActivities;
    return modifiedProcessData;
  };

  const validateData = () => {
    const validationData = {
      isValidate: true,
      messages: [],
    };

    if (processData.title === '') {
      validationData.isValidate = false;
      validationData.messages.push(t('error-empty-title'));
    }

    if (processData.stepsActivities.length === 0) {
      validationData.isValidate = false;
      validationData.messages.push(t('error-empty-process'));
    }

    processData.stepsActivities.map((stepActivities) => {
      if (stepActivities.length === 0) {
        if (validationData.messages.indexOf(t('error-empty-step')) < 0) {
          validationData.isValidate = false;
          validationData.messages.push(t('error-empty-step'));
        }
      }
      return true;
    });

    return validationData;
  };

  const handleCreateProcess = () => {
    const validationData = validateData();
    if (validationData.isValidate) {
      const modifiedProcessData = modifyProcessDataForSave();

      createProcess(modifiedProcessData)
        .then(async (response) => {
          await props.getProcesses();
          showSuccessMessage(enqueueSnackbar, t);
          history.push(`/org/settings/processes/${response.id}/manage`);
        })
        .catch((err) => {
          if (err.status === 403) {
            enqueueSnackbar(t('error-create-process-not-owner'), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(t('error-create-process'), {
              variant: 'error',
            });
          }
        });
    } else {
      validationData.messages.map((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
        return true;
      });
    }
  };

  const handleUpdateProcess = () => {
    const modifiedProcessData = modifyProcessDataForSave();

    updateProcess(modifiedProcessData)
      .then(async () => {
        await props.getProcesses();
        showSuccessMessage(enqueueSnackbar, t);
      })
      .catch((err) => {
        if (err.status === 403) {
          enqueueSnackbar(t('error-update-process-not-owner'), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t('error-update-process'), {
            variant: 'error',
          });
        }
      });
  };

  const handleDeleteProcess = () => {
    deleteProcess(processId)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounts'],
        });

        await sleep(1000);

        if (props.processes) {
          const newProcesses: any[] = [...props.processes];
          const filteredPorcesses = newProcesses.filter(
            (newProcess) => newProcess.id !== processId
          );
          filteredPorcesses.sort((a, b) =>
            new Date(a.createDateTime).getTime() <
            new Date(b.createDateTime).getTime()
              ? 1
              : -1
          );
          if (selectedAccount.processId === processId) {
            setSelectedAccount({ ...selectedAccount, processId: null });
            const newDocuments = [...documents];
            newDocuments
              .filter((document) => document.account.id === selectedAccount.id)
              .map((document) => (document.account.processId = null));
            setDocuments(newDocuments);
          }
          history.push('/org/settings/processes');
          props.setProcesses(filteredPorcesses);
        } else {
          history.push('/org/settings/processes');
        }
      })
      .catch(() => {
        enqueueSnackbar(t('error-delete-process'), {
          variant: 'error',
        });
      });
  };

  const handleSaveProcess = () => {
    let showWarning = false;
    for (let i = 1; i < processData.stepsActivities.length; i++) {
      for (let j = 0; j < processData.stepsActivities[i].length; j++) {
        if (
          processData.stepsActivities[i][j].type === 'ALL_TOPICS_HAVE_IMAGE'
        ) {
          showWarning = true;
          break;
        }
        if (showWarning) break;
      }
    }
    if (showWarning) setOpenWarningDialog(true);
    else {
      processId !== '' ? handleUpdateProcess() : handleCreateProcess();
    }
  };

  const helpData = [
    {
      description:
        'The approval process includes parallel and sequential rules and steps to formally approve documents of an organization at different levels.',
    },
    {
      description:
        'Steps of the approval process are sequential, therefore make sure you define a correct order. It is recommended that steps containing automated rules come prior to those with users’ approvals.',
    },
    {
      description:
        'Rules in each step of the approval process can be met in parallel without any order. You can add parallel rules to each step here.',
    },
  ];

  if (loading) {
    return (
      <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Col>
    );
  } else if (props.plan.features.process === false) {
    return <Col xs={24}>{t('approval-enterprise-text')}</Col>;
  } else {
    return (
      <Col xs={24}>
        <Col
          xs={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Typography
            style={{ margin: '10px 0 0 0' }}
            component="h1"
            variant="h6"
          >
            {t('processes-settings-title')}
          </Typography>
        </Col>
        <Col xs={24} style={{ marginBottom: 10 }}>
          <HelpTexts data={helpData} />
        </Col>
        <Col xs={24}>
          <Col xs={24} style={{ display: 'flex' }}>
            <Col xs={12} style={{ paddingRight: 5 }}>
              <TextInput
                field="title"
                value={processData.title}
                label={t('title')}
                onChange={(event) =>
                  handleOnDataChange('title', event.target.value)
                }
                showError={false}
              />
            </Col>
            <Col xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <Col
                xs={8}
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Col
                  xs={22}
                  className={classes.addStepBox}
                  style={{
                    paddingRight: 0,
                    paddingBottom: 0,
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="grey"
                    className={classes.addStepBox}
                    onClick={handleAddStep}
                    style={{ padding: 4, marginTop: 20 }}
                  >
                    <AddIcon />
                    <span>{t('add-step')}</span>
                  </Button>
                </Col>
              </Col>
            </Col>
          </Col>
          <Col xs={24} style={{ display: 'flex' }}>
            <Col
              xs={24}
              style={{
                paddingRight: 5,
                overflowX: 'scroll',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Steps
                openDialogOnFirstActivity={openDialogOnFirstActivity}
                setOpenDialogOnFirstActivity={setOpenDialogOnFirstActivity}
                viewMode={false}
                processData={processData}
                accesses={props.accesses}
                handleOnActivityDataChange={handleOnActivityDataChange}
                handleRemoveStep={handleRemoveStep}
                handleRemoveActivity={handleRemoveActivity}
                handleAddActivity={handleAddActivity}
              />
            </Col>
          </Col>
        </Col>
        <Col
          xs={24}
          style={{
            marginTop: 10,
            textAlign: 'right',
            direction: 'rtl',
            display: 'flex',
          }}
        >
          <Col xs={16}>
            <Button
              onClick={handleSaveProcess}
              style={{ padding: '5px 7px', fontSize: '0.8rem' }}
              variant="outlined"
              color="primary"
              disabled={isCreatingProcess || isUpdatingProcess}
            >
              {isCreatingProcess || isUpdatingProcess ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : processId !== '' ? (
                t('save')
              ) : (
                t('create')
              )}
            </Button>
            <Button
              onClick={() => {
                history.push('/org/settings/processes');
              }}
              style={{ marginRight: 8, padding: '5px 7px', fontSize: '0.8rem' }}
              variant="outlined"
            >
              {t('cancel')}
            </Button>
          </Col>
          {processId !== '' && (
            <Col xs={8} style={{ textAlign: 'left' }}>
              <Button
                style={{
                  marginRight: 10,
                  borderColor: 'red',
                  color: 'red',
                  padding: '5px 7px',
                  fontSize: '0.8rem',
                }}
                variant="outlined"
                onClick={() => setOpenDialog(true)}
              >
                {isDeletingProcess ? (
                  <CircularProgress
                    style={{ width: 25, height: 25, color: 'red' }}
                  />
                ) : (
                  t('delete')
                )}
              </Button>
            </Col>
          )}
        </Col>
        {processId !== '' && (
          <ConfirmationDialog
            isOpen={openDialog}
            onDialogClosed={() => setOpenDialog(false)}
            title={t<string>('delete-process')}
            message={t('delete-process-message')}
            onDialogConfirmed={handleDeleteProcess}
            loading={isDeletingProcess}
          />
        )}
        <ConfirmationDialog
          isOpen={openWarningDialog}
          onDialogClosed={() => setOpenWarningDialog(false)}
          title={t<string>('warning-automated-rules')}
          message={t('warning-automated-rules-message')}
          onDialogConfirmed={
            processId !== '' ? handleUpdateProcess : handleCreateProcess
          }
          loading={isCreatingProcess || isUpdatingProcess}
        />
      </Col>
    );
  }
};

export default ManageProcess;
