import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';

import { Box } from '@mui/material';

import useSyncStatusGoogleDocTopic from '../../api-hooks/topic/useSyncStatusGoogleDocTopic';
import RestrictedLabel from '../../helpers/base-components/restricted-label';
import { AccountContext } from '../../contexts/account';
import { AccountRoles } from '../../helpers/roles';

type EmbeddedGoogleDocProps = {
  driveId: string;
  accountId: string;
  handleOnFocusOut: () => void;
};

const EmbeddedGoogleDoc: React.FC<EmbeddedGoogleDocProps> = ({
  driveId,
  accountId,
  handleOnFocusOut,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSyncStatusDown, setIsSyncStatusDown] = useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const debounceRef = useRef<boolean>(false);

  const { userRole } = useContext(AccountContext);

  const {
    syncStatusData,
    isLoadingsyncStatusGoogleDocTopic,
    isSuccessLoadingsyncStatusGoogleDocTopic,
  } = useSyncStatusGoogleDocTopic({
    accountId: accountId,
    googleDriveId: driveId,
  });

  const { t } = useTranslation();

  const settingsUrl = `${config.get('editorURL')}/org/settings?selectedTab=google drive integration`;

  useEffect(() => {
    if (isLoadingsyncStatusGoogleDocTopic) {
      setIsLoading(true);
      return;
    }

    if (isSuccessLoadingsyncStatusGoogleDocTopic) {
      if (syncStatusData?.status && userRole !== AccountRoles.READER) {
        setIsSyncStatusDown(false);
      } else {
        setIsSyncStatusDown(true);
      }
    }
  }, [isLoadingsyncStatusGoogleDocTopic]);

  useEffect(() => {
    const checkFocus = () => {
      if (
        document.activeElement !== iframeRef.current &&
        !debounceRef.current
      ) {
        debounceRef.current = true;
        handleOnFocusOut();

        setTimeout(() => {
          debounceRef.current = false;
        }, 3000);
      }
    };

    window.addEventListener('blur', checkFocus);
    window.addEventListener('focus', checkFocus);

    return () => {
      window.removeEventListener('blur', checkFocus);
      window.removeEventListener('focus', checkFocus);
    };
  }, [handleOnFocusOut]);

  const handleIframeError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f4f4f4',
      }}
    >
      {isLoading && !hasError && (
        <div style={{ marginBottom: '20px', fontSize: '18px', color: '#555' }}>
          {t('google-doc-loading')}
        </div>
      )}

      {hasError && (
        <div style={{ marginBottom: '20px', fontSize: '18px', color: 'red' }}>
          {t('error-google-doc-loading')}
        </div>
      )}

      {isSyncStatusDown ? (
        <Box
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
            transition: 'width 0.5s ease, height 0.5s ease',
            display: isLoading || hasError ? 'none' : 'block',
          }}
        >
          {userRole !== AccountRoles.READER && (
            <RestrictedLabel
              textContent={
                <Trans
                  i18nKey="not-sync-google-docs-warning"
                  components={{
                    1: (
                      <a
                        href={settingsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: 'underline',  
                          color: 'inherit',             
                        }}
                      >
                        settings
                      </a>
                    ),
                  }}
                />
              }
              contentBgColor="white"
              backgroundColor="red"
              textFont="15px"
            />
          )}

          <iframe
            ref={iframeRef}
            src={`https://docs.google.com/document/d/${driveId}/pub?embedded=true`}
            style={{
              width: '100%',
              height: '100vh',
              border: 'none',
              transition: 'width 0.5s ease, height 0.5s ease',
              display: isLoading || hasError ? 'none' : 'block',
            }}
            onLoad={() => setIsLoading(false)}
            onError={handleIframeError}
            title={t('google-doc-title')}
            allowFullScreen
          />
        </Box>
      ) : (
        <iframe
          ref={iframeRef}
          src={`https://docs.google.com/document/d/${driveId}/edit#heading=h.z6ne0og04bp5`}
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
            transition: 'width 0.5s ease, height 0.5s ease',
            display: isLoading || hasError ? 'none' : 'block',
          }}
          onLoad={() => setIsLoading(false)}
          onError={handleIframeError}
          title={t('google-doc-title')}
          allowFullScreen
        />
      )}
    </div>
  );
};

export default EmbeddedGoogleDoc;
