import React, { useContext, useEffect, useState, useRef } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';

import { getTimeDifferenceInString } from '../../../../helpers/common';
import { DocumentContext } from '../../../../contexts/document';
import { TopicContext } from '../../../../contexts/topic';
import { DraftTopic } from '../../../../api-schemas/topic';

const useStyles: any = makeStyles(() => ({
  breadcrumbs: {
    display: 'flex',
    justifyContent: 'flex-left',
  },
  chip: {
    marginRight: '0px',
  },
}));

let saveDateTimeInterval: any = null;

const Badges = ({
  isLanguageEqual,
  isAlreadyPublished,
  showPublishButton,
  inSaveState,
}) => {
  const [differenceInTime, setDifferenceInTime] = useState<string | null>(null);

  const topicBaseModel = useRef<DraftTopic>();

  const { selectedLanguage } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const classes = useStyles();
  const { t } = useTranslation();

  const setLastSavedDateTime = (saveDateTime: Date) => {
    if (saveDateTimeInterval) {
      clearInterval(saveDateTimeInterval);
    }

    setDifferenceInTime(getTimeDifferenceInString(saveDateTime, new Date()));

    saveDateTimeInterval = setInterval(() => {
      setDifferenceInTime(getTimeDifferenceInString(saveDateTime, new Date()));
    }, 10000);
  };

  useEffect(() => {
    if (
      selectedTopic &&
      (!topicBaseModel.current ||
        topicBaseModel.current!.topicId !== selectedTopic.topicId)
    ) {
      topicBaseModel.current = selectedTopic;

      setLastSavedDateTime(new Date(selectedTopic.topicUpdateDateTime));
    } else if (inSaveState === false) {
      setLastSavedDateTime(new Date());
    }
  }, [inSaveState, selectedTopic]);

  return (
    <span className={classes.breadcrumbs} style={{ marginRight: '20px' }}>
      {isLanguageEqual && !isAlreadyPublished && showPublishButton && (
        <Tooltip title={t<string>('topic-draft')}>
          <Tag
            className={classes.chip}
            style={{
              borderRadius: '4px',
            }}
          >
            Draft
          </Tag>
        </Tooltip>
      )}

      {selectedTopic?.contentType !== 'GoogleDoc' &&
        (inSaveState === true ? (
          <Tooltip arrow title={t<string>('saving')}>
            <Tag
              className={classes.chip}
              style={{
                marginLeft:
                  isLanguageEqual && !isAlreadyPublished && showPublishButton
                    ? '20px'
                    : 0,
                borderRadius: '4px',
              }}
            >
              Saving ...
            </Tag>
          </Tooltip>
        ) : inSaveState === false ? (
          <Tooltip
            arrow
            title={
              t<string>('saved') +
              ' ' +
              (differenceInTime === 'just now'
                ? differenceInTime
                : differenceInTime + ' ago')
            }
          >
            <Tag
              className={classes.chip}
              style={{
                marginLeft:
                  isLanguageEqual && !isAlreadyPublished && showPublishButton
                    ? '20px'
                    : 0,
                borderRadius: '4px',
              }}
            >
              Saved
            </Tag>
          </Tooltip>
        ) : null)}

      {!isLanguageEqual && selectedLanguage && !inSaveState && (
        <Tooltip title={t<string>('topic-no-language-tooltip')}>
          <Tag
            className={classes.chip}
            style={{
              borderRadius: '4px',
              marginLeft: 5,
            }}
          >
            {t('topic-no-language') +
              ISO6391.getName(selectedLanguage.id) +
              t('topic-version')}
          </Tag>
        </Tooltip>
      )}
    </span>
  );
};

export default Badges;
