import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';

import DocumentVersionSelector from './version-selector';
import TopicTreeSelect from '../../select';
import { DocumentContext } from '../../../../../contexts/document';
import { topicService } from '../../../../../services/topic';
import { isNewTopic } from '../../../../../helpers/common';
import { TFunction } from 'i18next';
import TextInput from '../../../../inputs/text-input';
import HeadingSelector from './heading-selector';
import { DraftTopicTree } from '../../../../../api-schemas/topic';

function areEqual(prevProps, nextProps) {
  if (
    nextProps.documentId === prevProps.documentId &&
    nextProps.title === prevProps.title &&
    nextProps.openCopy === prevProps.openCopy &&
    nextProps.openPublish === prevProps.openPublish &&
    nextProps.openTopicLink === prevProps.openTopicLink
  )
    return true;
  else return false;
}

interface TopicSelectorDialogProps extends WithTranslation {
  topics?: any[];
  onClose?: any;
  t: TFunction;
  onAddTopicLink?: any;
  onCopyTopic?: any;
  title?: string;
  onPublishTopics?: any;
  openCopy?: boolean;
  openPublish?: boolean;
  openTopicLink?: boolean;
  usedForTopicLink?: boolean;
  flatDraftTopicTree?: DraftTopicTree;

  documentId?: string;
  okTitle?: string;
  editingTopicLinkData?: any;
  updateTopicLinkData?: any;
  selectedTitle?: string | null | undefined;
  defaultSelectedTopics?: any[];
}

const useStyles: any = makeStyles(() => ({
  topicLinkTree: {
    '& *': {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiButton-root': {
      paddingLeft: 5,
    },
    '& .ant-tree-switcher': {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const MemoisedTopicSelectorDialog = React.memo(function TopicSelectorDialog(
  props: TopicSelectorDialogProps
) {
  const { selectedLanguage, selectedVersion, selectedTopic } =
    React.useContext(DocumentContext);
  const topicAPI = new topicService();

  const [selectedVersionId, setSelectedVersionId] = useState<string>(
    selectedVersion?.id
  );
  const [publishTitle, setPublishTitle] = useState<string>('');
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [checkedNodeIds, setCheckedNodeIds] = useState<any[]>(
    props.defaultSelectedTopics
      ? props.defaultSelectedTopics.map((t) => t.topicId)
      : []
  );
  const [checkedTopics, setCheckedTopics] = useState<any[]>(
    props.defaultSelectedTopics ? props.defaultSelectedTopics : []
  );
  const [selectedTopicInComponent, setSelectedTopicInComponent] =
    useState<any>(null);
  const [topics, setTopics] = useState<any[] | undefined>(props.topics);
  const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
  const [deletedTopics, setDeletedTopics] = useState<any[]>([]);
  const [notPublishedYetTopics, setNotPublishedYetTopics] = useState<any[]>([]);
  const [modifiedTopics, setModifiedTopics] = useState<any[]>([]);
  const [editingTopicLinkData, setEditingTopicLinkData] = useState<any>();
  const { t } = props;
  const topicLinkModalTitle = t('topic-link-modal-title');
  const copyTopicTitle = t('copy-topic-modal-title');
  const publishTopicTitle = t('publish-topics-modal-title');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.defaultSelectedTopics) {
      handleTopicChecked(checkedNodeIds);
    }
    // eslint-disable-next-line
  }, [props.defaultSelectedTopics]);

  useEffect(() => {
    let filteredTopic: any;
    if (props.openTopicLink) {
      if (props.editingTopicLinkData && topics && !editingTopicLinkData) {
        filteredTopic = topics.find(
          (topic) =>
            topic.topicId === props.editingTopicLinkData.topicId ||
            topic.slug === props.editingTopicLinkData.slug
        );
      } else if (topics && selectedTopic) {
        filteredTopic = topics.find(
          (topic) => topic.topicId === selectedTopic.topicId
        );
      }
      if (filteredTopic) {
        handleTopicSelected(filteredTopic, true);
      }
    }
    // eslint-disable-next-line
  }, [props.editingTopicLinkData, topics, props.openTopicLink, selectedTopic]);

  useEffect(() => {
    if (selectedVersionId) loadTopics(selectedVersionId);
  }, [selectedVersionId]);

  // #region event handler methods

  const loadTopics = (documentVersionId) => {
    if (documentVersionId) {
      setLoadingTopics(true);
      return topicAPI
        .getTopics(documentVersionId, selectedLanguage.id)
        .then((rootTopic) => {
          setTopics(rootTopic);
          setLoadingTopics(false);
        });
    }
  };

  const handleCancel = () => {
    setEditingTopicLinkData(null);
    props.onClose();
  };

  const handleOk = () => {
    onClose(selectedTopicId ? selectedTopicId : checkedNodeIds);
  };

  const handleDocumentVersionChanged = (newVersionId) => {
    if (selectedVersionId !== newVersionId) {
      setSelectedVersionId(newVersionId);
      loadTopics(newVersionId);
    }
  };

  const handleTopicSelected = (newTopic, isFirstTime: boolean = false) => {
    setSelectedTopicInComponent(newTopic);
    if (newTopic) {
      setSelectedTopicId(newTopic.topicId);
      if (props.editingTopicLinkData) {
        setEditingTopicLinkData({
          title: isFirstTime
            ? props.editingTopicLinkData.title
            : newTopic.title,
          topicId: newTopic.topicId,
          headingId: isFirstTime ? props.editingTopicLinkData.headingId : null,
          url: isFirstTime
            ? props.editingTopicLinkData.url
            : `/${newTopic.slug}?lang=${selectedLanguage.code}`,
          slug: newTopic.slug,
          key: props.editingTopicLinkData.key,
        });
      } else {
        setEditingTopicLinkData({
          title: props.selectedTitle || newTopic.title,
          topicId: newTopic.topicId,
          url: `/${newTopic.slug}?lang=${selectedLanguage.code}`,
          slug: newTopic.slug,
          headingId: null,
        });
      }
    } else setSelectedTopicId(null);
  };

  const checkPublishEnable = (node) => {
    const isLanguageEqual = node.title !== null;
    const notPublishedYet = node.topicVersionNumber < 1.0;
    const isAlreadyPublished =
      node.latestTopicVersionId === node.publishedTopicVersionId &&
      isLanguageEqual;
    if (
      (notPublishedYet || !isAlreadyPublished || node.action === 'Deleted') &&
      isLanguageEqual
    )
      return false;
    else return true;
  };
  const handleTopicChecked = (checkedNodeIds) => {
    const isCheckedAll = checkedNodeIds.find((nodeId) => nodeId === 'checkall');
    let checkedTopics: any[] = [];

    if (isCheckedAll) {
      // --- check all publishable topics
      const publishableTopics = topics?.filter(
        (topic) => !checkPublishEnable(topic)
      );
      checkedTopics = publishableTopics || [];
      checkedNodeIds = checkedTopics.map((topic) => topic.id);
    } else {
      checkedTopics = checkedNodeIds.map((topicId) => {
        return topics?.find((topic) => topic.id === topicId);
      });
    }
    setCheckedTopics(checkedTopics);
    setCheckedNodeIds(checkedNodeIds);

    const deletedTopics = checkedTopics.filter(
      (topic) => topic.action === 'Deleted'
    );
    const notPublishedYetTopics = checkedTopics.filter(
      (topic) => topic.topicVersionNumber < 1.0 && topic.parentTopicId !== null
    );

    const modifiedTopics = checkedTopics.filter(
      (topic) =>
        topic.latestTopicVersionId !== topic.publishedTopicVersionId &&
        topic.action !== 'Deleted' &&
        topic.topicVersionNumber >= 1.0
    );
    setDeletedTopics(deletedTopics);
    setNotPublishedYetTopics(notPublishedYetTopics);
    setModifiedTopics(modifiedTopics);
  };
  // #endregion

  const onClose = (selectedTopicId) => {
    if (notPublishedYetTopics && notPublishedYetTopics.length > 100) {
      enqueueSnackbar(t<string>('error.cant-publish-more-than-100'), {
        variant: 'error',
      });
      return;
    }
    if (
      selectedTopicId &&
      props.onCopyTopic &&
      props.title === copyTopicTitle
    ) {
      props.onCopyTopic(
        selectedTopicId,
        selectedTopicInComponent.parentTopicId
      );
      props.onClose();
    }
    if (props.title === topicLinkModalTitle) {
      if (props.editingTopicLinkData) {
        props.updateTopicLinkData(editingTopicLinkData);
      } else {
        props.onAddTopicLink(selectedTopicInComponent, editingTopicLinkData);
      }
      setEditingTopicLinkData(null);
      props.onClose();
    }
    if (props.title === publishTopicTitle) {
      const publishingTopics: any[] = [];
      checkedNodeIds.map((nodeId) =>
        publishingTopics.push(
          props.topics?.filter((topic) => topic.id === nodeId)[0]
        )
      );
      props.onPublishTopics(publishingTopics, publishTitle);
      props.onClose();
    }
    setSelectedTopicInComponent(null);
    setSelectedTopicId(null);
    props.onClose();
  };

  const changeTopicLinkTitle = (value: string) => {
    setEditingTopicLinkData({ ...editingTopicLinkData, title: value });
  };

  // #region render methods

  const filteredForTopicLink = props.flatDraftTopicTree?.filter(
    (topic) =>
      topic.parentTopicId !== null && topic.publishedTopicVersionId !== null
  );
  return (
    <Fragment>
      <Dialog
        disableEscapeKeyDown
        open={
          (props.openCopy && props.title === copyTopicTitle) ||
          (props.openPublish && props.title === publishTopicTitle) ||
          ((props.openTopicLink &&
            props.title === topicLinkModalTitle) as boolean)
        }
        fullWidth={!props.usedForTopicLink}
        maxWidth={props.usedForTopicLink ? 'lg' : 'xs'}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
        {props.usedForTopicLink !== true ? (
          props.openCopy ? (
            <DialogContent>
              <DocumentVersionSelector
                selectedVersionId={selectedVersionId}
                documentId={props.documentId}
                onChange={handleDocumentVersionChanged}
              />
              {loadingTopics ? (
                <CircularProgress
                  style={{ marginLeft: '45%', marginTop: '20px' }}
                />
              ) : (
                <TopicTreeSelect
                  topics={topics?.filter(
                    (topic) => !isNewTopic(topics, topic.topicId)
                  )}
                  usedForCopy={true}
                  languageCode={selectedLanguage ? selectedLanguage.code : ''}
                  onTopicSelect={handleTopicSelected}
                  selectedNodeId={selectedTopicId}
                />
              )}
            </DialogContent>
          ) : (
            <DialogContent style={{ padding: '0px' }}>
              <div style={{ paddingLeft: 18 }}>
                <TextInput
                  style={{ marginBottom: 10, padding: '0 15px 0 3px' }}
                  field="publishTitle"
                  label={t('title')}
                  value={publishTitle}
                  onChange={(event) => setPublishTitle(event.target.value)}
                  showError={false}
                />
              </div>

              <TopicTreeSelect
                topics={topics}
                usedForCopy={false}
                languageCode={selectedLanguage ? selectedLanguage.code : ''}
                onTopicCheck={handleTopicChecked}
                checkedKeys={checkedTopics.map((topic) => topic.id)}
                showHeadings={true}
                defaultSelectedTopics={
                  props.defaultSelectedTopics ? props.defaultSelectedTopics : []
                }
              />
            </DialogContent>
          )
        ) : (
          <DialogContent>
            {selectedTopicId ? (
              <TextInput
                style={{ marginBottom: 10, padding: '0 15px 0 3px' }}
                field="title"
                label={t('title')}
                value={editingTopicLinkData ? editingTopicLinkData.title : ''}
                onChange={(event) => changeTopicLinkTitle(event.target.value)}
                showError={false}
              />
            ) : null}
            {filteredForTopicLink && filteredForTopicLink.length > 0 ? (
              <div
                className={classes.topicLinkTree}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minWidth: 520,
                }}
              >
                <div style={{ minWidth: 250 }}>
                  <TopicTreeSelect
                    topics={props.flatDraftTopicTree}
                    usedForCopy={false}
                    checkable={false}
                    usedForTopicLink={props.usedForTopicLink}
                    languageCode={selectedLanguage ? selectedLanguage.code : ''}
                    onTopicSelect={handleTopicSelected}
                    selectedNodeId={selectedTopicId}
                    selectedTopic={topics?.find(
                      (topic) => topic.topicId === selectedTopicId
                    )}
                    expandeNodes={true}
                  />
                </div>
                {selectedTopicId ? (
                  <HeadingSelector
                    selectedTitle={props.selectedTitle}
                    selectedLanguageCode={
                      selectedLanguage ? selectedLanguage.code : ''
                    }
                    selectedTopicId={selectedTopicId}
                    editingTopicLinkData={editingTopicLinkData}
                    setEditingTopicLinkData={setEditingTopicLinkData}
                  />
                ) : null}
              </div>
            ) : (
              <div>{t<string>('empty-topic-link-list')}</div>
            )}
          </DialogContent>
        )}
        {checkedTopics.length > 0 && (
          <div style={{ paddingLeft: '20px' }}>
            {deletedTopics.length > 0 && (
              <Chip
                variant="outlined"
                size="small"
                label={'Deleted: ' + deletedTopics.length}
                style={{ marginRight: '5px' }}
                icon={
                  <SvgIcon
                    style={{
                      color: 'red',
                      marginTop: '7px',
                      marginLeft: '7px',
                    }}
                  >
                    <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM6 7h4a1 1 0 0 1 .117 1.993L10 9H6a1 1 0 0 1-.117-1.993L6 7h4-4z"></path>
                  </SvgIcon>
                }
              />
            )}
            {modifiedTopics.length > 0 && (
              <Chip
                variant="outlined"
                size="small"
                style={{ marginRight: '5px' }}
                label={' Modified: ' + modifiedTopics.length}
                icon={
                  <SvgIcon
                    style={{
                      color: '#fc9403',
                      marginTop: '7px',
                      marginLeft: '7px',
                    }}
                    fontSize="large"
                  >
                    <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path>
                  </SvgIcon>
                }
              />
            )}
            {notPublishedYetTopics.length > 0 && (
              <Chip
                variant="outlined"
                size="small"
                style={{ marginRight: '5px', color: 'green' }}
                label={' Added: ' + notPublishedYetTopics.length}
                icon={
                  <SvgIcon
                    style={{
                      color: 'green',
                      marginTop: '7px',
                      marginLeft: '7px',
                    }}
                    fontSize="large"
                  >
                    <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 5a1 1 0 0 1 .993.883L9 6v1h1a1 1 0 0 1 .117 1.993L10 9H9v1a1 1 0 0 1-1.993.117L7 10V9H6a1 1 0 0 1-.117-1.993L6 7h1V6a1 1 0 0 1 1-1z"></path>
                  </SvgIcon>
                }
              />
            )}
          </div>
        )}
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            color="primary"
            variant="contained"
            disabled={
              (props.usedForTopicLink === true &&
                (!filteredForTopicLink || filteredForTopicLink.length === 0)) ||
              (selectedTopicId === null && checkedNodeIds.length === 0)
            }
            autoFocus
          >
            {props.okTitle ? props.okTitle : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}, areEqual);

export default withTranslation()(MemoisedTopicSelectorDialog);
