import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { ProfileContext } from '../profile';
import { AccountRoles as ROLES } from '../../helpers/roles';
import { Account } from '../../api-schemas/account';
import { getLanguageCode, isHomepage } from '../../helpers/route';
import useGetAccounts from '../../api-hooks/account/useGetAccounts';

const initialState: AccountContextType = {
  accountsData: undefined,
  selectedAccount: {},
  setSelectedAccount: Function,
  userRole: null,
  setUserRole: Function,
  selectedLanguage: undefined,
};

type AccountContextType = {
  accountsData: any;
  selectedAccount: any;
  setSelectedAccount: (value: any) => void;
  userRole: string | null;
  setUserRole: (value: string) => void;
  selectedLanguage?: string;
};

export const AccountContext =
  React.createContext<AccountContextType>(initialState);

export function AccountProvider({ children }) {
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>();

  const previousSelectedAccountId = useRef<string>();

  const { profileData } = useContext(ProfileContext);

  const history = useHistory();
  const queryClient = useQueryClient();
  const { search } = useLocation();

  const { accounts } = useGetAccounts();

  useEffect(() => {
    setSelectedLanguage(
      getLanguageCode() || selectedAccount?.defaultLanguageCode
    );
  }, [search, selectedAccount]);

  useEffect(() => {
    if (accounts && !selectedAccount) {
      const previousSelectedAccountId =
        localStorage.getItem('selectedAccountId');

      if (previousSelectedAccountId) {
        const previousSelectedAccount = accounts.find(
          (account) => account.id === previousSelectedAccountId
        );

        if (previousSelectedAccount) {
          setSelectedAccount(previousSelectedAccount);

          return;
        }
      }

      if (profileData) {
        const userAccount = accounts.find(
          (account) => account.email === profileData.email
        );

        if (userAccount) {
          setSelectedAccount(userAccount);
          setUserRole(ROLES.OWNER);
        } else {
          setSelectedAccount(accounts[0]);
        }
      }
    }
  }, [profileData, accounts]);

  useEffect(() => {
    if (accounts && selectedAccount) {
      const updatedAccount = accounts.find(
        (account) => account.id === selectedAccount.id
      );

      if (updatedAccount) {
        setSelectedAccount(updatedAccount);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (
      selectedAccount &&
      selectedAccount.id !== previousSelectedAccountId.current
    ) {
      previousSelectedAccountId.current = selectedAccount.id;

      localStorage.setItem('selectedAccountId', selectedAccount.id);

      queryClient.invalidateQueries({
        queryKey: ['accesses', selectedAccount.id],
      });

      if (isHomepage()) {
        history.push({
          search: `?lang=${selectedAccount.defaultLanguageCode}`,
        });
      }
    }
  }, [selectedAccount]);

  const value = {
    accountsData: accounts,
    selectedAccount,
    setSelectedAccount,
    userRole,
    setUserRole,
    selectedLanguage,
  };

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
}
