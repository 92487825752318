import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Account,
  AccountWebAssistantSettings,
} from '../../api-schemas/account';
import {
  base64toBlob,
  getImageContentType,
  getImageType,
} from '../../helpers/common';

type AccountGeneralSettings = Account & {
  webAssistant: AccountWebAssistantSettings;
};

const transformAccountGeneralSettingsData = async (
  account: AccountGeneralSettings
) => {
  const data = new FormData();

  if (account.favicon) {
    if ((account.favicon as any) instanceof Blob) {
      data.append('Favicon', account.favicon);
    } else {
      const imageContentType = getImageContentType(account.favicon);
      const imageType = getImageType(imageContentType);
      const favicon = await base64toBlob(account.favicon, imageContentType);

      if (favicon) data.append('Favicon', favicon, `favicon.${imageType}`);
      else data.append('Favicon', '');
    }
  } else {
    data.append('Favicon', '');
  }

  data.append('Id', account.id);
  data.append('Title', account.title);
  data.append('Slug', account.slug);
  data.append('Email', account.email);
  data.append('TrackingId', account.trackingId ?? '');
  data.append(
    'GoogleSiteVerificationId',
    account.googleSiteVerificationId ?? ''
  );
  data.append('BingSiteVerificationId', account.bingSiteVerificationId ?? '');
  data.append('GoogleTagManagerId', account.googleTagManagerId ?? '');
  data.append('CustomDomain', account.customDomain ? account.customDomain : '');
  data.append('WebsiteUrl', account.websiteUrl ?? '');
  data.append('Style', account.style ?? 'Flat');
  data.append('isFeedbackEnabled', `${account.isFeedbackEnabled ?? false}`);
  data.append(
    'isDocumentExportEnabled',
    `${account.isDocumentExportEnabled ?? false}`
  );
  data.append('ProcessId', account.processId);
  data.append('isPrivate', account.isPrivate.toString());
  data.append('Menu', account.menu);
  data.append('ShowAISearchResult', account.showAISearchResult.toString());
  data.append('NavbarVisibility', account.navbarVisibility.toString());
  data.append(
    'ShareButtonVisibility',
    account.shareButtonVisibility.toString()
  );
  data.append(
    'DurationToReadVisibility',
    account.durationToReadVisibility.toString()
  );
  data.append('DarkModeVisibility', account.darkModeVisibility.toString());
  data.append('AiSearchEngineModel', account.aiSearchEngineModel);

  for (const webAssistantItem in account.webAssistant) {
    data.append(
      `WebAssistant.${webAssistantItem}`,
      account.webAssistant[webAssistantItem]
    );
  }

  return data;
};

const updateAccountGeneralSettingsRequest = async (
  data: AccountGeneralSettings
) => {
  const body = await transformAccountGeneralSettingsData(data);

  return axios
    .put('/api/Account/general-settings', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountGeneralSettings(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountGeneralSettings,
    isPending: isUpdatingAccountGeneralSettings,
    isSuccess: isSuccessUpdatingAccountGeneralSettings,
  } = useMutation({
    mutationFn: updateAccountGeneralSettingsRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });

      queryClient.invalidateQueries({
        queryKey: ['account', accountId],
      });
    },
  });

  return {
    updateAccountGeneralSettings,
    isUpdatingAccountGeneralSettings,
    isSuccessUpdatingAccountGeneralSettings,
  };
}
