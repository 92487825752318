import React from 'react';
import { withStyles } from '@mui/styles';
import ManageViewerAccesses from '../../../manage-viewer-accesses';

const styles: any = (theme) => ({
  documentUserContainer: {
    width: 'auto',
    height: 'calc(100% - 40px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

const ViewerAccesses = ({
  handlePrivateChanged,
  loading,
  ROLES,
  documentId,
  accesses,
  selectedDoc,
  loadAccesses,
  handleAccessActionChanged,
  handleAddAccesses,
  handleRemoveAccess,
  ...props
}) => {
  const { classes } = props;
  return (
    <div className={classes.documentUserContainer}>
      <ManageViewerAccesses
        documentId={documentId}
        loading={loading}
        handlePrivateChanged={handlePrivateChanged}
        selectedDoc={selectedDoc}
        entityType="Document"
        accesses={accesses}
        plan={props.plan}
        handleAccessActionChanged={handleAccessActionChanged}
        ROLES={ROLES}
        isViewerAccess={true}
        handleAddAccesses={handleAddAccesses}
        handleRemoveAccess={handleRemoveAccess}
        loadAccesses={loadAccesses}
        entityTitle={selectedDoc?.title}
        title={null}
        type={props.type || 'USER'}
      />
    </div>
  );
};

export default withStyles(styles)(ViewerAccesses);
