import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { UserNotifications } from '../../api-schemas/user';

const getNotificationsRequest = (lastCreateDate: string | null = null) => {
  return axios
    .get<UserNotifications>(`/api/User/Notification`, {
      params: { lastCreateDate },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetUserNotifications(
  lastCreateDate: string | null = null
) {
  const {
    data,
    refetch: getNotifications,
    isFetching: isLoadingNotifications,
    isSuccess: isSuccessLoadingNotifications,
  } = useQuery<UserNotifications>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['user', 'notifications', lastCreateDate],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () => getNotificationsRequest(lastCreateDate),
  });

  const notifications = useMemo(() => data || [], [data]);

  return {
    notifications,
    isLoadingNotifications,
    isSuccessLoadingNotifications,

    getNotifications,
  };
}
