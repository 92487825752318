import React, { useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';

const SnackbarProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const notistackRef = useRef<any>();

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <Button
          onClick={() => notistackRef?.current?.closeSnackbar(key)}
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        />
      )}
      maxSnack={3}
      autoHideDuration={7000}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderWrapper;
