import React from 'react';
import { Col, Row, Typography } from 'antd';
import NavbarMenuBuilderEditor from './editor';
import NavbarMenuBuilderPreview from './preview';
import { useTranslation } from 'react-i18next';

const defaultData: string = `items:
  - title: First Item
    url: https://sonat.com
    target: newTab
  - title: Second Item
    url: https://sonat.com`;

interface NavbarManeBuilderInterface {
  data: string | null;
  handleChangeData: (value: string) => void;
  showEditor: boolean;
  showPreview: boolean;
  showPreviewMassage?: boolean;
}

const { Text } = Typography;

const NavbarMenuBuilder: React.FC<NavbarManeBuilderInterface> = ({
  data,
  handleChangeData,
  showEditor,
  showPreview,
  showPreviewMassage,
}) => {
  const { t } = useTranslation();

  return (
    <Row style={{ justifyContent: 'space-between' }}>
      {showEditor ? (
        <Col xs={24} style={{ marginTop: 10 }}>
          <NavbarMenuBuilderEditor
            data={data || defaultData}
            handleChangeData={handleChangeData}
          />
        </Col>
      ) : null}
      {showPreview ? (
        <Col xs={24} style={{ marginTop: 15 }}>
          <NavbarMenuBuilderPreview
            isResponsive={false}
            data={data || defaultData}
          />
        </Col>
      ) : null}
      {showPreviewMassage && (
        <Col xs={24} style={{ marginTop: 15 }}>
          <Text>{t('navbar-menu-builder-preview')}</Text>
        </Col>
      )}
    </Row>
  );
};

export default NavbarMenuBuilder;
