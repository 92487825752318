import Countable from 'countable';
import { Paper, Researcher } from 'yoastseo';
import readingTime from 'reading-time';
import yaml from 'js-yaml';
import { convertToSlug, getNotReservedTopicIndex } from '../common';
import { DraftTopic } from '../../api-schemas/topic';

export const extractReadabilityMetrics = (
  topic: any,
  textContent: string | null,
  body
) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const textReadability = require('text-readability');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Sentiment = require('sentiment');
  const sentiment = new Sentiment();

  const metrics: any = {};

  if (topic.languageCode === 'en') {
    try {
      metrics.syllableCount = textReadability.syllableCount(textContent);
      metrics.lexiconCount = textReadability.lexiconCount(textContent);
      metrics.fleschReadingEase =
        textReadability.fleschReadingEase(textContent);
      metrics.fleschKincaidGrade =
        textReadability.fleschKincaidGrade(textContent);
      metrics.gunningFog = textReadability.gunningFog(textContent);
      metrics.smogIndex = textReadability.smogIndex(textContent);
      metrics.automatedReadabilityIndex =
        textReadability.automatedReadabilityIndex(textContent);
      metrics.colemanLiauIndex = textReadability.colemanLiauIndex(textContent);
      metrics.linsearWriteFormula =
        textReadability.linsearWriteFormula(textContent);
      metrics.daleChallReadabilityScore =
        textReadability.daleChallReadabilityScore(textContent);
      metrics.textStandard = textReadability.textStandard(textContent);
    } catch (error) {
      /* empty */
    }

    try {
      Countable.count(body, (counter) => {
        metrics.charactersCount = counter.characters;
      });
    } catch (error) {
      /* empty */
    }

    try {
      metrics.sentimentData = sentiment.analyze(textContent);
    } catch (error) {
      /* empty */
    }
  }

  try {
    metrics.timeToRead = readingTime(body).minutes;
  } catch (error) {
    /* empty */
  }

  try {
    const yoastSupportedLanguages: string[] = [
      'en',
      'de',
      'nl',
      'fr',
      'es',
      'it',
      'pt',
      'ru',
      'ca',
      'pl',
      'sv',
      'hu',
      'id',
      'ar',
      'he',
      'fa',
      'tr',
    ];

    if (yoastSupportedLanguages.indexOf(topic.languageCode) >= 0) {
      const paper = new Paper(textContent, {
        keyphrase: topic.keyphrase ? topic.keyphrase : 'topic',
        title: topic.title,
        description: topic.description || '',
      });
      const researcher = new Researcher(paper);
      const researches = [
        'urlLength',
        'wordCountInText',
        'findKeywordInPageTitle',
        'calculateFleschReading',
        'getLinkStatistics',
        'getLinks',
        'linkCount',
        'imageCount',
        'altTagCount',
        'matchKeywordInSubheadings',
        'stopWordsInKeyword',
        'getKeywordDensity',
        'stopWordsInKeyword',
        'stopWordsInUrl',
        'metaDescriptionLength',
        'keyphraseLength',
        'keywordCountInUrl',
        'firstParagraph',
        'metaDescriptionKeyword',
        'pageTitleWidth',
        'getParagraphLength',
        'countSentencesFromText',
        'countSentencesFromDescription',
        'getSubheadingTextLengths',
        'findTransitionWords',
        'passiveVoice',
        'getSentenceBeginnings',
        'relevantWords',
      ];

      for (const research of researches) {
        metrics[research] = researcher.getResearch(research);
      }
      metrics['paragraphCount'] = metrics.getParagraphLength?.length;
      metrics['passiveVoice'] =
        metrics.passiveVoice?.passives && metrics.countSentencesFromText
          ? Math.round(
              (metrics.passiveVoice.passives.length /
                metrics.countSentencesFromText.length) *
                100
            )
          : 0;
      metrics['consecutiveSentences'] = metrics.getSentenceBeginnings
        ?.map((sentence) => sentence.word)
        .join(',');
    }
  } catch (error) {
    /* empty */
  }

  return metrics;
};

export const calculateNewTopicAndSlug = (
  topic,
  newSlug,
  topics,
  newTopicTitle
) => {
  const newTopicSlug: string = convertToSlug(newTopicTitle);
  const maxIndex: number = getNotReservedTopicIndex(
    newTopicSlug,
    topics,
    topic.topicId
  );

  if (topic.body === '<h1></h1><p></p>') {
    topic.body = `<h1>${newTopicTitle}</h1><p>&nbsp</p>`;
    newSlug = maxIndex ? newTopicSlug + '-' + maxIndex : newTopicSlug;
  }

  if (topic.title === '' || topic.title === '&nbsp;') {
    topic.title = newTopicTitle;
    topic.slug = convertToSlug(
      maxIndex ? newTopicSlug + '-' + maxIndex : newTopicSlug
    );
    newSlug = maxIndex ? newTopicSlug + '-' + maxIndex : newTopicSlug;
  }

  let newTitle: string = topic.title; //default value
  let newTitleWithoutSpaces: string = newTitle; //default value

  if (topic.body && topic.contentType !== 'Component') {
    // retrieve title from the content
    const tempElement = document.createElement('div');

    tempElement.innerHTML = topic.body;
    newTitle = tempElement.getElementsByTagName('h1')[0]?.innerText;
    newTitle = newTitle?.replace(/&nbsp;/g, ''); // fix spaces
    newTitleWithoutSpaces = newTitle?.trim();
  }

  if (
    !!newTitle &&
    (newTitle === '&nbsp;' ||
      newTitleWithoutSpaces === '&nbsp;' ||
      newTitleWithoutSpaces.length === 0)
  ) {
    if (topic.contentType !== 'Component') {
      const tempElement = window.document.createElement('div');

      tempElement.innerHTML = topic.body;
      tempElement.querySelectorAll('h1')[0].innerText = newTopicTitle;
      topic.body = tempElement.innerHTML;
    }

    newTitle = newTopicTitle;
    newSlug = maxIndex ? newTopicSlug + '-' + maxIndex : newTopicSlug;
  } else if (topic.contentType !== 'Component') {
    let title: string = '';

    if (topic.contentType !== 'Component') {
      const tempElement = window.document.createElement('div');

      tempElement.innerHTML = topic.body;

      const h1Element = tempElement.querySelector('h1');
      title = h1Element ? h1Element.innerText : '';
      if (title) topic.title = title;
    } else {
      title = topic.title;
    }

    newTitle = title;

    const maxIndex: number = getNotReservedTopicIndex(
      topic.slug || convertToSlug(newTitle),
      topics,
      topic.topicId
    );

    newSlug = maxIndex
      ? (topic.slug || convertToSlug(newTitle)) + '-' + maxIndex
      : topic.slug || convertToSlug(newTitle);
    topic.slug = newSlug;
  }

  if (newTitle.trim() === '') {
    newTitle = newTopicTitle;
  }

  if (newSlug.trim() === '') {
    const maxIndex: number = getNotReservedTopicIndex(
      topic.slug || convertToSlug(newTitle),
      topics,
      topic.topicId
    );

    newSlug = maxIndex
      ? (topic.slug || convertToSlug(newTitle)) + '-' + maxIndex
      : topic.slug || convertToSlug(newTitle);
    topic.slug = newSlug;
  }

  return [topic, newSlug, newTitle];
};

export const addCustomMetricsData = (
  topic: any,
  metrics: any,
  imageSRCs: string
) => {
  const body =
    topic.contentType !== 'Component'
      ? topic.body
      : document.getElementById('component-editor')?.innerHTML;
  metrics['wordCount'] = metrics['wordCountInText'];

  if (!metrics['wordCount'] || metrics['wordCount'] < 100) {
    return null;
  }

  if (metrics['countSentencesFromText']) {
    let sentenceLengthSum: number = 0;
    metrics['countSentencesFromText'].map((sentence) => {
      sentenceLengthSum += sentence.sentenceLength;
      return true;
    });
    metrics['averageSentenceLength'] =
      sentenceLengthSum / metrics['countSentencesFromText'].length;
  }

  metrics['titleLength'] = topic.title.length;
  metrics['metaDescription'] = !!topic.description;
  metrics['metaDescriptionLength'] = topic.description
    ? topic.description.length
    : 0;
  metrics['keyphraseLength'] = topic.focusKeyword
    ? topic.focusKeyword.length
    : 0;
  metrics['focusKeywordInTitle'] = topic.focusKeyword
    ? topic.title
        .toLowerCase()
        .replaceAll(' ', '')
        .includes(topic.focusKeyword.toLowerCase().replaceAll(' ', ''))
    : false;
  metrics['focusKeywordInMeta'] =
    topic.focusKeyword && topic.description
      ? topic.description
          .toLowerCase()
          .replaceAll(' ', '')
          .includes(topic.focusKeyword.toLowerCase().replaceAll(' ', ''))
      : false;
  metrics['focusKeywordInUrl'] = topic.focusKeyword
    ? topic.slug
        .toLowerCase()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .includes(topic.focusKeyword.toLowerCase().replaceAll(' ', ''))
    : false;
  metrics['readabilityScore'] =
    metrics['calculateFleschReading'] > 100
      ? 100
      : metrics['calculateFleschReading'] < 0
        ? 0
        : metrics['calculateFleschReading'];

  const tempElement = document.createElement('div');
  tempElement.innerHTML = body!;

  metrics['avgParagraphWordCount'] =
    metrics['wordCount'] / tempElement.querySelectorAll('p').length;

  const internalLinksCount =
    tempElement.querySelectorAll('a.topic-link').length;
  const outboundLinksCount =
    tempElement.querySelectorAll('a').length - internalLinksCount;
  metrics['internalLinks'] = internalLinksCount > 0;
  metrics['outboundLinks'] = outboundLinksCount > 0;

  let numberOfKeyphrases: any = 0;
  const headers = tempElement.querySelectorAll('h2, h3, .custom-heading');
  if (topic.focusKeyword) {
    headers.forEach((header) => {
      const headerText = header.textContent || header.innerHTML;
      if (
        headerText
          .toLowerCase()
          .replaceAll(' ', '')
          .includes(topic.focusKeyword.toLowerCase().replaceAll(' ', ''))
      ) {
        numberOfKeyphrases++;
      }
    });
  }
  metrics['keyphraseInSubheadings'] = headers.length
    ? Math.round((numberOfKeyphrases / headers.length) * 100)
    : 0;

  const content = (tempElement.textContent || '')
    .replace(/\s/g, ' ')
    .toLowerCase()
    .replaceAll(' ', '');
  metrics['keyphraseDensity'] = topic.focusKeyword
    ? (content.split(topic.focusKeyword.toLowerCase().replaceAll(' ', ''))
        .length -
        1) /
      content.split(' ').length
    : 0;

  let numberOfWordsBetweenHeaders: number = 0;
  for (let i = -1; i < headers.length; i++) {
    const firstHeaderString = i === -1 ? '' : headers[i].outerHTML;
    const secondHeaderString =
      i < headers.length - 1 ? headers[i + 1].outerHTML : '';
    const startIndex =
      i === -1
        ? 0
        : tempElement.innerHTML.indexOf(firstHeaderString) +
          firstHeaderString.length;
    const endIndex =
      i < headers.length - 1
        ? tempElement.innerHTML.indexOf(secondHeaderString)
        : tempElement.innerHTML.length - 1;
    const htmlContent = tempElement.innerHTML
      .substring(startIndex, endIndex)
      .replace(/\s/g, ' ');
    const secondTempElement = document.createElement('div');
    secondTempElement.innerHTML = htmlContent;
    const content = secondTempElement.textContent || '';
    const numberOfWords = content.split(' ').length;
    numberOfWordsBetweenHeaders += numberOfWords;
  }
  metrics['subheadingDistribution'] = headers.length
    ? numberOfWordsBetweenHeaders / headers.length
    : 0;

  metrics['h1Heading'] = true;
  metrics['h2Heading'] = !!tempElement.querySelectorAll('h2, .custom-heading')
    .length;

  let allBlockImagesHaveCaption = true;
  const figures = tempElement.querySelectorAll('figure');
  figures.forEach((figure) => {
    if (figure.querySelector('img') && !figure.querySelector('figcaption')) {
      allBlockImagesHaveCaption = false;
      return;
    }
  });
  metrics['allBlockImagesHaveCaption'] = allBlockImagesHaveCaption;

  let allImagesHaveAlt = true;
  const images = tempElement.querySelectorAll('img');
  images.forEach((image) => {
    if (!image.getAttribute('alt')) {
      allImagesHaveAlt = false;
    }
  });
  metrics['imageAltAttribute'] = images.length === 0 ? false : allImagesHaveAlt;

  metrics['imageSrcs'] = imageSRCs;

  metrics['image'] = images.length !== 0;

  const newTempElement = document.createElement('div');
  newTempElement.innerHTML = body!;
  newTempElement.querySelectorAll('figcaption')?.forEach((element) => {
    element.remove();
  });

  if (newTempElement.textContent) {
    metrics['sentenceCount'] =
      newTempElement.textContent?.split('.').length +
      newTempElement.textContent?.split(':').length -
      2;
    metrics['transitionWords'] =
      (metrics.findTransitionWords?.sentenceResults?.length /
        metrics['sentenceCount']) *
      100;
    metrics['charactersCount'] = newTempElement.textContent
      ?.replace(/&nbsp;/g, '')
      .replaceAll(' ', '')
      .split('').length;
  }

  metrics['wordCount'] =
    newTempElement.innerHTML
      .replace(/<[^>]*>/g, ' ')
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ').length - 1;

  metrics['fleschReadingEase'] =
    metrics['fleschReadingEase'] > 100
      ? 100
      : metrics['fleschReadingEase'] < 0
        ? 0
        : metrics['fleschReadingEase'];
  metrics['transitionWords'] = metrics['transitionWords']
    ? metrics['transitionWords']
    : 0;

  delete metrics['wordCountInText'];
  delete metrics['automatedReadabilityIndex'];
  delete metrics['fleschKincaidGrade'];
  delete metrics['gunningFog'];
  delete metrics['smogIndex'];
  delete metrics['colemanLiauIndex'];
  delete metrics['linsearWriteFormula'];
  delete metrics['daleChallReadabilityScore'];
  delete metrics['textStandard'];
  delete metrics['urlLength'];
  delete metrics['findKeywordInPageTitle'];
  delete metrics['calculateFleschReading'];
  delete metrics['getLinkStatistics'];
  delete metrics['getLinks'];
  delete metrics['linkCount'];
  delete metrics['altTagCount'];
  delete metrics['matchKeywordInSubheadings'];
  delete metrics['stopWordsInKeyword'];
  delete metrics['stopWordsInUrl'];
  delete metrics['firstParagraph'];
  delete metrics['relevantWords'];
  delete metrics['imageCount'];

  return metrics;
};

export const updateComponentIndex = (item, increaseIndex: boolean) => {
  const previousIndex: number = parseInt(item.order);
  window.componentsData[previousIndex] = null;
  const newIndex: number = increaseIndex
    ? previousIndex + 1
    : previousIndex - 1;
  item.order = newIndex.toString();
  document.getElementById(`component-script-${newIndex}`)?.remove();
  document.getElementById(`component-style-${newIndex}`)?.remove();
  window.componentsData[newIndex] = yaml.load(
    item.componentData.content.content
  );
  return item;
};

export const removeHeadingIds = (contentBody) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = contentBody;
  const headings = tempElement.querySelectorAll<HTMLElement>('h2');
  headings.forEach((heading) => {
    heading.removeAttribute('id');
  });
  return tempElement.innerHTML.toString();
};

export const isTopicUpdated = (topic: DraftTopic, updatedTopic: DraftTopic) => {
  const bodyWithoutHeadingIds = removeHeadingIds(updatedTopic.body);

  return (
    bodyWithoutHeadingIds !== topic.body ||
    updatedTopic.componentBody !== topic.componentBody ||
    updatedTopic.title !== topic.title ||
    updatedTopic.slug !== topic.slug ||
    updatedTopic.description !== topic.description ||
    updatedTopic.tool !== topic.tool ||
    updatedTopic.supply !== topic.supply ||
    updatedTopic.focusKeyword !== topic.focusKeyword ||
    updatedTopic.firstImageId !== topic.firstImageId ||
    updatedTopic.imageId !== topic.imageId ||
    updatedTopic.webAssistantSimpleRegex !== topic.webAssistantSimpleRegex ||
    updatedTopic.isHidden !== topic.isHidden
  );
};
