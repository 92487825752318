import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DiscardTopicRequest } from '../../api-schemas/topic';

const discardTopicRequest = (data: DiscardTopicRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Topic/Discard', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDiscardTopic({
  documentVersionId,
  languageCode,
  topicId,
}: {
  documentVersionId?: string;
  languageCode: string;
  topicId: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: discardTopic,
    isPending: isDiscardingTopic,
    isSuccess: isSuccessDiscardingTopic,
  } = useMutation({
    mutationFn: discardTopicRequest,
    onSuccess: () => {
      if (documentVersionId) {
        queryClient.invalidateQueries({
          queryKey: ['draftTopicTree', documentVersionId, languageCode],
        });
      }

      queryClient.invalidateQueries({
        queryKey: ['draftTopic', topicId, languageCode],
      });
    },
  });

  return {
    discardTopic,
    isDiscardingTopic,
    isSuccessDiscardingTopic,
  };
}
