import React, { Fragment, useContext, useState } from 'react';
import { ProfileContext } from '../../contexts/profile';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import ProfileAuthConfigurationEditor from './auth';
import ProfileNotificationConfigurationEditor from './notification';
import { Col } from 'antd';

const styles: any = (theme) => ({
  mainConfigurationBody: {
    padding: '15px 15px 0px',
    height: 'calc(100% - 40px)',
    maxWidth: '1100px',
    margin: 'auto!important',
    boxShadow: 'none!important',
  },
  tab: { marginTop: '10px' },
  paper: {
    padding: `${theme.spacing(2)}!important`,
    [theme.breakpoints.up(600 + parseInt(theme.spacing(3)) * 2)]: {
      padding: `${theme.spacing(3)}!important`,
      paddingBottom: `${theme.spacing(0.5)}!important`,
    },
    paddingBottom: `${theme.spacing(0.5)}!important`,
    boxShadow: 'none!important',
  },
  accountEditor: {
    height: 'calc(100% - 70px)',
  },
  noShadow: {
    boxShadow: 'none!important',
  },
  summary: {
    display: 'initial',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '5px',
  },
});

const Profile = (props) => {
  const { profileData } = useContext(ProfileContext);

  const [expanded, setExpanded] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleChangeExpand =
    (panel: string) => (event, isExpanded: boolean) => {
      isExpanded ? setExpanded(panel) : setExpanded(null);
    };

  const { classes } = props;

  return (
    <div className={classes.mainConfigurationBody}>
      {profileData ? (
        <Fragment>
          <Box display="flex" p={1} m={1}>
            <Box p={1} flexGrow={1}>
              <Typography className={classes.heading}>
                {t('profile-settings')}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ margin: '5px' }} />
          <Paper className={classes.paper}>
            <div className={classes.accountEditor}>
              <Fragment>
                <Accordion
                  className={classes.noShadow}
                  expanded={expanded === 'panel1'}
                  onChange={handleChangeExpand('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: 0 }}
                  >
                    <Typography className={classes.heading}>
                      {t('auth-settings-title')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.summary}>
                    <div>
                      <div style={{ margin: '0' }}>
                        <ProfileAuthConfigurationEditor
                          profileData={profileData}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <Accordion
                  className={classes.noShadow}
                  expanded={expanded === 'panel2'}
                  onChange={handleChangeExpand('panel2')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{ padding: 0 }}
                  >
                    <Typography className={classes.heading}>
                      {t('notification-settings-title')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.summary}>
                    <div>
                      <div style={{ margin: '0' }}>
                        <ProfileNotificationConfigurationEditor
                          profileData={profileData}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </Fragment>
            </div>
          </Paper>
        </Fragment>
      ) : (
        <Grid
          container
          spacing={0}
          alignItems="center"
          direction="column"
          style={{ justifyContent: 'center' }}
        >
          <Col>
            <CircularProgress />
          </Col>
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(Profile);
