import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteFolderRequest = ({ folderId }: { folderId: string }) => {
  return axios
    .delete(`/api/Folder/${folderId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteFolder(
  accountId: string,
  parentFolderId?: string
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteFolder,
    isPending: isDeletingFolder,
    isSuccess: isSuccessDeletingFolder,
  } = useMutation({
    mutationFn: deleteFolderRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: parentFolderId
          ? ['folders', accountId, parentFolderId]
          : ['folders', accountId],
      });
    },
  });

  return {
    deleteFolder,
    isDeletingFolder,
    isSuccessDeletingFolder,
  };
}
