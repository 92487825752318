import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, Divider } from '@mui/material';
import GoogleSearchResultBreadcrumb from '../breadcrumb';
import { getDateTime } from '../../../helpers/common';
import { TopicContext } from '../../../contexts/topic';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: '#326cd9',
    fontWeight: 400,
  },
});

const GoogleNormalSearchResult = ({ topicList }) => {
  const classes = useStyles();

  const { selectedTopic } = useContext(TopicContext);

  if (selectedTopic) {
    return (
      <Card className={classes.root}>
        <CardContent style={{ paddingBottom: 10 }}>
          <GoogleSearchResultBreadcrumb topicList={topicList} />

          <Typography className={classes.title} gutterBottom>
            {selectedTopic?.title}
          </Typography>

          <Divider />

          <div style={{ marginTop: 10 }}>
            <span style={{ color: '#7b8085' }}>
              {getDateTime(selectedTopic)}
            </span>

            <span style={{ color: '#7b8085', margin: '0 3px' }}>--</span>

            <span style={{ color: '#3b4043' }}>
              {selectedTopic?.description ||
                selectedTopic?.title ||
                selectedTopic?.slug}
            </span>
          </div>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default GoogleNormalSearchResult;
