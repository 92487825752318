import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateAccountLanguagesRequest } from '../../api-schemas/account';

const updateAccountLanguagesRequest = (data: UpdateAccountLanguagesRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Account/Language', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountLanguages(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountLanguages,
    isPending: isUpdatingAccountLanguages,
    isSuccess: isSuccessUpdatingAccountLanguages,
  } = useMutation({
    mutationFn: updateAccountLanguagesRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', accountId],
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });
    },
  });

  return {
    updateAccountLanguages,
    isUpdatingAccountLanguages,
    isSuccessUpdatingAccountLanguages,
  };
}
