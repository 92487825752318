import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ISO6391 from 'iso-639-1';
import { DocumentContext } from '../../../../../contexts/document';
import { getQueries } from '../../../../../helpers/route';
import { useHistory, useParams } from 'react-router-dom';
import { Radio, Tooltip } from 'antd';
import { TopicContext } from '../../../../../contexts/topic';

const useStyles: any = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    margin: '10px',
    marginLeft: 0,
    marginRight: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const TranslationLanguages = ({ isLanguageEqual }) => {
  const [languages, setLanguages] = useState<any[]>([]);

  const {
    selectedDoc,
    selectedLanguage,
    setSelectedLanguage,
    selectedVersion,
  } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const history = useHistory();
  const {
    accountId,
    documentId,
    topicId,
  }: { accountId: string; documentId: string; topicId: string } = useParams();

  const classes = useStyles();

  useEffect(() => {
    if (selectedDoc && selectedTopic && selectedTopic.languages) {
      if (selectedTopic.languageCode) {
        const shouldBeTranslatedToLanguages = selectedDoc.languages
          .filter(
            (language) =>
              selectedTopic.languages.indexOf(language.languageCode) < 0
          )
          .map((language) => language.languageCode);
        setLanguages(shouldBeTranslatedToLanguages);
      }
    }
  }, [selectedDoc, selectedTopic]);

  const handleGoToTopicInAnotherLanguage = (languageCode: string) => {
    const selectedLanguage = { code: languageCode, id: languageCode };

    setSelectedLanguage(selectedLanguage);

    const queries = getQueries(selectedVersion, selectedLanguage);
    history.push(
      `/document/${accountId}/${documentId}/${topicId}/content${queries}`
    );
  };

  return (
    <Fragment>
      {!isLanguageEqual && selectedLanguage
        ? null
        : languages
            .slice(0, languages.length < 5 ? languages.length : 4)
            .map((language, index) => (
              <Tooltip
                key={index}
                title={`Translate to ${ISO6391.getName(language)}`}
                placement="bottom"
                color="rgba(97, 97, 97, 0.9)"
                overlayStyle={{ fontSize: 11, fontWeight: 'bold' }}
              >
                <Radio.Button
                  className={classes.chip}
                  style={{
                    borderRadius: '0',
                    cursor: 'pointer',
                    padding: '0 3px',
                    fontSize: 12,
                    width: 32,
                    height: 32,
                  }}
                  onClick={() => handleGoToTopicInAnotherLanguage(language)}
                >
                  {language}
                </Radio.Button>
              </Tooltip>
            ))}
    </Fragment>
  );
};

export default TranslationLanguages;
