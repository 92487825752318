import React, { useEffect } from 'react';
import ManageDocumentAccesses from '../../document/accesses/manage-accesses';
import AccountAccessesSettings from '../../account/accesses';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useGetAccountTokens from '../../../api-hooks/account/useGetAccountTokens';

const ManageTokens = ({ accesses, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { accountTokens, isErrorLoadingAccountTokens } = useGetAccountTokens(
    props.accountId || props.selectedDoc.account.id,
    props.isViewerAccess
  );

  useEffect(() => {
    if (isErrorLoadingAccountTokens)
      enqueueSnackbar(t('error.get-tokens'), {
        variant: 'error',
      });
  }, [isErrorLoadingAccountTokens]);

  if (props.entityType === 'Document') {
    return (
      <ManageDocumentAccesses
        accesses={accesses}
        tokens={accountTokens}
        {...props}
        type="TOKEN"
      />
    );
  } else {
    return (
      <AccountAccessesSettings
        accesses={accesses}
        tokens={accountTokens}
        {...props}
        type="TOKEN"
      />
    );
  }
};

export default ManageTokens;
