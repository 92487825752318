import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DocumentContext } from '../../../../../../contexts/document';

const styles: any = () => ({});

function DocumentVersionSelector(props) {
  const { versions } = useContext(DocumentContext);

  const handleDocumentVersionChanged = (args) => {
    const newValue = args.target.value;
    documentVersionChanged(newValue);
  };

  // #endregion

  const documentVersionChanged = (newValue) => {
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  // #region render methods

  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="documentVersionId"
        style={{ position: 'relative', transform: 'none' }}
      >
        Variant:{' '}
      </InputLabel>
      <Select
        value={props.selectedVersionId}
        fullWidth
        onChange={handleDocumentVersionChanged}
        inputProps={{
          name: 'id',
          id: 'id',
        }}
      >
        {versions.map((version) => (
          <MenuItem key={version.id} value={version.id}>
            {version.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // #endregion
}

export default withStyles(styles)(DocumentVersionSelector);
