import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import {
  CircularProgress,
  ListItem,
  ListItemSecondaryAction,
  Button,
  ListItemText,
  Tooltip,
  IconButton,
  Divider,
  List,
  Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar, useSnackbar } from 'notistack';
import config from 'react-global-configuration';
import { Edit } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { Col } from 'antd';

import Plan from './plan';
import PaymentMethods from './payment-methods';
import Invoices from './invoices';
import { AccountContext } from '../../../contexts/account';
import TextInput from '../../inputs/text-input';
import PricingTable from './pricing-table';
import useGetPlans from '../../../api-hooks/plan/useGetPlans';
import useGetCurrentPlan from '../../../api-hooks/plan/useGetCurrentPlan';
import useGetAccountPaymentMethods from '../../../api-hooks/account/useGetAccountPaymentMethods';
import useUpdateAccountPaymentMethod from '../../../api-hooks/account/useUpdateAccountPaymentMethod';
import useUpdateAccountBillingSettings from '../../../api-hooks/account/useUpdateAccountBillingSettings';
import useDeleteAccountPaymentMethod from '../../../api-hooks/account/useDeleteAccountPaymentMethod';
import useUpdateAccountPlan from '../../../api-hooks/account/useUpdateAccountPlan';

const styles: any = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '215px',
  },
  emailBox: {
    paddingTop: 0,
    '& .MuiListItem-root': {
      paddingLeft: 0,
    },
  },
  errorBox: {
    color: 'red',
  },
  emailInput: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
    },
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
  editLoading: {
    width: '20px!important',
    height: '20px!important',
    color: 'white',
  },
});

function AccountBillingSettings(props: any) {
  const billingSettingsItem = props.match.params['settingsAction'] || 'plan';

  const [paymentMethods, setPaymentMethods] = useState<any>(null);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] =
    React.useState<string>('');
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [disableSaveEdit, setDisableSaveEdit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  const { accountsData } = useContext(AccountContext);

  const { plans, isErrorLoadingPlans } = useGetPlans();
  const { currentPlan, isLoadingCurrentPlan, isErrorLoadingCurrentPlan } =
    useGetCurrentPlan(props.accountConfiguration.id);
  const {
    accountPaymentMethods,
    isErrorLoadingAccountPaymentMethods,
    isSuccessLoadingAccountPaymentMethods,
    getAccountPaymentMethods,
  } = useGetAccountPaymentMethods(props.accountConfiguration.id);
  const { updateAccountPaymentMethod } = useUpdateAccountPaymentMethod(
    props.accountConfiguration.id
  );
  const { updateAccountBillingSettings } = useUpdateAccountBillingSettings();
  const { deleteAccountPaymentMethod } = useDeleteAccountPaymentMethod(
    props.accountConfiguration.id
  );
  const { updateAccountPlan, isUpdatingAccountPlan } = useUpdateAccountPlan(
    props.accountConfiguration.id
  );

  const { t } = useTranslation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isErrorLoadingPlans) {
      const key = props.enqueueSnackbar(t(`error.getPlans`), {
        variant: 'error',
        persist: true,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  }, [isErrorLoadingPlans]);

  useEffect(() => {
    if (isErrorLoadingCurrentPlan) {
      props.enqueueSnackbar(t(`error.getCurrentPlan`), {
        variant: 'error',
        persist: true,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
    }
  }, [isErrorLoadingCurrentPlan]);

  useEffect(() => {
    setEmail(
      props.accountConfiguration.email ? props.accountConfiguration.email : ''
    );
  }, [props.accountConfiguration.email]);

  useEffect(() => {
    if (accountsData) {
      const account = accountsData.find(
        (account: any) => account.id === props.accountConfiguration.id
      );
      if (
        config
          .get('errorSubscriptionStatuses')
          .indexOf(account.stripeSubscriptionStatus) >= 0
      ) {
        const key = props.enqueueSnackbar(t(`error.subscription`), {
          variant: 'error',
          persist: true,
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccessLoadingAccountPaymentMethods && accountPaymentMethods) {
      updatePaymentMethods();
    }
    // eslint-disable-next-line
  }, [accountPaymentMethods, isSuccessLoadingAccountPaymentMethods]);

  useEffect(() => {
    if (isErrorLoadingAccountPaymentMethods) {
      props.enqueueSnackbar(t(`error.getPaymentMethods`), {
        variant: 'error',
        persist: true,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });

      setPaymentMethods({ data: [] });
    }
  }, [isErrorLoadingAccountPaymentMethods]);

  const changeDefaultPaymentMethod = async (paymentMethodId: string) => {
    if (paymentMethodId === defaultPaymentMethodId) return false;

    const body = {
      accountId: props.accountConfiguration.id,
      paymentMethodId: paymentMethodId,
    };

    const result = await updateAccountPaymentMethod(body);

    if (result.id) {
      setDefaultPaymentMethodId(result.id);
    } else {
      props.enqueueSnackbar(t(`error.changeDefaultPaymentMethod`), {
        variant: 'error',
        persist: true,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
    }
  };

  const updatePaymentMethods = async () => {
    if (accountPaymentMethods) {
      setPaymentMethods(accountPaymentMethods.paymentMethods);
      setDefaultPaymentMethodId(accountPaymentMethods.defaultPaymentMethodId);
    }
  };

  const changePlan = async (planId: string) => {
    const body = {
      accountId: props.accountConfiguration.id,
      planId: planId,
    };

    updateAccountPlan(body)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['plan', props.accountConfiguration.id],
        });
      })
      .catch(async (error) => {
        let errorMessage = t('error.changePlan');

        try {
          const errorJson = await error.json();
          if (
            !!errorJson?.errors &&
            errorJson?.errors[0].field === 'AccountHasNoDefaultPaymentMethod'
          ) {
            errorMessage = t('error.AccountHasNoDefaultPaymentMethod');
          }
        } finally {
          enqueueSnackbar(errorMessage, {
            variant: 'error',
            persist: true,
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        }
      });
  };

  const detachPaymentMethod = async (paymentMethodId: string) => {
    deleteAccountPaymentMethod({
      accountId: props.accountConfiguration.id,
      paymentMethodId,
    })
      .then(() => {
        getAccountPaymentMethods();
      })
      .catch(async (error) => {
        let errorMessage;

        try {
          const resp = await error.json();

          if (resp.errors || resp.error) {
            errorMessage = t('error.detachPaymentMethod');
          }
        } finally {
          if (errorMessage)
            props.enqueueSnackbar(errorMessage, {
              variant: 'error',
              persist: true,
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        }
      });
  };

  const handleEditEmailChanged = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (value === props.accountConfiguration.email) {
      setDisableSaveEdit(true);
    } else {
      setDisableSaveEdit(false);
    }
    setEmail(value);
  };

  const handleClickEditEmail = () => {
    setEditEmail(true);
    setDisableSaveEdit(true);
  };

  const emailValidation = (email: string) => {
    // eslint-disable-next-line
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (pattern.test(email)) {
      setEmailError(undefined);
      return true;
    }
    setEmailError(t('error.email'));
    return false;
  };

  const handleCancelEdit = () => {
    setEmail(props.accountConfiguration.email);
    setEditEmail(false);
  };

  const handleSaveAccount = async () => {
    const isValidate = emailValidation(email);
    if (isValidate) {
      setEditLoading(true);
      setEmailError(undefined);
      const body = {
        id: props.accountConfiguration.id,
        email: email,
      };

      updateAccountBillingSettings(body)
        .then(() => {
          const updatedAccount = Object.assign({}, props.accountConfiguration);
          updatedAccount.email = email;
          props.onAccountConfigurationChanged(updatedAccount);
          setEditLoading(false);
          setEditEmail(false);
        })
        .catch((error) => {
          setEditLoading(false);
          props.enqueueSnackbar(
            error.error ? error.error : t('error.unknown'),
            {
              variant: 'error',
            }
          );
        });
    } else {
      setEmailError(t('error.email'));
    }
  };

  const classes = props.classes;

  const getErrorMessage = () => emailError;

  const emailSection = () => {
    return (
      <List className={classes.emailBox}>
        {editEmail ? (
          <ListItem>
            <TextInput
              style={{ width: 250 }}
              field="billing-email"
              label={t('billing-email')}
              onChange={handleEditEmailChanged}
              value={email}
              required={true}
              showError={true}
              getErrorMessage={getErrorMessage}
            />
            <ListItemSecondaryAction>
              <Button className={classes.button} onClick={handleCancelEdit}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                disabled={disableSaveEdit}
                onClick={() => handleSaveAccount()}
              >
                {editLoading ? (
                  <CircularProgress className={classes.editLoading} />
                ) : (
                  t('save')
                )}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText primary={`${t('billing-email-text')} ${email}`} />
            <ListItemSecondaryAction>
              <Tooltip title={`${t('edit')}`}>
                <IconButton
                  aria-label={`Edit`}
                  onClick={() => handleClickEditEmail()}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    );
  };

  if (!isLoadingCurrentPlan) {
    switch (billingSettingsItem) {
      case 'plan':
        return !isUpdatingAccountPlan ? (
          <div>
            {emailSection()}
            <Divider />
            <div className={classes.root}>
              <Plan
                accountConfiguration={props.accountConfiguration}
                subscriptionError={props.subscriptionError}
                paymentMethods={paymentMethods}
                defaultPaymentMethodId={defaultPaymentMethodId}
                plans={plans}
                changePlan={changePlan}
                currentPlanId={currentPlan?.id}
                currentPlanName={currentPlan?.name}
                updatePaymentMethods={updatePaymentMethods}
              />
            </div>
          </div>
        ) : (
          <Col xs={24} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </Col>
        );
      case 'payment-method':
        return (
          <div>
            {emailSection()}
            <Divider />
            <div className={classes.root}>
              <PaymentMethods
                accountConfiguration={props.accountConfiguration}
                updatePaymentMethods={updatePaymentMethods}
                changeDefaultPaymentMethod={changeDefaultPaymentMethod}
                paymentMethods={paymentMethods}
                defaultPaymentMethodId={defaultPaymentMethodId}
                detachPaymentMethod={detachPaymentMethod}
              />
            </div>
          </div>
        );
      case 'invoices':
        return (
          <div>
            {emailSection()}
            <Divider />
            <div className={classes.root}>
              <Invoices accountConfiguration={props.accountConfiguration} />
            </div>
          </div>
        );
      case 'pricing-table':
        return (
          <div>
            {emailSection()}
            <Divider />
            <div className={classes.root}>
              <PricingTable />
            </div>
          </div>
        );
      default:
        return (
          <div>
            {emailSection()}
            <Divider />
            <div className={classes.root}>
              <Plan
                accountConfiguration={props.accountConfiguration}
                subscriptionError={props.subscriptionError}
                paymentMethods={paymentMethods}
                defaultPaymentMethodId={defaultPaymentMethodId}
                plans={plans}
                changePlan={changePlan}
                currentPlanId={currentPlan?.id}
                currentPlanName={currentPlan?.name}
                updatePaymentMethods={updatePaymentMethods}
              />
            </div>
          </div>
        );
    }
  } else {
    return (
      <Col xs={24} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </Col>
    );
  }
}
export default withStyles(styles)(withSnackbar(AccountBillingSettings));
