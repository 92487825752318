import React from 'react';

type TooltipItem = {
  value: string | number;
  color: string;
}

type TooltipProps = {
  title: string;
  items: TooltipItem[];
  label: string;
}

const CustomTooltip: React.FC<TooltipProps> = ({ title, items, label }) => {
  return (
    <div key={title}>
      <h6>{title}</h6>
      {items.map((item) => {
        const { value, color } = item;
        return (
          <div key={value.toString()}>
            <div
              style={{
                margin: 0,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ marginRight: 6 }}>
                <span
                  style={{
                    display: 'inline-block',
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: color,
                    marginRight: 6,
                  }}
                ></span>
                <span>{label}</span>
              </div>
              <h6>{value}</h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomTooltip;
