import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../account';
import { DocumentContext } from '../document';
import useGetAccesses from '../../api-hooks/access/useGetAccesses';
import { Accesses } from '../../api-schemas/access';
import { sleep } from '../../helpers/common';

const initialState: AccessContextType = {
  accountAccesses: [],
  setAccountAccesses: () => [],
  documentAccesses: [],
  setDocumentAccesses: () => [],
};

type AccessContextType = {
  accountAccesses: any;
  setAccountAccesses: any;
  documentAccesses: any;
  setDocumentAccesses: any;
};

export const AccessContext =
  React.createContext<AccessContextType>(initialState);

export function AccessProvider({ children }) {
  const [accountAccesses, setAccountAccesses] = useState<Accesses>([]);
  const [documentAccesses, setDocumentAccesses] = useState<Accesses>([]);
  const [previousSelectedAccount, setPreviousSelectedAccount] = useState<any>(
    {}
  );
  const [previousSelectedDocument, setPreviousSelectedDocument] = useState<any>(
    {}
  );
  const [entityId, setEntityId] = useState<string>('');

  const { selectedAccount } = useContext(AccountContext);
  const { selectedDoc } = useContext(DocumentContext);

  const { getAccesses } = useGetAccesses(entityId);

  const value = {
    accountAccesses,
    setAccountAccesses,
    documentAccesses,
    setDocumentAccesses,
  };

  const handleAccountChanged = async () => {
    setPreviousSelectedAccount(selectedAccount);
    setEntityId(selectedAccount.id);

    await sleep();

    getAccesses().then((response) => {
      if (response.data) {
        setAccountAccesses(response.data);
      }
    });
  };

  useEffect(() => {
    if (
      selectedAccount &&
      selectedAccount.id &&
      selectedAccount.id !== previousSelectedAccount.id
    ) {
      handleAccountChanged();
    }
    // eslint-disable-next-line
  }, [selectedAccount]);

  useEffect(() => {
    if (
      selectedDoc &&
      selectedDoc.id &&
      selectedDoc.id !== previousSelectedDocument.id
    ) {
      setPreviousSelectedDocument(selectedDoc);
      setEntityId(selectedDoc.id);

      getAccesses().then((response) => {
        if (response.data) {
          setDocumentAccesses(response.data);
        }
      });
    } else if (!selectedDoc) {
      setEntityId('');
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  );
}
