import React, { Fragment } from 'react';
import { Card, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import PreviewSection from '../preview-section';

const ComponentBox = ({
  data,
  componentData,
  componentVersion,
  setDeleteItemIndex,
  setEditItem,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Col span={24} style={{ height: '100%' }}>
        <Card
          className="component-card component-container"
          style={{ height: '100%' }}
          extra={
            <Fragment>
              <Tooltip title={t('rm')}>
                <DeleteIcon
                  onClick={() => setDeleteItemIndex(data.order)}
                  style={{ cursor: 'pointer', fontSize: 16, marginRight: 5 }}
                />
              </Tooltip>
              <Tooltip title={t('settings')}>
                <SettingsIcon
                  onClick={() =>
                    setEditItem({
                      data: JSON.parse(JSON.stringify(data)),
                      componentVersion: JSON.parse(
                        JSON.stringify(componentVersion)
                      ),
                    })
                  }
                  style={{ cursor: 'pointer', fontSize: 16, marginRight: 5 }}
                />
              </Tooltip>
            </Fragment>
          }
        >
          <PreviewSection
            selectedComponentVersion={{
              ...componentVersion,
              data: {
                ...componentVersion.data,
                content: componentData.content,
              },
            }}
            order={data.order}
            component={componentData}
          />
        </Card>
      </Col>
    </Fragment>
  );
};

export default ComponentBox;
