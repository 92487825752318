import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { PublishRequests } from '../../api-schemas/publish-request';

export type GetPublishRequestsParams = {
  documentId?: string | null;
  status: string;
  pageIndex?: number;
  pageSize?: number;
  languageCode?: string | null;
  enabled?: boolean;
};

const getPublishRequestsRequest = (params: GetPublishRequestsParams) => {
  return axios
    .get<PublishRequests>(`/api/PublishRequest`, {
      params,
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetPublishRequests({
  documentId,
  status,
  pageIndex = 0,
  pageSize = 20,
  languageCode = null,
  enabled = true,
}: GetPublishRequestsParams) {
  const {
    data,
    refetch: getPublishRequests,
    isFetching: isLoadingPublishRequests,
    isSuccess: isSuccessLoadingPublishRequests,
  } = useQuery<PublishRequests>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['publishRequests', documentId, languageCode],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(enabled && documentId),
    queryFn: () =>
      getPublishRequestsRequest({
        documentId,
        status,
        pageSize,
        pageIndex,
        languageCode,
      }),
  });

  const publishRequests = useMemo(() => data || [], [data]);

  return {
    publishRequests,
    isLoadingPublishRequests,
    isSuccessLoadingPublishRequests,

    getPublishRequests,
  };
}
