import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteAccountPaymentMethodRequest = ({
  accountId,
  paymentMethodId,
}: {
  accountId: string;
  paymentMethodId: string;
}) => {
  return axios
    .delete(`/api/Account/${accountId}/PaymentMethod/${paymentMethodId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteAccountPaymentMethod(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteAccountPaymentMethod,
    isPending: isDeletingAccountPaymentMethod,
    isSuccess: isSuccessDeletingAccountPaymentMethod,
  } = useMutation({
    mutationFn: deleteAccountPaymentMethodRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'paymentMethod', accountId],
      });
    },
  });

  return {
    deleteAccountPaymentMethod,
    isDeletingAccountPaymentMethod,
    isSuccessDeletingAccountPaymentMethod,
  };
}
