import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Accounts } from '../../api-schemas/account';

const getAccountsRequest = () => {
  return axios
    .get<Accounts>(`/api/Account`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccounts() {
  const {
    data,
    refetch: getAccounts,
    isFetching: isLoadingAccounts,
    isSuccess: isSuccessLoadingAccounts,
  } = useQuery<Accounts>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['accounts'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: getAccountsRequest,
  });

  const accounts = useMemo(() => data || [], [data]);

  return {
    accounts,
    isLoadingAccounts,
    isSuccessLoadingAccounts,

    getAccounts,
  };
}
