import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleOutlined } from '@ant-design/icons';
import { Typography as AntTypography } from 'antd';
import { Tooltip } from 'antd';
import { Button as ButtonMaterial, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { showSuccessMessage } from '../../../../helpers/common';
import { AccountContext } from '../../../../contexts/account';
import { buildAuthUrl } from '../../../../helpers/google-drive-auth';
import useDeleteGoogleDriveAccountToken from '../../../../api-hooks/account/useDeleteGoogleDriveAccountToken';
import { AccountRoles } from '../../../../helpers/roles';
import { ConfirmationDialog } from '../../../confirmation-dialog';

import '../styles.css';

const { Text, Title } = AntTypography;

function GoogleDriveIntegrationSettings() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDisconnectDialog, setOpenDisconnectDialog] =
    useState<boolean>(false);

  const { selectedAccount, userRole } = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();

  const { deleteGoogleDriveAccountToken } = useDeleteGoogleDriveAccountToken(
    selectedAccount.id
  );

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleGoogleDriveSignIn = () => {
    window.location.href = buildAuthUrl();
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDisconnectDialog = () => {
    setOpenDisconnectDialog(true);
  };

  const handleCloseDisconnectDialog = () => {
    setOpenDisconnectDialog(false);
  };

  const handleConnectToGoogleDrive = () => {
    if (userRole === AccountRoles.ADMIN || userRole === AccountRoles.OWNER) {
      setLoading(true);

      handleGoogleDriveSignIn();
    } else setOpenDialog(true);
  };

  const handleDisconnectToGoogleDrive = () => {
    handleCloseDisconnectDialog();

    if (userRole === AccountRoles.ADMIN || userRole === AccountRoles.OWNER) {
      setLoading(true);

      deleteGoogleDriveAccountToken(selectedAccount.id)
        .then(() => {
          setLoading(false);

          queryClient.invalidateQueries({
            queryKey: ['googleDriveAccountToken'],
          });

          showSuccessMessage(enqueueSnackbar, t);
        })
        .catch(() =>
          enqueueSnackbar(t('error-failed-DeleteGoogleDrive'), {
            variant: 'error',
          })
        );
    } else setOpenDialog(true);
  };

  return (
    <div>
      <div style={{ borderRadius: '5px', padding: '10px' }}>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div
            className={
              window.innerWidth > 675
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <GoogleOutlined
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div
            className={
              window.innerWidth > 675
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('googleDrive-title-setting')}
            </Title>
            <Text>
              {selectedAccount.hasValidGoogleDriveToken
                ? t('disconnect-googleDrive-description-setting')
                : t('request-googleDrive-description-setting')}
            </Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: '30px',
              }}
            >
              {selectedAccount.hasValidGoogleDriveToken ? (
                <Tooltip
                  title={t<string>('disconnect-googleDrive-help')}
                  style={{ margin: '5px 20px 0 0' }}
                >
                  <ButtonMaterial
                    variant="contained"
                    style={{ height: '35px', marginRight: '10px' }}
                    color="error"
                    onClick={handleOpenDisconnectDialog}
                    aria-label="Delete current document"
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: 25, height: 25, color: '#fff' }}
                      />
                    ) : (
                      t('disconnect-googleDrive')
                    )}
                  </ButtonMaterial>
                </Tooltip>
              ) : (
                <Tooltip
                  title={t<string>('connect-googleDrive-help')}
                  style={{ margin: '5px 20px 0 0' }}
                >
                  <ButtonMaterial
                    variant="contained"
                    style={{ height: '35px' }}
                    color="primary"
                    onClick={handleConnectToGoogleDrive}
                    aria-label="Create new document"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: 25, height: 25, color: '#fff' }}
                      />
                    ) : (
                      t('connect-googleDrive')
                    )}
                  </ButtonMaterial>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        title={t<string>('google-drive-access-reject-title')}
        message={t<string>('google-drive-access-reject-desc')}
        isOpen={openDialog}
        onDialogConfirmed={handleCloseDeleteDialog}
      />

      <ConfirmationDialog
        title={t<string>('google-drive-disconnect-wanrning-title')}
        message={t<string>('google-drive-disconnect-wanrning-desc')}
        isOpen={openDisconnectDialog}
        onDialogClosed={handleCloseDisconnectDialog}
        onDialogConfirmed={handleDisconnectToGoogleDrive}
      />
    </div>
  );
}

export default GoogleDriveIntegrationSettings;
