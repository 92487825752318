import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  getAuth,
  User,
  GoogleAuthProvider,
  GithubAuthProvider,
  linkWithPopup,
  unlink,
} from 'firebase/auth';
import { withSnackbar } from 'notistack';
import firebaseApp from '../../../../helpers/firebase';
import { showSuccessMessage } from '../../../../helpers/common';
import { Col } from 'antd';

const useStyles: any = makeStyles((theme) => ({
  box: {
    display: 'flex!important',
    alignItems: 'center!important',
    [theme.breakpoints.down('sm')]: {
      display: 'block!important',
    },
    marginBottom: '10px!important',
    marginTop: '10px!important',
  },
  button: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center!important',
      marginTop: '10px!important',
    },
    '& > button': {
      textTransform: 'none!important',
    },
    '& > button > span': {
      fontSize: '12px!important',
    },
  },
  title: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
}));

const auth = getAuth(firebaseApp);

const LinkAndUnlinkConfiguration = (props) => {
  const [user, setUser] = useState<User | null>(null);
  const [loadingByProviderName, setLoadingByProviderName] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.user) {
      setLoading(false);
      setUser(props.user);
    } else {
      setLoading(true);
    }
  }, [props.user]);

  const { t } = useTranslation();

  const classes = useStyles();

  const linkToAccount = (provider: string) => {
    setLoadingByProviderName(provider);
    switch (provider) {
      case 'google':
        // eslint-disable-next-line no-case-declarations
        const googleProvider = new GoogleAuthProvider();
        linkWithPopup(user as User, googleProvider)
          .then(function (result) {
            setLoadingByProviderName(null);
            setUser(result.user);
            showSuccessMessage(props.enqueueSnackbar, t);
          })
          .catch(function (error) {
            switch (error.message) {
              case 'Firebase: Error (auth/email-already-in-use).':
                props.enqueueSnackbar(t(`error.already-associated`), {
                  variant: 'error',
                  persist: false,
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
                break;
              case 'Firebase: Error (auth/credential-already-in-use).':
                props.enqueueSnackbar(t(`error.already-associated`), {
                  variant: 'error',
                  persist: false,
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
                break;
              case 'This credential is already associated with a different user account.':
                props.enqueueSnackbar(t(`error.already-associated`), {
                  variant: 'error',
                  persist: false,
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
                break;
              default:
                props.enqueueSnackbar(t(`error.unknown`), {
                  variant: 'error',
                  persist: false,
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
                break;
            }
            setLoadingByProviderName(null);
          });
        break;
      case 'github':
        // eslint-disable-next-line no-case-declarations
        const githubProvider = new GithubAuthProvider();
        linkWithPopup(user as User, githubProvider)
          .then(function (result) {
            setLoadingByProviderName(null);
            setUser(result.user);
            showSuccessMessage(props.enqueueSnackbar, t);
          })
          .catch(function (error) {
            if (
              error.message ===
              'This credential is already associated with a different user account.'
            ) {
              props.enqueueSnackbar(t(`error.already-associated`), {
                variant: 'error',
                persist: false,
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
            } else {
              props.enqueueSnackbar(t(`error.unknown`), {
                variant: 'error',
                persist: false,
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
            }
            setLoadingByProviderName(null);
          });
        break;
      default:
        setLoadingByProviderName(null);
        break;
    }
  };

  const unlinkToAccount = (provider: string) => {
    if (props.user.providerData.length === 1) {
      props.enqueueSnackbar(t(`error.unlink-account`), {
        variant: 'error',
        persist: false,
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
      return;
    }
    setLoadingByProviderName(provider);
    switch (provider) {
      case 'google':
        // eslint-disable-next-line no-case-declarations
        const googleProviderId = GoogleAuthProvider.PROVIDER_ID;
        unlink(user as User, googleProviderId)
          .then(function () {
            setLoadingByProviderName(null);
            setUser(auth.currentUser);
            showSuccessMessage(props.enqueueSnackbar, t);
          })
          .catch(function () {
            setLoadingByProviderName(null);
            props.enqueueSnackbar(t(`error.unknown`), {
              variant: 'error',
              persist: false,
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
        break;
      case 'github':
        // eslint-disable-next-line no-case-declarations
        const githubProviderId = GithubAuthProvider.PROVIDER_ID;
        unlink(user as User, githubProviderId)
          .then(function () {
            setLoadingByProviderName(null);
            setUser(auth.currentUser);
            showSuccessMessage(props.enqueueSnackbar, t);
          })
          .catch(function () {
            setLoadingByProviderName(null);
            props.enqueueSnackbar(t(`error.unknown`), {
              variant: 'error',
              persist: false,
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
        break;
      default:
        setLoadingByProviderName(null);
        break;
    }
  };

  return (
    <React.Fragment>
      <Col xs={24} className={classes.box}>
        <Col xs={24} lg={16}>
          <Col xs={24} className={classes.title}>
            {t('google')}
          </Col>
          <Col xs={24} className={classes.text}>
            {t('link-unlink-google-text')}
          </Col>
        </Col>
        <Col xs={24} lg={8} className={classes.button}>
          {props.user &&
          props.user.providerData.filter(
            (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
          ).length ? (
            <Button
              variant="contained"
              onClick={() => unlinkToAccount('google')}
              style={{ width: 200, backgroundColor: 'red', color: '#fff' }}
            >
              {t('unlink-account')}
            </Button>
          ) : loading || loadingByProviderName === 'google' ? (
            <Button variant="contained" color="grey" style={{ width: 200 }}>
              <CircularProgress style={{ width: 25, height: 25 }} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ width: 200 }}
              onClick={() => linkToAccount('google')}
            >
              {t('link-account')}
            </Button>
          )}
        </Col>
      </Col>
      <Divider />
      <Col xs={24} className={classes.box}>
        <Col xs={24} lg={16}>
          <Col xs={24} className={classes.title}>
            {t('github')}
          </Col>
          <Col xs={24} className={classes.text}>
            {t('link-unlink-github-text')}
          </Col>
        </Col>
        <Col xs={24} lg={8} className={classes.button}>
          {props.user &&
          props.user.providerData.filter(
            (provider) => provider.providerId === GithubAuthProvider.PROVIDER_ID
          ).length ? (
            <Button
              variant="contained"
              style={{ width: 200, backgroundColor: 'red', color: '#fff' }}
              onClick={() => unlinkToAccount('github')}
            >
              {t('unlink-account')}
            </Button>
          ) : loading || loadingByProviderName === 'github' ? (
            <Button variant="contained" color="grey" style={{ width: 200 }}>
              <CircularProgress style={{ width: 25, height: 25 }} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ width: 200 }}
              onClick={() => linkToAccount('github')}
            >
              {t('link-account')}
            </Button>
          )}
        </Col>
      </Col>
    </React.Fragment>
  );
};

export default withSnackbar(LinkAndUnlinkConfiguration);
