import { Avatar } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';

import { makeLightColor } from '../../../helpers/common';

const DocumentLogo = (props) => {
  const [hasLogo, setHasLogo] = useState<boolean>(true);

  const { t } = useTranslation();

  return hasLogo ? (
    <LazyLoadImage
      alt={t('logo')}
      effect="blur"
      style={{ width: 40 }}
      onError={() => {
        setHasLogo(false);
      }}
      delayTime={50}
      threshold={1000}
      //   placeholderSrc={process.env.PUBLIC_URL + "/logo.svg"}
      placeholder={<Skeleton width={40} height={40} />}
      src={`${localStorage.webApiAddress}document/${props.document.id}/logo?updateDateTime=${props.document.updateDateTime}`}
    />
  ) : props.document.iconName ? (
    <span
      className={`active mdi ${props.document.iconName}`}
      style={{ fontSize: '40px' }}
    ></span>
  ) : (
    <Avatar
      variant="rounded"
      style={{
        color: 'inherit',
        backgroundColor: makeLightColor(props.document.id),
      }}
    >
      {props.document.title[0]}
    </Avatar>
  );
};

export default DocumentLogo;
