import React, { FormEvent, useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, CircularProgress, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import useCreateAccountClientSecret from '../../../../../../api-hooks/account/useCreateAccountClientSecret';
import useAddAccountPaymentMethod from '../../../../../../api-hooks/account/useAddAccountPaymentMethod';

import './styles.css';

const styles: any = () => ({
  button: {
    marginTop: '10px!important',
    width: '100%',
  },
  loading: {
    width: '20px!important',
    height: '20px!important',
    '& > div': {
      color: 'white',
    },
  },
});

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#1a73e8',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#eb1c26',
      iconColor: '#eb1c26',
    },
  },
};

const CardSetupForm = (props: any) => {
  const [client_secret, setClientSecret] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const { createAccountClientSecret } = useCreateAccountClientSecret();
  const { addAccountPaymentMethod } = useAddAccountPaymentMethod(
    props.accountConfiguration.id
  );

  useEffect(() => {
    createAccountClientSecret({
      accountId: props.accountConfiguration.id,
    }).then((result) => {
      setClientSecret(result.client_secret);
    });
    // eslint-disable-next-line
  }, [props.accountConfiguration.id]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const result = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement) || { token: '' },
        billing_details: {
          name: (document.getElementById('customerName') as HTMLInputElement)
            .value,
        },
      },
    });

    if (result.error) {
      setError(result.error.message || '');
      setLoading(false);
    } else if (result.setupIntent) {
      setError(null);

      const body = {
        accountId: props.accountConfiguration.id,
        paymentMethodId: result.setupIntent.payment_method as string,
      };

      addAccountPaymentMethod(body)
        .then(async () => {
          await props.updatePaymentMethods();

          if (props.changePlan !== undefined) {
            await props.changePlan(props.planId);

            if (props.setOpenPaymentDialog) {
              props.setOpenPaymentDialog(false);
            }
          }

          setLoading(false);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.addPaymentMethod`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });

          setLoading(false);

          if (props.setOpenPaymentDialog) {
            props.setOpenPaymentDialog(false);
          }
        });
    }
  };

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const classes = props.classes;

  return (
    <form onSubmit={handleSubmit}>
      <div className="title">
        <span>{t('card-detail')}</span>
      </div>
      <TextField
        id="customerName"
        label="Name"
        placeholder="Name"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
      <div className="card-errors" role="alert">
        {error && <span>{error}</span>}
      </div>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={(event) => handleSubmit(event)}
        disabled={!(stripe && !loading)}
      >
        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <span>{t('save-card')}</span>
        )}
      </Button>
    </form>
  );
};

export default withStyles(styles)(withSnackbar(CardSetupForm));
