import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useQueryClient } from '@tanstack/react-query';
import ISO6391 from 'iso-639-1';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { DeleteRounded } from '@mui/icons-material';
import { DocumentService } from '../../../../services/document';
import { withSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { localizeKey } from '../../../../helpers/localize-key';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { DocumentContext } from '../../../../contexts/document';
import { getQueries } from '../../../../helpers/route';
import SelectInput from '../../../inputs/select-input';
import { Col, Select } from 'antd';
import { showSuccessMessage } from '../../../../helpers/common';
import { useParams } from 'react-router-dom';
import { FolderContext } from '../../../../contexts/folder';
import useGetDocuments from '../../../../api-hooks/document/useGetDocuments';

const { Option } = Select;

const styles: any = () => ({
  card: {
    margin: '20px 0 0',
    width: '600px',
  },
  cardHeader: {
    borderBottom: '1px solid silver',
  },
  cardContent: {
    padding: 0,
  },
  newLanguage: {
    margin: '0 10px',
    display: 'flex!important',
    flexDirection: 'row!important',
  },
  newLanguageSelector: {
    flex: 1,
  },
  newLanguageSaveButton: {
    marginTop: 20,
    marginBottom: -10,
  },
  actionButtons: {
    marginLeft: '5px!important',
  },
  addButton: {
    marginLeft: '10px!important',
  },
});

const DocumentLanguageManagement = (props) => {
  const documentService = new DocumentService();

  const { t, i18n } = useTranslation();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();
  const { selectedFolderId } = useContext(FolderContext);
  const { getDocuments } = useGetDocuments(accountId, selectedFolderId, false);
  const queryClient = useQueryClient();

  const { classes } = props;

  const [documentLanguages, setDocumentLanguages] = useState<any[]>([]);
  const [newLanguage, setNewLanguage] = useState<any>(null);
  const [selectedLanguageInComponent, setSelectedLanguageInComponent] =
    useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableApply, setDisableApply] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [originalDocumentLanguages, setOriginalDocumentLanguages] = useState<
    any[]
  >([]);
  const [shouldRefreshPage, setShouldRefreshPage] = useState<boolean>(false);

  const {
    selectedDoc,
    setSelectedDoc,
    documents,
    setDocuments,
    selectedVersion,
    selectedLanguage,
    selectedTopic,
  } = useContext(DocumentContext);

  /**
   * @description onload tasks
   */
  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    if (selectedDoc) {
      setLanguages(selectedDoc);
    } else {
      loadLanguages();
    }
    // eslint-disable-next-line
  }, [documentId]);

  useEffect(() => {
    if (documents.length && documentId && accountId) {
      const foundDocuments = documents.filter(
        (doc) => doc.id === documentId && doc.account.id === accountId
      );
      if (foundDocuments.length) {
        if (!selectedDoc) {
          setSelectedDoc(foundDocuments[0]);
        }
      }
    }
    // eslint-disable-next-line
  }, [documents, documentId, accountId]);

  const setLanguages = (data) => {
    const foundDocuments = documents.filter(
      (doc) => doc.id === documentId && doc.account.id === accountId
    );
    if (foundDocuments.length) {
      if (
        JSON.stringify(foundDocuments[0].languages) !==
        JSON.stringify(data.languages)
      ) {
        const subtract = documents.filter((el) => !foundDocuments.includes(el));
        subtract.push(data);
        setDocuments(subtract);
      }
    } else {
      props.enqueueSnackbar(t('error.doc-not-found'), {
        variant: 'error',
      });
      props.history.push('/');
    }
    setDocumentLanguages(data.languages.map((item) => item.languageCode));
    setOriginalDocumentLanguages(
      data.languages.map((item) => item.languageCode)
    );
  };

  const loadLanguages = () => {
    if (documentId) {
      return documentService
        .getDocument(documentId)
        .then((data) => {
          setLanguages(data);
          setSelectedDoc(data);
          const newDocuments = [...documents];
          const docIndex = newDocuments.indexOf(
            newDocuments.find((doc) => doc.id === data.id)
          );
          if (docIndex > -1) {
            newDocuments.splice(docIndex, 1, data);
            setDocuments(newDocuments);
          }
          setLoading(false);
          setNewLanguage(null);
        })
        .catch((error) => {
          setLoading(false);
          props.enqueueSnackbar(
            error.statusText
              ? t(`error.${localizeKey(error.statusText)}`)
              : t('error.unknown'),
            {
              variant: 'error',
            }
          );
        });
    }
  };

  /**
   * @description Functions and events
   */
  const allLanguages = ISO6391.getAllNames()
    .map((langName) => ({
      name: langName,
      languageCode: ISO6391.getCode(langName),
    }))
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const handleClickOpenDeleteDialog = (lang) => {
    setOpenDeleteDialog(true);
    setSelectedLanguageInComponent(lang);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedLanguageInComponent(null);
  };

  const handleRemoveLanguageFromDocument = (callback) => {
    setDocumentLanguages(
      documentLanguages.filter((item) => item !== selectedLanguageInComponent)
    );
    setDisableApply(false);
    setShouldRefreshPage(true);
    callback();
  };

  const handleNewLanguageChanged = (value) => {
    setDisableSave(false);
    let newLangCode: string = '';
    const createdNewLanguage: any = newLanguage;
    allLanguages.forEach((element) => {
      if (element.name === value) {
        newLangCode = element.languageCode;
      }
    });
    createdNewLanguage.languageCode = newLangCode;
    setNewLanguage({
      languageCode: newLangCode,
      name: value,
      order: createdNewLanguage.order,
    });
  };

  const handleNewLanguageClick = () => {
    setNewLanguage({
      languageCode: '',
      order: documentLanguages.length + 1,
    });
  };

  const handleNewLanguageSaveClick = () => {
    if (newLanguage && newLanguage.languageCode) {
      const isAddedBefore = documentLanguages.filter(
        (language) => language === newLanguage.languageCode
      ).length;
      if (isAddedBefore) {
        props.enqueueSnackbar(t(`error.${localizeKey('duplicate-language')}`), {
          variant: 'error',
        });
        return;
      }
      const list = documentLanguages;
      list.push(newLanguage.languageCode);
      setDocumentLanguages(list);
      setDisableSave(true);
      setDisableApply(false);
      setNewLanguage(null);
    }
  };

  const handleEditContent = () => {
    const queries: string | null = getQueries(
      selectedVersion,
      selectedLanguage
    );
    props.history.push(
      `/document/${accountId}/${documentId}/${selectedTopic.topicId}/content${queries || ''}`
    );
  };

  const handleApplyChanges = () => {
    const newLanguageList = documentLanguages.map((item, index) => {
      return { languageCode: item, order: index + 1 };
    });
    setDisableApply(true);
    if (documentId) {
      setLoading(true);
      documentService
        .putLanguage({
          id: documentId,
          languages: newLanguageList,
        })
        .then(async (data) => {
          if (shouldRefreshPage) {
            window.location.reload();
          } else {
            await loadLanguages();
            setDocumentLanguages(
              data.languages.map((item) => item.languageCode)
            );

            getDocuments();

            queryClient.invalidateQueries({ queryKey: ['documents', true] });
            queryClient.invalidateQueries({
              queryKey: selectedFolderId
                ? ['documents', false, accountId, selectedFolderId]
                : ['documents', false, accountId],
            });

            showSuccessMessage(props.enqueueSnackbar, t);
          }
        })
        .catch(() => {
          setDocumentLanguages(originalDocumentLanguages);
          setLoading(false);
          setDisableApply(false);
          props.enqueueSnackbar(
            t(`error.${localizeKey('apply-language-changes')}`),
            {
              variant: 'error',
            }
          );
        });
    }
  };

  const handleSetDefaultLanguage = (lang) => {
    const data = {
      documentId: selectedDoc.id,
      defaultDocumentLanguageCode: lang,
    };
    setLoading(true);
    documentService
      .changeDefaultLanguageCode(data)
      .then(() => {
        const newDoc = { ...selectedDoc, ...{ defaultLanguageCode: lang } };
        setSelectedDoc(newDoc);
        const tempDocuments = documents;
        const foundDocuments = tempDocuments.filter(
          (doc) => doc.id === selectedDoc.id
        );
        if (foundDocuments.length) {
          const index = tempDocuments.indexOf(foundDocuments[0]);
          tempDocuments[index].defaultLanguageCode = lang;
          setDocuments(tempDocuments);
        }
        showSuccessMessage(props.enqueueSnackbar, t);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        props.enqueueSnackbar(t(`error.unknown`), {
          variant: 'error',
        });
      });
  };

  /**
   * @description #region render method and dependencies such as renderVersionItem
   */
  const renderLanguageItem = (lang) => {
    const languageName = ISO6391.getName(lang);
    const isNew = !selectedDoc.languages.find(
      (language) => language.languageCode === lang
    );
    const isDefaultLanguage = selectedDoc.defaultLanguageCode === lang;
    return (
      <React.Fragment key={lang}>
        <ListItem>
          <ListItemText
            primary={`${documentLanguages.indexOf(lang) + 1} - ${languageName}`}
          />
          <ListItemSecondaryAction>
            <Tooltip title={`${t('rm')} ${languageName}`}>
              <span>
                <IconButton
                  aria-label={`Remove ${languageName}`}
                  onClick={() => handleClickOpenDeleteDialog(lang)}
                  disabled={isDefaultLanguage}
                  className="delete-language-button"
                >
                  <DeleteRounded />
                </IconButton>
              </span>
            </Tooltip>
            {isDefaultLanguage ? (
              <Tooltip
                title={
                  <Trans i18nKey="language.default">
                    {' '}
                    {{ languageName }} is your default language{' '}
                  </Trans>
                }
              >
                <IconButton
                  aria-label={`${languageName} is your default language`}
                >
                  <DoneOutlineIcon style={{ color: 'green' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <Trans i18nKey="language.setAsDefault">
                    {' '}
                    Set {{ languageName }} as your default{' '}
                  </Trans>
                }
              >
                <span>
                  <IconButton
                    aria-label={`Set ${languageName} as default`}
                    onClick={() => handleSetDefaultLanguage(lang)}
                    disabled={isNew}
                  >
                    <DoneOutlineIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  };

  const renderNewLanguageForm = () => {
    return (
      <FormControl className={classes.newLanguage} style={{ margin: '0 16px' }}>
        <SelectInput
          id="new-language-select"
          layout="horizontal"
          style={{ width: '100%' }}
          field={`${documentLanguages.length + 1} `}
          label={`${documentLanguages.length + 1} `}
          required={true}
          showError={false}
          onChange={handleNewLanguageChanged}
          showSearch={true}
          value={newLanguage.name}
        >
          {allLanguages
            .filter(
              (lang) =>
                documentLanguages.findIndex(
                  (l) => l.languageCode === lang.languageCode
                ) === -1
            )
            .map((lang) => {
              return (
                <Option
                  key={lang.languageCode}
                  value={lang.name}
                >{`${lang.name} (${lang.languageCode})`}</Option>
              );
            })}
        </SelectInput>
        <Tooltip
          title={`Add ${ISO6391.getName(newLanguage.languageCode)}`}
          className={classes.newLanguageSaveButton}
        >
          <span style={{ marginTop: 0 }}>
            <Button
              aria-label={`${t('add')} ${ISO6391.getName(
                newLanguage.languageCode
              )}`}
              disabled={disableSave}
              onClick={() => handleNewLanguageSaveClick()}
              variant="contained"
              color="primary"
              className={classes.addButton}
              style={{ maxHeight: 32, marginTop: 0 }}
            >
              Add
            </Button>
          </span>
        </Tooltip>
      </FormControl>
    );
  };

  const renderDeleteLanguageConfirmationDialog = () => {
    const varTitle =
      selectedLanguageInComponent && selectedLanguageInComponent.languageCode
        ? ISO6391.getName(selectedLanguageInComponent.languageCode)
        : '';
    return (
      <ConfirmationDialog
        title={t<string>('rm-lang')}
        message={
          <Trans i18nKey="docVerManagement.action.confirm-disable-ver">
            Are you sure you want to remove {{ varTitle }} from the document?
          </Trans>
        }
        isOpen={openDeleteDialog}
        onDialogClosed={handleCloseDeleteDialog}
        onDialogConfirmed={() =>
          handleRemoveLanguageFromDocument(handleCloseDeleteDialog)
        }
      />
    );
  };

  return (
    <React.Fragment>
      <Grid container justifyContent={'center'}>
        <Col>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={t<string>('docLangManagement.doc-lang')}
              action={
                <React.Fragment>
                  <Tooltip title={t<string>('doclist.tooltip.edit-content')}>
                    <span>
                      <Button
                        aria-label={`Back`}
                        onClick={() => handleEditContent()}
                        color="primary"
                        disabled={loading}
                      >
                        Back
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={t<string>('tooltip.apply-changes')}>
                    <span>
                      <Button
                        aria-label={`apply changes`}
                        onClick={() => handleApplyChanges()}
                        color="primary"
                        variant="contained"
                        className={classes.actionButtons}
                        disabled={disableApply}
                      >
                        Apply
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={t<string>('tooltip.add-language')}>
                    <span>
                      <Button
                        aria-label={`Add new language`}
                        onClick={handleNewLanguageClick}
                        color="primary"
                        variant="contained"
                        className={classes.actionButtons}
                        disabled={loading}
                      >
                        New
                      </Button>
                    </span>
                  </Tooltip>
                </React.Fragment>
              }
            />
            {loading || !documentLanguages.length || !selectedDoc ? (
              <Col xs={24}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  m={2}
                >
                  <CircularProgress />
                </Box>
              </Col>
            ) : (
              <CardContent className={classes.cardContent}>
                <List>
                  {documentLanguages
                    .sort((a, b) => a.order - b.order)
                    .map((lang) => renderLanguageItem(lang))}
                </List>
                {newLanguage && renderNewLanguageForm()}
              </CardContent>
            )}
          </Card>
        </Col>
      </Grid>
      {selectedLanguageInComponent && renderDeleteLanguageConfirmationDialog()}
    </React.Fragment>
  );
};

export default withStyles(styles)(withSnackbar(DocumentLanguageManagement));
