import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';

export function ConfirmationDialog(props) {
  function onClose(event, reason) {
    if (props.onDialogClosed && reason !== 'backdropClick') {
      props.onDialogClosed();
    }
  }

  function onConfirm() {
    if (props.onDialogConfirmed) {
      props.onDialogConfirmed();
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      {props.discardChangesMessage && (
        <DialogContent>
          <DialogContentText>{props.discardChangesMessage}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {/* Conditionally render the "No" button */}
        {props.onDialogClosed && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <Button onClick={onClose} color="primary" autoFocus>
            No
          </Button>
        )}
        <Button variant="contained" onClick={onConfirm} color="primary">
          {props.loading ? (
            <CircularProgress
              style={{ width: '20px', height: '20px', color: 'white' }}
            />
          ) : (
            'Yes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
