import React, { useState } from 'react';

import { TopicContentComponentBodyItem } from '../../api-schemas/topic';

const initialState: ComponentContextType = {
  items: [],
  setItems: () => {},
};

type ComponentContextType = {
  items: TopicContentComponentBodyItem[];
  setItems: (
    items: (
      items: TopicContentComponentBodyItem[]
    ) => TopicContentComponentBodyItem[]
  ) => void;
};

export const ComponentContext =
  React.createContext<ComponentContextType>(initialState);

export function ComponentProvider({ children }) {
  const [items, setItems] = useState<TopicContentComponentBodyItem[]>([]);

  const value = { items, setItems };

  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  );
}
