import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import '../../preview/styles.css';
import { Col } from 'antd';

const styles: any = () => ({
  preview: {
    padding: '10px',
    border: '2px solid #e7e6e6',
    margin: '5px',
    borderRadius: '4px',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  navbar: {
    margin: '5px 0',
  },
  mainContent: {
    '& > div': {
      marginBottom: 5,
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  marginBottom: {
    '& > div': {
      marginBottom: 5,
    },
  },
  pageSide: {
    backgroundColor: '#fff',
    margin: '5px',
    padding: '0 5px',
    '& > div': {
      marginBottom: 5,
    },
  },
  topicTree: {
    backgroundColor: '#fff',
    padding: 5,
  },
  topicContent: {
    margin: '5px 0 5px 5px',
    paddingRight: 10,
    backgroundColor: '#fff',
  },
});

const KnowledgeBasePreview = (props) => {
  const classes = props.classes;

  return (
    <Col xs={24} className={classes.preview}>
      <Col xs={24} className={classes.borderBottom}>
        <Box
          display="flex"
          alignItems="center"
          margin={1}
          style={{ backgroundColor: props.navbarColor }}
          className={classes.navbar}
        >
          <Box margin={1} style={{ width: '18px' }}>
            <Skeleton
              animation="wave"
              variant="circular"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
          <Box width="15%">
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
          <Box flexGrow={1}></Box>
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
        </Box>
      </Col>
      <Col xs={24} className={classes.borderBottom}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={1}
          style={{ backgroundColor: props.navbarColor }}
          className={classes.navbar}
        >
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
        </Box>
        <br />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={1}
          style={{ backgroundColor: props.navbarColor }}
          className={classes.navbar}
        >
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
          <Box width="10%" marginRight={1}>
            <Skeleton
              animation="wave"
              style={{ backgroundColor: props.fontColor }}
            />
          </Box>
        </Box>
        <br />
      </Col>
      <Col xs={24} style={{ display: 'flex' }} className="previewContent">
        <Col xs={20} className={classes.topicContent}>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft={1}
            className={classes.mainContent}
          >
            <Box width="50%">
              <Skeleton animation="wave" />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="30%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="50%">
              <Skeleton animation="wave" />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="50%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="30%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="50%">
              <Skeleton animation="wave" />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="50%" className="light">
              <Skeleton animation="wave" height={15} />
            </Box>
          </Box>
          <Col xs={24} className={classes.borderBottom}>
            <Box display="flex" marginLeft={1}>
              <Box width="10%">
                <Skeleton animation="wave" />
              </Box>
              <Box flexGrow={1}></Box>
              <Box width="10%">
                <Skeleton animation="wave" />
              </Box>
            </Box>
          </Col>
          <Col xs={24}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box width="50%">
                <Skeleton animation="wave" />
              </Box>
            </Box>
          </Col>
        </Col>
        <Col xs={4}>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft={1}
            marginRight={1}
            className={classes.pageSide}
          >
            <Box width="80%">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="90%">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="80%">
              <Skeleton animation="wave" height={15} />
            </Box>
            <Box width="100%">
              <Skeleton animation="wave" height={15} />
            </Box>
          </Box>
        </Col>
      </Col>
    </Col>
  );
};

export default withStyles(styles)(KnowledgeBasePreview);
