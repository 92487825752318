import React, { useEffect, useState, useRef } from 'react';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { getTypeTitle } from '../../../../helpers/common';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Select, Tag, Form, Col } from 'antd';
import TextInput from '../../../inputs/text-input';
import SelectInput from '../../../inputs/select-input';

const { Option } = Select;

const useStyles: any = makeStyles(() => ({
  activityListItem: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: 10,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogContent: {
    '& *': {
      borderRadius: '0!important',
    },
    minWidth: 600,
    display: 'flex!important',
    flexFlow: 'wrap',
  },
  input: {
    '& *': {
      borderRadius: 0,
    },
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
  iconBox: {
    display: 'flex',
    '& > svg': {
      width: 20,
    },
  },
  completed: {
    '& > svg': {
      color: 'green!important',
    },
  },
  failed: {
    '& > svg': {
      color: 'red!important',
    },
  },
  waiting: {
    '& > svg': {
      color: '#fca021 !important',
    },
  },
  selectMenu: {
    '& .MuiSelect-selectMenu': {
      display: 'flex',
    },
  },
}));

interface ActivityProps {
  stepActivities: any[];
  status?: string;
  activity: any;
  accesses: any[];
  handleOnActivityDataChange?: any;
  handleRemoveActivity?: any;
  viewMode: boolean;
  openDialogOnFirstActivity?: boolean;
  setOpenDialogOnFirstActivity?: any;
}

const Activity: React.FunctionComponent<ActivityProps> = ({
  stepActivities,
  status,
  activity,
  handleOnActivityDataChange,
  accesses,
  handleRemoveActivity,
  viewMode,
  openDialogOnFirstActivity,
  setOpenDialogOnFirstActivity,
}) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (
      !viewMode &&
      ref.current === undefined &&
      stepActivities.indexOf(activity) === stepActivities.length - 1 &&
      activity.id !== undefined &&
      activity.id.toString().length < 3 &&
      openDialogOnFirstActivity
    ) {
      setOpenDialog(true);
      setOpenDialogOnFirstActivity(false);
      ref.current = 'visited';
    }
    // eslint-disable-next-line
  }, []);

  const truncateTitle = (title: string) => {
    if (title.length > 30) {
      return title.slice(0, 27) + '...';
    }
    return title;
  };

  const tagRender = (props) => {
    const { value } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={(event) => {
          event.preventDefault();
          handleOnActivityDataChange(
            activity.id,
            'principal',
            value,
            activity.step
          );
        }}
        style={{ marginRight: 3 }}
      >
        {accesses.find((access) => access.principalId === value)?.email}
      </Tag>
    );
  };

  return (
    <div>
      <div
        className={classes.activityListItem}
        style={{ cursor: 'pointer' }}
        onClick={() => setOpenDialog(true)}
      >
        <span>
          <Tooltip arrow title={activity.title}>
            <div>{truncateTitle(activity.title)}</div>
          </Tooltip>
        </span>
        <Tooltip
          arrow
          title={t<string>('rule-type') + ': ' + getTypeTitle(activity.type, t)}
        >
          <span
            className={
              classes.iconBox +
              ' ' +
              (status === 'COMPLETED'
                ? classes.completed
                : status === 'FAILED'
                  ? classes.failed
                  : status === 'WAITING'
                    ? classes.waiting
                    : '')
            }
          >
            {activity.type === 'APPROVAL' ? (
              <CheckCircleIcon />
            ) : (
              <InsertPhotoIcon />
            )}
          </span>
        </Tooltip>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{activity.title}</DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={viewMode ? { paddingBottom: 20 } : {}}
        >
          <Col xs={12} style={{ marginTop: 20, paddingRight: 5 }}>
            <TextInput
              field="title"
              label={t('title')}
              value={activity.title}
              disabled={viewMode}
              onChange={(event) =>
                handleOnActivityDataChange(
                  activity.id,
                  'title',
                  event.target.value,
                  activity.step
                )
              }
            />
          </Col>
          <Col xs={12} style={{ marginTop: 20, paddingLeft: 5 }}>
            <SelectInput
              field="approval"
              label={t('rule-type')}
              showError={false}
              disabled={viewMode}
              value={activity.type}
              onChange={(value) =>
                handleOnActivityDataChange(
                  activity.id,
                  'type',
                  value,
                  activity.step
                )
              }
            >
              <Option value={'APPROVAL'}>{getTypeTitle('APPROVAL', t)}</Option>
              {stepActivities.filter(
                (stepActivity) =>
                  stepActivity.type === 'ALL_TOPICS_HAVE_IMAGE' &&
                  stepActivity.id !== activity.id
              ).length === 0 && (
                <Option value={'ALL_TOPICS_HAVE_IMAGE'}>
                  {getTypeTitle('ALL_TOPICS_HAVE_IMAGE', t)}
                </Option>
              )}
            </SelectInput>
          </Col>
          {activity.type === 'APPROVAL' ? (
            <Col xs={24} style={{ marginTop: 15 }}>
              <Form layout="vertical">
                <Form.Item label={t('users')}>
                  <Select
                    mode="multiple"
                    onChange={(value) =>
                      handleOnActivityDataChange(
                        activity.id,
                        'principals',
                        value,
                        activity.step
                      )
                    }
                    value={activity.principals.map(
                      (principal) => principal.userId || principal.id
                    )}
                    optionLabelProp="label"
                    tagRender={tagRender}
                    disabled={viewMode}
                  >
                    {accesses?.map((access) => (
                      <Option
                        key={access.id}
                        value={access.principalId}
                        label={access.email}
                      >
                        {access.email}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          ) : null}
          <Col xs={12} style={{ marginTop: 20 }}>
            {activity.type === 'APPROVAL' ? (
              <TextInput
                type="number"
                tooltip={t('help-approval-process')}
                showError={false}
                field="requiredApprovals"
                label={t('requiredApprovals')}
                disabled={viewMode}
                value={activity.requiredApprovals}
                onChange={(event) =>
                  handleOnActivityDataChange(
                    activity.id,
                    'requiredApprovals',
                    parseInt(event.target.value as string),
                    activity.step
                  )
                }
              />
            ) : (
              <TextInput
                type="number"
                tooltip={t('help-minimum-number-of-images')}
                showError={false}
                style={{ paddingRight: 5 }}
                disabled={viewMode}
                field="minimumNumberOfImages"
                label={t('minimumNumberOfImages')}
                value={activity.minimumNumberOfImages}
                onChange={(event) =>
                  handleOnActivityDataChange(
                    activity.id,
                    'minimumNumberOfImages',
                    parseInt(event.target.value as string),
                    activity.step
                  )
                }
              />
            )}
          </Col>
        </DialogContent>
        {!viewMode && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(false);
                handleRemoveActivity(activity.id, activity.step);
              }}
              variant="outlined"
              style={{ borderColor: 'red', color: 'red' }}
            >
              {t('delete')}
            </Button>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="contained"
              color="primary"
            >
              {t('done')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Activity;
