import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Select, Col, Typography as AntTypography, Switch } from 'antd';

import { Typography, CircularProgress } from '@mui/material';
import ManageUsers from './users';
import ManageDomains from './domains';
import ManageTokens from './tokens';
import AccountTokensSettings from '../account/tokens';
import SelectInput from '../inputs/select-input';
import LockIcon from '@mui/icons-material/Lock';
import PlanLimitationDialog from '../plan-limitation-dialog';

const { Option } = Select;
const { Title, Text } = AntTypography;

let previousData: any = null;

const ManageViewerAccesses = (props: any) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!props.plan) {
      setLoading(true);
    }
    if (props.plan && props.plan.name === 'Free') {
      setOpenDialog(true);
      setLoading(false);
    }
    if (props.plan && props.plan !== previousData) {
      previousData = props.plan;
      setLoading(false);
    }
  }, [props.plan]);

  if (props.plan && props.plan.name === 'Free' && !loading) {
    return (
      <React.Fragment>
        <Typography
          style={{ margin: '10px 0 15px 0' }}
          component="h1"
          variant="h6"
        >
          Private Document
        </Typography>
        <span style={{ textAlign: 'center' }}>
          {t('private-document-plan-limitation-text')}
        </span>
        <PlanLimitationDialog
          open={openDialog}
          accountPlan={props.plan.name}
          textTitle={t('private-document-plan-limitation-text-title')}
          textMessage={t('private-document-plan-limitation-text-message')}
          handleCloseLimitationDialog={() => {
            setOpenDialog(false);
          }}
        />
      </React.Fragment>
    );
  } else if (props.account && !loading) {
    return (
      <React.Fragment>
        <Typography
          style={{ margin: '10px 0 15px 0' }}
          component="h1"
          variant="h6"
        >
          Private Document
        </Typography>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="middle"
          items={[
            {
              label: t('settings'),
              key: '1',
              children: (
                <div
                  style={{
                    display: 'flex',
                    marginTop: 10,
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: 15,
                    border: '1px solid #dfdfdf',
                  }}
                >
                  <div
                    className={
                      window.innerWidth > 670
                        ? 'settings-icon-container-show'
                        : 'settings-icon-container-hide'
                    }
                  >
                    <LockIcon
                      style={{
                        fontSize: '36px',
                        color: '#1a73e8',
                        marginLeft: 6,
                        marginTop: '3px',
                      }}
                    />
                  </div>

                  <div
                    style={{ flexDirection: 'column' }}
                    className={
                      window.innerWidth > 670
                        ? 'settings-detail-container-show-partly'
                        : 'settings-detail-container-show-full'
                    }
                  >
                    <Title className="settings-item-title" level={4}>
                      {t('private-document')}
                    </Title>

                    <Text>{t<string>('private-document-desc')}</Text>

                    <br />

                    <Switch
                      checked={props.account.isPrivate}
                      onChange={() => props.handlePrivateChanged()}
                      style={{ marginTop: 10 }}
                      disabled={props.saveLoading}
                    />

                    <div style={{ marginTop: 15, width: '90%' }}>
                      <span
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#726e6e',
                        }}
                      >
                        {t('private-document-help')}
                      </span>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: t('manage-tokens'),
              key: '2',
              children: <AccountTokensSettings {...props} />,
            },
            {
              label: t('private-accesses'),
              key: '3',
              children: (
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size="middle"
                  items={[
                    {
                      label: t('users'),
                      key: '1',
                      children: (
                        <ManageUsers
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) =>
                              access.principalType === 'USER' ||
                              access.principalType === null
                          )}
                        />
                      ),
                    },
                    {
                      label: t('domains'),
                      key: '2',
                      children: (
                        <ManageDomains
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) => access.principalType === 'DOMAIN'
                          )}
                        />
                      ),
                    },
                    {
                      label: t('tokens'),
                      key: '3',
                      children: (
                        <ManageTokens
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) => access.principalType === 'TOKEN'
                          )}
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  } else if (props.selectedDoc && !loading) {
    return (
      <React.Fragment>
        <Typography
          style={{ margin: '10px 0 15px 0' }}
          component="h1"
          variant="h6"
        >
          Private Document
        </Typography>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="middle"
          items={[
            {
              label: t('settings'),
              key: '1',
              children: (
                <Col
                  xs={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <SelectInput
                    style={{ minWidth: 200, width: '100%' }}
                    field="select-private"
                    label={t('private-document')}
                    tooltip={t('private-document-help')}
                    required={true}
                    showError={false}
                    onChange={props.handlePrivateChanged}
                    disabled={props.loading}
                    value={
                      props.selectedDoc.isPrivate === null
                        ? 0
                        : props.selectedDoc.isPrivate
                          ? 1
                          : 2
                    }
                  >
                    <Option value={0}>
                      {t('use-account-settings') +
                        ` (${props.selectedDoc.account.isPrivate ? t('private') : t('public')})`}
                    </Option>
                    <Option value={1}>{t('private')}</Option>
                    <Option value={2}>{t('public')}</Option>
                  </SelectInput>
                </Col>
              ),
            },
            {
              label: t('private-accesses'),
              key: '2',
              children: (
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size="middle"
                  items={[
                    {
                      label: t('users'),
                      key: '1',
                      children: (
                        <ManageUsers
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) =>
                              access.principalType === 'USER' ||
                              access.principalType === null
                          )}
                        />
                      ),
                    },
                    {
                      label: t('domains'),
                      key: '2',
                      children: (
                        <ManageDomains
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) => access.principalType === 'DOMAIN'
                          )}
                        />
                      ),
                    },
                    {
                      label: t('tokens'),
                      key: '3',
                      children: (
                        <ManageTokens
                          {...props}
                          accesses={props.accesses?.filter(
                            (access) => access.principalType === 'TOKEN'
                          )}
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  } else if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return null;
  }
};

export default ManageViewerAccesses;
