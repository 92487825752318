import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Process } from '../../api-schemas/process';

const updateProcessRequest = (data: Process) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/process', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateProcess(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateProcess,
    isPending: isUpdatingProcess,
    isSuccess: isSuccessUpdatingProcess,
  } = useMutation({
    mutationFn: updateProcessRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['processes', accountId] });
    },
  });

  return {
    updateProcess,
    isUpdatingProcess,
    isSuccessUpdatingProcess,
  };
}
