import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { AccountTokens } from '../../api-schemas/account';

const getAccountTokensRequest = (accountId: string) => {
  return axios
    .get<AccountTokens>(`/api/Account/${accountId}/Token`, {
      params: { AccountTokens },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccountTokens(
  accountId: string,
  enabled: boolean = true
) {
  const {
    data,
    refetch: getAccountTokens,
    isFetching: isLoadingAccountTokens,
    isError: isErrorLoadingAccountTokens,
    isSuccess: isSuccessLoadingAccountTokens,
  } = useQuery<AccountTokens>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'tokens', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId && enabled),
    queryFn: () => getAccountTokensRequest(accountId),
  });

  const accountTokens = useMemo(() => data, [data]);

  return {
    accountTokens,
    isLoadingAccountTokens,
    isErrorLoadingAccountTokens,
    isSuccessLoadingAccountTokens,

    getAccountTokens,
  };
}
