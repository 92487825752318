import React, { useEffect, useState } from 'react';

type SupportedTranslatedLanguageType = {
  languageCode: string;
  displayName: string;
  supportSource: boolean;
  supportTarget: boolean;
};

const initialState: TranslateContextType = {
  supportedTranslateLanguages: [],
};

type TranslateContextType = {
  supportedTranslateLanguages: SupportedTranslatedLanguageType[];
};

export const TranslateContext =
  React.createContext<TranslateContextType>(initialState);

export function TranslateProvider({ children }) {
  const [supportedTranslateLanguages, setSupportedTranslateLanguages] =
    useState<SupportedTranslatedLanguageType[]>([]);

  const getSupportedLanguages = async () => {
    const supportedTranslateLanguages = await fetch(
      '/SupportedTranslatedLanguages.json'
    )
      .then((response) => response.json())
      .then((result) => result);
    setSupportedTranslateLanguages(supportedTranslateLanguages);
  };

  useEffect(() => {
    getSupportedLanguages();
  }, []);

  const value = { supportedTranslateLanguages };

  return (
    <TranslateContext.Provider value={value}>
      {children}
    </TranslateContext.Provider>
  );
}
