import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { ProfileContext } from '../../../contexts/profile';

const useStyles: any = makeStyles(() => ({
  small: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    fontSize: '14px',
  },
  avatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#01579b',
    fontSize: '15px',
  },
}));

const ProfilePicture = () => {
  const profile = React.useContext(ProfileContext);
  const classes = useStyles();
  if (profile.profileData && profile.profileData.photoUrl)
    return (
      <img
        src={profile.profileData.photoUrl}
        alt="account"
        className={classes.small}
      />
    );
  else if (localStorage.user_name)
    return (
      <Avatar className={classes.avatar}>{localStorage.user_name[0]}</Avatar>
    );
  else return <AccountCircleIcon />;
};

export default ProfilePicture;
