import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { DraftTopic } from '../../api-schemas/topic';

const getTopicsRequest = (
  accountId: string,
  pageSize: number,
  parentFolderId?: string,
  lastTopicId?: string
) => {
  return axios
    .get<DraftTopic[]>(`/api/Topic`, {
      params: { accountId, parentFolderId, lastTopicId, pageSize },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetTopics({
  accountId,
  parentFolderId,
  lastTopicId,
  pageSize = 10,
  enabled = true,
}: {
  accountId: string;
  parentFolderId?: string;
  lastTopicId?: string;
  pageSize?: number;
  enabled: boolean;
}) {
  const queryKey = ['topics', accountId];

  if (parentFolderId) {
    queryKey.push(parentFolderId);
  }

  if(lastTopicId) {
    queryKey.push(lastTopicId);
  }

  const {
    data,
    refetch: getTopics,
    isFetching: isLoadingTopics,
    isSuccess: isSuccessLoadingTopics,
  } = useQuery<DraftTopic[]>({
    staleTime: 0,
    queryKey,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled,
    queryFn: () =>
      getTopicsRequest(accountId, pageSize, parentFolderId, lastTopicId),
  });

  const topics = useMemo(() => data || [], [data]);

  return {
    topics,
    isLoadingTopics,
    isSuccessLoadingTopics,

    getTopics,
  };
}
