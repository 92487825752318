import React, { useState, Fragment, useEffect } from 'react';
import { Box, Divider, MenuItem } from '@mui/material';
import NotificationItem from '../notification-item';
import Skeleton from '@mui/material/Skeleton';
import {
  List as VirtualizedList,
  AutoSizer,
  InfiniteLoader,
} from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const NotificationsList = ({
  notifications,
  setNotifications,
  fullWidth,
  getNotifications,
  setAnchorEl,
}) => {
  const listHeight: number = 900;
  const rowHeight: number = 90;
  const [notificationsList, setNotificationsList] = useState<any>(null);

  useEffect(() => {
    setNotificationsList(notifications);
  }, [notifications]);

  const { t } = useTranslation();
  const history = useHistory();

  const isRowLoaded = ({ index }) => {
    return !!notificationsList[index];
  };

  const renderNotification = ({ index }) => {
    return (
      <Fragment key={notificationsList[index].id}>
        <NotificationItem
          notification={notificationsList[index]}
          setNotifications={setNotifications}
          setAnchorEl={setAnchorEl}
        />
        {index !== notificationsList.length - 1 ? <Divider /> : null}
      </Fragment>
    );
  };

  if (notificationsList) {
    if (fullWidth) {
      return (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={getNotifications}
          rowCount={100}
          threshold={2}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width }) => (
                <VirtualizedList
                  width={width}
                  height={listHeight}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowHeight={rowHeight}
                  rowRenderer={renderNotification}
                  rowCount={notificationsList.length}
                  style={{
                    maxHeight: window.innerHeight - 220,
                    overflow: 'auto',
                    position: 'relative',
                    outline: 'none',
                  }}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      );
    } else {
      return (
        <Fragment>
          {notificationsList.length === 0 ? (
            <MenuItem
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {t('no-notification')}
            </MenuItem>
          ) : null}
          {notificationsList
            .slice(
              0,
              notificationsList.length < 4 ? notificationsList.length : 4
            )
            .map((notification, index) => (
              <MenuItem style={{ padding: '0 15px' }} key={notification.id}>
                <NotificationItem
                  notification={notification}
                  setNotifications={setNotifications}
                  setAnchorEl={setAnchorEl}
                />
                {index !== notifications.length - 1 ? <Divider /> : null}
              </MenuItem>
            ))}
          {notificationsList.length > 4 ? (
            <MenuItem
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                color: '#1a73e8',
              }}
              onClick={() => history.push('/notifications')}
            >
              {t('show-more')}
            </MenuItem>
          ) : null}
        </Fragment>
      );
    }
  } else {
    return (
      <Box display="flex" flexDirection="column" marginTop={2}>
        {new Array(5).fill(0).map((_, index) => (
          <Box
            key={index}
            paddingBottom={2}
            style={{
              paddingRight: fullWidth ? 0 : 15,
              paddingLeft: fullWidth ? 0 : 15,
            }}
          >
            <Skeleton width={300} height={25} />
            <Skeleton width={50} height={10} style={{ marginBottom: 15 }} />
            {index !== 4 ? <Divider /> : null}
          </Box>
        ))}
      </Box>
    );
  }
};

export default NotificationsList;
