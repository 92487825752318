import React from 'react';
import ManageDocumentAccesses from '../../document/accesses/manage-accesses';
import AccountAccessesSettings from '../../account/accesses';

const ManageDomains = ({ accesses, ...props }) => {
  if (props.entityType === 'Document') {
    return (
      <ManageDocumentAccesses accesses={accesses} {...props} type="DOMAIN" />
    );
  } else {
    return (
      <AccountAccessesSettings accesses={accesses} {...props} type="DOMAIN" />
    );
  }
};

export default ManageDomains;
