import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useGetDraftTopicSilently from './useGetDraftTopicSilently';

import { PublishTopicRequest } from '../../api-schemas/topic';

const pulishTopicRequest = (data: PublishTopicRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Topic/Publish', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function usePublishTopic({
  documentVersionId,
  languageCode,
  topicId,
}: {
  documentVersionId?: string;
  languageCode?: string;
  topicId?: string;
}) {
  const queryClient = useQueryClient();
  const { getTopic } = useGetDraftTopicSilently({
      topicId: topicId,
      languageCode: languageCode,
    });

  const {
    mutateAsync: publishTopic,
    isPending: isPublishingTopic,
    isSuccess: isSuccessPublishingTopic,
  } = useMutation({
    mutationFn: pulishTopicRequest,
    mutationKey: ['publishTopic', documentVersionId, languageCode, topicId],
    onSuccess: () => {
      if (documentVersionId) {
        queryClient.invalidateQueries({
          queryKey: ['draftTopicTree', documentVersionId, languageCode],
        });
      }

      queryClient.invalidateQueries({
        queryKey: ['draftTopic', topicId, languageCode],
      });

      getTopic();
    },
  });

  return {
    publishTopic,
    isPublishingTopic,
    isSuccessPublishingTopic,
  };
}
