import React, { useContext } from 'react';
import { Select } from 'antd';
import ISO6391 from 'iso-639-1';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SelectInput from '../../inputs/select-input';
import { AccountContext } from '../../../contexts/account';

import './styles.css';

const { Option } = Select;

const AccountLanguageSelect = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { selectedAccount, selectedLanguage } = useContext(AccountContext);

  const handleSelectAccountLanguage = (selectedLanguageCode: string) => {
    if (selectedLanguageCode === 'manage') {
      history.push(`/org/settings?selectedTab=languages`);

      return;
    }

    history.push({ search: `lang=${selectedLanguageCode}` });
  };

  if (selectedAccount) {
    return (
      <div id="org-language-dropdown">
        <SelectInput
          id="topic-language-select"
          layout="horizontal"
          style={{ width: '200px', marginRight: 10 }}
          field={`${selectedAccount.languages.length + 1} `}
          required={true}
          showError={false}
          onChange={handleSelectAccountLanguage}
          showSearch={false}
          value={selectedLanguage}
        >
          {selectedAccount.languages.map((language) => (
            <Option key={language.languageCode} value={language.languageCode}>
              {ISO6391.getName(language.languageCode)}
            </Option>
          ))}

          <Option value={'manage'}>{t('manage')}</Option>
        </SelectInput>
      </div>
    );
  }

  return null;
};

export default AccountLanguageSelect;
