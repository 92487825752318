import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteCommentRequest = ({
  threadId,
  commentId,
}: {
  threadId: string;
  commentId: string;
}) => {
  return axios
    .delete(`/api/thread/${threadId}/comment/${commentId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteComment({
  threadId,
  channelId,
}: {
  threadId: string;
  channelId: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteComment,
    isPending: isDeletingComment,
    isSuccess: isSuccessDeletingComment,
  } = useMutation({
    mutationFn: deleteCommentRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['threads', channelId] });
      queryClient.invalidateQueries({ queryKey: ['thread', threadId] });
    },
  });

  return {
    deleteComment,
    isDeletingComment,
    isSuccessDeletingComment,
  };
}
