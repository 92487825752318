import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col } from 'antd';

import DocerBreadcrumbs from '../../../docer-breadcrumbs';
import EditSlugBox from '../../editor/edit-slug-box';
import MetricsStatuses from '../../../metrics-analytics/metrics-statuses';
import {
  calculateBreadCrumb,
  calculateReadabilityScore,
  calculateSEOScore,
  isNewTopic,
} from '../../../../helpers/common';
import Badges from '../../editor/badges';
import SecondaryButtons from '../../editor/buttons/secondary-buttons';
import PrimaryButtons from '../../editor/buttons/primary-buttons';
import { DraftTopic, DraftTopicTree } from '../../../../api-schemas/topic';
import { getLanguageCode } from '../../../../helpers/route';
import { TopicContext } from '../../../../contexts/topic';
import { DocumentContext } from '../../../../contexts/document';
import TopicStatuses from '../../new-tree/topic-statuses';
import useGetSourceTopicLinks from '../../../../api-hooks/reference-links/useGetSourceTopicLinks';

type TopicContentEditorHandlerHeaderPropsType = {
  draftTopicTree?: DraftTopicTree;
  flatDraftTopicTree?: DraftTopicTree;
  isInSaveState: boolean;
  isEditingContent: boolean;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  targetTopicVersionId: string | null;
};

const TopicContentEditorHandlerHeader = ({
  draftTopicTree,
  flatDraftTopicTree,
  isInSaveState,
  isEditingContent,
  selectedTab,
  setSelectedTab,
  targetTopicVersionId,
}: TopicContentEditorHandlerHeaderPropsType) => {
  const { editedTopic, changeTopic, setIsUserModifyingTopic } = useContext(TopicContext);
  const { selectedDoc, selectedLanguage } = useContext(DocumentContext);

  const [numbersOfBrokenLinks, setNumbersOfBrokenLinks] = useState<number>(0);

  const history = useHistory();
  const { search } = useLocation();

  const isLanguageEqual: boolean = editedTopic
    ? editedTopic.title !== null
    : false;
  const isDeleted: boolean = editedTopic
    ? editedTopic.action === 'Deleted'
    : false;
  const isNewTopicFlag: boolean = isNewTopic(
    draftTopicTree,
    editedTopic?.topicId as string
  );
  const isAlreadyPublished: boolean =
    editedTopic && !isDeleted
      ? editedTopic.latestTopicVersionId === editedTopic.publishedTopicVersionId
      : false;
  const showPublishButton: boolean = editedTopic
    ? editedTopic.languageCode === getLanguageCode() && !isNewTopicFlag
    : false;

  const {
    SourceTopicLinks,
    isSuccessLoadingSourceTopicLinks,
    getSourceTopicLinks,
  } = useGetSourceTopicLinks({
    topicId: editedTopic?.topicId,
    languageCode: editedTopic?.languageCode,
  });

  const breadCrumb = useMemo(() => {
    if (
      draftTopicTree &&
      draftTopicTree.length > 0 &&
      editedTopic &&
      editedTopic.topicId
    ) {
      return calculateBreadCrumb(editedTopic.topicId, [], draftTopicTree)
        .slice(0)
        .reverse();
    }
  }, [editedTopic, draftTopicTree, isLanguageEqual]);

  useEffect(() => {
    if (selectedLanguage && editedTopic) {
      getSourceTopicLinks().then(() => {
        if (isSuccessLoadingSourceTopicLinks) {
          const filteredBrokenNumbers = SourceTopicLinks?.filter(
            (it) => it.IsValid === false
          ).length;

          setNumbersOfBrokenLinks(filteredBrokenNumbers || 0);
        }
      });
    }
  }, [selectedLanguage, editedTopic]);

  useEffect(() => {
    if (isInSaveState) setIsUserModifyingTopic(false);
  }, [isInSaveState]);

  const handleSEOOnClick = () => {
    history.push({ hash: 'SEO', search });
  };

  const handleReadabilityOnClick = () => {
    history.push({ hash: 'readability', search });
  };

  const handleRefLinksOnClick = () => {
    history.push({ hash: 'ReferenceLinks', search });
  };

  return (
    <Fragment>
      {isLanguageEqual ? (
        <Fragment>
          <Col
            sm={selectedDoc ? 19 : 21}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editedTopic && !!draftTopicTree && (
                <DocerBreadcrumbs
                  items={breadCrumb}
                  onClick={(topic: DraftTopic) => changeTopic(topic.topicId)}
                />
              )}

              {editedTopic && (
                <Fragment>
                  <span style={{ marginLeft: 3 }}>(</span>
                  <EditSlugBox />
                  <span style={{ marginRight: 5 }}>)</span>

                  <TopicStatuses draftTopic={editedTopic} />
                </Fragment>
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  marginLeft: 'auto',
                }}
              >
                {editedTopic && editedTopic.metrics && (
                  <span
                    style={{
                      display: 'flex',
                      marginRight: 15,
                      marginTop: 3,
                    }}
                    className="hide-on-large"
                  >
                    <MetricsStatuses
                      seoScore={calculateSEOScore(editedTopic?.metrics)}
                      readabilityScore={calculateReadabilityScore(
                        editedTopic?.metrics
                      )}
                      seoOnClick={handleSEOOnClick}
                      readabilityOnClick={handleReadabilityOnClick}
                      brokenLinksOnClick={handleRefLinksOnClick}
                      numberOfBrokenLinks={numbersOfBrokenLinks}
                    />
                  </span>
                )}

                <Badges
                  isLanguageEqual={isLanguageEqual}
                  isAlreadyPublished={isAlreadyPublished}
                  showPublishButton={showPublishButton}
                  inSaveState={isInSaveState}
                />
              </div>

              <div>
                {isEditingContent && (
                  <SecondaryButtons isLanguageEqual={isLanguageEqual} />
                )}
              </div>
            </div>
          </Col>

          <Col
            sm={selectedDoc ? 5 : 3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              flexWrap: 'wrap',
            }}
          >
            <PrimaryButtons
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              draftTopicTree={draftTopicTree}
              flatDraftTopicTree={flatDraftTopicTree}
              isInSaveState={isInSaveState}
              targetTopicVersionId={targetTopicVersionId}
            />
          </Col>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default TopicContentEditorHandlerHeader;
