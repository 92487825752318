import React from 'react';
import { LineChart as AntDLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, TooltipProps } from 'recharts';


const formatDate = (tickItem: string) => {
  const date = new Date(tickItem);
  const options: Intl.DateTimeFormatOptions = { 
    weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  };
  return date.toLocaleDateString('en-US', options);
};

const CustomTooltip: React.FC<TooltipProps<string, number>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
        <p className="label">{`Date : ${formatDate(label)}`}</p>
        <p className="intro">{`Number Of Views : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const LineChart = ({ chartData }) => {


    return (
        <div style={{ margin: '40px 30px 5px 20px', width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
            <AntDLineChart
                width={1400}
                height={400}
                data={chartData}
            >
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="date" tickFormatter={formatDate} />

                <YAxis />

                <Tooltip content={<CustomTooltip />} />

                <Legend />

                <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    label="Number of count"
                    activeDot={{ r: 8 }}
                />
            </AntDLineChart>
            {
            chartData.length > 0 ?
                null : 
                <div
                style={{
                  position: 'absolute',
                  top: '70%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                No Data to Display!
              </div>
                }
        </div>
    )
}

export default LineChart;