import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Plans } from '../../api-schemas/plan';

const getPlansRequest = () => {
  return axios
    .get<Plans>('/api/plan', {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetPlans() {
  const {
    data,
    refetch: getPlans,
    isFetching: isLoadingPlans,
    isError: isErrorLoadingPlans,
    isSuccess: isSuccessLoadingPlans,
  } = useQuery<Plans>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['plans'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: getPlansRequest,
  });

  const plans = useMemo(() => data || [], [data]);

  return {
    plans,
    isLoadingPlans,
    isErrorLoadingPlans,
    isSuccessLoadingPlans,

    getPlans,
  };
}
