class StyleProvider {
    public data;

    constructor(data: any) {
        this.data = data;
    }

    getCSSContent() {
        switch (this.data.type) {
            case "css":
                return this.data.content;
            case "less":
                return this.lessToCSS();
            default:
                break;
        }
    }

    lessToCSS() {
        return this.data.content;
    }
}

export default StyleProvider;