import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ThreadBox from '../thread-box';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../../contexts/profile';
import { AccountContext } from '../../../contexts/account';
import { MdTaskAlt, MdCheck, MdOutlineCircle } from 'react-icons/md';
import { Radio, Tooltip } from 'antd';
import { AccessContext } from '../../../contexts/access';
import useGetThreads from '../../../api-hooks/comment/useGetThreads';
import { emptyGuid } from '../../../helpers/common';

const useStyles: any = makeStyles(() => ({
  commentsBox: {
    padding: 8,
    height: 'calc(100vh - 150px)',
    overflowY: 'auto',
  },
}));

type CommentsBoxPropsType = {
  fullWidth: boolean;
  channelType: string;
  channelId: string;
};

const CommentsBox = ({
  fullWidth,
  channelType,
  channelId,
}: CommentsBoxPropsType) => {
  const emptyThread = {
    id: emptyGuid(),
    comments: [],
    channelId,
    channelType,
  };

  const [threads, setThreads] = useState<any>([]);
  const [accesses, setAccesses] = useState<any>([]);
  const [currentFilter, setCurrentFilter] = useState<string>('unresolved');

  const { profileData } = useContext(ProfileContext);
  const { setUserRole } = useContext(AccountContext);
  const { documentAccesses } = useContext(AccessContext);

  const { t } = useTranslation();

  const { threads: unsortedThreads } = useGetThreads({ channelId });

  const sortAndFilterThreadsAndComments = (
    threads,
    filterValue: string = 'unresolved'
  ) => {
    let sortedThreads = [...threads];
    setCurrentFilter(filterValue);
    sortedThreads = sortedThreads.filter((thread) => {
      switch (filterValue) {
        case 'all':
          return true;
        case 'resolved':
          return thread.resolved === true;
        case 'unresolved':
          return thread.resolved === false;
        default:
          return false;
      }
    });
    sortedThreads.map((thread) => {
      thread.comments?.sort(
        (a, b) =>
          new Date(a.createdDateTime).getTime() >
          new Date(b.createdDateTime).getTime()
      );
      return true;
    });
    sortedThreads.sort((a, b) => {
      if (
        new Date(a.comments[0]?.createdDateTime).getTime() >
        new Date(b.comments[0]?.createdDateTime).getTime()
      )
        return 1;
      return -1;
    });
    setThreads(sortedThreads);
  };

  useEffect(() => {
    if (unsortedThreads)
      sortAndFilterThreadsAndComments(unsortedThreads, currentFilter);
    // eslint-disable-next-line
  }, [unsortedThreads]);

  useEffect(() => {
    if (documentAccesses) {
      setAccesses(documentAccesses);
      const user = documentAccesses.find(
        (access) => access.email === profileData?.email
      );
      if (user) {
        setUserRole(user.action);
      }
    }
    // eslint-disable-next-line
  }, [documentAccesses]);

  const classes = useStyles();
  return (
    <div
      className={classes.commentsBox}
      style={fullWidth ? { paddingLeft: 0, paddingRight: 0 } : {}}
    >
      <Radio.Group
        style={{ display: 'flex' }}
        className="filter-section"
        defaultValue="unresolved"
        buttonStyle="solid"
        onChange={(event) =>
          sortAndFilterThreadsAndComments(unsortedThreads, event.target.value)
        }
      >
        <Tooltip title={t<string>('open')}>
          <Radio.Button
            value="unresolved"
            style={{
              borderBottom: 'none',
              borderColor: 'rgb(196 196 196)',
              display: 'flex',
            }}
          >
            <MdOutlineCircle style={{ width: 15 }} />
          </Radio.Button>
        </Tooltip>
        <Tooltip title={t<string>('resolved')}>
          <Radio.Button
            value="resolved"
            style={{
              borderBottom: 'none',
              borderColor: 'rgb(196 196 196)',
              display: 'flex',
              borderRadius: 0
            }}
          >
            <MdCheck style={{ width: 15 }} />
          </Radio.Button>
        </Tooltip>
        <Tooltip title={t<string>('all')}>
          <Radio.Button
            value="all"
            style={{
              borderBottom: 'none',
              borderColor: 'rgb(196 196 196)',
              display: 'flex',
            }}
          >
            <MdTaskAlt style={{ width: 15 }} />
          </Radio.Button>
        </Tooltip>
      </Radio.Group>
      {threads.map((thread) => (
        <ThreadBox
          key={thread.id}
          fullWidth={fullWidth}
          thread={thread}
          accesses={accesses}
          currentFilter={currentFilter}
        />
      ))}
      <ThreadBox
        thread={emptyThread}
        fullWidth={fullWidth}
        accesses={accesses}
        currentFilter={currentFilter}
      />
    </div>
  );
};

export default CommentsBox;
