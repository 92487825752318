import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Documents } from '../../api-schemas/document';
import useGetAccounts from '../account/useGetAccounts';

const getDocumentsRequest = (
  returnAll: boolean,
  accountId?: string,
  parentFolderId?: string
) => {
  return axios
    .get<Documents>(`/api/Document`, {
      params: { parentFolderId, returnAll, accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetDocuments(
  accountId?: string,
  parentFolderId?: string,
  returnAll: boolean = true,
  enabled: boolean = true
) {
  const { isSuccessLoadingAccounts } = useGetAccounts();

  const queryKey = ['documents', returnAll];

  if (accountId) {
    queryKey.push(accountId);
  }

  if (parentFolderId) {
    queryKey.push(parentFolderId);
  }

  const {
    data,
    refetch: getDocuments,
    isFetching: isLoadingDocuments,
    isSuccess: isSuccessLoadingDocuments,
  } = useQuery<Documents>({
    staleTime: 1000 * 60 * 60,
    queryKey,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isSuccessLoadingAccounts && enabled,
    queryFn: () => getDocumentsRequest(returnAll, accountId, parentFolderId),
  });

  const documents = useMemo(() => data || [], [data]);

  return {
    documents,
    isLoadingDocuments,
    isSuccessLoadingDocuments,

    getDocuments,
  };
}
