import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

import TextInput from '../../../inputs/text-input';
import { convertToSlug } from '../../../../helpers/common';
import useSaveTopic from '../../../../api-hooks/topic/useSaveTopic';
import { TopicContext } from '../../../../contexts/topic';
import { DocumentContext } from '../../../../contexts/document';

const EditSlugBox = () => {
  const [mode, setMode] = useState<string>('view');
  const [newSlug, setNewSlug] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { editedTopic, setEditedTopic } = useContext(TopicContext);

  const { saveTopic } = useSaveTopic({
    documentVersionId: editedTopic?.documentVersionId,
    languageCode: editedTopic?.languageCode,
  });

  const { documentTopics } = React.useContext(DocumentContext);

  const queryClient = useQueryClient();

  const validateNewSlug = () => {
    //check if slug is not changed
    if (newSlug === editedTopic?.slug) {
      setMode('view');

      return false;
    }

    //check duplicate slug in doc
    const topicWithSameSlug = documentTopics.find(
      (topic) => topic.slug === newSlug
    );

    if (topicWithSameSlug) {
      enqueueSnackbar(t(`error.Duplicate`), {
        variant: 'error',
      });

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (editedTopic) setNewSlug(editedTopic.slug);
  }, [editedTopic]);

  const handleSave = () => {
    if (editedTopic) {
      if (!validateNewSlug()) return;

      setLoading(true);

      const newTopic = { ...editedTopic, slug: newSlug };

      saveTopic({ ...newTopic, overwrite: false })
        .then(async (topic) => {
          setEditedTopic(topic);

          await queryClient.invalidateQueries({
            queryKey: [
              'draftTopic',
              editedTopic.topicId,
              editedTopic.languageCode,
            ],
          });

          setMode('view');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    const value: string = event.target.value;

    setNewSlug(convertToSlug(value));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextInput
        inputStyle={{
          height: 32,
          border: 'none',
          backgroundColor: '#fff',
          padding: '5px 1px',
          boxShadow: 'none',
          width:
            (newSlug
              ? (newSlug.length > 25 ? 25 : newSlug.length) * 7
              : 'new-editedTopic'.length * 7) +
            (mode === 'view' ? 18 : 36) +
            (mode === 'view' ? 10 : 20) +
            5,
        }}
        field="slug"
        showError={false}
        value={newSlug}
        onChange={handleChange}
        disabled={mode === 'view'}
        suffix={
          mode === 'view' ? (
            <EditIcon
              onClick={() => setMode('edit')}
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                width: 18,
                cursor: 'pointer',
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress style={{ width: 15, height: 15 }} />
              ) : (
                <DoneIcon
                  onClick={handleSave}
                  style={{
                    color: loading ? 'rgba(0, 0, 0, 0.54)' : '#1a73e8',
                    pointerEvents: loading ? 'none' : 'auto',
                    width: 18,
                    cursor: 'pointer',
                  }}
                />
              )}
              <CloseIcon
                onClick={() => {
                  setNewSlug(editedTopic!.slug);
                  setMode('view');
                }}
                style={{
                  color: loading ? 'rgba(0, 0, 0, 0.54)' : 'rgb(235, 0, 20)',
                  width: 18,
                  cursor: 'pointer',
                }}
              />
            </Fragment>
          )
        }
      />
    </div>
  );
};

export default EditSlugBox;
