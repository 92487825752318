import React, { useState, useEffect, useContext, useRef } from 'react';
import { Paper, CircularProgress, Grid, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AccountGeneralSettings from './org-settings/general';
import AccountAccessesSettings from './accesses';
import AccountBillingSettings from './billing';
import { AccountContext } from '../../contexts/account';
import { ProfileContext } from '../../contexts/profile';
import AccountProcessesSettings from './process/list';
import ManageProcess from './process/manage';
import { showSuccessMessage } from '../../helpers/common';
import ManageViewerAccesses from '../manage-viewer-accesses';
import { AccountRoles as ROLES } from '../../helpers/roles';
import OrgSettings from './org-settings';
import Variables from './variables';
import useGetAccesses from '../../api-hooks/access/useGetAccesses';
import { Accesses } from '../../api-schemas/access';
import useGetCurrentPlan from '../../api-hooks/plan/useGetCurrentPlan';
import useGetProcesses from '../../api-hooks/process/useGetProcesses';
import useGetAccounts from '../../api-hooks/account/useGetAccounts';
import useGetAccount from '../../api-hooks/account/useGetAccount';
import useUpdateAccountWebAssistantSettings from '../../api-hooks/account/useUpdateAccountWebAssistantSettings';
import useCreateAccount from '../../api-hooks/account/useCreateAccount';
import useUploadAccountLogo from '../../api-hooks/account/useUploadAccountLogo';
import useDeleteAccountLogo from '../../api-hooks/account/useDeleteAccountLogo';
import useUpdateAccountGeneralSettings from '../../api-hooks/account/useUpdateAccountGeneralSettings';

const styles: any = (theme) => ({
  layout: {
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    height: 'calc(100% - 40px)',
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    boxShadow: 'none !important',
    height: 'fit-content',
    width: '80%',
    maxWidth: 950 + theme.spacing(3) * 2,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(900 + parseInt(theme.spacing(3)) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  selectedAccountContainer: {
    margin: '5px',
    alignment: 'right',
  },
  projectLabel: {
    display: 'inline-block',
    marginRight: '10px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '5px',
  },
});

function AccountSettings(props) {
  const createMode = window.location.pathname.split('/')[3] === 'create';
  const settingsItem = props.match.params['settingsItem'];
  const settingsAction = props.match.params['settingsAction'] || '';

  const { selectedAccount, setSelectedAccount, setUserRole } =
    useContext(AccountContext);
  const profile = useContext(ProfileContext);

  const webAssistantInitialSettings = useRef();
  const previousAccountId = useRef<string | null>(null);

  const { t } = useTranslation();
  const history = useHistory();

  const [processes, setProcesses] = useState<any[] | undefined>(undefined);
  const [showBilling, setShowBilling] = useState<boolean>(true);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [loadingUsers, setUsersLoading] = useState<boolean>(false);
  const [trackingId, setTrackingId] = useState<string | null>(null);
  const [webAssistantConfiguration, setWebAssistantConfiguration] =
    useState<any>();
  const [accesses, setAccesses] = useState<Accesses | null>(null);

  const { getAccesses } = useGetAccesses(selectedAccountId || '');
  const {
    account,
    loadingAccountError,
    isSuccessLoadingAccount,
    isErrorLoadingAccount,
  } = useGetAccount(selectedAccountId || '');
  const { accounts, getAccounts } = useGetAccounts();
  const { currentPlan: plan } = useGetCurrentPlan(selectedAccountId || '');
  const {
    processes: serverProcesses,
    isSuccessLoadingProcesses,
    getProcesses,
  } = useGetProcesses({
    accountId: selectedAccountId || '',
    enabled: Boolean(selectedAccountId),
  });
  const { updateAccountWebAssistantSettings } =
    useUpdateAccountWebAssistantSettings(selectedAccountId || '');
  const { createAccount } = useCreateAccount();
  const { uploadAccountLogo } = useUploadAccountLogo(selectedAccountId || '');
  const { deleteAccountLogo } = useDeleteAccountLogo(selectedAccountId || '');
  const { updateAccountGeneralSettings } = useUpdateAccountGeneralSettings(
    selectedAccountId || ''
  );

  const { classes } = props;

  useEffect(() => {
    if (isSuccessLoadingProcesses && serverProcesses) {
      const sortedProcesses = [...serverProcesses];

      sortedProcesses.sort((a, b) =>
        new Date(a.createDateTime).getTime() <
        new Date(b.createDateTime).getTime()
          ? 1
          : -1
      );

      setProcesses(sortedProcesses);
    }
  }, [isSuccessLoadingProcesses]);

  useEffect(() => {
    if (accounts && selectedAccountId === '' && profile.profileData) {
      const filteredAccounts = accounts.filter(
        (account) => account.email === profile.profileData.email
      );

      if (filteredAccounts.length && createMode) {
        history.push('/org/settings/general');
      } else if (
        (!selectedAccount ||
          selectedAccount.email !== profile.profileData.email) &&
        createMode
      ) {
        setSelectedAccountId(null);
        setSelectedAccount({
          id: null,
          slug: '',
          title: '',
        });
      } else {
        const firstAccount = accounts.at(-1);

        setTrackingId(
          selectedAccount
            ? selectedAccount.trackingId
            : firstAccount?.trackingId
        );
      }
    }

    // eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    if (selectedAccount) {
      selectAccount(selectedAccount.id);
    }

    // eslint-disable-next-line
  }, [selectedAccount]);

  useEffect(() => {
    if (isSuccessLoadingAccount && account) {
      setSelectedAccount(account);
      setTrackingId(account.trackingId);
      setContentLoading(false);

      getAccesses().then((result) => {
        const accesses = result.data;

        setUsersLoading(false);

        if (accesses) {
          setAccesses(accesses);
          handleShowBilling(accesses);

          const user = accesses.find(
            (access) => access.email === profile.profileData?.email
          );
          if (user) {
            setUserRole(user.action);
          }
        }
      });
    }
  }, [account, isSuccessLoadingAccount]);

  useEffect(() => {
    if (isErrorLoadingAccount) {
      setUsersLoading(false);
      setContentLoading(false);
      setSelectedAccountId(previousAccountId.current);

      if (loadingAccountError.response && loadingAccountError.response.data) {
        props.enqueueSnackbar(
          loadingAccountError.response.status === 403
            ? t('error.insufficientPermission')
            : loadingAccountError.response.data,
          {
            variant: 'error',
          }
        );
      }
    }
  }, [isErrorLoadingAccount]);

  const handleAccountGeneralConfigurationChange = async (
    accountConfiguration,
    saveAccount: boolean
  ) => {
    if (!accountConfiguration.slug || !accountConfiguration.title) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }

    setSelectedAccount(accountConfiguration);

    if (saveAccount) {
      await handleSaveAccountSettings(accountConfiguration);
    }
  };

  const handleRemoveLogo = () => {
    return deleteAccountLogo({ accountId: selectedAccount?.id })
      .then(() => {
        props.enqueueSnackbar(t('success.deleteLogo'), {
          variant: 'success',
        });
        return true;
      })
      .catch(() => {
        props.enqueueSnackbar(t('error.deleteLogo'), {
          variant: 'error',
        });
        return false;
      });
  };

  const sendLogo = () => {
    if (selectedAccount?.accountLogo) {
      const formData = new FormData();

      formData.append('accountId', selectedAccount.id);
      formData.append(
        'logo',
        selectedAccount?.accountLogo,
        `image.${selectedAccount.accountLogo?.type.split('/')[1]}`
      );

      return uploadAccountLogo(formData)
        .then(() => {
          return true;
        })
        .catch(() => {
          props.enqueueSnackbar(t('error.uploadLogo'), {
            variant: 'error',
          });
          return false;
        });
    } else {
      return true;
    }
  };

  const handleSaveWebAssistantSettings = async () => {
    if (
      webAssistantConfiguration &&
      JSON.stringify(webAssistantInitialSettings) !==
        JSON.stringify(webAssistantConfiguration)
    ) {
      await updateAccountWebAssistantSettings({
        accountId: selectedAccount?.id,
        data: webAssistantConfiguration,
      }).catch((error) => {
        props.enqueueSnackbar(
          error.error ? error.error : t('error.unknown-web-assistant'),
          {
            variant: 'error',
          }
        );
      });
    }
  };

  const handleSaveAccountSettings = async (accountConfiguration) => {
    const accountData = accountConfiguration?.id
      ? accountConfiguration
      : selectedAccount;
    if (accountData) {
      if (createMode) {
        setLoading(true);

        const body = {
          title: accountData.title,
          slug: accountData.slug,
        };

        await createAccount(body)
          .then(async () => {
            //        if (
            //          config.get('environment') === 'stage' ||
            //          selectedAccount.planId ===
            //            '0b8085be-c002-4320-b3f7-7982636c2671' ||
            //          selectedAccount.planId ===
            //            '2d8e7c87-74d8-4c17-9108-5f77f0cec109' ||
            //          selectedAccount.slug === 'sangal' ||
            //          selectedAccount.slug === 'manu-online'
            //        ) {
            await handleSaveWebAssistantSettings();
            //        }

            showSuccessMessage(props.enqueueSnackbar, t);

            setLoading(false);
          })
          .catch((error) => {
            props.enqueueSnackbar(
              error.error ? error.error : t('error.unknown'),
              {
                variant: 'error',
              }
            );

            setLoading(false);
          });
      } else {
        if (
          accountData.trackingId &&
          document.getElementById('analytics-error')
        ) {
          const value = accountData.trackingId;
          if (
            (value.substr(0, 3) === 'UA-' && value.length === 14) ||
            (value.substr(0, 2) === 'G-' && value.length === 12)
          ) {
            (
              document.getElementById('analytics-error') as HTMLElement
            ).innerHTML = '';
          } else {
            (
              document.getElementById('analytics-error') as HTMLElement
            ).innerHTML = t('error.analytics');
            return;
          }
        }

        setLoading(true);

        if (selectedAccountId) {
          await updateAccountGeneralSettings(accountData)
            .then(async () => {
              //            if (
              //              config.get('environment') === 'stage' ||
              //              selectedAccount.slug === 'sangal' ||
              //              selectedAccount.slug === 'manu-online' ||
              //              selectedAccount.planId ===
              //                '0b8085be-c002-4320-b3f7-7982636c2671' ||
              //              selectedAccount.planId ===
              //                '2d8e7c87-74d8-4c17-9108-5f77f0cec109'
              //            ) {
              await handleSaveWebAssistantSettings();
              //            }
              const resultSendLogo = await sendLogo();
              if (resultSendLogo) {
                getAccounts();
              } else {
                selectAccount(selectedAccountId);
              }

              showSuccessMessage(props.enqueueSnackbar, t);

              setLoading(false);
            })
            .catch((error) => {
              props.enqueueSnackbar(
                error.error ? error.error : t('error.unknown'),
                {
                  variant: 'error',
                }
              );
              setLoading(false);
            });
        }
      }
    }
  };

  const handleShowBilling = (accesses) => {
    const access = accesses.find(
      (access) => access.principalId === profile.profileData?.id
    );
    if (access.action === ROLES.OWNER) {
      setShowBilling(true);
    } else {
      setShowBilling(false);
    }
  };

  const selectAccount = (accountId: string) => {
    previousAccountId.current = selectedAccountId;

    if (previousAccountId.current === accountId) return;

    setSelectedAccountId(accountId);
    setUsersLoading(true);
    setContentLoading(true);
  };

  const renderSettingsPage = (Component, customProps: any = null) => {
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {contentLoading ? (
            <Grid
              container
              spacing={0}
              alignItems="center"
              direction="column"
              style={{ height: 'inherit', justifyContent: 'center' }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Component
              {...customProps}
              accountConfiguration={selectedAccount}
              history={props.history}
              match={props.match}
              onAccountConfigurationChanged={
                handleAccountGeneralConfigurationChange
              }
              handleSaveAccountSettings={handleSaveAccountSettings}
              disabled={disableSaveButton}
              setLoading={setLoading}
            />
          )}
        </Paper>
      </main>
    );
  };

  const handlePrivateChanged = () => {
    const newAccountConfiguration: any = Object.assign({}, selectedAccount);

    newAccountConfiguration.isPrivate = !newAccountConfiguration.isPrivate;

    handleSaveAccountSettings(newAccountConfiguration);
  };

  if (createMode) {
    return renderSettingsPage(AccountGeneralSettings, { loading: loading });
  } else if (selectedAccount) {
    if (!showBilling && settingsItem === 'billing') {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ color: 'red', fontSize: '20px' }}
        >
          You do not have access
        </Box>
      );
    }
    switch (settingsItem) {
      case 'processes':
        switch (settingsAction) {
          case 'manage':
            return renderSettingsPage(ManageProcess, {
              loading: loading,
              accesses: accesses,
              processes: processes,
              setProcesses: setProcesses,
              getProcesses: getProcesses,
              plan: plan,
            });
          default:
            return renderSettingsPage(AccountProcessesSettings, {
              loading: loading,
              processes: processes,
              setProcesses: setProcesses,
              plan: plan,
            });
        }
      case 'billing':
        return renderSettingsPage(AccountBillingSettings, {
          subscriptionError: props.subscriptionError,
          match: props.match,
        });
      case 'manage-team':
        return renderSettingsPage(AccountAccessesSettings, {
          accountId: selectedAccountId,
          accesses: accesses,
          ROLES: ROLES,
          isViewerAccess: false,
          accountTitle: selectedAccount.title,
          loading: loadingUsers,
          plan: plan,
          title: t<string>('manage-team-settings-title'),
        });
      case 'variables':
        return renderSettingsPage(Variables, {
          accountId: selectedAccountId,
          accountTitle: selectedAccount.title,
          title: t<string>('manage-variables-settings-title'),
        });
      case 'private-document':
        return renderSettingsPage(ManageViewerAccesses, {
          saveLoading: loading,
          handlePrivateChanged: handlePrivateChanged,
          accountId: selectedAccountId,
          entityType: 'Account',
          accesses: accesses,
          ROLES: ROLES,
          isViewerAccess: true,
          accountTitle: selectedAccount.title,
          loading: loadingUsers,
          plan: plan,
          account: selectedAccount,
        });
      default:
        return renderSettingsPage(OrgSettings, {
          trackingId: trackingId,
          loading: loading,
          handleRemoveLogo: handleRemoveLogo,
          webAssistantConfiguration,
          setWebAssistantConfiguration,
          webAssistantInitialSettings,
        });
    }
  } else {
    return null;
  }
}

export default withStyles(styles)(withSnackbar(AccountSettings));
