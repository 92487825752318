import React, { useContext, useState } from 'react';
import { Tooltip, Typography as AntTypography } from 'antd';

import { useTranslation } from 'react-i18next';
import { GoogleOutlined } from '@ant-design/icons';
import { Button as ButtonMaterial, CircularProgress } from '@mui/material';

import { AccountContext } from '../../../contexts/account';
import { handleGoogleDriveSignIn } from '../../../helpers/google-drive-auth';
import { AccountRoles } from '../../../helpers/roles';
import { ConfirmationDialog } from '../../confirmation-dialog';

const { Title } = AntTypography;

const InvalidGoogleDriveTokenSection = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { userRole } = useContext(AccountContext);

  const { t } = useTranslation();

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleConnectToGoogleDrive = () => {
    if (userRole === AccountRoles.ADMIN || userRole === AccountRoles.OWNER) {
      setLoading(true);

      handleGoogleDriveSignIn();
    } else setOpenDialog(true);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        marginTop: '60px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <GoogleOutlined
          style={{ fontSize: 32 }}
          className="menu-item-for-new"
        />
        <Title className="settings-item-title" level={4}>
          {t('google-drive-deleted-token-desc')}
        </Title>
      </div>
      <div
        style={{
          width: '95%',
          display: 'flex',
          justifyContent: 'end',
          marginTop: '20px'
        }}
      >
        <Tooltip
          title={t<string>('connect-googleDrive-help')}
          style={{ margin: '5px 30px 0 0' }}
        >
          <ButtonMaterial
            variant="contained"
            style={{ height: '35px' }}
            color="primary"
            onClick={handleConnectToGoogleDrive}
            aria-label="Create new document"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: '#fff' }}
              />
            ) : (
              t('connect-googleDrive')
            )}
          </ButtonMaterial>
        </Tooltip>
      </div>

      <ConfirmationDialog
        title={t<string>('google-drive-access-reject-title')}
        message={t<string>('google-drive-access-reject-desc')}
        isOpen={openDialog}
        onDialogConfirmed={handleCloseDeleteDialog}
      />
    </div>
  );
};

export default InvalidGoogleDriveTokenSection;
