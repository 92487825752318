export const AccountRoles = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  WRITER: 'WRITER',
  READER: 'READER',
  VIEWER_READER: 'VIEWER_READER',
};

export const DocumentRoles = {
  ADMIN: 'ADMIN',
  WRITER: 'WRITER',
  READER: 'READER',
  VIEWER_READER: 'VIEWER_READER',
};
