import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import config from 'react-global-configuration';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <BrowserRouter
    {...rest}
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    //@ts-expect-error
    render={(props) =>
      isAuthenticated() ? <Component {...props} /> : redirectToLoginPage()
    }
  />
);

function isAuthenticated() {
  if (localStorage.auth_token) {
    return true;
  } else {
    const queryParamteres = getQueryStringParameters();
    const authenticationToken = queryParamteres.find(
      (item) => item.key === 'authenticationToken'
    );
    const userName = queryParamteres.find((item) => item.key === 'userName');
    if (authenticationToken && userName) {
      let url = window.location.toString();
      url = removeParameter(url, 'authenticationToken');
      url = removeParameter(url, 'userName');
      window.history.replaceState({}, document.title, url);
      localStorage.auth_token = authenticationToken.value;
      localStorage.user_name = userName.value;
      return true;
    }

    return false;
  }
}

export function redirectToLoginPage() {
  localStorage.auth_token = '';
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
  if (window.location.hostname === 'localhost') {
    window.location.assign(
      `/auth/login?targetPathName=${window.location.pathname}`
    );
  } else {
    window.location.assign(
      `${config.get('editorURL')}/auth/login?targetPathName=${window.location.pathname}`
    );
  }
}

function getQueryStringParameters() {
  return decodeURIComponent(window.location.search.substring(1))
    .split('&')
    .map((a) => {
      const p = a.split('=');
      const [key, value] = p;
      return { key, value };
    });
}

function removeParameter(url: string, key) {
  const startIndex = url.indexOf(key);
  let endIndex = url
    .toString()
    .substring(startIndex)
    .indexOf(encodeURIComponent('&'));
  if (endIndex < 0) endIndex = url.toString().length;

  endIndex += startIndex + encodeURIComponent('&').length;

  let newUrl: string =
    url.toString().substring(0, startIndex) +
    url.toString().substring(endIndex, url.toString().length);
  if (newUrl[newUrl.length - 1] === '?')
    newUrl = newUrl.substring(0, newUrl.length - 1);

  return newUrl;
}

export default PrivateRoute;
