import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const sendEmailRequest = (data: FormData) => {
  return axios
    .post('/api/Email', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useSendEmail() {
  const {
    mutateAsync: sendEmail,
    isPending: isSendingEmail,
    isSuccess: isSuccessSendingEmail,
  } = useMutation({
    mutationFn: sendEmailRequest,
  });

  return {
    sendEmail,
    isSendingEmail,
    isSuccessSendingEmail,
  };
}
