import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseSquareOutlined,
  CheckSquareOutlined,
  FileTextOutlined,
  PictureOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import config from 'react-global-configuration';
import { useLocation } from 'react-router-dom';

import ImagePreviewModal from '../image-modal';

import { TopicLinksData } from '../../../api-schemas/reference-links';

import './styles.css';

type TopicRefrenceProps = {
  data: TopicLinksData;
};

type DataSourceWithRow = TopicLinksData[0] & { key: number; Url: string };

const DirectTopicRefrence = ({ data }: TopicRefrenceProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const { t } = useTranslation();
  const { hash: selectedSideSection } = useLocation();

  const openModalWithImage = (url: string) => {
    setImageUrl(url);
    setIsModalOpen(true);
  };

  const TABLE_COLUMNS = [
    {
      title: t('title'),
      dataIndex: 'Url',
      key: 'Url',
      render: (text, record) =>
        record.TargetType === 'URL' ? (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : record.TargetType === 'IMAGE' ? (
          record.IsValid ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openModalWithImage(`${config.get('editorURL')}${text}`);
              }}
            >
              {record.ImageName}
            </a>
          ) : (
            t('file-name-not-found')
          )
        ) : (
          text
        ),
    },
    {
      title: t('link-type'),
      dataIndex: 'TargetType',
      key: 'TargetType',
      render: (text) =>
        text == 'IMAGE' ? (
          <Tooltip
            title={t<string>('image')}
            style={{ margin: '5px 20px 0 0' }}
          >
            <PictureOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ) : text == 'URL' ? (
          <Tooltip title={t<string>('url')} style={{ margin: '5px 20px 0 0' }}>
            <LinkOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ) : (
          <Tooltip
            title={t<string>('topic-link')}
            style={{ margin: '5px 20px 0 0' }}
          >
            <FileTextOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ),
    },
    {
      title: t('status'),
      dataIndex: 'IsValid',
      key: 'IsValid',
      render: (text) =>
        text ? (
          <CheckSquareOutlined style={{ color: 'green', fontSize: '22px' }} />
        ) : (
          <CloseSquareOutlined style={{ color: 'red', fontSize: '22px' }} />
        ),
    },
  ];

  const filteredColumns = selectedSideSection
    ? TABLE_COLUMNS.filter((col) => col.dataIndex !== 'TargetType')
    : TABLE_COLUMNS;

  const dataSourceWithRow: DataSourceWithRow[] = data.map((item, index) => ({
    ...item,
    key: index,
    Url: item.TargetType === 'TOPIC_LINK' ? item.Header || '' : item.Url || '',
  }));

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 0 15px 0',
        }}
      >
        <Table
          style={{ width: '90%', whiteSpace: 'normal', wordWrap: 'break-word' }}
          columns={filteredColumns}
          dataSource={dataSourceWithRow}
          pagination={dataSourceWithRow.length > 5 ? { pageSize: 5 } : false}
        />
      </div>

      <ImagePreviewModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default DirectTopicRefrence;
