import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, CircularProgress } from '@mui/material';
import { ReloadOutlined } from '@ant-design/icons';
import { Tabs, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import DirectTopicRefrence from './direct-reference';
import CrossTopicRefrence from './cross-reference';

import { initinitializeGraph } from '../../helpers/lib/ref-links-graph';
import { TopicContext } from '../../contexts/topic';
import useGetSourceTopicLinks from '../../api-hooks/reference-links/useGetSourceTopicLinks';
import useGetTargetTopicLinks from '../../api-hooks/reference-links/useGetTargetTopicLinks';
import useGetRevalidateLinks from '../../api-hooks/reference-links/useRevalidateLinks';

import './styles.css';

let grph;

const ReferenceLinks = () => {
  const { selectedTopic } = useContext(TopicContext);

  const [menuItem, setMenuItem] = useState<number>(1);
  const [lastValidationDate, setLastValidationDate] = useState<string>('Never');
  const [loading, setLoading] = useState<boolean>(true);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hash: selectedSideSection } = useLocation();

  const {
    SourceTopicLinks,
    isSuccessLoadingSourceTopicLinks,
    isLoadingSourceTopicLinks,
    isRefetchingSourceTopicLinks,
    getSourceTopicLinks,
  } = useGetSourceTopicLinks({
    topicId: selectedTopic?.topicId,
    languageCode: selectedTopic?.languageCode,
  });
  const {
    TargetTopicLinks,
    isSuccessLoadingTargetTopicLinks,
    getTargetTopicLinks,
  } = useGetTargetTopicLinks({
    topicId: selectedTopic?.topicId,
    languageCode: selectedTopic?.languageCode,
  });

  const { getRevalidateLinks } = useGetRevalidateLinks({
    sourceTopicId: selectedTopic?.topicId,
    targetTopicId: selectedTopic?.topicId,
  });

  const ITEMS = [
    {
      label: t('from-this-topic'),
      key: '1',
      children: <DirectTopicRefrence data={SourceTopicLinks || []} />,
    },
    {
      label: t('to-this-topic'),
      key: '2',
      children: <CrossTopicRefrence data={TargetTopicLinks || []} />,
    },
  ];

  const handleRevalidateLinks = async () => {
    if (isDebouncing) {
      enqueueSnackbar(t(`revalidate-avoid-attempting`), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });

      return;
    }

    if (selectedTopic) {
      setLoading(true);
      setIsDebouncing(true);

      await getRevalidateLinks();
      fetchTopicLinks();

      setTimeout(() => {
        setIsDebouncing(false);
      }, 30000);
    }
  };

  const fetchTopicLinks = () => {
    if (selectedTopic) {
      getSourceTopicLinks();
      getTargetTopicLinks();
    }
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    setMenuItem(e.target.value);
  };

  useEffect(() => {
    fetchTopicLinks();
  }, []);

  useEffect(() => {
    if (
      isSuccessLoadingSourceTopicLinks &&
      isSuccessLoadingTargetTopicLinks &&
      !isLoadingSourceTopicLinks
    )
      setLoading(false);
  }, [
    isSuccessLoadingSourceTopicLinks,
    isSuccessLoadingTargetTopicLinks,
    isRefetchingSourceTopicLinks,
  ]);

  useEffect(() => {
    const container = document.getElementById('container');

    if (!grph && container != null && SourceTopicLinks && TargetTopicLinks) {
      const nodeCrossSource = TargetTopicLinks.map((it) => {
        return {
          text: it.Url || it.Header,
          count: 9,
          lang: 'en',
          id: 'ex' + it.Id,
          type: it.TargetType,
        };
      });

      const nodeDirectSource = SourceTopicLinks.map((it) => {
        return {
          text:
            it.TargetType == 'IMAGE'
              ? it.ImageName || 'Invalid Image !'
              : it.Url || it.Header,
          id: it.Id,
          lang: 'en',
          centerNode: selectedTopic?.topicId,
          neighbor: true,
        };
      });

      const grphDirectSource = SourceTopicLinks.map((it) => {
        return {
          source: selectedTopic?.topicId,
          target: it.Id,
        };
      });

      const grphCrossSource = TargetTopicLinks.map((it) => {
        return {
          source: selectedTopic?.topicId,
          target: 'ex' + it.Id,
        };
      });

      const tempObj = {
        nodes: [
          ...nodeCrossSource,
          ...nodeDirectSource,
          {
            text: selectedTopic?.title,
            count: 10,
            lang: 'en',
            id: selectedTopic?.topicId,
            type: 'Topic',
          },
        ],
        edges: [...grphDirectSource, ...grphCrossSource],
      };

      grph = initinitializeGraph(container, true, tempObj);
    } else if (menuItem === 1) grph = initinitializeGraph(container, false);
  }, [menuItem, SourceTopicLinks, TargetTopicLinks]);

  const formattedDate = useMemo(() => {
    if (SourceTopicLinks && TargetTopicLinks) {
      const sourceTimestamps = SourceTopicLinks.map(
        (it) => it.UpdateDateTime._seconds
      );
      const targetTimestamps = TargetTopicLinks.map(
        (it) => it.UpdateDateTime._seconds
      );

      const maxSourceTimestamp = Math.max(...sourceTimestamps);
      const maxTargetTimestamp = Math.max(...targetTimestamps);

      const maxTimestamp = Math.max(maxSourceTimestamp, maxTargetTimestamp);

      const leastTime = new Date(maxTimestamp * 1000);

      const formattedDate = moment(new Date(leastTime), 'YYYYMMDDLL').fromNow();

      return formattedDate !== 'Invalid Date' ? formattedDate : t('never');
    }

    return t('never');
  }, [SourceTopicLinks, TargetTopicLinks]);

  useEffect(() => {
    if (formattedDate) {
      setLastValidationDate(formattedDate);
    }
  }, [formattedDate]);

  return (
    <div
      className="layout"
      style={
        selectedSideSection
          ? {
              display: 'inline-table',
              marginRight: 10,
              marginTop: 10,
              padding: 10,
            }
          : {}
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '25px',
          marginRight: '25px',
        }}
      >
        <Typography
          style={{
            margin: '10px 0 2px 8px',
            marginLeft: selectedSideSection ? 0 : 20,
          }}
          component="h1"
          variant="h6"
        >
          {t('reference-links-title')}
        </Typography>

        <Radio.Group onChange={onRadioChange} value={menuItem}>
          <Radio.Button value={1}>{t('detail-menu-item')}</Radio.Button>
          <Radio.Button value={2}>{t('graph-menu-item')}</Radio.Button>
        </Radio.Group>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: 30,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'start',
              marginBottom: '25px',
              marginLeft: '27px',
            }}
          >
            <Button
              variant="outlined"
              color="grey"
              onClick={handleRevalidateLinks}
              style={{ textTransform: 'none', marginBottom: '4px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ReloadOutlined
                  style={{ fontSize: '17px', marginRight: '3px' }}
                />
                {t('revalidate-links')}
              </div>
            </Button>

            <span
              style={{
                color: 'balck',
                fontSize: '14px',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                marginLeft: '5px',
                marginBottom: '14px',
              }}
            >
              {`${t('last-revalidation-date')} ${lastValidationDate}`}
            </span>
          </div>
          {menuItem === 1 ? (
            <div>
              <Tabs
                defaultActiveKey="1"
                items={ITEMS}
                className="org-settings-tabs"
                centered
              />
            </div>
          ) : (
            <div style={{ width: '100%', height: '500px' }} id="container" />
          )}
        </div>
      )}
    </div>
  );
};

export default ReferenceLinks;
