import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const syncGoogleDocTopicRequest = ({
  accountId,
  googleDriveIds,
}: {
  accountId: string;
  googleDriveIds: string[];
}) => {
  return axios
    .post(
      `/api/google-drive-integration/account/${accountId}/sync`,
      { googleDriveIds: googleDriveIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useSyncGoogleDocTopic() {
  const {
    mutateAsync: syncGoogleDocTopic,
    isPending: isLoadingSyncGoogleDocTopic,
    isSuccess: isSuccessLoadingSyncGoogleDocTopic,
    isError: isErrorLoadingSyncGoogleDocTopic,
  } = useMutation({
    mutationFn: syncGoogleDocTopicRequest,
  });

  return {
    syncGoogleDocTopic,
    isLoadingSyncGoogleDocTopic,
    isSuccessLoadingSyncGoogleDocTopic,
    isErrorLoadingSyncGoogleDocTopic,
  };
}