import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Thread } from '../../api-schemas/comment';

const createThreadRequest = (data: Thread) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/thread', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateThread(channelId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createThread,
    isPending: isCreatingThread,
    isSuccess: isSuccessCreatingThread,
  } = useMutation({
    mutationFn: createThreadRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['threads', channelId] });
    },
  });

  return {
    createThread,
    isCreatingThread,
    isSuccessCreatingThread,
  };
}
