import { DraftTopicTree } from '../../api-schemas/topic';

export const createTopicTree = (
    draftTopicTree: DraftTopicTree,
    parentTopicId: string | null
  ) => {
    return draftTopicTree
      .filter((topic) => topic.parentTopicId === parentTopicId)
      .map((topic) => ({
        ...topic,
        children: createTopicTree(draftTopicTree, topic.topicId) ?? [],
      }));
  };
  