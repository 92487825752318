import React, { useEffect, useState, useContext } from 'react';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ProfileContext } from '../../../contexts/profile';
import { AccountContext } from '../../../contexts/account';
import ManageAccesses from '../../manage-accesses';
import { isInvitationEmailOk } from '../../../helpers/common';
import useCreateAccess from '../../../api-hooks/access/useCreateAccess';
import useUpdateAccess from '../../../api-hooks/access/useUpdateAccess';
import useGetAccesses from '../../../api-hooks/access/useGetAccesses';
import useDeleteAccess from '../../../api-hooks/access/useDeleteAccess';
import useInviteUser from '../../../api-hooks/access/useInviteUser';
import {
  CreateAccessRequestType,
  Access,
  Accesses,
} from '../../../api-schemas/access';

const AccountAccessesSettings = (props: any) => {
  const [accountId, setAccountId] = useState<string>(props.accountId);
  const [accesses, setAccesses] = useState<Accesses | null>(null);
  const [myAccess, setMyAccess] = useState<Access | null>(null);

  const { profileData } = useContext(ProfileContext);
  const { setUserRole } = useContext(AccountContext);

  const { t } = useTranslation();

  const { inviteUser } = useInviteUser(accountId);
  const { createAccess } = useCreateAccess(accountId);
  const { updateAccess } = useUpdateAccess(accountId);
  const { deleteAccess } = useDeleteAccess(accountId);
  const { accesses: allAccesses, getAccesses } = useGetAccesses(accountId);

  const findCurrentUserAccess = (accesses: Accesses) => {
    const myAccess = accesses.filter(
      (a: any) => a.email === profileData.email
    )[0];

    if (myAccess) {
      setMyAccess(myAccess);
      setUserRole(myAccess.action);
    }
  };

  useEffect(() => {
    if (props.accountId) {
      handleAccountIdChanged(props.accountId);
    }
    // eslint-disable-next-line
  }, [props.accountId]);

  useEffect(() => {
    if (props.accesses && profileData) {
      setAccesses(props.accesses);

      findCurrentUserAccess(props.accesses);
    }
    // eslint-disable-next-line
  }, [props.accesses, profileData]);

  useEffect(() => {
    if (allAccesses?.length) {
      if (props.isViewerAccess) {
        setAccesses(
          allAccesses.filter(
            (access) =>
              access.action === props.ROLES.VIEWER_READER &&
              ((props.type === 'USER' && access.principalType === null) ||
                access.principalType === props.type)
          )
        );
      } else {
        setAccesses(
          allAccesses.filter(
            (access) => access.action !== props.ROLES.VIEWER_READER
          )
        );
      }

      findCurrentUserAccess(allAccesses);
    }

    //eslint-disable-next-line
  }, [allAccesses]);

  const handleAccountIdChanged = (newAccountId: string) => {
    if (newAccountId && accountId !== newAccountId) {
      setAccountId(newAccountId);
    }
  };

  const hasPlanLimitationsToAddAccess = (
    newAccesses: CreateAccessRequestType[]
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    const allAccesses = [...accesses]?.concat(newAccesses);
    const nonViewerAccesses = allAccesses?.filter(
      (account) =>
        account.action !== props.ROLES.VIEWER_READER &&
        (account.principalType === 'USER' || account.principalType === null)
    );

    return !!(
      nonViewerAccesses &&
      props.plan.features.numberOfUsers !== -1 &&
      props.plan.features.numberOfUsers <= nonViewerAccesses?.length
    );
  };

  const hasPermissionToAddAccess = (newAccessesAction: string) => {
    return !(
      myAccess?.action === props.ROLES.ADMIN &&
      newAccessesAction === props.ROLES.OWNER
    );
  };

  const handleAddAccesses = async (
    newAccesses: CreateAccessRequestType[],
    newAccessesAction: string,
    setOpenUserLimitationDialog
  ) => {
    if (hasPlanLimitationsToAddAccess(newAccesses)) {
      setOpenUserLimitationDialog(true);

      return;
    }

    if (!hasPermissionToAddAccess(newAccessesAction)) {
      props.enqueueSnackbar(t('error.add-user-permission'), {
        variant: 'error',
      });

      return;
    }

    return await Promise.all(
      newAccesses.map(async (access) => {
        access.action = props.isViewerAccess
          ? props.ROLES.VIEWER_READER
          : newAccessesAction;

        const newAccess = {
          entityId: accountId,
          entityType: 'Account',
          action: props.isViewerAccess
            ? props.ROLES.VIEWER_READER
            : newAccessesAction,
          principalId: access.principalId,
          principalType: props.type || 'USER',
        };

        if (
          newAccess.principalId &&
          isInvitationEmailOk(newAccess.principalId)
        ) {
          const email = newAccess.principalId;

          newAccess.principalId = null;

          return await inviteUser({ email, data: newAccess })
            .then((addedAccess) => addedAccess)
            .catch(async (err) => {
              // eslint-disable-next-line
              throw { err: err, access: access };
            });
        } else {
          return await createAccess(newAccess);
        }
      })
    );
  };

  const handleAccessActionChanged = async (
    userId: string,
    newValue: string
  ) => {
    if (!accesses) return;

    const access = accesses.find((access) => access.principalId === userId);

    if (!access) return;

    if (access.action === newValue) return;

    if (
      myAccess?.action === props.ROLES.ADMIN &&
      (access.action === props.ROLES.OWNER || newValue === props.ROLES.OWNER)
    ) {
      props.enqueueSnackbar(t('error.role-change'), {
        variant: 'error',
      });

      return;
    } else if (myAccess?.action === props.ROLES.OWNER) {
      const accountOwners = accesses.filter(
        (access) => access.action === props.ROLES.OWNER
      );

      if (
        access.action === props.ROLES.OWNER &&
        newValue === props.ROLES.ADMIN &&
        accountOwners?.length === 1
      ) {
        props.enqueueSnackbar(t('error.account-owner-count'), {
          variant: 'error',
        });
        return;
      }
    }

    const newAccess = {
      entityId: accountId,
      entityType: 'Account',
      action: newValue,
      principalId: access.principalId,
      principalType: access.principalType,
    };

    await updateAccess(newAccess);
  };

  const handleRemoveAccessFromAccount = async (accessId: string) => {
    return deleteAccess(accessId)
      .then(() => true)
      .catch((err) => {
        throw err;
      });
  };

  return (
    <ManageAccesses
      tokens={props.tokens}
      account={props.account}
      accountId={accountId}
      isViewerAccess={props.isViewerAccess}
      entityType="Account"
      accesses={accesses}
      currentPlan={props.plan}
      handleAccessActionChanged={handleAccessActionChanged}
      ROLES={props.ROLES}
      handleAddAccesses={handleAddAccesses}
      handleRemoveAccess={handleRemoveAccessFromAccount}
      loadAccesses={getAccesses}
      handleTabChange={props.handleTabChange}
      entityTitle={props.accountTitle}
      title={props.title}
      type={props.type || 'USER'}
    />
  );
};

export default withSnackbar(AccountAccessesSettings);
