import { Tooltip } from '@mui/material';
import { Avatar } from 'antd';
import React from 'react';

const UserAvatar = ({ displayName, photoUrl, size }) => {
  return (
    <Tooltip arrow title={displayName}>
      <Avatar
        style={
          size === 'small'
            ? { width: 16, height: 16 }
            : size === 'medium'
              ? { width: 24, height: 24 }
              : { width: 32, height: 32 }
        }
        src={photoUrl}
      />
    </Tooltip>
  );
};

export default UserAvatar;
