import React, { useContext, useState, useEffect, Fragment } from 'react';
import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  SvgIcon,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  HistoryRounded,
  MoreVert as MoreVertIcon,
  Settings as SettingsIcon,
  Delete as DeleteOutlinedIcon,
  Publish as PublishOutlinedIcon,
} from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { DeleteTopicConfirmation } from '../../delete-confirmation';
import { topicService } from '../../../../services/topic';
import { changeRouteBaseOnEditContentMenues } from '../../../../helpers/route';
import {
  findPreviousTopic,
  findNextTopic,
  maxTopicOrderOfChild,
  calculateSEOScore,
  calculateReadabilityScore,
} from '../../../../helpers/common';
import { DocumentContext } from '../../../../contexts/document';
import TopicSelectorDialog from '../selector/dialog';
import DiscardButton from './discard-button';
import MetricsStatuses from '../../../metrics-analytics/metrics-statuses';
import config from 'react-global-configuration';

const styles: any = () => ({
  actionIcon: {
    fontSize: 'large',
    marginRight: '7px',
    height: '24px !important',
    width: '24px !important',
  },
  statusIcon: {
    marginTop: '7px!important',
    marginLeft: '7px!important',
  },
  addedIcon: {
    marginLeft: '0px!important',
    marginRight: '6px!important',
    marginTop: '5px!important',
    fontSize: '20px!important',
  },
  iconButton: {
    marginRight: '20px',
    position: 'absolute',
    right: '20px',
  },
  menuItem: { fontSize: '0.9rem' },
});

const TopicItemMenu = (props) => {
  const { classes, t } = props;
  const topicAPI = new topicService();
  const { setCopiedTopicParent, selectedDoc } = useContext(DocumentContext);
  const [openCopy, setOpenCopy] = useState<boolean>(false);
  const [topicLanguages, setTopicLanguages] = useState([
    props.node.languageCode,
  ]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
  const isDeleted: boolean = props.node.action === 'Deleted';
  const isAlreadyPublished: boolean =
    props.node.latestTopicVersionId === props.node.publishedTopicVersionId &&
    props.node.latestTopicVersionId !== null;
  const isNotPublishedYet: boolean = props.node.topicVersionNumber < 1.0;
  const isLanguageEqual: boolean = props.node.title !== null;
  const history = useHistory();
  const prevTopic: any = findPreviousTopic(props.node, props.topics);
  const nextTopic: any = findNextTopic(props.node, props.topics);
  const parentTopic = props.topics.find(
    (topic) => topic.topicId === props.node.parentTopicId
  );
  const parentOfParentTopic = parentTopic
    ? props.topics.find((topic) => topic.topicId === parentTopic.parentTopicId)
    : null;

  useEffect(() => {
    if (openDeleteDialog) {
      setLoadingLanguages(true);
    }
  }, [openDeleteDialog]);

  useEffect(() => {
    if (loadingLanguages && props.node.topicId)
      topicAPI
        .getTopicContentByTopicId(props.node.languageCode, props.node.topicId)
        .then((topicContent) => {
          props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          setTopicLanguages(topicContent.languages);
          setLoadingLanguages(false);
        });
    // eslint-disable-next-line
  }, [loadingLanguages]);

  const renderMetricStatus = () => {
    const topicMetric = props.node.metrics[props.metric.field];
    if (props.metric.type === 'Boolean') {
      if (topicMetric === props.metric.successValue) {
        return (
          <Tooltip title={`${topicMetric}`} arrow placement="top">
            <FiberManualRecordIcon style={{ color: '#28a745', width: 15 }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={`${topicMetric}`} arrow placement="top">
            <FiberManualRecordIcon style={{ color: '#dc3545', width: 15 }} />
          </Tooltip>
        );
      }
    } else {
      if (
        topicMetric >= props.metric.successValueStartRange &&
        topicMetric <= props.metric.successValueEndRange
      ) {
        return (
          <Tooltip title={`${topicMetric}`} arrow placement="top">
            <FiberManualRecordIcon style={{ color: '#28a745', width: 15 }} />
          </Tooltip>
        );
      } else if (
        props.metric.errorValueStartRange !== undefined &&
        topicMetric >= props.metric.errorValueStartRange &&
        ((props.metric.errorValueEndRange !== Infinity &&
          topicMetric <= props.metric.errorValueEndRange) ||
          props.metric.errorValueEndRange === 'Infinity')
      ) {
        return (
          <Tooltip title={`${topicMetric}`} arrow placement="top">
            <FiberManualRecordIcon style={{ color: '#dc3545', width: 15 }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={`${topicMetric}`} arrow placement="top">
            <FiberManualRecordIcon style={{ color: '#ffc107', width: 15 }} />
          </Tooltip>
        );
      }
    }
  };

  const seoOnClick = () => {
    props.onSelectNode(null, props.node, false);
    props.setSelectedSidePart('SEO');
  };

  const readabilityOnClick = () => {
    props.onSelectNode(null, props.node, false);
    props.setSelectedSidePart('readability');
  };

  const brokenLinksOnClick = () => {
    props.onSelectNode(null, props.node, false);
    props.setSelectedSidePart('RefLinks');
  };

  return (
    <Fragment>
      {!props.showMetricData ? (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop:
              !isDeleted &&
              !(isNotPublishedYet && isLanguageEqual) &&
              isAlreadyPublished
                ? 5
                : 0,
            marginRight:
              !isDeleted &&
              !(isNotPublishedYet && isLanguageEqual) &&
              isAlreadyPublished
                ? 30
                : 0,
          }}
        >
          {(props.selectedSidePart === 'SEO' ||
            props.selectedSidePart === 'readability') &&
          props.node.metrics ? (
            <span
              style={{
                display: 'flex',
                marginRight: isDeleted
                  ? 15
                  : isNotPublishedYet && isLanguageEqual
                    ? 20
                    : !isAlreadyPublished
                      ? 15
                      : 0,
                marginTop:
                  !isDeleted && isNotPublishedYet && isLanguageEqual ? 5 : 0,
              }}
            >
              <MetricsStatuses
                seoScore={calculateSEOScore(props.node?.metrics)}
                readabilityScore={calculateReadabilityScore(
                  props.node?.metrics
                )}
                seoOnClick={seoOnClick}
                readabilityOnClick={readabilityOnClick}
                selectedTopic={props.node}
                numberOfBrokenLinks={2}
                brokenLinksOnClick={brokenLinksOnClick}
              />
            </span>
          ) : null}
          <span>
            {isDeleted ? (
              <IconButton
                aria-label="Deleted topic"
                size="small"
                className={classes.iconButton}
              >
                <Tooltip title="Deleted" placement="top">
                  <SvgIcon
                    style={{
                      color: 'red',
                    }}
                    className={classes.statusIcon}
                  >
                    <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM6 7h4a1 1 0 0 1 .117 1.993L10 9H6a1 1 0 0 1-.117-1.993L6 7h4-4z"></path>
                  </SvgIcon>
                </Tooltip>
              </IconButton>
            ) : isNotPublishedYet ? (
              isLanguageEqual && (
                <IconButton
                  aria-label="Added"
                  size="small"
                  className={classes.iconButton + ' added-icon'}
                >
                  <Tooltip title="Added" placement="top">
                    <SvgIcon
                      style={{
                        color: 'green',
                      }}
                      className={classes.addedIcon}
                    >
                      <path d="M9 9h6v6H9z"></path>
                      <path d="M19 17V7c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2zM7 7h10l.002 10H7V7z"></path>
                    </SvgIcon>
                  </Tooltip>
                </IconButton>
              )
            ) : (
              !isAlreadyPublished && (
                <IconButton
                  aria-label="Modified"
                  size="small"
                  className={classes.iconButton}
                >
                  <Tooltip title="Modified" placement="top">
                    <SvgIcon
                      style={{
                        color: '#fc9403',
                      }}
                      className={classes.statusIcon}
                    >
                      <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path>
                    </SvgIcon>
                  </Tooltip>
                </IconButton>
              )
            )}
          </span>
        </span>
      ) : props.metric &&
        props.node.metrics &&
        props.node.metrics[props.metric.field] !== null &&
        props.node.metrics[props.metric.field] !== undefined &&
        props.node.contentType !== 'Component' ? (
        <span style={{ display: 'flex' }}>{renderMetricStatus()}</span>
      ) : null}

      {props.showMenu !== false && (
        <Tooltip title="Options" placement="top">
          <IconButton
            aria-label="Add child topic"
            size="small"
            onClick={(event) => {
              props.handleMenu(
                event,
                props.hasChildren ? '1' : '2',
                props.node.id
              );
            }}
            style={{
              position: 'absolute',
              right: '2px',
              top: '4px',
            }}
            disabled={!isLanguageEqual}
          >
            <MoreVertIcon
              fontSize="small"
              color={props.isSelected ? 'primary' : 'inherit'}
            />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="long-menu"
        keepMounted
        open={
          props.hasChildren && props.nodeEl1 === props.node.id
            ? true
            : props.nodeEl2 === props.node.id
        }
        anchorEl={props.hasChildren ? props.anchorEl1 : props.anchorEl2}
        onClose={() => {
          props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!selectedDoc.processId && !selectedDoc.account.processId && (
          <MenuItem
            onClick={() => {
              props.onPublish([props.node]);
              props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            }}
            disabled={isAlreadyPublished && !isDeleted}
            className={classes.menuItem}
          >
            <PublishOutlinedIcon className={classes.actionIcon} />
            <Typography variant="inherit">
              {isDeleted ? 'Publish delete' : 'Publish topic'}
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
          className={classes.menuItem}
          disabled={isDeleted}
        >
          <DeleteOutlinedIcon className={classes.actionIcon} />
          <Typography variant="inherit">Delete topic</Typography>
        </MenuItem>
        <Divider light />
        <MenuItem
          onClick={(event) => {
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            props.onSelectNode(event, props.node, true);
            props.handleAddTopic(props.node, 'CKEditor5');
          }}
          disabled={isDeleted}
          className={classes.menuItem}
        >
          <PostAddIcon
            style={{
              fontSize: 'medium',
            }}
            className={classes.actionIcon}
          />
          <Typography variant="inherit">New child topic</Typography>
        </MenuItem>
        {config.get('environment') === 'stage' ? (
          <MenuItem
            onClick={(event) => {
              props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
              props.onSelectNode(event, props.node, true);
              props.handleAddTopic(props.node, 'Component');
            }}
            disabled={isDeleted}
            className={classes.menuItem}
          >
            <PostAddIcon
              style={{
                fontSize: 'medium',
              }}
              className={classes.actionIcon}
            />
            <Typography variant="inherit">New child page</Typography>
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={(event) => {
            props.onSelectNode(event, props.node, false);
            setCopiedTopicParent(props.node);
            setOpenCopy(true);
            props.onOpenCopyModal(true);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          }}
          disabled={isDeleted}
          className={classes.menuItem}
        >
          <FileCopyIcon className={classes.actionIcon} />
          <Typography variant="inherit">Copy topic here</Typography>
        </MenuItem>
        <Divider light />
        <MenuItem
          onClick={(event) => {
            props.onSelectNode(event, props.node, false);
            const MoveData = {
              topicId: props.node.topicId,
              targetParentTopicId: props.node.parentTopicId,
              minOrder: findPreviousTopic(prevTopic, props.topics)?.topicOrder,
              maxOrder: prevTopic?.topicOrder,
            };
            props.onMove(MoveData);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          }}
          disabled={isDeleted || prevTopic?.topicOrder === null}
          className={classes.menuItem}
        >
          <ArrowUpwardIcon
            style={{
              fontSize: 'medium',
            }}
            className={classes.actionIcon}
          />
          <Typography variant="inherit">Move up</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            props.onSelectNode(event, props.node, false);
            const MoveData = {
              topicId: props.node.topicId,
              targetParentTopicId: props.node.parentTopicId,
              minOrder: nextTopic?.topicOrder,
              maxOrder: findNextTopic(nextTopic, props.topics)?.topicOrder,
            };
            props.onMove(MoveData);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          }}
          disabled={isDeleted || nextTopic?.topicOrder === null}
          className={classes.menuItem}
        >
          <ArrowDownwardIcon
            style={{
              fontSize: 'medium',
            }}
            className={classes.actionIcon}
          />
          <Typography variant="inherit">Move down</Typography>
        </MenuItem>
        <Tooltip
          title={prevTopic ? 'Convert to a child of ' + prevTopic?.title : ''}
          arrow
        >
          <MenuItem
            onClick={(event) => {
              props.onSelectNode(event, props.node, false);
              const MoveData = {
                topicId: props.node.topicId,
                targetParentTopicId: prevTopic?.topicId,
                minOrder: maxTopicOrderOfChild(prevTopic),
                maxOrder: null,
              };
              props.onMove(MoveData);
              props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            }}
            disabled={isDeleted || prevTopic?.topicOrder === null}
            className={classes.menuItem}
          >
            <ArrowForwardIcon
              style={{
                fontSize: 'medium',
              }}
              className={classes.actionIcon}
            />
            <Typography variant="inherit">Indent</Typography>
          </MenuItem>
        </Tooltip>
        <Tooltip
          title={
            parentOfParentTopic && parentOfParentTopic.parentTopicId !== null
              ? 'Convert to a child of ' + parentOfParentTopic.title
              : !parentOfParentTopic
                ? ''
                : 'Move to root'
          }
          arrow
        >
          <MenuItem
            onClick={(event) => {
              props.onSelectNode(event, props.node, false);
              const MoveData = {
                topicId: props.node.topicId,
                targetParentTopicId: parentOfParentTopic?.topicId,
                minOrder: parentTopic?.topicOrder,
                maxOrder: findNextTopic(parentTopic, props.topics)?.topicOrder,
              };
              props.onMove(MoveData);
              props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            }}
            disabled={
              isDeleted ||
              (props.homeTopic &&
                props.node.parentTopicId === props.homeTopic.topicId)
            }
            className={classes.menuItem}
          >
            <ArrowBackIcon
              style={{
                fontSize: 'medium',
              }}
              className={classes.actionIcon}
            />
            <Typography variant="inherit">Outdent</Typography>
          </MenuItem>
        </Tooltip>

        <Divider light />
        <MenuItem
          onClick={() => {
            props.onRevert(
              props.node.topicId,
              props.node.latestTopicVersionId,
              props.node.languageCode
            );
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
          }}
          className={classes.menuItem}
          disabled={!isDeleted}
        >
          <RestoreFromTrashIcon className={classes.actionIcon} />
          <Typography variant="inherit">Undo delete</Typography>
        </MenuItem>
        <DiscardButton
          isAlreadyPublished={isAlreadyPublished}
          isDeleted={isDeleted}
          isNotPublishedYet={isNotPublishedYet}
          {...props}
        />
        <Divider light />
        <MenuItem
          onClick={() => {
            props.ontopicSelect(props.node);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            const returnedResult = changeRouteBaseOnEditContentMenues(
              'Settings',
              window.location.pathname.split('/')[4]
            );
            history.push(`${returnedResult[1]}`);
          }}
          className={classes.menuItem}
          disabled={isDeleted}
        >
          <SettingsIcon className={classes.actionIcon} />
          <Typography variant="inherit">Settings</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.ontopicSelect(props.node);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            const returnedResult = changeRouteBaseOnEditContentMenues(
              'History',
              window.location.pathname.split('/')[4]
            );
            history.push(`${returnedResult[1]}`);
          }}
          className={classes.menuItem}
          disabled={isDeleted}
        >
          <HistoryRounded className={classes.actionIcon} />
          <Typography variant="inherit">History</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.ontopicSelect(props.node);
            props.hasChildren ? props.onCloseEl1() : props.onCloseEl2();
            const returnedResult = changeRouteBaseOnEditContentMenues(
              'Feedback',
              window.location.pathname.split('/')[4]
            );
            history.push(`${returnedResult[1]}`);
          }}
          className={classes.menuItem}
          disabled={isDeleted}
        >
          <FeedbackIcon className={classes.actionIcon} />
          <Typography variant="inherit">Feedback</Typography>
        </MenuItem>
      </Menu>

      {openCopy && (
        <TopicSelectorDialog
          documentId={props.documentId}
          title={t('copy-topic-modal-title')}
          topics={props.topics}
          onCopyTopic={props.onCopyTopic}
          openCopy={openCopy}
          key={props.node.topicId}
          onClose={() => {
            setOpenCopy(false);
            props.onOpenCopyModal(false);
          }}
          okTitle={'Copy'}
        />
      )}
      <DeleteTopicConfirmation
        title={'Delete topic'}
        message={`Are you sure you want to delete ${
          props.node.title ? props.node.title : props.node.slug
        }?`}
        isOpen={openDeleteDialog}
        languages={topicLanguages}
        loadingLanguages={loadingLanguages}
        onDialogClosed={() => setOpenDeleteDialog(false)}
        onDialogConfirmed={(deleteAll) => {
          const success = props.onRemove(
            deleteAll,
            props.node,
            props.node.topicId || props.node.id
          );
          if (success) setOpenDeleteDialog(false);
        }}
      />
    </Fragment>
  );
};

export default withStyles(styles)(withTranslation()(TopicItemMenu));
