import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FlagFilled, CopyFilled, OpenAIOutlined } from '@ant-design/icons';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Button } from 'antd';
import { Select, Typography as AntTypography, Divider } from 'antd';
import { CgWebsite } from 'react-icons/cg';
import { GiBrain } from 'react-icons/gi';

import TextInput from '../../../inputs/text-input';
import useGetCurrentPlan from '../../../../api-hooks/plan/useGetCurrentPlan';

import '../styles.css';

const { Option } = Select;
const { Text, Title } = AntTypography;

const styles: any = (theme: any) => ({
  textField: {
    marginRight: '10px',
    marginBottom: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
});

function AccountAdvancedSettings(props: any) {
  const [accountConfiguration, setAccountConfiguration] = useState({});
  const [websiteUrl, setWebsiteUrl] = useState<string>('');
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState<boolean>(false);
  const [isDocumentExportEnabled, setIsDocumentExportEnabled] =
    useState<boolean>(false);
  const [showAISearchResult, setShowAISearchResult] = useState<boolean>(false);
  const [isEnterprisePlan, setEnterprisePlan] = useState<boolean>(false);
  const [aiSearchEngineModel, setAiSearchEngineModel] = useState<any>();

  const { currentPlan, isSuccessLoadingCurrentPlan } = useGetCurrentPlan(
    props.accountConfiguration.id
  );

  useEffect(() => {
    if (
      currentPlan &&
      (currentPlan.name === 'Enterprise' ||
        currentPlan.name === 'Enterprise Plus' ||
        currentPlan.name === 'Enterprise Old')
    ) {
      setEnterprisePlan(true);
    } else {
      setEnterprisePlan(false);
    }
  }, [isSuccessLoadingCurrentPlan]);

  useEffect(() => {
    setWebsiteUrl(
      props.accountConfiguration.websiteUrl
        ? props.accountConfiguration.websiteUrl
        : ''
    );
    setAccountConfiguration(props.accountConfiguration);
    setIsFeedbackEnabled(props.accountConfiguration.isFeedbackEnabled);
    setIsDocumentExportEnabled(
      props.accountConfiguration.isDocumentExportEnabled
    );
    setShowAISearchResult(props.accountConfiguration.showAISearchResult);
    setAiSearchEngineModel(props.accountConfiguration.aiSearchEngineModel);

    // eslint-disable-next-line
  }, [props.accountConfiguration]);

  const handleWebsiteUrlChange = (event: any) => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );

    newAccountConfiguration.websiteUrl = event.target.value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const raiseAccountConfigurationChanged = (accountConfiguration: any) => {
    if (props.onAccountConfigurationChanged) {
      props.onAccountConfigurationChanged(accountConfiguration);
    }
  };

  const classes = props.classes;

  const { t } = useTranslation();

  const handleEnableFeedbackChanged = () => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );
    setIsFeedbackEnabled(!isFeedbackEnabled);
    newAccountConfiguration.isFeedbackEnabled = !isFeedbackEnabled;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleEnableDocumentExportChanged = () => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );
    setIsDocumentExportEnabled(!isDocumentExportEnabled);
    newAccountConfiguration.isDocumentExportEnabled = !isDocumentExportEnabled;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleShowAISearchResultChanged = () => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );
    setShowAISearchResult(!showAISearchResult);
    newAccountConfiguration.showAISearchResult = !showAISearchResult;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleAiSearchEngineChange = (value: any) => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );

    setAiSearchEngineModel(value);

    newAccountConfiguration.aiSearchEngineModel = value;

    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  return (
    <div>
      <div style={{ borderRadius: '5px', padding: '10px' }}>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div
            className={
              window.innerWidth > 675
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <CgWebsite
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div
            className={
              window.innerWidth > 675
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('website-url')}
            </Title>
            <Text>{t('website-url-help')}</Text>
            <TextInput
              style={{ width: '90%', marginRight: 10 }}
              field="websiteUrl"
              required={true}
              onChange={handleWebsiteUrlChange}
              value={websiteUrl}
              showError={false}
            />
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div
            className={
              window.innerWidth > 675
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <FlagFilled
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div
            className={
              window.innerWidth > 675
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('feedback')}
            </Title>
            <Text>{t('document-feedback-help')}</Text>
            <Select
              className="advanced-settings-select"
              style={{ marginTop: 10, display: 'block' }}
              onChange={() => handleEnableFeedbackChanged()}
              value={isFeedbackEnabled ? 1 : 0}
            >
              <Option value={1}>{t('enable-feedback')}</Option>
              <Option value={0}>{t('disable-feedback')}</Option>
            </Select>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div
            className={
              window.innerWidth > 675
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <CopyFilled
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div
            style={{ display: 'grid' }}
            className={
              window.innerWidth > 675
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('document-export')}
            </Title>
            <Text>{t('document-export-help')}</Text>
            <Select
              className="advanced-settings-select"
              value={isDocumentExportEnabled ? 1 : 0}
              style={{ marginTop: 10, display: 'block' }}
              onChange={() => handleEnableDocumentExportChanged()}
            >
              <Option value={1}>{t('enable-document-export')}</Option>
              <Option value={0}>{t('disable-document-export')}</Option>
            </Select>
          </div>
        </div>

        <Divider />

        <div style={{ display: 'flex' }}>
          <div
            className={
              window.innerWidth > 675
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <GiBrain
              style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
            />
          </div>

          <div
            style={{ display: 'grid', marginBottom: '20px' }}
            className={
              window.innerWidth > 675
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('ai-search-result')}
            </Title>
            <Text>{t('ai-search-result-help')}</Text>
            <Select
              className="advanced-settings-select"
              value={showAISearchResult ? 1 : 0}
              style={{ marginTop: 10, display: 'block' }}
              onChange={() => handleShowAISearchResultChanged()}
            >
              <Option value={1}>{t('enable-ai-search-result')}</Option>
              <Option value={0}>{t('disable-ai-search-result')}</Option>
            </Select>
          </div>
        </div>

        <div>
          <Divider />

          <div style={{ display: 'flex' }}>
            <div
              className={
                window.innerWidth > 675
                  ? 'settings-icon-container-show'
                  : 'settings-icon-container-hide'
              }
            >
              <OpenAIOutlined
                style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }}
              />
            </div>

            <div
              style={{ display: 'grid', marginBottom: '20px' }}
              className={
                window.innerWidth > 675
                  ? 'settings-detail-container-show-partly'
                  : 'settings-detail-container-show-full'
              }
            >
              <Title className="settings-item-title" level={4}>
                {t('ai-Search-engine-model')}
              </Title>
              <Text>{t('ai-Search-engine-model-help')}</Text>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '12px',
                  paddingInlineStart: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Brightness1Icon
                    style={{
                      width: '12px',
                      height: '12px',
                      marginRight: '3px',
                    }}
                  />
                  <Text>{t('ai-Search-engine-one-help')}</Text>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Brightness1Icon
                    style={{
                      width: '12px',
                      height: '12px',
                      marginRight: '3px',
                    }}
                  />
                  <Text>{t('ai-Search-engine-two-help')}</Text>
                </div>
              </div>
              {!isEnterprisePlan ? (
                <Text type="danger">{t('disabled-option-enterprise')}</Text>
              ) : null}
              <Select
                className="advanced-settings-select"
                value={aiSearchEngineModel}
                style={{ marginTop: 10, display: 'block' }}
                onChange={handleAiSearchEngineChange}
                disabled={!isEnterprisePlan}
              >
                <Option value="gpt-4">{t('ai-Search-engine-one')}</Option>
                <Option value="gpt-3.5-turbo">
                  {t('ai-Search-engine-two')}
                </Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.buttons}>
        <Button
          type="primary"
          size="large"
          onClick={props.handleSaveAccountSettings}
          className={classes.button}
          disabled={props.loading || props.disableSaveButton}
        >
          {props.loading ? (
            <CircularProgress
              style={{ width: 25, height: 25, color: '#fff' }}
            />
          ) : (
            t('save')
          )}
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(AccountAdvancedSettings);
