import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

import useCopyTopic from '../../../../../api-hooks/topic/useCopyTopic';
import {
  DraftTopicTree,
  DraftTopicTreeItem,
} from '../../../../../api-schemas/topic';
import TopicSelectorDialog from '../../../../../components/topic/tree/selector/dialog';

type CopyButtonPropsType = {
  documentId: string;
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
  draftTopicTree: DraftTopicTree;
  flatDraftTopicTree: DraftTopicTree;
};

const CopyButton = ({
  documentId,
  documentVersionId,
  draftTopicTreeItem,
  draftTopicTree,
  flatDraftTopicTree,
}: CopyButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const [openCopyTopicDialog, setOpenCopyTopicDialog] =
    useState<boolean>(false);

  const homeTopic = useMemo(
    () => flatDraftTopicTree.find((topic) => !topic.parentTopicId),
    [flatDraftTopicTree]
  );

  const { t } = useTranslation();

  const { copyTopic, isCopyingTopic } = useCopyTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
  });

  const handleCopy = (selectedTopicId: string, parentTopicId: string) => {
    const copiedTopicParentId = parentTopicId ?? homeTopic?.topicId;

    copyTopic({
      parentTopicId: copiedTopicParentId,
      id: selectedTopicId,
      documentVersionId,
    });
  };

  return (
    <Fragment>
      <Button
        onClick={() => setOpenCopyTopicDialog(true)}
        disabled={isDeleted || isCopyingTopic}
        type="text"
        style={{ display: 'flex', width: '100%' }}
      >
        <FileCopyIcon style={{ marginRight: 5 }} />

        <Typography>{t('copy-topic')}</Typography>
      </Button>

      {openCopyTopicDialog && (
        <TopicSelectorDialog
          documentId={documentId}
          title={t('copy-topic-modal-title')}
          topics={draftTopicTree}
          onCopyTopic={handleCopy}
          openCopy={openCopyTopicDialog}
          onClose={() => {
            setOpenCopyTopicDialog(false);
          }}
          okTitle={t('copy')}
        />
      )}
    </Fragment>
  );
};

export default CopyButton;
