import React, { Dispatch, useState } from 'react';

import CodeEditor from '../code-editor';
import TextInput from '../../../inputs/text-input';
import { useTranslation } from 'react-i18next';

type TComponentDataEditorProps = {
  component: any;
  setComponent: Dispatch<any>;
};

const ComponentDataEditor = ({ component, setComponent }: TComponentDataEditorProps) => {
  const [columnSize, setColumnSize] = useState<number>(component.data?.componentData?.layout?.baseColumnSize || 12);

  const { t } = useTranslation();

  const handleBaseColumnSizeChange = (value: string | undefined) => {
    if (value) {
      const newComponent = { ...component };

      if (newComponent['data']['componentData']['layout']) {
        newComponent['data']['componentData']['layout']['baseColumnSize'] = Number(value);
      } else {
        newComponent['data']['componentData']['layout'] = {
          baseColumnSize: Number(value),
        };
      }

      setComponent(newComponent);
      setColumnSize(Number(value));
    }
  };

  return (
    <div>
      <CodeEditor component={component} setComponent={setComponent} />
      <TextInput
        field="baseColumnSize"
        label={t('base-column-size')}
        tooltip={t('base-column-size-help-text')}
        value={columnSize}
        showError={false}
        type="number"
        min={1}
        max={12}
        onChange={(e) => handleBaseColumnSizeChange(e.target.value)}
        style={{ marginTop: 12 }}
      />
    </div>
  );
};

export default ComponentDataEditor;
