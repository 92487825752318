import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Query from 'query-string';
import ISO6391 from 'iso-639-1';
import { Col } from 'antd';

import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { withSnackbar } from 'notistack';
import { Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Assignment as ManageIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

import { DocumentService } from '../../../../services/document';
import { DocumentContext } from '../../../../contexts/document';
import { AccountContext } from '../../../../contexts/account';
import {
  getLanguageCode,
  getQueries,
  getVersionSlug,
} from '../../../../helpers/route';
import { DocumentRoles } from '../../../../helpers/roles';
import { TopicContext } from '../../../../contexts/topic';

const styles: any = (theme) => ({
  fullHeight: {
    height: '100%',
    margin: '0',
  },
  topicsContainer: {
    padding: '0 !important',
  },
  topicsViewerActions: {
    margin: '5px 10px 0 0',
  },
  topicItem: {
    padding: '5px',
  },
  select: {
    color: 'white!important',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
    padding: '4px!important',
    '& > div': {
      padding: '0 24px 0 0!important',
    },
    '& fieldset': {
      border: 'none!important',
    },
  },
  icon: {
    fill: 'white!important',
  },
  detailMenu: {
    maxWidth: 'fit-content!important',
  },
});

function DocumentVersionSelect(props) {
  const {
    selectedVersion,
    setSelectedVersion,
    selectedLanguage,
    setSelectedLanguage,
    selectedDoc,
    versions,
    setDocumentVersions,
  } = React.useContext(DocumentContext);
  const { userRole } = React.useContext(AccountContext);
  const { selectedTopic } = React.useContext(TopicContext);
  const { classes } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const documentService = new DocumentService();

  const [languages, setLanguages] = useState([]);
  const [selectedVersionId, setSelectedVersionId] = useState<
    string | undefined
  >('');
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('');
  const [query, setQuery] = useState({ ver: '', lang: '' });

  useEffect(() => {
    if (selectedLanguage && selectedLanguage.code !== selectedLanguageCode) {
      setSelectedLanguageCode(selectedLanguage.code);
    }
    // eslint-disable-next-line
  }, [selectedLanguage]);

  const processData = async (tempVersions) => {
    let query: any,
      versionInQuery: string | undefined,
      languageInQuery: string | undefined;

    const languages = selectedDoc.languages;

    const versionsArray = tempVersions || versions;

    if (props.location.search) {
      query = Query.parse(props.location.search);
      setQuery(query);
      versionInQuery = query.ver;
      languageInQuery = query.lang;
    }

    let selectedVersionId: string | undefined,
      selecteVersionTitle: string | undefined,
      selectedVersionSlug: string | undefined;

    let selectedLanguageCode = languages[0]?.languageCode;

    let foundVersion: boolean = false;
    let foundLanguage: boolean = false;

    if (versionInQuery) {
      selecteVersionTitle = versionInQuery;
      // find id for this version title
      // eslint-disable-next-line
      await versionsArray.map((item) => {
        if (item.slug === versionInQuery) {
          selectedVersionId = item.id;
          selectedVersionSlug = item.slug;
          selecteVersionTitle = item.slug;
          foundVersion = true;
        }
      });
    }
    if (!foundVersion || !versionInQuery) {
      // eslint-disable-next-line
      versionsArray.map((version) => {
        if (version.slug === selectedDoc.defaultDocumentVersionSlug) {
          selectedVersionId = version.id;
          selectedVersionSlug = version.slug;
          selecteVersionTitle = version.title;
        }
      });
    }
    // if a wrong language code is in url, the default language will be selected
    if (languageInQuery) {
      // eslint-disable-next-line
      languages.map((item) => {
        if (item.languageCode === languageInQuery) {
          selectedLanguageCode = item.languageCode;
          foundLanguage = true;
        }
      });
    }
    if (
      (!languageInQuery || !foundLanguage) &&
      selectedDoc.defaultLanguageCode
    ) {
      selectedLanguageCode = selectedDoc.defaultLanguageCode;
    }
    setLanguages(languages);
    setSelectedVersionId(selectedVersionId);
    setSelectedLanguage(null);
    let shouldUpdateSelectedVersion: boolean = false;
    if (!selectedVersion || selectedVersion.id !== selectedVersionId) {
      shouldUpdateSelectedVersion = true;
    }

    shouldUpdateSelectedVersion &&
      setSelectedVersion({
        id: selectedVersionId,
        title: selecteVersionTitle,
        slug: selectedVersionSlug,
      });

    setSelectedLanguage({
      id: selectedLanguageCode,
      code: selectedLanguageCode,
    });

    setSelectedLanguageCode(selectedLanguageCode);

    const queries: string | null = getQueries(
      {
        slug: selectedVersionSlug,
      },
      {
        code: selectedLanguageCode,
      }
    );

    const newHref =
      'https://' +
      window.location.hostname +
      '/' +
      window.location.pathname +
      (queries || '');
    if (newHref !== window.location.href) {
      props.history.replace(window.location.pathname + (queries || ''));
    }
  };

  useEffect(() => {
    if (selectedDoc) {
      let tempVersions = versions;
      const promiseVersions = documentService
        .getVersions(selectedDoc.id)
        .then((data) => {
          tempVersions = data;
          setDocumentVersions(data);
        })
        .catch((error) => {
          props.enqueueSnackbar(
            error.statusText
              ? t(`error.${error.statusText}`)
              : t('error.unknown'),
            {
              variant: 'error',
            }
          );
        });

      window.Promise.all([promiseVersions]).then(() => {
        processData(tempVersions);
      });
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  useEffect(() => {
    if (selectedVersion && selectedLanguage) {
      const currentVersionSlug: string | null = getVersionSlug();
      const currentLanguageCode: string | null = getLanguageCode();
      if (currentVersionSlug !== selectedVersion.slug) {
        const filteredVersions = versions.filter(
          (version) => version.slug === currentVersionSlug
        );
        if (filteredVersions.length) {
          setSelectedVersion(filteredVersions[0]);
        }
      }
      if (currentLanguageCode !== selectedLanguage.code) {
        const filteredLanguages: any[] = languages.filter(
          (language: any) => language.languageCode === currentLanguageCode
        );
        if (filteredLanguages.length) {
          setSelectedLanguage({
            id: filteredLanguages[0].languageCode,
            code: filteredLanguages[0].languageCode,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [window.location.href]);

  // #endregion

  // #region event handlers

  const handleDocumentVersionChanged = (args) => {
    let versionTitle: string | undefined, versionSlug: string | undefined;
    const newQuery: any = query;
    // eslint-disable-next-line
    versions.map((version) => {
      if (args.target.value === version.id) {
        versionTitle = version.title;
        versionSlug = version.slug;
      }
    });
    const versionId: string = args.target.value;
    if (args.target.value === 'manageVariants') {
      props.history.push(
        `/document/${selectedDoc.accountId}/${selectedDoc.id}/version`
      );
    } else if (versionId !== selectedVersionId) {
      newQuery.ver = versionSlug;
      setSelectedVersionId(versionId);
      setQuery(newQuery);

      const queries = getQueries({ slug: versionSlug }, selectedLanguage);

      setSelectedVersion({
        id: args.target.value,
        title: versionTitle,
        slug: versionSlug,
      });

      props.history.push(
        `/document/${selectedDoc.accountId}/${selectedDoc.id}/content${queries || ''}`
      );
    }
  };

  const handleDocumentLanguageChanged = (args) => {
    const languageCode = args.target.value;
    const newQuery = query;

    queryClient.invalidateQueries({
      queryKey: [
        'draftTopicTree',
        selectedTopic?.documentVersionId,
        languageCode,
      ],
    });

    queryClient.invalidateQueries({
      queryKey: ['draftTopic', 'silent', selectedTopic?.topicId, languageCode],
    });

    // eslint-disable-next-line
    if (languageCode === 'manageLanguages') {
      props.history.push(
        `/document/${selectedDoc.accountId}/${selectedDoc.id}/language`
      );
    } else if (languageCode !== selectedLanguageCode) {
      newQuery.lang = languageCode;
      i18n.changeLanguage(languageCode);
      setQuery(newQuery);
      setSelectedLanguageCode(languageCode);

      const queries = getQueries(selectedVersion, { code: languageCode });
      props.history.push(
        `/document/${selectedDoc.accountId}/${selectedDoc.id}/${selectedTopic?.topicId}/content${queries || ''}`
      );

      setSelectedLanguage({
        id: languageCode,
        code: languageCode,
      });
    }
  };

  // #region render methods

  return (
    <Fragment>
      {versions && versions.length > 1 && (
        <Col xs={24} sm={12} md={4} lg={2} className={classes.detailMenu}>
          <Select
            className={classes.select}
            value={selectedVersionId}
            disableUnderline
            onChange={handleDocumentVersionChanged}
            inputProps={{
              name: 'id',
              id: 'id',
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {versions.map((version) => (
              <MenuItem key={version.id} value={version.id}>
                {version.title}
              </MenuItem>
            ))}
            <Divider />
            {(userRole === DocumentRoles.ADMIN || userRole === 'OWNER') && (
              <MenuItem
                key="manageVariants"
                value="manageVariants"
                style={{ alignItems: 'left' }}
              >
                <ListItemIcon style={{ alignSelf: 'left' }}>
                  <ManageIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Variants"
                  style={{ alignContent: 'left' }}
                />
              </MenuItem>
            )}
          </Select>
        </Col>
      )}
      {languages && languages.length > 1 && (
        <Col xs={24} sm={12} md={4} lg={2} className={classes.detailMenu}>
          <Select
            className={classes.select}
            value={selectedLanguageCode}
            disableUnderline
            onChange={handleDocumentLanguageChanged}
            inputProps={{
              name: 'documentLanguageId',
              id: 'documentLanguageId',
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {languages.map((language: any) => (
              <MenuItem
                key={language.languageCode}
                value={language.languageCode}
              >
                {ISO6391.getName(language.languageCode)}
              </MenuItem>
            ))}
            <Divider />
            {(userRole === DocumentRoles.ADMIN || userRole === 'OWNER') && (
              <MenuItem key="manageLanguages" value="manageLanguages">
                <ListItemIcon>
                  <ManageIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Languages" />
              </MenuItem>
            )}
          </Select>
        </Col>
      )}
    </Fragment>
  );
}

export default withStyles(styles)(withSnackbar(DocumentVersionSelect));
