import React from 'react';
import { useTranslation } from 'react-i18next';
import OveralDescription from '../overall-description';
import AnalyticsTabs from '../analytics-tabs';
import { calculateSEOScore, getDocumentMetrics } from '../../../helpers/common';
import { getLanguageCode, getVersionSlug } from '../../../helpers/route';
import { Col } from 'antd';

const SEOAnalytics = ({
  data,
  handleChangeMetric,
  selectedTopic,
  selectedDoc,
  selectedSidePart,
}) => {
  const { t } = useTranslation();

  const score = selectedTopic
    ? calculateSEOScore(selectedTopic.metrics)
    : selectedDoc
      ? calculateSEOScore(
          getDocumentMetrics(selectedDoc, getVersionSlug(), getLanguageCode())
        )
      : 0;

  const settingsItem = window.location.pathname.split('/')[5];

  return (
    <div style={{ display: 'contents' }}>
      <Col
        xs={selectedSidePart && settingsItem !== 'Metrics' ? 24 : 12}
        style={{
          paddingRight: selectedSidePart ? 5 : 20,
          paddingLeft: selectedSidePart ? 0 : 20,
        }}
      >
        <OveralDescription
          score={score}
          text={
            selectedTopic?.metrics?.keyphraseLength === 0
              ? t('keyphrase-not-set')
              : t('seo-overview-text')
          }
          label={t('seo-overview-title')}
          className="seo"
          selectedTopic={selectedTopic}
          selectedSidePart={selectedSidePart}
        />
        <Col xs={24} style={{ display: 'flex' }}>
          <Col xs={24}>
            <AnalyticsTabs
              category="SEO"
              handleChangeMetric={handleChangeMetric}
              data={data}
              selectedSidePart={selectedSidePart}
            />
          </Col>
        </Col>
      </Col>
    </div>
  );
};

export default SEOAnalytics;
