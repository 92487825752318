import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Rating from '@mui/lab/Rating';
import Paper from '@mui/material/Paper';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ISO6391 from 'iso-639-1';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import useUpdateFeedbackStatus from '../../../api-hooks/feedback/useUpdateFeedbackStatus';

const useStyles: any = makeStyles((theme) => ({
  feedbackBox: {
    width: '100%',
  },
  firstRow: {
    marginBottom: 10,
    paddingTop: 10,
    alignItems: 'center',
    borderBottom: '1px solid #d3d1d1',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  nameBox: {
    fontSize: '16px',
    fontWeight: 700,
  },
  smallBox: {
    fontSize: '12px',
    color: '#979797',
  },
  infoBox: {
    backgroundColor: '#bdbdbd52',
    padding: '0 15px',
    marginTop: 10,
    display: 'flex',
  },
  info: {
    fontSize: 12,
    textAlign: 'left',
    display: 'inline-block',
    width: '100%',
  },
  titleBox: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
}));

const FeedbackBox = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [showMoreExpanded, setShowMoreExpanded] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const { updateFeedbackStatus, isUpdatingFeedbackStatus } =
    useUpdateFeedbackStatus();

  useEffect(() => {
    setIsClosed(props.isClosed);
  }, [props.isClosed]);

  const handleShowMoreExpandChange = () => {
    setShowMoreExpanded(!showMoreExpanded);
  };

  const handleChangeFeedbackState = (checked: boolean, id: string) => {
    const body = {
      id: id,
      status: checked ? 2 : 1,
    };

    setIsClosed(checked);

    updateFeedbackStatus(body).catch(() => {
      setIsClosed(!checked);
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <Paper elevation={2} className={classes.feedbackBox}>
        <Col xs={24} style={{ marginTop: '10px' }}>
          <Col xs={24} className="flex-resp">
            <Col xs={24} style={{ padding: '0 15px' }}>
              <Col xs={24} className={classes.firstRow}>
                <Col
                  xs={24}
                  lg={20}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <div>
                    <span className={classes.nameBox}>
                      {props.feedback.fullName
                        ? props.feedback.fullName
                        : t('user')}
                    </span>
                    {props.feedback.companyName ? (
                      <span
                        className={classes.smallBox}
                        style={{ marginLeft: 5 }}
                      >
                        ({props.feedback.companyName})
                      </span>
                    ) : null}
                    <span
                      className={classes.smallBox}
                      style={{ marginLeft: 10 }}
                    >
                      {props.feedback.country},{' '}
                      {new Date(props.feedback.createDateTime).toLocaleString()}
                    </span>
                  </div>
                  {props.feedback.rate ? (
                    <Rating
                      name="customized-empty"
                      style={{ marginLeft: 10 }}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      size="small"
                      value={props.feedback.rate}
                      max={5}
                      precision={1}
                      disabled
                    />
                  ) : null}
                </Col>
                <Col xs={24} lg={4} style={{ textAlign: 'right' }}>
                  <FormControlLabel
                    style={{ marginRight: '-5px' }}
                    label={isClosed ? t('open-feedback') : t('close-feedback')}
                    control={
                      <Tooltip
                        title={
                          isClosed
                            ? t<string>('open-feedback')
                            : t<string>('close-feedback')
                        }
                        arrow
                      >
                        <Switch
                          checked={isClosed}
                          disabled={isUpdatingFeedbackStatus}
                          onChange={(event) =>
                            handleChangeFeedbackState(
                              event.target.checked,
                              props.feedback.id
                            )
                          }
                          name="open-close"
                          color="primary"
                        />
                      </Tooltip>
                    }
                  />
                </Col>
              </Col>
              <Col xs={24} className={classes.titleBox}>
                {props.feedback.title}
              </Col>
              <Col xs={24}>{props.feedback.message}</Col>
            </Col>
          </Col>
          <Col xs={24} className={classes.infoBox}>
            <Accordion
              expanded={showMoreExpanded}
              style={{ width: '100%', background: 'none', boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() => handleShowMoreExpandChange()}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="expanded-summary-feedback"
              >
                <Col xs={24}>
                  <Col xs={6} className={classes.info}>
                    {t('topic')}: {props.feedback.topicTitle} (
                    {props.feedback.topicVersionNumber})
                  </Col>
                  <Col xs={6} className={classes.info}>
                    {t('version')}:{' '}
                    {
                      props.versions.filter(
                        (version) =>
                          version.id === props.feedback.documentVersionId
                      )[0].title
                    }
                  </Col>
                  <Col xs={6} className={classes.info}>
                    {t('language')}:{' '}
                    {ISO6391.getName(props.feedback.languageCode)}
                  </Col>
                  <Col xs={6} className={classes.info}></Col>
                </Col>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block', padding: '8px 0' }}>
                <Col xs={24}>
                  <Col xs={12} md={6} className={classes.info}>
                    {t('city')}: {props.feedback.city}
                  </Col>
                  <Col xs={12} md={6} className={classes.info}>
                    {t('browser')}: {props.feedback.browser}
                  </Col>
                  <Col xs={12} md={6} className={classes.info}>
                    {t('browser-version')}: {props.feedback.browserVersion}
                  </Col>
                  <Col xs={12} md={6} className={classes.info}>
                    {t('os')}: {props.feedback.os}
                  </Col>
                </Col>
                <Col xs={12} style={{ paddingTop: 20, paddingBottom: 10 }}>
                  {props.feedback.mobileVendor && (
                    <Col xs={12} md={6} className={classes.info}>
                      {t('mobile-vendor')}: {props.feedback.mobileVendor}
                    </Col>
                  )}
                  {props.feedback.mobileModel && (
                    <Col xs={12} md={6} className={classes.info}>
                      {t('mobile-model')}: {props.feedback.mobileModel}
                    </Col>
                  )}
                  {props.feedback.email && (
                    <Col xs={24} md={6} className={classes.info}>
                      {t('email')}: {props.feedback.email}
                    </Col>
                  )}
                  {props.feedback.phoneNumber && (
                    <Col xs={24} md={6} className={classes.info}>
                      {t('phone-number')}: {props.feedback.phoneNumber}
                    </Col>
                  )}
                </Col>
              </AccordionDetails>
            </Accordion>
          </Col>
        </Col>
      </Paper>
    </div>
  );
};

export default FeedbackBox;
