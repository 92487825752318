import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateAccountPlanRequest } from '../../api-schemas/account';

const updateAccountPlanRequest = (data: UpdateAccountPlanRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Account/Subscription', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountPlan(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountPlan,
    isPending: isUpdatingAccountPlan,
    isSuccess: isSuccessUpdatingAccountPlan,
  } = useMutation({
    mutationFn: updateAccountPlanRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'plan', accountId],
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });

      queryClient.invalidateQueries({
        queryKey: ['account', accountId],
      });
    },
  });

  return {
    updateAccountPlan,
    isUpdatingAccountPlan,
    isSuccessUpdatingAccountPlan,
  };
}
