import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { DraftTopic } from '../../api-schemas/topic';

const getDraftTopicRequest = ({ topicId, languageCode }: { topicId?: string; languageCode?: string }) => {
  if (!topicId || !languageCode) {
    return Promise.reject(new Error('Missing topicId or languageCode'));
  }

  return axios
    .get<DraftTopic>(`/api/Topic/Draft/${topicId}`, {
      params: { languageCode },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetDraftTopicSilently({
  topicId,
  languageCode,
}: {
  topicId?: string;
  languageCode?: string;
}) {
  const {
    data: draftTopic,
    refetch: getTopic,
    isFetching: isLoadingDraftTopic,
    isRefetching: isRefetchingDraftTopic,
    isError: isErrorLoadingDraftTopic,
    isSuccess: isSuccessLoadingDraftTopic,
  } = useQuery<DraftTopic>({
    staleTime: 0,
    queryKey: ['draftTopic', 'silent', topicId, languageCode],
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!topicId && !!languageCode,
    queryFn: () => getDraftTopicRequest({ topicId, languageCode }),
  });

  return {
    draftTopic,
    isLoadingDraftTopic,
    isRefetchingDraftTopic,
    isErrorLoadingDraftTopic,
    isSuccessLoadingDraftTopic,

    getTopic,
  };
}
