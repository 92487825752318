import React from 'react';
import { createTheme, alpha, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { Theme } from '@mui/material/styles';

import DocerAppBar from './components/appbar';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

const greyTheme = createTheme({
  palette: {
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
});

const muiTheme = createTheme(greyTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: greyTheme.palette.getContrastText(
              greyTheme.palette.grey[300]
            ),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: greyTheme.palette.text.primary,
            borderColor:
              greyTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${greyTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                greyTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                greyTheme.palette.text.primary,
                greyTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { variant: 'text', color: 'grey' },
          style: {
            color: greyTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                greyTheme.palette.text.primary,
                greyTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    palette: {
      primary: { main: '#1a73e8' },
      secondary: blue,
      success: green,
      warning: red,
    },
  },
});

const Layout = (props) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <div className="app-layout">
        <DocerAppBar />
        <div className="main">
          <div className="sidebar"></div>
          <div className="content">{props.children}</div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
