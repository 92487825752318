import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Variables } from '../../api-schemas/variable';

const getVariablesRequest = (accountId: string) => {
  return axios
    .get<Variables>(`/api/Variable`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetVariables(accountId: string) {
  const {
    data,
    refetch: getVariables,
    isFetching: isLoadingVariables,
    isSuccess: isSuccessLoadingVariables,
  } = useQuery<Variables>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['variables', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => (accountId ? getVariablesRequest(accountId) : []),
  });

  const variables = useMemo(() => data || [], [data]);

  return {
    variables,
    isLoadingVariables,
    isSuccessLoadingVariables,

    getVariables,
  };
}
