import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import DocerCKEditor from '../ck-editor';
import TopicSelectorDialog from '../tree/selector/dialog';
import { isNewTopic } from '../../../helpers/common';
import { DocumentContext } from '../../../contexts/document';
import { ProfileContext } from '../../../contexts/profile';
import { AccountContext } from '../../../contexts/account';
import { AccessContext } from '../../../contexts/access';
import ComponentSelector from '../../component-selector';
import VariableSelector from '../../variable-selector';
import { Variable } from '../../../api-schemas/variable';
import { TopicContext } from '../../../contexts/topic';

const TopicEditor = (props) => {
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [openTopicLink, setOpenTopicLink] = useState<boolean>(false);
  const [goingToOpenTopicLink, setGoingToOpenTopicLink] =
    useState<boolean>(false);
  const [editingTopicLinkData, setEditingTopicLinkData] = useState<any>(null);
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [isComponentsModalOpen, setIsComponentsModalOpen] =
    useState<boolean>(false);
  const [isVariablesModalOpen, setIsVariablesModalOpen] =
    useState<boolean>(false);

  const { setUserRole } = useContext(AccountContext);
  const { documentAccesses } = useContext(AccessContext);
  const { selectedDoc, users, setUsers } = useContext(DocumentContext);
  const { profileData } = useContext(ProfileContext);
  const { selectedTopic } = useContext(TopicContext);

  const { t } = props;

  const openComponentsListModal = () => {
    setIsComponentsModalOpen(true);
  };

  const openVariablesListModal = () => {
    setIsVariablesModalOpen(true);
  };

  const addTopicLinkOnClickFunctionality = () => {
    document
      .querySelectorAll<HTMLElement>('a.topic-link')
      .forEach((element, key) => {
        element.onclick = () => {
          setEditingTopicLinkData({
            topicId: element.getAttribute('topicid'),
            url: element.getAttribute('url'),
            title: element.getAttribute('title'),
            slug: element.getAttribute('url')?.split('?')[0].split('/')[1],
            headingId: element.getAttribute('url')?.split('#')[1],
            key: key,
          });
          setOpenTopicLink(true);
        };
      });
  };

  const updateTopicLinkData = (editingTopicLinkData) => {
    if (editingTopicLinkData) {
      const newElement = document.createElement('a');
      const tempElement = document.createElement('div');

      newElement.setAttribute('topicId', editingTopicLinkData.topicId);
      newElement.setAttribute('url', editingTopicLinkData.url);
      newElement.setAttribute('title', editingTopicLinkData.title);
      newElement.setAttribute('class', 'topic-link ck-widget');

      newElement.innerText = editingTopicLinkData.title;
      tempElement.innerHTML = document.querySelectorAll(
        '.ck-content.ck-editor__editable'
      )[0].innerHTML;

      tempElement
        .querySelectorAll<HTMLElement>('a.topic-link')
        .item(editingTopicLinkData.key)
        .replaceWith(newElement);

      props.handleTopicDataChange(tempElement.innerHTML, 'body');

      setOpenTopicLink(false);
    }
  };

  const makeTopicLinkList = (topics) => {
    let topicLinkList = topics ? [...topics] : [];

    const homeTopic = topicLinkList.filter(
      (topic) => topic.parentTopicId === null
    )[0];

    if (topicLinkList.length > 0) {
      //filter out topics that are new-but-not-save or those that don't have content in this language
      topicLinkList = topicLinkList.filter(
        (topic) =>
          (!isNewTopic(topicLinkList, topic.id) && topic.title !== null) ||
          topic.parentTopicId === null // do not filter home topic in other languages
      );

      // modify parentTopicId of the topics that their parent is not in the list=> set to homeTopic
      topicLinkList.map((topic, index) => {
        if (
          topicLinkList.find((item) => item.topicId === topic.parentTopicId) ===
          -1
        ) {
          const newTopic = topic;
          newTopic.parentTopicId = homeTopic.topicId;
          topicLinkList[index] = newTopic;

          return newTopic;
        } else return topic;
      });
    }

    return topicLinkList;
  };

  const isReader = () => {
    return users.find(
      (user) => user.principalId === profileData.id && user.action === 'READER'
    );
  };

  const handleAddComponent = (content: string, component: any) => {
    const order = Number(uuidv4().match(/\d/g).slice(0, 5).join(''));
    const latestComponentVersion = component.latestComponentVersion;
    const componentData = {
      id: uuidv4(),
      componentId: component.id,
      componentVersionNumber: latestComponentVersion.majorNumber,
      content: latestComponentVersion.data.content,
    };
    const contentComponentData = {
      id: uuidv4(),
      componentDataId: componentData.id,
      order,
      componentData: componentData,
    };

    editorInstance.execute(
      'component',
      `${component.id}_${contentComponentData.id}`,
      content
    );

    const newComponentBody = [
      ...selectedTopic!.componentBody,
      contentComponentData,
    ];

    props.handleTopicDataChange(newComponentBody, 'componentBody');
  };

  const handleAddVariable = (variable: Variable) => {
    editorInstance.execute('variable', variable.id, variable.title);
  };

  useEffect(() => {
    if (documentAccesses) {
      setUsers(documentAccesses);
      const user = documentAccesses.find(
        (access) => access.email === profileData?.email
      );
      if (user) {
        setUserRole(user.action);
      }
    }
    // eslint-disable-next-line
  }, [documentAccesses]);

  useEffect(() => {
    if (goingToOpenTopicLink) {
      if (!editingTopicLinkData) {
        const selection = editorInstance?.model.document.selection;
        const range = selection?.getFirstRange();

        if (range && range.getItems() && range.getItems()[0]) {
          setSelectedTitle(range.getItems()[0].data);
        } else {
          setSelectedTitle('');
        }
      }

      setOpenTopicLink(true);
    }
    // eslint-disable-next-line
  }, [goingToOpenTopicLink, editingTopicLinkData]);

  useEffect(() => {
    setTimeout(() => {
      addTopicLinkOnClickFunctionality();
    }, 1000);
    // eslint-disable-next-line
  }, [selectedTopic]);

  return (
    <div id="CKEditorWrapper" style={{ marginTop: '10px' }}>
      <TopicSelectorDialog
        documentId={selectedDoc?.id}
        title={t('topic-link-modal-title')}
        flatDraftTopicTree={props.flatDraftTopicTree}
        onAddTopicLink={(selectedTopic, editingTopicLinkData) => {
          if (selectedTopic) {
            editorInstance.execute(
              'insertTopicLink',
              editingTopicLinkData.url,
              editingTopicLinkData.title || selectedTopic.title,
              editingTopicLinkData.topicId ||
                selectedTopic.topicId ||
                selectedTopic.id
            );
          }
        }}
        openTopicLink={openTopicLink}
        onClose={() => {
          setGoingToOpenTopicLink(false);
          setOpenTopicLink(false);
          setEditingTopicLinkData(null);
        }}
        editingTopicLinkData={editingTopicLinkData}
        updateTopicLinkData={updateTopicLinkData}
        usedForTopicLink={true}
        topics={makeTopicLinkList(props.topics)} // filter out some topics
        okTitle={editingTopicLinkData ? 'Update' : 'Add'}
        selectedTitle={selectedTitle}
      />

      <DocerCKEditor
        topics={props.topics}
        editorInstance={editorInstance}
        setEditorInstance={setEditorInstance}
        setOpenTopicLink={setOpenTopicLink}
        setGoingToOpenTopicLink={setGoingToOpenTopicLink}
        handleTopicDataChange={props.handleTopicDataChange}
        isReader={isReader}
        openComponentsListModal={openComponentsListModal}
        openVariablesListModal={openVariablesListModal}
      />

      <Modal
        className={'component-selector-modal'}
        title="Components Modal"
        open={isComponentsModalOpen}
        footer={[
          <Button key={0} onClick={() => setIsComponentsModalOpen(false)}>
            Cancel
          </Button>,
        ]}
        afterClose={() => setIsComponentsModalOpen(false)}
        onCancel={() => setIsComponentsModalOpen(false)}
      >
        <ComponentSelector
          isDraggable={false}
          onSelectComponent={(content, component) => {
            if (content) {
              handleAddComponent(content, component);
              setIsComponentsModalOpen(false);
            }
          }}
        />
      </Modal>

      <Modal
        className={'variable-selector-modal'}
        title="Variables Modal"
        open={isVariablesModalOpen}
        footer={[
          <Button key={0} onClick={() => setIsVariablesModalOpen(false)}>
            Cancel
          </Button>,
        ]}
        afterClose={() => setIsVariablesModalOpen(false)}
        onCancel={() => setIsVariablesModalOpen(false)}
      >
        <VariableSelector
          onSelectVariable={(variable: Variable) => {
            if (variable) {
              handleAddVariable(variable);
              setIsVariablesModalOpen(false);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default withTranslation()(TopicEditor);
