import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAccountTokenRequest } from '../../api-schemas/account';

const createAccountTokenRequest = (data: CreateAccountTokenRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Account/Token', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateAccountToken(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createAccountToken,
    isPending: isCreatingAccountToken,
    isSuccess: isSuccessCreatingAccountToken,
  } = useMutation({
    mutationFn: createAccountTokenRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'tokens', accountId],
      });
    },
  });

  return {
    createAccountToken,
    isCreatingAccountToken,
    isSuccessCreatingAccountToken,
  };
}
