import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StructuredDataContext } from '../../../../contexts/structured-data-context';
import Component from '../classes/component';
import useGetComponentVersionWithId from '../../../../api-hooks/component/useGetComponentVersionWithId';

const PreviewSection = ({ selectedComponentVersion, order, component }) => {
  // eslint-disable-next-line
  const [componentData, setComponentData] = useState<any>(null);
  const [
    selectedComponentVersionInComponent,
    setSelectedComponentVersionInComponent,
  ] = useState<any>(null);

  const previousComponentDataRef = useRef<any>(null);
  const componentDataRef = useRef<any>(null);
  const previousSelectedComponentVersionRef = useRef<any>(null);
  const selectedComponentVersionRef = useRef<any>(null);

  const { context } = useContext(StructuredDataContext);

  const {
    componentVersion,
    isSuccessLoadingComponentVersion,
    isLoadingComponentVersion,
  } = useGetComponentVersionWithId(component.latestComponentVersionId);

  useEffect(() => {
    return () => {
      document.getElementById(`component-script-${order}`)?.remove();
      document.getElementById(`component-style-${order}`)?.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedComponentVersion) {
      setSelectedComponentVersionInComponent(selectedComponentVersion);
    } else if (component && isSuccessLoadingComponentVersion) {
      setSelectedComponentVersionInComponent(componentVersion);
    }
    // eslint-disable-next-line
  }, [selectedComponentVersion, isSuccessLoadingComponentVersion]);

  useEffect(() => {
    if (
      document.getElementById(`preview-section-${order}`) &&
      selectedComponentVersionInComponent &&
      context.currentUser &&
      context.currentTopic
    ) {
      previousSelectedComponentVersionRef.current = JSON.parse(
        JSON.stringify(selectedComponentVersionRef.current)
      );
      selectedComponentVersionRef.current = JSON.parse(
        JSON.stringify(selectedComponentVersionInComponent)
      );

      if (
        previousSelectedComponentVersionRef.current === null ||
        (selectedComponentVersionInComponent.title ===
          previousSelectedComponentVersionRef.current.title &&
          selectedComponentVersionInComponent.description ===
            previousSelectedComponentVersionRef.current.description &&
          JSON.stringify(selectedComponentVersionInComponent.data) !==
            JSON.stringify(previousSelectedComponentVersionRef.current.data))
      ) {
        document.getElementById(`component-script-${order}`)?.remove();
        document.getElementById(`component-style-${order}`)?.remove();

        if(!selectedComponentVersionInComponent.id)
          return;

        const component = new Component(
          {
            ...selectedComponentVersionInComponent.data,
            content: {
              type:
                window.componentsData && window.componentsData[order]
                  ? 'json'
                  : selectedComponentVersionInComponent.data.content.type,
              content:
                window.componentsData && window.componentsData[order]
                  ? window.componentsData[order]
                  : selectedComponentVersionInComponent.data.content.content
                      .replace(/\|\+/g, '')
                      .replace(/\\r/g, ''),
            },
          },
          order,
          context
        );

        const script = component.scriptCreator();
        const style = component.styleCreator();

        document.body.appendChild(script);
        document.body.appendChild(style);
      }
    }
    // eslint-disable-next-line
  }, [selectedComponentVersionInComponent, componentData, context]);

  useEffect(() => {
    if (selectedComponentVersionInComponent && selectedComponentVersion) {
      window.updateComponentDataFunctions[order] = (newComponentData) => {
        previousComponentDataRef.current = JSON.parse(
          JSON.stringify(componentDataRef.current)
        );
        window.componentsData[order] = newComponentData;
        componentDataRef.current = newComponentData;
        setComponentData(newComponentData);
      };
    }
    // eslint-disable-next-line
  }, [selectedComponentVersionInComponent, selectedComponentVersion]);

  if (isLoadingComponentVersion) {
    return (
      <span
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'cetner',
          justifyContent: 'center',
        }}
      >
        <CircularProgress style={{ width: 30, height: 30 }} />
      </span>
    );
  } else {
    return (
      <div
        id={`preview-section-${order}`}
        className={`preview-section-${order}`}
        style={{ marginBottom: 10 }}
      ></div>
    );
  }
};

export default PreviewSection;
