import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateVariableRequestType } from '../../api-schemas/variable';

const updateVariableRequest = (data: CreateVariableRequestType) => {
    const body = JSON.stringify(data);

    return axios.put('/api/Variable', body, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useUpdateVariable(accountId: string) {
    const queryClient = useQueryClient()

    const {
        mutateAsync: updateVariable,
        isPending: isUpdatingVariable,
        isSuccess: isSuccessUpdatingVariable
    } = useMutation({
        mutationFn: updateVariableRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['variables', accountId] });
        }
    });

    return {
        updateVariable,
        isUpdatingVariable,
        isSuccessUpdatingVariable
    }
}