import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsTab from './analytics-tab';
import AnalyticsTabItem from './analytics-tab-item';

const AnalyticsTabs = ({
  category,
  data,
  handleChangeMetric,
  selectedSidePart,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <AnalyticsTab
        title={`${t('errors')} (${data.errors.length})`}
        variant="error"
      >
        {data.errors.map((metric, index) => (
          <AnalyticsTabItem
            key={index}
            category={category}
            handleChangeMetric={handleChangeMetric}
            metric={metric}
            variant="error"
            selectedSidePart={selectedSidePart}
          />
        ))}
      </AnalyticsTab>
      <AnalyticsTab
        title={`${t('improvements')} (${data.improvements.length})`}
        variant="warning"
      >
        {data.improvements.map((metric, index) => (
          <AnalyticsTabItem
            key={index}
            category={category}
            handleChangeMetric={handleChangeMetric}
            metric={metric}
            variant="warning"
            selectedSidePart={selectedSidePart}
          />
        ))}
      </AnalyticsTab>
      <AnalyticsTab
        title={`${t('good-results')} (${data.goodResults.length})`}
        variant="success"
      >
        {data.goodResults.map((metric, index) => (
          <AnalyticsTabItem
            key={index}
            category={category}
            handleChangeMetric={handleChangeMetric}
            metric={metric}
            variant="success"
            selectedSidePart={selectedSidePart}
          />
        ))}
      </AnalyticsTab>
    </Fragment>
  );
};

export default AnalyticsTabs;
