import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAccountRequest } from '../../api-schemas/account';

const createAccountRequest = (data: CreateAccountRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Account', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateAccount() {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createAccount,
    isPending: isCreatingAccount,
    isSuccess: isSuccessCreatingAccount,
  } = useMutation({
    mutationFn: createAccountRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      queryClient.invalidateQueries({ queryKey: ['documents', true] });
    },
  });

  return {
    createAccount,
    isCreatingAccount,
    isSuccessCreatingAccount,
  };
}
