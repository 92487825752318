import config from 'react-global-configuration';

import { TokenDataSchema } from "../../api-schemas/google-auth";

const googleDocConfig = config.get('googleDocConfig');

const authEndpoint = 'https://accounts.google.com/o/oauth2/auth';
const tokenEndpoint = 'https://oauth2.googleapis.com/token';

const buildAuthUrl = (): string => {
  const params = new URLSearchParams({
    client_id: googleDocConfig.CLIENT_ID,
    redirect_uri: googleDocConfig.REDIRECT_URI,
    response_type: 'code',
    scope: googleDocConfig.SCOPE,
    access_type: 'offline',
    prompt: 'consent',
  });

  return `${authEndpoint}?${params.toString()}`;
};

const getToken = async (code: string): Promise<TokenDataSchema> => {
  const response = await fetch(tokenEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      code,
      client_id: googleDocConfig.CLIENT_ID,
      redirect_uri: googleDocConfig.REDIRECT_URI,
      grant_type: 'authorization_code',
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch the token');
  }

  return response.json();
};

const openAuthPopup = (
  url: string,
  name: string,
  width: number,
  height: number
): Promise<string | null> => {
  return new Promise((resolve) => {
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      url,
      name,
      `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`
    );

    if (!popup) {
      resolve(null);
      return;
    }

    const interval = setInterval(() => {
      try {
        if (popup.closed) {
          clearInterval(interval);
          resolve(null);
        }

        if (popup.location.href.includes(googleDocConfig.REDIRECT_URI)) {
          const urlParams = new URLSearchParams(popup.location.search);
          const code = urlParams.get('code');
          popup.close();
          clearInterval(interval);
          resolve(code);
        }
      } catch (error) {
        // Ignore cross-origin access errors
      }
    }, 500);
  });
};

const handleGoogleDriveSignIn = () => {
  window.location.href = buildAuthUrl();
};


export { buildAuthUrl, getToken, openAuthPopup, handleGoogleDriveSignIn };
