import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Plan } from '../../api-schemas/plan';

const getCurrentPlanRequest = (accountId: string) => {
  return axios
    .get<Plan>(`/api/account/${accountId}/plan`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetCurrentPlan(
  accountId: string,
  enabled: boolean = true
) {
  const {
    data,
    refetch: getCurrentPlan,
    isFetching: isLoadingCurrentPlan,
    isError: isErrorLoadingCurrentPlan,
    isSuccess: isSuccessLoadingCurrentPlan,
  } = useQuery<Plan>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['plan', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(enabled && accountId),
    queryFn: () => getCurrentPlanRequest(accountId),
  });

  const currentPlan = useMemo(() => data, [data]);

  return {
    currentPlan,
    isLoadingCurrentPlan,
    isErrorLoadingCurrentPlan,
    isSuccessLoadingCurrentPlan,

    getCurrentPlan,
  };
}
