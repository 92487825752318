import config from 'react-global-configuration';
import productionConfig from './production';
import stageConfig from './stage';

switch (window.location.hostname) {
  case 'editor-stage.sonat.com':
    config.set(stageConfig);
    break;
  case 'localhost':
    config.set(stageConfig);
    break;
  default:
    if (window.location.hostname.includes('.vashk')) {
      config.set(stageConfig);
    } else {
      config.set(productionConfig);
    }
    break;
}
