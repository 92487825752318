import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { AccountWebAssistantSettings } from '../../api-schemas/account';

const getAccountWebAssistantSettingsRequest = (accountId?: string) => {
  return axios
    .get<AccountWebAssistantSettings[]>(
      `/api/Account/${accountId}/web-assistant`,
      {
        params: { accountId },
        headers: {
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useGetAccountWebAssistantSettings(accountId: string) {
  const {
    data,
    refetch: getAccountWebAssistantSettings,
    isFetching: isLoadingAccountWebAssistantSettings,
    isSuccess: isSuccessLoadingAccountWebAssistantSettings,
  } = useQuery<AccountWebAssistantSettings[]>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'webAssistantSettings', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId),
    queryFn: () => getAccountWebAssistantSettingsRequest(accountId),
  });

  const accountWebAssistantSettings = useMemo(() => data, [data]);

  return {
    accountWebAssistantSettings,
    isLoadingAccountWebAssistantSettings,
    isSuccessLoadingAccountWebAssistantSettings,

    getAccountWebAssistantSettings,
  };
}
