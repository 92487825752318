import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAccessRequestType } from '../../api-schemas/access';

const inviteUserRequest = ({
  email,
  data,
}: {
  email: string;
  data: CreateAccessRequestType;
}) => {
  const body = JSON.stringify(data);

  return axios
    .post(`/api/Access/invite`, body, {
      params: { email },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useInviteUser(entityId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: inviteUser,
    isPending: isInvitingUser,
    isSuccess: isSuccessInvitingUser,
  } = useMutation({
    mutationFn: inviteUserRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accesses', entityId] });
    },
  });

  return {
    inviteUser,
    isInvitingUser,
    isSuccessInvitingUser,
  };
}
