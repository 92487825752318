import React, { useState, useEffect, useContext } from 'react';
import { InputLabel, CircularProgress, Tooltip, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import DocumentTheme from './theme';
import config from 'react-global-configuration';
import { Col, Form, Row, Button } from 'antd';
import Carousel from 'react-elastic-carousel';
import { Typography, Divider } from 'antd';
import { TbLetterT, TbLetterS } from 'react-icons/tb';
import DescriptionIcon from '@mui/icons-material/Description';
import { useHistory, useParams } from 'react-router-dom';

import { AccountContext } from '../../../../contexts/account';
import TextInput from '../../../inputs/text-input';
import TextAreaInput from '../../../inputs/text-area-input';
import { shortenTitle } from '../../../../helpers/common';
import { DocumentService } from '../../../../services/document';
import { ProfileContext } from '../../../../contexts/profile';
import { convertToSlug, emptyGuid } from '../../../../helpers/common';
import { formError, localizeKey } from '../../../../helpers/localize-key';

const { Title, Text } = Typography;

const styles: any = () => ({
  errorBox: {
    color: 'red',
    marginTop: 5,
    display: 'block',
  },
  inputLabel: {
    marginBottom: 10,
  },
  languageBox: {
    display: 'flex',
  },
  template: {
    borderStyle: 'solid',
    maxWidth: '171px',
    borderRadius: '2px',
    borderWidth: '1px',
    borderColor: 'grey',
    margin: 'auto',
    '&:hover': {
      borderColor: '#1a73e8',
      boxShadow: '0 0 2px #1a73e8',
    },
  },
  templateName: {
    textAlign: 'center',
    marginTop: '5px',
  },
});

const GeneralSettings = (props) => {
  const { classes } = props;
  const { t, i18n } = useTranslation();
  const documentService = new DocumentService();
  const history = useHistory();

  const [firstModel, setFirstModel] = useState<any>(null);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const { documentId }: { documentId?: string } = useParams();

  const mode = props.mode ?? window.location.pathname.split('/')[2];

  const { profileData } = useContext(ProfileContext);
  const { selectedAccount, selectedLanguage } = useContext(AccountContext);

  useEffect(
    () => {
      if (
        props.accountsData &&
        profileData &&
        !firstModel &&
        (props.mode ||
          props.selectedDoc ||
          (window.location.pathname === '/document/create' &&
            props.selectedDoc === undefined))
      ) {
        onLoadEditor();
      }
    },
    // eslint-disable-next-line
    [props.accountsData, profileData, props.selectedDoc]
  );

  useEffect(() => {
    setLoadingTemplates(true);
    documentService
      .getDocumentTemplates()
      .then((templates) => {
        setTemplates(templates);
        setLoadingTemplates(false);
      })
      .catch((error) => {
        setLoadingTemplates(false);
        props.enqueueSnackbar(
          error.statusText
            ? t(`error.${localizeKey(error.statusText)}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === 'create' && selectedAccount && props.model) {
      const newModel = props.model;

      newModel.account = selectedAccount;
      newModel.accountRef = selectedAccount.id;
      newModel.accountId = selectedAccount.id;
      props.setModel((prevState) => {
        return { ...prevState, ...newModel };
      });
    }
  }, [selectedAccount]);

  const onLoadEditor = () => {
    i18n.changeLanguage(navigator.language);
    if (documentId) editForm();
    else createForm();
  };

  const editForm = () => {
    const docData = props.selectedDoc;
    const foundAccounts = props.accountsData.filter(
      (account) => account.id === docData.account.id
    );
    if (!foundAccounts.length) {
      props.enqueueSnackbar(t('error.account-not-found'), {
        variant: 'error',
      });
      props.history.push('/');
    }
    setFirstModel({ ...docData });
    props.setLoading(false);
  };

  const createForm = () => {
    const newModel = generateNewModel();
    try {
      if (selectedAccount) {
        newModel.account = selectedAccount;
        newModel.accountId = selectedAccount.id;
      } else {
        const myAccount = props.accountsData.filter(
          (account) => account.email === profileData.email
        )[0];
        newModel.accountId =
          props.accountsData.length === 0
            ? null
            : myAccount
              ? myAccount.id
              : props.accountsData[0].id;
        newModel.account =
          props.accountsData.length === 0
            ? null
            : myAccount
              ? myAccount
              : props.accountsData[0];
      }

      newModel.docTemplateId = selectedTemplate ? selectedTemplate.id : null;
      newModel.slug = 'untitled-document';
      newModel.title = 'Untitled Document';
    } catch (error) {
      props.enqueueSnackbar(t('error.account-not-found'), {
        variant: 'error',
      });
      props.history.push('/');
    }
    props.setModel(newModel);
    setFirstModel({ ...newModel });
    props.setLoading(false);
  };

  const generateNewModel = () => ({
    id: emptyGuid(),
    title: '',
    accountRef: '',
    slug: '',
    accountId: '',
    account: null,
    description: '',
    defaultLanguageCode: selectedLanguage,
    isFeedbackEnabled: null,
    docTemplateId: null,
    style: null,
    theme: 'Manual',
  });

  const handleTitleChanged = (event) => {
    const value = event.target.value;
    const newModel = props.model;
    const slug = convertToSlug(value);
    newModel.title = value;
    newModel.slug = slug;
    newModel.slug2 = slug;
    if (newModel.id === emptyGuid()) newModel.slug = convertToSlug(value);
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleTemplateChanged = (template) => {
    const newModel = props.model;
    newModel.docTemplateId = template.id;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleSlugChanged = (event) => {
    const value = event.target.value;
    const newModel = props.model;
    const slug = convertToSlug(value);
    newModel.slug = slug;
    newModel.slug2 = slug;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    const newModel = props.model;
    newModel.description = value;
    props.setModel((prevState) => {
      return { ...prevState, ...newModel };
    });
  };

  const getErrorMessage = (field: string) =>
    props.errors &&
    props.errors.length > 0 &&
    props.errors.some((err) => err.field === field)
      ? t(`error.form.${formError(props.errors, field)}`)
      : undefined;

  const generateTemplates = () => {
    return [...templates].map((template, index) => (
      <div key={index}>
        <Tooltip arrow title={template.description}>
          <Paper
            variant="outlined"
            className={classes.template}
            onClick={() => {
              setSelectedTemplate(template);
              handleTemplateChanged(template);
            }}
            style={{
              borderColor:
                selectedTemplate && selectedTemplate.id === template.id
                  ? '#1a73e8'
                  : '',
            }}
          >
            <DocumentTheme document={template}></DocumentTheme>
          </Paper>
        </Tooltip>
        <div className={classes.templateName}>
          <Tooltip arrow title={template.title}>
            <span
              style={{
                color:
                  selectedTemplate && selectedTemplate.id === template.id
                    ? '#1a73e8'
                    : '',
              }}
            >
              {shortenTitle(template.title, 15)}
            </span>
          </Tooltip>{' '}
          <a
            style={{
              color: '#1a73e8',
            }}
            href={`${config.get('viewerURL')}${
              template.account.slug
            }/${template.slug}/${
              template.defaultDocumentVersionSlug
            }?lang=${template.defaultLanguageCode}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip title={'View live'}>
              <OpenInNewIcon
                style={{
                  width: 15,
                  height: 15,
                  marginBottom: '-3px',
                }}
                id="openInNewIcon"
              />
            </Tooltip>
          </a>
        </div>
      </div>
    ));
  };

  const renderCreateForm = () => {
    if (props.selectedDoc) {
      return (
        <div
          style={{
            border: '1px solid #f0f0f0',
            borderRadius: '5px',
            padding: '10px',
            marginBottom: 15,
          }}
        >
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ width: '10%' }}>
              <TbLetterT
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>
            <div
              style={{ width: '90%', display: 'flex', flexDirection: 'column' }}
            >
              <Title className="settings-item-title" level={4}>
                {t('title')}
              </Title>
              <Text>{t('title-description')}</Text>
              <TextInput
                id="title"
                field="Title"
                style={{ width: '95%', marginTop: 10 }}
                value={props.model ? props.model.title : ''}
                onChange={handleTitleChanged}
                required={true}
                showError={true}
                getErrorMessage={getErrorMessage}
              />
            </div>
          </div>
          <Divider />
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ width: '10%' }}>
              <TbLetterS
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>
            <div
              style={{ width: '90%', display: 'flex', flexDirection: 'column' }}
            >
              <Title className="settings-item-title" level={4}>
                {t('slug')}
              </Title>
              <Text>{t('document-slug-help')}</Text>

              <Button
                variant="dashed"
                style={{ width: '230px', marginTop: '10px' }}
                onClick={() =>
                  history.push(
                    `/document/${selectedAccount.id}/${props.selectedDoc.id}/version?lang=${selectedLanguage}`
                  )
                }
              >
                {t('nav-to-variant-setting')}
              </Button>
            </div>
          </div>
          <Divider />
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ width: '10%' }}>
              <DescriptionIcon
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>
            <div
              style={{ width: '90%', display: 'flex', flexDirection: 'column' }}
            >
              <Title className="settings-item-title" level={4}>
                {t('description')}
              </Title>
              <Text>{t('description-description')}</Text>
              <TextAreaInput
                id="description"
                showCount={true}
                field="Description"
                value={props.model ? props.model.description : ''}
                onChange={handleDescriptionChange}
                required={false}
                maxLength={175}
                showError={false}
                getErrorMessage={getErrorMessage}
                rows={4}
                style={{ marginTop: 10, width: '95%' }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Form layout="vertical">
            <input
              type="hidden"
              name="documentId"
              value={props.model ? props.model.id : ''}
            />
            <Row style={{ padding: 10 }}>
              <Col xs={24} md={12} style={{ padding: 5 }}>
                <TextInput
                  style={{ width: 'calc(100% - 5px)' }}
                  id="title"
                  field="Title"
                  label={t('doceditor.title')}
                  value={props.model ? props.model.title : ''}
                  onChange={handleTitleChanged}
                  required={true}
                  showError={true}
                  getErrorMessage={getErrorMessage}
                />
              </Col>
              <Col xs={24} md={12} style={{ padding: 5 }}>
              <TextInput
                  style={{ width: 'calc(100% - 5px)' }}
                  field="Slug"
                  label={t('slug')}
                  tooltip={t('document-slug-help')}
                  value={props.model ? props.model.slug : ''}
                  onChange={handleSlugChanged}
                  required={true}
                  showError={true}
                  getErrorMessage={getErrorMessage}
                />
              </Col>
              <Col xs={24} style={{ padding: 5 }}>
                <TextAreaInput
                  id="description"
                  showCount={true}
                  field="Description"
                  label={t('description')}
                  value={props.model ? props.model.description : ''}
                  onChange={handleDescriptionChange}
                  required={false}
                  maxLength={175}
                  showError={false}
                  getErrorMessage={getErrorMessage}
                />
              </Col>
              {!props.selectedDoc && templates.length > 0 && (
                <Col xs={24} style={{ padding: 5 }}>
                  <InputLabel className="label" htmlFor="theme">
                    {'Template'}
                  </InputLabel>
                  <Col xs={24} style={{ marginTop: '5px' }}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-expect-error*/}
                    <Carousel
                      itemsToShow={3}
                      itemsToScroll={3}
                      showArrows={false}
                      isRTL={false}
                    >
                      <div>
                        <Paper
                          variant="outlined"
                          onClick={() => {
                            setSelectedTemplate(null);
                            handleTemplateChanged({ id: null });
                          }}
                          className={classes.template}
                          style={{
                            borderColor:
                              selectedTemplate === null ? '#1a73e8' : '',
                          }}
                        >
                          <div
                            style={{
                              minHeight: '169px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <AddIcon
                              style={{
                                width: 50,
                                height: 50,
                                color: '1890ff',
                              }}
                              id="blankDocumentIcon"
                            />
                          </div>
                        </Paper>
                        <div className={classes.templateName}>
                          <span
                            style={{
                              color: selectedTemplate === null ? '#1a73e8' : '',
                            }}
                          >
                            Blank
                          </span>{' '}
                        </div>
                      </div>
                      {generateTemplates()}
                    </Carousel>
                  </Col>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      );
    }
  };

  const contents =
    props.loading ||
    loadingTemplates ||
    !props.accountsData ||
    !props.model ||
    !firstModel ? (
      <div style={{ position: 'relative' }}>
        <CircularProgress style={{ marginLeft: '50%', marginTop: '20px' }} />
      </div>
    ) : (
      renderCreateForm()
    );

  return <div>{contents}</div>;
};

export default withStyles(styles)(withSnackbar(GeneralSettings));
