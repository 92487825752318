import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-full-gallery';
import { ImageInterface } from 'react-full-gallery/interfaces';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import config from 'react-global-configuration';

import useGetAccountImages from '../../api-hooks/account/useGetAccountImages';
import { DocumentService } from '../../services/document';
import DocerBreadcrumbs from '../docer-breadcrumbs';
import { AccountDto } from '../../types/data-types';
import { dataURItoBlob, showErrorMessage } from '../../helpers/common';
import useAddAccountImages from '../../api-hooks/account/useAddAccountImages';
import useUpdateAccountImages from '../../api-hooks/account/useUpdateAccountImages';
import useDeleteAccountImages from '../../api-hooks/account/useDeleteAccountImages';

import './styles.css';

const styles: any = (theme) => ({
  container: {
    width: 'auto',
    height: 'calc(100% - 40px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 970,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

const documentServices = new DocumentService();

interface EntityInterface {
  id: string;
  title: string;
  slug: string;
  accountId: string;
  account?: AccountDto;
}

interface ImageGalleryInterface {
  entityType: 'Account' | 'Document';
  entity: EntityInterface;
  classes: any;
  showBreadcrumb?: boolean;
}

const ImageGallery: React.FC<ImageGalleryInterface> = ({
  entityType,
  entity,
  classes,
  showBreadcrumb,
}) => {
  const [images, setImages] = useState<ImageInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const { getAccountImages } = useGetAccountImages(entity.id);
  const { addAccountImages } = useAddAccountImages(entity.id);
  const { updateAccountImages } = useUpdateAccountImages(entity.id);
  const { deleteAccountImages } = useDeleteAccountImages(entity.id);

  useEffect(() => {
    if (entity && entity.id) {
      setLoading(true);

      getImages();
    }
    // eslint-disable-next-line
  }, [entity]);

  const getImages = async () => {
    switch (entityType) {
      case 'Account':
        await getAccountImages()
          .then((images) => {
            setLoading(false);

            if (images.data) {
              setImages(
                images.data.map((image) => ({
                  ...image,
                  originalUrl: `${image.originalUrl}?dateTime=${new Date().getTime()}`,
                }))
              );
            }
          })
          .catch(() => {
            setLoading(false);
            showErrorMessage(enqueueSnackbar, t('error.unknown'));
          });
        break;
      case 'Document':
        await documentServices
          .getImages(entity.accountId, entity.id)
          .then((images) => {
            setLoading(false);
            setImages(
              images.map((image) => ({
                ...image,
                originalUrl: `${image.originalUrl}?dateTime=${new Date().getTime()}`,
              }))
            );
          })
          .catch(() => {
            setLoading(false);
            showErrorMessage(enqueueSnackbar, t('error.unknown'));
          });
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const handleRemoveImages = async (removedImages: ImageInterface[]) => {
    if (removedImages.length) {
      const imageIds = removedImages.map((removedImage) => removedImage.id);
      const data = {
        imageIds,
      };
      switch (entityType) {
        case 'Account':
          await deleteAccountImages({ accountId: entity.id, data })
            .then(async () => {
              await getImages();
            })
            .catch(() => {
              showErrorMessage(enqueueSnackbar, t('error.unknown'));
            });
          break;
        case 'Document':
          await documentServices
            .deleteImages(entity.id, data)
            .then(async () => {
              await getImages();
            })
            .catch(() => {
              showErrorMessage(enqueueSnackbar, t('error.unknown'));
            });
          break;
        default:
          break;
      }
    }
  };

  const handleUpdateImages = async (modifiedImages: ImageInterface[]) => {
    if (modifiedImages.length) {
      const formData = new FormData();

      for (const modifiedImage of modifiedImages) {
        const blobImage = dataURItoBlob(modifiedImage.originalUrl);

        formData.append('Upload', blobImage, modifiedImage.name);
        formData.append('Id', modifiedImage.id);
      }

      switch (entityType) {
        case 'Account':
          await updateAccountImages({ accountId: entity.id, data: formData })
            .then(async () => {
              await getImages();
            })
            .catch(() => {
              showErrorMessage(enqueueSnackbar, t('error.unknown'));
            });
          break;
        case 'Document':
          await documentServices
            .updateImages(entity.accountId, entity.id, formData)
            .then(async () => {
              await getImages();
            })
            .catch(() => {
              showErrorMessage(enqueueSnackbar, t('error.unknown'));
            });
          break;
        default:
          break;
      }
    }
  };

  const handleUploadImages = async (newImages: ImageInterface[]) => {
    const formData = new FormData();
    for (const newImage of newImages) {
      await fetch(newImage.originalUrl)
        .then((res) => res.blob())
        .then((blobImage) => {
          formData.append('upload', blobImage, newImage.name);
        });
    }
    switch (entityType) {
      case 'Account':
        await addAccountImages({ accountId: entity.id, data: formData })
          .then(async () => {
            setLoading(true);
          })
          .catch(() => {
            showErrorMessage(enqueueSnackbar, t('error.unknown'));
          });
        break;
      case 'Document':
        await documentServices
          .postImages(entity.accountId, entity.id, formData)
          .then(async () => {
            setLoading(true);
            await getImages();
          })
          .catch(() => {
            showErrorMessage(enqueueSnackbar, t('error.unknown'));
          });
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const breadCrumbItems: any[] = [];
  if (entityType === 'Document') {
    breadCrumbItems.push({
      id: 0,
      title: entity?.account?.title,
      url: `/org/settings`,
    });
  }
  breadCrumbItems.push({
    id: 1,
    title: entity?.title,
    url:
      entityType === 'Document'
        ? `/document/${entity?.account?.slug}/${entity?.slug}/content`
        : `/org/settings`,
  });
  breadCrumbItems.push({
    id: 2,
    title: t('images'),
  });

  const handleBreadCrumbClick = (item) => {
    if (item.url) {
      history.push(item.url);
    }
  };

  if (entity) {
    return (
      <div className={classes.container}>
        {showBreadcrumb && (
          <div style={{ width: '100%', marginBottom: 15 }}>
            <DocerBreadcrumbs
              items={breadCrumbItems}
              onClick={handleBreadCrumbClick}
            />
          </div>
        )}
        <div style={{ width: '100%', marginBottom: 15 }}>
          <Typography variant="h6">{t('images')}</Typography>
        </div>
        <div style={{ display: 'grid', paddingBottom: 30 }}>
          <ReactImageGallery
            images={images}
            targetId="image-gallery"
            formTargetId="image-gallery-upload-form"
            note={t('image-gallery-note')}
            remoteSources={[
              'Dropbox',
              'GoogleDrive',
              'OneDrive',
              'Unsplash',
              'Url',
            ]}
            loading={loading}
            allowMultipleDelete={false}
            allowMultipleEdit={false}
            handleUploadImages={handleUploadImages}
            handleUpdateImages={handleUpdateImages}
            handleRemoveImages={handleRemoveImages}
            companionUrl={`${config.get('editorURL')}/companion`}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(ImageGallery);
