import React, { MouseEvent } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles: any = () => ({
  button: {
    minWidth: '50px!important',
    width: '50px!important',
  },
});

const PaymentMethodsMenu = (props: any) => {
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const classes = props.classes;

  const changeDefaultPaymentMethod = () => {
    handleClose();
    props.changeDefaultPaymentMethod(props.id);
  };

  const handleDeleteClicked = () => {
    handleClose();
    props.openDetachPaymentMethodDialog(props.name, props.id);
  };

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        ...
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={changeDefaultPaymentMethod}>Set as default</MenuItem>
        <MenuItem onClick={handleDeleteClicked}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(PaymentMethodsMenu);
