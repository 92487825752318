import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TopicContext } from '../../../../contexts/topic';
import {
  changeRouteBaseOnEditContentMenues,
  getEditContentUrl,
} from '../../../../helpers/route';
import DeleteButton from './delete';
import PublishButtons from './publish';
import MoreButton from './more';
import RevertButton from './revert';
import { DraftTopicTree } from '../../../../api-schemas/topic';
import useGetDraftTopicSilently from '../../../../api-hooks/topic/useGetDraftTopicSilently';
import { AccountContext } from '../../../../contexts/account';

import './styles.css';

const useStyles: any = makeStyles((theme: Theme) => ({
  paper: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(600 + parseInt(theme.spacing(3)) * 2)]: {
      paddingLeft: theme.spacing(3),
    },
    marginBottom: '0px',
    height: 'calc(100%)',
    boxShadow: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  breadcrumbs: {
    display: 'flex',
    justifyContent: 'flex-left',
  },
  button: {
    margin: `${theme.spacing(1)}!important`,
    height: 35,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      textTransform: 'none',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  buttonText: {
    marginLeft: 0,
  },
  topicContentEditor: {
    height: 'calc(100%)',
  },
  chip: {
    margin: '10px',
    marginRight: '0px',
  },
  infoText: {
    color: 'red',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
}));

type PrimaryButtonsPropsType = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  draftTopicTree?: DraftTopicTree;
  flatDraftTopicTree?: DraftTopicTree;
  targetTopicVersionId: string | null;
  isInSaveState: boolean;
};

const PrimaryButtons = ({
  selectedTab,
  setSelectedTab,
  draftTopicTree,
  flatDraftTopicTree,
  targetTopicVersionId,
  isInSaveState,
}: PrimaryButtonsPropsType) => {
  const [showBackButton, setShowBackButton] = useState<boolean>(false);

  const { selectedTopic } = useContext(TopicContext);
  const { selectedLanguage } = useContext(AccountContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();

  const { draftTopic } = useGetDraftTopicSilently({
    topicId: selectedTopic?.topicId,
    languageCode: selectedTopic?.languageCode || selectedLanguage,
  });

  const isLanguageEqual: boolean = draftTopic
    ? draftTopic.title !== null
    : false;
  const isDeleted: boolean = draftTopic
    ? draftTopic.action === 'Deleted'
    : false;
  const isAlreadyPublished: boolean =
    draftTopic && !isDeleted
      ? draftTopic.latestTopicVersionId === draftTopic.publishedTopicVersionId
      : false;

  useEffect(() => {
    const returnedData = changeRouteBaseOnEditContentMenues(
      null,
      window.location.pathname.split('/')[4]
    );

    setSelectedTab(returnedData[0].toString());
    setShowBackButton(Boolean(returnedData[1]));
    // eslint-disable-next-line
  }, [window.location.pathname]);

  const handleBackButtonClick = () => {
    if (selectedTopic) {
      history.push(
        getEditContentUrl(accountId, documentId, selectedTopic.topicId)
      );

      setSelectedTab('Edit');
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>

      {selectedTab === 'History' && (
        <RevertButton targetTopicVersionId={targetTopicVersionId} />
      )}

      {isLanguageEqual && selectedTab === 'Edit' && (
        <DeleteButton
          isDeleted={isDeleted}
          flatDraftTopicTree={flatDraftTopicTree}
        />
      )}

      {showBackButton && (
        <Tooltip title="Back" aria-label="back">
          <Button
            variant="outlined"
            color="grey"
            onClick={handleBackButtonClick}
            className={classes.button}
          >
            <span className={classes.buttonText}>{t('back')}</span>
          </Button>
        </Tooltip>
      )}

      {isLanguageEqual && selectedTab === 'Edit' && (
        <PublishButtons
          flatDraftTopicTree={flatDraftTopicTree}
          isAlreadyPublished={isAlreadyPublished}
          isInSaveState={isInSaveState}
          selectedTopic={draftTopic}
        />
      )}

      <MoreButton
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isLanguageEqual={isLanguageEqual}
        setShowBackButton={setShowBackButton}
        isAlreadyPublished={isAlreadyPublished}
        draftTopicTree={draftTopicTree}
      />
    </div>
  );
};

export default PrimaryButtons;
