import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { TopicLinksData } from '../../api-schemas/reference-links';

const getTargetTopicLinksRequest = ({
  topicId,
  languageCode,
}: {
  topicId?: string;
  languageCode?: string;
}) => {
  return axios
    .get<TopicLinksData>(`/api/content-processor/topic-link?targetTopicId=${topicId}&sourceTopicLang=${languageCode}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetTargetTopicLinks({
  topicId,
  languageCode,
}: {
  topicId?: string;
  languageCode?: string;
}) {
  const {
    data,
    refetch: getTargetTopicLinks,
    isFetching: isLoadingTargetTopicLinks,
    isRefetching: isRefetchingTargetTopicLinks,
    isError: isErrorLoadingTargetTopicLinks,
    isSuccess: isSuccessLoadingTargetTopicLinks,
  } = useQuery<TopicLinksData>({
    staleTime: 0,
    queryKey: ['TargetTopicLinks', topicId, languageCode],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(topicId),
    queryFn: () => getTargetTopicLinksRequest({ topicId, languageCode }),
  });

  const TargetTopicLinks = useMemo(() => data, [data]);

  return {
    TargetTopicLinks,
    isLoadingTargetTopicLinks,
    isRefetchingTargetTopicLinks,
    isErrorLoadingTargetTopicLinks,
    isSuccessLoadingTargetTopicLinks,

    getTargetTopicLinks,
  };
}
