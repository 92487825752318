export default {
  stripeKey: 'pk_live_jr2x3zTgVOMF9uOY6vYGbUqh00zdVYHI0b',
  viewerURL: 'https://sonat.com/@',
  errorSubscriptionStatuses: [
    'past_due',
    'unpaid',
    'canceled',
    'incomplete_expired',
    'trialing',
  ],
  CNAME: 'hosting.sonat.com',
  environment: 'production',
  PUBLIC_VAPID_KEY:
    'BNzMtD0SKq_D8iiD3NL6bonfIWlq1uhabKi-b0ROWwoXJsnB0HwA1XIt79TZxb_Zo8BucjCSd-chMhl7PIHZqFQ',
  editorURL: 'https://editor.sonat.com',
  firebaseConfig: {
    apiKey: 'AIzaSyBdBvlxnR1-cwm4fu-_TYicOTVw7qRwvb8',
    authDomain: 'auth.sonat.com',
    databaseURL: 'https://sonat-prod.firebaseio.com',
    projectId: 'sonat-prod',
    storageBucket: 'sonat-prod.appspot.com',
    messagingSenderId: '767408642079',
    appId: '1:767408642079:web:b6d96868a66b01073d9d2a',
    measurementId: 'G-EKP5FK6K5T',
  },
  GTM_ID: 'GTM-5D6LCRG',
  facebookChatPageID: '342123023622762',
  sentry: {
    dsn: 'https://769772aac9e16022551ea0d2e5a57b8c@o4508473040371712.ingest.us.sentry.io/4508495212118016'
  },
  googleDocConfig: {
    CLIENT_ID:
      '767408642079-pki63vg8mpmv019bklupmjlick6u2ldn.apps.googleusercontent.com',
    REDIRECT_URI: 'https://editor.sonat.com/driveoauthcallback',
    SCOPE:
      'https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.appfolder https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install',
    authEndpoint: 'https://accounts.google.com/o/oauth2/auth',
    tokenEndpoint: 'https://oauth2.googleapis.com/token',
  },
};
