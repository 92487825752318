import React, { FC, useContext, useState, FormEvent } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Row, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../contexts/account';
import TextInput from '../../inputs/text-input';
import { Folder, CreateFolderRequest } from '../../../api-schemas/folder';
import { showErrorMessage } from '../../../helpers/common';
import useUpdateFolder from '../../../api-hooks/folder/useUpdateFolder';

type AddFolderLanguageDialogPropsType = {
  selectedFolderId?: string;
  existingFolder: Folder;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

const AddFolderLanguageDialog: FC<AddFolderLanguageDialogPropsType> = ({
  selectedFolderId,
  existingFolder,
  setOpenDialog,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedLanguage } = useContext(AccountContext);

  const [folder, setFolder] = useState<CreateFolderRequest>({
    ...existingFolder,
    titles: [
      ...existingFolder.titles,
      {
        languageCode: selectedLanguage as string,
        title: '',
      },
    ],
  });

  const folderTitle = folder.titles.find(
    (title) => title.languageCode === selectedLanguage
  );

  const { updateFolder, isUpdatingFolder } = useUpdateFolder(
    folder.accountId,
    selectedFolderId
  );

  const handleTitleChanged = (event: FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const newFolder = { ...folder };
    const folderTitle = newFolder.titles.find(
      (title) => title.languageCode === selectedLanguage
    );

    folderTitle!.title = value;

    setFolder(newFolder);
  };

  const handleUpdateFolder = () => {
    updateFolder(folder)
      .then(() => {
        setOpenDialog(false);
      })
      .catch(() => {
        showErrorMessage(enqueueSnackbar, t('error.unknown'));
      });
  };

  return (
    <Modal
      title={t('add-folder-language')}
      open={true}
      okText={t('add')}
      onOk={handleUpdateFolder}
      onCancel={() => setOpenDialog(false)}
      confirmLoading={isUpdatingFolder}
      bodyStyle={{ height: 100, minHeight: 100, padding: '0 0 0 10px' }}
    >
      <Row style={{ padding: 10 }}>
        <Col xs={24} style={{ padding: 5 }}>
          <TextInput
            style={{ width: '100%' }}
            id="title"
            field="Title"
            label={t('title')}
            value={folderTitle!.title}
            onChange={handleTitleChanged}
            required={true}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AddFolderLanguageDialog;
