import { useEffect, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { ComponentVersion } from '../../api-schemas/component';

export const getComponentVersionRequest = (
  componentId: string,
  componentVersionNumber: string
) => {
  return axios
    .get<ComponentVersion>(
      `/api/Component/Version/${componentId}/${componentVersionNumber}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useGetComponentVersionWithNumber({
  componentId,
  componentVersionNumber,
  enabled,
  onSuccess,
  onReject,
}: {
  componentId: string;
  componentVersionNumber: string;
  enabled: boolean;
  onSuccess: () => void;
  onReject: () => void;
}) {
  const {
    data,
    refetch: getComponentVersion,
    isFetching: isLoadingComponentVersion,
    isSuccess: isSuccessLoadingComponentVersion,
    isError: isErrorLoadingComponentVersion,
  } = useQuery<ComponentVersion>({
    staleTime: 1000 * 60 * 60,
    queryKey: [
      'component-version-with-number',
      componentId,
      componentVersionNumber,
    ],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () =>
      getComponentVersionRequest(componentId, componentVersionNumber),
    enabled,
  });

  const componentVersion = useMemo(() => data || {}, [data]);

  useEffect(() => {
    if (isSuccessLoadingComponentVersion) {
      onSuccess();
    }
  }, [isSuccessLoadingComponentVersion, onSuccess]);

  useEffect(() => {
    if (isErrorLoadingComponentVersion) {
      onReject();
    }
  }, [isErrorLoadingComponentVersion, onReject]);

  return {
    componentVersion,
    isLoadingComponentVersion,
    isSuccessLoadingComponentVersion,

    getComponentVersion,
  };
}
