import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { Publish as PublishOutlinedIcon } from '@mui/icons-material';

import usePublishTopic from '../../../../../api-hooks/topic/usePublishTopic';
import { DraftTopicTreeItem } from '../../../../../api-schemas/topic';
import { Document } from '../../../../../api-schemas/document';

type PublishButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
  document: Document;
};

const PublishButton = ({
  documentVersionId,
  draftTopicTreeItem,
  document,
}: PublishButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';
  const isAlreadyPublished: boolean =
    draftTopicTreeItem.latestTopicVersionId !== null &&
    draftTopicTreeItem.latestTopicVersionId ===
      draftTopicTreeItem.publishedTopicVersionId;

  const { t } = useTranslation();

  const { publishTopic, isPublishingTopic } = usePublishTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    topicId: draftTopicTreeItem.topicId,
  });

  const handlePublish = () => {
    publishTopic({
      topicVersionIds: [draftTopicTreeItem.latestTopicVersionId],
      languageCode: draftTopicTreeItem.languageCode,
      title: new Date().toLocaleDateString(),
    });
  };

  return (
    <Button
      onClick={handlePublish}
      disabled={
        (!isDeleted && isAlreadyPublished) ||
        isPublishingTopic ||
        !!document.processId ||
        !!document.account.processId
      }
      type="text"
      style={{ display: 'flex', width: '100%' }}
    >
      <PublishOutlinedIcon style={{ marginRight: 5 }} />

      <Typography>
        {isDeleted ? t('publish-delete') : t('publish-topic')}
      </Typography>
    </Button>
  );
};

export default PublishButton;
