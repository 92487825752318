import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DeleteRounded, Edit } from '@mui/icons-material';
import { withSnackbar } from 'notistack';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { useParams } from 'react-router-dom';

import { ConfirmationDialog } from '../../../confirmation-dialog';
import { DocumentService } from '../../../../services/document';
import { convertToSlug, showSuccessMessage } from '../../../../helpers/common';
import { localizeKey } from '../../../../helpers/localize-key';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { DocumentContext } from '../../../../contexts/document';
import { AccountContext } from '../../../../contexts/account';
import { getQueries } from '../../../../helpers/route';
import TextInput from '../../../inputs/text-input';
import SelectInput from '../../../inputs/select-input';
import PlanLimitationDialog from '../../../plan-limitation-dialog';
import useGetCurrentPlan from '../../../../api-hooks/plan/useGetCurrentPlan';

const { Option } = Select;

const styles: any = () => ({
  card: {
    margin: '20px 0 0',
    width: '600px',
  },
  cardHeader: {
    borderBottom: '1px solid silver',
  },
  cardContent: {
    padding: '0!important',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  newVersion: {
    margin: '0 10px',
    display: 'flex',
    flexDirection: 'row',
  },
  newVersionTitle: {
    flex: 0.5,
    margin: '0 2px',
  },
  newVersionSlug: {
    flex: 0.5,
    margin: '0 2px',
  },
  addButton: {
    marginLeft: '10px!important',
  },
  editVersionSlug: {
    marginLeft: '10px!important',
  },
  editLoading: {
    width: '20px!important',
    height: '20px!important',
    color: 'white!important',
  },
  loadingBox: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPadding: {
    padding: 0,
  },
  formLabel: {
    marginBottom: 5,
    marginTop: '15px',
  },
  menuPaper: {
    maxHeight: 200,
  },
});

const DocumentVersionManagement = (props) => {
  /**
   * @description to rest api classes
   */

  const documentService = new DocumentService();

  const { t } = useTranslation();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();

  const { classes } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedVariantForEdit, setSelectedVariantForEdit] =
    useState<any>(null);
  const [disableSaveEdit, setDisableSaveEdit] = useState<boolean>(true);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [sourceVariant, setSourceVariant] = useState<string>('Empty');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [newVersion, setNewVersion] = useState<any>(null);
  const [previousVersionSlug, setPreviousVersionSlug] = useState<string | null>(
    null
  );
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(true);

  const {
    selectedDoc,
    setSelectedDoc,
    selectedVersion,
    documents,
    setDocuments,
    versions,
    setDocumentVersions,
    selectedLanguage,
    setSelectedVersion,
    setPreviousVersionId,
  } = useContext(DocumentContext);
  const account = useContext(AccountContext);

  const { currentPlan } = useGetCurrentPlan(selectedAccount?.id);

  useEffect(() => {
    if (selectedDoc && !versions.length) {
      loadVersions();
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  useEffect(() => {
    if (account.accountsData) {
      account.accountsData.forEach((value) => {
        if (value.id === accountId) {
          setSelectedAccount(value);
        }
      });
    }
  }, [accountId, account]);

  const loadVersions = () => {
    setLoading(true);
    documentService
      .getVersions(selectedDoc.id)
      .then((data) => {
        setDocumentVersions(data);
        setNewVersion(null);
        setLoading(false);
        setDisableSaveEdit(true);
      })
      .catch((error) => {
        setLoading(false);
        props.enqueueSnackbar(
          error.statusText
            ? t(`error.${localizeKey(error.statusText)}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
  };

  /**
   * @description Functions and events
   */
  const handleClickOpenDeleteDialog = (ver) => {
    setOpenDeleteDialog(true);
    setSelectedVariant(ver);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedVariant(null);
  };

  const processVersionsAfterDelete = async () => {
    const tempVersions: any[] = versions;
    const subtract: any[] = tempVersions.filter(
      (ver) => ver.id !== selectedVariant.id
    );
    setDocumentVersions(subtract);
    let newDocument = selectedDoc;
    if (selectedVariant.slug === selectedDoc.defaultDocumentVersionSlug) {
      await documentService
        .getDocument(selectedDoc.id)
        .then((result) => {
          newDocument = result;
          setSelectedDoc(result);
        })
        .catch(() => {
          setSelectedVersion(subtract[0]);
        });
    }
    if (selectedVariant.id === selectedVersion.id) {
      const foundVersions = subtract.filter(
        (ver) => ver.slug === newDocument.defaultDocumentVersionSlug
      );
      if (foundVersions.length) {
        setSelectedVersion(foundVersions[0]);
      } else {
        setSelectedVersion(subtract[0]);
      }
    }
  };

  const handleRemoveVersionFromDocument = (callback) => {
    if (versions.length === 1) {
      props.enqueueSnackbar(
        t(`error.${localizeKey('remove-latest-version')}`),
        {
          variant: 'error',
        }
      );
      callback();
    } else {
      setDeleteLoading(true);
      documentService
        .deleteVersion(selectedVariant)
        .then(async () => {
          setDeleteLoading(false);
          callback();
          processVersionsAfterDelete();
        })
        .catch(async (error) => {
          const errorJson = await error.json();
          if (
            errorJson.errors &&
            errorJson.errors[0].code === 'CannotRemoveDefaultVersionOfDocument'
          ) {
            props.enqueueSnackbar(t(`error.removeDefaultVariant`), {
              variant: 'error',
            });
          } else {
            props.enqueueSnackbar(t(`error.${localizeKey('remove-version')}`), {
              variant: 'error',
            });
          }
          setDeleteLoading(false);
          callback();
        });
    }
  };

  const handleNewVersionTitleChanged = (event) => {
    const value: string = event.target.value;
    const createdNewVersion = newVersion;
    const slug = convertToSlug(value);
    createdNewVersion.title = value;
    createdNewVersion.slug = slug;
    createdNewVersion.slug2 = slug;
    setNewVersion((prevState) => {
      return { ...prevState, ...createdNewVersion };
    });
    if (!value.length || !createdNewVersion.slug.length) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const handleNewVersionSlugChanged = (event) => {
    const value: string = event.target.value;
    if (!value.length) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
    const createdNewVersion = newVersion;
    createdNewVersion.slug = value;
    setNewVersion((prevState) => {
      return { ...prevState, ...createdNewVersion };
    });
  };

  const handleNewVersionClick = () => {
    setSelectedVariantForEdit(null);
    setNewVersion({
      documentId: documentId,
      title: '',
      slug: '',
      slug2: '',
    });
  };

  const validateDataBeforeSave = (ver) => {
    const otherVersionWithSameName = versions.find(
      (version) => version.title === ver.title && version.id !== ver.id
    );
    if (otherVersionWithSameName) {
      props.enqueueSnackbar(t(`error.duplicateTitle`), {
        variant: 'error',
      });
      return false;
    }
    const otherVersionWithSameSlug = versions.find(
      (version) => version.slug === ver.slug && version.id !== ver.id
    );
    if (otherVersionWithSameSlug) {
      props.enqueueSnackbar(t(`error.duplicateSlug`), {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  const handleNewVersionSaveClick = () => {
    const isDataValid = validateDataBeforeSave(newVersion);
    if (!isDataValid) return;
    setSaveLoading(true);
    if (newVersion && newVersion.title) {
      documentService
        .postVersion(newVersion)
        .then((version) => {
          setSaveLoading(false);
          const tempVersions = versions;
          newVersion.id = version.id;
          tempVersions.push(newVersion);
          setNewVersion(null);
          setDocumentVersions(tempVersions);
        })
        .catch(async (error) => {
          const errorJson = await error.json();
          setSaveLoading(false);
          if (errorJson.errors[0].field === 'MaximumNumberOfTopicsExceeded') {
            setOpenDialog(true);
          } else {
            props.enqueueSnackbar(t(`error.${localizeKey('add-version')}`), {
              variant: 'error',
            });
          }
        });
    }
  };

  const handleEditContent = () => {
    const queries: string | null = getQueries(
      selectedVersion,
      selectedLanguage
    );

    props.history.push(
      `/document/${accountId}/${documentId}/content${queries || ''}`
    );
  };

  const handleClickEditVariant = (ver) => {
    setSelectedVariantForEdit({ ...ver });
    setPreviousVersionSlug(ver.slug);
    setDisableSaveEdit(true);
  };

  const handleCancelEdit = () => {
    setPreviousVersionSlug(null);
    setSelectedVariantForEdit(null);
  };

  const processVersionsAfterUpdate = async (newVer) => {
    let tempVersions = [...versions];
    let index: number = -1;
    const foundVersions = tempVersions.filter((ver) => ver.id === newVer.id);
    if (foundVersions.length) {
      index = tempVersions.indexOf(foundVersions[0]);
    }
    tempVersions = tempVersions.filter((version) => version.id !== newVer.id);
    tempVersions.splice(index, 0, newVer);
    setDocumentVersions(tempVersions);
    if (
      previousVersionSlug === selectedDoc.defaultDocumentVersionSlug &&
      newVer.slug !== previousVersionSlug
    ) {
      const newDocument = selectedDoc;
      newDocument.defaultDocumentVersionSlug = newVer.slug;
      const tempDocuments = documents;
      const foundDocuments = tempDocuments.filter(
        (doc) => doc.id === selectedDoc.id
      );
      if (foundDocuments.length) {
        const index = tempDocuments.indexOf(foundDocuments[0]);
        tempDocuments[index].defaultDocumentVersionSlug = newVer.slug;
        setDocuments(tempDocuments);
      }
      setSelectedDoc(newDocument);
    }
    if (selectedVersion && newVer.id === selectedVersion.id) {
      setPreviousVersionId(null);
      setSelectedVersion(newVer);
    }
  };

  const handleSaveVariant = (ver) => {
    const isDataValid = validateDataBeforeSave(ver);
    if (!isDataValid) return;
    setEditLoading(true);
    documentService
      .putVersion(ver)
      .then(() => {
        processVersionsAfterUpdate(ver);
        setEditLoading(false);
        setSelectedVariantForEdit(null);
        showSuccessMessage(props.enqueueSnackbar, t);
      })
      .catch(() => {
        setEditLoading(false);
        setDisableSaveEdit(true);
        props.enqueueSnackbar(t(`error.${localizeKey('edit-version')}`), {
          variant: 'error',
        });
      });
  };

  const handleEditVersionSlugChanged = (event) => {
    const value: string = event.target.value;
    if (!value.length) {
      setDisableSaveEdit(true);
    } else {
      setDisableSaveEdit(false);
    }
    const cratedEditedVersion = selectedVariantForEdit;
    cratedEditedVersion.slug = value;
    cratedEditedVersion.slug2 = value;
    setSelectedVariantForEdit((prevState) => {
      return { ...prevState, ...cratedEditedVersion };
    });
  };

  const handleEditVersionTitleChanged = (event) => {
    const value: string = event.target.value;
    const cratedEditedVersion = selectedVariantForEdit;
    cratedEditedVersion.title = value;
    const slug = convertToSlug(value);
    cratedEditedVersion.slug = slug;
    cratedEditedVersion.slug2 = slug;
    setSelectedVariantForEdit((prevState) => {
      return { ...prevState, ...cratedEditedVersion };
    });
    if (!value.length || !cratedEditedVersion.slug.length) {
      setDisableSaveEdit(true);
    } else {
      setDisableSaveEdit(false);
    }
  };

  const handleSetDefaultVersion = (ver) => {
    const data = {
      documentId: documentId,
      defaultDocumentVersionSlug: ver.slug,
    };
    setLoading(true);
    documentService
      .changeDefaultVersion(data)
      .then(() => {
        const newDoc = {
          ...selectedDoc,
          ...{ defaultDocumentVersionSlug: ver.slug },
        };
        setSelectedDoc(newDoc);
        const tempDocuments = documents;
        const foundDocuments = tempDocuments.filter(
          (doc) => doc.id === newDoc.id
        );
        if (foundDocuments.length) {
          const index = tempDocuments.indexOf(foundDocuments[0]);
          tempDocuments[index].defaultDocumentVersionSlug = ver.slug;
        }
        setDocuments(tempDocuments);
        showSuccessMessage(props.enqueueSnackbar, t);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        props.enqueueSnackbar(t(`error.unknown`), {
          variant: 'error',
        });
      });
  };

  const varTitle: string =
    selectedVariant && selectedVariant.title ? selectedVariant.title : '';

  /**
   * @description #region render method and dependencies such as renderVersionItem
   */
  const renderVersionItem = (ver, index: number) => {
    const variantName = ver.title;
    return (
      <React.Fragment key={ver.id}>
        {selectedVariantForEdit && selectedVariantForEdit.id === ver.id ? (
          <ListItem>
            <Grid>
              <TextInput
                style={{ paddingRight: 5 }}
                field="title"
                label={t('title')}
                required={true}
                showError={false}
                onChange={handleEditVersionTitleChanged}
                value={selectedVariantForEdit.title}
              />
            </Grid>
            <Grid>
              <TextInput
                field="slug"
                label={t('slug')}
                tooltip={t('version-slug-help')}
                required={true}
                showError={false}
                onChange={handleEditVersionSlugChanged}
                value={selectedVariantForEdit.slug2}
              />
            </Grid>
            <ListItemSecondaryAction>
              <Button className={classes.button} onClick={handleCancelEdit}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                disabled={disableSaveEdit}
                onClick={() => handleSaveVariant(selectedVariantForEdit)}
              >
                {editLoading ? (
                  <CircularProgress className={classes.editLoading} />
                ) : (
                  t('save')
                )}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ) : (
          <Fragment>
            <ListItem>
              <ListItemText primary={`${ver.title}`} />
              <ListItemSecondaryAction>
                <Tooltip title={`${t('edit')} ${ver.title}`}>
                  <IconButton
                    aria-label={`Edit ${ver.title}`}
                    onClick={() => handleClickEditVariant(ver)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`${t('rm')} ${ver.title}`}>
                  <span>
                    <IconButton
                      aria-label={`Delete ${ver.title}`}
                      onClick={() => handleClickOpenDeleteDialog(ver)}
                      className="delete-version-button"
                      disabled={
                        selectedDoc.defaultDocumentVersionSlug === ver.slug
                      }
                    >
                      <DeleteRounded />
                    </IconButton>
                  </span>
                </Tooltip>
                {selectedDoc.defaultDocumentVersionSlug === ver.slug ? (
                  <Tooltip
                    title={
                      <Trans i18nKey="variant.default">
                        {' '}
                        {{ variantName }} is your default variant{' '}
                      </Trans>
                    }
                  >
                    <IconButton
                      aria-label={`${ver.title} is your default variant`}
                    >
                      <DoneOutlineIcon style={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <Trans i18nKey="variant.setAsDefault">
                        {' '}
                        Set {{ variantName }} as your default{' '}
                      </Trans>
                    }
                  >
                    <IconButton
                      aria-label={`Set ${ver.title} as default`}
                      onClick={() => handleSetDefaultVersion(ver)}
                    >
                      <DoneOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            {index !== versions.length - 1 && <Divider />}
          </Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderNewVersionForm = () => {
    return (
      <Dialog
        disableEscapeKeyDown
        open={!!newVersion}
        fullWidth
        maxWidth={'xs'}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">New variant</DialogTitle>
        <DialogContent>
          <Row style={{ padding: 8 }}>
            <Col xs={24} style={{ marginBottom: 10 }}>
              <TextInput
                field="docVerManagement.new-ver"
                label={t('docVerManagement.new-ver')}
                required={true}
                showError={false}
                onChange={handleNewVersionTitleChanged}
                value={newVersion.title}
              />
            </Col>
            <Col xs={24} style={{ marginBottom: 10 }}>
              <TextInput
                field="slug"
                label={t('slug')}
                tooltip={t('version-slug-help')}
                required={true}
                showError={false}
                onChange={handleNewVersionSlugChanged}
                value={newVersion.slug2}
              />
            </Col>
            <Col xs={24} style={{ marginBottom: 10 }}>
              <SelectInput
                field="Copy from"
                label="Copy from"
                value={sourceVariant}
                onChange={(value) => {
                  const createdNewVersion = newVersion;
                  createdNewVersion.sourceDocumentVersionId =
                    value !== 'Empty' ? value : null;
                  setNewVersion(createdNewVersion);
                  setSourceVariant(`${value}`);
                }}
              >
                <Option key={'Empty'} value={'Empty'}>
                  {'None (Empty variant)'}
                </Option>
                {versions.map((version) => (
                  <Option key={version.id} value={version.id}>
                    {version.title}
                  </Option>
                ))}
              </SelectInput>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewVersion(null);
              setDisableSave(true);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Tooltip title={`${t('add')} ${newVersion.title}`}>
            <span>
              <Button
                color="primary"
                variant="contained"
                aria-label={`Add ${newVersion.title}`}
                disabled={disableSave || saveLoading}
                onClick={() => handleNewVersionSaveClick()}
                // className={classes.addButton}
                autoFocus
              >
                {saveLoading ? (
                  <CircularProgress className={classes.editLoading} />
                ) : (
                  t('add')
                )}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDeleteVersionConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        title={t<string>('rm-ver')}
        message={
          <Trans i18nKey="docVerManagement.action.confirm-disable-ver">
            Are you sure you want to delete {{ varTitle }} from the document?
          </Trans>
        }
        loading={deleteLoading}
        isOpen={openDeleteDialog}
        onDialogClosed={handleCloseDeleteDialog}
        onDialogConfirmed={() =>
          handleRemoveVersionFromDocument(handleCloseDeleteDialog)
        }
      />
    );
  };

  if (!loading && selectedDoc) {
    return (
      <React.Fragment>
        <Row style={{ justifyContent: 'center' }}>
          <Col>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                title={t<string>('docVerManagement.doc-ver')}
                action={
                  <React.Fragment>
                    <Tooltip title={t<string>('doclist.tooltip.edit-content')}>
                      <Button
                        aria-label={`Back`}
                        onClick={() => handleEditContent()}
                        color="primary"
                      >
                        Back
                      </Button>
                    </Tooltip>
                    <Button
                      variant="contained"
                      color="primary"
                      aria-label="New variant"
                      onClick={handleNewVersionClick}
                    >
                      New
                    </Button>
                  </React.Fragment>
                }
              />
              <CardContent className={classes.cardContent}>
                <List className={classes.noPadding}>
                  {versions
                    .sort((a, b) =>
                      a.title > b.title ? 1 : a.title < b.title ? -1 : 0
                    )
                    .map((ver, index) => renderVersionItem(ver, index))}
                </List>
                {newVersion && renderNewVersionForm()}
              </CardContent>
            </Card>
          </Col>
        </Row>
        {selectedVariant && renderDeleteVersionConfirmationDialog()}
        <PlanLimitationDialog
          accountPlan={currentPlan?.name}
          open={openDialog}
          textMessage={
            currentPlan?.name === 'Free'
              ? t('dialog-topic-limitation-free')
              : t('dialog-topic-limitation-business')
          }
          textTitle={t('dialog-cant-add-topics')}
          handleCloseLimitationDialog={() => {
            setOpenDialog(false);
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <Col xs={24} className={classes.loadingBox}>
        <CircularProgress />
      </Col>
    );
  }
};

export default withStyles(styles)(withSnackbar(DocumentVersionManagement));
