import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddUserSubscriptionRequestType } from '../../api-schemas/user';

const addUserSubscriptionRequest = (data: AddUserSubscriptionRequestType) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/User/Subscription', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useAddUserSubscription({
  entityType,
  entityId,
}: AddUserSubscriptionRequestType) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: addUserSubscription,
    isPending: isAddingUserSubscription,
    isError: isErrorAddingUserSubscription,
    isSuccess: isSuccessAddingUserSubscription,
  } = useMutation({
    mutationFn: () => addUserSubscriptionRequest({ entityId, entityType }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'subscriptions'] });
      queryClient.removeQueries({
        queryKey: ['watchers', entityId, entityType],
      });
    },
  });

  return {
    addUserSubscription,
    isAddingUserSubscription,
    isErrorAddingUserSubscription,
    isSuccessAddingUserSubscription,
  };
}
