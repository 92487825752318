import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Liquid, Area, Column } from '@ant-design/plots';
import { Select, Progress, Typography, Divider, Table, DatePicker } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { SortOrder } from 'antd/lib/table/interface';
import { CircularProgress, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CustomTooltip from './tooltip';

// 1.2 Relative component imports
import SelectInput from '../../inputs/select-input';

// 1.3 Relative js/ts imports
import { AnalyticsService } from '../../../services/analytics';
import { topicService } from '../../../services/topic';
import { DocumentService } from '../../../services/document';
import { DocumentContext } from '../../../contexts/document';
import { AccountContext } from '../../../contexts/account';
import {
  calculateReadabilityScore,
  calculateSEOScore,
  getDocumentMetrics,
} from '../../../helpers/common';
import {
  getLastYearDate,
  getLastMonthDate,
  getLastWeekDate,
  getNowDate,
  formatDatePickerValue,
} from '../../../helpers/date';

// 1.4 Relative css imports
import './styles.css';

const { RangePicker } = DatePicker;

const { Title } = Typography;
const { Option } = Select;

const ANALYTICS_SERVICE = new AnalyticsService();
const DOCUMENT_SERVICE = new DocumentService();
const TOPIC_SERVICE = new topicService();

const WriteAnalytics = (props) => {
  const { t } = useTranslation();

  const [selectedDateRangeTitle, setSelectedDateTitle] = useState<any>();
  const [isCustomDatePicker, setIsCustomDatePicker] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [languages, setLanguages] = useState<any>();
  const [publishData, setPublishData] = useState<any>();
  const [contributorsData, setContributorsData] = useState<any>();
  const [mostEditedTopicData, setMostEditedTopicData] = useState<any>();
  const [topContributorsData, setTopContributorsData] = useState<any>();
  const [changesOverTimeData, setChangesOverTimeData] = useState<any>();
  const [selectedDateRangeData, setSelectedDateRange] = useState<string>();
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [plotLoading, setPlotLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [seoScore, setSeoScore] = useState<number>(0.0);
  const [readabilityScore, setReadabilityScore] = useState<number>(0.0);
  const [selectedAccountDocuments, setSelectedAccountDocuments] =
    useState<any>();

  const { documentId }: { documentId: string } = useParams();
  const isAccount = !documentId;

  const {
    versions,
    setVersions,
    selectedVersion,
    setSelectedVersion,
    setSelectedDoc,
    topicsPerLanguageVersion,
    setTopicsPerLanguageVersion,
    selectedTopic,
    setSelectedTopic,
    documents,
  } = useContext(DocumentContext);

  const { selectedAccount } = useContext(AccountContext);
  const history = useHistory();

  const FORMAT_DATE = [
    {
      id: 0,
      title: 'Last Week',
    },
    {
      id: 1,
      title: 'Last Month',
    },
    {
      id: 2,
      title: 'Last Year',
    },
    {
      id: 3,
      title: 'Custom',
    },
  ];

  const TOP_CONTRIBUTORS_COLUMNS = [
    {
      title: 'Contributor',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Number of Edits',
      dataIndex: 'numberOfChanges',
      key: 'numberOfChanges',
      sorter: (a: any, b: any) => a.numberOfChanges - b.numberOfChanges,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
    },
  ];

  const MOST_EDITED_TOPICS_COLUMNS = [
    {
      title: 'Topic',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Number of Edits',
      dataIndex: 'numberOfChanges',
      key: 'numberOfChanges',
      sorter: (a: any, b: any) => a.numberOfChanges - b.numberOfChanges,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
    },
  ];

  const seoConfig = {
    percent: seoScore,
    style: {
      outlineBorder: 4,
      outlineDistance: 8,
      waveLength: 128,
      //      backgroundFill: seoScore > 60? '#28a745': seoScore > 30? '#ffc107': seoScore > 0 ? '#dc3545' : '#5f6368'
    },
  };
  const readabilityConfig = {
    percent: readabilityScore,
    style: {
      outlineBorder: 4,
      outlineDistance: 8,
      waveLength: 64,
      //      backgroundFill: readabilityScore > 60? '#28a745': readabilityScore > 30? '#ffc107': readabilityScore > 0 ? '#dc3545' : '#5f6368'
    },
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const changesOverTimeconfig = {
    data: changesOverTimeData,
    xField: (d) =>
      new Date(d.datetime).toLocaleDateString('en-US', dateOptions),
    yField: 'numberOfChanges',
    interaction:{
      tooltip: {
        render: (e, { title, items }) => {
          return <CustomTooltip title={title} items={items} label="Number Of Changes" />;
        },
      },
    }
  };

  const publishTimeconfig = {
    data: publishData,
    xField: (d) => new Date(d.date).toLocaleDateString('en-US', dateOptions),
    yField: 'numberOfPublishes',
    interaction:{
      tooltip: {
        render: (e, { title, items }) => {
          return <CustomTooltip title={title} items={items} label="Number Of Publishes" />;
        },
      },
    }
  };

  const cotributorConfig = {
    data: contributorsData,
    xField: 'displayName',
    yField: 'numberOfChanges',
    interaction:{
      tooltip: {
        render: (e, { title, items }) => {
          return <CustomTooltip title={title} items={items} label="Number Of Changes" />;
        },
      },
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      const selectedAccountDocs = documents.filter(
        (doc) => doc.accountId === selectedAccount.id
      );

      const docArrays = [{ id: 0, title: 'ALL' }];

      docArrays.push(...selectedAccountDocs);

      let tempSelectedDoc;
      const documentLanguages = [{ id: 0, title: 'ALL' }];

      if (isAccount) {
        tempSelectedDoc = selectedAccountDocs[0];

        setSelectedDocument(docArrays[0]);
        setLoading(false);
      } else {
        tempSelectedDoc = documents.find(
          (doc) =>
            doc.id === documentId && doc.account.slug === selectedAccount.slug
        );

        tempSelectedDoc = tempSelectedDoc
          ? tempSelectedDoc
          : selectedAccountDocs[0];

        const docLanguages = tempSelectedDoc.languages.map((x) => {
          return { id: x.languageCode, title: x.languageCode };
        });

        documentLanguages.push(...docLanguages);

        setSelectedDocument(tempSelectedDoc);
      }

      setSelectedAccountDocuments(docArrays);

      setSelectedDoc(tempSelectedDoc);
      setLanguages(documentLanguages);
      setSelectedLanguage(documentLanguages[0]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedDocument && !isAccount) {
      DOCUMENT_SERVICE.getVersions(selectedDocument.id)
        .then((result) => {
          setVersions(result);
          setSelectedVersion(result[0]);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getVersions`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }

    // eslint-disable-next-line
  }, [selectedDocument]);

  useEffect(() => {
    if (selectedDocument && selectedVersion && !isAccount) {
      TOPIC_SERVICE.getTopics(
        selectedVersion.id,
        selectedDocument.defaultLanguageCode
      )
        .then((result) => {
          const documentTopics = [{ id: 0, title: 'ALL' }];

          documentTopics.push(...result);

          setTopicsPerLanguageVersion(documentTopics);
          setSelectedTopic(documentTopics[0]);

          setLoading(false);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getTopics`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }

    // eslint-disable-next-line
  }, [selectedDocument, selectedVersion]);

  useEffect(() => {
    const now = getNowDate();
    setSelectedDateRange(`startDate=${getLastMonthDate()}&endDate=${now}`);
    setSelectedDateTitle(FORMAT_DATE[1]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedAccount && isAccount && selectedLanguage) {
      setPlotLoading(true);

      ANALYTICS_SERVICE.getWriteChangesOverTimeByAccount(
        selectedAccount.id,
        selectedLanguage.title,
        selectedDateRangeData
      )
        .then((result) => {
          setChangesOverTimeData(result);

          setPlotLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getWritePublishByAccount(
        selectedAccount.id,
        selectedLanguage.title,
        selectedDateRangeData
      )
        .then((result) => {
          setPublishData(result);

          setPlotLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getWriteContributorsByAccount(
        selectedAccount.id,
        selectedLanguage.title,
        selectedDateRangeData
      )
        .then((result) => {
          setContributorsData(result);

          setPlotLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getWriteTopContributorsByAccount(
        selectedAccount.id,
        selectedLanguage.title,
        selectedDateRangeData
      )
        .then((result) => {
          setTopContributorsData(result);

          setPlotLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getWriteMostdEitedTopicsByAccount(
        selectedAccount.id,
        selectedDateRangeData
      )
        .then((result) => {
          const tempResault = result.filter((it) => it.title != null);
          setMostEditedTopicData(tempResault);

          setPlotLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });
    } else if (selectedVersion && selectedLanguage && selectedTopic) {
      setPlotLoading(true);

      if (selectedTopic?.title !== 'ALL') {
        const seoScore = calculateSEOScore(selectedTopic?.metrics) / 100;
        const readabilityScore =
          calculateReadabilityScore(selectedTopic?.metrics) / 100;

        setSeoScore(seoScore ? seoScore : 0);
        setReadabilityScore(readabilityScore ? readabilityScore : 0);

        ANALYTICS_SERVICE.getWriteChangesOverTimeByTopic(
          selectedTopic.id,
          selectedLanguage.title,
          selectedDateRangeData
        )
          .then((result) => {
            setChangesOverTimeData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWritePublishByTopic(
          selectedTopic.id,
          selectedLanguage.title,
          selectedDateRangeData
        )
          .then((result) => {
            setPublishData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWriteContributorsByTopic(
          selectedTopic.id,
          selectedLanguage.title,
          selectedDateRangeData
        )
          .then((result) => {
            setContributorsData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWriteTopContributorsByTopic(
          selectedTopic.id,
          selectedLanguage.title,
          selectedDateRangeData
        )
          .then((result) => {
            setTopContributorsData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });
      } else {
        const metrics = getDocumentMetrics(
          selectedDocument,
          selectedDocument.defaultDocumentVersionSlug,
          selectedDocument.defaultLanguageCode
        );

        const seoScore = calculateSEOScore(metrics) / 100;
        const readabilityScore = calculateReadabilityScore(metrics) / 100;

        setSeoScore(seoScore ? seoScore : 0);
        setReadabilityScore(readabilityScore ? readabilityScore : 0);

        ANALYTICS_SERVICE.getWriteChangesOverTimeByDocument(
          selectedDocument.id,
          selectedLanguage.title,
          selectedVersion.id,
          selectedDateRangeData
        )
          .then((result) => {
            setChangesOverTimeData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWritePublishByDocument(
          selectedDocument.id,
          selectedLanguage.title,
          selectedVersion.id,
          selectedDateRangeData
        )
          .then((result) => {
            setPublishData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWriteContributorsByDocument(
          selectedDocument.id,
          selectedLanguage.title,
          selectedVersion.id,
          selectedDateRangeData
        )
          .then((result) => {
            setContributorsData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWriteTopContributorsByDocument(
          selectedDocument.id,
          selectedLanguage.title,
          selectedVersion.id,
          selectedDateRangeData
        )
          .then((result) => {
            setTopContributorsData(result);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });

        ANALYTICS_SERVICE.getWriteMostdEitedTopicsByDocument(
          selectedDocument.id,
          selectedDateRangeData
        )
          .then((result) => {
            const tempResault = result.filter((it) => it.title != null);
            setMostEditedTopicData(tempResault);

            setPlotLoading(false);
          })
          .catch((error) => {
            if (Number(error.status) === 504)
              props.enqueueSnackbar(t(`error.gateway-timeout`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
          });
      }
    }
  }, [
    selectedAccount,
    selectedDocument,
    selectedDateRangeData,
    selectedLanguage,
    selectedTopic,
    selectedDateRangeData,
  ]);

  const handleDateRenge = async (newValue: string) => {
    let tempSelectedDate = '';
    const now = getNowDate();

    switch (FORMAT_DATE.find((it) => it.id === Number(newValue))?.title) {
      case 'Last Week':
        tempSelectedDate = `startDate=${getLastWeekDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[0]);

        break;
      case 'Last Month':
        tempSelectedDate = `startDate=${getLastMonthDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[1]);

        break;
      case 'Last Year':
        tempSelectedDate = `startDate=${getLastYearDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[2]);

        break;
      case 'Custom':
        setIsCustomDatePicker(true);
        break;
    }

    return tempSelectedDate;
  };

  const handleSelectedDocument = async (newValue: string) => {
    let tempDoc = selectedAccountDocuments.filter((x) => x.id === newValue)[0];
    const documentLanguages = [{ id: 0, title: 'ALL' }];

    setSelectedVersion(null);
    setSelectedTopic(null);

    if (isAccount && Number(newValue) !== 0) {
      setSelectedDoc(tempDoc);

      const docLanguages = tempDoc.languages.map((x) => {
        return { id: x.languageCode, title: x.languageCode };
      });

      documentLanguages.push(...docLanguages);

      history.push(
        `/document/${selectedAccount.id}/${tempDoc.id}/write-analytics`
      );
    } else if (!isAccount && Number(newValue) === 0) {
      setSelectedDoc(selectedAccountDocuments[1]);
      tempDoc = selectedAccountDocuments[0];

      history.push(`/org/write-analytics`);
    } else {
      setSelectedDoc(tempDoc);
      const docLanguages = tempDoc.languages.map((x) => {
        return { id: x.languageCode, title: x.languageCode };
      });
      documentLanguages.push(...docLanguages);

      history.push(
        `/document/${selectedAccount.id}/${tempDoc.id}/write-analytics`
      );
    }

    setLanguages(documentLanguages);
    setSelectedLanguage(documentLanguages[0]);

    return tempDoc;
  };

  const handleCustomDate = (startDate, endDate) => {
    const formattedDateStart = formatDatePickerValue(startDate);
    const formattedDateEnd = formatDatePickerValue(endDate);
    setSelectedDateRange(
      `startDate=${formattedDateStart}&endDate=${formattedDateEnd}`
    );
  };

  const handleChangeSelect = async (element: string, newValue: string) => {
    setPlotLoading(true);

    const actions = {
      'Document Version': () =>
        setSelectedVersion(versions.filter((x) => x.id === newValue)[0]),
      Topic: () =>
        setSelectedTopic(
          topicsPerLanguageVersion.filter((x) => x.id === newValue)[0]
        ),
      Languages: () =>
        setSelectedLanguage(languages.filter((x) => x.id === newValue)[0]),
      'Date Range': async () =>
        setSelectedDateRange(await handleDateRenge(newValue)),
      Documents: async () =>
        setSelectedDocument(await handleSelectedDocument(newValue)),
    };

    actions[element]?.();
  };

  const handleCloseCustomRange = () => {
    const now = getNowDate();
    setSelectedDateRange(`startDate=${getLastMonthDate()}&endDate=${now}`);
    setSelectedDateTitle(FORMAT_DATE[1]);

    setIsCustomDatePicker(false);
  };

  const renderSelect = (element: string, selectedElement, items: any[]) => {
    return items.length > 0 ? (
      <SelectInput
        field={element}
        label={t(element)}
        showError={false}
        onChange={(value) => handleChangeSelect(element, value)}
        value={selectedElement ? selectedElement.id : items[0].id}
        showSearch={true}
      >
        {items &&
          items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          ))}
      </SelectInput>
    ) : null;
  };

  return (
    <div style={{ marginRight: 80, marginLeft: 80, marginTop: 40 }}>
      {((selectedAccount && isAccount) ||
        (!isAccount &&
          selectedDocument &&
          selectedLanguage &&
          selectedVersion)) && (
        <Breadcrumbs style={{ marginBottom: 10 }}>
          <Link
            color={'textPrimary'}
            style={{ fontSize: 'small', cursor: 'pointer' }}
            to={{
              pathname: '/',
              state: {
                accountSlug: selectedAccount.slug,
              },
            }}
          >
            {' '}
            {selectedAccount.title}
          </Link>
          {!isAccount ? (
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedAccount.id}/${selectedDocument.id}/content`}
            >
              {selectedDocument.title}
            </Link>
          ) : (
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/org/write-analytics`}
            >
              Write Metrics
            </Link>
          )}
          {!isAccount ? (
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedAccount.id}/${selectedDocument.id}/write-analytics`}
            >
              Write Metrics
            </Link>
          ) : null}
        </Breadcrumbs>
      )}

      <h3>{t('write-analytics-title')}</h3>

      <span
        style={{
          color: 'balck',
          fontSize: '14px',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          marginLeft: '5px',
          marginBottom: '14px',
        }}
      >
        {t('notice-metrics')}
      </span>

      <div
        style={{
          display: 'flex',
          paddingRight: 10,
          justifyContent: 'center',
          flexDirection: 'row-reverse',
          marginBottom: '10px',
          gap: 10,
        }}
      >
        {selectedLanguage &&
          (isCustomDatePicker ? (
            <div
              className="container-box-shape-sm"
              style={{
                width: '500px',
                height: '80px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#eef6fc',
              }}
            >
              <RangePicker
                format="YYYY-MM-DD"
                onChange={(dates) => {
                  handleCustomDate(
                    dates ? dates[0] : null,
                    dates ? dates[1] : null
                  );
                }}
              />

              <div
                style={{ height: '100%', display: 'flex', alignItems: 'start' }}
              >
                <CloseCircleOutlined
                  style={{ width: '20px', color: 'red' }}
                  onClick={() => handleCloseCustomRange()}
                />
              </div>
            </div>
          ) : (
            <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
              {renderSelect('Date Range', selectedDateRangeTitle, FORMAT_DATE)}
            </div>
          ))}

        {!isAccount && selectedTopic && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Topic', selectedTopic, topicsPerLanguageVersion)}
          </div>
        )}

        {selectedLanguage && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Languages', selectedLanguage, languages)}
          </div>
        )}

        {!isAccount && selectedVersion && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Document Version', selectedVersion, versions)}
          </div>
        )}

        {selectedDocument && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect(
              'Documents',
              selectedDocument,
              selectedAccountDocuments
            )}
          </div>
        )}
      </div>

      {loading || plotLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: 30,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {!isAccount && (
            <div
              className="container-box-shape"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                marginBottom: '25px',
              }}
            >
              <div className="flex-col">
                <h5 style={{ marginTop: '30px' }}>
                  {t('seo-title-write-analytics')}
                </h5>
                <Liquid height={200} width={200} {...seoConfig} />
              </div>

              <div className="flex-col">
                <h5 style={{ marginTop: '30px' }}>
                  {t('readibility-title-write-analytics')}
                </h5>
                <Liquid height={200} width={200} {...readabilityConfig} />
              </div>

              <div className="flex-col">
                <h5 style={{ marginBottom: '28px' }}>
                  {t('broken-links-write-analytics')}
                </h5>
                <Progress
                  width={160}
                  type="circle"
                  percent={99.99}
                  format={() => '0'}
                />
              </div>
            </div>
          )}

          <div className="flex-col-full">
            <Title className="item-title" level={4}>
              {t('changes-over-time-write-analytics')}
            </Title>
            {changesOverTimeData && changesOverTimeData?.length > 0 ? (
              <Area width={1000} {...changesOverTimeconfig} />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '130px',
                  paddingBottom: '130px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                No Data to Display!
              </div>
            )}
          </div>

          <Divider />

          <div className="flex-col-full">
            <Title className="item-title" level={4}>
              {t('publish-write-analytics')}
            </Title>
            {publishData && publishData?.length > 0 ? (
              <Column width={1000} {...publishTimeconfig} />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '130px',
                  paddingBottom: '130px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                No Data to Display!
              </div>
            )}
          </div>

          <Divider />

          <div className="flex-col-full">
            <Title className="item-title" level={4}>
              {t('changes-write-analytics')}
            </Title>
            {contributorsData && contributorsData?.length > 0 ? (
              <Column width={1000} {...cotributorConfig} />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '130px',
                  paddingBottom: '130px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                No Data to Display!
              </div>
            )}
          </div>

          <Divider />

          <div className="flex-col-full">
            <Title className="item-title" level={4}>
              {t('top-contributor-write-analytics')}
            </Title>
            <Table
              style={{ width: '40%' }}
              dataSource={topContributorsData}
              columns={TOP_CONTRIBUTORS_COLUMNS}
              pagination={
                topContributorsData?.length > 5 ? { pageSize: 5 } : false
              }
            />
          </div>

          <Divider />
          {(!(selectedTopic?.title !== 'ALL') || isAccount) && (
            <div className="flex-col-full">
              <Title className="item-title" level={4}>
                {t('most-edited-topic-write-analytics')}
              </Title>
              <Table
                style={{ width: '40%' }}
                pagination={
                  mostEditedTopicData?.length > 5 ? { pageSize: 5 } : false
                }
                dataSource={mostEditedTopicData}
                columns={MOST_EDITED_TOPICS_COLUMNS}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WriteAnalytics;
