import React from 'react';
import { withStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import { withSnackbar } from 'notistack';
import { Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Assignment as ManageIcon } from '@mui/icons-material';
import { DocumentContext } from '../../../contexts/document';

const styles: any = (theme) => ({
  fullHeight: {
    height: '100%',
    margin: '0',
  },
  topicsContainer: {
    padding: '0 !important',
  },
  topicsViewerActions: {
    margin: '5px 10px 0 0',
  },
  topicItem: {
    padding: '5px',
  },
  documentProperties: {
    marginLeft: '10px',
    padding: '5px 10px',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  select: {
    color: 'white!important',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
    padding: '4px!important',
    '& > div': {
      padding: '0 24px 0 0!important',
    },
    '& fieldset': {
      border: 'none!important',
    },
  },
  icon: {
    fill: 'white!important',
  },
});

function DocumentSelect(props) {
  const { selectedDoc, documents, setPreviousVersionId, setDocumentVersions } =
    React.useContext(DocumentContext);

  const { classes } = props;

  const { t } = useTranslation();

  const handleDocumentChanged = (args) => {
    if (args.target.value === 'manageDocuments') {
      props.history.push(`/`);
      return;
    }
    setDocumentVersions([]);
    setPreviousVersionId(null);
    const foundDocuments = documents.filter(
      (doc) => doc.id === args.target.value
    );
    if (foundDocuments.length) {
      props.history.push(
        `/document/${foundDocuments[0].account.id}/${foundDocuments[0].id}/content`
      );
    } else {
      props.enqueueSnackbar(t('error.doc-not-found'), {
        variant: 'error',
      });
      props.history.push('/');
    }
  };

  return (
    documents &&
    documents.length > 1 && (
      <Select
        className={classes.select}
        value={selectedDoc?.id}
        onChange={handleDocumentChanged}
        disableUnderline
        inputProps={{
          name: 'id',
          id: 'id',
          disableUnderline: true,
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {documents.map((document) => (
          <MenuItem key={document.id} value={document.id}>
            {document.title}
          </MenuItem>
        ))}

        <Divider />

        <MenuItem key="manageDocuments" value="manageDocuments">
          <ListItemIcon>
            <ManageIcon />
          </ListItemIcon>

          <ListItemText primary="Manage Documents" />
        </MenuItem>
      </Select>
    )
  );
}

export default withStyles(styles)(withSnackbar(DocumentSelect));
