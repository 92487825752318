import React, { useEffect, useState } from 'react';
import { Container, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NotificationsList from '../../components/notification/list';
import useGetUserNotifications from '../../api-hooks/user/useGetUserNotifications';

const NotificationsPage = (props) => {
  const [notifications, setNotifications] = useState<any[] | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  // eslint-disable-next-line
  let pageSize: number = 10;

  const { t } = useTranslation();

  const { getNotifications: getNotificationsFromServer } =
    useGetUserNotifications(notifications?.at(-1).createDateTime);

  const getNotifications = () => {
    if (hasMore) {
      getNotificationsFromServer()
        .then((response) => {
          if (!response.data || response.data.length < pageSize) {
            setHasMore(false);
          }
          setNotifications(
            response.data?.sort(
              (a, b) =>
                new Date(a.updateDateTime).getTime() -
                new Date(b.updateDateTime).getTime()
            ) || []
          );
        })
        .catch(() => {
          // empty-state
        });
    }
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ marginTop: 30 }}>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        {t('notifications')}
      </Typography>
      <Divider />
      <NotificationsList
        notifications={notifications}
        fullWidth={true}
        getNotifications={getNotifications}
        setNotifications={setNotifications}
        {...props}
      />
    </Container>
  );
};

export default NotificationsPage;
