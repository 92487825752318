import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const deleteUserRequest = ({ UserId }: { UserId: string }) => {
  return axios
    .delete(`/api/user/delete-user/${UserId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteUser() {

  const {
    mutateAsync: deleteUser,
    isPending: isDeletingUser,
    isSuccess: isSuccessDeletingUser,
  } = useMutation({
    mutationFn: deleteUserRequest,
  });

  return {
    deleteUser,
    isDeletingUser,
    isSuccessDeletingUser,
  };
}
