import React from 'react';

const PricingTable = () => {
  return (
    <div style={{ width: '1500px' }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <stripe-pricing-table
        pricing-table-id="prctbl_1LOACOAjdz5E4wyy409ERKgs"
        publishable-key="pk_test_aDuixjwmZpsfBBqM190d1JjD00rHYHvbVk"
      />
    </div>
  );
};

export default PricingTable;
