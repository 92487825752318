class TemplateProvider {
    public data;

    constructor(data: any) {
        this.data = data;
    }

    getScriptContent(jsonData, index, context) {
        switch (this.data.type) {
            case "jsx":
                return this.parseJSX(jsonData, index, context);
            default:
                return "";
        }
    }

    parseJSX(jsonData, index, context) {
        const array = this.data.content.split("export default");
        let componentName = array.pop();
        if(componentName) {
            componentName = componentName.trim();
            componentName = componentName[componentName.length - 1] === ";" ? componentName.substring(0, componentName.length - 1) : componentName;

            return `const { createElement } = React; const render = ReactDOM.render; const html = htm.bind(createElement); const context = ${JSON.stringify(context)}; ` + this.data.content + `render(html\`<\${${componentName}} data=\${${JSON.stringify(jsonData)}}/>\`, document.getElementById("preview-section-${index}"));`;
        }
        return "";
    }
}

export default TemplateProvider;