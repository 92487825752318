import { baseWebApi } from "../base-web-api";

export class AnalyticsService extends baseWebApi {
  getDocumentView = (documentId, docVersionId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `document/${documentId}/view?${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getAccountView = (accountId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `account/${accountId}/view?${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getTopicView = (topicId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `topic/${topicId}/view?${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) =>
      response.json());
  };

  getDocumentViewByLocation = (documentId, docVersionId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `document/${documentId}/location_base_view?${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getAccountViewByLocation = (accountId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `account/${accountId}/location_base_view?${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getTopicViewByLocation = (topicId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `topic/${topicId}/location_base_view?${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getTopTopicsByDocument = (documentId, docVersionId, dateRange , languageCode) => {
    return this.sendRequest(
      "get",
      `document/${documentId}/top-topics?${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getTopTopicsByAccount = (accountId, dateRange) => {
    return this.sendRequest(
      "get",
      `account/${accountId}/top-topics?${dateRange}`
    ).then((response: Response) => response.json());
  };

  /************Write Analytics API *******************/

  getWritePublishByAccount = (accountId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/publish?accountId=${accountId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWritePublishByTopic  = (topicId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/publish?topicId=${topicId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWritePublishByDocument = (documentId, languageCode, docVersionId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/publish?documentId=${documentId}&${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteContributorsByAccount = (accountId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/contributers?accountId=${accountId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteContributorsByTopic  = (topicId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/contributers?topicId=${topicId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteContributorsByDocument = (documentId, languageCode, docVersionId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/contributers?documentId=${documentId}&${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteTopContributorsByAccount = (accountId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/top-contributers?accountId=${accountId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteTopContributorsByTopic  = (topicId, languageCode, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/top-contributers?topicId=${topicId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteTopContributorsByDocument = (documentId, languageCode, docVersionId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/top-contributers?documentId=${documentId}&${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteMostdEitedTopicsByAccount = (accountId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/most-edited-topics?accountId=${accountId}&${dateRange}`
    ).then((response: Response) => response.json());
  };

  getWriteMostdEitedTopicsByDocument = (documentId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/most-edited-topics?documentId=${documentId}&${dateRange}`
    ).then((response: Response) => response.json());
  };

  getWriteChangesOverTimeByAccount = (accountId, languageCode,  dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/changesOverTime?accountId=${accountId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteChangesOverTimeByTopic = (topicId, languageCode,  dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/changesOverTime?topicId=${topicId}&${dateRange}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

  getWriteChangesOverTimeByDocument = (documentId, languageCode, docVersionId, dateRange) => {
    return this.sendRequest(
      "get",
      `analytics/changesOverTime?documentId=${documentId}&${dateRange}&documentVersionId=${docVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  };

}
