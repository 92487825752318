import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RevertTopicRequest } from '../../api-schemas/topic';

const revertTopicRequest = (data: RevertTopicRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Topic/Revert', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useRevertTopic({
  documentVersionId,
  languageCode,
  topicId,
}: {
  documentVersionId?: string;
  languageCode?: string;
  topicId?: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: revertTopic,
    isPending: isRevertingTopic,
    isSuccess: isSuccessRevertingTopic,
  } = useMutation({
    mutationFn: revertTopicRequest,
    onSuccess: () => {
      if (documentVersionId) {
        queryClient.invalidateQueries({
          queryKey: ['draftTopicTree', documentVersionId, languageCode],
        });
      }

      queryClient.invalidateQueries({
        queryKey: ['draftTopic', topicId, languageCode],
      });
    },
  });

  return {
    revertTopic,
    isRevertingTopic,
    isSuccessRevertingTopic,
  };
}
