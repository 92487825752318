import React, { useEffect, useContext } from 'react';

import { StructuredDataContext } from '../structured-data-context';
import useGetProfile from '../../api-hooks/user/useGetUserProfile';

const initialState: ProfileContextType = {
  profileData: {},
};

type ProfileContextType = {
  profileData: any;
};

export const ProfileContext =
  React.createContext<ProfileContextType>(initialState);

export function ProfileProvider({ children }) {
  const { context, setContext } = useContext(StructuredDataContext);

  const { profile } = useGetProfile();

  const value = { profileData: profile };

  useEffect(() => {
    if (profile) {
      localStorage.setItem('userId', profile.id);
      localStorage.setItem('user_name', profile.displayName);

      setContext({ ...context, currentUser: profile });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      if (window.hj) {
        const hotjarScript = document.createElement('script');
        hotjarScript.innerHTML = `
                        // Install this entire snippet AFTER the Hotjar tracking code.
                        var userId = ${profile.id} || null; // Replace your_user_id with your own if available.
                        window.hj('identify', userId, {
                            'firebaseUserId': ${profile.firebaseUserId},
                            'email': ${profile.email},
                            'displayName': ${profile.displayName}
                        });
                    `;
        document.head.appendChild(hotjarScript);
      }
    }
  }, [profile]);

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}
