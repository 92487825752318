import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { AccountPaymentMethods } from '../../api-schemas/account';

const getAccountPaymentMethodsRequest = (accountId?: string) => {
  return axios
    .get<AccountPaymentMethods>(`/api/Account/${accountId}/PaymentMethods`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccountPaymentMethods(accountId: string) {
  const {
    data,
    refetch: getAccountPaymentMethods,
    isFetching: isLoadingAccountPaymentMethods,
    isError: isErrorLoadingAccountPaymentMethods,
    isSuccess: isSuccessLoadingAccountPaymentMethods,
  } = useQuery<AccountPaymentMethods>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'paymentMethods', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId),
    queryFn: () => getAccountPaymentMethodsRequest(accountId),
  });

  const accountPaymentMethods = useMemo(() => data, [data]);

  return {
    accountPaymentMethods,
    isLoadingAccountPaymentMethods,
    isErrorLoadingAccountPaymentMethods,
    isSuccessLoadingAccountPaymentMethods,

    getAccountPaymentMethods,
  };
}
