import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  Grid,
  ListItemIcon,
  SvgIcon,
  Avatar,
} from '@mui/material';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import DescriptionIcon from '@mui/icons-material/Description';
import { withSnackbar } from 'notistack';
import { DocumentContext } from '../../../../contexts/document';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import Reviewers from './reviewers';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styles from './card.module.css';

const iconStyle = {
  width: 18,
  height: 18,
  marginRight: '2px',
  marginLeft: '10px',
};

const format: any = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function PublishCard(props) {
  const { topicsPerLanguageVersion } = React.useContext(DocumentContext);
  const isPublished: boolean = props.publish.status === 'Published';

  const [updatedBy, setUpdatedBy] = useState<any>();
  const [updatedByEmail, setUpdatedByEmail] = useState<any>();
  const [createdBy, setCreatedBy] = useState<any>();
  const [createdByEmail, setCreatedByEmail] = useState<any>();
  const [statusChangedBy, setStatusChangedBy] = useState<any>();
  const [statusChangedByEmail, setStatusChangedByEmail] = useState<any>();

  const { t } = useTranslation();

  useEffect(() => {
    if (props.accesses && props.accesses.length) {
      const updatedBy = props.accesses.find(
        (access) => access.principalId === props.publish.updatedBy
      );
      const createdBy = props.accesses.find(
        (access) => access.principalId === props.publish.createdBy
      );
      const statusChangedBy = props.accesses.find(
        (access) => access.principalId === props.publish.statusChangedBy
      );
      setUpdatedBy(updatedBy);
      setCreatedBy(createdBy);
      setStatusChangedBy(statusChangedBy);
      setUpdatedByEmail(updatedBy ? updatedBy.email : 'Unknown');
      setCreatedByEmail(createdBy ? createdBy.email : 'Unknown');
      setStatusChangedByEmail(
        statusChangedBy ? statusChangedBy.email : 'Unknown'
      );
    }
    // eslint-disable-next-line
  }, [props.accesses]);

  const calculateTopicStatuses = () => {
    let deletedTopics: any[] = [];
    let notPublishedYetTopics: any[] = [];
    let modifiedTopics: any[] = [];

    const topicsOfPublishRequest =
      topicsPerLanguageVersion[
        props.publish.documentVersionId + props.publish.languageCode
      ];

    if (
      topicsOfPublishRequest &&
      topicsOfPublishRequest !== 'loading' &&
      !isPublished
    ) {
      props.publish.topics.forEach((topic) => {
        const topicWithDetails = topicsOfPublishRequest.find(
          (item) => topic.topicId === item.topicId
        );
        if (topicWithDetails) {
          const topicIsAlreadyPublished =
            topicWithDetails.latestTopicVersionId ===
            topicWithDetails.publishedTopicVersionId;
          if (topicWithDetails.action === 'Deleted') {
            deletedTopics.push(topicWithDetails);
          } else if (
            !topicIsAlreadyPublished &&
            topicWithDetails.topicVersionNumber < 1.0
          )
            notPublishedYetTopics.push(topicWithDetails);
          else if (
            !topicIsAlreadyPublished &&
            topicWithDetails.topicVersionNumber >= 1.0
          )
            modifiedTopics.push(topicWithDetails);
        }
      });
    }
    if (isPublished) {
      deletedTopics = props.publish.topics.filter(
        (item) => item.action === 'Deleted'
      );
      notPublishedYetTopics = props.publish.topics.filter(
        (item) =>
          item.action === 'Created' &&
          item.previousPublishedTopicVersionId === null
      );
      modifiedTopics = props.publish.topics.filter(
        (item) =>
          item.action === 'Created' &&
          item.previousPublishedTopicVersionId !== null
      );
    }
    return {
      deletedTopics: deletedTopics,
      notPublishedYetTopics: notPublishedYetTopics,
      modifiedTopics: modifiedTopics,
    };
  };

  const versionTitle =
    props.versions.length === 0
      ? ''
      : props.versions.find(
            (version) => version.id === props.publish.documentVersionId
          )
        ? props.versions.find(
            (version) => version.id === props.publish.documentVersionId
          ).title
        : 'Deleted';
  const { modifiedTopics, deletedTopics, notPublishedYetTopics } =
    calculateTopicStatuses();

  const getPassedActivities = () => {
    const totalNumberOfActivities =
      props.publish.processInstance.activityInstances.length;
    let numberOfPassedActivities = 0;
    props.publish.processInstance.activityInstances.map((activity) => {
      if (activity.activityStatus === 'COMPLETED') {
        numberOfPassedActivities++;
      }
      return true;
    });
    return numberOfPassedActivities + '/' + totalNumberOfActivities;
  };

  const getProcessStatusContent = () => {
    let title = '';
    let icon: any = null;

    switch (props.publish.processInstance.processStatus) {
      case 'OPEN':
        title = t('pending');
        icon = <PauseCircleOutlineIcon style={{ color: '#bdbdbd' }} />;
        break;
      case 'COMPLETED':
        title = t('completed');
        icon = <CheckCircleOutlineIcon style={{ color: 'green' }} />;
        break;
      case 'FAILED':
        title = t('failed');
        icon = <HighlightOffIcon style={{ color: 'red' }} />;
        break;
      default:
        break;
    }

    return (
      <Tooltip arrow title={title}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          {icon}
        </div>
      </Tooltip>
    );
  };

  return (
    <span className={styles.rowsStyle} style={props.style}>
      <ListItem
        style={{ padding: '0 16px', borderBottom: '1px solid #ebebeb' }}
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 8,
            width: '100%',
          }}
        >
          <Col xs={12} xl={8}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 4,
              }}
            >
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <Grid>
                  <Tooltip
                    arrow
                    title={
                      'Created ' +
                      moment(
                        new Date(props.publish.createDateTime),
                        'YYYYMMDDLL'
                      ).fromNow() +
                      ' by ' +
                      (createdBy
                        ? createdBy.displayName || createdByEmail
                        : props.accesses.length > 0
                          ? 'Deleted user'
                          : 'Loading...')
                    }
                  >
                    {createdBy ? (
                      createdBy.photoUrl ? (
                        <Avatar
                          variant="square"
                          alt={createdBy.displayName || createdByEmail}
                          src={createdBy.photoUrl}
                          style={{ width: 40, height: 40, marginRight: 15 }}
                        />
                      ) : (
                        <Avatar
                          variant="square"
                          style={{ width: 40, height: 40, marginRight: 15 }}
                        >
                          {(createdBy.displayName || createdByEmail)[0]}
                        </Avatar>
                      )
                    ) : (
                      <Avatar
                        variant="square"
                        style={{ width: 40, height: 40, marginRight: 15 }}
                      >
                        D
                      </Avatar>
                    )}
                  </Tooltip>
                </Grid>
                <ListItemText
                  primary={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title={
                          'Description: ' +
                          (props.publish.description || 'No Description')
                        }
                        arrow
                      >
                        <Link
                          style={{
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          to={window.location.pathname + '/' + props.publish.id}
                        >
                          <span style={{ marginRight: '7px' }}>
                            {props.publish.title
                              ? props.publish.title
                              : 'No title'}
                          </span>
                        </Link>
                      </Tooltip>
                      {props.publish.status === 'Closed' && (
                        <Tooltip
                          title={'Number of changed topics in publish request'}
                          arrow
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <DescriptionIcon
                              style={{ ...iconStyle, marginLeft: 0 }}
                            />
                            <span>{props.publish.topics.length}</span>
                          </div>
                        </Tooltip>
                      )}
                      {props.publish.status !== 'Closed' && (
                        <span style={{ marginLeft: 5, display: 'flex' }}>
                          {deletedTopics.length > 0 && (
                            <Tooltip title={'Deleted topics'} arrow>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: 10,
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: 'red',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM6 7h4a1 1 0 0 1 .117 1.993L10 9H6a1 1 0 0 1-.117-1.993L6 7h4-4z"></path>
                                </SvgIcon>
                                <span>{deletedTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                          {modifiedTopics.length > 0 && (
                            <Tooltip title={'Modified topics'} arrow>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: 10,
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: '#fc9403',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path>
                                </SvgIcon>
                                <span>{modifiedTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                          {notPublishedYetTopics.length > 0 && (
                            <Tooltip title={'New topics'} arrow>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: 'green',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 5a1 1 0 0 1 .993.883L9 6v1h1a1 1 0 0 1 .117 1.993L10 9H9v1a1 1 0 0 1-1.993.117L7 10V9H6a1 1 0 0 1-.117-1.993L6 7h1V6a1 1 0 0 1 1-1z"></path>
                                </SvgIcon>
                                <span>{notPublishedYetTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      )}
                    </div>
                  }
                  secondary={
                    <div>
                      <span>
                        <ListItem
                          style={{
                            paddingTop: '0px',
                            paddingRight: '0px',
                            paddingLeft: 0,
                          }}
                        >
                          <Tooltip
                            title={
                              'At ' +
                              new Date(
                                props.publish.status === 'Open'
                                  ? props.publish.updateDateTime
                                  : props.publish.statusChangedDateTime
                              ).toLocaleString('en-US', format)
                            }
                            arrow
                          >
                            <span
                              style={{
                                fontSize: '13px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span>
                                {(props.publish.status === 'Open'
                                  ? 'Updated '
                                  : props.publish.status + ' ') +
                                  moment(
                                    new Date(
                                      props.publish.status === 'Open'
                                        ? props.publish.updateDateTime
                                        : props.publish.statusChangedDateTime
                                    ),
                                    'YYYYMMDDLL'
                                  ).fromNow() +
                                  ' by '}
                              </span>
                              <ListItemText
                                primary={
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      marginLeft: 5,
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {updatedBy || statusChangedBy
                                      ? props.publish.status === 'Open' &&
                                        updatedBy
                                        ? updatedBy?.displayName ||
                                          updatedByEmail
                                        : statusChangedBy
                                          ? statusChangedBy.displayName ||
                                            statusChangedByEmail
                                          : ''
                                      : props.accesses.length > 0
                                        ? 'Deleted user'
                                        : 'Loading...'}
                                  </span>
                                }
                              ></ListItemText>
                            </span>
                          </Tooltip>
                        </ListItem>
                      </span>
                    </div>
                  }
                  style={{ marginTop: '10px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6} xl={8}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props.publish.processInstance && (
                <Fragment>
                  {getProcessStatusContent()}
                  <Tooltip arrow title={t<string>('number-of-completed-rules')}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <PlaylistAddCheckIcon style={{ marginRight: 3 }} />
                      {getPassedActivities()}
                    </div>
                  </Tooltip>
                </Fragment>
              )}
              <Tooltip arrow title={t<string>('number-of-comments')}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 20,
                  }}
                >
                  <ChatBubbleOutlineIcon
                    style={{ marginRight: 3, width: 20, height: 20 }}
                  />
                  {props.publish.commentsCount}
                </div>
              </Tooltip>
            </div>
          </Col>
          <Col
            xs={6}
            xl={8}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Reviewers
                    accesses={props.accesses}
                    reviewers={props.publish.reviews}
                    smallSize={true}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <Tooltip title={'Language'} arrow>
                    <ListItemIcon
                      style={{
                        minWidth: '20px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Fragment>
                        <GTranslateIcon style={iconStyle}></GTranslateIcon>
                        {props.publish.languageCode}
                      </Fragment>
                    </ListItemIcon>
                  </Tooltip>
                  {props.versions.length > 0 && (
                    <div>
                      <Tooltip arrow title={'Variant of the publish'}>
                        <ListItemIcon
                          style={{
                            minWidth: '20px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Fragment>
                            <DescriptionIcon
                              style={iconStyle}
                            ></DescriptionIcon>
                            {versionTitle}
                          </Fragment>
                        </ListItemIcon>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ListItem>
      <Divider light />
    </span>
  );
}

export default withSnackbar(PublishCard);
