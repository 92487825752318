import React, { useState } from 'react';
import moment from 'moment/moment';
import { RiRecordCircleLine } from 'react-icons/ri';

type PublishRequestHistoryItemPropsType = {
  publishRequest: any;
  isSelected: boolean;
  isSelectedInCompare: boolean;
  isInCompareRange: boolean;
  isAnyPublishRequestSelected: boolean;
  selectedPublishRequestsToCompare: {
    sourcePublishRequest: any;
    targetPublishRequest: any;
  };
  hoveredPublishRequest: any;
  handleSelectedPublishRequestsToCompare: (publishRequest: any) => void;
  handleSelectedPublishRequest: (publishRequest: any) => void;
  handlePublishRequestTimeIconHover: (publishRequest: any) => void;
};

const PublishRequestHistoryItem = ({
  publishRequest,
  isSelected,
  isSelectedInCompare,
  isInCompareRange,
  isAnyPublishRequestSelected,
  selectedPublishRequestsToCompare,
  hoveredPublishRequest,
  handleSelectedPublishRequestsToCompare,
  handleSelectedPublishRequest,
  handlePublishRequestTimeIconHover,
}: PublishRequestHistoryItemPropsType) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const isTargetPublishRequest =
    selectedPublishRequestsToCompare.targetPublishRequest &&
    ((hovered &&
      moment(publishRequest.statusChangedDateTime).isAfter(
        moment(
          selectedPublishRequestsToCompare.targetPublishRequest
            .statusChangedDateTime
        )
      )) ||
      publishRequest.id ===
        selectedPublishRequestsToCompare.targetPublishRequest.id);

  return (
    <div
      className={`publish-request-history-item${isSelectedInCompare ? ' selected' : ''}${isInCompareRange || hovered ? ' selected-in-compare' : ''}`}
      style={
        isSelected || isSelectedInCompare
          ? { backgroundColor: '#1976d2', color: '#fff' }
          : isInCompareRange || hovered
            ? { backgroundColor: '#1976d282', color: '#fff' }
            : { backgroundColor: 'white' }
      }
      onClick={() => handleSelectedPublishRequest(publishRequest)}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginBottom: 5 }}>{publishRequest.title}</span>
        <span>
          Published
          {` ${moment(new Date(publishRequest.statusChangedDateTime), 'YYYYMMDDLL').fromNow()}`}
        </span>
      </div>

      <RiRecordCircleLine
        onMouseEnter={() => {
          if (isAnyPublishRequestSelected) {
            setHovered(true);
            handlePublishRequestTimeIconHover(publishRequest);
          }
        }}
        onMouseLeave={() => {
          setHovered(false);
          handlePublishRequestTimeIconHover(null);
        }}
        onClick={(event) => {
          event.stopPropagation();

          if (isAnyPublishRequestSelected) {
            handleSelectedPublishRequestsToCompare(publishRequest);
          } else {
            handleSelectedPublishRequest(publishRequest);
          }
        }}
        className="circle-icon"
      />

      {(isSelectedInCompare &&
        (hoveredPublishRequest ||
          (selectedPublishRequestsToCompare.sourcePublishRequest &&
            selectedPublishRequestsToCompare.targetPublishRequest))) ||
      isInCompareRange ||
      hovered ? (
        <span
          className="time-line"
          style={
            isTargetPublishRequest
              ? { bottom: 0, height: isInCompareRange ? 60 : 25 }
              : { top: 0, height: isInCompareRange ? 60 : 25 }
          }
        />
      ) : null}
    </div>
  );
};

export default PublishRequestHistoryItem;
