import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../inputs/text-input';
import { DiBingSmall } from 'react-icons/di';
import { SiGoogletagmanager } from 'react-icons/si';
import {
  Typography as AntTypography,
  Divider,
  Switch,
  Button,
  Col,
} from 'antd';
import TimelineIcon from '@mui/icons-material/Timeline';
import { GoogleOutlined } from '@ant-design/icons';
import '../styles.css';

const { Title, Text } = AntTypography;
const styles: any = (theme: Theme) => ({
  analyticsForm: {
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 0,
    '& > div': {
      alignItems: 'center',
    },
  },
  errorBox: {
    color: 'red',
  },
  expansionSummary: {
    flexDirection: 'column',
    padding: 0,
  },
  checkBox: {
    padding: 0,
    marginRight: 10,
  },
  helpBox: {
    fontSize: '12px',
    marginTop: '5px',
    color: '#726e6e',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    '& label.Mui-focused': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& label.MuiFormLabel-filled': {
      backgroundColor: '#fff',
      paddingRight: 5,
      paddingLeft: 5,
    },
    '& legend': {
      maxWidth: 0,
    },
  },
});

function AccountIntegrationsSettings(props: any) {
  const [trackingId, setTrackingId] = useState<string>('');
  const [analyticsChecked, setAnalyticsChecked] = useState<boolean>(false);
  const [googleSiteVerificationId, setGoogleSiteVerificationId] =
    useState<string>('');
  const [googleSearchConsoleChecked, setGoogleSearchConsoleChecked] =
    useState<boolean>(false);
  const [bingSiteVerificationId, setBingSiteVerificationId] =
    useState<string>('');
  const [bingSearchConsoleChecked, setBingSearchConsoleChecked] =
    useState<boolean>(false);
  const [googleTagManagerId, setGoogleTagManagerId] = useState<string>('');
  const [googleTagManagerChecked, setGoogleTagManagerChecked] =
    useState<boolean>(false);
  const [accountConfiguration, setAccountConfiguration] = useState<any>({});

  useEffect(() => {
    if (
      (props.accountConfiguration.trackingId !== null &&
        !!props.accountConfiguration.trackingId) ||
      analyticsChecked
    ) {
      setAnalyticsChecked(true);
      setTrackingId(props.accountConfiguration.trackingId);
    } else {
      setAnalyticsChecked(false);
      setTrackingId('');
    }

    if (
      (props.accountConfiguration.googleSiteVerificationId !== null &&
        !!props.accountConfiguration.googleSiteVerificationId) ||
      googleSearchConsoleChecked
    ) {
      setGoogleSearchConsoleChecked(true);
      setGoogleSiteVerificationId(
        props.accountConfiguration.googleSiteVerificationId
      );
    } else {
      setGoogleSearchConsoleChecked(false);
      setGoogleSiteVerificationId('');
    }

    if (
      (props.accountConfiguration.bingSiteVerificationId !== null &&
        !!props.accountConfiguration.bingSiteVerificationId) ||
      bingSearchConsoleChecked
    ) {
      setBingSearchConsoleChecked(true);
      setBingSiteVerificationId(
        props.accountConfiguration.bingSiteVerificationId
      );
    } else {
      setBingSearchConsoleChecked(false);
      setBingSiteVerificationId('');
    }

    if (
      (props.accountConfiguration.googleTagManagerId !== null &&
        !!props.accountConfiguration.googleTagManagerId) ||
      googleTagManagerChecked
    ) {
      setGoogleTagManagerChecked(true);
      setGoogleTagManagerId(props.accountConfiguration.googleTagManagerId);
    } else {
      setGoogleTagManagerChecked(false);
      setGoogleTagManagerId('');
    }

    setAccountConfiguration(props.accountConfiguration);
    // eslint-disable-next-line
  }, [props.accountConfiguration]);

  const raiseAccountConfigurationChanged = (accountConfiguration: any) => {
    if (props.onAccountConfigurationChanged)
      props.onAccountConfigurationChanged(accountConfiguration);
  };

  const classes = props.classes;

  const { t } = useTranslation();

  const handleAnalyticsExpansionClick = () => {
    const isAnalyticsChecked: boolean = analyticsChecked;
    if (analyticsChecked) {
      const newAccountConfiguration = Object.assign({}, accountConfiguration);
      newAccountConfiguration.trackingId = '';
      setTrackingId('');
      raiseAccountConfigurationChanged(newAccountConfiguration);
    }
    setAnalyticsChecked(!isAnalyticsChecked);
  };

  const handleAnalyticsChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    setTrackingId(value);
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.trackingId = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleGoogleSearchConsoleExpansionClick = () => {
    const isGoogleSearchConsoleChecked: boolean = googleSearchConsoleChecked;
    if (googleSearchConsoleChecked) {
      const newAccountConfiguration = Object.assign({}, accountConfiguration);
      newAccountConfiguration.googleSiteVerificationId = '';
      setGoogleSiteVerificationId('');
      raiseAccountConfigurationChanged(newAccountConfiguration);
    }
    setGoogleSearchConsoleChecked(!isGoogleSearchConsoleChecked);
  };

  const handleGoogleVerificationIdChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value: string = event.target.value;
    setGoogleSiteVerificationId(value);
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.googleSiteVerificationId = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleBingSearchConsoleExpansionClick = () => {
    const isBingSearchConsoleChecked: boolean = bingSearchConsoleChecked;
    if (bingSearchConsoleChecked) {
      const newAccountConfiguration = Object.assign({}, accountConfiguration);
      newAccountConfiguration.bingSiteVerificationId = '';
      setBingSiteVerificationId('');
      raiseAccountConfigurationChanged(newAccountConfiguration);
    }
    setBingSearchConsoleChecked(!isBingSearchConsoleChecked);
  };

  const handleBingVerificationIdChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value: string = event.target.value;
    setBingSiteVerificationId(value);
    const newAccountConfiguration = Object.assign({}, accountConfiguration);
    newAccountConfiguration.bingSiteVerificationId = value;
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleGoogleTagManagerExpansionClick = () => {
    const isGoogleTagManagerChecked: boolean = googleTagManagerChecked;

    if (googleTagManagerChecked) {
      const newAccountConfiguration = Object.assign({}, accountConfiguration);

      newAccountConfiguration.googleTagManagerId = '';

      setGoogleTagManagerId('');
      raiseAccountConfigurationChanged(newAccountConfiguration);
    }

    setGoogleTagManagerChecked(!isGoogleTagManagerChecked);
  };

  const handleGoogleTagManagerIdChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value: string = event.target.value;
    const newAccountConfiguration = Object.assign({}, accountConfiguration);

    setGoogleTagManagerId(value);

    newAccountConfiguration.googleTagManagerId = value;

    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  return (
    <div>
      <Col xs={24} style={{ display: 'block' }}>
        <div style={{ borderRadius: '5px', padding: '10px', marginBottom: 15 }}>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div
              className={
                window.innerWidth > 670
                  ? 'settings-icon-container-show'
                  : 'settings-icon-container-hide'
              }
            >
              <TimelineIcon
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>

            <div
              style={{ flexDirection: 'column' }}
              className={
                window.innerWidth > 670
                  ? 'settings-detail-container-show-partly'
                  : 'settings-detail-container-show-full'
              }
            >
              <Title className="settings-item-title" level={4}>
                {t('google-analytics')}
              </Title>

              <Text>{t<string>('analytics-help')}</Text>

              <br />

              <Switch
                checked={analyticsChecked}
                onChange={() => handleAnalyticsExpansionClick()}
                style={{ marginTop: 10 }}
              />

              <div style={{ marginTop: 15, width: '90%' }}>
                <TextInput
                  disabled={!analyticsChecked}
                  field="analytics-id"
                  required={analyticsChecked}
                  onChange={handleAnalyticsChanged}
                  value={trackingId}
                  placeholder={trackingId ? '' : t('analytics-id')}
                  showError={false}
                />

                <span className={classes.helpBox}>
                  {t('analytics-tracking-id-help')}

                  <br />

                  {t('analytics-measurement-id-help')}
                </span>

                <div id="analytics-error" className={classes.errorBox}></div>
              </div>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex', marginTop: 10 }}>
            <div
              className={
                window.innerWidth > 670
                  ? 'settings-icon-container-show'
                  : 'settings-icon-container-hide'
              }
            >
              <GoogleOutlined
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>
            <div
              style={{ flexDirection: 'column' }}
              className={
                window.innerWidth > 670
                  ? 'settings-detail-container-show-partly'
                  : 'settings-detail-container-show-full'
              }
            >
              <Title className="settings-item-title" level={4}>
                {t('google-search-console')}
              </Title>
              <Text>
                {t<string>('google-search-console-help') +
                  (accountConfiguration && !accountConfiguration.customDomain
                    ? '. You should set custom domain first.'
                    : '')}
              </Text>
              <br />
              <Switch
                disabled={
                  accountConfiguration && !accountConfiguration.customDomain
                }
                checked={googleSearchConsoleChecked}
                onChange={() => handleGoogleSearchConsoleExpansionClick()}
                style={{ marginTop: 10 }}
              />
              <div style={{ marginTop: 15, width: '90%' }}>
                <TextInput
                  field="google-verification-id"
                  disabled={!accountConfiguration.customDomain}
                  required={googleSearchConsoleChecked}
                  onChange={handleGoogleVerificationIdChanged}
                  value={googleSiteVerificationId}
                  placeholder={t('google-verification-id')}
                  showError={false}
                />
                <ol style={{ marginTop: 10 }}>
                  <li>
                    <Text>
                      {t('google-verification-id-help-1')}
                      <a
                        href="https://search.google.com/search-console/about"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('google-verification-id-help-1-link')}
                      </a>
                    </Text>
                  </li>
                  <li>
                    <Text>{t('google-verification-id-help-2')}</Text>
                  </li>
                  <li>
                    <Text>{t('google-verification-id-help-3')}</Text>
                  </li>
                  <li>
                    <Text>{t('google-verification-id-help-4')}</Text>
                  </li>
                </ol>
                <div
                  id="google-verification-id-error"
                  className={classes.errorBox}
                ></div>
              </div>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex', marginTop: 10 }}>
            <div
              className={
                window.innerWidth > 670
                  ? 'settings-icon-container-show'
                  : 'settings-icon-container-hide'
              }
            >
              <SiGoogletagmanager
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>

            <div
              style={{ flexDirection: 'column' }}
              className={
                window.innerWidth > 670
                  ? 'settings-detail-container-show-partly'
                  : 'settings-detail-container-show-full'
              }
            >
              <Title className="settings-item-title" level={4}>
                {t('google-tag-manager')}
              </Title>

              <Text>
                {t<string>('google-tag-manager-help') +
                  (accountConfiguration && !accountConfiguration.customDomain
                    ? '. You should set custom domain first.'
                    : '')}
              </Text>

              <br />

              <Switch
                disabled={
                  accountConfiguration && !accountConfiguration.customDomain
                }
                checked={googleTagManagerChecked}
                onChange={() => handleGoogleTagManagerExpansionClick()}
                style={{ marginTop: 10 }}
              />

              <div style={{ marginTop: 15, width: '90%' }}>
                <TextInput
                  field="google-tag-manager-id"
                  disabled={!accountConfiguration.customDomain}
                  required={googleTagManagerChecked}
                  onChange={handleGoogleTagManagerIdChanged}
                  value={googleTagManagerId}
                  placeholder={t('google-tag-manager-id')}
                  showError={false}
                />

                <ol style={{ marginTop: 10 }}>
                  <li>
                    <Text>
                      {t('google-tag-manager-id-help-1')}
                      <a
                        href="https://tagmanager.google.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('google-tag-manager-id-help-1-link')}
                      </a>
                    </Text>
                  </li>
                  <li>
                    <Text>{t('google-tag-manager-id-help-2')}</Text>
                  </li>
                  <li>
                    <Text>{t('google-tag-manager-id-help-3')}</Text>
                  </li>
                  <li>
                    <Text>{t('google-tag-manager-id-help-4')}</Text>
                  </li>
                </ol>

                <div
                  id="google-tag-manager-id-error"
                  className={classes.errorBox}
                ></div>
              </div>
            </div>
          </div>

          <Divider />

          <div style={{ display: 'flex', marginTop: 10 }}>
            <div
              className={
                window.innerWidth > 670
                  ? 'settings-icon-container-show'
                  : 'settings-icon-container-hide'
              }
            >
              <DiBingSmall
                style={{
                  fontSize: '36px',
                  color: '#1a73e8',
                  marginLeft: 6,
                  marginTop: '-5px',
                }}
              />
            </div>
            <div
              style={{ flexDirection: 'column' }}
              className={
                window.innerWidth > 670
                  ? 'settings-detail-container-show-partly'
                  : 'settings-detail-container-show-full'
              }
            >
              <Title className="settings-item-title" level={4}>
                {t('bing-search-console')}
              </Title>
              <Text>{t<string>('bing-search-console-help')}</Text>
              <br />
              <Switch
                checked={bingSearchConsoleChecked}
                onChange={() => handleBingSearchConsoleExpansionClick()}
                style={{ marginTop: 10 }}
              />
              <div style={{ marginTop: 15, width: '90%' }}>
                <TextInput
                  disabled={!bingSearchConsoleChecked}
                  field="bing-verification-id"
                  required={bingSearchConsoleChecked}
                  onChange={handleBingVerificationIdChanged}
                  value={bingSiteVerificationId}
                  placeholder={t('bing-verification-id')}
                  showError={false}
                />
                <span className={classes.helpBox}>
                  {t('bing-verification-id-help')}
                </span>
                <div
                  id="bing-verification-id-error"
                  className={classes.errorBox}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col xs={24}>
        <div className={classes.buttons}>
          <Button
            type="primary"
            onClick={props.handleSaveAccountSettings}
            className={classes.button}
            disabled={props.loading || props.disableSaveButton}
            size="large"
          >
            {props.loading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: '#fff' }}
              />
            ) : (
              t('save')
            )}
          </Button>
        </div>
      </Col>
    </div>
  );
}

export default withStyles(styles)(AccountIntegrationsSettings);
