import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TokenDataSchema } from '../../api-schemas/google-auth';

const postDriveAuthRequest = (
  accountId: string,
  tokenData: TokenDataSchema
) => {
  const parsedTokenData = TokenDataSchema.parse(tokenData);

  const requestBody = JSON.stringify({
    code: parsedTokenData.code,
  });

  return axios
    .post(
      `/api/google-drive-integration/save-token/${accountId}`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

type UsePostDriveAuthProps = {
  accountId: string;
  tokenData: TokenDataSchema;
};

export default function usePostDriveAuth({
  accountId,
  tokenData,
}: UsePostDriveAuthProps) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: postDriveAuth,
    isPending: isLoadingDriveAuth,
    isSuccess: isSuccessLoadingDriveAuth,
    isError: isErrorLoadingDriveAuth,
  } = useMutation({
    mutationFn: () => postDriveAuthRequest(accountId, tokenData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['save-token', accountId],
      });
    },
  });

  return {
    postDriveAuth,
    isLoadingDriveAuth,
    isSuccessLoadingDriveAuth,
    isErrorLoadingDriveAuth,
  };
}
