import React, { useContext, useRef } from 'react';
import { getTopicLink } from '../../../../../helpers/common';
import { DocumentContext } from '../../../../../contexts/document';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdPreview } from 'react-icons/md';
import { Button, Tooltip } from 'antd';
import { TopicContext } from '../../../../../contexts/topic';
import useSyncGoogleDocTopic from '../../../../../api-hooks/topic/useSyncGoogleDocTopic';
import { AccountContext } from '../../../../../contexts/account';
import useGetItemType from '../../../../../api-hooks/folder/useGetItemType';

const PreviewBox = ({
  showText = true,
  showBorder = true,
  color = 'black',
}: {
  showText?: boolean;
  showBorder?: boolean;
  color?: string;
}) => {
  const { selectedAccount } = useContext(AccountContext);
  const { selectedDoc, selectedLanguage } =
    useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const { syncGoogleDocTopic } = useSyncGoogleDocTopic();
  const { getItemType } = useGetItemType(selectedTopic?.id);

  const { t } = useTranslation();

  const isDoclessTopic = useRef<boolean>(
    Boolean(selectedTopic && !selectedTopic.documentId)
  );

  const handleSyncDocument = () => {
    getItemType(selectedTopic?.topicId).then((itemType) => {
      const topicLink = getTopicLink(
        selectedDoc,
        selectedTopic,
        selectedLanguage,
        false,
        isDoclessTopic.current,
        selectedAccount,
        itemType?.draftId
      );

      const newTab = window.open('', '_blank');

      if (newTab) {
        if (selectedTopic?.contentType === 'GoogleDoc') {
          syncGoogleDocTopic({
            accountId: selectedTopic.accountId,
            googleDriveIds: [selectedTopic.googleDriveId],
          }).then(() => {
            newTab.location.href = topicLink;
          });
        } else {
          newTab.location.href = topicLink;
        }
      }
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ marginRight: 5 }}
      className="hide-on-large"
    >
      <Tooltip title={t('preview-tooltip')}>
        <Button
          style={{
            fontSize: 12,
            height: 32,
            paddingTop: 2,
            border: showBorder ? '1px solid #e5e7eb' : 'none',
            color,
            display: 'flex',
            alignItems: 'center',
          }}
          icon={
            <MdPreview
              id="openInNewIcon"
              style={{ marginRight: 5, marginTop: '-2px', fontSize: 14 }}
            />
          }
          size="small"
          onClick={handleSyncDocument}
        >
          {showText ? t<string>('preview') : null}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default PreviewBox;
