import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const resetUserNotificationsCountRequest = () => {
  return axios
    .put(
      '/api/User/Notification/Reset',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useResetUserNotificationsCount() {
  const {
    mutateAsync: resetUserNotificationsCount,
    isPending: isResettingUserNotificationCount,
    isSuccess: isSuccessResettingUserNotificationCount,
  } = useMutation({
    mutationFn: resetUserNotificationsCountRequest,
  });

  return {
    resetUserNotificationsCount,
    isResettingUserNotificationCount,
    isSuccessResettingUserNotificationCount,
  };
}
