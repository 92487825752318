import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CiSquarePlus, CiSquareMinus, CiSquareInfo } from 'react-icons/ci';
import { Tooltip } from 'antd';

import { DraftTopic, DraftTopicTreeItem } from '../../../../api-schemas/topic';

type TopicStatusesPropsType = {
  draftTopic: DraftTopic | DraftTopicTreeItem;
};

const TopicStatuses = ({ draftTopic }: TopicStatusesPropsType) => {
  const isDeleted = draftTopic.action === 'Deleted';
  const isAlreadyPublished =
    draftTopic.latestTopicVersionId !== null &&
    draftTopic.latestTopicVersionId === draftTopic.publishedTopicVersionId;
  const isNotPublishedYet = Number(draftTopic.topicVersionNumber) < 1.0;
  const isLanguageEqual = draftTopic.title !== null;

  const { t } = useTranslation();

  return (
    <Fragment>
      {isDeleted ? (
        <Tooltip title={t('deleted')} placement="top">
          <CiSquareMinus style={{ color: 'red' }} />
        </Tooltip>
      ) : isNotPublishedYet && isLanguageEqual ? (
        <Tooltip title={t('added')} placement="top">
          <CiSquarePlus style={{ color: 'green' }} />
        </Tooltip>
      ) : !isAlreadyPublished && isLanguageEqual ? (
        <Tooltip title={t('modified')} placement="top">
          <CiSquareInfo style={{ color: 'orange' }} />
        </Tooltip>
      ) : null}
    </Fragment>
  );
};

export default TopicStatuses;
