import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { useParams } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { BsFileRichtext } from 'react-icons/bs';
import { FolderOutlined } from '@ant-design/icons';

import NewDocumentDialog from '../../components/document/new-document-dialog';
import NewTopicDialog from '../../components/topic/new-topic-dialog';
import DocumentList from '../../components/document/list';
import TopicList from '../../components/topic/list';
import ManageFolderDialog from '../../components/folder/manage-folder-dialog';
import FolderList from '../../components/folder/list';
import { Folder } from '../../api-schemas/folder';
import DocerBreadcrumbs from '../../components/docer-breadcrumbs';
import { FolderContext } from '../../contexts/folder';
import { AccountContext } from '../../contexts/account';
import useGetGoogleDriveAccountToken from '../../api-hooks/account/useGetGoogleDriveAccountToken';
import { handleGoogleDriveSignIn } from '../../helpers/google-drive-auth';
import { AccountRoles } from '../../helpers/roles';
import { ConfirmationDialog } from '../../components/confirmation-dialog';

const HomePage = () => {
  const { t } = useTranslation();

  const [isNewGoogleDocSelected, setIsNewGoogleDocSelected] =
    useState<boolean>(false);
  const [openNewDocumentDialog, setOpenNewDocumentDialog] =
    useState<boolean>(false);
  const [openNewTopicDialog, setOpenNewTopicDialog] = useState<boolean>(false);
  const [openNewFolderDialog, setOpenNewFolderDialog] =
    useState<boolean>(false);
  const [restrictedUserDialog, setRestrictedUserDialog] =
    useState<boolean>(false);
  const [openAdminReqTokenDialog, setOpenAdminReqTokenDialog] =
    useState<boolean>(false);

  const { setSelectedFolderId, selectedFolders, setSelectedFolders } =
    useContext(FolderContext);
  const { selectedAccount, userRole } = useContext(AccountContext);

  const { accountId }: { accountId: string } = useParams();

  const { googleDriveAccountToken } = useGetGoogleDriveAccountToken(accountId);

  const handleOpenNewDocumentDialog = () => {
    setOpenNewDocumentDialog(true);
  };

  const handleOpenNewTopicDialog = (isGoogleDocType: boolean) => {
    if(isGoogleDocType) handleGoogleDocScenarios();

    setOpenNewTopicDialog(true);
    setIsNewGoogleDocSelected(isGoogleDocType);
  };

  const handleOpenNewFolderDialog = () => {
    setOpenNewFolderDialog(true);
  };

  const handleCloseAdminReqTokenDialog = () => {
    setOpenAdminReqTokenDialog(false);
  };

  const handleCloseRestrictedUserDialog = () => {
    setRestrictedUserDialog(false);
  };

  const handleBreadCrumbOnClick = (folder: Folder) => {
    let itemIndex = selectedFolders.findIndex(
      (item) => (item as Folder).id === folder.id
    );

    if (itemIndex) {
      setSelectedFolderId(folder.id);
    } else {
      itemIndex = 1;

      setSelectedFolderId(undefined);
    }

    setSelectedFolders((prevSelectedFolders) => {
      prevSelectedFolders.splice(itemIndex);

      return prevSelectedFolders;
    });
  };

  const handleGoogleDocScenarios = () => {
    if (!selectedAccount.hasValidGoogleDriveToken) {
      if (googleDriveAccountToken) {
        if (
          userRole === AccountRoles.READER ||
          userRole === AccountRoles.WRITER
        )
          setRestrictedUserDialog(true);
        else setOpenAdminReqTokenDialog(true);

        return;
      } else {
        handleGoogleDriveSignIn();

        return;
      }
    }
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <>
          <FolderOutlined
            style={{ fontSize: 16 }}
            className="menu-item-for-new"
          />
          {t('new-folder')}
        </>
      ),
      key: '1',
      onClick: handleOpenNewFolderDialog,
    },
    {
      label: (
        <>
          <BsFileRichtext
            style={{ fontSize: 16 }}
            className="menu-item-for-new"
          />
          {t('new-topic')}
        </>
      ),
      key: '2',
      onClick: () => handleOpenNewTopicDialog(false),
    },
    {
      label: (
        <>
          <GoogleOutlined
            style={{ fontSize: 16 }}
            className="menu-item-for-new"
          />
          {t('new-google-doc')}
        </>
      ),
      key: '3',
      onClick: () => handleOpenNewTopicDialog(true),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'end',
          marginBottom: 10,
        }}
      >
        <Dropdown.Button
          type="primary"
          menu={{ items }}
          onClick={handleOpenNewDocumentDialog}
          style={{ width: 'auto' }}
        >
          {t('new-document')}
        </Dropdown.Button>
      </div>

      <div style={{ width: '80%', display: 'flex', marginBottom: 10 }}>
        <DocerBreadcrumbs
          items={selectedFolders}
          onClick={handleBreadCrumbOnClick}
        />
      </div>

      <FolderList />

      <DocumentList />

      <TopicList />

      {openNewFolderDialog ? (
        <ManageFolderDialog setOpenDialog={setOpenNewFolderDialog} />
      ) : openNewDocumentDialog ? (
        <NewDocumentDialog setOpenDialog={setOpenNewDocumentDialog} />
      ) : openNewTopicDialog ? (
        <NewTopicDialog
          setOpenDialog={setOpenNewTopicDialog}
          isGoogleDocType={isNewGoogleDocSelected}
        />
      ) : null}

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-access-reject-desc')}
        isOpen={restrictedUserDialog}
        onDialogConfirmed={handleCloseRestrictedUserDialog}
      />

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-expired-token-desc')}
        isOpen={openAdminReqTokenDialog}
        onDialogClosed={handleCloseAdminReqTokenDialog}
        onDialogConfirmed={() => handleGoogleDriveSignIn()}
      />
    </div>
  );
};

export default HomePage;
