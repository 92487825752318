import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { PublishRequestDiffs } from '../../api-schemas/publish-request';

export type GetPublishRequestDiffsParams = {
  sourcePublishRequestId?: string;
  destPublishRequestId?: string;
  languageCode?: string | null;
};

const getPublishRequestDiffsRequest = (
  params: GetPublishRequestDiffsParams
) => {
  return axios
    .get<PublishRequestDiffs>(`/api/PublishRequest/diff`, {
      params,
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetPublishRequestDiffs({
  sourcePublishRequestId,
  destPublishRequestId,
  languageCode,
}: GetPublishRequestDiffsParams) {
  const {
    data,
    refetch: getPublishRequestDiffs,
    isFetching: isLoadingPublishRequestDiffs,
    isSuccess: isSuccessLoadingPublishRequestDiffs,
  } = useQuery<PublishRequestDiffs>({
    staleTime: 1000 * 60 * 60,
    queryKey: [
      'publishRequestDiffs',
      sourcePublishRequestId,
      destPublishRequestId,
      languageCode,
    ],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () =>
      getPublishRequestDiffsRequest({
        sourcePublishRequestId,
        destPublishRequestId,
        languageCode,
      }),
  });

  const publishRequestDiffs = useMemo(() => data, [data]);

  return {
    publishRequestDiffs,
    isLoadingPublishRequestDiffs,
    isSuccessLoadingPublishRequestDiffs,

    getPublishRequestDiffs,
  };
}
