import React, { useContext, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Button, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocumentContext } from '../../contexts/document';
import { DocumentService } from '../../services/document';
import { topicService } from '../../services/topic';
import ISO6391 from 'iso-639-1';
import {
  changeRouteBaseOnFeedbackAnalysisFilters,
  getVersionSlug,
  getLanguageCode,
} from '../../helpers/route';
import { useHistory, useParams } from 'react-router-dom';
import './styles.css';
import FeedbackBox from './box';
import FeedbackSkeletonLoading from './loading';
import FilterSkeletonLoading from './filter-loading';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import SelectInput from '../inputs/select-input';
import { Col, Row, Select, Breadcrumb, Typography } from 'antd';
import useGetFeedbacks, {
  GetFeedbacksParamsType,
} from '../../api-hooks/feedback/useGetFeedbacks';
import { Feedbacks } from '../../api-schemas/feedback';
import { TopicContext } from '../../contexts/topic';

const { Option } = Select;
const { Title, Text } = Typography;

const useStyles: any = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: `${theme.spacing(1)}!important`,
      marginBottom: `${theme.spacing(1)}!important`,
      width: theme.spacing(16),
      height: 'auto',
    },
  },
  filterPaper: {
    width: '100%!important',
    boxShadow: 'none !important',
    border: '1px solid #d9d9d9',
  },
  formControl: {
    margin: `${theme.spacing(1)}!important`,
    minWidth: '120px!important',
    width: '100%!important',
    display: 'flex',
  },
  label: {
    position: 'relative',
  },
  selectEmpty: {
    marginTop: 0,
  },
  selectPartent: {
    display: 'inline-block',
    padding: '0 20px 0 3px',
    width: '100%',
  },
  selectBox: {
    width: '100%!important',
    marginTop: '0!important',
  },
  borderRight: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #b2b2b2',
    },
  },
  buttonBox: {
    marginTop: 10,
    textAlign: 'center',
  },
  feedbackBox: {
    width: '100%',
  },
  nameBox: {
    fontSize: '16px',
    fontWeight: 700,
    marginRight: 10,
  },
  dateBox: {
    fontSize: '12px',
    color: '#979797',
  },
  infoBox: {
    backgroundColor: '#bdbdbd52',
    padding: '0 15px',
    marginTop: 10,
    display: 'flex',
  },
  info: {
    fontSize: 12,
    textAlign: 'left',
  },
}));

const FeedbackAnalysis = (props: any) => {
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();

  const { selectedTopic: selectedDraftTopic } = useContext(TopicContext);

  const classes = useStyles();

  const { t } = useTranslation();

  const rateArray = [
    {
      id: 0,
      title: t('all-ratings'),
    },
    {
      id: 1,
      title: t('1-star'),
    },
    {
      id: 2,
      title: t('2-stars'),
    },
    {
      id: 3,
      title: t('3-stars'),
    },
    {
      id: 4,
      title: t('4-stars'),
    },
    {
      id: 5,
      title: t('5-stars'),
    },
  ];

  const sortOptionArray = [
    {
      id: 'MostRecent',
      title: t('most-recent'),
    },
    {
      id: 'HighestRate',
      title: t('highest-rate'),
    },
    {
      id: 'LowestRate',
      title: t('lowest-rate'),
    },
  ];

  const statusArray = [
    {
      id: 0,
      title: t('all'),
    },
    {
      id: 1,
      title: t('open'),
    },
    {
      id: 2,
      title: t('closed'),
    },
  ];

  const history = useHistory();

  const {
    documents,
    selectedVersion,
    setSelectedVersion,
    selectedLanguage,
    setSelectedLanguage,
    versions,
    setDocumentVersions,
    setDocumentTopics,
    documentTopics,
  } = useContext(DocumentContext);

  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [selectedTopic, setSelectedTopic] = useState<any>(null);
  const [currentTopics, setCurrentTopics] = useState<any>(null);
  const [currentVersion, setCurrentVersion] = useState<any>(null);
  const [currentVersions, setCurrentVersions] = useState<any>(null);
  const [currentLanguage, setCurrentLanguage] = useState<any>(null);
  const [selectedTopicVersion, setSelectedTopicVersion] = useState<any>(null);
  const [selectedRate, setSelectedRate] = useState(rateArray[0]);
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptionArray[0]
  );
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [closedIdArray, setCloseIdArray] = useState<string[]>([]);
  const [topicVersionNumberArray, setTopicVersionNumberArray] = useState<any[]>(
    []
  );
  const [selectedStatus, setSelectedStatus] = useState(statusArray[0]);
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>(null);
  const [feedbacks, setFeedbacks] = useState<Feedbacks | null | undefined>([]);
  const [params, setParams] = useState<GetFeedbacksParamsType>();

  const sendRequest: any = useRef();

  const { isLoadingFeedbacks, getFeedbacks: getFeedbacksRequest } =
    useGetFeedbacks(params);

  const documentServices = new DocumentService();
  const topicServices = new topicService();

  const updateVersions = (
    selectedDocument,
    shouldNotUpdateTopics,
    shouldUpdateContext: boolean = false
  ) => {
    return documentServices
      .getVersions(selectedDocument.id)
      .then(async (result) => {
        if (shouldUpdateContext) {
          setDocumentVersions([...result]);
          const foundDefaultVersions = result.filter(
            (ver) => ver.slug === selectedDocument.defaultDocumentVersionSlug
          );
          if (foundDefaultVersions.length) {
            setSelectedVersion(foundDefaultVersions[0]);
          } else {
            setSelectedVersion(result[0]);
          }
        }
        setCurrentVersions([...result]);
        const foundVersions = result.filter(
          (ver) => ver.slug === getVersionSlug()
        );
        if (foundVersions.length) {
          if (!shouldNotUpdateTopics)
            await updateTopics(
              foundVersions[0].id,
              currentLanguage ? currentLanguage.code : selectedLanguage.code,
              false
            );
          return foundVersions[0];
        } else {
          const foundVersions = result.filter(
            (ver) => ver.slug === selectedDocument.defaultDocumentVersionSlug
          );
          if (foundVersions.length) {
            if (!shouldNotUpdateTopics)
              await updateTopics(
                foundVersions[0].id,
                currentLanguage ? currentLanguage.code : selectedLanguage.code,
                false
              );
            return foundVersions[0];
          } else {
            return {
              id: 0,
              title: t('all-versions'),
            };
          }
        }
      })
      .catch(() => {
        props.enqueueSnackbar(t('error.unknown'), {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (selectedDocument) {
      const tempBreadCrumbItems = (
        <Breadcrumb>
          <Breadcrumb.Item>{selectedDocument.account.title}</Breadcrumb.Item>
          <Breadcrumb.Item>{selectedDocument.title}</Breadcrumb.Item>
          {!!selectedTopic && selectedTopic.id !== 0 && (
            <Breadcrumb.Item>{selectedTopic.title}</Breadcrumb.Item>
          )}
          <Breadcrumb.Item>{t('feedbacks')}</Breadcrumb.Item>
        </Breadcrumb>
      );
      setBreadCrumbItems(tempBreadCrumbItems);
    }
    //eslint-disable-next-line
  }, [selectedDocument]);

  const updateLanguage = async (
    selectedDocument,
    versionId: string,
    shouldUpdateContext: boolean = false
  ) => {
    shouldUpdateContext &&
      setSelectedLanguage({
        id: selectedDocument.defaultLanguageCode,
        code: selectedDocument.defaultLanguageCode,
      });
    const foundLanguages = await selectedDocument.languages.filter(
      (lang) => lang.languageCode === getLanguageCode()
    );
    if (foundLanguages.length) {
      await updateTopics(
        versionId ? versionId : currentVersion.id,
        foundLanguages[0].languageCode,
        shouldUpdateContext
      );
      return {
        id: foundLanguages[0].languageCode,
        code: foundLanguages[0].languageCode,
      };
    } else {
      const foundLanguages = await selectedDocument.languages.filter(
        (lang) => lang.languageCode === selectedDocument.defaultLanguageCode
      );
      if (foundLanguages.length) {
        await updateTopics(
          versionId ? versionId : currentVersion.id,
          foundLanguages[0].languageCode,
          shouldUpdateContext
        );
        return {
          id: foundLanguages[0].languageCode,
          code: foundLanguages[0].languageCode,
        };
      } else {
        return {
          id: 0,
          title: t('all-languages'),
        };
      }
    }
  };

  const updateTopics = async (
    versionId: string,
    languageCode: string,
    shouldUpdateContext: boolean = false
  ) => {
    if (versionId) {
      await topicServices
        .getTopics(versionId, languageCode)
        .then((result) => {
          shouldUpdateContext && setDocumentTopics([...result]);
          setCurrentTopics([...result]);
          setSelectedTopic({
            id: 0,
            title: t('all-topics'),
          });
        })
        .catch(() => {
          props.enqueueSnackbar(t('error.unknown'), {
            variant: 'error',
          });
        });
    }
  };

  const processData = async () => {
    if (documents && documents.length) {
      const foundDocs = documents.filter(
        (doc) => doc.id === documentId && doc.account.id === accountId
      );
      if (foundDocs.length) {
        setSelectedDocument(foundDocs[0]);
        let version = selectedVersion;
        let language = selectedLanguage;
        let shouldUrlBeChanged: boolean = false;
        if (!selectedVersion) {
          version = await updateVersions(foundDocs[0], true, true);
          if (getVersionSlug() === 'all') {
            setCurrentVersion({
              id: 0,
              title: t('all-versions'),
            });
          } else {
            setSelectedVersion(version);
            setCurrentVersion(version);
            shouldUrlBeChanged = true;
          }
        } else {
          setCurrentVersions([...versions]);
          setCurrentVersion(selectedVersion);
        }
        if (!selectedLanguage) {
          language = await updateLanguage(foundDocs[0], version.id, true);
          if (getLanguageCode() === 'all') {
            setCurrentLanguage({
              id: 0,
              title: t('all-languages'),
            });
          } else {
            setSelectedLanguage({ ...language });
            setCurrentLanguage({ ...language });
            shouldUrlBeChanged = true;
          }
        } else {
          setCurrentLanguage(selectedLanguage);
          if (!documentTopics.length) {
            updateTopics(version.id, language.code, true);
            shouldUrlBeChanged = true;
          } else {
            if (selectedDraftTopic) {
              generateTopicVersionArray(selectedDraftTopic);
              setSelectedTopic(selectedDraftTopic);
            } else {
              setSelectedTopic({
                id: 0,
                title: t('all-topics'),
              });
            }
            setCurrentTopics(documentTopics);
          }
        }
        shouldUrlBeChanged &&
          changeRouteBaseOnFeedbackAnalysisFilters(
            history,
            foundDocs[0].account.id,
            foundDocs[0].id,
            getVersionSlug() === 'all' ? 'all' : version.slug,
            getLanguageCode() === 'all' ? 'all' : language.code
          );
      } else {
        props.enqueueSnackbar(t('error.doc-not-found'), {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, [documents]);

  const sendRequestFunction = async () => {
    if (
      (sendRequest.current || sendRequest.current === undefined) &&
      !isLoadingFeedbacks &&
      feedbacks?.length
    ) {
      sendRequest.current = false;
      const result = await getFeedbacks();
      if (result) {
        let feedbackResultTemp = [...feedbacks];
        feedbackResultTemp = feedbackResultTemp.concat(result);
        setFeedbacks(feedbackResultTemp);
        window.onscroll = handleScroll;
      }
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 50
    ) {
      window.onscroll = () => {};
      sendRequestFunction();
    }
  };

  useEffect(() => {
    window.onscroll = handleScroll;
    // eslint-disable-next-line
  });

  const generateTopicVersionArray = (topic) => {
    const latestTopicVersionNumber: number = parseInt(topic.topicVersionNumber);
    const tempTopicVersionNumber: any[] = [];
    tempTopicVersionNumber.push({
      id: 0,
      title: t('all-topic-versions'),
    });
    for (let i = 1; i <= latestTopicVersionNumber; i++) {
      tempTopicVersionNumber.push({
        id: i,
        title: i,
      });
    }
    setSelectedTopicVersion(tempTopicVersionNumber[0]);
    setTopicVersionNumberArray(tempTopicVersionNumber);
  };

  const handleChangeSelect = async (element, newValue) => {
    switch (element) {
      case 'document':
        if (selectedDocument.id === newValue) return;
        (document.getElementById('filterBox') as HTMLElement).classList.add(
          'disabled'
        );
        // eslint-disable-next-line no-case-declarations
        const foundDocuments = documents.filter((doc) => doc.id === newValue);
        if (foundDocuments.length) {
          setFeedbacks([]);
          setSelectedDocument({ ...foundDocuments[0] });
          const version = await updateVersions(foundDocuments[0], true);
          setCurrentVersion(version);
          const language = await updateLanguage(foundDocuments[0], version.id);
          setCurrentLanguage(language);
          changeRouteBaseOnFeedbackAnalysisFilters(
            history,
            foundDocuments[0].account.id,
            foundDocuments[0].id,
            version.slug,
            language.code
          );
        }
        (document.getElementById('filterBox') as HTMLElement).classList.remove(
          'disabled'
        );
        break;
      case 'version':
        if (currentVersion.id === newValue) return;
        if (newValue === 0) {
          setCurrentVersion({
            id: 0,
            title: t('all-versions'),
          });
          changeRouteBaseOnFeedbackAnalysisFilters(
            history,
            selectedDocument.account.id,
            selectedDocument.id,
            'all',
            getLanguageCode()
          );
        } else {
          (document.getElementById('filterBox') as HTMLElement).classList.add(
            'disabled'
          );
          const foundVersions = currentVersions.filter(
            (version) => version.id === newValue
          );
          if (foundVersions.length) {
            setCurrentVersion(foundVersions[0]);
            changeRouteBaseOnFeedbackAnalysisFilters(
              history,
              selectedDocument.account.id,
              selectedDocument.id,
              foundVersions[0].slug,
              getLanguageCode()
            );
            if (newValue !== 0 && getLanguageCode() !== 'all')
              await updateTopics(foundVersions[0].id, currentLanguage.code);
          }
          (
            document.getElementById('filterBox') as HTMLElement
          ).classList.remove('disabled');
        }
        break;
      case 'language':
        if (currentLanguage.code === newValue) return;
        if (newValue === 0) {
          setCurrentLanguage({
            id: 0,
            title: t('all-languages'),
          });
          changeRouteBaseOnFeedbackAnalysisFilters(
            history,
            selectedDocument.account.id,
            selectedDocument.id,
            getVersionSlug(),
            'all'
          );
        } else {
          (document.getElementById('filterBox') as HTMLElement).classList.add(
            'disabled'
          );
          const foundLanguages = selectedDocument.languages.filter(
            (language) => language.languageCode === newValue
          );
          if (foundLanguages.length) {
            setCurrentLanguage({
              id: foundLanguages[0].languageCode,
              code: foundLanguages[0].languageCode,
            });
            changeRouteBaseOnFeedbackAnalysisFilters(
              history,
              selectedDocument.account.id,
              selectedDocument.id,
              getVersionSlug(),
              foundLanguages[0].languageCode
            );
            if (newValue !== 0 && getVersionSlug() !== 'all')
              await updateTopics(
                currentVersion.id,
                foundLanguages[0].languageCode
              );
          }
          (
            document.getElementById('filterBox') as HTMLElement
          ).classList.remove('disabled');
        }
        break;
      case 'topic':
        if (selectedTopic.id === newValue) return;
        if (newValue === 0) {
          setSelectedTopic({
            id: 0,
            title: t('all-topics'),
          });
        } else {
          const foundTopics = currentTopics.filter(
            (topic) => topic.id === newValue
          );
          if (foundTopics.length) {
            setSelectedTopic(foundTopics[0]);
            generateTopicVersionArray(foundTopics[0]);
          }
        }
        break;
      case 'rate':
        if (selectedRate.id === newValue) return;
        // eslint-disable-next-line no-case-declarations
        const foundRates = rateArray.filter((rate) => rate.id === newValue);
        if (foundRates.length) {
          setSelectedRate(foundRates[0]);
        }
        break;
      case 'sort':
        if (selectedSortOption.id === newValue) return;
        // eslint-disable-next-line no-case-declarations
        const foundSortOptions = sortOptionArray.filter(
          (sortOption) => sortOption.id === newValue
        );
        if (foundSortOptions.length) {
          setSelectedSortOption(foundSortOptions[0]);
        }
        break;
      case 'topicVersion':
        if (selectedTopicVersion.id === newValue) return;
        // eslint-disable-next-line no-case-declarations
        const foundTopicVersions = topicVersionNumberArray.filter(
          (topicVersion) => topicVersion.id === newValue
        );
        if (foundTopicVersions.length) {
          setSelectedTopicVersion(foundTopicVersions[0]);
        }
        break;
      case 'status':
        if (selectedStatus.id === newValue) return;
        // eslint-disable-next-line no-case-declarations
        const foundStatuses = statusArray.filter(
          (status) => status.id === newValue
        );
        if (foundStatuses.length) {
          setSelectedStatus(foundStatuses[0]);
        }
        break;
      default:
        break;
    }
  };

  const getFeedbacks = async (shouldUpdateState = false) => {
    let tempPageIndex = pageIndex;

    if (shouldUpdateState) {
      tempPageIndex = 0;
      setFeedbacks([]);
      sendRequest.current = true;
      setPageIndex(tempPageIndex);
    }

    setParams({
      topicId: selectedTopic.id !== 0 ? selectedTopic.topicId : null,
      documentVersionId: currentVersion.id !== 0 ? currentVersion.id : null,
      documentId: selectedDocument.id,
      sortedBy: selectedSortOption.id,
      rate: selectedRate.id !== 0 ? selectedRate.id : null,
      languageCode: currentLanguage.id !== 0 ? currentLanguage.code : null,
      topicVersionNumber:
        selectedTopicVersion && selectedTopicVersion.id !== 0
          ? selectedTopicVersion.id
          : null,
      status: selectedStatus.id !== 0 ? selectedStatus.id : null,
      pageIndex: tempPageIndex,
      pageSize: 5,
    });

    await new Promise((r) => setTimeout(r, 500));

    return getFeedbacksRequest()
      .then(async (result) => {
        shouldUpdateState && setFeedbacks(result.data);
        if (!result?.data?.length) {
          if (!feedbacks?.length) {
            setFeedbacks(null);
          }
          sendRequest.current = false;
          return null;
        } else {
          sendRequest.current = result.data.length >= 5;
          const tempClosedIdArray = closedIdArray;
          result.data.forEach((feedback) => {
            if (
              tempClosedIdArray.indexOf(feedback.id) === -1 &&
              feedback.status === 0
            ) {
              tempClosedIdArray.push(feedback.id);
            }
          });
          setCloseIdArray(tempClosedIdArray);
          setPageIndex(pageIndex + 1);

          return result.data;
        }
      })
      .catch(() => {
        props.enqueueSnackbar(t('error.get-feedbacks'), {
          variant: 'error',
        });
        return null;
      });
  };

  const renderSelect = (element: string, selectedElement, elementArray) => {
    if (
      element === 'language' ||
      element === 'version' ||
      element === 'topic'
    ) {
      if (elementArray[0].id !== 0) {
        elementArray.unshift({
          id: 0,
          title: t(`all-${element}s`),
        });
      }
    }
    return (
      <FormControl className={classes.formControl}>
        <SelectInput
          field={element}
          label={t(element)}
          showError={false}
          onChange={(value) => handleChangeSelect(element, value)}
          value={selectedElement ? selectedElement.id : elementArray[0].id}
        >
          {element === 'language'
            ? elementArray.map((singleElement) =>
                singleElement.id === 0 ? (
                  <Option key={singleElement.id} value={singleElement.id}>
                    {singleElement.title}
                  </Option>
                ) : (
                  <Option
                    key={singleElement.languageCode}
                    value={singleElement.languageCode}
                  >
                    {ISO6391.getName(singleElement.languageCode)}
                  </Option>
                )
              )
            : elementArray.map((singleElement) =>
                element === 'topic' &&
                singleElement.parentTopicId === null ? null : (
                  <Option key={singleElement.id} value={singleElement.id}>
                    {singleElement.title}
                  </Option>
                )
              )}
        </SelectInput>
      </FormControl>
    );
  };

  if (
    documents &&
    selectedDocument &&
    currentVersion &&
    currentLanguage &&
    currentVersions &&
    currentTopics &&
    selectedTopic !== null
  ) {
    return (
      <React.Fragment>
        <Row
          style={{
            marginBottom: 15,
            width: '100%',
            margin: 'auto',
            maxWidth: 1100,
          }}
        >
          <div
            className={classes.root}
            id="filterBox"
            style={{ width: '100%' }}
          >
            <Paper elevation={2} className={classes.filterPaper}>
              <Col xs={24} style={{ margin: '10px 0' }}>
                <Title style={{ marginLeft: 10 }} level={3}>
                  {selectedTopic.id !== 0
                    ? t('topic-feedbacks')
                    : t('document-feedbacks')}
                </Title>
                <Text style={{ marginLeft: 10 }}>
                  {t('feedback-page-help')}
                </Text>
                {breadCrumbItems !== null && (
                  <Col xs={24} style={{ marginTop: 8, marginLeft: 10 }}>
                    {breadCrumbItems}
                  </Col>
                )}
                {!selectedDraftTopic && (
                  <div style={{ display: 'initial', marginTop: 10 }}>
                    <Col
                      xs={24}
                      md={8}
                      className={
                        classes.selectPartent + ' ' + classes.borderRight
                      }
                    >
                      {renderSelect('document', selectedDocument, documents)}
                    </Col>
                    <Col
                      xs={24}
                      md={8}
                      className={
                        classes.selectPartent + ' ' + classes.borderRight
                      }
                    >
                      {renderSelect('version', currentVersion, currentVersions)}
                    </Col>
                    <Col xs={24} md={8} className={classes.selectPartent}>
                      {renderSelect('language', currentLanguage, [
                        ...selectedDocument.languages,
                      ])}
                    </Col>
                    <Col
                      xs={24}
                      md={8}
                      className={
                        classes.selectPartent + ' ' + classes.borderRight
                      }
                    >
                      {renderSelect('topic', selectedTopic, [...currentTopics])}
                    </Col>
                  </div>
                )}
                {selectedTopic.id !== 0 && (
                  <Col
                    xs={24}
                    md={8}
                    className={
                      classes.selectPartent + ' ' + classes.borderRight
                    }
                  >
                    {renderSelect(
                      'topicVersion',
                      selectedTopicVersion,
                      topicVersionNumberArray
                    )}
                  </Col>
                )}
                <Col
                  xs={24}
                  md={8}
                  className={
                    classes.selectPartent +
                    (selectedTopic.id === 0 || selectedDraftTopic
                      ? ' ' + classes.borderRight
                      : '')
                  }
                >
                  {renderSelect('status', selectedStatus, statusArray)}
                </Col>
                <Col
                  xs={24}
                  md={8}
                  className={
                    classes.selectPartent +
                    (selectedTopic.id !== 0 && !selectedDraftTopic
                      ? ' ' + classes.borderRight
                      : '')
                  }
                >
                  {renderSelect('rate', selectedRate, rateArray)}
                </Col>
                <Col
                  xs={12}
                  md={8}
                  className={classes.selectPartent + ' ' + classes.borderRight}
                >
                  {renderSelect('sort', selectedSortOption, sortOptionArray)}
                </Col>
                <Col xs={24} className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getFeedbacks(true)}
                  >
                    {t('filter')}
                  </Button>
                </Col>
              </Col>
            </Paper>
          </div>
          {feedbacks ? (
            feedbacks.map((feedback) => (
              <FeedbackBox
                key={feedback.id}
                feedback={feedback}
                versions={currentVersions}
                isClosed={
                  !!closedIdArray.filter((id) => id === feedback.id).length
                }
              />
            ))
          ) : (
            <Col
              xs={24}
              style={{ textAlign: 'center', color: 'red', fontSize: '18px' }}
            >
              {t('no-result')}
            </Col>
          )}
          {isLoadingFeedbacks && <FeedbackSkeletonLoading />}
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row
          style={{
            marginBottom: 15,
            width: '100%',
            margin: 'auto',
            maxWidth: 1100,
          }}
        >
          <FilterSkeletonLoading />
        </Row>
      </React.Fragment>
    );
  }
};

//eslint-disable-next-line
//@ts-expect-error
export default withRouter(withSnackbar(FeedbackAnalysis));
