import React, { useContext, useMemo, useRef, useState } from 'react';
import { MenuProps } from 'antd/lib';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { BsFileRichtext } from 'react-icons/bs';
import { Dropdown } from 'antd';
import { DownOutlined, CopyOutlined, GoogleOutlined, BuildOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import config from 'react-global-configuration';

import { ConfirmationDialog } from '../../confirmation-dialog';
import { AccountContext } from '../../../contexts/account';
import TopicSelectorDialog from '../tree/selector/dialog';
import useGetDraftTopicTree from '../../../api-hooks/topic/useGetDraftTopicTree';
import { DocumentContext } from '../../../contexts/document';
import { getLanguageCode } from '../../../helpers/route';
import { createNewTopicContent, createNewTopics } from '../../../helpers/common';
import useSaveTopic from '../../../api-hooks/topic/useSaveTopic';
import { TopicContext } from '../../../contexts/topic';

import useGetCurrentPlan from '../../../api-hooks/plan/useGetCurrentPlan';
import useCopyTopic from '../../../api-hooks/topic/useCopyTopic';
import PlanLimitationDialog from '../../plan-limitation-dialog';
import { handleGoogleDriveSignIn } from '../../../helpers/google-drive-auth';
import { AccountRoles } from '../../../helpers/roles';
import useGetGoogleDriveAccountToken from '../../../api-hooks/account/useGetGoogleDriveAccountToken';

import './styles.css';

const styles: any = () => ({
  btnAddTopic: {
    minWidth: '40px',
  },
  btnMoreAddTopic: {
    minWidth: '40px',
    borderLeft: 'none',
  },
  menuItem: { fontSize: '0.9rem' },
  actionIcon: {
    fontSize: 'large',
    marginRight: '7px!important',
  },
});

type AddTopicButtonsPropsType = {
  loading: boolean;
  setIsSideSectionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddTopicButtons({ loading, setIsSideSectionExpanded }: AddTopicButtonsPropsType) {
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [openPlanLimitationDialog, setOpenPlanLimitationDialog] = useState<boolean>(false);
  const [newTopicId, setNewTopicId] = useState<string | null>(null);
  const [restrictedUserDialog, setRestrictedUserDialog] = useState<boolean>(false);
  const [openAdminReqTokenDialog, setOpenAdminReqTokenDialog] = useState<boolean>(false);

  const isNewTopicPage = useRef<boolean>(false);

  const { selectedVersion } = useContext(DocumentContext);
  const { changeTopic } = useContext(TopicContext);
  const { selectedAccount, userRole } = useContext(AccountContext);

  const { accountId, documentId }: { accountId: string; documentId: string } = useParams();
  const history = useHistory();
  const { search } = useLocation();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { currentPlan } = useGetCurrentPlan(accountId);

  const { draftTopicTree, flatDraftTopicTree } = useGetDraftTopicTree({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
  });

  const { saveTopic, isSavingTopic } = useSaveTopic({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
    onSuccessCallback: () => {
      changeTopic(newTopicId!);

      if (isNewTopicPage.current) {
        isNewTopicPage.current = false;

        history.push({
          hash: 'components',
          search,
        });

        setIsSideSectionExpanded(true);
      } else {
        history.push({
          hash: 'comments',
          search,
        });
      }
    },
    onErrorMessageCallback: (message) => {
      if (message.includes('MaximumNumberOfTopicsExceeded')) setOpenPlanLimitationDialog(true);
    },
  });

  const { copyTopic } = useCopyTopic({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
  });

  const { googleDriveAccountToken } = useGetGoogleDriveAccountToken(accountId);

  const homeTopic = useMemo(() => flatDraftTopicTree.find((topic) => !topic.parentTopicId), [flatDraftTopicTree]);

  const userHasPrevisoulyAccess =
    (userRole === AccountRoles.READER || userRole === AccountRoles.WRITER) && googleDriveAccountToken;

  const isGoogleDocOptionEnabled =
    userRole === AccountRoles.ADMIN ||
    userRole === AccountRoles.OWNER ||
    selectedAccount.hasValidGoogleDriveToken ||
    userHasPrevisoulyAccess;

  const handleCloseAdminReqTokenDialog = () => {
    setOpenAdminReqTokenDialog(false);
  };

  const handleCloseRestrictedUserDialog = () => {
    setRestrictedUserDialog(false);
  };

  const handleGoogleDocScenarios = (topicContentType: string) => {
    if (topicContentType === 'GoogleDoc' && !selectedAccount.hasValidGoogleDriveToken) {
      if (googleDriveAccountToken) {
        if (userRole === AccountRoles.READER || userRole === AccountRoles.WRITER) setRestrictedUserDialog(true);
        else setOpenAdminReqTokenDialog(true);

        return;
      } else {
        handleGoogleDriveSignIn();

        return;
      }
    }
  };

  const handleAddTopic = async (parentTopic, topicContentType: string = 'CKEditor5') => {
    handleGoogleDocScenarios(topicContentType);

    const newTopic = createNewTopics(parentTopic.topicId, getLanguageCode(), flatDraftTopicTree, topicContentType);

    setNewTopicId(newTopic.topicId);

    parentTopic.children = [];
    parentTopic.children.push(newTopic);

    const newContent = createNewTopicContent(
      parentTopic.topicId,
      getLanguageCode(),
      selectedVersion,
      newTopic,
      topicContentType
    );

    if (topicContentType === 'Component') {
      isNewTopicPage.current = true;
    } else {
      isNewTopicPage.current = false;
    }

    //eslint-disable-next-line
    //@ts-expect-error
    saveTopic(newContent);
  };

  const handleCopyTopic = async (topicId: string, parentTopicId: string) => {
    const copiedTopicParentId = parentTopicId ?? homeTopic?.topicId;

    copyTopic({
      documentVersionId: selectedVersion!.id,
      parentTopicId: copiedTopicParentId,
      id: topicId,
    }).catch(async (error) => {
      if (error.status === 500) {
        enqueueSnackbar(error && error.errors ? t(`error.${error.errors[0].field}`) : t('error.unknown'), {
          variant: 'error',
        });

        setOpenPlanLimitationDialog(true);
      } else {
        try {
          const errorJson = await error.json();

          if (!!errorJson?.errors && errorJson?.errors[0].field === 'MaximumNumberOfTopicsExceeded') {
            setOpenPlanLimitationDialog(true);
          }
        } catch {
          enqueueSnackbar(error.statusText ? t(`error.${error.statusText}`) : t('error.unknown'), {
            variant: 'error',
          });
        }
      }
    });
  };

  const menu: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: <span style={{ fontSize: '14px', color: '#1a73e8' }}>New Topic</span>,
      children: [
        {
          label: (
            <>
              <BsFileRichtext style={{ fontSize: 16, marginLeft: 10 }} className="menu-item-for-new" />
              Rich Text
            </>
          ),
          key: '2',
          onClick: () => handleAddTopic(homeTopic, 'CKEditor5'),
        },
        ...(isGoogleDocOptionEnabled
          ? [
              {
                label: (
                  <>
                    <GoogleOutlined style={{ fontSize: 16, marginLeft: 10 }} className="menu-item-for-new" />
                    {t('google-doc')}
                  </>
                ),
                key: '4',
                onClick: () => handleAddTopic(homeTopic, 'GoogleDoc'),
              },
            ]
          : []),
        ...(config.get('environment') === 'stage'
          ? [
              {
                label: (
                  <>
                    <BuildOutlined style={{ fontSize: 16, marginLeft: 10 }} className="menu-item-for-new" />
                    New Page
                  </>
                ),
                key: '5',
                onClick: () => handleAddTopic(homeTopic, 'Component'),
              },
            ]
          : []),
      ],
    },
    {
      label: (
        <>
          <CopyOutlined style={{ fontSize: 16 }} className="menu-item-for-new" />
          Copy Here
        </>
      ),
      key: '3',
      onClick: () => setOpenCopyDialog(true),
    },
  ];

  const menuProps = {
    items: menu,
    overlayClassName: 'menu-for-new',
  };

  return (
    <span className="new-btn-box">
      <Dropdown.Button
        style={{ marginBottom: '6px', marginTop: '6px' }}
        className="dropdown-new-btn"
        type="primary"
        icon={<DownOutlined />}
        menu={menuProps}
        onClick={() => handleAddTopic(homeTopic, 'CKEditor5')}
        disabled={loading || isSavingTopic}
        loading={loading || isSavingTopic}
      >
        New
      </Dropdown.Button>

      {openCopyDialog && (
        <TopicSelectorDialog
          documentId={documentId}
          title={t('copy-topic-modal-title')}
          topics={draftTopicTree}
          openCopy={openCopyDialog}
          onClose={() => setOpenCopyDialog(false)}
          onCopyTopic={handleCopyTopic}
          okTitle={'Copy'}
        />
      )}

      <PlanLimitationDialog
        accountPlan={currentPlan?.name}
        textTitle={t('dialog-cant-add-topics')}
        open={openPlanLimitationDialog}
        textMessage={
          currentPlan?.name === 'Free' ? t('dialog-topic-limitation-free') : t('dialog-topic-limitation-business')
        }
        handleCloseLimitationDialog={() => {
          setOpenPlanLimitationDialog(false);
        }}
      />

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-access-reject-desc')}
        isOpen={restrictedUserDialog}
        onDialogConfirmed={handleCloseRestrictedUserDialog}
      />

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-expired-token-desc')}
        isOpen={openAdminReqTokenDialog}
        onDialogClosed={handleCloseAdminReqTokenDialog}
        onDialogConfirmed={() => handleGoogleDriveSignIn()}
      />
    </span>
  );
}

// #endregion

export default withStyles(styles)(AddTopicButtons);
