import React, { FC, FormEvent, useContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Row, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { AccountContext } from '../../../contexts/account';
import TextInput from '../../inputs/text-input';
import { Folder, CreateFolderRequest } from '../../../api-schemas/folder';
import { convertToSlug, showErrorMessage } from '../../../helpers/common';
import useCreateFolder from '../../../api-hooks/folder/useCreateFolder';
import useUpdateFolder from '../../../api-hooks/folder/useUpdateFolder';
import { FolderContext } from '../../../contexts/folder';

type ManageFolderDialogPropsType = {
  existingFolder?: Folder;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

const ManageFolderDialog: FC<ManageFolderDialogPropsType> = ({
  existingFolder,
  setOpenDialog,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedAccount, selectedLanguage } = useContext(AccountContext);
  const { selectedFolderId } = useContext(FolderContext);

  const [folder, setFolder] = useState<CreateFolderRequest>(
    existingFolder ?? {
      id: uuidv4(),
      titles: [
        {
          languageCode: selectedLanguage as string,
          title: 'New Folder',
        },
      ],
      slug: 'new-folder',
      accountId: selectedAccount.id,
      parentFolderId: selectedFolderId ?? null,
    }
  );

  const folderTitle = folder.titles.find(
    (title) => title.languageCode === selectedLanguage
  );

  const { createFolder, isCreatingFolder } = useCreateFolder(
    selectedAccount.id,
    selectedFolderId
  );
  const { updateFolder, isUpdatingFolder } = useUpdateFolder(
    selectedAccount.id,
    selectedFolderId
  );

  const handleTitleChanged = (event: FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const newFolder = { ...folder };
    const folderTitle = newFolder.titles.find(
      (title) => title.languageCode === selectedLanguage
    );

    folderTitle!.title = value;
    newFolder.slug = convertToSlug(value);

    setFolder(newFolder);
  };

  const handleSlugChanged = (event) => {
    const value = event.target.value;
    const newFolder = { ...folder };

    newFolder.slug = convertToSlug(value);

    setFolder(newFolder);
  };

  const handleCreateFolder = () => {
    createFolder(folder)
      .then(() => {
        setOpenDialog(false);
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 409) {
          showErrorMessage(enqueueSnackbar, t('error.Duplicate'));
        } else {
          showErrorMessage(enqueueSnackbar, t('error.unknown'));
        }
      });
  };

  const handleUpdateFolder = () => {
    updateFolder(folder)
      .then(() => {
        setOpenDialog(false);
      })
      .catch(() => {
        showErrorMessage(enqueueSnackbar, t('error.unknown'));
      });
  };

  return (
    <Modal
      title={existingFolder ? t('edit-folder') : t('new-folder')}
      open={true}
      okText={t('save')}
      onOk={existingFolder ? handleUpdateFolder : handleCreateFolder}
      onCancel={() => setOpenDialog(false)}
      confirmLoading={isCreatingFolder || isUpdatingFolder}
      bodyStyle={{ height: 200, minHeight: 200, padding: '0 0 0 10px' }}
    >
      <Row style={{ padding: 10 }}>
        <Col xs={24} style={{ padding: 5, marginBottom: 10 }}>
          <TextInput
            style={{ width: '100%' }}
            id="title"
            field="Title"
            label={t('title')}
            value={folderTitle!.title}
            onChange={handleTitleChanged}
            required={true}
          />
        </Col>

        <Col xs={24} style={{ padding: 5 }}>
          <TextInput
            style={{ width: '100%' }}
            field="Slug"
            label={t('slug')}
            value={folder.slug}
            onChange={handleSlugChanged}
            required={true}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ManageFolderDialog;
