import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const getAccountLogoRequest = ({
  accountId,
  updateDateTime,
}: {
  accountId: string;
  updateDateTime: string;
}) => {
  return axios
    .get(`/api/Account/${accountId}/Logo?updateDateTime=${updateDateTime}`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccountLogo({
  accountId,
  updateDateTime,
}: {
  accountId: string;
  updateDateTime: string;
}) {
  const {
    data,
    refetch: getAccountLogo,
    isFetching: isLoadingAccountLogo,
    isSuccess: isSuccessLoadingAccountLogo,
  } = useQuery({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'logo', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () => getAccountLogoRequest({ accountId, updateDateTime }),
  });

  const accountLogo = useMemo(() => data, [data]);

  return {
    accountLogo,
    isLoadingAccountLogo,
    isSuccessLoadingAccountLogo,

    getAccountLogo,
  };
}
