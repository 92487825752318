import yaml from 'js-yaml';

class DataProvider {
    public data;

    constructor(data: any) {
        this.data = data;
    }

    getJSONData() {
        switch (this.data.type) {
            case "yaml":
                return this.yamlToJSON();
            default:
                return this.data.content;
        }
    }

    yamlToJSON() {
        const jsonData = yaml.load(this.data.content.replaceAll(/\\n/g, '\n').replaceAll(/"/g, "").replaceAll(/'/g, "").replaceAll(/&nbsp;>-/g, "").replaceAll(/ >-/g, ""));
        return jsonData;
    }
}

export default DataProvider;