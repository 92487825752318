import React, {
  MouseEvent,
  KeyboardEvent,
  FC,
  useState,
  useContext,
} from 'react';
import { Dropdown, MenuProps } from 'antd';
import { FolderOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { Folder } from '../../../../api-schemas/folder';
import ManageFolderDialog from '../../manage-folder-dialog';
import useDeleteFolder from '../../../../api-hooks/folder/useDeleteFolder';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { AccountContext } from '../../../../contexts/account';
import AddFolderLanguageDialog from '../../add-folder-language-dialog';
import useUpdateFolder from '../../../../api-hooks/folder/useUpdateFolder';

import './styles.css';

type FolderListItemPropsType = {
  folder: Folder;
  onSelectFolder: (folder: Folder) => void;
};

const FolderListItem: FC<FolderListItemPropsType> = ({
  folder,
  onSelectFolder,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedLanguage } = useContext(AccountContext);

  const [openEditFolderDialog, setOpenEditFolderDialog] =
    useState<boolean>(false);
  const [openDeleteFolderDialog, setOpenDeleteFolderDialog] =
    useState<boolean>(false);
  const [openAddFolderLanguageDialog, setOpenAddFolderLanguageDialog] =
    useState<boolean>(false);

  const { deleteFolder, isDeletingFolder } = useDeleteFolder(
    folder.accountId,
    folder.parentFolderId
  );
  const { updateFolder, isUpdatingFolder } = useUpdateFolder(
    folder.accountId,
    folder.parentFolderId
  );

  const folderTitle = folder.titles.find(
    (title) => title.languageCode === selectedLanguage
  );

  const showErrorMessage = (error: AxiosError) => {
    if (error.response?.status === 422) {
      enqueueSnackbar(t('error.folderHasContent'), { variant: 'error' });

      setOpenDeleteFolderDialog(false);
    }
  };

  const handleDeleteFolder = () => {
    const updatedFolder = { ...folder };

    updatedFolder.titles = updatedFolder.titles.filter(
      (title) => title.languageCode !== selectedLanguage
    );

    if (updatedFolder.titles.length === 0) {
      deleteFolder({ folderId: folder.id }).catch(showErrorMessage);
    } else {
      updateFolder(updatedFolder).catch(showErrorMessage);
    }
  };

  const handleOpenEditFolderDialog = ({
    domEvent,
  }: {
    domEvent:
      | MouseEvent<HTMLElement, globalThis.MouseEvent>
      | KeyboardEvent<HTMLElement>;
  }) => {
    domEvent.stopPropagation();

    setOpenEditFolderDialog(true);
  };
  const handleOpenDeleteFolderDialog = ({
    domEvent,
  }: {
    domEvent:
      | MouseEvent<HTMLElement, globalThis.MouseEvent>
      | KeyboardEvent<HTMLElement>;
  }) => {
    domEvent.stopPropagation();

    setOpenDeleteFolderDialog(true);
  };

  const handleOpenAddFolderLanguageDialog = ({
    domEvent,
  }: {
    domEvent:
      | MouseEvent<HTMLElement, globalThis.MouseEvent>
      | KeyboardEvent<HTMLElement>;
  }) => {
    domEvent.stopPropagation();

    setOpenAddFolderLanguageDialog(true);
  };

  const items: MenuProps['items'] = [];

  if (folderTitle) {
    items.push(
      {
        key: '1',
        label: t('edit'),
        onClick: handleOpenEditFolderDialog,
        style: { width: 100 },
      },
      {
        key: '2',
        label: t('delete'),
        onClick: handleOpenDeleteFolderDialog,
        style: { width: 100, color: 'red' },
      }
    );
  } else {
    items.push({
      key: '1',
      label: t('add-language'),
      onClick: handleOpenAddFolderLanguageDialog,
      style: { width: 150 },
    });
  }

  return (
    <div
      className={folderTitle ? 'folder-list-item' : 'folder-list-item disabled'}
      onDoubleClick={() => (folderTitle ? onSelectFolder(folder) : null)}
    >
      <div>
        <FolderOutlined style={{ marginRight: 5 }} />
        <span>{folderTitle?.title || folder.slug}</span>
      </div>

      <Dropdown menu={{ items }}>
        <MoreOutlined style={{ cursor: 'pointer' }} />
      </Dropdown>

      {openEditFolderDialog ? (
        <ManageFolderDialog
          existingFolder={folder}
          setOpenDialog={setOpenEditFolderDialog}
        />
      ) : null}

      {openAddFolderLanguageDialog ? (
        <AddFolderLanguageDialog
          existingFolder={folder}
          setOpenDialog={setOpenAddFolderLanguageDialog}
        />
      ) : null}

      <ConfirmationDialog
        isOpen={openDeleteFolderDialog}
        onDialogClosed={() => setOpenDeleteFolderDialog(false)}
        title={t('delete-folder-title')}
        message={t('delete-folder-message')}
        onDialogConfirmed={handleDeleteFolder}
        loading={isDeletingFolder || isUpdatingFolder}
      />
    </div>
  );
};

export default FolderListItem;
