import { useMemo } from 'react';
import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Account } from '../../api-schemas/account';

const getAccountRequest = (accountId?: string) => {
  return axios
    .get<Account>(`/api/Account/${accountId}`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccount(accountId: string) {
  const {
    data,
    error: loadingAccountError,
    refetch: getAccount,
    isFetching: isLoadingAccount,
    isError: isErrorLoadingAccount,
    isSuccess: isSuccessLoadingAccount,
  } = useQuery<Account>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId),
    queryFn: () => getAccountRequest(accountId),
  });

  const account = useMemo(() => data, [data]);

  return {
    account,
    loadingAccountError: loadingAccountError as AxiosError,
    isLoadingAccount,
    isErrorLoadingAccount,
    isSuccessLoadingAccount,

    getAccount,
  };
}
