import React, { useContext } from 'react';
import OveralDescription from '../overall-description';
import { useTranslation } from 'react-i18next';
import AnalyticsTabs from '../analytics-tabs';
import { DocumentContext } from '../../../contexts/document';
import {
  calculateReadabilityScore,
  getDocumentMetrics,
} from '../../../helpers/common';
import { getLanguageCode, getVersionSlug } from '../../../helpers/route';
import { Col } from 'antd';

const ReadabilityAnalytics = ({
  data,
  handleChangeMetric,
  selectedTopic,
  selectedSidePart,
}) => {
  const { selectedDoc } = useContext(DocumentContext);

  const { t } = useTranslation();

  const settingsItem = window.location.pathname.split('/')[5];

  const metrics = selectedTopic?.metrics
    ? selectedTopic?.metrics
    : selectedDoc?.metrics
      ? getDocumentMetrics(selectedDoc, getVersionSlug(), getLanguageCode())
      : null;

  const score = metrics ? calculateReadabilityScore(metrics) : 0;

  const text =
    score > 60 && score <= 70
      ? `Great job! You're writing in plain English. Your readability score is ${score}%. It means your topic is easily understood by 13- to 15-year-old students (8th & 9th graders).`
      : score > 70 && score <= 80
        ? `Your readability score is ${score}% which is considered fairly easy to read. Your topic is understood by 7th graders.`
        : score > 80 && score <= 90
          ? `Your readability score is ${score}% which is considered easy to read. It means your topic is understood by 7th graders. If you aim to write in conversational English, you're doing great.`
          : score > 90 && score <= 100
            ? `Your readability score is ${score}% which is considered very easy to read. It means an average 11-year-old student can easily understand your topic`
            : `Your readability score is ${score}% which is considered difficult to read.`;
  return (
    <div style={{ display: 'contents' }}>
      <Col
        xs={selectedSidePart && settingsItem !== 'Metrics' ? 24 : 12}
        style={{
          paddingLeft: selectedSidePart ? 5 : 20,
          paddingRight: selectedSidePart ? 0 : 20,
        }}
      >
        <OveralDescription
          score={score}
          text={text}
          label={t('readability-overview-title')}
          className="readability"
          selectedTopic={selectedTopic}
          selectedSidePart={selectedSidePart}
        />
        <Col xs={24} style={{ display: 'flex' }}>
          <Col xs={24}>
            <AnalyticsTabs
              category="readability"
              handleChangeMetric={handleChangeMetric}
              data={data}
              selectedSidePart={selectedSidePart}
            />
          </Col>
        </Col>
      </Col>
    </div>
  );
};

export default ReadabilityAnalytics;
