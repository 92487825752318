import React, { useState } from 'react';
import { Col, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import yaml from 'js-yaml';
import MonacoYamlEditor from 'react-monaco-yaml-editor';

const YamlEditor = ({ data, handleChangeData, style, schemaUri }) => {
  const [theme, setTheme] = useState<string>('vs-light');

  const { t } = useTranslation();

  const handleChangeTheme = (event) => {
    setTheme(event.target.value);
  };

  const handleOnChange = (value: string | undefined) => {
    try {
      yaml.load(value);
      handleChangeData(value);
    } catch (error) {
      /* empty */
    }
  };

  return (
    <Col
      xs={24}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '1px solid #f0f0f0',
        width: '100%',
      }}
    >
      <Radio.Group
        style={{ marginBottom: 20, marginTop: 15 }}
        onChange={handleChangeTheme}
        defaultValue={'vs-light'}
        buttonStyle="solid"
        value={theme}
      >
        <Radio.Button value={'vs-light'}>{t('light')}</Radio.Button>
        <Radio.Button value={'vs-dark'}>{t('dark')}</Radio.Button>
      </Radio.Group>
      <MonacoYamlEditor
        schemaUri={schemaUri}
        value={data}
        onChange={handleOnChange}
        theme={theme}
        style={style}
      />
    </Col>
  );
};

export default YamlEditor;
