import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import config from 'react-global-configuration';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';

import './i18n';
import './config';
import * as serviceWorker from './serviceWorker';
import { DocumentProvider } from './contexts/document';
import { ProfileProvider } from './contexts/profile';
import { AccountProvider } from './contexts/account';
import { TranslateProvider } from './contexts/translate';
import { ComponentProvider } from './contexts/component';
import { AccessProvider } from './contexts/access';
import { StructuredDataContextProvider } from './contexts/structured-data-context';
import QueryProvider from './lib-providers/base-query-client';
import SnackbarProviderWrapper from './lib-providers/snackbar-provider';
import { TopicProvider } from './contexts/topic';
import { FolderProvider } from './contexts/folder';
import App from './App';

import './index.css';

const rootElement = document.getElementById('root');
const bodyElement = document.getElementsByTagName('body')[0];
const WebAssistantScript = document.createElement('script');

WebAssistantScript.setAttribute('name', 'SonatWebAssistant');

if (config.get('environment') === 'prod') {
  Sentry.init({
    dsn: config.get('sentry').dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://editor.sonat.com", /^\/api\//],
    replaysSessionSampleRate: 0.1, 
    replaysOnErrorSampleRate: 1.0,
  });

  WebAssistantScript.setAttribute('id', '8136c644-f513-4906-971e-53e937321f78');
  WebAssistantScript.setAttribute(
    'src',
    'https://resources-stage.sonat.com/webAssistantSonat.min.js'
  );
} else {
  WebAssistantScript.setAttribute('id', '1cbcec97-c277-453b-99c7-5fad430937a7');
  WebAssistantScript.setAttribute(
    'src',
    'https://resources.sonat.com/webAssistantSonat.min.js'
  );
}

bodyElement.appendChild(WebAssistantScript);

const root = createRoot(rootElement!);

localStorage.webApiAddress = '/api/';

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <SnackbarProviderWrapper>
      <BrowserRouter>
        <QueryProvider>
          <StructuredDataContextProvider>
            <DocumentProvider>
              <ProfileProvider>
                <AccountProvider>
                  <FolderProvider>
                    <AccessProvider>
                      <TranslateProvider>
                        <ComponentProvider>
                          <TopicProvider>
                            <Suspense
                              fallback={
                                <div>
                                  <CircularProgress
                                    style={{
                                      marginLeft: '50%',
                                      marginTop: '100px',
                                    }}
                                  />
                                </div>
                              }
                            >
                              <ConfigProvider
                                theme={{
                                  hashed: false,
                                  token: {
                                    fontFamily:
                                      '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeightStrong: 500
                                  },
                                }}
                              >
                                <App />
                              </ConfigProvider>
                            </Suspense>
                          </TopicProvider>
                        </ComponentProvider>
                      </TranslateProvider>
                    </AccessProvider>
                  </FolderProvider>
                </AccountProvider>
              </ProfileProvider>
            </DocumentProvider>
          </StructuredDataContextProvider>
        </QueryProvider>
      </BrowserRouter>
    </SnackbarProviderWrapper>
  </LocalizationProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
