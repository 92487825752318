import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const readUserNotificationRequest = (notificationId: string) => {
  return axios
    .put(
      `/api/User/Notification/${notificationId}/Read`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useReadUserNotification() {
  const {
    mutateAsync: readUserNotification,
    isPending: isReadingUserNotification,
    isSuccess: isSuccessReadingUserNotification,
  } = useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      readUserNotificationRequest(notificationId),
  });

  return {
    readUserNotification,
    isReadingUserNotification,
    isSuccessReadingUserNotification,
  };
}
