import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateFolderRequest } from '../../api-schemas/folder';

const createFolderRequest = (data: CreateFolderRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Folder', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateFolder(
  accountId: string,
  parentFolderId?: string
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createFolder,
    isPending: isCreatingFolder,
    isSuccess: isSuccessCreatingFolder,
  } = useMutation({
    mutationFn: createFolderRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: parentFolderId
          ? ['folders', accountId, parentFolderId]
          : ['folders', accountId],
      });
    },
  });

  return {
    createFolder,
    isCreatingFolder,
    isSuccessCreatingFolder,
  };
}
