import React from 'react';
import { Button } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import TopicItemMenu from '../item-menu';
import { Tree } from 'antd';
import { Key } from 'react';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { htmlDecode, list_to_tree } from '../../../../helpers/common';
import { changeRouteBasedOnTopic } from '../../../../helpers/route';

const styles: any = (theme) => ({
  root: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 90px)',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100vh',
    },
    padding: '10px 10px 10px 20px',
  },
  content: {
    width: '100%!important',
    justifyContent: 'initial!important',
    textTransform: 'none!important',
    borderTopRightRadius: '4px!important',
    borderBottomRightRadius: '4px!important',
    '&:hover': {
      backgroundColor: 'inherit!important',
    },
  },
  selectedItem: {
    width: '100%!important',
    justifyContent: 'initial!important',
    textTransform: 'none!important',
    backgroundColor: '#e8f0fe!important',
    borderTopRightRadius: '4px!important',
    borderBottomRightRadius: '4px!important',
    fontWeight: '500!important',
    '&:hover': {
      backgroundColor: '#e8f0fe!important',
    },
  },
  selectAll: {
    display: 'inline-block',
    color: '#286FBE',
    cursor: 'pointer',
    fontSize: 'medium',
  },
});

interface TopicTreeSelectProps extends WithTranslation {
  selectedNodeId?: string | null;
  selectedTopic?: any;
  usedForCopy?: boolean;
  shouldRenderAlreadyPublished?: boolean;
  topics?: any[];
  onTopicSelect?: (arg0: any, arg1: boolean) => any;
  classes?: any;
  usedForTopicLink?: boolean;
  createNewTopicContent?: any;
  onCopyTopic?: any;
  documentId?: string;
  onAddTopic?: any;
  onPublish?: any;
  onRemove?: any;
  checkedKeys?: string[];
  t: TFunction;
  loadingSoftly?: boolean;
  onTopicCheck?: any;
  viewMode?: boolean;
  children?: ReactNode;
  languageCode?: string;
  selectedVersion?: any;
  showHeadings?: boolean;
  expandeNodes?: boolean;
  checkable?: boolean;
  showMetricData?: boolean;
  metric?: any;
  selectedMetricCategory?: string;
  history?: any;
  setSelectedSidePart?: any;
  setSelectedTopic?: any;
  defaultSelectedTopics?: any[];
}

interface TopicTreeSelectState {
  expandedNodeIds: Key[];
  selectedNodeId: string | null;
  moveTopicLoading: boolean;
  anchorEl1: any;
  nodeEl1: any;
  anchorEl2: any;
  nodeEl2: any;
  searchValue: string | null;
  checkAll: boolean;
  copyRoot: boolean;
}

class TopicTreeSelect extends React.Component<
  TopicTreeSelectProps,
  TopicTreeSelectState
> {
  constructor(props) {
    super(props);
    this.state = {
      expandedNodeIds: [],
      selectedNodeId: this.props.selectedNodeId
        ? this.props.selectedNodeId
        : null,
      moveTopicLoading: false,
      anchorEl1: null,
      nodeEl1: null,
      anchorEl2: null,
      nodeEl2: null,
      searchValue: null,
      checkAll: false,
      copyRoot: false,
    };
  }

  componentDidMount() {
    if (
      this.props.expandeNodes &&
      this.props.usedForCopy !== true &&
      this.props.selectedTopic &&
      !this.state.expandedNodeIds.length
    ) {
      this.expandParents([this.props.selectedTopic]);
    }

    if (this.props.defaultSelectedTopics) {
      this.expandParents(this.props.defaultSelectedTopics);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.selectedTopic !== this.props.selectedTopic ||
        this.props.expandeNodes) &&
      this.props.usedForCopy !== true
    ) {
      if (this.props.selectedTopic && !this.state.expandedNodeIds.length)
        this.expandParents([this.props.selectedTopic]);
    }
  }

  checkPublishEnable = (node) => {
    // if (this.props.usedForCopy) return false;
    const isLanguageEqual = node.title !== null;
    const notPublishedYet = node.topicVersionNumber < 1.0;
    const isAlreadyPublished =
      node.latestTopicVersionId === node.publishedTopicVersionId &&
      isLanguageEqual;
    if (
      (notPublishedYet || !isAlreadyPublished || node.action === 'Deleted') &&
      isLanguageEqual
    ) {
      return { render: true, disable: false };
    } else {
      let hasOnePublishableChild;
      // check if topic has any child that is publishable
      if (node.children.length > 0) {
        const childrenPublishableStatus = node.children.map(
          (item) => this.checkPublishEnable(item).disable
        );
        hasOnePublishableChild = childrenPublishableStatus.find(
          (item) => item === false
        );
        if (hasOnePublishableChild === false)
          return { render: true, disable: true };
      }
      return {
        render: this.props.shouldRenderAlreadyPublished || false,
        disable: true,
      };
    }
  };

  checkTopicLinkEnable = (node) => {
    const isLanguageEqual = node.title !== null;
    if (!isLanguageEqual || node.action === 'Deleted') return true;
    else return false;
  };

  getNodeId = (node) => {
    return node.id;
  };

  onCloseEl1 = () => {
    this.setState({
      anchorEl1: null,
      nodeEl1: null,
    });
  };

  onCloseEl2 = () => {
    this.setState({
      anchorEl2: null,
      nodeEl2: null,
    });
  };

  shortenTitle = (title, length) => {
    if (title.length < length) return htmlDecode(title);
    else return htmlDecode(title.substring(0, length - 1) + '...');
  };

  makeTitle = (title) => {
    const length = window.location.pathname.includes('publish-request')
      ? 20
      : 35;
    if (!this.state.searchValue)
      return <span>{this.shortenTitle(title, length)}</span>;
    const index = title
      .toLowerCase()
      .indexOf(this.state.searchValue.toLowerCase());

    if (index !== -1) {
      const beforeStr = title.substr(0, index);
      // const matchedStr = title.substr(
      //   index,
      //   index + this.state.searchValue.length
      // );
      const afterStr = title.substr(index + this.state.searchValue.length);
      return (
        <span>
          {beforeStr}
          <span className="search-result">{this.state.searchValue}</span>
          {afterStr}
        </span>
      );
    } else return <span>{this.shortenTitle(title, length)}</span>;
  };

  expandParents = (searchResult) => {
    const searchResultList = [...searchResult];
    const newExpanded = [...this.state.expandedNodeIds];
    do {
      if (
        searchResultList[0] &&
        searchResultList[0].parentTopicId &&
        newExpanded.indexOf(searchResultList[0].parentTopicId) === -1
      ) {
        let parentTopic: any = null;
        // eslint-disable-next-line
        this.props.topics?.map((topic) => {
          if (topic.topicId === searchResultList[0].parentTopicId) {
            parentTopic = topic;
          }
        });
        if (parentTopic) {
          newExpanded.push(parentTopic.id);
          searchResultList.push(parentTopic);
        }
      }
      searchResultList.splice(0, 1);
    } while (searchResultList.length > 0);
    if (
      newExpanded &&
      (!this.state.expandedNodeIds ||
        JSON.stringify(this.state.expandedNodeIds) !==
          JSON.stringify(newExpanded))
    )
      this.setState({ expandedNodeIds: newExpanded });
  };

  onSelectNode = (event, node, addOrCopyIconClicked = false) => {
    if (
      (Boolean(this.state.anchorEl1) || Boolean(this.state.anchorEl2)) &&
      !addOrCopyIconClicked
    )
      return;

    if (
      this.props.onTopicSelect &&
      event.target.tagName.toLowerCase() !== 'svg' &&
      event.target.tagName.toLowerCase() !== 'path'
    ) {
      this.props.onTopicSelect(node, addOrCopyIconClicked);
      this.setState({ copyRoot: false });
    }
    if (
      event.target.closest('.MuiTreeItem-iconContainer') ||
      addOrCopyIconClicked
    ) {
      const expandedNodes = [...this.state.expandedNodeIds];
      const nodeId = this.getNodeId(node);
      const index = expandedNodes.indexOf(nodeId);
      if (index > -1) {
        if (!addOrCopyIconClicked) expandedNodes.splice(index, 1);
      } else expandedNodes.push(this.getNodeId(node));
      this.setState({
        expandedNodeIds: expandedNodes,
      });
    }
  };

  renderTree(tree) {
    const { classes } = this.props;
    const isLanguageEqual = tree.title !== null;
    let children = null;
    const nodeId = this.getNodeId(tree);
    const isSelected = this.state.copyRoot
      ? true
      : nodeId === this.props.selectedNodeId ||
        tree.topicId === this.props.selectedNodeId;
    let filteredChilderen = tree.children;

    if (this.props.usedForTopicLink)
      filteredChilderen = tree.children.filter(
        (topic) => !this.checkTopicLinkEnable(topic)
      );
    else if (!this.props.usedForCopy)
      filteredChilderen = tree.children.filter(
        (topic) => this.checkPublishEnable(topic).render
      );
    if (filteredChilderen && filteredChilderen.length > 0) {
      const sortedChildrens = filteredChilderen.sort((a, b) => {
        return a.topicOrder - b.topicOrder;
      });
      children = sortedChildrens.map((child) => this.renderTree(child));

      const treeDataItem = {
        title: (
          <Button
            variant={'text'}
            color={isSelected ? 'primary' : 'grey'}
            className={isSelected ? classes.selectedItem : classes.content}
            onClick={(event) => {
              if (
                this.props.showMetricData &&
                this.props.metric &&
                tree.metrics &&
                tree.metrics[this.props.metric.field] !== null &&
                tree.metrics[this.props.metric.field] !== undefined
              ) {
                this.props.setSelectedTopic(null);
                changeRouteBasedOnTopic(
                  this.props.selectedVersion,
                  { code: this.props.languageCode },
                  this.props.history,
                  tree.slug,
                  this.props.selectedMetricCategory
                );
              } else {
                this.onSelectNode(event, tree);
              }
            }}
            disabled={
              this.props.usedForTopicLink
                ? this.checkTopicLinkEnable(tree)
                : !this.props.usedForCopy
                  ? this.checkPublishEnable(tree).disable
                  : false
            }
          >
            {this.makeTitle(isLanguageEqual ? tree.title : tree.slug)}
            <div
              hidden={this.props.usedForTopicLink}
              style={{ position: 'absolute', right: '0px' }}
            >
              <TopicItemMenu
                createNewTopicContent={this.props.createNewTopicContent}
                isSelected={isSelected}
                hasChildren={true}
                anchorEl1={this.state.anchorEl1}
                nodeEl1={this.state.nodeEl1}
                node={tree}
                key={tree.topicId}
                topics={this.props.topics}
                onCopyTopic={this.props.onCopyTopic}
                documentId={this.props.documentId}
                handleAddTopic={this.props.onAddTopic}
                onCloseEl1={this.onCloseEl1}
                onPublish={this.props.onPublish}
                onRemove={this.props.onRemove}
                showMenu={false}
                showMetricData={this.props.showMetricData}
                metric={this.props.metric}
              />
            </div>
          </Button>
        ),
        key: nodeId,
        style: { marginRight: '10px' },
        selectable: false,
        disabled: this.props.usedForTopicLink
          ? this.checkTopicLinkEnable(tree)
          : !this.props.usedForCopy
            ? this.checkPublishEnable(tree).disable
            : false,
        children: children,
      };

      return treeDataItem;
    } else {
      const shouldRender =
        this.props.showMetricData ||
        (!this.props.usedForCopy && this.checkPublishEnable(tree).render) ||
        (this.props.usedForTopicLink && !this.checkTopicLinkEnable(tree)) ||
        (this.props.usedForCopy && !this.props.usedForTopicLink);

      if (shouldRender) {
        const treeDataItem = {
          title: (
            <Button
              variant={'text'}
              color={isSelected ? 'primary' : 'grey'}
              className={isSelected ? classes.selectedItem : classes.content}
              onClick={(event) => {
                if (
                  this.props.showMetricData &&
                  this.props.metric &&
                  tree.metrics &&
                  tree.metrics[this.props.metric.field] !== null &&
                  tree.metrics[this.props.metric.field] !== undefined
                ) {
                  this.props.setSelectedTopic(null);
                  changeRouteBasedOnTopic(
                    this.props.selectedVersion,
                    { code: this.props.languageCode },
                    this.props.history,
                    tree.slug,
                    this.props.selectedMetricCategory
                  );
                } else {
                  this.onSelectNode(event, tree);
                }
              }}
              disabled={
                this.props.usedForTopicLink
                  ? this.checkTopicLinkEnable(tree)
                  : !this.props.usedForCopy && !this.props.showMetricData
                    ? this.checkPublishEnable(tree).disable
                    : false
              }
            >
              {this.makeTitle(isLanguageEqual ? tree.title : tree.slug)}
              <div
                hidden={this.props.usedForTopicLink}
                style={{ position: 'absolute', right: '0px' }}
              >
                <TopicItemMenu
                  createNewTopicContent={this.props.createNewTopicContent}
                  isSelected={isSelected}
                  hasChildren={false}
                  anchorEl2={this.state.anchorEl2}
                  nodeEl2={this.state.nodeEl2}
                  node={tree}
                  key={tree.topicId}
                  topics={this.props.topics}
                  onCopyTopic={this.props.onCopyTopic}
                  documentId={this.props.documentId}
                  handleAddTopic={this.props.onAddTopic}
                  onCloseEl2={this.onCloseEl2}
                  onPublish={this.props.onPublish}
                  onRemove={this.props.onRemove}
                  showMenu={false}
                  showMetricData={this.props.showMetricData}
                  metric={this.props.metric}
                />
              </div>
            </Button>
          ),
          key: nodeId,
          style: { marginRight: '10px' },
          selectable: false,
          disabled:
            this.props.usedForTopicLink && this.checkTopicLinkEnable(tree),
        };

        return treeDataItem;
      } else if (filteredChilderen.length === 0) {
        return null;
      }
    }
  }

  render() {
    const topicsList = this.props.topics;
    const { classes, t } = this.props;

    let treeData: any[] = [];
    treeData = list_to_tree(
      topicsList?.map((topic) => {
        topic.key = topic.id;
        return topic;
      })
    );
    let nodes: any[] | undefined = [];
    //... sorting topic tree based on topicOrder if not empty
    const sortedTopicList: any[] = [];
    if (treeData.length > 0) {
      const sortedChildrens = treeData[0]?.children.sort((a, b) => {
        return a.topicOrder - b.topicOrder;
      });

      sortedChildrens.map((topic) => {
        const tree = this.renderTree(topic);
        if (tree) sortedTopicList.push(tree);
        return true;
      });

      if (!this.props.usedForCopy && !this.props.viewMode) {
        nodes?.push({
          checkable: false,
          key: 'checkall',
          title: (
            <Button
              variant={'text'}
              color={'grey'}
              className={classes.content}
              onClick={() => {
                if (this.props.checkedKeys && this.props.checkedKeys.length > 0)
                  this.props.onTopicCheck([]);
                else this.props.onTopicCheck(['checkall']);
              }}
            >
              <span className={classes.selectAll}>
                {this.props.checkedKeys && this.props.checkedKeys.length > 0
                  ? 'Uncheck all'
                  : 'Check all'}
              </span>
            </Button>
          ),
        });
      }
      if (this.props.usedForCopy && !this.props.usedForTopicLink) {
        nodes?.push({
          checkable: false,
          key: 'copyAll',
          style: { marginTop: '5px' },
          title: (
            <Button
              variant={'text'}
              color={'grey'}
              className={classes.content}
              onClick={() => {
                const shouldCopyAll = !this.state.copyRoot;
                this.setState({ copyRoot: !this.state.copyRoot });
                if (shouldCopyAll)
                  this.props.onTopicSelect &&
                    this.props.onTopicSelect(
                      this.props.topics?.find(
                        (item) => item.parentTopicId === null
                      ),
                      false
                    );
                else {
                  this.props.onTopicSelect &&
                    this.props.onTopicSelect(null, false);
                }
              }}
            >
              <span
                onClick={() => {
                  this.setState({ copyRoot: !this.state.copyRoot });
                  if (this.state.copyRoot)
                    this.props.onTopicSelect &&
                      this.props.onTopicSelect(
                        this.props.topics?.find(
                          (item) => item.parentTopicId === null
                        ),
                        false
                      );
                }}
                className={classes.selectAll}
              >
                {this.state.copyRoot ? 'UnSelect all' : 'Select All'}
              </span>
            </Button>
          ),
        });
      }

      if (!this.props.usedForTopicLink)
        nodes = [
          ...nodes,
          ...sortedTopicList.filter((node) => node.type !== 'span'),
        ];
      else nodes = [...nodes, ...sortedTopicList];
    }

    return (
      <div>
        {nodes && nodes.length > 0 ? (
          <div>
            <Tree
              blockNode={true}
              onExpand={(expandedKeys) => {
                this.setState({
                  expandedNodeIds: expandedKeys,
                });
              }}
              onCheck={(checkedKeys: any, info) => {
                if (
                  (info.nativeEvent.target as HTMLElement).classList.contains(
                    'ant-tree-checkbox-inner'
                  ) ||
                  (info.nativeEvent.target as HTMLElement).classList.contains(
                    'ant-tree-checkbox'
                  )
                ) {
                  if (
                    checkedKeys.checked.find((nodeId) => nodeId === 'checkall')
                  ) {
                    const allTopics = this.props.topics?.map(
                      (topic) => topic.id
                    );
                    this.props.onTopicCheck([...(allTopics || []), 'checkall']);
                  } else this.props.onTopicCheck(checkedKeys.checked);
                }
              }}
              checkedKeys={this.props.checkedKeys}
              checkable={
                !this.props.usedForCopy &&
                !this.props.viewMode &&
                this.props.checkable !== false
              }
              checkStrictly={true}
              expandedKeys={this.state.expandedNodeIds}
              selectedKeys={
                this.props.selectedNodeId ? [this.props.selectedNodeId] : []
              }
              multiple={false}
              style={{
                backgroundColor:
                  this.props.loadingSoftly || this.state.moveTopicLoading
                    ? '#f1f1f1'
                    : 'white',
                pointerEvents: this.props.loadingSoftly ? 'none' : 'auto',
                width: '100%',
              }}
              treeData={nodes}
            />
          </div>
        ) : !this.props.usedForCopy ? (
          <div style={{ marginTop: '10px', marginLeft: '20px' }}>
            {t<string>('empty-publish-topic-list')}
          </div>
        ) : !this.props.usedForTopicLink ? (
          <div style={{ marginTop: '10px' }}>
            {t<string>('empty-copy-topic-list')}
          </div>
        ) : (
          <div style={{ marginTop: '10px' }}>
            {t<string>('empty-topic-link-list')}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(TopicTreeSelect));
