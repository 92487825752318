import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { UpdateAccountBillingSettingsRequest } from '../../api-schemas/account';

const updateAccountBillingSettingsRequest = (
  data: UpdateAccountBillingSettingsRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .put(`/api/Account/billing-settings`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountBillingSettings() {
  const {
    mutateAsync: updateAccountBillingSettings,
    isPending: isUpdatingAccountBillingSettings,
    isSuccess: isSuccessUpdatingAccountBillingSettings,
  } = useMutation({
    mutationFn: updateAccountBillingSettingsRequest,
  });

  return {
    updateAccountBillingSettings,
    isUpdatingAccountBillingSettings,
    isSuccessUpdatingAccountBillingSettings,
  };
}
