import React, { useContext } from 'react';
import { List } from 'antd';

import { DocumentContext } from '../../contexts/document';
import VariableItem from '../account/variables/variable-item';
import useGetVariables from '../../api-hooks/variable/useGetVariables';
import { Variable } from '../../api-schemas/variable';

const VariableSelector = ({
  onSelectVariable,
}: {
  onSelectVariable: (variable: Variable) => void;
}) => {
  const { selectedDoc } = useContext(DocumentContext);

  const { variables, isLoadingVariables } = useGetVariables(
    selectedDoc.account.id
  );

  return (
    <List
      loading={isLoadingVariables}
      grid={{ gutter: 16, column: 4 }}
      dataSource={variables}
      renderItem={(variable: Variable) => (
        <VariableItem
          insertMode
          accountId={selectedDoc.account.id}
          key={variable.id}
          variable={variable}
          handleInsertVariable={onSelectVariable}
        />
      )}
    />
  );
};

export default VariableSelector;
