import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const PlanLimitationDialog = ({
  accountPlan,
  textTitle,
  open,
  textMessage,
  handleCloseLimitationDialog,
}) => {
  const history = useHistory();

  const handleGoToPlansPage = () => {
    handleCloseLimitationDialog();
    history.push('/org/settings/billing/plan');
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseLimitationDialog}>
        <DialogTitle>{textTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>
              This account has a <b>{accountPlan}</b> plan
            </span>
            <br />
            <br />
            <span>{textMessage}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLimitationDialog} color="primary">
            Close
          </Button>
          <Button
            onClick={handleGoToPlansPage}
            color="primary"
            variant="contained"
            autoFocus
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlanLimitationDialog;
