import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const translateTopicRequest = ({
  topicId,
  sourceLanguageCode,
  targetLanguageCode,
}: {
  topicId?: string;
  sourceLanguageCode?: string;
  targetLanguageCode?: string;
}) => {
  return axios
    .get(
      `/api/Topic/${topicId}/Translate/${sourceLanguageCode}/${targetLanguageCode}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useTranslateTopic({
  topicId,
  sourceLanguageCode,
  targetLanguageCode,
}: {
  topicId?: string;
  sourceLanguageCode?: string;
  targetLanguageCode?: string;
}) {
  const {
    data,
    refetch: translateTopic,
    isFetching: isTranslatingTopic,
    isSuccess: isSuccessTranslatingTopic,
  } = useQuery({
    queryKey: ['translate', topicId, sourceLanguageCode, targetLanguageCode],
    enabled: false,
    queryFn: () =>
      translateTopicRequest({
        topicId,
        sourceLanguageCode,
        targetLanguageCode,
      }),
  });

  const translatedTopic = useMemo(() => data, [data]);

  return {
    translatedTopic,
    isTranslatingTopic,
    isSuccessTranslatingTopic,

    translateTopic,
  };
}
