import React, { useState, useEffect, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LockIcon from '@mui/icons-material/Lock';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Divider,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  CardMedia,
  Collapse,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Description as DescriptionIcon,
  Translate as TranslateIcon,
  DynamicFeed as DynamicFeedIcon,
  Assessment as AssessmentIcon,
  Publish as PublishIcon,
  ExpandLess,
  ExpandMore,
  EditRounded,
  ContentPaste as ContentPasteIcon,
} from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import config from 'react-global-configuration';
import { MdOutlineHelpOutline } from 'react-icons/md';
import { Badge, Col, Tooltip } from 'antd';

import ProfilePicture from '../profile/picture';
import { ProfileContext } from '../../contexts/profile';
import DocumentVersionSelect from '../document/version/select';
import DocumentSelect from '../document/select';
import ViewLiveButton from '../viewLive';
import { DocumentContext } from '../../contexts/document';
import { AccountContext } from '../../contexts/account';
import ContactUs from '../contact-us';
import { useSnackbar } from 'notistack';
import Notification from '../notification';
import { AccessContext } from '../../contexts/access';
import { DocumentRoles } from '../../helpers/roles';
import { isHomepage } from '../../helpers/route';
import SelectAccountDropdown from '../account/select-account-dropdown';
import AccountLanguageSelect from '../account/language-select';
import { TopicContext } from '../../contexts/topic';
import buildInfo from '../../buildInfo.json'

const drawerWidth = 240;
const styles: any = (theme) => ({
  root: {
    width: '100%',
    color: 'inherit',
  },
  docerTitle: {
    color: theme.palette.common.white,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: '999999 !important',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  documentProperties: {
    marginLeft: '20px',
    padding: '5px 10px',
    paddingLeft: '20px',
    justifyContent: 'flex-end',
    // width: "100%"
  },
  documentMenu: {
    maxWidth: 'fit-content',
  },
});
const DocerAppBar = (props) => {
  const { classes, ...propsWithoutStyles } = props;
  const auth = true;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState({ openDrawer: false, openCircleImg: false });
  const [openContactUsForm, setOpenContactUsForm] = useState<boolean>(false);
  const [disableDocumentMenuItems, setDisableDocumentMenuItems] =
    useState<boolean>(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<string | null>(null);
  const [topics, setTopics] = useState([]);

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { documentId }: { documentId: string } = useParams();

  const { selectedLanguage, selectedVersion, selectedDoc, documentTopics } =
    React.useContext(DocumentContext);
  const { selectedTopic } = React.useContext(TopicContext);
  const { accountsData, selectedAccount } = React.useContext(AccountContext);
  const profile = React.useContext(ProfileContext);
  const { accountAccesses, documentAccesses } = React.useContext(AccessContext);

  useEffect(() => {
    if (
      accountAccesses.length &&
      documentAccesses.length &&
      profile &&
      profile.profileData
    ) {
      const allAccesses = [...accountAccesses, ...documentAccesses];
      const userAccess = allAccesses.find(
        (access) => access.principalId === profile.profileData.id
      );
      if (!userAccess || userAccess.action === DocumentRoles.WRITER) {
        setDisableDocumentMenuItems(true);
      }
    }
  }, [accountAccesses, documentAccesses, profile]);

  useEffect(() => {
    if (documentTopics) setTopics(documentTopics);
  }, [documentTopics]);

  const documentQueries = `lang=${
    selectedLanguage ? selectedLanguage.code : selectedDoc?.defaultLanguageCode
  }&ver=${
    selectedVersion
      ? selectedVersion.slug
      : selectedDoc?.defaultDocumentVersionSlug
  }`;

  const forthUrlParam: string = props.location.pathname.split('/')[4];
  const fifthUrlParam: string = props.location.pathname.split('/')[5];
  const shouldRenderDocMenu =
    (forthUrlParam === 'version' ||
      forthUrlParam === 'language' ||
      forthUrlParam === 'images' ||
      forthUrlParam === 'settings' ||
      forthUrlParam === 'feedback' ||
      forthUrlParam === 'metrics' ||
      forthUrlParam === 'RefLinks' ||
      forthUrlParam === 'private-document' ||
      fifthUrlParam === 'Feedback' ||
      fifthUrlParam === 'Settings' ||
      forthUrlParam === 'publish-request' ||
      forthUrlParam === 'publish-history' ||
      forthUrlParam === 'manage-team' ||
      forthUrlParam === 'manage-viewers' ||
      forthUrlParam === 'content' ||
      forthUrlParam === 'view-analytics' ||
      forthUrlParam === 'write-analytics' ||
      fifthUrlParam === 'History' ||
      fifthUrlParam === 'Metrics' ||
      fifthUrlParam === 'RefLinks' ||
      fifthUrlParam === 'content') &&
    selectedDoc;

  const firstUrlParam: string = props.location.pathname.split('/')[1];
  const secondUrlParam: string = props.location.pathname.split('/')[2];
  const shouldRenderAccountMenu =
    ((firstUrlParam === 'org' && secondUrlParam === 'settings') ||
      secondUrlParam === 'view-analytics' ||
      secondUrlParam === 'write-analytics' ||
      window.location.pathname === '/' ||
      window.location.pathname === '/notifications' ||
      window.location.pathname === '/profile') &&
    accountsData;

  /**
   * @description onload function
   */
  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleToggleDrawer = (forceClose = false) => {
    const forthUrlParam: string | undefined =
      window.location.pathname.split('/')[4];
    const secondUrlParam: string | undefined =
      window.location.pathname.split('/')[2];
    if (
      (forthUrlParam !== 'settings' && secondUrlParam !== 'settings') ||
      !open.openDrawer ||
      forceClose
    )
      setTimeout(() => {
        setOpen({ openDrawer: !open.openDrawer, openCircleImg: false });
      }, 50);
  };

  const findSelectedMenuItem = () => {
    if (open.openDrawer) setOpen({ openDrawer: false, openCircleImg: false });

    const forthUrlParam: string = window.location.pathname.split('/')[4];
    const secondUrlParam: string = window.location.pathname.split('/')[2];

    if (
      secondUrlParam === 'view-analytics' ||
      forthUrlParam === 'view-analytics'
    )
      setSelectedMenuItem('view-analytics');

    if (
      secondUrlParam === 'write-analytics' ||
      forthUrlParam === 'write-analytics'
    )
      setSelectedMenuItem('write-analytics');

    if (secondUrlParam === 'settings') {
      const thirdUrlParam: string = window.location.pathname.split('/')[3];
      if (thirdUrlParam) {
        if (thirdUrlParam === 'billing') {
          setIsCollapsed('Billing');
          const fourthUrlParam: string = window.location.pathname.split('/')[4];
          switch (fourthUrlParam) {
            case 'plan':
              setSelectedMenuItem('plan');
              break;
            case 'payment-method':
              setSelectedMenuItem('payment-method');
              break;
            case 'invoices':
              setSelectedMenuItem('invoices');
              break;
            default:
              break;
          }
        } else if (thirdUrlParam === 'private-document') {
          setSelectedMenuItem('org-private-document');
        } else if (thirdUrlParam === 'manage-team') {
          setSelectedMenuItem('org-manage-team');
        } else if (thirdUrlParam === 'manage-viewers') {
          setSelectedMenuItem('org-manage-viewers');
        } else if (thirdUrlParam === 'processes') {
          setSelectedMenuItem('processes');
        } else if (thirdUrlParam === 'manage-tokens') {
          setSelectedMenuItem('manage-tokens');
        } else if (thirdUrlParam === 'variables') {
          setSelectedMenuItem('org-variables');
        }
      } else {
        setSelectedMenuItem('org-settings');
      }
    } else if (forthUrlParam) {
      switch ((forthUrlParam.match(/\S[^?]*(?:\?+|$)/g) as string[])[0]) {
        case 'content':
          setSelectedMenuItem('content');
          break;
        case 'settings':
          setSelectedMenuItem('document-settings');
          break;
        case 'publish-request':
          setSelectedMenuItem('publish-request');
          break;
        case 'language':
          setSelectedMenuItem('languages');
          break;
        case 'version':
          setSelectedMenuItem('variants');
          break;
        case 'images':
          setSelectedMenuItem('images');
          break;
        case 'manage-team':
          setSelectedMenuItem('document-manage-team');
          break;
        case 'private-document':
          setSelectedMenuItem('private-document');
          break;
        case 'publish-history':
          setSelectedMenuItem('publish-history');
          setIsCollapsed('Analytics');
          break;
        case 'feedback':
          setSelectedMenuItem('feedback');
          setIsCollapsed('Analytics');
          break;
        case 'metrics':
          setSelectedMenuItem('metrics');
          setIsCollapsed('Analytics');
          break;
        case 'refLinks':
          setSelectedMenuItem('refLinks');
          setIsCollapsed('refLinks');
          break;
        case 'view-analytics':
          setSelectedMenuItem('view-analytics');
          setIsCollapsed('Analytics');
          break;
        case 'write-analytics':
          setSelectedMenuItem('write-analytics');
          setIsCollapsed('Analytics');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    findSelectedMenuItem();
    // eslint-disable-next-line
  }, [forthUrlParam, secondUrlParam]);

  const handleCloseDrawer = () => {
    setOpen({ openDrawer: false, openCircleImg: false });
  };

  const handleToggleCircleImg = (event: any = null) => {
    if (!open.openCircleImg) {
      setAnchorEl(event?.currentTarget);
    }
    setTimeout(() => {
      setOpen({ openDrawer: false, openCircleImg: !open.openCircleImg });
    }, 50);
  };

  const handleCollapse = (
    item: string | null = null,
    isChild: boolean = false
  ) => {
    if (item === isCollapsed && !isChild) setIsCollapsed(null);
    else setIsCollapsed(item);
  };

  const goToRoot = () => {
    if (location.pathname !== '/') {
      history.push(`/?lang=${selectedAccount.defaultLanguageCode}`);
    }
  };

  const handleOnContactUsClicked = () => {
    handleToggleCircleImg();
    setOpenContactUsForm(true);
  };

  const getUserName = () => {
    return localStorage.user_name;
  };

  const handleSignOut = () => {
    window
      .fetch(`${localStorage.webApiAddress}user/sessionLogout`, {
        method: 'POST',
      })
      .then(() => {
        localStorage.removeItem('auth_token');
        if (window.location.hostname === 'localhost') {
          window.location.assign(`/auth/login`);
        } else {
          window.location.assign(`${config.get('editorURL')}/auth/login`);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error.errors
            ? t(`error.${error.errors[0].code}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
  };
  /**
   * @description render form
   */

  return (
    <div className={classes.root}>
      <AppBar position="static" id="navbar" style={{ boxShadow: 'none' }}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={() => handleToggleDrawer(false)}
          >
            <MenuIcon />
          </IconButton>

          <Button className={classes.docerTitle} onClick={goToRoot}>
            <CardMedia
              component="img"
              image={process.env.PUBLIC_URL + '/logo.svg'}
              title="Sonat"
              style={{
                width: '130px',
                height: '30px',
              }}
            />
          </Button>

          {shouldRenderDocMenu &&
            history.location.pathname.split('/')[4] !== 'view-analytics' && (
              <Fragment>
                <Grid
                  className={classes.documentProperties}
                  container
                  direction="row"
                  spacing={1}
                  style={{ margin: 'auto' }}
                >
                  <Col>
                    <DocumentSelect {...propsWithoutStyles}></DocumentSelect>
                  </Col>

                  {history.location.pathname.split('/')[4] !==
                  'publish-history' ? (
                    <DocumentVersionSelect
                      document={selectedDoc}
                      {...propsWithoutStyles}
                    />
                  ) : null}
                </Grid>
              </Fragment>
            )}

          <div className={classes.grow} />

          {isHomepage() ? <AccountLanguageSelect /> : null}

          {(!shouldRenderDocMenu && (!selectedTopic || selectedDoc)) ||
          history.location.pathname.split('/')[4] === 'view-analytics' ||
          history.location.pathname.split('/')[4] === 'write-analytics' ? (
            <SelectAccountDropdown />
          ) : null}

          <Notification />

          <MdOutlineHelpOutline
            style={{ marginRight: 10, fontSize: 25, cursor: 'pointer' }}
            data-swa-paneljstrigger
          />

          {auth && profile && profile.profileData && (
            <div>
              <IconButton
                aria-owns={open.openCircleImg ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleToggleCircleImg}
                color="inherit"
              >
                <ProfilePicture />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={open.openCircleImg}
                onClose={handleToggleCircleImg}
              >
                <MenuItem
                  style={{
                    cursor: 'default',
                    pointerEvents: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span style={{ textAlign: 'center' }}>{getUserName()}</span>
                  <span style={{ textAlign: 'center', fontSize: 10 }}>
                    {profile.profileData.email}
                  </span>
                </MenuItem>

                <Divider style={{ marginTop: 0, marginBottom: 0 }} />

                <MenuItem
                  onClick={() => history.push('/profile')}
                  style={{ justifyContent: 'center' }}
                >
                  {t('profile')}
                </MenuItem>

                <Divider style={{ marginTop: 0, marginBottom: 0 }} />

                <MenuItem
                  onClick={handleOnContactUsClicked}
                  style={{ justifyContent: 'center' }}
                >
                  {t('feedback')}
                </MenuItem>

                <Divider style={{ marginTop: 0, marginBottom: 0 }} />

                <MenuItem style={{ justifyContent: 'center' }}>
                  <a
                    href="https://sonat.com/@sonat/knowledge-base"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  >
                    {t('need-help')}
                  </a>
                </MenuItem>

                <Divider style={{ marginTop: 0, marginBottom: 0 }} />

                <MenuItem
                  style={{ justifyContent: 'center' }}
                  onClick={handleSignOut}
                >
                  {t('sign-out')}
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <ClickAwayListener onClickAway={handleCloseDrawer}>
        <SwipeableDrawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open.openDrawer}
          onOpen={() => handleToggleDrawer()}
          onClose={() => handleToggleDrawer()}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <div>
              <div className={classes.drawerHeader}>
                <IconButton
                  id="close-menu-icon"
                  onClick={() => handleToggleDrawer(true)}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </div>

              <Divider />

              {location.pathname !== '/' ? (
                <>
                  <List>
                    <ListItem
                      button
                      key={'doc'}
                      onClick={() => {
                        history.push(
                          `/?lang=${selectedAccount.defaultLanguageCode}`
                        );
                        setSelectedMenuItem(null);
                        handleToggleDrawer();
                      }}
                    >
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <DescriptionIcon />
                      </ListItemIcon>

                      <ListItemText primary={t('home')} />
                    </ListItem>
                  </List>

                  <Divider />
                </>
              ) : null}

              {accountsData &&
                profile.profileData &&
                !accountsData.find(
                  (account) => account.email === profile.profileData.email
                ) && (
                  <Fragment>
                    <List>
                      <ListItem
                        button
                        key={'create-account'}
                        onClick={() => {
                          history.push('/org/settings/create');
                          setSelectedMenuItem(null);
                          handleToggleDrawer();
                        }}
                      >
                        <ListItemIcon>
                          <AddBoxIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('create-org')} />
                      </ListItem>
                    </List>
                    <Divider />
                  </Fragment>
                )}
              {shouldRenderDocMenu && (
                <List>
                  {[
                    {
                      text: 'Edit Content',
                      icon: <EditRounded />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/content?${documentQueries}`,
                      hasChild: false,
                      key: 'content',
                    },
                    {
                      text: 'Document Settings',
                      icon: <SettingsIcon />,
                      key: 'document-settings',
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/settings?${documentQueries}`,
                    },
                    {
                      text: 'Publish Requests',
                      icon: <PublishIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/publish-request?${documentQueries}`,
                      key: 'publish-request',
                    },
                    {
                      text: 'Languages',
                      icon: <TranslateIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/language`,
                      key: 'languages',
                    },
                    {
                      text: 'Variants',
                      icon: <DynamicFeedIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/version`,
                      key: 'variants',
                    },
                    {
                      text: 'Images',
                      icon: <PhotoLibraryIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/images`,
                      key: 'images',
                    },
                    {
                      text: 'Manage Team',
                      icon: <GroupIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/manage-team`,
                      key: 'document-manage-team',
                    },
                    {
                      text: 'Private Document',
                      icon: <LockIcon />,
                      url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/private-document`,
                      key: 'private-document',
                    },
                    {
                      text: 'Analytics',
                      icon: <AssessmentIcon />,
                      hasChild: true,
                      key: 'Analytics',
                      children: [
                        {
                          currentHostName: null,
                          text: 'View Metrics (Beta)',
                          url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/view-analytics`,
                          key: 'view-analytics',
                        },
                        {
                          currentHostName: null,
                          text: 'Write Metrics (Beta)',
                          url: `/document/${selectedDoc?.account.slug}/${selectedDoc?.slug}/write-analytics`,
                          key: 'write-analytics',
                        },
                        {
                          currentHostName: null,
                          text: 'Publish History',
                          icon: (
                            <MdOutlinePublishedWithChanges
                              style={{ width: 20, height: 20, marginLeft: 2 }}
                            />
                          ),
                          url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/publish-history?${documentQueries}`,
                          key: 'publish-history',
                        },
                        {
                          currentHostName: null,
                          text: 'Feedback',
                          url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/feedback?${documentQueries}`,
                          key: 'feedback',
                        },
                        {
                          currentHostName: null,
                          text: 'Quality Metrics',
                          url: `/document/${selectedDoc?.account.id}/${selectedDoc?.id}/metrics?${documentQueries}`,
                          key: 'metrics',
                        },
                      ],
                    },
                    {
                      text: 'Share',
                      url: '',
                      key: 'share',
                    },
                  ].map((item) => {
                    const component =
                      item.text === 'Share' ? (
                        <ViewLiveButton
                          key={item.text}
                          docId={documentId}
                          document={selectedDoc}
                          selectedLanguage={selectedLanguage}
                          selectedVersion={selectedVersion}
                          topics={topics}
                          documentViewLive={true}
                          disabled={false}
                        />
                      ) : item.text === 'Publish Requests' ? (
                        <ListItem
                          button
                          key={item.key}
                          style={{
                            color:
                              selectedMenuItem === item.key
                                ? '#1a73e8'
                                : 'inherit',
                            opacity: disableDocumentMenuItems ? '0.5' : '1',
                          }}
                          onClick={() => {
                            if (disableDocumentMenuItems) {
                              handleCollapse();
                              return;
                            }
                            history.push(item.url as string);
                            findSelectedMenuItem();
                            handleCollapse();
                            handleToggleDrawer();
                          }}
                        >
                          <ListItemIcon
                            style={
                              selectedMenuItem === item.key
                                ? { color: '#1a73e8', minWidth: '35px' }
                                : { minWidth: '35px' }
                            }
                          >
                            <Fragment>{item.icon}</Fragment>
                          </ListItemIcon>

                          <ListItemText primary={t(`${item.text}`)} />

                          <Badge count={selectedDoc?.openPublishRequests} />
                        </ListItem>
                      ) : item.hasChild ? (
                        <React.Fragment key={item.key}>
                          <ListItem
                            button
                            style={{
                              opacity:
                                disableDocumentMenuItems &&
                                item.key !== 'Analytics'
                                  ? '0.5'
                                  : '1',
                            }}
                            onClick={() => handleCollapse(item.text)}
                          >
                            <ListItemIcon style={{ minWidth: 35 }}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={t(`${item.text}`)} />
                            {isCollapsed === item.text ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={isCollapsed === item.text}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {item.children?.map((child) => {
                                const component = (
                                  <ListItem
                                    button
                                    key={child.key}
                                    className={classes.nested}
                                    style={{
                                      color:
                                        selectedMenuItem === child.key
                                          ? '#1a73e8'
                                          : 'inherit',
                                      opacity:
                                        disableDocumentMenuItems &&
                                        item.key !== 'Analytics'
                                          ? '0.5'
                                          : '1',
                                    }}
                                    onClick={() => {
                                      if (
                                        disableDocumentMenuItems &&
                                        item.key !== 'Analytics'
                                      ) {
                                        handleCollapse(item.text, true);
                                        return;
                                      }
                                      if (child.currentHostName)
                                        history.push(child.url, {
                                          prevHostName: child.currentHostName,
                                        });
                                      else history.push(child.url);
                                      findSelectedMenuItem();
                                      handleCollapse(item.text, true);
                                      handleToggleDrawer();
                                    }}
                                  >
                                    <ListItemText
                                      style={{ marginLeft: 35 }}
                                      primary={t(`${child.text}`)}
                                    />
                                  </ListItem>
                                );
                                if (
                                  !disableDocumentMenuItems ||
                                  item.key === 'Analytics'
                                ) {
                                  return component;
                                } else {
                                  return (
                                    <Tooltip
                                      key={child.key}
                                      title={t<string>(
                                        'error.insufficientPermission'
                                      )}
                                    >
                                      {component}
                                    </Tooltip>
                                  );
                                }
                              })}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      ) : (
                        <ListItem
                          button
                          key={item.key}
                          style={{
                            color:
                              selectedMenuItem === item.key
                                ? '#1a73e8'
                                : 'inherit',
                            opacity:
                              disableDocumentMenuItems && item.key !== 'content'
                                ? '0.5'
                                : '1',
                          }}
                          onClick={() => {
                            if (
                              disableDocumentMenuItems &&
                              item.key !== 'content'
                            ) {
                              handleCollapse();
                              return;
                            }
                            history.push(item.url as string);
                            findSelectedMenuItem();
                            handleToggleDrawer();
                            handleCollapse();
                          }}
                        >
                          <ListItemIcon
                            style={
                              selectedMenuItem === item.key
                                ? { color: '#1a73e8', minWidth: '35px' }
                                : { minWidth: '35px' }
                            }
                          >
                            <Fragment>{item.icon}</Fragment>
                          </ListItemIcon>
                          <ListItemText primary={t(`${item.text}`)} />
                        </ListItem>
                      );
                    if (
                      !disableDocumentMenuItems ||
                      (item.hasChild && item.key === 'Analytics') ||
                      item.key === 'content'
                    ) {
                      return component;
                    } else {
                      return (
                        <Tooltip
                          key={item.key}
                          title={t<string>('error.insufficientPermission')}
                        >
                          {component}
                        </Tooltip>
                      );
                    }
                  })}
                </List>
              )}
              {shouldRenderAccountMenu &&
                profile.profileData &&
                ((accountsData &&
                  accountsData.find(
                    (account) => account.email === profile.profileData.email
                  )) ||
                  (accountAccesses &&
                    accountAccesses.find(
                      (access) => access.email === profile.profileData.email
                    ))) && (
                  <List>
                    {[
                      {
                        text: 'Organization Settings',
                        icon: <SettingsIcon />,
                        hasChild: false,
                        key: 'org-settings',
                        url: `/org/settings`,
                      },
                      {
                        text: 'Approval Processes',
                        icon: <AssignmentTurnedInIcon />,
                        url: `/org/settings/processes`,
                        key: 'processes',
                      },
                      {
                        text: 'Billing',
                        icon: <PaymentIcon />,
                        hasChild: true,
                        key: 'billing',
                        children: [
                          {
                            text: 'Plan',
                            url: `/org/settings/billing/plan`,
                            currentHostName: window.location.hostname,
                            key: 'plan',
                          },
                          {
                            text: 'Payment Method',
                            url: `/org/settings/billing/payment-method`,
                            currentHostName: window.location.hostname,
                            key: 'payment-method',
                          },
                          {
                            text: 'Invoices',
                            url: `/org/settings/billing/invoices`,
                            currentHostName: window.location.hostname,
                            key: 'invoices',
                          },
                          {
                            text: 'Pricing Table',
                            url: `/org/settings/billing/pricing-table`,
                            currentHostName: window.location.hostname,
                            key: 'pricing-table',
                          },
                        ],
                      },
                      {
                        text: 'Private Document',
                        icon: <LockIcon />,
                        key: 'org-private-document',
                        url: `/org/settings/private-document`,
                      },
                      {
                        text: 'Variables',
                        icon: <ContentPasteIcon />,
                        key: 'org-variables',
                        url: `/org/settings/variables`,
                      },
                      {
                        text: 'Analytics',
                        icon: <AssessmentIcon />,
                        hasChild: true,
                        key: 'Analytics',
                        children: [
                          {
                            currentHostName: null,
                            text: 'View Metrics (Beta)',
                            url: `/org/view-analytics`,
                            key: 'view-analytics',
                          },
                          {
                            currentHostName: null,
                            text: 'Write Metrics (Beta)',
                            url: `/org/write-analytics`,
                            key: 'write-analytics',
                          },
                        ],
                      },
                      {
                        text: 'Manage Team',
                        icon: <GroupIcon />,
                        url: `/org/settings/manage-team`,
                        key: 'org-manage-team',
                      },
                      {
                        text: 'Share',
                        url: '',
                        key: 'share',
                      },
                    ].map((item) =>
                      item.text === 'Share' ? (
                        <ViewLiveButton
                          key={item.text}
                          account={selectedAccount}
                          accountViewLive={true}
                          disabled={false}
                        />
                      ) : item.hasChild ? (
                        <React.Fragment key={item.key}>
                          <ListItem
                            button
                            key={item.key}
                            onClick={() => handleCollapse(item.text)}
                          >
                            <ListItemIcon style={{ minWidth: 35 }}>
                              {item.icon}
                            </ListItemIcon>

                            <ListItemText primary={t(`${item.text}`)} />
                            {isCollapsed === item.text ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>

                          <Collapse
                            in={isCollapsed === item.text}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {item.children?.map((child) =>
                                (child.key === 'pricing-table' &&
                                  config.get('environment') === 'stage') ||
                                child.key !== 'pricing-table' ? (
                                  <ListItem
                                    key={child.key}
                                    button
                                    className={classes.nested}
                                    style={
                                      selectedMenuItem === child.key
                                        ? { color: '#1a73e8' }
                                        : {}
                                    }
                                    onClick={() => {
                                      if (child.currentHostName)
                                        history.push(child.url, {
                                          prevHostName: child.currentHostName,
                                        });
                                      else history.push(child.url);
                                      findSelectedMenuItem();
                                      handleCollapse(item.text, true);
                                      handleToggleDrawer(true);
                                    }}
                                  >
                                    <ListItemText
                                      style={{ marginLeft: 35 }}
                                      primary={t(`${child.text}`)}
                                    />
                                  </ListItem>
                                ) : null
                              )}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      ) : (
                        <ListItem
                          button
                          key={item.text}
                          style={
                            selectedMenuItem === item.key
                              ? { color: '#1a73e8' }
                              : {}
                          }
                          onClick={() => {
                            history.push(item.url as string);
                            findSelectedMenuItem();
                            handleCollapse();
                            handleToggleDrawer(true);
                          }}
                        >
                          <ListItemIcon
                            style={
                              selectedMenuItem === item.key
                                ? { color: '#1a73e8', minWidth: '35px' }
                                : { minWidth: '35px' }
                            }
                          >
                            <Fragment>{item.icon}</Fragment>
                          </ListItemIcon>

                          <ListItemText primary={t(`${item.text}`)} />
                        </ListItem>
                      )
                    )}
                  </List>
                )}
            </div>

            <div>
              <Divider />

              <span style={{ padding: '10px 16px', display: 'block' }}>
                V{buildInfo.version}
              </span>
            </div>
          </div>
        </SwipeableDrawer>
      </ClickAwayListener>

      <ContactUs
        openDialog={openContactUsForm}
        closeDialog={setOpenContactUsForm}
      />
    </div>
  );
};

export default withStyles(styles)(withRouter(DocerAppBar));
