import React, { useState, useEffect, Fragment } from 'react';
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from '@mui/material/Skeleton';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { DocumentService } from '../../../../services/document';
import { topicService } from '../../../../services/topic';
import PublishCard from '../card';
import { DocumentContext } from '../../../../contexts/document';
import { AccountContext } from '../../../../contexts/account';
import { ProfileContext } from '../../../../contexts/profile';
import { Link, useParams } from 'react-router-dom';
import { getLanguageCode, getVersionSlug } from '../../../../helpers/route';
import { Col, Row, Select } from 'antd';
import TextInput from '../../../inputs/text-input';
import SelectInput from '../../../inputs/select-input';
import useGetAccesses from '../../../../api-hooks/access/useGetAccesses';
import useGetPublishRequests from '../../../../api-hooks/publish-request/useGetPublishRequests';

const { Option } = Select;

const styles: any = () => ({
  docLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabs: {
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
  },
});

function PublishList(props) {
  const {
    selectedDoc,
    setSelectedDoc,
    versions,
    setVersions,
    topicsPerLanguageVersion,
    setTopicsPerLanguageVersion,
  } = React.useContext(DocumentContext);
  const { setUserRole } = React.useContext(AccountContext);
  const { profileData } = React.useContext(ProfileContext);

  const { i18n } = useTranslation();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPublishes, setLoadingPublishes] = useState<boolean>(false);
  const [publishes, setPublishes] = useState<any[]>([]);
  const [sortType, setSortType] = useState<string>('sortByDate');
  const [searchValue, setSearchValue] = useState<string>('');
  const [accesses, setAccesses] = useState<any[]>([]);

  const tabs: string[] = ['Open', 'Published', 'Closed', 'All'];
  const pageSize: number = 10;

  const { t } = useTranslation();

  const { getAccesses } = useGetAccesses(selectedDoc?.account?.id);
  const { getPublishRequests } = useGetPublishRequests({
    documentId,
    pageIndex,
    pageSize,
    status: tabs[selectedTab],
  });

  const documentService = new DocumentService();
  const topicAPI = new topicService();

  useEffect(() => {
    if (!selectedDoc) loadDocument();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedDoc) {
      setPageIndex(0);
      setPublishes([]);
      setHasMore(true);
      setLoadingPublishes(true);
    }
    // eslint-disable-next-line
  }, [selectedDoc, selectedTab]);

  useEffect(() => {
    if (loadingPublishes) {
      loadPublishes();
    }
    // eslint-disable-next-line
  }, [loadingPublishes]);

  useEffect(() => {
    if (selectedDoc) {
      getAccountAccesses();
      loadVersions();
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  useEffect(() => {
    if (publishes.length > 0) {
      const topicsList = { ...topicsPerLanguageVersion };

      publishes.map(async (publish: any) => {
        const topicIndex: number =
          publish.documentVersionId + publish.languageCode;
        if (topicsList[topicIndex] !== 'loading' && publish.status === 'Open') {
          topicsList[topicIndex] = 'loading';
          setTopicsPerLanguageVersion(topicsList);
          await topicAPI
            .getTopics(publish.documentVersionId, publish.languageCode)
            .then((topics) => {
              topicsList[topicIndex] = topics;
              setTopicsPerLanguageVersion(topicsList);
            })
            .catch(() => {
              props.enqueueSnackbar(t(`error.getTopics`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
            });
        }
      });
    }
    // eslint-disable-next-line
  }, [publishes]);

  useEffect(() => {
    if (publishes.length > 0) {
      const topicsList = { ...topicsPerLanguageVersion };

      publishes.map(async (publish: any) => {
        const topicIndex: number =
          publish.documentVersionId + publish.languageCode;
        if (topicsList[topicIndex] !== 'loading' && publish.status === 'Open') {
          topicsList[topicIndex] = 'loading';
          setTopicsPerLanguageVersion(topicsList);
          await topicAPI
            .getTopicsWithCache(publish.documentVersionId, publish.languageCode)
            .then((topics) => {
              topicsList[topicIndex] = topics;
              setTopicsPerLanguageVersion(topicsList);
            })
            .catch(() => {
              props.enqueueSnackbar(t(`error.getTopics`), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
            });
        }
      });
    }
    // eslint-disable-next-line
  }, [publishes]);

  const loadDocument = async () => {
    setLoading(true);
    i18n.changeLanguage(navigator.language);
    await documentService
      .getDocument(documentId)
      .then((doc) => {
        setSelectedDoc(doc);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.getDocument`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };
  const loadVersions = async () => {
    i18n.changeLanguage(navigator.language);
    const versions = await documentService.getVersions(selectedDoc.id);
    setVersions(versions);
  };

  const loadPublishes = async () => {
    if (loadingPublishes && hasMore) {
      await getPublishRequests()
        .then((result) => {
          const publishRequests = result.data;

          if (publishRequests) {
            setHasMore(publishRequests.length >= pageSize);
            setPageIndex(pageIndex + 1);
            setPublishes([...publishes, ...publishRequests]);
            setLoadingPublishes(false);

            return publishes;
          }
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getPublishRequests`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
          setHasMore(false);
          setLoadingPublishes(false);
        });
    }
  };

  const getAccountAccesses = async () => {
    if (selectedDoc && selectedDoc.account && selectedDoc.account.id)
      getAccesses()
        .then((result) => {
          const accesses = result.data;

          if (accesses) {
            setAccesses(accesses);
            const user = accesses.find(
              (access) => access.email === profileData?.email
            );
            if (user) {
              setUserRole(user.action);
            }
          }
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getAccesses`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    return accesses;
  };

  const handleChange = (event, newValue: number) => {
    setSelectedTab(newValue);
    setSearchValue('');
  };

  const loadPublishRequests = async () => {
    if (hasMore)
      if (!loadingPublishes) {
        setLoadingPublishes(true);
      }
  };

  const renderPublish = (publish) => {
    if (accesses && versions)
      return (
        <PublishCard
          accesses={accesses}
          publish={publish}
          versions={versions}
          key={publish.id}
        />
      );
    else return null;
  };

  const renderPublishes = () => {
    return (
      <List>
        {publishes && publishes.length > 0 ? (
          <div style={{ height: 700, overflow: 'auto' }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadPublishRequests}
              hasMore={hasMore}
              loader={
                <span style={{ width: '100%', height: '500px' }}>
                  {' '}
                  {publishSkeleton(['1', '2', '3', '4', '5', '6', '7', '8'])}
                </span>
              }
              useWindow={false}
            >
              {publishes
                .sort((publish1, publish2) =>
                  sortType === 'sortByDate'
                    ? new Date(publish2.createDateTime).getTime() -
                      new Date(publish1.createDateTime).getTime()
                    : publish1.title && publish2.title
                      ? publish1.title.localeCompare(publish2.title)
                      : -1
                )
                .filter((item) =>
                  searchValue !== '' && item.title !== null
                    ? item.title
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      searchValue === ''
                    : item
                )
                .map((publish) => renderPublish(publish))}
            </InfiniteScroll>
          </div>
        ) : (
          <span style={{ marginTop: '15px' }}>
            {' '}
            <ListItem style={{ height: '70px' }}>
              <ListItemText
                primary={
                  tabs[selectedTab] === 'All'
                    ? 'No Publish found'
                    : 'No ' + tabs[selectedTab] + ' item found'
                }
              ></ListItemText>
            </ListItem>
          </span>
        )}
      </List>
    );
  };

  const publishSkeleton = (skeletonList) => {
    return (
      <List>
        {skeletonList.map((item, index) => {
          return (
            <Fragment key={index}>
              <span key={item} style={{ margin: '5px 0' }}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Skeleton
                        width={Math.random() * 20 + 5 + '%'}
                        style={{ marginLeft: '10px' }}
                      ></Skeleton>
                    }
                    secondary={
                      <Skeleton
                        width={Math.random() * 10 + '%'}
                        style={{ marginLeft: '10px' }}
                      ></Skeleton>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
              </span>
            </Fragment>
          );
        })}
      </List>
    );
  };

  const contents =
    loading || (loadingPublishes && pageIndex === 0) ? (
      <span style={{ width: '100%', height: '500px' }}>
        {' '}
        {publishSkeleton(['1', '2', '3', '4', '5', '6', '7', '8'])}
      </span>
    ) : (
      renderPublishes()
    );
  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Box style={{ maxWidth: '1280px', margin: 'auto' }} border={0}>
        {selectedDoc && (
          <Breadcrumbs>
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={{
                pathname: '/',
                state: {
                  accountId,
                },
              }}
            >
              {' '}
              {selectedDoc.account.title}
            </Link>
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedDoc.account.id}/${selectedDoc.id}/content?lang=${getLanguageCode()}&ver=${getVersionSlug()}`}
            >
              {selectedDoc.title}
            </Link>
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedDoc.account.id}/${selectedDoc.id}/publish-request`}
            >
              Publish Requests
            </Link>
          </Breadcrumbs>
        )}
        <CardContent style={{ paddingTop: '10px', boxShadow: '0' }}>
          <Fragment>
            <Row style={{ padding: '16px 16px 0' }}>
              <Col xs={16} style={{ display: 'flex' }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className={props.classes.tabs}
                >
                  {tabs.map((tab, index) => (
                    <Tab
                      label={tab}
                      value={index}
                      key={index}
                      style={{ textTransform: 'initial', textAlign: 'left' }}
                    />
                  ))}
                </Tabs>
              </Col>

              <Col
                xs={8}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ float: 'right', display: 'flex' }}>
                  <TextInput
                    style={{ marginRight: '10px' }}
                    field="Title"
                    placeholder={'Filter by title ...'}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    required={false}
                    showError={false}
                  />
                  <SelectInput
                    field="sortByTitle"
                    value={sortType}
                    onChange={(value) => setSortType(value)}
                  >
                    <Option key={'sortByTitle'} value={'sortByTitle'}>
                      {'Sort by title'}
                    </Option>
                    <Option key={'sortByDate'} value={'sortByDate'}>
                      {'Sort by date'}
                    </Option>
                  </SelectInput>
                </div>
              </Col>
            </Row>
            <Divider light />
          </Fragment>
          {contents}
        </CardContent>
      </Box>
    </div>
  );
}

export default withStyles(styles)(withSnackbar(PublishList));
