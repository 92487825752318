import React, { useState } from 'react';
import { Col, Space, Tooltip } from 'antd';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import yaml from 'js-yaml';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { MdOpenInNew } from 'react-icons/md';
import styled from 'styled-components';

const styles: any = () => ({
  preview: {
    padding: '10px',
    border: '2px solid #e7e6e6',
    margin: '5px',
    borderRadius: '4px',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  navbar: {
    margin: '5px',
  },
  mainContent: {
    '& > div': {
      marginBottom: 5,
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  marginBottom: {
    '& > div': {
      marginBottom: 5,
    },
  },
  pageSide: {
    backgroundColor: '#fff',
    margin: '5px',
    padding: '0 5px',
    '& > div': {
      marginBottom: 5,
    },
  },
  topicTree: {
    backgroundColor: '#fff',
    padding: 5,
  },
  topicContent: {
    margin: '5px 5px 5px',
    paddingRight: 10,
    backgroundColor: '#fff',
  },
});

const NavbarMenuBuilderPreview = ({ data, isResponsive, classes }) => {
  const [showErrorHintMessage, setShowErrorHintMessage] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const styleValidation = (style) => {
    let isValidStyle = true;
    if (typeof style === 'object') {
      try {
        Object.entries(style).forEach(([v]) => {
          if (!v) isValidStyle = false;
        });
      } catch (error) {
        isValidStyle = false;
      }
    } else {
      isValidStyle = false;
    }
    return isValidStyle;
  };

  const getLinkStyle = (style) => {
    const linkStyle: any = {
      color: 'rgba(0, 0, 0, 0.85)',
    };

    if (style) {
      if (style.color) {
        linkStyle.color = style.color;
      }
      if (style.fontFamily) {
        linkStyle.fontFamily = style.fontFamily;
      }
      if (style.fontSize) {
        linkStyle.fontSize = style.fontSize;
      }
      if (style.textDecoration) {
        linkStyle.textDecoration = style.textDecoration;
      }
      if (style.fontWeight) {
        linkStyle.fontWeight = style.fontWeight;
      }
    }

    return linkStyle;
  };

  const onMouseOver = (
    isValidHoverStyle: boolean,
    hoverStyle,
    elementId: string
  ) => {
    const element = document.getElementById(elementId);
    if (isValidHoverStyle && element) {
      Object.entries(hoverStyle).forEach(([k, v]) => {
        element.style[k] = v;
      });
    }
  };

  const onMouseLeave = (
    isValidHoverStyle: boolean,
    hoverStyle,
    isValidStyle: boolean,
    style,
    elementId: string
  ) => {
    const element = document.getElementById(elementId);
    if (isValidHoverStyle && element) {
      Object.entries(hoverStyle).forEach(([k]) => {
        delete element.style[k];
      });
    }
    if (isValidStyle && element) {
      Object.entries(style).forEach(([k, v]) => {
        element.style[k] = v;
      });
    }
  };

  const renderOverlay = (items, level) => {
    const menuItems = items?.map((item, _index) => {
      if (!item) {
        return null;
      } else if (item.items) {
        return renderMenus(item, level + 1);
      } else {
        const style = item.style;
        const linkStyle = getLinkStyle(style);
        const isValidStyle = styleValidation(style);
        const hoverStyle = item.hoverStyle;
        const linkHoverStyle = getLinkStyle(hoverStyle);
        const isValidHoverStyle = styleValidation(hoverStyle);
        const defaultStyle = {
          height: !isResponsive ? 38 : 'auto',
          border: 'none',
          paddingLeft: isResponsive && level > 1 ? level * 12 : 12,
          padding: isResponsive ? 'auto' : '5px 12px',
        };

        const buttonId = `button-${Math.round(Math.random() * 100)}`;
        const linkId = `link-${Math.round(Math.random() * 100)}`;
        return (
          <Menu.Item
            onMouseLeave={() =>
              onMouseLeave(
                isValidHoverStyle,
                hoverStyle,
                isValidStyle,
                style,
                buttonId
              )
            }
            onMouseOver={() =>
              onMouseOver(isValidHoverStyle, hoverStyle, buttonId)
            }
            id={buttonId}
            key={_index.toString() + level}
            style={isValidStyle ? { ...defaultStyle, ...style } : defaultStyle}
          >
            {item.url ? (
              <a
                onMouseLeave={() =>
                  onMouseLeave(
                    isValidHoverStyle,
                    linkHoverStyle,
                    isValidStyle,
                    linkStyle,
                    linkId
                  )
                }
                onMouseOver={() =>
                  onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                }
                id={linkId}
                href={item.url}
                target={item.target === 'newTab' ? '_blank' : '_self'}
                rel="noreferrer"
                style={
                  isValidStyle ? linkStyle : { color: 'rgba(0, 0, 0, 0.85)' }
                }
              >
                {item.target === 'newTab' ? (
                  <span className="menu-item-span">
                    {item.title}{' '}
                    <MdOpenInNew style={{ fontSize: 10, marginLeft: 5 }} />
                  </span>
                ) : (
                  item.title
                )}
              </a>
            ) : (
              <span
                onMouseLeave={() =>
                  onMouseLeave(
                    isValidHoverStyle,
                    linkHoverStyle,
                    isValidStyle,
                    linkStyle,
                    linkId
                  )
                }
                onMouseOver={() =>
                  onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                }
                id={linkId}
                style={
                  isValidStyle ? linkStyle : { color: 'rgba(0, 0, 0, 0.85)' }
                }
              >
                {item.title}
              </span>
            )}
          </Menu.Item>
        );
      }
    });

    if (level === 1 && !isResponsive) {
      return <Menu>{menuItems}</Menu>;
    } else {
      return menuItems;
    }
  };

  const renderMenus = (incomingData, level) => {
    try {
      const jsonData =
        typeof incomingData === 'string'
          ? yaml.load(
              incomingData
                .replace(/\\n/g, '\n')
                .replace(/"/g, '')
                .replace(/'/g, '')
            )
          : incomingData;
      if (jsonData.items && jsonData.items.length) {
        if (level === 1) {
          return jsonData.items.map((item, _index) => {
            if (!item) {
              return null;
            } else {
              const style = item.style;
              const linkStyle = getLinkStyle(style);
              const isValidStyle = styleValidation(style);
              const hoverStyle = item.hoverStyle;
              const linkHoverStyle = getLinkStyle(hoverStyle);
              const isValidHoverStyle = styleValidation(hoverStyle);

              const buttonId = `button-${Math.round(Math.random() * 100)}`;
              const linkId = `link-${Math.round(Math.random() * 100)}`;
              if (item.items) {
                const defaultStyle = {
                  height: !isResponsive ? 40 : 'auto',
                  marginTop: 5,
                  marginLeft: 0,
                  borderColor: 'rgba(0, 0, 0, 0.85)',
                  padding: '5px',
                };
                const title = (
                  <Space
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {item.url ? (
                      <a
                        onMouseLeave={() =>
                          onMouseLeave(
                            isValidHoverStyle,
                            linkHoverStyle,
                            isValidStyle,
                            linkStyle,
                            linkId
                          )
                        }
                        onMouseOver={() =>
                          onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                        }
                        id={linkId}
                        href={item.url}
                        target={item.target === 'newTab' ? '_blank' : '_self'}
                        rel="noreferrer"
                        style={
                          isValidStyle
                            ? linkStyle
                            : { color: 'rgba(0, 0, 0, 0.85)' }
                        }
                      >
                        {item.target === 'newTab' ? (
                          <span className="menu-item-span">
                            {item.title}{' '}
                            <MdOpenInNew
                              style={{ fontSize: 10, marginLeft: 5 }}
                            />{' '}
                            {!isResponsive && (
                              <DownOutlined
                                style={{ fontSize: 12, marginLeft: 7 }}
                              />
                            )}
                          </span>
                        ) : (
                          <span className="menu-item-span">
                            {item.title}{' '}
                            {!isResponsive && (
                              <DownOutlined
                                style={{ fontSize: 12, marginLeft: 7 }}
                              />
                            )}
                          </span>
                        )}
                      </a>
                    ) : (
                      <span
                        onMouseLeave={() =>
                          onMouseLeave(
                            isValidHoverStyle,
                            linkHoverStyle,
                            isValidStyle,
                            linkStyle,
                            linkId
                          )
                        }
                        onMouseOver={() =>
                          onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                        }
                        id={linkId}
                        style={
                          isValidStyle
                            ? linkStyle
                            : { color: 'rgba(0, 0, 0, 0.85)' }
                        }
                        className="menu-item-span"
                      >
                        {item.title}{' '}
                        {!isResponsive && (
                          <DownOutlined
                            style={{ fontSize: 12, marginLeft: 7 }}
                          />
                        )}
                      </span>
                    )}
                  </Space>
                );
                if (isResponsive) {
                  const SubMenu = styled(Menu.SubMenu)`
                    & .ant-menu-submenu-arrow {
                      color: ${isValidStyle && style.color
                        ? style.color
                        : 'rgba(0, 0, 0, 0.85)'};
                      font-size: ${isValidStyle && style.fontSize
                        ? style.fontSize
                        : 'rgba(0, 0, 0, 0.85)'};
                      font-family: ${isValidStyle && style.fontFamily
                        ? style.fontFamily
                        : 'rgba(0, 0, 0, 0.85)'};
                      text-decoration: ${isValidStyle && style.textDecoration
                        ? style.textDecoration
                        : 'rgba(0, 0, 0, 0.85)'};
                      font-weight: ${isValidStyle && style.fontWeight
                        ? style.fontWeight
                        : 'rgba(0, 0, 0, 0.85)'};
                    }
                  `;
                  if (showErrorHintMessage) {
                    setShowErrorHintMessage(false);
                  }
                  return (
                    <SubMenu
                      onMouseLeave={() =>
                        onMouseLeave(
                          isValidHoverStyle,
                          hoverStyle,
                          isValidStyle,
                          style,
                          buttonId
                        )
                      }
                      onMouseOver={() =>
                        onMouseOver(isValidHoverStyle, hoverStyle, buttonId)
                      }
                      id={buttonId}
                      style={
                        isValidStyle
                          ? { ...defaultStyle, ...style }
                          : defaultStyle
                      }
                      className={`first-level-button ${!isValidStyle || (isValidStyle && !style.color) ? 'white-color' : ''}`}
                      title={title}
                      key={_index.toString() + level}
                    >
                      {renderOverlay(item.items, level)}
                    </SubMenu>
                  );
                } else {
                  if (showErrorHintMessage) {
                    setShowErrorHintMessage(false);
                  }
                  return (
                    <Dropdown
                      key={_index.toString() + level}
                      className="navbar-builder"
                      menu={renderOverlay(item.items, level)}
                    >
                      <Button
                        onMouseLeave={() =>
                          onMouseLeave(
                            isValidHoverStyle,
                            hoverStyle,
                            isValidStyle,
                            style,
                            buttonId
                          )
                        }
                        onMouseOver={() =>
                          onMouseOver(isValidHoverStyle, hoverStyle, buttonId)
                        }
                        id={buttonId}
                        style={
                          isValidStyle
                            ? { ...defaultStyle, ...style }
                            : defaultStyle
                        }
                        className={`first-level-button ${!isValidStyle || (isValidStyle && !style.color) ? 'white-color' : ''}`}
                      >
                        {title}
                      </Button>
                    </Dropdown>
                  );
                }
              } else {
                const defaultStyle = {
                  height: !isResponsive ? 40 : 'auto',
                  marginTop: 5,
                  marginLeft: 0,
                  border: 'none',
                  borderColor: 'rgba(0, 0, 0, 0.85)',
                };
                const title = (
                  <Space
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {item.url ? (
                      <a
                        onMouseLeave={() =>
                          onMouseLeave(
                            isValidHoverStyle,
                            linkHoverStyle,
                            isValidStyle,
                            linkStyle,
                            linkId
                          )
                        }
                        onMouseOver={() =>
                          onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                        }
                        id={linkId}
                        href={item.url}
                        target={item.target === 'newTab' ? '_blank' : '_self'}
                        rel="noreferrer"
                        style={
                          isValidStyle
                            ? linkStyle
                            : { color: 'rgba(0, 0, 0, 0.85)' }
                        }
                      >
                        {item.target === 'newTab' ? (
                          <span className="menu-item-span">
                            {item.title}{' '}
                            <MdOpenInNew
                              style={{ fontSize: 10, marginLeft: 5 }}
                            />
                          </span>
                        ) : (
                          <span className="menu-item-span">{item.title}</span>
                        )}
                      </a>
                    ) : (
                      <span
                        onMouseLeave={() =>
                          onMouseLeave(
                            isValidHoverStyle,
                            linkHoverStyle,
                            isValidStyle,
                            linkStyle,
                            linkId
                          )
                        }
                        onMouseOver={() =>
                          onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                        }
                        id={linkId}
                        style={
                          isValidStyle
                            ? linkStyle
                            : { color: 'rgba(0, 0, 0, 0.85)' }
                        }
                        className="menu-item-span"
                      >
                        {item.title}
                      </span>
                    )}
                  </Space>
                );
                if (isResponsive) {
                  if (showErrorHintMessage) {
                    setShowErrorHintMessage(false);
                  }
                  return (
                    <Menu.Item
                      onMouseLeave={() =>
                        onMouseLeave(
                          isValidHoverStyle,
                          hoverStyle,
                          isValidStyle,
                          style,
                          buttonId
                        )
                      }
                      onMouseOver={() =>
                        onMouseOver(isValidHoverStyle, hoverStyle, buttonId)
                      }
                      id={buttonId}
                      key={_index.toString() + level}
                      style={
                        isValidStyle
                          ? { ...defaultStyle, ...style }
                          : defaultStyle
                      }
                      className={`first-level-button ${!isValidStyle || (isValidStyle && !style.color) ? 'white-color' : ''}`}
                    >
                      {title}
                    </Menu.Item>
                  );
                } else {
                  if (showErrorHintMessage) {
                    setShowErrorHintMessage(false);
                  }
                  return (
                    <Button
                      onMouseLeave={() =>
                        onMouseLeave(
                          isValidHoverStyle,
                          hoverStyle,
                          isValidStyle,
                          style,
                          buttonId
                        )
                      }
                      onMouseOver={() =>
                        onMouseOver(isValidHoverStyle, hoverStyle, buttonId)
                      }
                      id={buttonId}
                      key={_index.toString() + level}
                      style={
                        isValidStyle
                          ? { ...defaultStyle, ...style }
                          : defaultStyle
                      }
                      className={`first-level-button ${!isValidStyle || (isValidStyle && !style.color) ? 'white-color' : ''}`}
                    >
                      {title}
                    </Button>
                  );
                }
              }
            }
          });
        } else {
          const style = jsonData.style;
          const linkStyle = getLinkStyle(style);
          const isValidStyle = styleValidation(style);
          const hoverStyle = jsonData.hoverStyle;
          const linkHoverStyle = getLinkStyle(hoverStyle);
          const isValidHoverStyle = styleValidation(hoverStyle);

          // const buttonId = `button-${Math.round(Math.random() * 100)}`;
          const linkId = `link-${Math.round(Math.random() * 100)}`;
          // const SubMenu = styled(Menu.SubMenu)`
          //     & .ant-menu-submenu-title {
          //         padding-left: ${isResponsive && level > 2 ? level * 12 : 12}px!important;
          //     }
          //     & .ant-menu-submenu-arrow {
          //         color: ${isValidStyle && style.color ? style.color : 'rgba(0, 0, 0, 0.85)'};
          //         font-size: ${isValidStyle && style.fontSize ? style.fontSize : 'rgba(0, 0, 0, 0.85)'};
          //         font-family: ${isValidStyle && style.fontFamily ? style.fontFamily : 'rgba(0, 0, 0, 0.85)'};
          //         text-decoration: ${isValidStyle && style.textDecoration ? style.textDecoration : 'rgba(0, 0, 0, 0.85)'};
          //         font-weight: ${isValidStyle && style.fontWeight ? style.fontWeight : 'rgba(0, 0, 0, 0.85)'};
          //     }
          // `;
          if (showErrorHintMessage) {
            setShowErrorHintMessage(false);
          }
          return (
            <Menu.SubMenu
              title={
                <Space
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {jsonData.url ? (
                    <a
                      onMouseLeave={() =>
                        onMouseLeave(
                          isValidHoverStyle,
                          linkHoverStyle,
                          isValidStyle,
                          linkStyle,
                          linkId
                        )
                      }
                      onMouseOver={() =>
                        onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                      }
                      id={linkId}
                      href={jsonData.url}
                      target={jsonData.target === 'newTab' ? '_blank' : '_self'}
                      rel="noreferrer"
                      style={
                        isValidStyle
                          ? linkStyle
                          : { color: 'rgba(0, 0, 0, 0.85)' }
                      }
                    >
                      {jsonData.target === 'newTab' ? (
                        <span className="menu-item-span">
                          {jsonData.title}{' '}
                          <MdOpenInNew
                            style={{ fontSize: 10, marginLeft: 5 }}
                          />{' '}
                          {!isResponsive && (
                            <RightOutlined
                              style={{ fontSize: 12, marginLeft: 7 }}
                            />
                          )}
                        </span>
                      ) : (
                        <span className="menu-item-span">
                          {jsonData.title}{' '}
                          {!isResponsive && (
                            <RightOutlined
                              style={{ fontSize: 12, marginLeft: 7 }}
                            />
                          )}
                        </span>
                      )}
                    </a>
                  ) : (
                    <span
                      onMouseLeave={() =>
                        onMouseLeave(
                          isValidHoverStyle,
                          linkHoverStyle,
                          isValidStyle,
                          linkStyle,
                          linkId
                        )
                      }
                      onMouseOver={() =>
                        onMouseOver(isValidHoverStyle, linkHoverStyle, linkId)
                      }
                      id={linkId}
                      style={
                        isValidStyle
                          ? linkStyle
                          : { color: 'rgba(0, 0, 0, 0.85)' }
                      }
                      className="menu-item-span"
                    >
                      {jsonData.title}{' '}
                      {!isResponsive && (
                        <RightOutlined
                          style={{ fontSize: 12, marginLeft: 7 }}
                        />
                      )}
                    </span>
                  )}
                </Space>
              }
              style={
                isValidStyle
                  ? { border: 'none', padding: '5px', ...style }
                  : { border: 'none', padding: '5px' }
              }
            >
              {renderOverlay(jsonData.items, level)}
            </Menu.SubMenu>
          );
        }
      } else {
        if (showErrorHintMessage) {
          setShowErrorHintMessage(false);
        }
        return null;
      }
    } catch (error) {
      if (!showErrorHintMessage) {
        setShowErrorHintMessage(true);
      }
      return null;
    }
  };

  return (
    <Col
      xs={24}
      className="navbar-preview navbar-menu-builder"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1',
      }}
    >
      <Col xs={24} className={classes.preview}>
        <Col xs={24}>
          <Box
            display="flex"
            alignItems="center"
            margin={1}
            className={classes.navbar}
          >
            <Box margin={1}>
              <Skeleton
                animation="wave"
                variant="circular"
                height={50}
                width={50}
              />
            </Box>
            <Box width="12%" style={{ marginRight: 10 }}>
              <Skeleton animation="wave" height={50} />
            </Box>
            {data ? (
              isResponsive ? (
                <Menu
                  mode="inline"
                  triggerSubMenuAction={isResponsive ? 'click' : 'hover'}
                  style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {renderMenus(data, 1)}
                </Menu>
              ) : (
                renderMenus(data, 1)
              )
            ) : null}
            {showErrorHintMessage && (
              <Tooltip title={t('error.navbar-menu-builder')}>
                <ErrorIcon color="error" style={{ marginLeft: 10 }} />
              </Tooltip>
            )}
            <Box flexGrow={1}></Box>
            <Box width="8%" marginRight={1}>
              <Skeleton animation="wave" height={50} />
            </Box>
            <Box width="8%" marginRight={1}>
              <Skeleton animation="wave" height={50} />
            </Box>
          </Box>
        </Col>
      </Col>
    </Col>
  );
};

export default withStyles(styles)(NavbarMenuBuilderPreview);
