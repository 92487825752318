import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  User,
} from 'firebase/auth';

export const reAuthenticate = async (
  user: User,
  provider,
  password: string
) => {
  switch (provider) {
    case EmailAuthProvider.PROVIDER_ID:
      // eslint-disable-next-line no-case-declarations
      const credential = EmailAuthProvider.credential(
        user.email || '',
        password
      );
      return reauthenticateWithCredential(user, credential)
        .then(function () {
          return true;
        })
        .catch(function () {
          return false;
        });
    case GoogleAuthProvider.PROVIDER_ID:
      // eslint-disable-next-line no-case-declarations
      const googleProvider = new GoogleAuthProvider();
      googleProvider.addScope('profile');
      googleProvider.addScope('email');
      return reauthenticateWithPopup(user, googleProvider)
        .then(function () {
          return true;
        })
        .catch(function () {
          return false;
        });
    case GithubAuthProvider.PROVIDER_ID:
      // eslint-disable-next-line no-case-declarations
      const githubProvider = new GithubAuthProvider();
      githubProvider.addScope('repo');
      return reauthenticateWithPopup(user, githubProvider)
        .then(function () {
          return true;
        })
        .catch(function () {
          return false;
        });
    default:
      return false;
  }
};
