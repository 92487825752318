import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateFolderRequest } from '../../api-schemas/folder';

const updateFolderRequest = (data: CreateFolderRequest) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Folder', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateFolder(
  accountId: string,
  parentFolderId?: string
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateFolder,
    isPending: isUpdatingFolder,
    isSuccess: isSuccessUpdatingFolder,
  } = useMutation({
    mutationFn: updateFolderRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: parentFolderId
          ? ['folders', accountId, parentFolderId]
          : ['folders', accountId],
      });
    },
  });

  return {
    updateFolder,
    isUpdatingFolder,
    isSuccessUpdatingFolder,
  };
}
