import React from 'react';
import ManageDocumentAccesses from '../../document/accesses/manage-accesses';
import AccountAccessesSettings from '../../account/accesses';

const ManageUsers = ({ accesses, ...props }) => {
  if (props.entityType === 'Document') {
    return (
      <ManageDocumentAccesses accesses={accesses} {...props} type="USER" />
    );
  } else {
    return (
      <AccountAccessesSettings accesses={accesses} {...props} type="USER" />
    );
  }
};

export default ManageUsers;
