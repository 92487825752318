import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Col } from 'antd';

import { AccountContext } from '../../../../contexts/account';
import { DocumentContext } from '../../../../contexts/document';
import { sleep } from '../../../../helpers/common';
import useUpdateAccountGeneralSettings from '../../../../api-hooks/account/useUpdateAccountGeneralSettings';

const useStyles: any = makeStyles(() => ({
  processListItem: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: '15px!important',
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateInfo: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13,
  },
}));

const ProcessListItem = ({ process, account, setParentLoading }) => {
  const { accountsData, setSelectedAccount } = useContext(AccountContext);
  const { documents, setDocuments } = useContext(DocumentContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { updateAccountGeneralSettings } = useUpdateAccountGeneralSettings(
    account.id
  );

  const updateAccount = (data: any) => {
    setParentLoading(true);

    updateAccountGeneralSettings(data)
      .then(async () => {
        await sleep(1000);

        const filteredAccounts = accountsData.filter(
          (accountData) => accountData.id === account.id
        );

        if (filteredAccounts.length) {
          filteredAccounts[0].processId = data.processId;
          setSelectedAccount(filteredAccounts[0]);
          const newDocuments = [...documents];
          newDocuments
            .filter(
              (document) => document.account.id === filteredAccounts[0].id
            )
            .map((document) => (document.account.processId = data.processId));
          setDocuments(newDocuments);
          setParentLoading(false);
        }
      })
      .catch(() => {
        setParentLoading(false);
        enqueueSnackbar(t('error-assign-process'), {
          variant: 'error',
        });
      });
  };

  const assignProcessToAccount = () => {
    updateAccount({ ...account, processId: process.id });
  };

  const unassignProcessToAccount = () => {
    updateAccount({ ...account, processId: null });
  };

  const calculateNumberOfSteps = () => {
    const seenSteps: number[] = [];
    process.activities?.map((activity) => {
      if (seenSteps.indexOf(activity.step) < 0) {
        seenSteps.push(activity.step);
      }
      return true;
    });
    return seenSteps.length;
  };

  return (
    <Col xs={24} className={classes.processListItem}>
      <Col xs={10} style={{ textAlign: 'left' }}>
        <div
          style={{ fontWeight: 'bold', paddingBottom: 12, cursor: 'pointer' }}
          onClick={() =>
            history.push(`/org/settings/processes/${process.id}/manage`)
          }
        >
          {process.title}
        </div>
        <div className={classes.dateInfo}>
          {t('created') + ' '}
          {moment(new Date(process.createDateTime), 'YYYYMMDDLL').fromNow()}
          {' ' + t('by') + ' '}
          {
            accountsData.filter(
              (accountData) => accountData.id === process.createdBy
            )[0]?.email
          }
        </div>
      </Col>
      <Col xs={10} style={{ textAlign: 'right' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
            paddingBottom: 12,
          }}
        >
          <Tooltip arrow title={t<string>('number-of-steps')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PlaylistAddCheckIcon
                style={{
                  marginRight: 5,
                  color: 'rgba(0, 0, 0, 0.83)',
                  fontSize: 22,
                }}
              />
              {calculateNumberOfSteps()}
            </div>
          </Tooltip>
          {account.processId === process.id ? (
            <Tooltip title={t<string>('active-process')}>
              <IconButton
                aria-label={t('active-process')}
                style={{ padding: 0, marginLeft: 10 }}
                onClick={unassignProcessToAccount}
              >
                <DoneOutlineIcon style={{ color: 'green', fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={t<string>('set-as-active-process')}
              style={{ padding: 0, marginLeft: 10 }}
            >
              <IconButton
                aria-label={t('set-as-active-process')}
                onClick={assignProcessToAccount}
              >
                <DoneOutlineIcon style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.dateInfo}>
          {t('updated') + ' '}
          {moment(new Date(process.updateDateTime), 'YYYYMMDDLL').fromNow()}
          {' ' + t('by') + ' '}
          {
            accountsData.filter(
              (accountData) => accountData.id === process.updatedBy
            )[0]?.email
          }
        </div>
      </Col>
    </Col>
  );
};

export default ProcessListItem;
