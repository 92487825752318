import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import { Input, Modal, Skeleton, Tooltip } from 'antd';
import { ConfirmationDialog } from '../../confirmation-dialog';
import yaml from 'js-yaml';
import { Box } from '@mui/material';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

import SortableItem from './item';
import { ComponentContext } from '../../../contexts/component';
import { updateComponentIndex } from '../../../helpers/content';
import useGetComponents from '../../../api-hooks/component/useGetComponents';
import { TopicContext } from '../../../contexts/topic';
import ComponentDataEditor from './component-data-editor';

import './componentGrid.css';

const ComponentEditor = ({ loading, handleTopicDataChange }) => {
  const [deleteItemIndex, setDeleteItemIndex] = useState<number | null>(null);
  const [editItem, setEditItem] = useState<any>(null);
  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState<string | null>(null);

  const firstTitle = useRef<string | undefined>();

  const { items } = useContext(ComponentContext);
  const { selectedTopic } = useContext(TopicContext);

  const { t } = useTranslation();

  const { components } = useGetComponents();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, []);

  useEffect(() => {
    if (selectedTopic && firstTitle.current === undefined) {
      firstTitle.current = selectedTopic.title;
    }
  }, [selectedTopic]);

  const handleDeleteComponent = () => {
    const toDeleteItemIndex = deleteItemIndex;
    const newItems = JSON.parse(JSON.stringify(items));
    const dataIndex = newItems.indexOf(newItems.find((dataItem) => dataItem.order === toDeleteItemIndex));

    newItems.splice(dataIndex, 1);

    if (toDeleteItemIndex !== null) {
      window.componentsData[toDeleteItemIndex] = null;
      window.updateComponentDataFunctions[toDeleteItemIndex] = null;
    }

    newItems
      .sort((a, b) => parseInt(a.order) < parseInt(b.order))
      .forEach((dataItem) => {
        if (toDeleteItemIndex && parseInt(dataItem.order) > toDeleteItemIndex) {
          dataItem = updateComponentIndex(dataItem, false);
        }
      });

    handleTopicDataChange(newItems, 'componentBody');

    setDeleteItemIndex(null);
  };

  const handleSaveChanges = () => {
    const newItems = [...items];
    const newItem = newItems.find((item) => item.order === editItem.data.order);

    if (newItem) {
      newItem.componentData.content.content = editItem.data.componentData.content.content;

      if (newItem.componentData.layout) {
        newItem.componentData.layout.baseColumnSize = editItem.data.componentData.layout?.baseColumnSize || 12;
      } else {
        newItem.componentData.layout = {
          baseColumnSize: editItem.data.componentData.layout?.baseColumnSize || 12,
        };
      }

      window.componentsData[editItem.data.order] = yaml.load(newItem.componentData.content.content);

      handleTopicDataChange(newItems, 'componentBody');

      setEditItem(null);
    }
  };

  const handleCancelChanges = () => {
    setEditItem(null);
  };

  const handleTopicContentTitleChange = (event) => {
    const newTitle: string = event.target.value;

    handleTopicDataChange(newTitle, 'title');
  };

  const handleCancelTitleChanges = () => {
    handleTopicDataChange(firstTitle.current, 'title');

    setSelectedFieldToEdit(null);
  };

  const handleSaveTitle = () => {
    setSelectedFieldToEdit(null);
  };

  const getModalTitle = () => {
    const component = components?.find((component) => component.id === editItem?.componentVersion.componentId);

    return (component ? component.title + ' ' : '') + t('settings');
  };

  return (
    <div id="component-editor">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '50%',
          marginTop: 5,
        }}
      >
        {selectedFieldToEdit === 'title' ? (
          <Fragment>
            <Input value={selectedTopic?.title} onChange={handleTopicContentTitleChange} />
            <Tooltip title={t('save')}>
              <CheckOutlined style={{ marginLeft: 10 }} onClick={handleSaveTitle} />
            </Tooltip>

            <Tooltip title={t('cancel')}>
              <CloseOutlined style={{ marginLeft: 10 }} onClick={handleCancelTitleChanges} />
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment>
            <Title style={{ marginBottom: 0 }} level={5}>
              {selectedTopic?.title}
            </Title>

            <Tooltip title={t('edit')}>
              <EditOutlined style={{ marginLeft: 10 }} onClick={() => setSelectedFieldToEdit('title')} />
            </Tooltip>
          </Fragment>
        )}
      </div>

      <Box style={{ position: 'relative' }}>
        {loading &&
          new Array(3).fill(0).map((_, index) => {
            return (
              <div key={index} className="full-height-flex-box" style={{ margin: '15px 0' }}>
                <Skeleton.Input active={false} size={'large'} block={true} style={{ height: 250 }} />
              </div>
            );
          })}

        <Droppable isDropDisabled={loading} droppableId="component-editor">
          {(provided) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  background: 'inherit',
                  padding: 4,
                  width: '100%',
                  minHeight: 500,
                  display: loading ? 'none' : 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {items
                  ?.sort((a, b) => a.order - b.order)
                  .map((item) => {
                    const index = Number(item.order);

                    return (
                      <SortableItem
                        key={index}
                        id={index}
                        handle={true}
                        data={item}
                        setEditItem={setEditItem}
                        setDeleteItemIndex={setDeleteItemIndex}
                      />
                    );
                  })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </Box>

      <ConfirmationDialog
        isOpen={deleteItemIndex !== null}
        onDialogClosed={() => setDeleteItemIndex(null)}
        title={t('delete-component-title')}
        message={t('delete-component-message')}
        onDialogConfirmed={handleDeleteComponent}
      />

      <Modal
        width={720}
        destroyOnClose={true}
        title={getModalTitle()}
        open={editItem !== null}
        onOk={handleSaveChanges}
        onCancel={handleCancelChanges}
      >
        <ComponentDataEditor component={editItem} setComponent={setEditItem} />
      </Modal>
    </div>
  );
};

export default ComponentEditor;
