import React, { useContext, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';

import {
  calculateBreadCrumb,
  htmlDecode,
  getTopicLink,
} from '../../../helpers/common';
import { DocumentContext } from '../../../contexts/document';
import { TopicContext } from '../../../contexts/topic';
import useGetItemType from '../../../api-hooks/folder/useGetItemType';
import { AccountContext } from '../../../contexts/account';

const useStyles: any = makeStyles(() => ({
  root: {
    '& *': {
      color: '#5f6368',
    },
  },
  item: {
    color: '#5f6368',
    fontSize: 14,
  },
}));

const GoogleSearchResultBreadcrumb = ({ topicList = [] }) => {
  const { selectedAccount } = useContext(AccountContext);
  const { selectedDoc, selectedLanguage } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const { getItemType } = useGetItemType(selectedTopic?.id);

  const classes = useStyles();

  const [topicUrl, setTopicUrl] = useState<string>('');

  const isDoclessTopic = useRef<boolean>(
    Boolean(selectedTopic && !selectedTopic.documentId)
  );

  getItemType(selectedTopic?.topicId).then((itemType) => {
    const publishLink = getTopicLink(
      selectedDoc,
      selectedTopic,
      /*selectedDoc.languages.length > 1 ? */ selectedLanguage /* : null*/,
      true,
      isDoclessTopic.current,
      selectedAccount,
      itemType?.path
    );
    setTopicUrl(publishLink);
  });

  const shortenTitle = (title: string) => {
    if (title.length < 25) return htmlDecode(title);
    else return htmlDecode(title.substring(0, 22) + '...');
  };

  return (
    <Breadcrumbs className={classes.root} separator="›" aria-label="breadcrumb">
      <div style={{ color: '#202124' }} className={classes.item}>
        {selectedDoc && selectedTopic && shortenTitle(topicUrl)}
      </div>
      {selectedTopic &&
        calculateBreadCrumb(selectedTopic.topicId, [], topicList)
          .slice(0)
          .reverse()
          .map((item, index) => {
            return (
              <div className={classes.item} key={index}>
                {shortenTitle(item.title || item.slug)}
              </div>
            );
          })}
    </Breadcrumbs>
  );
};

export default GoogleSearchResultBreadcrumb;
