import { AxiosResponse } from 'axios';
import { baseWebApi } from '../base-web-api';

export class DocumentService extends baseWebApi {
  getImages = (accountId: string, documentId: string) => {
    return this.sendRequest(
      'get',
      `document/image/${accountId}/${documentId}`
    ).then((response: Response) => response.json());
  };

  getDocumentTemplates = () => {
    return this.sendRequest('get', 'document/templates').then(
      (response: Response) => response.json()
    );
  };

  getDocument = (documentId: string) => {
    return this.sendRequest('get', `document/${documentId}`).then(
      (response: Response) => response.json()
    );
  };

  getVersions(docId: string) {
    return this.sendRequest('get', `document/${docId}/version`).then(
      (response: Response) => response.json()
    );
  }

  copyDocument(documentId, copiedTitle, copiedSlug) {
    return this.sendRequest(
      'POST',
      `Document/${documentId}/copy?title=${copiedTitle}&slug=${copiedSlug}`
    ).then((response: Response) => response.json());
  }

  postDocument(document) {
    const data = JSON.stringify(document);

    return this.sendRequest('POST', 'document', data).then(
      (response: Response) => response.json()
    );
  }

  putLanguage(document) {
    const data = JSON.stringify(document);
    return this.sendRequest('PUT', 'document/language/', data).then(
      (response: Response) => response.json()
    );
  }
  putDocument(document) {
    const data = JSON.stringify(document);

    return this.sendRequest('PUT', `document`, data).then(
      (response: Response) => response.json()
    );
  }

  putVersion(ver) {
    const data = JSON.stringify(ver);

    return this.sendRequest('PUT', 'document/version/', data).then(
      (response: Response) => response.json()
    );
  }

  postVersion(ver) {
    const data = JSON.stringify(ver);

    return this.sendRequest('POST', 'document/version/', data).then(
      (response: Response) => response.json()
    );
  }

  postImages = (accountId: string, documentId: string, data: FormData) => {
    return this.sendRequest(
      'POST',
      `resources/image/upload?accountId=${accountId}&documentId=${documentId}&userId=${localStorage.getItem('userId')}`,
      data,
      'undefined'
    ).then((response: Response) => response.json());
  };

  updateImages = (accountId: string, documentId: string, data: FormData) => {
    return this.sendRequest(
      'PUT',
      `document/image/${accountId}/${documentId}`,
      data,
      'undefined'
    ).then((response: Response) => response.json());
  };

  deleteImages = (documentId: string, data) => {
    const body = JSON.stringify(data);
    return this.sendRequest(
      'POST',
      `document/image/${documentId}/bulk-delete`,
      body
    ).then((response: Response) => response);
  };

  removeDocument = (documentId: string) => {
    return this.sendRequest('delete', `document/${documentId}`);
  };

  removeLogo(documentId: string) {
    return this.sendRequest('delete', `Document/${documentId}/Logo`);
  }

  deleteVersion(ver) {
    return this.sendRequest(
      'delete',
      `document/${ver.documentId}/version/${ver.title}`
    );
  }

  uploadLogo(data) {
    return this.sendRequest('post', `document/logo`, data, 'undefined');
  }

  getLogo(
    documentId: string,
    updateDateTime: string,
    useCache: boolean = true
  ) {
    if (useCache) {
      return this.getWithCache(
        `Document/${documentId}/logo?updateDateTime=${updateDateTime}`
      ).then((response: AxiosResponse) => {
        return response.status;
      });
    } else {
      return this.sendRequest(
        'get',
        `Document/${documentId}/logo?updateDateTime=${updateDateTime}`
      ).then((response: AxiosResponse) => {
        return response.status;
      });
    }
  }

  changeDefaultVersion(data) {
    const body = JSON.stringify(data);
    return this.sendRequest('put', 'document/DefaultDocumentVersionSlug', body);
  }

  changeDefaultLanguageCode(data) {
    const body = JSON.stringify(data);
    return this.sendRequest(
      'put',
      'document/DefaultDocumentLanguageCode',
      body
    );
  }
}
