import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const updateAccountImagesRequest = ({
  accountId,
  data,
}: {
  accountId: string;
  data: FormData;
}) => {
  return axios
    .put(`/api/Account/Image/${accountId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountImages(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountImages,
    isPending: isUpdatingAccountImages,
    isSuccess: isSuccessUpdatingAccountImages,
  } = useMutation({
    mutationFn: updateAccountImagesRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'images', accountId],
      });
    },
  });

  return {
    updateAccountImages,
    isUpdatingAccountImages,
    isSuccessUpdatingAccountImages,
  };
}
