import React, { type FC, useContext } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import useGetFolders from '../../../api-hooks/folder/useGetFolders';
import FolderListItem from './list-item';
import { Folder } from '../../../api-schemas/folder';
import { AccountContext } from '../../../contexts/account';
import { FolderContext } from '../../../contexts/folder';

const FolderList: FC = () => {
  const { t } = useTranslation();

  const { selectedLanguage, selectedAccount } = useContext(AccountContext);
  const { selectedFolderId, setSelectedFolderId, setSelectedFolders } =
    useContext(FolderContext);

  const { folders, isLoadingFolders, isErrorLoadingFolders } = useGetFolders(
    selectedAccount?.id || '',
    selectedFolderId,
    Boolean(selectedAccount?.id)
  );

  const sortedFolders = folders.sort(
    (a, b) =>
      new Date(b.createDateTime).getTime() -
      new Date(a.createDateTime).getTime()
  );

  const handleSelectFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);

    const folderTitle = folder.titles.find(
      (title) => title.languageCode === selectedLanguage
    );

    setSelectedFolders((prevSelectedFolders) => [
      ...prevSelectedFolders,
      {
        ...folder,
        title: folderTitle?.title,
      },
    ]);
  };

  return (
    <div style={{ width: '80%', marginBottom: 30 }}>
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #bebebe8a',
          marginTop: 10,
          marginBottom: 15,
          paddingBottom: 5,
          fontSize: 16,
        }}
      >
        {t('folders')}
      </div>

      {isLoadingFolders ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : isErrorLoadingFolders || sortedFolders.length === 0 ? (
        <span
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: 14,
          }}
        >
          {t('no-folder-message')}
        </span>
      ) : (
        <div style={{ display: 'flex' }}>
          {sortedFolders.map((folder) => (
            <FolderListItem
              key={folder.id}
              folder={folder}
              onSelectFolder={handleSelectFolder}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FolderList;
