import { Tooltip } from 'antd';
import React from 'react';
import '../styles.css';

const ProgressBarStep = ({
  minValue,
  maxValue,
  metricMaxValue,
  color,
  label,
  ...props
}) => {
  const style = {
    left: (props.width / 100) * minValue,
    width:
      (props.width / 100) *
      (maxValue === 'Infinity' ? 100 : maxValue - minValue),
    backgroundColor: color,
  };

  const convertValue = (value: number) => {
    return Math.round(
      value === 100 ? metricMaxValue : (value * metricMaxValue) / 100
    );
  };

  return (
    <Tooltip
      title={`${label} (${convertValue(minValue)} - ${maxValue === 'Infinity' ? '∞' : convertValue(maxValue)})`}
    >
      <div className="progress-bar-step" style={style}></div>
    </Tooltip>
  );
};

export default ProgressBarStep;
