import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import { Col } from 'antd';

const useStyles: any = makeStyles((theme) => ({
  feedbackBox: {
    width: '100%',
  },
  firstRow: {
    marginBottom: 10,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #d3d1d1',
  },
  nameBox: {
    fontSize: '16px',
    fontWeight: 700,
  },
  smallBox: {
    fontSize: '12px',
    color: '#979797',
  },
  infoBox: {
    backgroundColor: '#bdbdbd52',
    padding: '10px 15px',
    marginTop: 10,
    display: 'flex',
  },
  info: {
    fontSize: 12,
    textAlign: 'left',
  },
  titleBox: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
  respInfo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const FeedbackSkeletonLoading = () => {
  const classes = useStyles();
  const loopArray = [0, 1, 2, 3, 4, 5];

  return (
    <Fragment>
      {loopArray.map((index) => (
        <div key={index}>
          <Paper elevation={2} className={classes.feedbackBox}>
            <Col xs={24} style={{ marginTop: '10px' }}>
              <Col xs={24} style={{ display: 'flex' }}>
                <Col xs={24} style={{ padding: '0 15px' }}>
                  <Col xs={24} className={classes.firstRow}>
                    <Col
                      xs={20}
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      <Skeleton width={300} height={40} />
                    </Col>
                    <Col
                      xs={4}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Skeleton width={100} height={40} />
                    </Col>
                  </Col>
                  <Col xs={24} className={classes.titleBox}>
                    <Skeleton width={200} height={40} />
                  </Col>
                  <Col xs={24}>
                    <Skeleton style={{ width: '100%' }} height={20} />
                    <Skeleton style={{ width: '100%' }} height={20} />
                    <Skeleton style={{ width: '50%' }} height={20} />
                  </Col>
                </Col>
              </Col>
              <Col xs={24} className={classes.infoBox}>
                <Col xs={24} style={{ display: 'flex' }}>
                  <Col xs={8} sm={6} className={classes.info}>
                    <Skeleton width={100} height={25} />
                  </Col>
                  <Col xs={8} sm={6} className={classes.info}>
                    <Skeleton width={100} height={25} />
                  </Col>
                  <Col
                    xs={8}
                    sm={6}
                    className={classes.info + ' ' + classes.respInfo}
                  >
                    <Skeleton width={100} height={25} />
                  </Col>
                  <Col
                    xs={8}
                    sm={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Skeleton width={25} height={25} variant="circular" />
                  </Col>
                </Col>
              </Col>
            </Col>
          </Paper>
        </div>
      ))}
    </Fragment>
  );
};

export default FeedbackSkeletonLoading;
