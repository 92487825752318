import { Button, CircularProgress, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileContext } from '../../../../../contexts/profile';
import useUpdatePublishRequestReviewer from '../../../../../api-hooks/publish-request/useUpdatePublishRequestReviewer';

const buttonStyle: any = {
  fontSize: 11,
  padding: 5,
  marginRight: 5,
  textTransform: 'none',
};

const ApproveButton = ({ publish, setProcess }) => {
  const { profileData } = useContext(ProfileContext);
  const [approveButtonData, setApproveButtonData] = useState<any>({
    show: false,
    disabled: false,
    isApproved: false,
  });
  const [loading, setLoading] = useState<any>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { updatePublishRequestReviewer } = useUpdatePublishRequestReviewer(
    publish?.id
  );

  useEffect(() => {
    if (publish && profileData) {
      const approveButtonData = {
        show: false,
        status: 'OPEN',
        reviewId: '',
      };
      const filteredReviewrs = { ...publish }.reviews?.filter(
        (reviewer) => reviewer.userId === profileData.id
      );
      if (filteredReviewrs && filteredReviewrs.length) {
        const reviewer = filteredReviewrs[0];
        approveButtonData.show = true;
        approveButtonData.status = reviewer.status;
        approveButtonData.reviewId = reviewer.id;
      }
      setApproveButtonData(approveButtonData);
    }
  }, [publish, profileData]);

  const changeStatus = (status: string) => {
    const newPublish = { ...publish };
    const filteredReviewer = newPublish.reviews.find(
      (reviewer) => reviewer.id === approveButtonData.reviewId
    );
    setLoading({
      previousStatus: approveButtonData.status,
      newStatus: status,
    });
    if (filteredReviewer) {
      const newReviewerData = { ...filteredReviewer, status: status };

      updatePublishRequestReviewer(newReviewerData)
        .then(() => {
          setProcess(null);
          setLoading(null);
        })
        .catch(() => {
          setLoading(null);
          enqueueSnackbar(t('error-change-approve-status'), {
            variant: 'error',
          });
        });
    }
  };

  if (approveButtonData.show) {
    return (
      <>
        {publish.status === 'Closed' ? (
          <></>
        ) : (
          <div style={{ display: 'flex' }}>
            <Tooltip
              arrow
              title={
                approveButtonData.status === 'APPROVED'
                  ? t<string>('disapprove-help')
                  : t<string>('approve-help')
              }
            >
              <Button
                disabled={loading !== null}
                style={{
                  ...buttonStyle,
                  ...{
                    color:
                      approveButtonData.status === 'APPROVED'
                        ? 'white'
                        : 'green',
                    borderColor: 'green',
                    backgroundColor:
                      approveButtonData.status === 'APPROVED'
                        ? 'green'
                        : 'white',
                  },
                }}
                variant="outlined"
                onClick={() =>
                  changeStatus(
                    approveButtonData.status === 'APPROVED'
                      ? 'OPEN'
                      : 'APPROVED'
                  )
                }
              >
                {loading?.previousStatus === 'APPROVED' &&
                loading?.newStatus === 'OPEN' ? (
                  <CircularProgress
                    style={{ width: 20, height: 20, color: '#fff' }}
                  />
                ) : loading?.newStatus === 'APPROVED' ? (
                  <CircularProgress
                    style={{ width: 20, height: 20, color: 'green' }}
                  />
                ) : approveButtonData.status === 'APPROVED' ? (
                  t('disapprove')
                ) : (
                  t('approve')
                )}
              </Button>
            </Tooltip>
            <Tooltip arrow title={t<string>('needs-work-help')}>
              <Button
                disabled={loading !== null}
                style={{
                  width: '80px',
                  ...buttonStyle,
                  ...{
                    color:
                      approveButtonData.status === 'REJECTED'
                        ? 'white'
                        : '#ffc107',
                    borderColor: '#ffc107',
                    backgroundColor:
                      approveButtonData.status === 'REJECTED'
                        ? '#ffc107'
                        : 'white',
                  },
                }}
                variant="outlined"
                onClick={() =>
                  changeStatus(
                    approveButtonData.status === 'REJECTED'
                      ? 'OPEN'
                      : 'REJECTED'
                  )
                }
              >
                {loading?.previousStatus === 'REJECTED' &&
                loading?.newStatus === 'OPEN' ? (
                  <CircularProgress
                    style={{ width: 20, height: 20, color: '#fff' }}
                  />
                ) : loading?.newStatus === 'REJECTED' ? (
                  <CircularProgress
                    style={{ width: 20, height: 20, color: '#ffc107' }}
                  />
                ) : (
                  t('needs-work')
                )}
              </Button>
            </Tooltip>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default ApproveButton;
