import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Divider, Typography } from '@mui/material';
import GoogleSearchResultBreadcrumb from '../breadcrumb';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDateTime } from '../../../helpers/common';
import { TopicContext } from '../../../contexts/topic';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: '#326cd9',
    fontWeight: 400,
  },
  accordion: {
    boxShadow: 'none',
    '& > div': {
      padding: '0 16px',
    },
  },
});

const GoogleHowToSearchResult = ({ topicList }) => {
  const [headings, setHeadings] = useState<NodeListOf<Element> | undefined>();

  const classes = useStyles();

  const { selectedTopic } = useContext(TopicContext);

  const getContentBetweenHeadings = (index: number) => {
    if (headings && selectedTopic) {
      const startIndex =
        selectedTopic.body.indexOf(headings[index].outerHTML) +
        headings[index].outerHTML.length;
      const endIndex =
        index !== headings.length - 1
          ? selectedTopic.body.indexOf(headings[index + 1].outerHTML)
          : selectedTopic.body.length;

      return selectedTopic.body.substring(startIndex, endIndex);
    }

    return '';
  };

  useEffect(() => {
    if (selectedTopic) {
      const tempElement = document.createElement('div');

      tempElement.innerHTML = selectedTopic.body;

      const headings = tempElement.querySelectorAll('h2, .custom-heading');

      setHeadings(headings);
    }
  }, [selectedTopic]);

  if (selectedTopic) {
    return (
      <Card className={classes.root}>
        <CardContent style={{ paddingBottom: 10 }}>
          <GoogleSearchResultBreadcrumb topicList={topicList} />

          <Typography className={classes.title} gutterBottom>
            {selectedTopic?.title}
          </Typography>

          <Divider />

          <div style={{ marginTop: 10 }}>
            <span style={{ color: '#7b8085' }}>
              {getDateTime(selectedTopic)}
            </span>
            <span style={{ color: '#7b8085', margin: '0 3px' }}>--</span>
            <span style={{ color: '#3b4043' }}>
              {selectedTopic?.description ||
                selectedTopic?.title ||
                selectedTopic?.slug}
            </span>
          </div>

          <div style={{ marginTop: 10 }}>
            {!!headings &&
              Array.from(headings).map((heading, index) => (
                <Accordion key={index} className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`panel-header-${index}`}
                  >
                    <Typography>
                      {index + 1}. {heading.textContent}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    style={{
                      padding: '8px 0',
                      borderBottom: '1px solid rgb(224 224 224)',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getContentBetweenHeadings(index),
                      }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default GoogleHowToSearchResult;
