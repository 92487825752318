import React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';

const Reviewers = ({ reviewers, accesses, smallSize }) => {
  const avatarStyle: any = {
    width: smallSize ? 25 : 30,
    height: smallSize ? 25 : 30,
    marginRight: 5,
    fontSize: smallSize ? 12 : 14,
  };

  const checkMarkStyle: any = {
    position: 'absolute',
    top: -6,
    right: 2,
    zIndex: 2,
    width: 15,
    height: 15,
    backgroundColor: '#fff',
    borderRadius: '50%',
  };

  const numberOfReviewersToShow = smallSize ? 3 : 4;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {reviewers &&
        (reviewers.length > numberOfReviewersToShow
          ? reviewers.slice(0, numberOfReviewersToShow)
          : reviewers
        ).map((reviewer, index) => {
          const filteredAccesses = accesses?.filter(
            (access) => access.principalId === reviewer.userId
          );
          if (filteredAccesses?.length) {
            return (
              <Tooltip
                key={index}
                arrow
                title={
                  filteredAccesses[0].displayName || filteredAccesses[0].email
                }
              >
                <div style={{ position: 'relative' }}>
                  {reviewer.status === 'APPROVED' && (
                    <CheckCircleOutlineIcon
                      style={{ ...checkMarkStyle, ...{ color: 'green' } }}
                    />
                  )}
                  {reviewer.status === 'REJECTED' && (
                    <BlockIcon
                      style={{ ...checkMarkStyle, ...{ color: '#ffc107' } }}
                    />
                  )}
                  {filteredAccesses[0].photoUrl ? (
                    <Avatar
                      variant="square"
                      alt={
                        filteredAccesses[0].displayName ||
                        filteredAccesses[0].email
                      }
                      src={filteredAccesses[0].photoUrl}
                      style={avatarStyle}
                    />
                  ) : (
                    <Avatar variant="square" style={avatarStyle}>
                      {
                        (filteredAccesses[0].displayName ||
                          filteredAccesses[0].email)[0]
                      }
                    </Avatar>
                  )}
                </div>
              </Tooltip>
            );
          }
          return null;
        })}
      {reviewers && reviewers.length > numberOfReviewersToShow && (
        <Avatar variant="square" style={avatarStyle}>
          +{reviewers.length - numberOfReviewersToShow}
        </Avatar>
      )}
    </div>
  );
};

export default Reviewers;
