import { Tooltip } from 'antd';
import React from 'react';
import './styles.css';

const ProgressBar = ({ value, realValue, width, children }) => {
  const childrenWithProps = children
    ? React.Children.map(
        children,
        (child) => child && React.cloneElement(child, { width: width })
      )
    : null;

  return (
    <div className="custom-progress-bar" style={{ width: width }}>
      {childrenWithProps}
      <Tooltip
        title={Number.isInteger(realValue) ? realValue : realValue.toFixed(1)}
      >
        <div
          className="current-value"
          style={{ left: (width / 100) * value }}
        ></div>
      </Tooltip>
    </div>
  );
};

export default ProgressBar;
