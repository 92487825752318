import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { AccountInvoices } from '../../api-schemas/account';

const getAccountInvoicesRequest = (accountId?: string) => {
  return axios
    .get<AccountInvoices>(`/api/Account/${accountId}/Invoices`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccountInvoices(accountId: string) {
  const {
    data,
    refetch: getAccountInvoices,
    isFetching: isLoadingAccountInvoices,
    isSuccess: isSuccessLoadingAccountInvoices,
  } = useQuery<AccountInvoices>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'invoices', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId),
    queryFn: () => getAccountInvoicesRequest(accountId),
  });

  const accountInvoices = useMemo(() => data, [data]);

  return {
    accountInvoices,
    isLoadingAccountInvoices,
    isSuccessLoadingAccountInvoices,

    getAccountInvoices,
  };
}
