import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import useRevertTopic from '../../../../../api-hooks/topic/useRevertTopic';
import { TopicContext } from '../../../../../contexts/topic';

const RevertButton = ({
  targetTopicVersionId,
}: {
  targetTopicVersionId: string | null;
}) => {
  const queryClient = useQueryClient();

  const { selectedTopic, setEditedTopic } = useContext(TopicContext);

  const { revertTopic } = useRevertTopic({
    topicId: selectedTopic?.topicId,
    documentVersionId: selectedTopic?.documentVersionId,
    languageCode: selectedTopic?.languageCode,
  });

  return (
    <Button
      variant="contained"
      color="primary"
      className={'restore-version-btn'}
      onClick={() => {
        revertTopic({
          topicId: selectedTopic!.topicId,
          languageCode: selectedTopic!.languageCode,
          targetTopicVersionId: targetTopicVersionId!,
        }).then(() => {
          setEditedTopic(undefined);

          queryClient.invalidateQueries({
            queryKey: [
              'draftTopic',
              'silent',
              selectedTopic!.topicId,
              selectedTopic!.languageCode,
            ],
          });
        });
      }}
      disabled={selectedTopic?.topicVersionId === targetTopicVersionId}
    >
      Restore
    </Button>
  );
};

export default RevertButton;
