import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';


const deleteGoogleDriveAccountTokenRequest = (accountId : string) => {
  return axios
    .delete(`/api/google-drive-integration/account/${accountId}/token`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteGoogleDriveAccountToken(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteGoogleDriveAccountToken,
    isPending: isDeletingGoogleDriveAccountToken,
    isSuccess: isSuccessDeleteGoogleDriveAccountToken,
  } = useMutation({
    mutationFn: (accountId: string) => deleteGoogleDriveAccountTokenRequest(accountId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', accountId],
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });
    },
  });

  return {
    deleteGoogleDriveAccountToken,
    isDeletingGoogleDriveAccountToken,
    isSuccessDeleteGoogleDriveAccountToken,
  };
}
