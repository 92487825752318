import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteAccountLogoRequest = ({ accountId }: { accountId: string }) => {
  return axios
    .delete(`/api/Account/${accountId}/Logo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteAccountLogo(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteAccountLogo,
    isPending: isDeletingAccountLogo,
    isSuccess: isSuccessDeletingAccountLogo,
  } = useMutation({
    mutationFn: deleteAccountLogoRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'logo', accountId],
      });
    },
  });

  return {
    deleteAccountLogo,
    isDeletingAccountLogo,
    isSuccessDeletingAccountLogo,
  };
}
