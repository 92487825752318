import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CardSetupForm from './setup-form';
import { CircularProgress } from '@mui/material';
import config from 'react-global-configuration';

const stripeJs = async () => await import('@stripe/stripe-js');

const AddPaymentMethod = (props: any) => {
  const [stripePromise, setStripePromise] = React.useState<any>('');
  const fetchData = async () => {
    const { loadStripe } = await stripeJs();
    const stripeKey = config.get('stripeKey');
    const stripePromise = loadStripe(stripeKey);
    setStripePromise(stripePromise);
  };
  React.useEffect(() => {
    if (stripePromise === '') {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  if (stripePromise !== '') {
    return (
      <Elements stripe={stripePromise}>
        <CardSetupForm
          accountConfiguration={props.accountConfiguration}
          updatePaymentMethods={props.updatePaymentMethods}
          changePlan={props.changePlan}
          setOpenPaymentDialog={props.setOpenPaymentDialog}
          planId={props.planId}
        />
      </Elements>
    );
  } else {
    return <CircularProgress />;
  }
};

export default AddPaymentMethod;
