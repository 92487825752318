import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Comment } from '../../api-schemas/comment';

const updateCommentRequest = ({
  threadId,
  data,
}: {
  threadId: string;
  data: Comment;
}) => {
  const body = JSON.stringify(data);

  return axios
    .put(`/api/thread/${threadId}/comment`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateComment({
  threadId,
  channelId,
}: {
  threadId: string;
  channelId: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateComment,
    isPending: isUpdatingComment,
    isSuccess: isSuccessUpdatingComment,
  } = useMutation({
    mutationFn: updateCommentRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['threads', channelId] });
      queryClient.invalidateQueries({ queryKey: ['thread', threadId] });
    },
  });

  return {
    updateComment,
    isUpdatingComment,
    isSuccessUpdatingComment,
  };
}
