import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import HelpSnippet from '../../help-snippet';
import TextInput from '../../inputs/text-input';
import TextAreaInput from '../../inputs/text-area-input';
import { Radio, Select, Typography as AntTypography } from 'antd';
import SelectInput from '../../inputs/select-input';
import GoogleNormalSearchResult from '../../google-search-results/normal';
import GoogleArticleSearchResult from '../../google-search-results/article';
import GoogleHowToSearchResult from '../../google-search-results/how-to';
import CropLogo from '../../crop-logo';
import config from 'react-global-configuration';
import { Divider } from 'antd';
import { AccountContext } from '../../../contexts/account';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { removeStopwords } = require('stopword');

const { Option } = Select;
const { Text, Title } = AntTypography;

const styles: any = () => ({
  titles: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    width: 75,
  },
  schemaTypeConfig: {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: '5px 7px',
    marginLeft: 10,
    '& .MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 20,
    },
  },
});

const TopicSettingsEditor = ({
  handleTopicDataChange,
  topicList,
  selectedTopic,
}) => {
  const [schemaType, setSchemaType] = useState<number>(0);
  const [showKeyphraseError, setShowKeyphraseError] = useState<boolean>(false);

  const cropLogoRef = useRef();

  const { t, i18n } = useTranslation();

  const { selectedAccount } = useContext(AccountContext);

  const isDeleted = selectedTopic?.action === 'Deleted';

  useEffect(() => {
    setSchemaType(selectedTopic?.schemaType || 0);
  }, [selectedTopic]);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    // eslint-disable-next-line
  }, []);

  const handleSlugChange = (event) => {
    const value: string = event.target.value;

    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'slug');
    }
  };

  const handleSchemaTypeChange = (value: number) => {
    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'schemaType');
      setSchemaType(value);
    }
  };

  const handleDescriptionChange = (event) => {
    const value: string = event.target.value;

    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'description');
    }
  };

  const handleKeyphraseChange = (event) => {
    const value: string = event.target.value;

    if (selectedTopic?.languageCode === 'en') {
      const newStringArray = removeStopwords(value.split(','));
      if (newStringArray.length > 4) {
        setShowKeyphraseError(true);
        return;
      }
    }
    setShowKeyphraseError(false);
    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'focusKeyword');
    }
  };

  const handleImageChanged = (file: any) => {
    if (handleTopicDataChange) {
      handleTopicDataChange(file, 'imageId');
    }
  };

  const handleToolChange = (event) => {
    const value: string = event.target.value;
    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'tool');
    }
  };

  const handleSupplyChange = (event) => {
    const value: string = event.target.value;
    if (handleTopicDataChange) {
      handleTopicDataChange(value, 'supply');
    }
  };

  const handleTopicVisibilityChange = (event) => {
    const value: string = event.target.value;

    if (handleTopicDataChange) {
      handleTopicDataChange(!value, 'isHidden');
    }
  };

  const getErrorMessage = (field: string) => {
    return t<string>(`error.${field}`);
  };

  const handleWebAssistantSimpleRegexChange = (
    event,
    characterSelected?: string
  ) => {
    let newValue: string | undefined | null =
      selectedTopic?.webAssistantSimpleRegex;

    if (characterSelected) {
      if (newValue === null) {
        newValue = '';
      }
      newValue += characterSelected;
    } else {
      newValue = event.target.value;
    }

    if (newValue === '') {
      newValue = null;
    }

    if (handleTopicDataChange) {
      handleTopicDataChange(newValue, 'webAssistantSimpleRegex');
    }
  };

  return (
    <div style={{ marginTop: 15, width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <TextInput
          required={true}
          field="slug"
          label={t('slug')}
          tooltip={t('topic-slug-help')}
          onChange={handleSlugChange}
          value={selectedTopic?.slug}
          disabled={isDeleted}
          style={{ width: 'calc(50% - 10px)', marginRight: 20 }}
        />
        <TextInput
          field="keyphrase"
          label={t('keyphrase')}
          tooltip={t('keyphrase-help')}
          onChange={handleKeyphraseChange}
          value={selectedTopic?.focusKeyword}
          disabled={isDeleted}
          style={{ width: 'calc(50% - 10px)' }}
          showError={showKeyphraseError}
          getErrorMessage={getErrorMessage}
        />
      </div>
      <TextAreaInput
        required={true}
        field="description"
        label={t('description')}
        onChange={handleDescriptionChange}
        value={selectedTopic?.description}
        disabled={isDeleted}
        style={{ marginTop: 15 }}
        id="topic-description"
      />
      <div
        style={{
          color: '#d9d9d9',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ color: '#ff00007d' }}>
          {selectedTopic &&
          selectedTopic.description &&
          selectedTopic.description.length > 160
            ? t('error.description-length')
            : ''}
        </span>
        <span>
          <span
            style={{
              color:
                selectedTopic &&
                selectedTopic.description &&
                selectedTopic.description.length > 160
                  ? '#ff00007d'
                  : '#d9d9d9',
            }}
          >
            {selectedTopic && selectedTopic.description
              ? selectedTopic.description.length
              : 0}
          </span>{' '}
          / <span>160</span>
        </span>
      </div>
      <Box display="flex" style={{ marginTop: 15 }}>
        <Box style={{ width: 'calc(40% - 20px)', marginRight: 10 }}>
          <Title className="settings-item-title" level={4}>
            {t('topic-visibility')}
          </Title>

          <Text
            style={{ display: 'block', color: 'rgba(0,0,0,.85)', fontSize: 14 }}
          >
            {t('topic-visibility-help')}
          </Text>

          <Radio.Group
            style={{ marginBottom: 20, marginTop: 15 }}
            onChange={handleTopicVisibilityChange}
            defaultValue={true}
            buttonStyle="solid"
            value={!selectedTopic?.isHidden}
          >
            <Radio.Button value={true}>{t('show')}</Radio.Button>
            <Radio.Button value={false}>{t('hide')}</Radio.Button>
          </Radio.Group>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          style={{ width: 'calc(40% - 20px)', marginRight: 10 }}
        >
          <Box className={'topic-schema-type'} style={{ width: '100%' }}>
            <SelectInput
              id="topic-schema-type-select"
              field="schema-type"
              label={t('topic-schema-type')}
              value={schemaType}
              onChange={handleSchemaTypeChange}
            >
              <Option value={0}>
                <span>{t('normal-schema-topic')}</span>
                <HelpSnippet title={t('normal-schema-topic-help')} />
              </Option>

              <Option value={1}>
                <span>{t('how-to-schema-topic')}</span>
                <HelpSnippet title={t('how-to-schema-topic-help')} />
              </Option>

              <Option value={2}>
                <span>{t('article-schema-topic')}</span>
                <HelpSnippet title={t('article-schema-topic-help')} />
              </Option>
            </SelectInput>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: 35, width: '100%' }}
          >
            {schemaType === 1 ? (
              <GoogleHowToSearchResult topicList={topicList} />
            ) : schemaType === 2 ? (
              <GoogleArticleSearchResult topicList={topicList} />
            ) : (
              <GoogleNormalSearchResult topicList={topicList} />
            )}
          </Box>
        </Box>

        <Box style={{ width: 'calc(20% - 20px)' }}>
          <InputLabel
            style={{
              verticalAlign: 'super',
              display: 'inline-block',
              color: 'rgba(0, 0, 0, 0.85)',
              fontSize: 14,
            }}
          >
            {t('image')}
          </InputLabel>

          <CropLogo
            // finalImageClassName={classes.finalImage}
            //eslint-disable-next-line
            //@ts-expect-error
            inputStyle={{ width: '100%' }}
            ref={cropLogoRef}
            handleLogoChanged={handleImageChanged}
            handleRemoveLogo={() => handleImageChanged(null)}
            logoSrc={selectedTopic?.imageId}
            selectedTopic={selectedTopic}
          />
        </Box>
      </Box>

      {schemaType === 1 ? (
        <div style={{ display: 'flex' }}>
          <TextInput
            field="tool"
            label={t('tool')}
            tooltip={t('tool-schema-help')}
            onChange={handleToolChange}
            value={selectedTopic?.tool}
            disabled={isDeleted}
            style={{
              marginTop: 15,
              width: 'calc(50% - 10px)',
              marginRight: 20,
            }}
          />
          <TextInput
            field="supply"
            label={t('supply')}
            tooltip={t('supply-schema-help')}
            onChange={handleSupplyChange}
            value={selectedTopic?.supply}
            disabled={isDeleted}
            style={{ marginTop: 15, width: 'calc(50% - 10px)' }}
          />
        </div>
      ) : null}

      {(config.get('environment') === 'stage' ||
        selectedAccount?.slug === 'sangal' ||
        selectedAccount?.slug === 'manu-online' ||
        selectedAccount?.planId === '0b8085be-c002-4320-b3f7-7982636c2671' ||
        selectedAccount?.planId === '2d8e7c87-74d8-4c17-9108-5f77f0cec109') && (
        <>
          <Divider />

          <div>
            <TextInput
              required={false}
              field="webAssistantSimpleRegex"
              label={t('web-assistant-simple-regex')}
              tooltip={t('web-assistant-simple-regex-help')}
              onChange={handleWebAssistantSimpleRegexChange}
              value={selectedTopic?.webAssistantSimpleRegex}
              disabled={isDeleted}
              style={{ marginTop: 15, width: '100%' }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(TopicSettingsEditor);
