import React from 'react'
import ViewAnalytics from '../../../components/analytics/view'

const ViewAnalyticsPage = (props) => {
    return (
        <ViewAnalytics {...props} />
    )
}

export default ViewAnalyticsPage
