import { useEffect, useRef } from 'react';

const useDebounceFn = ({
  value,
  callback,
  delay,
}: {
  value: any;
  callback: (...args) => any;
  delay: number;
}) => {
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      callback();
    }, delay);

    return () => clearTimeout(timeoutId.current);
  }, [value]);
};

export default useDebounceFn;
