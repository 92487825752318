import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import './index.css';
import icons from './icons';
import { Input, Space } from 'antd';
import { Tooltip, IconButton } from '@mui/material';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import { MdOutlineDelete } from 'react-icons/md';

const { Text } = Typography;

const IconPicker = (props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<string | undefined | null>(
    null
  );
  const [tempSelectedIcon, setTempSelectedIcon] = useState<
    string | undefined | null
  >(null);
  const [filteredIcons, setFilteredIcons] = useState<any[]>(icons);
  const [searchedString, setSearchedString] = useState<string>('');
  const { Search } = Input;

  const handleOnChangeSearch = (event) => {
    setSearchedString(event.target.value);
    const searchResult = [...icons].filter((icon) => {
      return icon.replaceAll('_', ' ').includes(event.target.value);
    });
    setFilteredIcons(searchResult);
  };

  const handleOnClick = (value) => {
    if (value === selectedIcon) {
      setTempSelectedIcon(null);
    } else {
      setTempSelectedIcon(value);
    }
  };

  const handleOnSelect = () => {
    if (tempSelectedIcon) {
      props.handleIconChange(tempSelectedIcon);
      setSelectedIcon(tempSelectedIcon);
      setOpenDialog(false);
    }
  };

  const handleRemoveIcon = () => {
    props.handleIconChange(null);
    setSelectedIcon(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.setIcon) {
      props.setIcon(selectedIcon);
    }
    //eslint-disable-next-line
  }, [selectedIcon]);

  useEffect(() => {
    if (props.model.iconName !== null) {
      setSelectedIcon(props.model.iconName);
      setTempSelectedIcon(props.model.iconName);
    }
  }, [props.model]);

  return (
    <div>
      <div style={{ height: 150 }}>
        {selectedIcon !== null &&
        selectedIcon !== undefined &&
        selectedIcon !== 'null' ? (
          <div
            style={{
              width: '150px',
              height: '150px',
              borderRadius: 15,
              border: '1px dashed #d9d9d9',
              textAlign: 'center',
              backgroundColor: '#fafafa',
            }}
          >
            <div
              style={{
                paddingTop: 35,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span
                className={`active mdi ${selectedIcon}`}
                style={{ fontSize: '45px' }}
              ></span>
              <Tooltip
                title="Delete Icon"
                onClick={() => {
                  handleRemoveIcon();
                }}
              >
                <IconButton aria-label="delete" style={{ width: 50 }}>
                  <MdOutlineDelete />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ) : (
          <AntButton
            style={{
              width: '150px',
              height: '150px',
              borderRadius: 15,
              border: '1px dashed #d9d9d9',
              textAlign: 'center',
              backgroundColor: '#fafafa',
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PlusOutlined />
              <Text style={{ marginTop: 10 }}>Choose Icon</Text>
            </div>
          </AntButton>
        )}
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog} className="dialog">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div style={{ paddingLeft: '15px' }}>
            <DialogTitle>Icons</DialogTitle>
          </div>
          <div style={{ paddingRight: '50px' }}>
            <Space direction="vertical">
              <Search
                placeholder="Search for icons"
                onChange={handleOnChangeSearch}
                style={{ width: 200 }}
              />
            </Space>
          </div>
        </div>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <DialogContentText className="icon-wrapper">
            {filteredIcons.length !== 0 ? (
              filteredIcons.map((icon, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                      marginRight: '10px',
                      marginTop: '20px',
                      fontSize: '40px',
                    }}
                    className={
                      tempSelectedIcon === icon
                        ? `active mdi ${icon}`
                        : `mdi ${icon}`
                    }
                    onClick={() => {
                      handleOnClick(icon);
                    }}
                  ></span>
                );
              })
            ) : (
              <h2>
                No results for <strong>{searchedString}</strong>
              </h2>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button
            onClick={handleOnSelect}
            color="primary"
            variant="contained"
            autoFocus
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IconPicker;
