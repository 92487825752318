import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from 'antd';

import { calculateReadabilityScore, calculateSEOScore, trunc } from '../../../../helpers/common';
import TreeItemMenu from '../tree-item-menu';
import TopicStatuses from '../topic-statuses';
import MetricsStatuses from '../../../metrics-analytics/metrics-statuses';
import { DraftTopicTree, DraftTopicTreeItem } from '../../../../api-schemas/topic';
import { Document } from '../../../../api-schemas/document';
import { TopicContext } from '../../../../contexts/topic';

type TreeItemTitlePropsType = {
  draftTopicTree: DraftTopicTree;
  flatDraftTopicTree: DraftTopicTree;
  draftTopicTreeItem: DraftTopicTreeItem;
  document: Document;
  documentVersionId: string;
  searchValue: string;
};

const TreeItemTitle = ({
  draftTopicTree,
  flatDraftTopicTree,
  draftTopicTreeItem,
  document,
  documentVersionId,
  searchValue,
}: TreeItemTitlePropsType) => {
  const title = draftTopicTreeItem.title || draftTopicTreeItem.slug;
  const index = title.indexOf(searchValue);
  const beforeSearchValue = title.substring(0, index);
  const afterSearchValue = title.slice(index + searchValue.length);
  const isLanguageEqual = draftTopicTreeItem.title !== null;

  const { changeTopic } = useContext(TopicContext);

  const history = useHistory();
  const { hash, search } = useLocation();

  const handleChangeTopic = () => {
    changeTopic(draftTopicTreeItem.topicId);

    history.push({ hash: '', search });
  };

  const seoOnClick = useCallback(() => {
    changeTopic(draftTopicTreeItem.topicId);

    history.push({ hash: 'SEO', search });
  }, [draftTopicTreeItem, search]);

  const readabilityOnClick = useCallback(() => {
    changeTopic(draftTopicTreeItem.topicId);

    history.push({ hash: 'readability', search });
  }, [draftTopicTreeItem, search]);

  const brokenLinksOnClick = useCallback(() => {
    changeTopic(draftTopicTreeItem.topicId);

    history.push({ hash: 'refLinks', search });
  }, [draftTopicTreeItem, search]);

  return (
    <div
      onClick={handleChangeTopic}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 8,
      }}
    >
      <Typography style={{ color: !isLanguageEqual ? 'gray' : 'inherit' }}>
        {index > -1 ? (
          <span>
            {beforeSearchValue}
            <span style={{ color: '#1577ff' }}>{searchValue}</span>
            {afterSearchValue}
          </span>
        ) : (
          <span>{trunc(title, 15)}</span>
        )}
      </Typography>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {(hash === 'SEO' || hash === 'readability') && draftTopicTreeItem.metrics ? (
          <MetricsStatuses
            seoScore={calculateSEOScore(draftTopicTreeItem.metrics)}
            readabilityScore={calculateReadabilityScore(draftTopicTreeItem.metrics)}
            seoOnClick={seoOnClick}
            readabilityOnClick={readabilityOnClick}
            numberOfBrokenLinks={2}
            brokenLinksOnClick={brokenLinksOnClick}
          />
        ) : null}

        <TopicStatuses draftTopic={draftTopicTreeItem} />

        <TreeItemMenu
          draftTopicTree={draftTopicTree}
          flatDraftTopicTree={flatDraftTopicTree}
          draftTopicTreeItem={draftTopicTreeItem}
          document={document}
          documentVersionId={documentVersionId}
        />
      </div>
    </div>
  );
};

export default TreeItemTitle;
