import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { DragIndicator } from '@mui/icons-material';
import { Draggable as DndDraggable } from 'react-beautiful-dnd';

import ComponentBox from '../component-box';
import useGetComponentVersionWithNumber from '../../../../api-hooks/component/useGetComponentVersionWithNumber';
import useGetComponentClassName from '../../../../component-hooks/component/useGetComponentClassName';

const SortableItem = (props) => {
  const [componentData, setComponentData] = useState<any>();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { componentVersion } = useGetComponentVersionWithNumber({
    componentId: props.data.componentData.componentId,
    componentVersionNumber: props.data.componentData.componentVersionNumber,
    enabled: props.data && props.data.order !== '-1',
    onSuccess: () => {
      setComponentData(props.data.componentData);
    },
    onReject: () => {
      enqueueSnackbar(t('error.getComponent'), {
        variant: 'error',
      });
    },
  });

  const { responsivenessClassName } = useGetComponentClassName({
    baseSize: props.data?.componentData?.layout?.baseColumnSize || 12,
  });

  if (props.data.order === '-1') {
    return (
      <div
        style={{
          margin: '10px 0',
          border: '1px solid rgba(0,0,0,.06)',
          textAlign: 'center',
        }}
      >
        <h6 style={{ margin: 20 }}>Drop your components here.</h6>
      </div>
    );
  } else if (!componentData || !componentVersion) {
    return null;
  } else {
    return (
      <DndDraggable draggableId={props.id.toString()} index={props.id}>
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              id={`component-box-${props.data.order}`}
              className={`full-height-flex-box component-item ${responsivenessClassName}`}
              style={{
                padding: 16,
                height: 'auto',
                border: 'none',
                margin: '0',
                maxWidth: '100%',
                minHeight: '50px',
                backgroundColor: 'inherit',
                color: 'white',
                ...provided.draggableProps.style,
              }}
            >
              <Box>
                <div>
                  <span className="drag-indicator" {...provided.dragHandleProps}>
                    <DragIndicator style={{ color: '#000' }} />
                  </span>
                  <div>
                    <ComponentBox
                      data={props.data}
                      componentData={componentData}
                      componentVersion={componentVersion}
                      setDeleteItemIndex={props.setDeleteItemIndex}
                      setEditItem={props.setEditItem}
                    />
                  </div>
                </div>
              </Box>
            </div>
          );
        }}
      </DndDraggable>
    );
  }
};

export default SortableItem;
