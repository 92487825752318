import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import yaml from 'js-yaml';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Skeleton } from '@mui/material';
import { Col, Tooltip } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import { DraftTopic, DraftTopicTree, TopicContentComponentBodyItem } from '../../../api-schemas/topic';
import {
  convertToSlug,
  createNewTopicContent,
  createNewTopics,
  emptyGuid,
  getNotReservedTopicIndex,
} from '../../../helpers/common';
import { calculateNewTopicAndSlug, isTopicUpdated, updateComponentIndex } from '../../../helpers/content';
import { ComponentContext } from '../../../contexts/component';
import ComponentEditor from '../component-editor';
import ContentEditor from '../content-editor';
import TopicVersionDiffViewer from '../diff';
import TopicSettingsEditor from '../settings';
import FeedbackAnalysis from '../../feedback';
import EmbeddedGoogleDoc from '../../google-doc-editor';
import MetricsAnalytics from '../../metrics-analytics';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import ComponentSelector from '../../component-selector';
import CommentsBox from '../../comment/comments-box';
import TopicContentEditorHandlerHeader from './header';
import { TopicContext } from '../../../contexts/topic';
import useSaveTopic from '../../../component-hooks/topic/useSaveTopic';
import { ConfirmationDialog } from '../../confirmation-dialog';
import PlanLimitationDialog from '../../plan-limitation-dialog';
import useGetCurrentPlan from '../../../api-hooks/plan/useGetCurrentPlan';
import { StructuredDataContext } from '../../../contexts/structured-data-context';
import { getLanguageCode } from '../../../helpers/route';
import { DocumentContext } from '../../../contexts/document';
import ReferenceLinks from '../../reference-links';
import { AccountContext } from '../../../contexts/account';
import InvalidGoogleDriveTokenSection from '../invalid-google-drive-token-section';
import useSyncGoogleDocTopic from '../../../api-hooks/topic/useSyncGoogleDocTopic';
import TranslationSelection from '../editor/translation-selection';

type TopicContentEditorHandlerPropsType = {
  draftTopicTree?: DraftTopicTree;
  flatDraftTopicTree?: DraftTopicTree;
  isLoadingDraftTopicTree?: boolean;
  isSideSectionExpanded: boolean;
  setIsSideSectionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const Tabs = Object.freeze({
  Content: 'Edit',
  History: 'History',
  Settings: 'Settings',
  Feedback: 'Feedback',
  Metrics: 'Metrics',
  Preview: 'Preview',
  RefLinks: 'RefLinks',
});

const TopicContentEditorHandler = ({
  draftTopicTree,
  flatDraftTopicTree,
  isLoadingDraftTopicTree,
  isSideSectionExpanded,
  setIsSideSectionExpanded,
}: TopicContentEditorHandlerPropsType) => {
  const [selectedTab, setSelectedTab] = useState<string>(Tabs.Content);
  const [sideSectionTooltip, setSideSectionTooltip] = useState<string | null>(null);
  const [loadingComponentEditor, setLoadingComponentEditor] = useState<boolean>(false);
  const [targetTopicVersionId, setTargetTopicVersionId] = useState<string | null>(null);
  const [openRefreshPageDialog, setOpenRefreshPageDialog] = useState<boolean>(false);
  const [openPlanLimitationDialog, setOpenPlanLimitationDialog] = useState<boolean>(false);

  const isTopicSlugUpdated = useRef<boolean>(false);

  const {
    selectedTopic,
    editedTopic,
    setEditedTopic,
    isLoadingSelectedTopic,
    setIsSavingTopicEnabled,
    isSavingTopicEnabled,
  } = useContext(TopicContext);
  const { selectedVersion } = useContext(DocumentContext);
  const { setItems } = useContext(ComponentContext);
  const { context, setContext } = useContext(StructuredDataContext);
  const { selectedAccount } = useContext(AccountContext);

  const { t } = useTranslation();
  const { hash: selectedSideSection } = useLocation();
  const { accountId }: { accountId: string } = useParams();
  const queryClient = useQueryClient();

  const { currentPlan } = useGetCurrentPlan(accountId || '');
  const { isSavingTopic, isSuccessSavingTopic, numberOfFailedRequests, saveTopicAPI } = useSaveTopic({
    selectedTopic: editedTopic,
    draftTopicTree,
    enabled: isSavingTopicEnabled,
  });
  const { syncGoogleDocTopic } = useSyncGoogleDocTopic();

  const isLanguageEqual: boolean = selectedTopic ? selectedTopic.title !== null : false;

  useEffect(() => {
    if (
      !isSavingTopic &&
      !selectedTopic &&
      !isLoadingSelectedTopic &&
      !isLoadingDraftTopicTree &&
      !editedTopic &&
      (flatDraftTopicTree === undefined || flatDraftTopicTree?.length === 1)
    ) {
      try {
        const parentTopicId: string | null =
          flatDraftTopicTree && flatDraftTopicTree[0] ? flatDraftTopicTree[0].topicId : null;
        const documentVersionId: string | null =
          flatDraftTopicTree && flatDraftTopicTree[0] ? selectedVersion?.id : null;

        const newTopic = createNewTopics(parentTopicId, getLanguageCode(), flatDraftTopicTree);

        const newContent = createNewTopicContent(parentTopicId, getLanguageCode(), { id: documentVersionId }, newTopic);

        //eslint-disable-next-line
        //@ts-expect-error
        saveTopicAPI(newContent).then(() => {
          //eslint-disable-next-line
          //@ts-expect-error
          setEditedTopic({ ...newContent });
        });
      } catch (error) {
        /* empty */
      }
    }
  }, [selectedTopic, isLoadingSelectedTopic, flatDraftTopicTree, isLoadingDraftTopicTree]);

  useEffect(() => {
    if (selectedTopic?.topicId !== editedTopic?.topicId || selectedTopic?.languageCode !== editedTopic?.languageCode) {
      setIsSavingTopicEnabled(false);
      setEditedTopic(selectedTopic);
    }

    if (selectedTopic) {
      setContext({
        ...context,
        currentTopic: {
          id: selectedTopic.topicId,
          topicTitle: selectedTopic.title,
          topicSlug: selectedTopic.slug,
        },
      });

      setItems(() => selectedTopic.componentBody);
    }
    // eslint-disable-next-line
  }, [selectedTopic, isLanguageEqual]);

  useEffect(() => {
    if (numberOfFailedRequests >= 2) {
      setOpenRefreshPageDialog(true);
    }
  }, [numberOfFailedRequests]);

  useEffect(() => {
    if (isSuccessSavingTopic && isTopicSlugUpdated.current) {
      isTopicSlugUpdated.current = false;

      queryClient.invalidateQueries({
        queryKey: ['draftTopic', editedTopic?.topicId, editedTopic?.languageCode],
      });
    }
  }, [isSuccessSavingTopic]);

  const updateSlugWithFirstTitleChange = (updatedTopic: DraftTopic) => {
    const newTitle = calculateNewTopicAndSlug(
      updatedTopic,
      updatedTopic.title,
      draftTopicTree || [],
      updatedTopic.contentType === 'Component' ? 'New Page' : t('topic-new')
    )[2];
    const newSlug = convertToSlug(newTitle);

    if (
      ((updatedTopic.contentType === 'Component' && !newSlug.startsWith('new-page')) ||
        (updatedTopic.contentType !== 'Component' && !newSlug.startsWith('new-topic'))) &&
      newSlug !== '-'
    ) {
      const maxIndex: number = getNotReservedTopicIndex(newSlug, flatDraftTopicTree, updatedTopic.topicId);

      updatedTopic.slug = maxIndex ? `${newSlug}-${maxIndex}` : newSlug;

      isTopicSlugUpdated.current = true;
    }
  };

  const handleTopicDataChange = (value: any, field: string) => {
    if (editedTopic && editedTopic?.contentType !== 'GoogleDoc') {
      const updatedTopic = { ...editedTopic };

      if (field === 'slug') {
        isTopicSlugUpdated.current = true;
        updatedTopic[field] = convertToSlug(value as string);
      } else {
        updatedTopic[field] = value;
      }

      if (
        (updatedTopic.slug.startsWith('new-topic') &&
          updatedTopic.title !== 'New Topic' &&
          field === 'body' &&
          (editedTopic.topicVersionId || editedTopic.latestTopicVersionId) !== emptyGuid()) ||
        convertToSlug(editedTopic.title) === editedTopic.slug
      ) {
        updateSlugWithFirstTitleChange(updatedTopic);
      }

      if (isTopicUpdated(editedTopic, updatedTopic)) {
        setIsSavingTopicEnabled(true);
        setEditedTopic(updatedTopic);
      }
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const draggableComponent = localStorage.getItem('draggableComponent');

    if (draggableComponent) {
      localStorage.removeItem('draggableComponent');
    }

    if (source && destination) {
      setLoadingComponentEditor(true);

      setItems((items: TopicContentComponentBodyItem[]) => {
        const newItems = [...items];
        if (source.droppableId === 'component-selector' && draggableComponent) {
          //eslint-disable-next-line
          //@ts-expect-error
          const deletedItem = newItems.find((item) => item.order === '-1');

          if (deletedItem) {
            const deletedItemIndex = newItems.indexOf(deletedItem);

            newItems.splice(deletedItemIndex, 1);
          }

          const jsonDraggableComponent = JSON.parse(draggableComponent);
          const latestComponentVersion = jsonDraggableComponent.latestComponentVersion;
          const componentData = {
            id: uuidv4(),
            componentId: jsonDraggableComponent.id,
            componentVersionNumber: latestComponentVersion.majorNumber,
            content: latestComponentVersion.data.content,
            layout: {
              baseColumnSize: 12,
            },
          };
          const contentComponentData = {
            id: uuidv4(),
            componentDataId: componentData.id,
            order: destination.index.toString(),
            componentData: componentData,
          };
          const destinationItem = newItems.find((item) => item.order.toString() === destination.index.toString());

          if (destinationItem) {
            newItems.forEach((item) => {
              if (Number(item.order) >= destination.index) {
                updateComponentIndex(item, true);
              }
            });
          }

          //eslint-disable-next-line
          //@ts-expect-error
          newItems.splice(destination.index, 0, contentComponentData);
        } else {
          const sourceItem = newItems.find((item) => Number(item.order) === Number(source.index));

          if (sourceItem) {
            if (source.index < destination.index) {
              newItems.forEach((item) => {
                if (
                  Number(item.order) <= destination.index &&
                  Number(item.order) >= source.index &&
                  item.order !== sourceItem.order
                )
                  updateComponentIndex(item, false);
              });
            } else {
              newItems.forEach((item) => {
                if (
                  Number(item.order) >= destination.index &&
                  Number(item.order) <= source.index &&
                  item.order !== sourceItem.order
                )
                  updateComponentIndex(item, true);
              });
            }

            sourceItem.order = destination.index;

            window.componentsData[destination.index] = yaml.load(sourceItem.componentData.content.content);
          }
        }

        handleTopicDataChange(newItems, 'componentBody');

        return newItems;
      });

      setTimeout(() => {
        setLoadingComponentEditor(false);
      }, 2000);
    }
  };

  const handleOnFocusOut = () => {
    syncGoogleDocTopic({
      accountId: accountId,
      googleDriveIds: [selectedTopic?.googleDriveId || ''],
    });
  };

  const handleCollapse = () => {
    setIsSideSectionExpanded(false);
    setSideSectionTooltip(null);
  };

  const handleExpand = () => {
    setIsSideSectionExpanded(true);
    setSideSectionTooltip(null);
  };

  if (!selectedTopic || isLoadingSelectedTopic) {
    return (
      <Col
        xs={24}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingTop: '10px',
        }}
      >
        <Skeleton variant="rectangular" animation="pulse" height="100%" width="100%" />
      </Col>
    );
  }

  return (
    <React.Fragment>
      <Col xs={24} style={{ width: '100%' }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid
            container
            spacing={0}
            style={{
              paddingTop: '6px',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            }}
          >
            <Col
              xs={24}
              style={{
                paddingLeft: '25px',
                paddingRight: '25px',
                borderLeft: '1px solid rgb(217, 217, 217)',
              }}
            >
              <div style={{ flexWrap: 'wrap' }}>
                <Col
                  xs={24}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: 'auto',
                    flexWrap: 'wrap',
                  }}
                >
                  <TopicContentEditorHandlerHeader
                    isInSaveState={isSavingTopic}
                    isEditingContent={selectedTab === Tabs.Content}
                    draftTopicTree={draftTopicTree}
                    flatDraftTopicTree={flatDraftTopicTree}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    targetTopicVersionId={targetTopicVersionId}
                  />
                </Col>

                <Col
                  xs={24}
                  className={selectedTab === Tabs.History ? 'content-col width-transition' : 'width-transition'}
                  id="main-section"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 13,
                  }}
                >
                  {selectedTab === Tabs.Content ? (
                    <Col xs={24} style={{ display: 'flex' }}>
                      {selectedTopic?.contentType === 'Component' ? (
                        <Col
                          xl={isSideSectionExpanded ? 19 : 23}
                          sm={isSideSectionExpanded ? 15 : 22}
                          xs={isSideSectionExpanded ? 11 : 21}
                        >
                          <ComponentEditor
                            loading={loadingComponentEditor}
                            handleTopicDataChange={handleTopicDataChange}
                          />
                        </Col>
                      ) : selectedTopic?.contentType === 'GoogleDoc' ? (
                        <Col
                          xl={isSideSectionExpanded ? 19 : 23}
                          sm={isSideSectionExpanded ? 15 : 22}
                          xs={isSideSectionExpanded ? 11 : 21}
                        >
                          {selectedAccount?.hasValidGoogleDriveToken ? (
                            <EmbeddedGoogleDoc
                              accountId={accountId}
                              driveId={selectedTopic.googleDriveId}
                              handleOnFocusOut={handleOnFocusOut}
                            />
                          ) : (
                            <InvalidGoogleDriveTokenSection />
                          )}
                        </Col>
                      ) : (
                        <Col
                          xl={isSideSectionExpanded ? 19 : 23}
                          sm={isSideSectionExpanded ? 15 : 22}
                          xs={isSideSectionExpanded ? 11 : 21}
                        >
                          {!isLanguageEqual ? (
                            <TranslationSelection {...selectedTopic} />
                          ) : (
                            <ContentEditor
                              style={{ marginTop: '0px' }}
                              handleTopicDataChange={handleTopicDataChange}
                              topics={draftTopicTree}
                              flatDraftTopicTree={flatDraftTopicTree}
                            />
                          )}
                        </Col>
                      )}

                      <Col
                        xl={isSideSectionExpanded ? 5 : 1}
                        sm={isSideSectionExpanded ? 9 : 2}
                        xs={isSideSectionExpanded ? 13 : 3}
                        className="width-transition"
                        id="side-section"
                        style={{
                          marginTop: 10,
                          width: '100%',
                          display: 'flex',
                          alignItems: 'end',
                          flexDirection: 'column',
                        }}
                      >
                        {selectedTopic?.contentType !== 'GoogleDoc' || selectedSideSection ? (
                          isSideSectionExpanded ? (
                            <div style={{ width: '100%' }}>
                              <Tooltip title={t('collapse')} open={sideSectionTooltip === 'collapse'}>
                                <BsChevronRight
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: 5,
                                    marginRight: 10,
                                    fontSize: 20,
                                  }}
                                  onClick={handleCollapse}
                                  onMouseOver={() => setSideSectionTooltip('collapse')}
                                  onMouseLeave={() => setSideSectionTooltip(null)}
                                />
                              </Tooltip>

                              {selectedSideSection === '#components' ? (
                                <ComponentSelector />
                              ) : (selectedSideSection === '#SEO' || selectedSideSection === '#readability') &&
                                selectedTopic?.metrics ? (
                                <MetricsAnalytics />
                              ) : selectedSideSection === '#ReferenceLinks' ? (
                                <ReferenceLinks />
                              ) : selectedSideSection === '#comments' ? (
                                <CommentsBox
                                  fullWidth={false}
                                  channelId={`${selectedTopic?.topicId}-${selectedTopic?.languageCode}`}
                                  channelType={'Topics'}
                                />
                              ) : selectedTopic?.contentType === 'Component' ? (
                                <ComponentSelector />
                              ) : (
                                <CommentsBox
                                  fullWidth={false}
                                  channelId={`${selectedTopic?.topicId}-${selectedTopic?.languageCode}`}
                                  channelType={'Topics'}
                                />
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #d9d9d9',
                              }}
                            >
                              <Tooltip title={t('expand')} open={sideSectionTooltip === 'expand'}>
                                <BsChevronDown
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    fontSize: 20,
                                  }}
                                  onClick={handleExpand}
                                  onMouseOver={() => setSideSectionTooltip('expand')}
                                  onMouseLeave={() => setSideSectionTooltip(null)}
                                />
                              </Tooltip>

                              <div
                                className="side-collapse-section"
                                onClick={handleExpand}
                                style={{
                                  cursor: 'pointer',
                                  marginBottom: 5,
                                  marginTop: 5,
                                  writingMode: 'vertical-rl',
                                }}
                              >
                                {t(
                                  selectedSideSection
                                    ? selectedSideSection.slice(1)
                                    : selectedTopic?.contentType === 'Component'
                                      ? 'components'
                                      : 'comments'
                                )}
                              </div>
                            </div>
                          )
                        ) : null}
                      </Col>
                    </Col>
                  ) : null}

                  {selectedTab === Tabs.History && (
                    <TopicVersionDiffViewer
                      topicId={selectedTopic?.topicId}
                      topicSlug={selectedTopic?.slug}
                      topicVersionId={selectedTopic?.topicVersionId}
                      languageCode={selectedTopic?.languageCode}
                      topics={draftTopicTree || []}
                      loadingHistory={false}
                      showSideBar
                      onChangeTopicVersion={(targetTopicVersionId: string) =>
                        setTargetTopicVersionId(targetTopicVersionId)
                      }
                    />
                  )}

                  {selectedTab === Tabs.Settings && (
                    <TopicSettingsEditor
                      handleTopicDataChange={handleTopicDataChange}
                      topicList={draftTopicTree}
                      selectedTopic={editedTopic}
                    />
                  )}

                  {selectedTab === Tabs.Feedback && <FeedbackAnalysis />}

                  {selectedTab === Tabs.Metrics && <MetricsAnalytics />}

                  {selectedTab === Tabs.RefLinks && <ReferenceLinks />}
                </Col>
              </div>
            </Col>
          </Grid>
        </DragDropContext>
      </Col>

      <ConfirmationDialog
        isOpen={openRefreshPageDialog}
        title={t<string>('error.number-of-failed-save-requests')}
        message={t('error.number-of-failed-save-requests-message')}
        onDialogClosed={() => setOpenRefreshPageDialog(false)}
        onDialogConfirmed={() => window.location.reload()}
      />

      <PlanLimitationDialog
        accountPlan={currentPlan?.name === 'Free' ? 'Free' : 'Business'}
        open={openPlanLimitationDialog}
        textMessage={
          currentPlan?.name === 'Free' ? t('dialog-topic-limitation-free') : t('dialog-topic-limitation-business')
        }
        textTitle={t('dialog-cant-add-topics')}
        handleCloseLimitationDialog={() => {
          setOpenPlanLimitationDialog(false);
        }}
      />
    </React.Fragment>
  );
};

export default TopicContentEditorHandler;
