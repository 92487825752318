import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import moment from 'moment';
import {
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Tabs,
  Tab,
  MenuItem,
  SvgIcon,
  Breadcrumbs,
  ButtonGroup,
  Typography,
  Popover,
  Paper,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';
import { Tag, Form, Col, Row, Button } from 'antd';

import { DocumentService } from '../../../../services/document';
import { topicService } from '../../../../services/topic';
import ChangesTab from '../changes-tab';
import { emptyGuid, modifyIncomingProcessData, showSuccessMessage } from '../../../../helpers/common';
import { DocumentContext } from '../../../../contexts/document';
import { AccountContext } from '../../../../contexts/account';
import { ProfileContext } from '../../../../contexts/profile';
import { getLanguageCode, getVersionSlug } from '../../../../helpers/route';
import Reviewers from '../card/reviewers';
import ManageReviewersBox from './manage-reviewer-box';
import UncompletedTasks from './uncompleted-tasks';
import ApproveButton from './approve-button';
import Steps from '../../../account/process/steps';
import CommentsBox from '../../../comment/comments-box';
import WatchSettings from '../../../watch/watch-settings';
import TextInput from '../../../inputs/text-input';
import TextAreaInput from '../../../inputs/text-area-input';
import useGetAccesses from '../../../../api-hooks/access/useGetAccesses';
import useGetProcess from '../../../../api-hooks/process/useGetProcess';
import { Process } from '../../../../api-schemas/process';
import useUpdatePublishRequest from '../../../../api-hooks/publish-request/useUpdatePublishRequest';
import useGetPublishRequest from '../../../../api-hooks/publish-request/useGetPublishRequest';
import useSyncGoogleDocTopic from '../../../../api-hooks/topic/useSyncGoogleDocTopic';

const styles: any = () => ({
  docLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textField: {
    marginLeft: 0,
  },
});

const avatarStyle: any = {
  width: 23,
  height: 23,
  marginRight: 5,
};

function PublishPage(props) {
  const tabs: string[] = ['Overview', 'Changes'];
  const { classes } = props;

  const { accountId, documentId, publishId }: { accountId: string; documentId: string; publishId: string } =
    useParams();

  const loadTopicsRequestRef = useRef<boolean>(false);
  const publishIdRef = useRef<string>(publishId);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [topics, setTopics] = useState<any[]>([]);
  const [shouldGetPublishRequest, setShouldGetPublishRequest] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(publishIdRef.current === 'new' ? 'create' : 'view');
  const [loading, setLoading] = useState<boolean>(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState<boolean>(false);
  const [deletedTopics, setDeletedTopics] = useState<any[]>([]);
  const [notPublishedYetTopics, setNotPublishedYetTopics] = useState<any[]>([]);
  const [modifiedTopics, setModifiedTopics] = useState<any[]>([]);
  const [loadingPublish, setLoadingPublish] = useState<boolean>(false);
  const [publish, setPublish] = useState<any>(null);
  const [unModifiedPublishData, setUnModifiedPublishData] = useState<any>(null);
  const [openMenu, SetOpenMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [newReviewers, setNewReviewers] = useState<any[]>([]);
  const [firstStepApprovalUsers, setFirstStepApprovalUsers] = useState<any[]>([]);
  const [accesses, setAccesses] = useState<any[]>([]);
  const [publishRequestStatusData, setPublishRequestStatusData] = useState<any>({
    possibleToPublish: true,
    messages: [],
  });
  const [refreshWatchersList, setRefreshWatchersList] = useState<boolean>(true);
  const [publishTooltip, setPublishTooltip] = useState<boolean | undefined>(false);
  const [process, setProcess] = useState<Process>();

  const {
    documents,
    setDocuments,
    selectedLanguage,
    setSelectedLanguage,
    selectedVersion,
    setSelectedVersion,
    selectedDoc,
    setSelectedDoc,
    versions,
    setVersions,
    setDocumentTopics,
  } = useContext(DocumentContext);
  const { setUserRole } = useContext(AccountContext);
  const { profileData } = useContext(ProfileContext);

  const processId =
    mode === 'create' ? selectedDoc?.processId || selectedDoc?.account?.processId : publish?.processInstance?.processId;
  const shouldGetProcess =
    (((publish && publish.processInstance) || (mode === 'create' && selectedDoc)) &&
      mode === 'create' &&
      selectedDoc.processId !== emptyGuid() &&
      (selectedDoc.processId || selectedDoc.account.processId)) ||
    (publish && publish.processInstance && publish.processInstance.processId && Boolean(processId));

  const {
    process: serverProcess,
    isErrorLoadingProcess,
    isSuccessLoadingProcess,
  } = useGetProcess({
    processId,
    enabled: Boolean(shouldGetProcess),
  });

  const { getAccesses } = useGetAccesses(selectedDoc?.account?.id);
  const { syncGoogleDocTopic } = useSyncGoogleDocTopic();
  const { updatePublishRequest } = useUpdatePublishRequest({
    documentId,
    languageCode: publish?.languageCode || selectedLanguage?.code,
    publishRequestId: publishId,
  });
  const { getPublishRequest } = useGetPublishRequest(publishId);

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const maxNumberOfTopicsPublishedSimultanously = 100;

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-expect-error
    if (location.state?.topicId) {
      setSelectedTab(1);
    }
    // eslint-disable-next-line
  }, [location.state]);

  const documentService = new DocumentService();
  const topicAPI = new topicService();

  const extractFirstStepApprovalUsers = () => {
    const firstStepApprovalUsers: any = [];
    process?.activities?.map((activity) => {
      if (activity.step === 0 && activity.type === 'APPROVAL') {
        activity.principals?.map((principal) => {
          firstStepApprovalUsers.push({ userId: principal.id });
          return true;
        });
      }
      return true;
    });
    setNewReviewers(firstStepApprovalUsers);
    setFirstStepApprovalUsers(firstStepApprovalUsers);
  };

  useEffect(() => {
    if (isSuccessLoadingProcess) {
      setProcess(serverProcess);
    }
  }, [isSuccessLoadingProcess]);

  useEffect(() => {
    if (isErrorLoadingProcess) {
      props.enqueueSnackbar(t(`error-get-process`), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
    }
  }, [isErrorLoadingProcess]);

  useEffect(() => {
    if (process && mode === 'create' && publish && newReviewers.length === 0) {
      extractFirstStepApprovalUsers();
    }
    // eslint-disable-next-line
  }, [process]);

  useEffect(() => {
    if (publish && newReviewers.length === 0) {
      if (publish.reviews && mode !== 'create') {
        setNewReviewers(publish.reviews);
      }
    }

    if (publish && process && mode === 'view') {
      checkPublishPossibility();
    }
    // eslint-disable-next-line
  }, [publish, process, selectedDoc]);

  useEffect(() => {
    if (!selectedDoc) loadDocument();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedDoc) {
      loadPublish();
      loadVersions();
      getAccountAccesses();
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  useEffect(() => {
    if (
      publish &&
      topics.length === 0 &&
      ((selectedVersion && selectedLanguage && publishIdRef.current === 'new') || publishIdRef.current !== 'new') &&
      !loadTopicsRequestRef.current
    ) {
      loadTopics();
    }
    setLoadingPublish(false);
    // eslint-disable-next-line
  }, [publish, selectedVersion, selectedLanguage]);

  const loadDocument = async () => {
    setLoading(true);
    i18n.changeLanguage(navigator.language);
    await documentService
      .getDocument(documentId)
      .then((doc) => {
        setSelectedDoc(doc);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.getDocument`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  const loadTopics = async () => {
    if (
      (publishIdRef.current === 'new' && selectedVersion.id) ||
      (publishIdRef.current !== 'new' && publish.documentVersionId)
    ) {
      loadTopicsRequestRef.current = true;
      i18n.changeLanguage(navigator.language);
      await topicAPI
        .getTopics(
          publishIdRef.current === 'new' ? selectedVersion.id : publish.documentVersionId,
          publishIdRef.current === 'new' ? selectedLanguage.code : publish.languageCode
        )
        .then((topics) => {
          setTopics(topics);
          setDocumentTopics(topics);
          loadTopicsRequestRef.current = false;
        })
        .catch(() => {
          loadTopicsRequestRef.current = false;
          props.enqueueSnackbar(t(`error.getTopics`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }
  };

  const loadVersions = async () => {
    i18n.changeLanguage(navigator.language);
    await documentService
      .getVersions(selectedDoc.id)
      .then((versions) => {
        if (publishIdRef.current === 'new') {
          const filteredVersions = versions.filter((version) => version.slug === getVersionSlug());
          if (filteredVersions.length) {
            setSelectedVersion(filteredVersions[0]);
          }
          const filteredLanguages = selectedDoc.languages.filter(
            (language) => language.languageCode === getLanguageCode()
          );
          if (filteredLanguages.length) {
            setSelectedLanguage({
              id: filteredLanguages[0].languageCode,
              code: filteredLanguages[0].languageCode,
            });
          }
        }
        setVersions(versions);
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.getVersions`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  const loadPublish = async () => {
    setLoadingPublish(true);
    if (publishIdRef.current === 'new') {
      setPublish({
        id: emptyGuid(),
        topicIds: [],
        topics: [],
        languageCode: getLanguageCode(),
        status: 'Open',
        title: '',
        description: '',
      });
    } else {
      await getPublishRequest()
        .then((result) => {
          const publishRequest = result.data;

          setPublish(publishRequest);
          setUnModifiedPublishData(publishRequest);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getPublishRequest`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
          history.push(`/document/${selectedDoc.account.id}/${selectedDoc.id}/publish-request`);
        });
    }
  };

  const loadAndUpdateDocument = () => {
    documentService
      .getDocument(selectedDoc.id)
      .then((response) => {
        const newDocuments = [...documents];
        newDocuments[newDocuments.indexOf(selectedDoc)] = response;
        setDocuments(newDocuments);
        setSelectedDoc(response);
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.getDocument`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  const changeRequestStatus = async (status) => {
    setChangeStatusLoading(true);
    await updatePublishRequest({
      id: publishIdRef.current,
      status: status,
    })
      .then(async () => {
        SetOpenMenu(false);
        loadAndUpdateDocument();
        await loadTopics();
        if (status === 'Published' && selectedVersion) {
          await topicAPI
            .getTopics(selectedVersion.id, getLanguageCode() || selectedLanguage.code)
            .then((response) => {
              setDocumentTopics(response);
            })
            .catch(() => {});
        }
        if (status === 'Closed') {
          history.push(`/document/${selectedDoc.account.id}/${selectedDoc.id}/publish-request`);
        }
        setChangeStatusLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar(t(`error.${status}PublishRequest`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
        setChangeStatusLoading(false);
      });
  };

  const getAccountAccesses = async () => {
    if (selectedDoc && selectedDoc.account && selectedDoc.account.id) {
      getAccesses()
        .then((result) => {
          const accesses = result.data;

          if (accesses) {
            setAccesses(accesses);

            const user = accesses.find((access) => access.email === profileData?.email);

            if (user) {
              setUserRole(user.action);
            }
          }
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getAccesses`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }
  };
  const handleChange = (event, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleTitleChanged = (event) => {
    setPublish({ ...publish, title: event.target.value });
  };

  const handleDescriptionChanged = (event) => {
    setPublish({ ...publish, description: event.target.value });
  };

  const handleSave = () => {
    let newPublish = { ...publish };
    if (
      (newPublish.topics && newPublish.topics.length > maxNumberOfTopicsPublishedSimultanously) ||
      (newPublish.topicIds && newPublish.topicIds.length > maxNumberOfTopicsPublishedSimultanously)
    ) {
      props.enqueueSnackbar(t(`error.cant-publish-more-than-100`), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
      return;
    } else if (
      (!newPublish.topics || newPublish.topics.length === 0) &&
      (!newPublish.topicIds || newPublish.topicIds.length === 0)
    ) {
      props.enqueueSnackbar(t(`error.savePublishRequestWithoutTopic`), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        preventDuplicate: true,
      });
    } else {
      setChangeStatusLoading(true);
      newPublish.topicIds =
        newPublish.topics.length !== 0 ? newPublish.topics.map((topic) => topic.topicId) : newPublish.topicIds;
      newPublish = { ...newPublish, reviews: newReviewers };
      if (!newPublish.topics || newPublish.topics.length === 0) {
        newPublish.topics = topics.filter((topic) => newPublish.topicIds.find((topicId) => topicId === topic.topicId));
      }
      updatePublishRequest(newPublish)
        .then(async (response) => {
          if (mode === 'create') {
            loadAndUpdateDocument();
            await loadTopics();
            setChangeStatusLoading(false);
            setPublish(newPublish);
            history.push(`/document/${selectedDoc.account.id}/${selectedDoc.id}/publish-request/${response.id}`);
            publishIdRef.current = response.id;
            setShouldGetPublishRequest(true);
            checkPublishPossibility(response);
            showSuccessMessage(props.enqueueSnackbar, t);
            setMode('view');
          } else {
            setChangeStatusLoading(false);
            setPublish(newPublish);
            setMode('view');
          }
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.savePublishRequest`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
          setChangeStatusLoading(false);
        });
    }
  };

  useEffect(() => {
    if (shouldGetPublishRequest) {
      loadPublish();
      setShouldGetPublishRequest(false);
    }
    // eslint-disable-next-line
  }, [shouldGetPublishRequest]);

  const handlePublishButtonClicked = () => {
    const hasGoogleDocTopic: boolean = topics.find((topic) => topic.googleDriveId != null);

    const googleDriveIds: string[] = topics
      .map((topic) => topic.googleDriveId)
      .filter((googleDriveId) => googleDriveId != null);

    if (hasGoogleDocTopic) {
      setChangeStatusLoading(true);

      syncGoogleDocTopic({
        accountId: accountId,
        googleDriveIds: googleDriveIds,
      })
        .then(() => {
          changeRequestStatus(publish.status === 'Closed' ? 'Open' : 'Published');
          setPublishTooltip(false);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.syncingGoogleDocTopics`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
          setChangeStatusLoading(false);
        });
    } else {
      changeRequestStatus(publish.status === 'Closed' ? 'Open' : 'Published');
      setPublishTooltip(false);
    }
  };

  const renderPublish = () => {
    return (
      publish && (
        <div style={{ margin: '5px 0' }}>
          {selectedTab === 0 && (
            <List>
              <ListItem style={{ padding: 0 }}>
                <Row style={{ padding: 8, width: '100%' }}>
                  <Col xs={24} sm={24}>
                    <Fragment>
                      <Paper variant="outlined" square style={{ margin: 'auto' }}>
                        <Box display="flex" flexDirection="column" style={{ margin: '10px', marginBottom: 0 }}>
                          <Box display="flex" flexDirection="column">
                            {mode === 'view' ? (
                              <Fragment>
                                <div className="label" style={{ fontWeight: 'bold' }}>
                                  {'Title'}
                                </div>
                                <span style={{ marginBottom: 5 }}>{publish.title || 'Untitled'}</span>
                              </Fragment>
                            ) : (
                              <Form layout="vertical">
                                <TextInput
                                  className="mb-3"
                                  field="Title"
                                  label="Title"
                                  placeholder={'Untitled'}
                                  value={publish.title}
                                  onChange={handleTitleChanged}
                                  required={false}
                                  showError={false}
                                />
                              </Form>
                            )}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{
                            margin: '10px',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <Box display="flex" flexDirection="column">
                            {mode === 'view' ? (
                              <Fragment>
                                <div className="label" style={{ fontWeight: 'bold' }}>
                                  {'Description'}
                                </div>
                                <span style={{ marginTop: '10px' }}>{publish.description}</span>
                              </Fragment>
                            ) : (
                              <Form layout="vertical">
                                <TextAreaInput
                                  className="mb-3"
                                  autoSize={{ minRows: 3, maxRows: 3 }}
                                  field="Description"
                                  label="Description"
                                  value={publish.description}
                                  onChange={handleDescriptionChanged}
                                  required={false}
                                  showError={false}
                                />
                              </Form>
                            )}
                          </Box>
                        </Box>
                        {(mode === 'edit' || mode === 'create') && accesses && accesses.length !== 0 && (
                          <Box>
                            <ManageReviewersBox
                              firstStepApprovalUsers={firstStepApprovalUsers}
                              process={process}
                              mode={mode}
                              accesses={accesses}
                              newReviewers={newReviewers}
                              setNewReviewers={setNewReviewers}
                              publish={publish}
                            />
                          </Box>
                        )}
                      </Paper>
                      <Divider light></Divider>
                      <Paper variant="outlined" square style={{ margin: 'auto', marginTop: '10px' }}>
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 8,
                          }}
                        >
                          <Col xs={16} sm={16}>
                            {' '}
                            <div style={{ margin: '10px' }}>
                              <b>{'Document'}</b>
                              <div>
                                <span>{selectedDoc ? selectedDoc.title : 'No title'}</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={8} sm={8}>
                            <div
                              style={{
                                margin: '10px',
                                float: 'right',
                                display: 'flex',
                              }}
                            >
                              <b>{'Variant: '}</b>
                              <span style={{ marginLeft: 5 }}>{versionTitle || <Skeleton width={15} />}</span>
                            </div>
                            <div style={{ margin: '10px', float: 'right' }}>
                              <b>{'Language: '}</b>
                              <span>{publish.languageCode}</span>
                            </div>
                          </Col>
                        </Row>
                      </Paper>
                      {mode === 'view' && process && (
                        <div>
                          <Divider light></Divider>
                          <Paper
                            variant="outlined"
                            square
                            style={{
                              margin: 'auto',
                              marginTop: '10px',
                              display: 'inline-flex',
                            }}
                          >
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: 8,
                              }}
                            >
                              <Steps
                                accesses={accesses}
                                processData={modifyIncomingProcessData(process)}
                                processInstance={publish.processInstance}
                                viewMode={true}
                              />
                            </Col>
                          </Paper>
                        </div>
                      )}
                      {publish && mode !== 'create' && (
                        <Grid className={classes.topicEditor} item xs={12}>
                          <CommentsBox fullWidth={true} channelId={publishId} channelType="Publishes" />
                        </Grid>
                      )}
                    </Fragment>
                  </Col>
                </Row>
              </ListItem>
            </List>
          )}

          {topics.length > 0 && (
            <ChangesTab
              hidden={selectedTab !== 1}
              topics={topics}
              publish={publish}
              setPublish={setPublish}
              accesses={accesses}
              mode={mode}
              setModifiedTopics={(topics) => setModifiedTopics(topics)}
              setDeletedTopics={(topics) => setDeletedTopics(topics)}
              setNotPublishedYetTopics={(topics) => setNotPublishedYetTopics(topics)}
            ></ChangesTab>
          )}
        </div>
      )
    );
  };

  const publishSkeleton = (skeletonList) => {
    return (
      <List>
        <Divider light />
        {skeletonList.map((item) => {
          return (
            <Fragment key={item}>
              <span style={{ margin: '5px 0' }}>
                <ListItem>
                  <Skeleton width={40} height={40}></Skeleton>
                  <Skeleton width={Math.random() * 30 + 5 + '%'} style={{ marginLeft: '10px' }}></Skeleton>
                  <ListItemSecondaryAction>
                    <IconButton aria-label={`Edit`}>
                      <Skeleton variant="circular" width={40} height={40}></Skeleton>
                    </IconButton>
                    <IconButton aria-label={`Remove`}>
                      <Skeleton variant="circular" width={40} height={40}></Skeleton>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </span>
            </Fragment>
          );
        })}
      </List>
    );
  };

  const format: any = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  let createdBy;
  if (accesses.length > 0 && publishIdRef.current !== 'new' && publish) {
    createdBy = accesses.find((access) => access.principalId === publish.createdBy);
  }

  const versionTitle: string =
    versions.length === 0 || !publish
      ? ''
      : publishIdRef.current !== 'new' && versions.find((version) => version.id === publish.documentVersionId)
        ? versions.find((version) => version.id === publish.documentVersionId).title
        : versions.find((version) => version.slug === getVersionSlug()) && publishIdRef.current === 'new'
          ? versions.find((version) => version.slug === getVersionSlug()).title
          : 'Deleted';
  const versionSlug: string =
    versions.length === 0 || !publish
      ? ''
      : publishIdRef.current !== 'new' && versions.find((version) => version.id === publish.documentVersionId)
        ? versions.find((version) => version.id === publish.documentVersionId).slug
        : versions.find((version) => version.slug === getVersionSlug()) && publishIdRef.current === 'new'
          ? getVersionSlug()
          : 'Deleted';

  const contents =
    loading || loadingPublish ? (
      <span style={{ width: '100%', height: '500px' }}> {publishSkeleton(['1'])}</span>
    ) : (
      renderPublish()
    );

  const checkPublishPossibility = (incomingPublish: any = null) => {
    const publishRequestStatusData: any = {
      possibleToPublish: undefined,
      messages: [],
    };
    const publishData = incomingPublish || publish;
    publishData.processInstance?.activityInstances?.map((activityInstance) => {
      const filteredActivities = process?.activities?.filter((activity) => activity.id === activityInstance.activityId);
      if (filteredActivities && filteredActivities.length) {
        const currentActivity = filteredActivities[0];
        if (activityInstance.activityStatus !== 'COMPLETED') {
          publishRequestStatusData.possibleToPublish = false;
          publishRequestStatusData.messages.push({
            type:
              activityInstance.activityStatus === 'OPEN' || activityInstance.activityStatus === 'WAITING'
                ? 'warning'
                : 'error',
            text: '"' + currentActivity.title + '" rule in step ' + (currentActivity.step + 1) + ' should be passed.',
          });
        }
      }
      return true;
    });
    if (publishRequestStatusData.possibleToPublish === undefined) {
      publishRequestStatusData.possibleToPublish = true;
    }
    setPublishRequestStatusData(publishRequestStatusData);
  };

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Box style={{ margin: '30px' }} border={0}>
        {publish && selectedDoc ? (
          <Breadcrumbs>
            <Link
              className={classes.pointerCursor}
              color={'textPrimary'}
              style={{ fontSize: 'small' }}
              to={{
                pathname: '/',
                state: {
                  accountId,
                },
              }}
            >
              {' '}
              {selectedDoc.account.title}
            </Link>
            <Link
              className={classes.pointerCursor}
              color={'textPrimary'}
              style={{ fontSize: 'small' }}
              to={`/document/${selectedDoc.account.slug}/${selectedDoc.slug}/content?lang=${publish.languageCode}&ver=${versionSlug}`}
            >
              {selectedDoc.title}
            </Link>
            <Link
              className={classes.pointerCursor}
              color={'textPrimary'}
              style={{ fontSize: 'small' }}
              to={`/document/${selectedDoc.account.slug}/${selectedDoc.slug}/publish-request`}
            >
              Publish Requests
            </Link>
            <span
              className={classes.pointerCursor}
              style={{
                fontSize: 'small',
                color: '#000',
              }}
            >
              {publish.title || 'Untitled'}
            </span>
          </Breadcrumbs>
        ) : (
          <Skeleton height={20} width={300}></Skeleton>
        )}

        {mode !== 'view' ? (
          <span style={{ marginTop: '8px', fontSize: '14px' }}>{t('publish-request-description')}</span>
        ) : null}

        <CardHeader
          style={{ margin: '10px 7px 0 0', padding: 0 }}
          action={
            <div style={{ float: 'right' }}>
              {publish && publishIdRef.current !== 'new' ? (
                <div>
                  <ListItem
                    style={{
                      paddingTop: '0px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }}
                  >
                    <Tooltip
                      title={'At ' + new Date(publish.createDateTime).toLocaleString('en-US', format)}
                      arrow
                      placement="top"
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '13px' }}>
                          {'Created ' + moment(new Date(publish.createDateTime), 'YYYYMMDDLL').fromNow() + ' by '}
                        </span>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '13px',
                                marginLeft: 5,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {createdBy ? (
                                createdBy.photoUrl ? (
                                  <Avatar
                                    variant="square"
                                    alt={createdBy.displayName || createdBy.email}
                                    src={createdBy.photoUrl}
                                    style={avatarStyle}
                                  />
                                ) : (
                                  <Avatar variant="square" style={avatarStyle}>
                                    {(createdBy.displayName || createdBy.email)[0]}
                                  </Avatar>
                                )
                              ) : (
                                <Avatar variant="square" style={avatarStyle}>
                                  D
                                </Avatar>
                              )}
                              {createdBy
                                ? createdBy.displayName
                                  ? createdBy.displayName
                                  : createdBy.email
                                : accesses.length > 0
                                  ? 'Deleted user'
                                  : 'Loading...'}
                            </span>
                          }
                        ></ListItemText>
                      </span>
                    </Tooltip>
                  </ListItem>
                </div>
              ) : publishIdRef.current === 'new' ? null : (
                <Skeleton height={20} width={200}></Skeleton>
              )}
            </div>
          }
          title={
            publish ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{publish.title || 'Untitled'}</span>{' '}
                {publishIdRef.current !== 'new' && <Tag style={{ marginLeft: '10px' }}>{publish.status}</Tag>}
              </div>
            ) : (
              <Skeleton height={40} width={150}></Skeleton>
            )
          }
        />
        <CardContent style={{ padding: '10px 0', boxShadow: '0' }}>
          <Fragment>
            <Row style={{ padding: 16 }}>
              <Col xs={10} sm={10}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={tabs[0]} value={0} style={{ textTransform: 'initial', textAlign: 'left' }} />

                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Changes</span>

                        <span style={{ marginLeft: 5, display: 'flex' }}>
                          {deletedTopics.length > 0 && (
                            <Tooltip title={'Deleted topics'}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: 10,
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: 'red',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM6 7h4a1 1 0 0 1 .117 1.993L10 9H6a1 1 0 0 1-.117-1.993L6 7h4-4z"></path>
                                </SvgIcon>
                                <span>{deletedTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                          {modifiedTopics.length > 0 && (
                            <Tooltip title={'Modified topics'}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: 10,
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: '#fc9403',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path>
                                </SvgIcon>
                                <span>{modifiedTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                          {notPublishedYetTopics.length > 0 && (
                            <Tooltip title={'New topics'}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <SvgIcon
                                  style={{
                                    color: 'green',
                                    marginTop: '7px',
                                    marginRight: '-4px',
                                  }}
                                >
                                  <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 5a1 1 0 0 1 .993.883L9 6v1h1a1 1 0 0 1 .117 1.993L10 9H9v1a1 1 0 0 1-1.993.117L7 10V9H6a1 1 0 0 1-.117-1.993L6 7h1V6a1 1 0 0 1 1-1z"></path>
                                </SvgIcon>
                                <span>{notPublishedYetTopics.length}</span>
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                    }
                    value={1}
                    style={{ textTransform: 'initial', textAlign: 'left' }}
                  />
                </Tabs>
              </Col>

              <Col style={{ justifyContent: 'flex-end' }} xs={14} sm={14}>
                <div
                  style={{
                    marginTop: '12px',
                    float: 'right',
                    marginBottom: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {mode === 'view' && publish && publish.id !== emptyGuid() && (
                    <WatchSettings
                      entityId={publish.id}
                      entityType="Publishes"
                      hasMargin={true}
                      refreshWatchersList={refreshWatchersList}
                      setRefreshWatchersList={setRefreshWatchersList}
                    />
                  )}
                  {mode === 'view' && <ApproveButton publish={publish} setProcess={setProcess} />}
                  {publishRequestStatusData.messages.length !== 0 && mode === 'view' && (
                    <UncompletedTasks messages={publishRequestStatusData.messages} />
                  )}
                  {accesses && publish && mode === 'view' && (
                    <Reviewers accesses={accesses} reviewers={publish.reviews} smallSize={false} />
                  )}
                  {mode === 'view' ? (
                    <div>
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="split button"
                        style={{ borderRadius: 0 }}
                      >
                        <Tooltip
                          title={
                            publish && publish.status === 'Open' && publishRequestStatusData.possibleToPublish !== true
                              ? t<string>('disable-publish-help')
                              : publish && publish.status === 'Closed'
                                ? 'Reopen publish request'
                                : 'Publish publish request'
                          }
                          style={{ margin: '5px 20px 0 0' }}
                          open={publishTooltip}
                          onMouseEnter={() => setPublishTooltip(true)}
                          onMouseLeave={() => setPublishTooltip(false)}
                        >
                          <Button
                            variant="filled"
                            size="small"
                            type="primary"
                            aria-label="Edit publish request"
                            disabled={
                              changeStatusLoading ||
                              (publish &&
                                publish.status === 'Open' &&
                                publishRequestStatusData.possibleToPublish !== true)
                                ? true
                                : publish
                                  ? publish.status !== 'Open' && publish.status !== 'Closed'
                                  : false
                            }
                            onClick={handlePublishButtonClicked}
                            style={{
                              minWidth: '40px',
                              borderRadius: 0,
                              pointerEvents: 'auto',
                            }}
                          >
                            {changeStatusLoading ? (
                              <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} />
                            ) : !publish ? null : publish.status === 'Closed' ? (
                              'Reopen'
                            ) : (
                              'Publish'
                            )}
                          </Button>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          size="small"
                          aria-owns={'options-menu'}
                          onClick={(event) => {
                            SetOpenMenu(true);
                            setAnchorEl(event.currentTarget);
                          }}
                          aria-haspopup="true"
                          // className={classes.btnMoreAddTopic}
                          aria-label="More options"
                          style={{ borderRadius: 0 }}
                        >
                          <ArrowDropDownIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                      <Popover
                        id="add-topic-options"
                        anchorEl={anchorEl}
                        open={openMenu}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        onClose={() => {
                          SetOpenMenu(false);
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem
                          className={classes.menuItem}
                          style={{ borderRadius: 0 }}
                          onClick={() => {
                            SetOpenMenu(false);
                            setAnchorEl(null);
                            setMode('edit');
                          }}
                        >
                          <EditIcon className={classes.actionIcon} />
                          <Typography variant="inherit">Edit</Typography>
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          style={{ borderRadius: 0 }}
                          onClick={() => changeRequestStatus('Closed')}
                          disabled={publish ? publish.status !== 'Open' : false}
                        >
                          <CloseIcon className={classes.actionIcon} />
                          <Typography variant="inherit">Close</Typography>
                        </MenuItem>
                      </Popover>
                    </div>
                  ) : mode === 'create' ? (
                    <div>
                      <Tooltip title={'Create publish request'}>
                        <Button
                          variant="filled"
                          type="primary"
                          aria-label="Save publish request"
                          onClick={() => handleSave()}
                        >
                          {changeStatusLoading ? (
                            <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} />
                          ) : (
                            'Create'
                          )}
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Cancel'}>
                        <Button
                          variant="outlined"
                          aria-label="Cancel"
                          onClick={() =>
                            history.push(
                              `/document/${selectedDoc.accountId}/${selectedDoc.id}/content?lang=${selectedDoc.defaultLanguageCode}&ver=${selectedDoc.defaultDocumentVersionSlug}`
                            )
                          }
                          style={{ marginLeft: '5px' }}
                        >
                          Cancel
                        </Button>
                      </Tooltip>
                    </div>
                  ) : (
                    <div>
                      <Tooltip title={'Save publish request'}>
                        <Button
                          // variant="contained"
                          variant="outlined"
                          size="small"
                          color="primary"
                          aria-label="Save publish request"
                          onClick={() => handleSave()}
                        >
                          {changeStatusLoading ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Save'}
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Cancel'}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          aria-label="Cancel"
                          onClick={() => {
                            SetOpenMenu(false);
                            setMode('view');
                            setPublish(unModifiedPublishData);
                          }}
                          style={{ marginLeft: '5px' }}
                        >
                          Cancel
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Divider light />
          </Fragment>
          {contents}
        </CardContent>
      </Box>
    </div>
  );
}

export default withStyles(styles)(withSnackbar(PublishPage));
