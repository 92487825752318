import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Comment } from '../../api-schemas/comment';

const createCommentRequest = ({
  threadId,
  data,
}: {
  threadId: string;
  data: Comment;
}) => {
  const body = JSON.stringify(data);

  return axios
    .post(`/api/thread/${threadId}/comment`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateComment({
  channelId,
  threadId,
}: {
  channelId: string;
  threadId: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createComment,
    isPending: isCreatingComment,
    isSuccess: isSuccessCreatingComment,
  } = useMutation({
    mutationFn: createCommentRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['threads', channelId] });
      queryClient.invalidateQueries({ queryKey: ['thread', threadId] });
    },
  });

  return {
    createComment,
    isCreatingComment,
    isSuccessCreatingComment,
  };
}
