import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import '../styles.css';
import { Col } from 'antd';

const useStyles: any = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: `${theme.spacing(1)}!important`,
      marginBottom: `${theme.spacing(1)}!important`,
      width: theme.spacing(16),
      height: 'auto',
    },
  },
  filterPaper: {
    width: '100%!important',
  },
  formControl: {
    margin: `${theme.spacing(1)}!important`,
    minWidth: '120px!important',
    width: '100%!important',
    display: 'flex',
  },
  label: {
    position: 'relative',
  },
  selectEmpty: {
    marginTop: 0,
  },
  selectPartent: {
    display: 'inline-block',
    padding: '0 20px 0 3px',
    width: '100%',
  },
  selectBox: {
    width: '100%!important',
    marginTop: '0!important',
  },
  borderRight: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #b2b2b2',
    },
  },
  buttonBox: {
    marginTop: 10,
    textAlign: 'center',
  },
}));

const FilterSkeletonLoading = () => {
  const classes = useStyles();
  const loopArray = [0, 1, 2, 3, 4, 5];

  return (
    <div className={classes.root} id="filterBox" style={{ width: '100%' }}>
      <Paper elevation={2} className={classes.filterPaper}>
        <Col xs={24} style={{ margin: '10px 0' }}>
          {loopArray.map((index) => (
            <Col
              key={index}
              xs={24}
              md={8}
              className={
                classes.selectPartent +
                (index % 3 !== 2 ? ' ' + classes.borderRight : '')
              }
            >
              <Skeleton width={100} height={25} />
              <Skeleton style={{ width: '100%' }} height={25} />
            </Col>
          ))}
          <Col xs={24} className={classes.buttonBox}>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={100}
              height={25}
            />
          </Col>
        </Col>
      </Paper>
    </div>
  );
};

export default FilterSkeletonLoading;
