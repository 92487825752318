import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { withSnackbar } from 'notistack';
import EmailAndPasswordConfiguration from './email-password';
import LinkAndUnlinkConfiguration from './link-unlink';
import firebaseApp from '../../../helpers/firebase';

const ProfileAuthConfigurationEditor = (props) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <EmailAndPasswordConfiguration
        user={user}
        profileData={props.profileData}
      />
      <Divider />
      <LinkAndUnlinkConfiguration user={user} />
    </React.Fragment>
  );
};

export default withSnackbar(ProfileAuthConfigurationEditor);
