export const plansData = [
  {
    name: 'Free',
    title: 'Get your business up and running',
    price: '$0',
    priceLabel: 'Free forever',
    topFeatures: [
      '40 Topics',
      '1 GB Storage',
      '1 Document',
      '1 Variant',
      'Custom Domain',
      'SEO metrics',
      'Readability metrics',
      'Menu builder',
    ],
  },
  {
    name: 'Business',
    title: '2000 Topics, Private Document',
    price: '$40',
    priceLabel: '$8 Per user / Per month',
    topFeatures: [
      'Free Features +',
      '5 Free Users Included',
      'Unlimited documents',
      'Unlimited variants',
      '2000 Topics',
      '5 GB Storage',
      'Private Documents',
      'AI Answer Generator',
    ],
  },
  {
    name: 'Enterprise',
    title: 'SAML, Workflow, Fully White-labeled',
    price: '$375',
    priceLabel: '$25 Per user / Per month',
    topFeatures: [
      'Business Features +',
      '15 Free Users Included',
      'Unlimited topics',
      '20 GB Storage',
      'SAML (SSO)',
      'Custom workflow',
      'AI Answer Generator with GPT-4',
    ],
  },
  {
    name: 'Enterprise Plus',
    title: 'Unlimited users, Unlimited storage',
    price: 'Custom',
    priceLabel: 'Contact us',
    topFeatures: [
      'Enterprise features +',
      'Unlimited users',
      'Unlimited storage',
      'Dedicated Customer Success Manager',
      'Customized viewer design',
    ],
  },
];

export const allFeatures = [
  {
    title: 'Workflow and Review',
    data: [
      {
        type: 'Boolean',
        name: 'Commenting',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Publish request',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Optional approval',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Custom workflow designer',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'Permissions & Access Management',
    data: [
      {
        type: 'Boolean',
        name: 'Role-Based Permissions',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'SAML',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'Branding',
    data: [
      {
        type: 'Boolean',
        name: 'Company logo',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Document logo',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Favicon',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Custom Domain',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Completely white labeled',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'Translation',
    data: [
      {
        type: 'Boolean',
        name: 'Multilingual user documents',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Machine Translation',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'History',
    data: [
      {
        type: 'Boolean',
        name: 'Unlimited publish changes',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Recent draft changes',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Unlimited draft changes',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'Support',
    data: [
      {
        type: 'Boolean',
        name: 'Priority Support',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Dedicated Customer Success Manage',
        free: false,
        business: false,
        enterprise: false,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'AI-Powered Solution',
    data: [
      {
        type: 'Boolean',
        name: 'AI Answer Generator',
        free: false,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'AI Answer Generator with GPT-4',
        free: false,
        business: false,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  {
    title: 'Other',
    data: [
      {
        type: 'Boolean',
        name: 'Viewer feedback',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'Search Engine',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
      {
        type: 'Boolean',
        name: 'User document templates',
        free: true,
        business: true,
        enterprise: true,
        enterprisePlus: true,
      },
    ],
  },
  // {
  //   type: 'Numerical',
  //   data: [
  //     {
  //       name: 'Document',
  //       free: '1',
  //       business: 'Unlimited'
  //     },
  //     {
  //       name: 'Topic',
  //       free: '50',
  //       business: '2000'
  //     },
  //     {
  //       name: 'Document',
  //       free: '1',
  //       business: 'Unlimited'
  //     },
  //     {
  //       name: 'Variant',
  //       free: '1',
  //       business: 'Unlimited'
  //     }
  //   ]
  // },
  // {
  //   type: 'Boolean',
  //   data: [
  //     {
  //       name: 'Multi language',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Custom logo',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Custom theme color',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Powerful rich text editor',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Custom styles',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'YouTube integration',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Vimeo integration',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Image',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Paste formatting',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Pasted images auto-upload',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Paste from Ms Word',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Paste from Google Docs',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Table editing',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Multiple undo / redo',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Convert single topic to PDF',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Human-readable document URLs',
  //       free: true,
  //       business: true
  //     },
  //     {
  //       name: 'Human-readable topic URLs',
  //       free: true,
  //       business: true
  //     },
  //   ]
  // }
];
