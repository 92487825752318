import React, { FC, useContext, useState } from 'react';
import type { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import TextInput from '../../inputs/text-input';
import {
  convertToSlug,
  createNewAccountDraftTopic,
} from '../../../helpers/common';
import { getLanguageCode } from '../../../helpers/route';
import { AccountContext } from '../../../contexts/account';
import useSaveTopic from '../../../api-hooks/topic/useSaveTopic';
import { FolderContext } from '../../../contexts/folder';
import useGetTopics from '../../../api-hooks/topic/useGetTopics';
import { TopicContext } from '../../../contexts/topic';

type NewTopicDialogPropsType = {
  isGoogleDocType: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

const NewTopicDialog: FC<NewTopicDialogPropsType> = ({
  setOpenDialog,
  isGoogleDocType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedAccount } = useContext(AccountContext);
  const { selectedFolderId } = useContext(FolderContext);
  const { setIsModifyingDoclessTopic } = useContext(TopicContext);

  const { getTopics } = useGetTopics({
    accountId: selectedAccount?.id || '',
    parentFolderId: selectedFolderId,
    enabled: Boolean(selectedAccount?.id),
  });

  const [topic, setTopic] = useState(
    createNewAccountDraftTopic({
      languageCode: getLanguageCode() || selectedAccount.defaultLanguageCode,
      accountId: selectedAccount.id,
      contentType: isGoogleDocType ? 'GoogleDoc' : 'CKEditor5',
    })
  );

  const { saveTopic, isSavingTopic } = useSaveTopic({
    languageCode: topic.languageCode,
    onErrorMessageCallback: (error) => {
      if (error == 'Duplicate')
        enqueueSnackbar(t('error.Duplicate'), {
          variant: 'error',
        });
      else
        enqueueSnackbar(t('error.unknown'), {
          variant: 'error',
        });
    },
  });

  const handleTopicTitleChange = (args: ChangeEvent<HTMLInputElement>) => {
    setTopic({
      ...topic,
      title: args.target.value,
      body: `<h1>${args.target.value}</h1><p></p>`,
      slug: convertToSlug(args.target.value),
    });
  };

  const handleTopicSlugChange = (args: ChangeEvent<HTMLInputElement>) => {
    setTopic({
      ...topic,
      slug: convertToSlug(args.target.value),
    });
  };

  const handleSaveTopic = () => {
    //eslint-disable-next-line
    //@ts-expect-error
    saveTopic({ ...topic, folderId: selectedFolderId }).then(async () => {
      setIsModifyingDoclessTopic(true);
      await getTopics();

      history.push(
        `/topic/${selectedAccount.id}/${topic.topicId}/content?lang=${topic.languageCode}`
      );

      setOpenDialog(false);
    });
  };

  return (
    <Modal
      title={isGoogleDocType ? t('new-google-doc') : t('new-topic')}
      open={true}
      okText={t('save')}
      onOk={handleSaveTopic}
      onCancel={() => setOpenDialog(false)}
      okButtonProps={{ disabled: isSavingTopic }}
      cancelButtonProps={{ disabled: isSavingTopic }}
      className="new-topic-modal"
    >
      <TextInput
        field={'title'}
        label={t('title')}
        value={topic.title}
        onChange={handleTopicTitleChange}
        style={{ marginBottom: 10 }}
      />
      <TextInput
        field={'title'}
        label={t('slug')}
        value={topic.slug}
        onChange={handleTopicSlugChange}
      />
    </Modal>
  );
};

export default NewTopicDialog;
