import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ProcessListItem from '../list-item';
import { useEffect } from 'react';
import PlanLimitationDialog from '../../../plan-limitation-dialog';
import { Col } from 'antd';

let currentProcesses: any = null;
let currentPlan: any = null;

const AccountProcessesSettings = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (
      props.processes &&
      props.plan &&
      props.processes !== currentProcesses &&
      props.plan !== currentPlan
    ) {
      setLoading(false);
      currentProcesses = props.processes;
      currentPlan = props.plan;
      if (props.plan.name === 'Free') {
        setOpenDialog(true);
      } else {
        setOpenDialog(false);
      }
    }
    // eslint-disable-next-line
  }, [props.processes, props.plan]);

  useEffect(() => {
    return () => {
      currentProcesses = null;
      currentPlan = null;
    };
  }, []);

  return (
    <Col xs={24}>
      <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          style={{ margin: '10px 0 15px 0' }}
          component="h1"
          variant="h6"
        >
          {t('processes-settings-title')}
        </Typography>
        {!loading && props.plan.features.process !== false && (
          <Button
            style={{ margin: '10px 0 15px 0' }}
            variant="contained"
            color="primary"
            onClick={() => history.push('/org/settings/processes/manage')}
          >
            {t('new')}
          </Button>
        )}
      </Col>
      <Col xs={24} style={{ textAlign: 'center' }}>
        {!loading && props.plan.features.process === false && (
          <Col xs={24}>
            {t('approval-enterprise-text')}
            <PlanLimitationDialog
              accountPlan={props.plan.name}
              open={openDialog}
              textMessage={
                props.plan.name === 'Free'
                  ? t('approval-enterprise-text-for-free')
                  : t('approval-enterprise-text-for-business')
              }
              textTitle={t('dialog-cant-manage-processes')}
              handleCloseLimitationDialog={() => {
                setOpenDialog(false);
              }}
            />
          </Col>
        )}
        {loading && <CircularProgress />}
        {!loading &&
          props.plan.features.process !== false &&
          props.processes &&
          props.processes.map((process) => (
            <ProcessListItem
              key={process.id}
              process={process}
              account={props.accountConfiguration}
              setParentLoading={setLoading}
            />
          ))}
        {!loading &&
          props.plan.features.process !== false &&
          props.processes.length === 0 && <Col xs={24}>{t('no-process')}</Col>}
      </Col>
    </Col>
  );
};

export default AccountProcessesSettings;
