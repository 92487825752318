import React, {
  FC,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Table, Tooltip } from 'antd';
import type { TableColumnsType } from 'antd';
import { Avatar } from '@mui/material';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { TiDocumentText } from 'react-icons/ti';
import { Link } from 'react-router-dom';

import { DraftTopic } from '../../../api-schemas/topic';
import { makeLightColor } from '../../../helpers/common';
import WatchSettings from '../../../components/watch/watch-settings';
import useGetTopics from '../../../api-hooks/topic/useGetTopics';
import { AccountContext } from '../../../contexts/account';
import TopicListItemMenu from '../../../components/topic/list/list-item-menu';
import { FolderContext } from '../../../contexts/folder';
import { TopicContext } from '../../../contexts/topic';

const TopicList: FC = () => {
  const { selectedFolderId } = useContext(FolderContext);
  const { selectedAccount, selectedLanguage } = useContext(AccountContext);
  const { isModifyingDoclessTopic, setIsModifyingDoclessTopic } =
    useContext(TopicContext);

  const { t } = useTranslation();

  const [refreshWatchersList, setRefreshWatchersList] = useState<boolean>(true);
  const [topics, setTopics] = useState<DraftTopic[]>([]);
  const [isIntersectingTableLoading, setIsIntersectingTableLoading] =
    useState(true);

  const tableLoadingRef = useRef<HTMLDivElement | null>(null);
  const lastTopicIdRef = useRef<string>();
  const existingFolderId = useRef<string>(selectedFolderId || '');

  const { topics: paginatedTopics, isLoadingTopics } = useGetTopics({
    accountId: selectedAccount?.id || '',
    parentFolderId: selectedFolderId,
    lastTopicId: lastTopicIdRef.current,
    enabled: Boolean(selectedAccount?.id) && isIntersectingTableLoading,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersectingTableLoading(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (tableLoadingRef.current) {
      observer.observe(tableLoadingRef.current);
    }

    return () => observer.disconnect();
  }, [tableLoadingRef.current]);

  useEffect(() => {
    if (selectedAccount) {
      setTopics([]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (
      paginatedTopics.length > 0 &&
      !isLoadingTopics &&
      topics.length !== paginatedTopics.length
    ) {
      if (topics.length !== 0) {
        setTopics((prev) => [...prev, ...paginatedTopics]);
      } else {
        setTopics(paginatedTopics);
      }
    }
  }, [paginatedTopics, isLoadingTopics]);

  useEffect(() => {
    if (
      paginatedTopics.length > 0 &&
      !isIntersectingTableLoading &&
      !isLoadingTopics
    ) {
      const lastTopic = paginatedTopics.at(-1);

      if (lastTopic) {
        lastTopicIdRef.current = lastTopic.topicId || lastTopic.id;
      }
    }
  }, [paginatedTopics, isIntersectingTableLoading]);

  useEffect(() => {
    if (isModifyingDoclessTopic || existingFolderId.current !== selectedFolderId) {
      lastTopicIdRef.current = undefined;
      setIsModifyingDoclessTopic(false);
      setTopics([]);
      existingFolderId.current = selectedFolderId || '';
    }
    
  }, [isModifyingDoclessTopic, selectedFolderId]);

  const columns: TableColumnsType<DraftTopic> = useMemo(
    () => [
      {
        title: 'Logo',
        key: 'logo',
        width: '5%',
        align: 'center',
        render: (_, record) => {
          return (
            <Avatar
              variant="rounded"
              style={{
                color: 'inherit',
                backgroundColor: makeLightColor(record.topicId || record.id),
              }}
            >
              {record.title ? record.title[0] : 'T'}
            </Avatar>
          );
        },
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        width: '25%',
        align: 'center',
        render: (_, record) => {
          const contentInCurrentLanguage = record.languages.find(
            (language) => language.languageCode === selectedLanguage
          );

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Link
                to={`/topic/${record.accountId}/${record.topicId || record.id}/content?lang=${selectedLanguage}`}
                style={{
                  marginRight: 5,
                  color: contentInCurrentLanguage ? '#000' : 'gray',
                }}
              >
                {' '}
                {contentInCurrentLanguage?.title ||
                  record.languages[0]?.title ||
                  ''}{' '}
              </Link>
            </div>
          );
        },
      },
      {
        title: 'Type',
        key: 'type',
        width: '5%',
        align: 'center',
        render: () => {
          return (
            <Tooltip title={t('topic')}>
              <TiDocumentText
                style={{
                  width: 18,
                  height: 18,
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Metrics',
        key: 'metrics',
        width: '10%',
        align: 'center',
        render: () => {
          return <span>-</span>;
        },
      },
      {
        title: 'Languages',
        key: 'languages',
        width: '5%',
        align: 'center',
        render: (_, record) => {
          const languages = record.languages.map(
            (language) => language.languageCode
          );

          return (
            <Tooltip
              title={'Languages: ' + languages.join(', ')}
              placement="top"
            >
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <GTranslateIcon
                  style={{ marginRight: 5, width: 18, height: 18 }}
                />

                <span>{languages.length}</span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Publish Requests',
        key: 'publishRequests',
        width: '10%',
        align: 'center',
        render: () => {
          return <span>-</span>;
        },
      },
      {
        title: 'Create date time',
        key: 'createDateTime',
        width: '15%',
        align: 'center',
        render: (_, record) => {
          return (
            <Tooltip
              title={new Date(record.createDateTime).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            >
              <span>
                {'Created ' +
                  moment(
                    new Date(record.createDateTime),
                    'YYYYMMDDLL'
                  ).fromNow()}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Privacy',
        key: 'privacy',
        width: '5%',
        align: 'center',
        render: () => {
          return <span>-</span>;
        },
      },
      {
        title: '',
        key: 'actions',
        width: '15%',
        align: 'center',
        render: (_, record) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <WatchSettings
                entityId={record.topicId || record.id}
                entityType={'Topics'}
                hasMargin={false}
                refreshWatchersList={refreshWatchersList}
                setRefreshWatchersList={setRefreshWatchersList}
              />

              <TopicListItemMenu topic={record} />
            </div>
          );
        },
      },
    ],
    [selectedLanguage]
  );

  return (
    <div style={{ width: '80%' }}>
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #bebebe8a',
          marginTop: 10,
          marginBottom: 15,
          paddingBottom: 5,
          fontSize: 16,
        }}
      >
        {t('topics')}
      </div>

      <Table
        columns={columns}
        dataSource={topics}
        pagination={false}
        style={{ width: '100%' }}
        loading={!selectedAccount || isLoadingTopics}
      />

      <div style={{ width: 5, height: 5 }} ref={tableLoadingRef} />
    </div>
  );
};

export default TopicList;
