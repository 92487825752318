import React, { Fragment, useState } from 'react';
import { History as HistoryIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, Typography } from 'antd';

import { ConfirmationDialog } from '../../../../confirmation-dialog';
import { DraftTopicTreeItem } from '../../../../../api-schemas/topic';
import useDiscardTopic from '../../../../../api-hooks/topic/useDiscardTopic';
import useDeleteTopic from '../../../../../api-hooks/topic/useDeleteTopic';

type DiscardButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
};

const DiscardButton = ({
  documentVersionId,
  draftTopicTreeItem,
}: DiscardButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';
  const isNotPublishedYet: boolean =
    Number(draftTopicTreeItem.topicVersionNumber) < 1.0;
  const isAlreadyPublished: boolean =
    draftTopicTreeItem.latestTopicVersionId !== null &&
    draftTopicTreeItem.latestTopicVersionId ===
      draftTopicTreeItem.publishedTopicVersionId;

  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { discardTopic, isDiscardingTopic } = useDiscardTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    topicId: draftTopicTreeItem.topicId,
  });
  const { deleteTopic, isDeletingTopic } = useDeleteTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
    topicId: draftTopicTreeItem.topicId,
  });

  const handleDiscard = () => {
    discardTopic({
      topicId: draftTopicTreeItem.topicId,
      languageCode: draftTopicTreeItem.languageCode,
    }).catch((error) => {
      if (error.errors && error.errors[0].field === 'Slug') {
        enqueueSnackbar(t(`error.duplicate-slug`), {
          variant: 'error',
        });
      }
      if (error.errors && error.errors[0].code === 'TopicVersionNotFound') {
        enqueueSnackbar(t(`error.deleted-parent`), {
          variant: 'error',
        });
      }
    });
  };

  const handleDelete = () => {
    deleteTopic({
      topicId: draftTopicTreeItem.topicId,
      languageCode: draftTopicTreeItem.languageCode,
    });
  };

  return (
    <Fragment>
      <Button
        onClick={() => setOpenDiscardDialog(true)}
        disabled={
          (!isDeleted && isAlreadyPublished) ||
          isDiscardingTopic ||
          isDeletingTopic
        }
        type="text"
        style={{ display: 'flex', width: '100%' }}
      >
        <HistoryIcon style={{ marginRight: 5 }} />

        <Typography>{t('discard-topic')}</Typography>
      </Button>

      <ConfirmationDialog
        title={t('discard-topic')}
        message={t('topic-discard-changes-confirm-message')}
        discardChangesMessage={
          <ul style={{ paddingLeft: '15px' }}>
            {isNotPublishedYet && (
              <li>{t('not-published-yet-discard-changes-confirm-message')}</li>
            )}
            {!isAlreadyPublished && !isNotPublishedYet && (
              <li>
                {t('not-already-published-discard-changes-confirm-message')}
              </li>
            )}
            {isDeleted && (
              <li>{t('undo-delete-discard-changes-confirm-message')}</li>
            )}
          </ul>
        }
        isOpen={openDiscardDialog}
        onDialogClosed={() => setOpenDiscardDialog(false)}
        onDialogConfirmed={() => {
          if (isNotPublishedYet) {
            handleDelete();
          } else {
            handleDiscard();
          }

          setOpenDiscardDialog(false);
        }}
      />
    </Fragment>
  );
};

export default DiscardButton;
