import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../inputs/text-input';
import { TbLetterT, TbLetterS } from 'react-icons/tb';
import { Typography as AntTypography, Divider, Button, Col } from 'antd';
import { convertToSlug, emptyGuid } from '../../../../helpers/common';
import '../styles.css';

const { Title, Text } = AntTypography;

const styles: any = (theme: Theme) => ({
  textField: {
    marginRight: '10px',
    marginBottom: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
});

function AccountGeneralSettings(props: any) {
  const [accountConfiguration, setAccountConfiguration] = useState<any>({});
  const createMode = window.location.pathname.split('/')[3] === 'create';

  useEffect(() => {
    if (props.accountConfiguration) {
      setAccountConfiguration(props.accountConfiguration);
    }
    // eslint-disable-next-line
  }, [props.accountConfiguration]);

  const handleTitleChange = (args: ChangeEvent<HTMLInputElement>) => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );

    newAccountConfiguration.title = args.target.value;

    if (newAccountConfiguration.id === emptyGuid())
      newAccountConfiguration.slug = convertToSlug(args.target.value);

    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const handleSlugChange = (args: ChangeEvent<HTMLInputElement>) => {
    const newAccountConfiguration: any = Object.assign(
      {},
      accountConfiguration
    );
    newAccountConfiguration.slug = convertToSlug(args.target.value);
    raiseAccountConfigurationChanged(newAccountConfiguration);
  };

  const raiseAccountConfigurationChanged = (accountConfiguration: any) => {
    if (props.onAccountConfigurationChanged)
      props.onAccountConfigurationChanged(accountConfiguration);
  };

  const classes = props.classes;

  const { t } = useTranslation();

  return (
    <div>
      <div style={{ borderRadius: '5px', padding: '28px', marginBottom: 15 }}>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div
            className={
              window.innerWidth > 525
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <TbLetterT
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: '-5px',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 10,
            }}
            className={
              window.innerWidth > 525
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('title')}
            </Title>
            <Text>{t('org-title-description')}</Text>
            <TextInput
              id="title"
              field="Title"
              style={{ width: '95%', marginTop: 10 }}
              value={props.accountConfiguration?.title}
              onChange={handleTitleChange}
              required={true}
              showError={false}
            />
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div
            className={
              window.innerWidth > 525
                ? 'settings-icon-container-show'
                : 'settings-icon-container-hide'
            }
          >
            <TbLetterS
              style={{
                fontSize: '36px',
                color: '#1a73e8',
                marginLeft: 6,
                marginTop: '-5px',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 10,
            }}
            className={
              window.innerWidth > 525
                ? 'settings-detail-container-show-partly'
                : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('slug')}
            </Title>
            <Text>{t('account-slug-help')}</Text>
            <TextInput
              field="Slug"
              style={{ width: '95%', marginTop: 10 }}
              value={props.accountConfiguration?.slug}
              onChange={handleSlugChange}
              required={true}
              showError={false}
            />
          </div>
        </div>
      </div>
      <Col xs={24}>
        <div className={classes.buttons}>
          <Button
            type="primary"
            size="large"
            onClick={props.handleSaveAccountSettings}
            className={classes.button}
            disabled={props.loading || props.disableSaveButton}
          >
            {props.loading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: '#fff' }}
              />
            ) : createMode ? (
              t('create')
            ) : (
              t('save')
            )}
          </Button>
        </div>
      </Col>
    </div>
  );
}

export default withStyles(styles)(AccountGeneralSettings);
