import React, { FC } from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { htmlDecode } from '../../helpers/common';

const useStyles: any = makeStyles(() => ({
  pointerCursor: {
    cursor: 'pointer !important',
  },
}));

type DocerBreadcrumbs = {
  items: any[];
  onClick: (item: any) => void;
};

const DocerBreadcrumbs: FC<DocerBreadcrumbs> = ({ items, onClick }) => {
  const classes = useStyles();

  const shortenTitle = (title: string) => {
    if (title.length < 25) return htmlDecode(title);
    else return htmlDecode(title.substring(0, 22) + '...');
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items?.map((item: any, index) => {
        return index === items.length - 1 ? (
          <span
            className={classes.pointerCursor}
            style={{
              fontSize: 'small',
              color: '#000',
              cursor: 'auto',
            }}
            key={index}
          >
            {shortenTitle(item.title || item.slug)}
          </span>
        ) : (
          <Link
            className={classes.pointerCursor}
            color="inherit"
            onClick={() => onClick(item)}
            style={{ fontSize: 'small' }}
            key={index}
          >
            {shortenTitle(item.title || item.slug)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default DocerBreadcrumbs;
