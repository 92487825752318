import React, { useEffect, useMemo, useState, Dispatch, SetStateAction, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { validate as isValidUUID } from 'uuid';

import { getLanguageCode } from '../../helpers/route';
import { DraftTopic } from '../../api-schemas/topic';
import useGetDraftTopicSilently from '../../api-hooks/topic/useGetDraftTopicSilently';

const initialState: TopicContextType = {
  editedTopic: undefined,
  selectedTopic: undefined,
  isLoadingSelectedTopic: false,
  setEditedTopic: () => {},
  setSelectedTopic: () => {},
  changeTopic: () => {},
  isUserModifyingTopic: false,
  setIsUserModifyingTopic: () => {},
  isPublishingTopic: false,
  setIsPublishingTopic: () => {},
  isSavingTopicEnabled: false,
  setIsSavingTopicEnabled: () => {},
  isModifyingDoclessTopic: false,
  setIsModifyingDoclessTopic: () => {},
};

type TopicContextType = {
  editedTopic?: DraftTopic;
  selectedTopic?: DraftTopic;
  isLoadingSelectedTopic: boolean;
  setEditedTopic: Dispatch<SetStateAction<DraftTopic | undefined>>;
  setSelectedTopic: Dispatch<SetStateAction<DraftTopic | undefined>>;
  changeTopic: (newTopicId: string) => void;
  isUserModifyingTopic: boolean;
  setIsUserModifyingTopic: Dispatch<SetStateAction<boolean>>;
  isPublishingTopic: boolean;
  setIsPublishingTopic: Dispatch<SetStateAction<boolean>>;
  isSavingTopicEnabled: boolean;
  setIsSavingTopicEnabled: Dispatch<SetStateAction<boolean>>;
  isModifyingDoclessTopic: boolean;
  setIsModifyingDoclessTopic: Dispatch<SetStateAction<boolean>>;
};

export const TopicContext = React.createContext<TopicContextType>(initialState);

export function TopicProvider({ children }) {
  const history = useHistory();
  const { pathname, hash, search } = useLocation();

  const [editedTopic, setEditedTopic] = useState<DraftTopic>();
  const [selectedTopic, setSelectedTopic] = useState<DraftTopic>();
  const [isLoadingSelectedTopic, setIsLoadingSelectedTopic] = useState<boolean>(false);
  const [isUserModifyingTopic, setIsUserModifyingTopic] = useState<boolean>(false);
  const [isPublishingTopic, setIsPublishingTopic] = useState<boolean>(false);
  const [isSavingTopicEnabled, setIsSavingTopicEnabled] = useState<boolean>(false);
  const [isModifyingDoclessTopic, setIsModifyingDoclessTopic] = useState<boolean>(false);

  const isFirstRender = useRef(true);

  const languageCode = useMemo(() => getLanguageCode(), [search]);
  const topicId = useMemo(() => {
    const pathnameParts = pathname.split('/');

    const urlTopicId = pathnameParts[1] === 'document' ? pathnameParts[4] : pathnameParts[3];

    return isValidUUID(urlTopicId) ? urlTopicId : undefined;
  }, [pathname]);

  const { draftTopic, isSuccessLoadingDraftTopic, isErrorLoadingDraftTopic, isLoadingDraftTopic } =
    useGetDraftTopicSilently({
      topicId,
      languageCode,
    });

  useEffect(() => {
    if (isLoadingDraftTopic) return;

    if (
      isFirstRender.current ||
      selectedTopic?.languageCode != draftTopic?.languageCode ||
      (selectedTopic?.topicVersionNumber != draftTopic?.topicVersionNumber &&
        !(parseFloat(selectedTopic?.topicVersionNumber || '0') >= 1.0)) ||
      isSuccessLoadingDraftTopic ||
      !draftTopic?.body
    ) {
      setSelectedTopic(draftTopic);
    }

    if (
      selectedTopic?.languageCode != draftTopic?.languageCode ||
      selectedTopic?.latestTopicVersionId != draftTopic?.latestTopicVersionId ||
      selectedTopic?.publishedTopicVersionId != draftTopic?.publishedTopicVersionId
    ) {
      setIsSavingTopicEnabled(false);
      setEditedTopic(draftTopic);
    }
  }, [draftTopic, isLoadingDraftTopic]);

  useEffect(() => {
    if (isSuccessLoadingDraftTopic || isErrorLoadingDraftTopic) {
      setIsLoadingSelectedTopic(false);
      isFirstRender.current = false;
    } else if (topicId) {
      setIsLoadingSelectedTopic(true);
    }
  }, [topicId, isErrorLoadingDraftTopic, isSuccessLoadingDraftTopic]);

  const changeTopic = (newTopicId: string) => {
    if (newTopicId === selectedTopic?.topicId) {
      return;
    }

    // We should clear previous topic components data if exist since the topic is changed.
    if (window.componentsData) {
      window.componentsData = [];
    }

    const urlFourthParameter = pathname.split('/')[4];

    history.push({
      pathname: isValidUUID(urlFourthParameter)
        ? pathname.replace(urlFourthParameter, newTopicId)
        : `${pathname.replace(urlFourthParameter, newTopicId)}/${urlFourthParameter}`,
      hash,
      search,
    });
  };

  const value = {
    editedTopic,
    selectedTopic,
    isLoadingSelectedTopic,
    setEditedTopic,
    setSelectedTopic,
    changeTopic,
    setIsUserModifyingTopic,
    isUserModifyingTopic,
    isPublishingTopic,
    setIsPublishingTopic,
    isSavingTopicEnabled,
    setIsSavingTopicEnabled,
    isModifyingDoclessTopic,
    setIsModifyingDoclessTopic,
  };

  return <TopicContext.Provider value={value}>{children}</TopicContext.Provider>;
}
