import React, { useState, useEffect, Fragment } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Paper,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { List as VirtualizedList, InfiniteLoader } from 'react-virtualized';
import PublishIcon from '@mui/icons-material/Publish';
import { topicService } from '../../../services/topic';
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import { DocumentContext } from '../../../contexts/document';
import { AccountContext } from '../../../contexts/account';
import { ProfileContext } from '../../../contexts/profile';
import { getEditContentUrl } from '../../../helpers/route';
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { htmlDecode } from '../../../helpers/common';
import { Col, Row } from 'antd';
import useGetAccesses from '../../../api-hooks/access/useGetAccesses';

const TopicVersionDiffViewer = (props) => {
  const topicAPI = new topicService();

  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [updatedByUser, setUpdatedByuser] = useState<any>(null);
  const [newTopicContent, setNewTopicContent] = useState<any>(null);
  const [topicVersions, setTopicVersions] = useState<any[]>([]);
  const [showChanges, setShowChanges] = useState<boolean>(true);
  const [onlyPublished, setOnlyPublished] = useState<boolean>(false);
  const [accesses, setAccesses] = useState<any[]>([]);
  const [loadingDiff, setLoadingDiff] = useState<boolean>(false);
  const [loadingVersions, setLoadingVersions] = useState<boolean>(true);
  const [loadingNewContent, setLoadingNewContent] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const defaultDiffInfo = {
    diffHtmlContent: '<p></p>',
    newTopicParentId: null,
    oldTopicParentId: null,
    oldTopicSlug: null,
    newTopicSlug: null,
  };
  const [diffInfo, setDiffInfo] = useState(defaultDiffInfo);
  const { selectedDoc } = React.useContext(DocumentContext);
  const { profileData } = React.useContext(ProfileContext);
  const { setUserRole } = React.useContext(AccountContext);

  const { getAccesses } = useGetAccesses(selectedDoc?.account?.id);

  const history = useHistory();
  const { t } = useTranslation();

  const pageSize: number = 20;
  const listHeight: number = window.innerHeight - (54 + 11 + 58 + 115);
  const rowHeight: number = 70;
  const rowWidth: number = 250;

  useEffect(() => {
    if (selectedDoc && selectedDoc.account && selectedDoc.account.id) {
      getAccesses().then((result) => {
        const accesses = result.data;

        if (accesses) {
          setAccesses(accesses);

          const user = accesses.find(
            (access) => access.email === profileData?.email
          );

          if (user) {
            setUserRole(user.action);
          }
        }
      });

      if (!loadingVersions) {
        setLoadingVersions(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedVersionId) {
      setNewTopicContent(null);
      // setDiffInfo(defaultDiffInfo);
      if (showChanges) setLoadingDiff(true);
      else {
        setLoadingNewContent(true);
      }
    }
    // eslint-disable-next-line
  }, [selectedVersionId]);

  useEffect(() => {
    if (showChanges) {
      //if (diffInfo === defaultDiffInfo) {
      setLoadingDiff(true);
      //}
    } else {
      if (newTopicContent === null) {
        setLoadingNewContent(true);
      }
    }
    // eslint-disable-next-line
  }, [showChanges]);

  useEffect(() => {
    setLoadingDiff(false);
    // eslint-disable-next-line
  }, [diffInfo]);

  useEffect(() => {
    if (props.previousVersionId !== undefined) {
      setSelectedVersionId(props.topicVersionId);
      setLoadingDiff(true);
    }
    // eslint-disable-next-line
  }, [props.previousVersionId]);

  useEffect(() => {
    if (loadingVersions && hasMore && props.previousVersionId === undefined) {
      topicAPI
        .getVersions(
          props.topicId,
          props.languageCode,
          pageIndex,
          pageSize,
          onlyPublished
        )
        .then(async (versions) => {
          setHasMore(versions.length >= pageSize);
          setPageIndex(pageIndex + 1);
          setTopicVersions([...topicVersions, ...versions]);
        })
        .catch((error) => {
          setHasMore(false);
          if (error.status === 404) {
            if (onlyPublished)
              props.enqueueSnackbar(t('error.noPublishVersion'), {
                variant: 'error',
                persist: false,
                style: { whiteSpace: 'pre-line' },
                preventDuplicate: true,
              });
            setTopicVersions([]);
            setOnlyPublished(false);
          } else if (topicVersions.length === 0) {
            //if in first load version we have error
            //----------go to edit tab, if topic has no history
            const docSlug = window.location.pathname.split('/')[3];
            const accesseslug = window.location.pathname.split('/')[2];
            history.push(
              getEditContentUrl(accesseslug, docSlug, props.topicSlug)
            );
          }
        });
      setLoadingVersions(false);
    }
    // eslint-disable-next-line
  }, [loadingVersions]);

  useEffect(() => {
    if (!loadingVersions) {
      setTopicVersions([]);
      setHasMore(true);
      setPageIndex(0);
      setLoadingVersions(true);
    }
    // eslint-disable-next-line
  }, [onlyPublished]);

  useEffect(() => {
    setLoadingVersions(false);

    if (topicVersions.length > 0) {
      if (!selectedVersionId) {
        setSelectedVersionId(props.topicVersionId);
        props.onChangeTopicVersion?.(props.topicVersionId);
        return;
      }

      const selectedVersion = topicVersions.find(
        (version) => version.id === selectedVersionId
      );
      const selectedVersionIsPublished = selectedVersion.isPublished;
      if (selectedVersionIsPublished === false && onlyPublished) {
        const publishedTopicVersions = topicVersions.filter(
          (version) => version.publisherId
        );
        setSelectedVersionId(publishedTopicVersions[0].id);
        props.onChangeTopicVersion?.(publishedTopicVersions[0].id);
      }
    } else if (props.previousVersionId === undefined) {
      setSelectedVersionId(null);
    }
    // eslint-disable-next-line
  }, [topicVersions]);

  useEffect(() => {
    if (!props.loadingHistory) {
      setDiffInfo(defaultDiffInfo);
      setTopicVersions([]);
      setHasMore(true);
      setPageIndex(0);
      setLoadingVersions(true);
    }
    // eslint-disable-next-line
  }, [props.loadingHistory, props.topicId]);

  useEffect(() => {
    const selectedTopicVersionElement = window.document.getElementById(
      `${selectedVersionId}`
    );
    if (selectedTopicVersionElement) {
      selectedTopicVersionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    // eslint-disable-next-line
  }, [selectedVersionId, onlyPublished]);

  useEffect(() => {
    let selectedVersion;
    if (topicVersions.length > 0 && selectedVersionId && accesses.length > 0) {
      selectedVersion = topicVersions.find(
        (version) => version.id === selectedVersionId
      );
      if (selectedVersion) {
        const selectedVersionUpdatedByUser = accesses.find(
          (access) => access.principalId === selectedVersion.createdBy
        );
        setUpdatedByuser(selectedVersionUpdatedByUser);
      }
    }
    // eslint-disable-next-line
  }, [selectedVersionId, accesses]);

  useEffect(() => {
    if (
      selectedVersionId &&
      (topicVersions.length > 0 || props.previousVersionId !== undefined) &&
      loadingDiff
    ) {
      if (!showChanges) {
        fetchTopicContent(selectedVersionId).then(async (newContent) => {
          setNewTopicContent(newContent);
          setLoadingNewContent(false);
        });
      }

      const oldTopicVersionId =
        props.previousVersionId === undefined
          ? findOldTopicVersionId(selectedVersionId)
          : props.previousVersionId;

      if (oldTopicVersionId) {
        fetchDiff(selectedVersionId, oldTopicVersionId);
      } else {
        fetchDiff(selectedVersionId, null);
      }
    }
    // eslint-disable-next-line
  }, [loadingDiff]);

  useEffect(() => {
    if (selectedVersionId && topicVersions.length > 0 && loadingNewContent) {
      if (!showChanges) {
        fetchTopicContent(selectedVersionId).then(async (newContent) => {
          setNewTopicContent(newContent);
          setLoadingNewContent(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [loadingNewContent]);

  const loadVersions = async () => {
    if (hasMore)
      if (!loadingVersions) {
        setLoadingVersions(true);
      }
  };

  const fetchDiff = async (
    selectedVersionId: string,
    oldTopicVersionId: string | null
  ) => {
    let diffHtml = null;
    if (oldTopicVersionId) {
      topicAPI
        .getDiff(`${oldTopicVersionId}`, selectedVersionId)
        .then((response) => {
          setDiffInfo(response);
          diffHtml = response.diffHtmlContent;
        });
    } else if (selectedVersionId) {
      fetchTopicContent(selectedVersionId).then(async (newVersionInfo) => {
        setDiffInfo({
          diffHtmlContent: newVersionInfo.body,
          oldTopicParentId: newVersionInfo.parentTopicId,
          newTopicParentId: newVersionInfo.parentTopicId,
          oldTopicSlug: newVersionInfo.slug,
          newTopicSlug: newVersionInfo.slug,
        });
        setNewTopicContent(newVersionInfo);
        setLoadingNewContent(false);
      });
    }
    return diffHtml;
  };

  const findOldTopicVersionId = (selectedVersionId: string) => {
    const currentVersionIndex: number = topicVersions.findIndex(
      (version) => version.id === selectedVersionId
    );
    if (currentVersionIndex + 1 < topicVersions.length) {
      const prevVersionIndex = currentVersionIndex + 1;
      const rightVersionId = topicVersions[prevVersionIndex].id;
      return rightVersionId;
    } else {
      return null;
    }
  };

  const fetchTopicContent = (topicVersionId: string) => {
    return topicAPI.getTopicContent(topicVersionId);
  };

  const handleNewTopicVersionChanged = (newValue: string) => {
    if (selectedVersionId !== newValue) {
      setSelectedVersionId(newValue);
      props.onChangeTopicVersion?.(newValue);
    }
  };

  const shortenTitle = (
    title: string,
    length: number,
    color: string | null
  ) => {
    let newTitle: string | null = '';
    if (title.length < length) newTitle = htmlDecode(title);
    else newTitle = htmlDecode(title.substring(0, length - 1) + '...');
    if (title.length < length)
      return (
        <span
          className={
            color
              ? color === 'green'
                ? 'diff-html-added'
                : 'diff-html-removed'
              : ''
          }
        >
          {newTitle}
        </span>
      );
    else
      return (
        <Tooltip title={title}>
          <span
            className={
              color
                ? color === 'green'
                  ? 'diff-html-added'
                  : 'diff-html-removed'
                : ''
            }
          >
            {newTitle}
          </span>
        </Tooltip>
      );
  };
  const isRowLoaded = ({ index }) => {
    return !!versionList[index];
  };

  const renderVersion = ({
    index, // Index of row
    key, // Unique key within array of rendered rows
    style, // Style object to be applied to row (to position it);
  }) => {
    const version = versionList[index];
    if (!version) return;
    const versionDate = new Date(version.createDateTime);
    const isCurrentYear =
      versionDate.getFullYear() === new Date(Date.now()).getFullYear();
    const format: any = isCurrentYear
      ? {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }
      : {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };
    const versionIsPublished = version.published;
    const text = versionDate.toLocaleString('en-US', format);
    const user = accesses.find(
      (access) => access.principalId === version.createdBy
    );

    return (
      <div key={key} style={style} className="row">
        <div className="content">
          <ListItem
            style={{
              cursor: 'pointer',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
              backgroundColor:
                selectedVersionId === version.id ? '#e8f0fe' : '',
            }}
            id={version.id}
            onClick={() => {
              handleNewTopicVersionChanged(version.id);
            }}
            button
            selected={selectedVersionId === version.id}
          >
            <ListItemText
              primary={
                <Fragment>
                  <span>{text}</span>
                  <div
                    hidden={!versionIsPublished}
                    style={{
                      position: 'absolute',
                      right: '2px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {versionIsPublished && (
                      <Tooltip title="Published">
                        <PublishIcon fontSize="small"></PublishIcon>
                      </Tooltip>
                    )}
                  </div>
                </Fragment>
              }
              secondary={
                <div
                  style={{
                    fontSize: 'smaller',
                    color:
                      selectedVersionId === version.id ? '#1a73e8' : 'inherit',
                  }}
                >
                  {user ? user.displayName : 'Deleted user'}
                </div>
              }
              style={{
                fontSize: 'smaller',
                marginRight: '10px',
                color: selectedVersionId === version.id ? '#1a73e8' : 'inherit',
              }}
            />
          </ListItem>
        </div>
      </div>
    );
  };

  const oldSlug: string = diffInfo.oldTopicSlug
    ? `${diffInfo.oldTopicSlug}`
    : '';
  const newSlug: string = diffInfo.newTopicSlug
    ? `${diffInfo.newTopicSlug}`
    : '';
  let oldParent: any = null;
  let newParent: any = null;
  let topicMoved: boolean = false;
  let isFirstVersion: boolean = false;
  if (
    props.topics.length > 0 &&
    (diffInfo.oldTopicParentId || diffInfo.newTopicParentId)
  ) {
    oldParent = props.topics.find(
      (topic) => topic.topicId === diffInfo.oldTopicParentId
    );
    newParent = props.topics.find(
      (topic) => topic.topicId === diffInfo.newTopicParentId
    );
    isFirstVersion = diffInfo.oldTopicParentId === null; // first version does not have oldTopicParentId
    topicMoved = !isFirstVersion
      ? diffInfo.oldTopicParentId !== diffInfo.newTopicParentId
      : false;
  }

  const oldParentTitle = oldParent
    ? oldParent.parentTopicId === null
      ? 'No parent(root)'
      : oldParent.title
    : 'Deleted topic';

  const newParentTitle = newParent
    ? newParent.parentTopicId === null
      ? 'No parent(root)'
      : newParent.title
    : 'Deleted topic';

  const slugChanged: boolean = oldSlug !== newSlug;
  const publishedTopicVersions = topicVersions?.filter(
    (version) => version.publisherId
  );
  const versionList = onlyPublished ? publishedTopicVersions : topicVersions;
  let selectedVersion;

  return (
    <Col xs={24} style={{ padding: 8, display: 'flex' }}>
      <Col xs={props.showSideBar ? 18 : 24} style={{ paddingRight: 10 }}>
        {((diffInfo !== defaultDiffInfo && showChanges) ||
          (newTopicContent && !showChanges)) &&
        !loadingDiff &&
        !loadingNewContent &&
        newSlug &&
        !props.loadingHistory ? (
          <Fragment>
            <Paper
              style={{
                borderRadius: '4px',
                paddingLeft: '20px',
                paddingTop: '10px',
                marginTop: '8px',
              }}
              variant="outlined"
            >
              <Row style={{ padding: 8 }}>
                <Col xs={12}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>{'Slug : '}</span>
                    {slugChanged && showChanges ? (
                      <Fragment>
                        {shortenTitle(oldSlug, 50, 'red')}{' '}
                        {shortenTitle(newSlug, 50, 'green')}
                      </Fragment>
                    ) : (
                      shortenTitle(newSlug, 40, null)
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>{'Parent : '}</span>
                    {showChanges && topicMoved ? (
                      <Fragment>
                        {shortenTitle(oldParentTitle, 20, 'red')}{' '}
                        {shortenTitle(newParentTitle, 20, 'green')}
                      </Fragment>
                    ) : (
                      shortenTitle(newParentTitle, 40, null)
                    )}
                  </div>
                </Col>
              </Row>

              {updatedByUser && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    <ListItem style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                      <ListItemAvatar style={{ minWidth: '25px' }}>
                        <Avatar
                          style={{
                            width: '25px',
                            height: '25px',
                            marginLeft: '0px',
                            marginRight: '3px',
                          }}
                          src={updatedByUser ? updatedByUser.photoUrl : ''}
                        >
                          {updatedByUser
                            ? updatedByUser.email[0].toUpperCase()
                            : 'U'}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: 'smaller' }}>
                            {updatedByUser
                              ? updatedByUser.email
                              : accesses.length > 0 && selectedVersion
                                ? 'Deleted user'
                                : 'Loading...'}
                          </span>
                        }
                      />
                    </ListItem>
                  </span>
                </div>
              )}
            </Paper>

            <div
              id="CKEditorWrapper"
              className="ck-content"
              style={{
                maxHeight: window.innerHeight - 200,
                overflow: 'auto',
                minWidth: window.innerWidth - 800,
              }}
            >
              {showChanges ? (
                <p style={{ padding: '20px' }}>
                  {parse('' + diffInfo.diffHtmlContent + '')}
                </p>
              ) : (
                <p style={{ padding: '20px' }}>
                  {parse('' + newTopicContent.body + '')}
                </p>
              )}
            </div>
          </Fragment>
        ) : (
          <Skeleton
            height={window.innerHeight - 100}
            style={{ marginTop: '8px', padding: '0px' }}
            variant={'rectangular'}
          ></Skeleton>
        )}
      </Col>
      {props.showSideBar ? (
        <Col xs={6}>
          <Fragment>
            {topicVersions.length > 0 && !props.loadingHistory ? (
              <List component="nav" dense={true}>
                <div className="list">
                  <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadVersions}
                    rowCount={100}
                    threshold={2}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <VirtualizedList
                        width={rowWidth}
                        height={listHeight}
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        rowHeight={rowHeight}
                        rowRenderer={renderVersion}
                        rowCount={topicVersions.length}
                        style={{
                          maxHeight: window.innerHeight - 220,
                          overflow: 'auto',
                          position: 'relative',
                          outline: 'none',
                          width: '100%',
                        }}
                      />
                    )}
                  </InfiniteLoader>
                </div>
              </List>
            ) : (
              <Skeleton
                variant={'rectangular'}
                style={{ marginTop: '8px', padding: '0px' }}
                height={window.innerHeight - 220}
              ></Skeleton>
            )}
            <List>
              <ListItem
                style={{
                  paddingTop: '10px',
                  paddingBottom: '5px',
                }}
              >
                <Checkbox
                  checked={showChanges}
                  onChange={() => {
                    setShowChanges(!showChanges);
                  }}
                ></Checkbox>
                <ListItemText primary={'show changes'} />
              </ListItem>
              <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <Checkbox
                  checked={onlyPublished}
                  onChange={() => setOnlyPublished(!onlyPublished)}
                ></Checkbox>
                <ListItemText primary={'Only published'} />
              </ListItem>
            </List>
          </Fragment>
        </Col>
      ) : null}
    </Col>
  );
};

export default withSnackbar(TopicVersionDiffViewer);
