import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Watchers } from '../../api-schemas/watcher';

const getWatchersRequest = ({
  entityType,
  entityId,
}: {
  entityType: string;
  entityId: string;
}) => {
  return axios
    .get<Watchers>(`/api/public/subscriptions/${entityType}/${entityId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetWatchers({
  entityType,
  entityId,
  enabled,
}: {
  entityType: string;
  entityId: string;
  enabled: boolean;
}) {
  const {
    data,
    refetch: getWatchers,
    isFetching: isLoadingWatchers,
    isError: isErrorLoadingWatchers,
    isSuccess: isSuccessLoadingWatchers,
  } = useQuery<Watchers>({
    staleTime: 1000 * 60 * 5,
    queryKey: ['watchers', entityId, entityType],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
    queryFn: () =>
      entityId ? getWatchersRequest({ entityType, entityId }) : [],
  });

  const watchers = useMemo(() => data || [], [data]);

  return {
    watchers,
    isLoadingWatchers,
    isErrorLoadingWatchers,
    isSuccessLoadingWatchers,

    getWatchers,
  };
}
