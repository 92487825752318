import React, { useCallback, useMemo } from 'react';
import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HistoryRounded as HistoryRoundedIcon,
  Settings as SettingsIcon,
  Feedback as FeedbackIcon,
} from '@mui/icons-material';
import { Button, Menu, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import { changeRouteBaseOnEditContentMenues } from '../../../../helpers/route';
import {
  DraftTopicTree,
  DraftTopicTreeItem,
} from '../../../../api-schemas/topic';
import { Document } from '../../../../api-schemas/document';

import PublishButton from './publish-button';
import DeleteButton from './delete-button';
import NewChildButton from './new-child-button';
import CopyButton from './copy-button';
import MoveButton from './move-button';
import UndoDeleteButton from './undo-delete-button';
import DiscardButton from './discard-button';

type TreeItemMenuPropsType = {
  draftTopicTree: DraftTopicTree;
  flatDraftTopicTree: DraftTopicTree;
  draftTopicTreeItem: DraftTopicTreeItem;
  document: Document;
  documentVersionId: string;
};

const TreeItemMenu = ({
  draftTopicTree,
  flatDraftTopicTree,
  draftTopicTreeItem,
  document,
  documentVersionId,
}: TreeItemMenuPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const { t } = useTranslation();
  const history = useHistory();

  const handleLinkClick = useCallback(async (
    event: MouseEvent<HTMLElement>,
    linkItem: 'Settings' | 'History' | 'Feedback'
  ) => {
    event.stopPropagation();

    const [, newRoute] = changeRouteBaseOnEditContentMenues(
      linkItem,
      draftTopicTreeItem.topicId
    );

    history.push(newRoute as string);
  }, []);

  const menuItems = useMemo(() => (
    [
      {
        key: draftTopicTreeItem.topicId,
        label: null,
        children: [
          {
            key: 0,
            label: (
              <PublishButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                document={document}
              />
            ),
          },
          {
            key: 1,
            label: (
              <DeleteButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
              />
            ),
          },
          {
            key: 2,
            label: (
              <NewChildButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                flatDraftTopicTree={flatDraftTopicTree}
              />
            ),
          },
          {
            key: 3,
            label: (
              <CopyButton
                documentId={document.id}
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                draftTopicTree={draftTopicTree}
                flatDraftTopicTree={flatDraftTopicTree}
              />
            ),
          },
          {
            key: 4,
            label: (
              <MoveButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                flatDraftTopicTree={flatDraftTopicTree}
                draftTopicTree={draftTopicTree}
                type="up"
              />
            ),
          },
          {
            key: 5,
            label: (
              <MoveButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                flatDraftTopicTree={flatDraftTopicTree}
                draftTopicTree={draftTopicTree}
                type="down"
              />
            ),
          },
          {
            key: 6,
            label: (
              <MoveButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                flatDraftTopicTree={flatDraftTopicTree}
                draftTopicTree={draftTopicTree}
                type="indent"
              />
            ),
          },
          {
            key: 7,
            label: (
              <MoveButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
                flatDraftTopicTree={flatDraftTopicTree}
                draftTopicTree={draftTopicTree}
                type="outdent"
              />
            ),
          },
          {
            key: 8,
            label: (
              <UndoDeleteButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
              />
            ),
          },
          {
            key: 9,
            label: (
              <DiscardButton
                documentVersionId={documentVersionId}
                draftTopicTreeItem={draftTopicTreeItem}
              />
            ),
          },
          {
            key: 10,
            label: (
              <Button
                type="text"
                onClick={(event) => handleLinkClick(event, 'Settings')}
                disabled={isDeleted}
                style={{ display: 'flex', width: '100%' }}
              >
                <SettingsIcon style={{ marginRight: 5 }} />
                <Typography>{t('settings')}</Typography>
              </Button>
            ),
          },
          {
            key: 11,
            label: (
              <Button
                type="text"
                onClick={(event) => handleLinkClick(event, 'History')}
                disabled={isDeleted || draftTopicTreeItem.contentType === 'GoogleDoc'}
                style={{ display: 'flex', width: '100%' }}
              >
                <HistoryRoundedIcon style={{ marginRight: 5 }} />
                <Typography>{t('history')}</Typography>
              </Button>
            ),
          },
          {
            key: 12,
            label: (
              <Button
                type="text"
                onClick={(event) => handleLinkClick(event, 'Feedback')}
                disabled={isDeleted}
                style={{ display: 'flex', width: '100%' }}
              >
                <FeedbackIcon style={{ marginRight: 5 }} />
                <Typography>{t('feedback')}</Typography>
              </Button>
            ),
          },
        ],
      },
    ]
  ), [draftTopicTreeItem, documentVersionId, flatDraftTopicTree, draftTopicTree])

  return (
    <Menu
      mode="vertical"
      items={menuItems}
    />
  );
};

export default TreeItemMenu;
