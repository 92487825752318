import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Col, Row } from 'antd';
import { withSnackbar } from 'notistack';

import { DocumentContext } from '../../../contexts/document';
import TopicTree from '../../topic/new-tree';
import TopicContentEditorHandler from '../../topic/content-editor-handler';
import useTopicTree from '../../../component-hooks/topic/useTopicTree';

const styles = () => ({
  fullHeight: {
    height: '100%',
    width: '100%',
    margin: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  topicsContainer: {
    padding: '0!important',
    boxShadow: 'none!important',
  },
  topicEditor: {
    padding: '0',
  },
});

const DocumentContentEditorHandler = (props) => {
  const { classes } = props;

  const [isSideSectionExpanded, setIsSideSectionExpanded] = useState<boolean>(false);

  const { selectedDoc, selectedVersion } = useContext(DocumentContext);

  const { flatDraftTopicTree, draftTopicTree, isLoadingDraftTopicTree, isRefetchingDraftTopicTree } =
    useTopicTree(selectedVersion);

  return (
    <Row className={classes.fullHeight} gutter={1}>
      <Col className={classes.topicsContainer} xs={24} sm={6} lg={5}>
        <TopicTree
          document={selectedDoc}
          draftTopicTree={draftTopicTree}
          flatDraftTopicTree={flatDraftTopicTree}
          isLoadingDraftTopicTree={isLoadingDraftTopicTree || !selectedVersion}
          isRefetchingDraftTopicTree={isRefetchingDraftTopicTree}
          setIsSideSectionExpanded={setIsSideSectionExpanded}
        />
      </Col>

      <Col className={classes.topicEditor} xs={24} sm={18} lg={19} style={{ paddingLeft: 10 }}>
        <TopicContentEditorHandler
          draftTopicTree={draftTopicTree}
          flatDraftTopicTree={flatDraftTopicTree}
          isLoadingDraftTopicTree={isLoadingDraftTopicTree || !selectedVersion}
          isSideSectionExpanded={isSideSectionExpanded}
          setIsSideSectionExpanded={setIsSideSectionExpanded}
        />
      </Col>
    </Row>
  );
};

//@ts-expect-error material problem
export default withStyles(styles)(withSnackbar(DocumentContentEditorHandler));
