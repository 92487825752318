import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Gauge } from '@ant-design/charts';
import HelpIcon from '@mui/icons-material/Help';
import { Button, Col, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { changeRouteBaseOnEditContentMenues } from '../../../helpers/route';
import { useTranslation } from 'react-i18next';

const OveralDescription = ({
  text,
  score,
  label,
  selectedTopic,
  selectedSidePart,
  className,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const ticks = [0, 3 / 10, 6 / 10, 1];
  const color = ['#F4664A', '#FAAD14', '#30BF78'];
  const config = {
    renderer: 'svg',
    percent: score / 100,
    range: {
      ticks: [0, 1],
      color: ['l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78'],
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    statistic: {
      title: {
        formatter: ({ percent }) => {
          return Math.round(percent * 100);
        },
        style: ({ percent }) => {
          return {
            fontSize: selectedTopic ? '20px' : '30px',
            transform:
              selectedTopic && window.innerWidth > 2000
                ? 'translate(-50%, -110px)'
                : selectedTopic
                  ? 'translate(-50%, -100px)'
                  : 'translate(-50%, -100px)',
            lineHeight: 1,
            color:
              percent < ticks[1]
                ? color[0]
                : percent < ticks[2]
                  ? color[1]
                  : color[2],
          };
        },
      },
      content: {
        style: {
          fontSize: '24px',
          lineHeight: '24px',
          transform: selectedTopic
            ? 'translate(-50%, -70px)'
            : 'translate(-50%, -65px)',
        },
        formatter: () => label,
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      const parentElement = document.getElementsByClassName(className)[0];
      if (parentElement) {
        const textTags = parentElement.getElementsByTagName('text');
        for (let i = 0; i <= 1; i += 0.2) {
          Array.from(textTags).forEach((textTag) => {
            if (
              textTag.getAttribute('id') ===
              `-axis-label-${i === 1 ? i : i.toFixed(1)}`
            ) {
              textTag.innerHTML = (100 * i).toFixed(0).toString();
            }
          });
        }
      }
    }, 10);
  });

  return (
    <Col xs={24}>
      <Col xs={24} style={{ display: 'flex' }}>
        <Typography>{label}</Typography>
        <Tooltip title={t('read-more')}>
          <a
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              marginLeft: '5px',
              height: '19px',
            }}
            href={
              className === 'seo'
                ? 'https://sonat.com/@sonat/knowledge-base/seo?lang=en'
                : 'https://sonat.com/@sonat/knowledge-base/readability?lang=en'
            }
            target="_blank"
            rel="noreferrer"
          >
            <HelpIcon />
          </a>
        </Tooltip>
      </Col>
      <Col xs={24}>
        <Col
          xs={24}
          style={{ minHeight: 50, display: 'flex', alignItems: 'baseline' }}
        >
          <p>{text}</p>
          {className === 'seo' &&
          selectedTopic?.metrics?.keyphraseLength === 0 ? (
            <Button
              type="link"
              onClick={() =>
                history.push(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-expect-error
                  changeRouteBaseOnEditContentMenues(
                    'Settings',
                    selectedTopic.topicId
                  )[1]
                )
              }
              style={{ padding: '5px' }}
            >
              (Set)
            </Button>
          ) : null}
        </Col>
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={selectedSidePart ? 24 : 8} lg={selectedSidePart ? 24 : 6}>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              <Gauge
                className={className}
                style={{
                  width: selectedSidePart ? '100%' : 340,
                  margin: 'auto',
                  padding: 0,
                  overflow: 'hidden',
                }}
                {...config}
              />
            }
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default OveralDescription;
