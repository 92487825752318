import { useMemo } from 'react';
import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { SyncStatusData } from '../../api-schemas/topic';

const syncStatusGoogleDocTopicRequest = ({
  accountId,
  googleDriveId,
}: {
  accountId: string;
  googleDriveId: string;
}) => {
  return axios
    .get<SyncStatusData>(
      `/api/google-drive-integration/doc/${googleDriveId}/sync-status`,
      {
        params: { accountId },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.auth_token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useSyncStatusGoogleDocTopic({
  accountId,
  googleDriveId,
}: {
  accountId: string;
  googleDriveId: string;
}) {
  const {
    data,
    refetch: syncStatusGoogleDocTopic,
    isFetching: isLoadingsyncStatusGoogleDocTopic,
    isRefetching: isRefetchingsyncStatusGoogleDocTopic,
    isError: isErrorLoadingsyncStatusGoogleDocTopic,
    isSuccess: isSuccessLoadingsyncStatusGoogleDocTopic,
  } = useQuery<SyncStatusData>({
    staleTime: 10,
    queryKey: ['syncStaus', accountId, googleDriveId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(accountId && googleDriveId),
    queryFn: () =>
      syncStatusGoogleDocTopicRequest({ accountId, googleDriveId }),
  });

  const syncStatusData = useMemo(() => data, [data]);

  return {
    syncStatusData,
    syncStatusGoogleDocTopic,
    isLoadingsyncStatusGoogleDocTopic,
    isSuccessLoadingsyncStatusGoogleDocTopic,
    isErrorLoadingsyncStatusGoogleDocTopic,
    isRefetchingsyncStatusGoogleDocTopic,
  };
}
