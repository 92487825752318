import React, { useContext, useEffect, useState } from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  Add as AddIcon,
  DeleteRounded,
  Search as SearchIcon,
} from '@mui/icons-material';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts/profile';
import { Col, Select, Tag } from 'antd';
import SelectInput from '../inputs/select-input';
import TextInput from '../inputs/text-input';
import { isInvitationEmailOk } from '../../helpers/common';
import isValidDomain from 'is-valid-domain';
import PlanLimitationDialog from '../plan-limitation-dialog';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getAccountLink, getDocLink } from '../../helpers/route';
import { DocumentContext } from '../../contexts/document';
import HelpSnippet from '../help-snippet';
import { AccountContext } from '../../contexts/account';
import useGetUser from '../../api-hooks/user/useGetUser';

const { Option } = Select;

const styles: any = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  existingMemberToolbar: {
    marginTop: '30px',
    paddingRight: '14px',
  },
  searchExistingMembers: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  searchExistingMembersIcon: {
    paddingRight: '0!important',
    padding: '10px!important',
  },
  searchExistingMembersInput: {
    marginLeft: 8,
    flex: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  newUserBody: {
    padding: '20px',
    marginBottom: '0px',
  },
  inputField: {
    width: '100%',
    '&& div': {
      paddingBottom: '5px',
    },
  },
  changeRole: {
    paddingBottom: '5px',
    '& > div': {
      padding: 14,
    },
  },
  chip: {
    borderRadius: '4px',
    backgroundColor: '#f1f1f1',
    paddingBottom: '0!important',
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: '5px',
  },
  roleNames: {
    width: '180px',
  },
  fullWidth: {
    width: '100%',
  },
  borders: {
    borderColor: '#dfdfdf',
    border: '1px solid',
    borderRadius: '4px',
    paddingLeft: '30px',
  },
  listItem: {
    paddingLeft: '10px',
  },
});

const ManageAccesses = (props: any) => {
  const [accesses, setAccesses] = useState<any[] | null>(null);
  const [filteredTokens, setFilteredTokens] = useState<any[]>([]);
  const [domainName, setDomainName] = useState<string | null>(null);
  const [selectedAccess, setSelectedAccess] = useState<any>(null);
  const [suggestedUsers, setSuggestedUsers] = useState<any[]>([]);
  const [newAccesses, setNewAccesses] = useState<any[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { profileData } = useContext(ProfileContext);
  const [myAccess, setMyAccess] = useState<any>(null);
  const [openUserLimitationDialog, setOpenUserLimitationDialog] =
    useState(false);
  const [newAccessesAction, setNewAccessesAction] = useState<string>(
    props.isViewerAccess ? props.ROLES.VIEWER_READER : ''
  );
  const [existingAccessSearchPhrase, setExistingAccessSearchPhrase] =
    useState<string>('');
  const [tooltipTitle, setTooltipTitle] = useState<string>(t('copy-token'));
  const [email, setEmail] = useState<string>('');

  const { selectedDoc, versions } = useContext(DocumentContext);
  const { selectedAccount } = useContext(AccountContext);

  const { user, userError, isSuccessLoadingUser } = useGetUser(email);

  useEffect(() => {
    if (isSuccessLoadingUser) {
      setSuggestedUsers([user]);
    }

    if (userError && userError.message === '404') {
      setSuggestedUsers([
        {
          id: email,
          email: email,
        },
      ]);
    }

    // eslint-disable-next-line
  }, [userError, isSuccessLoadingUser]);

  useEffect(() => {
    setNewAccessesAction(
      props.isViewerAccess
        ? props.ROLES.VIEWER_READER
        : props.ROLES.OWNER || props.ROLES.ADMIN
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.tokens && accesses) {
      filterTokens();
    }
    // eslint-disable-next-line
  }, [props.tokens, accesses]);

  useEffect(() => {
    if (props.accesses) {
      setAccesses(props.accesses);
      const myTempAccess = props.accesses.filter(
        (a: any) => a.email === profileData.email
      )[0];
      setMyAccess(myTempAccess);

      // const newAccessesSelectedDocs = {...accessesSelectedDocs};
      // props.accesses.forEach((access) => {
      //     newAccessesSelectedDocs[access.principalId] = access.entityIds || [];
      // });
      // setAccessesSelectedDocs(newAccessesSelectedDocs);
    }
    // eslint-disable-next-line
  }, [props.accesses]);

  const handleAccessActionChanged = (userId: string, newValue: string) => {
    setLoading(true);
    props
      .handleAccessActionChanged(userId, newValue)
      .then(() => {
        loadAccesses();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleNewAccessActionChanged = (newValue: string) => {
    setNewAccessesAction(newValue);
  };

  const handleClickOpenDeleteDialog = (access: any) => {
    if (myAccess?.action === props.ROLES.ADMIN) {
      if (access?.action === props.ROLES.OWNER) {
        props.enqueueSnackbar(t('error.delete-user-permisson'), {
          variant: 'error',
        });
        return;
      }
    } else if (myAccess?.action === props.ROLES.OWNER) {
      const accountOwners = accesses?.filter(
        (u) => u.action === props.ROLES.OWNER
      );
      if (accountOwners?.length === 1 && access.action === props.ROLES.OWNER) {
        props.enqueueSnackbar(t('error.account-owner-count'), {
          variant: 'error',
        });
        return;
      }
    }
    setOpenDeleteDialog(true);
    setSelectedAccess(access);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedAccess(null);
  };

  const handleAddAccesses = async () => {
    let addedAccesses: any[] = [];
    let realNewAccesses = [...newAccesses];
    if (accesses) {
      addedAccesses = [...accesses];
      realNewAccesses = realNewAccesses.filter((newAccess) => {
        const accesses = addedAccesses.filter(
          (access) => access.principalId === newAccess.principalId
        );
        if (
          accesses &&
          accesses.length &&
          (accesses[0].action !== props.ROLES.VIEWER_READER ||
            accesses.length > 1)
        ) {
          props.enqueueSnackbar(
            `${t(`error.AccessAlreadyCreated`)} (${newAccess.email || newAccess.name || newAccess.principalId})`,
            {
              variant: 'error',
            }
          );
          return false;
        }
        return true;
      });
    }
    if (realNewAccesses.length) {
      setLoading(true);
      props
        .handleAddAccesses(
          realNewAccesses,
          newAccessesAction,
          setOpenUserLimitationDialog,
          props.isViewerAccess,
          props.type
        )
        .then(() => {
          loadAccesses();
        })
        .catch(async (data) => {
          setLoading(false);
          const errorJson = await data.err.json();
          props.enqueueSnackbar(
            errorJson.errors && errorJson.errors[0] && errorJson.errors[0].code
              ? `${t(`error.${errorJson.errors[0].code}`)}`
              : t('error.unknown'),
            {
              variant: 'error',
            }
          );
        });
      const myTempAccess = addedAccesses?.filter(
        (u) => u.email === profileData.email
      )[0];
      setMyAccess(myTempAccess);
      setNewAccesses([]);
      setSuggestedUsers([]);
    }
  };

  const handleRemoveAccess = (callback: any) => {
    setLoading(true);
    callback();
    props
      .handleRemoveAccess(selectedAccess.id)
      .then(() => {
        loadAccesses();
      })
      .catch(async (err) => {
        try {
          const errorJson = await err.json();
          props.enqueueSnackbar(
            !!errorJson?.errors &&
              errorJson.errors[0].field === 'AccountLastOwnerCannotBeDeleted'
              ? t(`error.account-owner-count`)
              : t('error.delete-access'),
            {
              variant: 'error',
            }
          );
        } catch (error) {
          props.enqueueSnackbar(t('error.delete-access'), {
            variant: 'error',
          });
        } finally {
          setLoading(false);
        }
      });
  };

  const handleFindExistingMembersChange = (args: any) => {
    setExistingAccessSearchPhrase(args.target.value);
  };

  // #endregion

  const loadAccesses = () => {
    setLoading(true);
    props
      .loadAccesses()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const filterTokens = (searchedName: string = '') => {
    let filteredTokens = [...props.tokens];
    filteredTokens = filteredTokens.filter(
      (token) =>
        !accesses?.find((access) => access.principalId === token.id) &&
        token.name.toLowerCase().includes(searchedName)
    );
    setFilteredTokens(filteredTokens);
  };

  const getTokenName = (tokenId: string) => {
    const token = props.tokens?.find((token) => token.id === tokenId);
    if (token) {
      return `${token?.name}`;
    } else {
      return null;
    }
  };

  const domainNameValidation = (enteredDomain: string) => {
    if (isValidDomain(enteredDomain, { subdomain: false })) {
      setDomainName(enteredDomain);
    } else {
      setDomainName(null);
    }
  };

  const renderSearchedText = (text: string, searchPhrase: string, access) => {
    const matches = match(text, searchPhrase);
    const parts = parse(text, matches);

    return (
      <div>
        {parts.map((part: any, index: number) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
        {props.isViewerAccess && access.entityType !== props.entityType
          ? ' (Inherited from organization)'
          : ''}
        {access.principalType === 'TOKEN' && (
          <span style={{ marginLeft: 5 }}>
            <Tooltip title={tooltipTitle} arrow>
              <FileCopyIcon
                style={{
                  fontSize: 12,
                  color: 'rgba(0, 0, 0, 0.54)',
                  cursor: 'pointer',
                }}
                onClick={() => copyValue(access.principalId)}
              />
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  const handleAccessSuggestionsFetchRequested = (value) => {
    if (suggestedUsers.length) setSuggestedUsers([]);

    const isValidEmail = isInvitationEmailOk(value);

    if (isValidEmail) {
      setEmail(value);
    }
  };

  const addInvitedAccess = (idArray) => {
    const accesses = [...newAccesses].filter(
      (access) => idArray.indexOf(access.principalId) > -1
    );
    if (props.type === 'USER') {
      suggestedUsers.map((user) => {
        if (
          idArray.indexOf(user.id) > -1 &&
          !accesses.find((access) => access.principalId === user.id)
        ) {
          accesses.push({
            entityId: props.accountId || props.documentId,
            entityType: props.accountId ? 'Account' : 'Document',
            action: props.isViewerAccess
              ? props.ROLES.VIEWER_READER
              : newAccessesAction,
            principalId: user.id,
            email: user.email,
          });
        }
        return true;
      });
    } else if (props.type === 'TOKEN') {
      props.tokens.map((token) => {
        if (
          idArray.indexOf(token.id) > -1 &&
          !accesses.find((access) => access.principalId === token.id)
        ) {
          accesses.push({
            entityId: props.accountId || props.documentId,
            entityType: props.accountId ? 'Account' : 'Document',
            action: props.isViewerAccess
              ? props.ROLES.VIEWER_READER
              : newAccessesAction,
            principalId: token.id,
            name: token.name,
          });
        }
        return true;
      });
    } else if (props.type === 'DOMAIN') {
      if (
        idArray.indexOf(domainName) > -1 &&
        !accesses.find((access) => access.principalId === domainName)
      ) {
        accesses.push({
          entityId: props.accountId || props.documentId,
          entityType: props.accountId ? 'Account' : 'Document',
          action: props.isViewerAccess
            ? props.ROLES.VIEWER_READER
            : newAccessesAction,
          principalId: domainName,
        });
      }
    }
    setNewAccesses(accesses);
    setSuggestedUsers([]);
  };

  const removeInvitedUser = (userId) => {
    setNewAccesses(
      newAccesses.filter(
        (invitedAccess) => invitedAccess.principalId !== userId
      )
    );
  };

  // const handleAccessSelectedDocsChanged = (principalId: string, values: string[]) => {
  //     const newAccesses = accesses ? [...accesses] : [];
  //     const access = newAccesses.find((access) => access.principalId === principalId);
  //     if(access) {
  //         access.entityIds = values;
  //         setAccesses(newAccesses);
  //     }
  // };

  const copyValue = (value) => {
    const textarea = document.createElement('textarea');
    textarea.textContent = props.accountId
      ? getAccountLink(selectedAccount || props.account, value)
      : getDocLink(selectedDoc, versions, value);
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    setTooltipTitle('Copied!');
    setTimeout(() => {
      setTooltipTitle(t('copy-token'));
    }, 1000);
  };

  const tagRender = (props, type: string = 'User') => {
    const { value } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const access = newAccesses.find(
      (newAccess) => newAccess.principalId === value
    );
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={(event) => {
          event.preventDefault();
          removeInvitedUser(value);
        }}
        style={{ marginRight: 3 }}
      >
        {type === 'DOMAIN'
          ? access?.principalId
          : type === 'TOKEN'
            ? access?.name
            : access?.principalId === access?.email
              ? 'Invite: ' + access?.email
              : access?.email}
      </Tag>
    );
  };

  const typeTitle =
    props.type === 'DOMAIN'
      ? 'domains'
      : props.type === 'TOKEN'
        ? 'tokens'
        : 'members';

  const renderNewUserSection = () => {
    const entityTitle = props.entityTitle;
    const { classes } = props;
    return (
      <Grid
        className={`${classes.newUserBody} ${classes.borders}`}
        container
        direction="column"
      >
        <Col style={{ marginBottom: 5, display: 'flex' }}>
          <Typography style={{ marginRight: 5 }}>
            Add new {typeTitle} to {entityTitle}
          </Typography>
          {props.type === 'TOKEN' ? (
            <HelpSnippet title={t('token-help-text')} />
          ) : null}
        </Col>
        <Col xs={24} style={{ display: 'flex', alignItems: 'center' }}>
          <Col md={16} style={{ padding: '8px 8px 8px 0' }}>
            {props.type === 'TOKEN' ? (
              <Select
                mode="multiple"
                showSearch
                onChange={addInvitedAccess}
                onSearch={(value) => filterTokens(value)}
                value={newAccesses.map((access) => access.principalId)}
                tagRender={(props) => tagRender(props, 'TOKEN')}
                placeholder="Title"
                style={{ width: '100%' }}
                filterOption={false}
                defaultActiveFirstOption={false}
              >
                {filteredTokens.map((token) => (
                  <Option
                    key={token.id}
                    value={token.id}
                    style={
                      newAccesses.find((newAccess) => newAccess.id === token.id)
                        ? { color: '#1a73e8' }
                        : {}
                    }
                  >
                    {token.name}
                  </Option>
                ))}
              </Select>
            ) : props.type === 'DOMAIN' ? (
              <Select
                mode="multiple"
                showSearch
                onChange={addInvitedAccess}
                onSearch={(value) => domainNameValidation(value)}
                value={newAccesses.map((access) => access.principalId)}
                tagRender={(props) => tagRender(props, 'DOMAIN')}
                placeholder="domain.com"
                style={{ width: '100%' }}
                filterOption={false}
                defaultActiveFirstOption={false}
              >
                {domainName && (
                  <Option
                    key={domainName}
                    value={domainName}
                    style={
                      newAccesses.find(
                        (newAccess) => newAccess.id === domainName
                      )
                        ? { color: '#1a73e8' }
                        : {}
                    }
                  >
                    {domainName}
                  </Option>
                )}
              </Select>
            ) : (
              <Select
                mode="multiple"
                showSearch
                onChange={addInvitedAccess}
                onSearch={(value) =>
                  handleAccessSuggestionsFetchRequested(value)
                }
                value={newAccesses.map((access) => access.principalId)}
                tagRender={tagRender}
                placeholder="Email"
                style={{ width: '100%' }}
                filterOption={false}
                defaultActiveFirstOption={false}
              >
                {suggestedUsers.map((user) => (
                  <Option
                    key={user.id}
                    value={user.id}
                    style={
                      newAccesses.find(
                        (newAccess) => newAccess.email === user.email
                      )
                        ? { color: '#1a73e8' }
                        : {}
                    }
                  >
                    {user.id === user.email
                      ? 'Invite: ' + user.email
                      : user.email}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Grid
            xs={8}
            item
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: props.isViewerAccess ? 'flex-end' : 'normal',
            }}
          >
            {!props.isViewerAccess && (
              <Grid xs item>
                <SelectInput
                  field="NewRole"
                  value={newAccessesAction}
                  onChange={handleNewAccessActionChanged}
                >
                  {Object.keys(props.ROLES)
                    .filter((key) => key !== 'VIEWER_READER')
                    .map((key) => (
                      <Option key={key} value={key}>
                        {props.ROLES[key].charAt(0) +
                          props.ROLES[key]
                            .slice(1)
                            .replace('_', ' ')
                            .toLowerCase()}
                      </Option>
                    ))}
                </SelectInput>
              </Grid>
            )}
            <Col>
              <Tooltip title={`Add ${typeTitle}`}>
                <span>
                  <IconButton
                    aria-label={`Add ${typeTitle}`}
                    disabled={!newAccesses.length}
                    onClick={() => handleAddAccesses()}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <PlanLimitationDialog
                accountPlan="Free"
                open={openUserLimitationDialog}
                textMessage={t('dialog-user-limitation')}
                textTitle={t('dialog-cant-add-users')}
                handleCloseLimitationDialog={() => {
                  setOpenUserLimitationDialog(false);
                }}
              />
            </Col>
          </Grid>
        </Col>
      </Grid>
    );
  };

  const { classes } = props;

  const entityTitle = props.entityTitle;
  const existingAccessesSearchPhrase =
    existingAccessSearchPhrase?.toLowerCase();
  return (
    <React.Fragment>
      {props.title ? (
        <Typography
          style={{ margin: '10px 0 15px 0' }}
          component="h1"
          variant="h6"
        >
          {props.title}
        </Typography>
      ) : null}
      {renderNewUserSection()}
      <Toolbar className={classes.existingMemberToolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          color="inherit"
          noWrap
        >
          Existing {typeTitle}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.searchExistingMembers}>
          <TextInput
            className={classes.searchExistingMembersInput}
            field="Title"
            placeholder={`Find existing ${typeTitle} …`}
            value={existingAccessSearchPhrase}
            onChange={handleFindExistingMembersChange}
            required={false}
            showError={false}
          />
          <IconButton
            className={classes.searchExistingMembersIcon}
            aria-label="Search"
          >
            <SearchIcon />
          </IconButton>
        </div>
      </Toolbar>
      <List>
        {!props.loading &&
          !loading &&
          (props.type !== 'TOKEN' || props.tokens) &&
          accesses
            ?.filter(
              (access) =>
                access &&
                (props.isViewerAccess
                  ? access.action === props.ROLES.VIEWER_READER
                  : access.action !== props.ROLES.VIEWER_READER) &&
                (!existingAccessesSearchPhrase ||
                  (access.principalType === 'DOMAIN'
                    ? access.principalId
                    : access.principalType === 'TOKEN'
                      ? getTokenName(access.principalId)
                      : access.email
                  )
                    .toLowerCase()
                    .includes(existingAccessesSearchPhrase))
            )
            .map(
              (access) =>
                access && (
                  <span
                    key={access.id}
                    style={{
                      margin: '5px 0',
                      display: 'block',
                      padding: '5px 0',
                    }}
                  >
                    <ListItem className={classes.listItem} divider={true}>
                      {access.principalType !== 'TOKEN' &&
                        access.principalType !== 'DOMAIN' && (
                          <Avatar
                            className={classes.avatar}
                            src={access.photoUrl}
                          >
                            {access.email[0].toUpperCase()}
                          </Avatar>
                        )}
                      <ListItemText
                        primary={renderSearchedText(
                          access.email ||
                            getTokenName(access.principalId) ||
                            access.principalId,
                          existingAccessesSearchPhrase,
                          access
                        )}
                      />
                      <ListItemSecondaryAction
                        style={{
                          top: '35%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {!props.isViewerAccess ? (
                          <SelectInput
                            disabled={access.entityType !== props.entityType}
                            field="action"
                            value={
                              Object.keys(props.ROLES).indexOf(access.action) >
                              -1
                                ? access.action
                                : access.action.charAt(0).toUpperCase() +
                                  access.action
                                    .slice(1)
                                    .replace('_', ' ')
                                    .toLowerCase() +
                                  ' (Inherited from organization)'
                            }
                            onChange={(value) =>
                              handleAccessActionChanged(
                                access.principalId,
                                value
                              )
                            }
                            className={classes.roleNames}
                          >
                            {Object.keys(props.ROLES)
                              .filter((key) => key !== 'VIEWER_READER')
                              .map((key) => (
                                <Option key={key} value={key}>
                                  {props.ROLES[key].charAt(0).toUpperCase() +
                                    props.ROLES[key]
                                      .slice(1)
                                      .replace('_', ' ')
                                      .toLowerCase() +
                                    (access.entityType !== props.entityType
                                      ? ' (Inherited from organization)'
                                      : '')}
                                </Option>
                              ))}
                          </SelectInput>
                        ) : null}
                        {access.entityType === props.entityType ? (
                          <Tooltip
                            title={`Remove ${access.email || getTokenName(access.principalId) || access.principalId}`}
                          >
                            <IconButton
                              aria-label={`Remove ${access.email || getTokenName(access.principalId) || access.principalId}`}
                              onClick={() =>
                                handleClickOpenDeleteDialog(access)
                              }
                              style={{ padding: 12 }}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <span style={{ width: 48, height: 48 }}></span>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </span>
                )
            )}
        {(props.loading ||
          loading ||
          !accesses ||
          (props.type === 'TOKEN' && !props.tokens)) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
      </List>
      {selectedAccess && (
        <ConfirmationDialog
          title={'Remove access?'}
          message={`Are you sure you want to remove ${selectedAccess.email || getTokenName(selectedAccess.principalId) || selectedAccess.principalId} from the ${entityTitle}?`}
          isOpen={openDeleteDialog}
          onDialogClosed={handleCloseDeleteDialog}
          onDialogConfirmed={() => handleRemoveAccess(handleCloseDeleteDialog)}
        />
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(withSnackbar(ManageAccesses));
