import React, { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';

import PublishRequestsHistoryList from './publish-request-history-list';
import PublishRequestsHistoryTopicList, {
  SelectedTopicType,
  TopicType,
} from './topic-list';
import TopicVersionDiffViewer from '../../../topic/diff';
import { getLanguageCode } from '../../../../helpers/route';
import useGetPublishRequestDiffs, {
  GetPublishRequestDiffsParams,
} from '../../../../api-hooks/publish-request/useGetPublishRequestDiffs';
import { sleep } from '../../../../helpers/common';
import { PublishRequestDiff } from '../../../../api-schemas/publish-request';

import './styles.css';

const PublishesHistory = () => {
  const [publishRequestsDiff, setPublishRequestsDiff] = useState<any>();
  const [publishRequestTopics, setPublishRequestTopics] = useState<TopicType[]>(
    []
  );
  const [isTopicDeleted, setIsTopicDeleted] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [loadingTopicDiff, setLoadingTopicDiff] = useState<boolean>();
  const [selectedTopic, setSelectedTopic] = useState<SelectedTopicType>();
  const [publishRequestDiffRequestData, setPublishRequestDiffRequestData] =
    useState<GetPublishRequestDiffsParams>({});

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { getPublishRequestDiffs } = useGetPublishRequestDiffs(
    publishRequestDiffRequestData
  );

  const getPublishRequestsDiff = async (
    selectedPublishRequestsToCompare: {
      sourcePublishRequest: any;
      targetPublishRequest: any;
    },
    languageCode: string | null = null
  ) => {
    setPublishRequestTopics(
      selectedPublishRequestsToCompare.targetPublishRequest.topics
    );
    setLoading(true);
    setPublishRequestDiffRequestData({
      sourcePublishRequestId:
        selectedPublishRequestsToCompare.sourcePublishRequest?.id,
      destPublishRequestId:
        selectedPublishRequestsToCompare.targetPublishRequest?.id,
      languageCode,
    });

    await sleep(1000);

    getPublishRequestDiffs()
      .then((response) => {
        setPublishRequestsDiff(response.data);

        if (response.data) {
          getTopicDiff(response.data[0], response.data);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(undefined);
        enqueueSnackbar(t('error.getPublishRequestDiff'), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  const getTopicDiff = (
    selectedTopic: PublishRequestDiff,
    topics = publishRequestsDiff
  ) => {
    setSelectedTopic({
      topicId: selectedTopic.topicId,
      topicSlug: topics.find(
        (topic) => topic.topicId === selectedTopic.topicId
      )!.topicSlug,
      publishedTopicVersionId: selectedTopic.publishedTopicVersionId,
      previousPublishedTopicVersionId:
        selectedTopic.previousPublishedTopicVersionId,
    });

    if (!selectedTopic.topicTitle) {
      setIsTopicDeleted(true);
      setLoading(false);

      return;
    }

    setIsTopicDeleted(false);
    setLoadingTopicDiff(true);

    setTimeout(() => {
      setLoadingTopicDiff(false);
    }, 1000);
  };

  return (
    <Fragment>
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
          <PublishRequestsHistoryList
            getPublishRequestsDiff={getPublishRequestsDiff}
          />
        </div>

        {loading === undefined ? (
          <div
            style={{
              width: 'calc(100% - 250px)',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: 20, fontWeight: 'bold' }}>
              {t('selectPublishRequestInHistoryMessage')}
            </span>
          </div>
        ) : loading ? (
          <Skeleton
            variant={'rectangular'}
            style={{
              margin: 15,
              marginLeft: 8,
              width: 'calc(100% - 273px)',
              height: 'calc(100% - 40px)',
            }}
          />
        ) : (
          <Fragment>
            <div
              style={{
                width: '250px',
                minWidth: 250,
                height: '100%',
                overflowY: 'auto',
                borderRight: '1px solid #cacaca',
                marginLeft: 10,
                paddingRight: 10,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: 55,
                  paddingLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #cacaca',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>{t('topics')}</span>
              </div>

              <div
                id="publish-requests-history-topic-list"
                style={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
              >
                <PublishRequestsHistoryTopicList
                  getTopicDiff={getTopicDiff}
                  topics={publishRequestsDiff}
                  selectedTopic={selectedTopic}
                />
              </div>
            </div>

            <div
              style={{
                width: 'calc(100% - 550px)',
                marginLeft: 30,
                height: '100%',
              }}
            >
              {!selectedTopic || isTopicDeleted ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  {isTopicDeleted ? t('error-deleted-topic') : t('selectTopic')}
                </div>
              ) : loadingTopicDiff ? (
                <Skeleton
                  variant={'rectangular'}
                  style={{
                    margin: 15,
                    marginLeft: 8,
                    height: 'calc(100% - 40px)',
                  }}
                />
              ) : (
                <TopicVersionDiffViewer
                  topicId={selectedTopic?.topicId}
                  topicSlug={selectedTopic?.topicSlug}
                  topicVersionId={selectedTopic?.publishedTopicVersionId}
                  previousVersionId={
                    selectedTopic?.previousPublishedTopicVersionId
                  }
                  languageCode={getLanguageCode()}
                  topics={publishRequestTopics}
                  loadingHistory={loading}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default PublishesHistory;
