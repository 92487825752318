import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { GenerateClientSecretRequest } from '../../api-schemas/account';

const createAccountClientSecretRequest = (
  data: GenerateClientSecretRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Account/SetupIntent', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateAccountClientSecret() {
  const {
    mutateAsync: createAccountClientSecret,
    isPending: isCreatingAccountClientSecret,
    isSuccess: isSuccessCreatingAccountClientSecret,
  } = useMutation({
    mutationFn: createAccountClientSecretRequest,
  });

  return {
    createAccountClientSecret,
    isCreatingAccountClientSecret,
    isSuccessCreatingAccountClientSecret,
  };
}
