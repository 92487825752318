import React from 'react';
import { Menu } from 'antd';

import UserAvatar from '../../user-avatar';

const WatchersListItem = ({ watcher }) => {
  return (
    <Menu.Item key="0">
      <div style={{ margin: '10px 15px' }}>
        <UserAvatar
          displayName={watcher.username}
          photoUrl={watcher.photoUrl}
          size="small"
        />
        <span style={{ marginLeft: 10 }}>{watcher.username}</span>
      </div>
    </Menu.Item>
  );
};

export default WatchersListItem;
