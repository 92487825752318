import React, { useContext, useState } from 'react';
import { IconButton, ListItemText, MenuItem, Popover } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Input, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { DocumentContext } from '../../../../contexts/document';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { DocumentService } from '../../../../services/document';
import { localizeKey } from '../../../../helpers/localize-key';
import PlanLimitationDialog from '../../../plan-limitation-dialog';
import { convertToSlug } from '../../../../helpers/common';
import useGetCurrentPlan from '../../../../api-hooks/plan/useGetCurrentPlan';
import { FolderContext } from '../../../../contexts/folder';
import useGetDocuments from '../../../../api-hooks/document/useGetDocuments';

const { Text } = Typography;

const DocumentListMenu = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [copiedTitleDocument, setCopiedTitleDocument] = useState<string>('');
  const [copiedSlugDocument, setCopiedSlugDocument] = useState<string>('');
  const [openPlanLimitationDialog, setOpenPlanLimitationDialog] =
    useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { getCurrentPlan, currentPlan } = useGetCurrentPlan(
    props.doc.account.id
  );

  const { selectedFolderId } = useContext(FolderContext);

  const { getDocuments } = useGetDocuments(
    props.doc.account.id,
    selectedFolderId,
    false
  );

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { documents, setSelectedDoc } = useContext(DocumentContext);

  const open = Boolean(menuOpen);

  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenCopyDialog = () => {
    setOpenCopyDialog(true);
  };

  const handleCloseCopyDialog = () => {
    setOpenCopyDialog(false);
  };

  const handleRemove = () => {
    setLoading(true);
    const documentService = new DocumentService();
    documentService
      .removeDocument(props.doc.id)
      .then(async () => {
        await getDocuments();

        setSelectedDoc(null);
        setLoading(false);
        setMenuOpen(null);

        handleCloseDeleteDialog();
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(
          error.statusText
            ? t(`error.${localizeKey(error.statusText)}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
  };

  const handleChangeCopiedTitle = (event) => {
    setCopiedTitleDocument(event.target.value);
    setCopiedSlugDocument(convertToSlug(event.target.value));
  };

  const handleChangeCopiedSlug = (event) => {
    setCopiedSlugDocument(convertToSlug(event.target.value));
  };

  const handleCopy = () => {
    setLoading(true);

    const numberOfManuals = documents.filter(
      (document) => document.account.id === props.doc.account.id
    ).length;

    getCurrentPlan(props.doc.account.id)
      .then(({ data }) => {
        if (
          data &&
          data.features.numberOfManuals !== -1 &&
          numberOfManuals >= data.features.numberOfManuals
        ) {
          setLoading(false);
          handleCloseCopyDialog();
          setOpenPlanLimitationDialog(true);
          return;
        } else {
          if (copiedSlugDocument === '' || copiedTitleDocument === '') {
            enqueueSnackbar(t('title-slug-cannot-be-empty'), {
              variant: 'error',
            });
            setLoading(false);
            return;
          }
          const documentService = new DocumentService();
          documentService
            .copyDocument(props.doc.id, copiedTitleDocument, copiedSlugDocument)
            .then(() => {
              setSelectedDoc(null);
              setLoading(false);
              setOpenCopyDialog(false);

              queryClient.invalidateQueries({ queryKey: ['documents'] });
              queryClient.invalidateQueries({
                queryKey: selectedFolderId
                  ? ['documents', false, props.doc.account.id, selectedFolderId]
                  : ['documents', false, props.doc.account.id],
              });
            })
            .catch(async (error) => {
              const errorJson = await error.json();
              if (
                errorJson.errors[0].field === 'MaximumNumberOfTopicsExceeded'
              ) {
                setLoading(false);
                setOpenCopyDialog(false);
              } else if (errorJson.errors[0].field === 'Duplicate') {
                enqueueSnackbar(t('duplicate-slug'), {
                  variant: 'error',
                });
                setLoading(false);
              } else {
                enqueueSnackbar(
                  error.statusText
                    ? t(`error.${localizeKey(error.statusText)}`)
                    : t('error.unknown'),
                  {
                    variant: 'error',
                  }
                );
                setLoading(false);
                setOpenCopyDialog(false);
              }
            });
        }
      })
      .catch(() => {
        props.enqueueSnackbar(t('error.cant-get-plan'), {
          variant: 'error',
        });
      });
  };

  const docTitle = props.doc.title;

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="default"
        style={{ marginRight: 8 }}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Popover
        id="long-menu"
        anchorEl={menuOpen}
        keepMounted
        open={open}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          style: {
            width: 200,
            boxShadow: 'none',
            border: '1px solid #d8d4d4',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {[
          {
            text: 'Edit Content',
            url: `/document/${props.doc.account.id}/${props.doc.id}/content`,
            currentHostName: window.location.hostname,
            key: 'document-edit-content',
          },
          {
            text: 'Settings',
            url: `/document/${props.doc.account.id}/${props.doc.id}/settings`,
            currentHostName: window.location.hostname,
            key: 'document-general',
          },
          {
            text: 'Languages',
            url: `/document/${props.doc.account.id}/${props.doc.id}/language`,
            key: 'languages',
          },
          {
            text: 'Variants',
            url: `/document/${props.doc.account.id}/${props.doc.id}/version`,
            key: 'variants',
          },
          {
            text: 'Manage Team',
            url: `/document/${props.doc.account.id}/${props.doc.id}/manage-team`,
            key: 'manage-team',
          },
          {
            text: 'Feedback',
            url: `/document/${props.doc.account.id}/${props.doc.id}/feedback?ver=${props.doc.defaultDocumentVersionSlug}&lang=${props.doc.defaultLanguageCode}`,
            key: 'feedback',
          },
          {
            text: 'Quality Metrics',
            url: `/document/${props.doc.account.id}/${props.doc.id}/metrics?ver=${props.doc.defaultDocumentVersionSlug}&lang=${props.doc.defaultLanguageCode}`,
            key: 'metrics',
            disabled: props.doc.metrics === null,
          },
          {
            text: 'View Metrics',
            url: `/document/${props.doc.account.id}/${props.doc.id}/view-analytics?`,
            key: 'view-analytics',
            disabled: props.doc.metrics === null,
          },
          {
            text: 'Images',
            url: `/document/${props.doc.account.id}/${props.doc.id}/images?ver=${props.doc.defaultDocumentVersionSlug}&lang=${props.doc.defaultLanguageCode}`,
            key: 'images',
          },
          {
            text: 'Copy',
            onClick: handleOpenCopyDialog,
            key: 'copy',
          },
          {
            text: 'Delete',
            onClick: handleClickOpenDeleteDialog,
            key: 'delete',
          },
        ].map((item) => (
          <MenuItem
            disabled={item.disabled ? item.disabled : false}
            key={item.key}
            onClick={() => {
              setSelectedDoc(props.doc);
              if (item.onClick) {
                item.onClick();
              } else {
                if (item.currentHostName)
                  history.push(item.url, {
                    prevHostName: item.currentHostName,
                  });
                else history.push(item.url);
              }
            }}
          >
            <ListItemText
              style={item.text === 'Delete' ? { color: 'rgb(220, 0, 78)' } : {}}
              primary={t(`${item.text}`)}
            />
          </MenuItem>
        ))}
      </Popover>
      <ConfirmationDialog
        title={t<string>('rm-doc')}
        message={
          <Trans i18nKey="doclist.action.confirm-disable-doc">
            Are you sure you want to remove {{ docTitle }} document?
          </Trans>
        }
        isOpen={openDeleteDialog}
        loading={loading}
        onDialogClosed={handleCloseDeleteDialog}
        onDialogConfirmed={() => handleRemove()}
      />
      <ConfirmationDialog
        title={t<string>('copy-doc')}
        message={
          <div>
            <Trans i18nKey="doclist.action.confirm-disable-doc">
              You are copying {{ docTitle }} document. Please choose a new Title
              and Slug for the copied document.
            </Trans>
            <div style={{ display: 'block' }}>
              <div style={{ marginTop: 10 }}>
                <Text style={{ display: 'block' }}>Title</Text>
                <Input
                  value={copiedTitleDocument}
                  onChange={handleChangeCopiedTitle}
                  style={{ width: '100%', display: 'block', maxWidth: '600px' }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <Text style={{ display: 'block' }}>Slug</Text>
                <Input
                  value={copiedSlugDocument}
                  onChange={handleChangeCopiedSlug}
                  style={{ width: '100%', display: 'block', maxWidth: '600px' }}
                />
              </div>
            </div>
          </div>
        }
        isOpen={openCopyDialog}
        loading={loading}
        onDialogClosed={handleCloseCopyDialog}
        onDialogConfirmed={() => handleCopy()}
      />
      <PlanLimitationDialog
        accountPlan={currentPlan?.name === 'Free' ? 'Free' : 'Business'}
        open={openPlanLimitationDialog}
        textMessage={t('dialog-topic-limitation-by-copying')}
        textTitle={t('dialog-cant-add-topics')}
        handleCloseLimitationDialog={() => {
          setOpenPlanLimitationDialog(false);
        }}
      />
    </Fragment>
  );
};

export default DocumentListMenu;
