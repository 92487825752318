import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AccountWebAssistantSettings } from '../../api-schemas/account';

const updateAccountWebAssistantSettingsRequest = ({
  accountId,
  data,
}: {
  accountId: string;
  data: AccountWebAssistantSettings;
}) => {
  const body = JSON.stringify(data);

  return axios
    .put(`/api/Account/${accountId}/web-assistant-settings`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountWebAssistantSettings(
  accountId: string
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountWebAssistantSettings,
    isPending: isUpdatingAccountWebAssistantSettings,
    isSuccess: isSuccessUpdatingAccountWebAssistantSettings,
  } = useMutation({
    mutationFn: updateAccountWebAssistantSettingsRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'webAssistantSettings', accountId],
      });
    },
  });

  return {
    updateAccountWebAssistantSettings,
    isUpdatingAccountWebAssistantSettings,
    isSuccessUpdatingAccountWebAssistantSettings,
  };
}
