import React, { useContext } from 'react';
import { DocumentContext } from '../../../../../contexts/document';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import { Button, Dropdown, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { shortenTitle } from '../../../../../helpers/common';
import { MdOutlinePublish } from 'react-icons/md';
import { TopicContext } from '../../../../../contexts/topic';
import { MenuProps } from 'antd/lib';

const useStyles: any = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 11,
  },
  dropDownButton: {
    '& > button:first-child': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 8px',
    },
  },
}));

const PublishRequests = ({ publishRequests }) => {
  const { selectedDoc } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = (publishRequestId: string) => {
    history.push(
      `/document/${selectedDoc.account.id}/${selectedDoc.id}/publish-request/${publishRequestId}`,
      { topicId: selectedTopic!.topicId }
    );
  };

  const renderPublishRequestsList = (): MenuProps => {
    const items = selectedTopic?.openPublishRequests
      .split(',')
      .map((publishRequestId) => {
        const publishRequest = publishRequests.find(
          (pr) => pr.id === publishRequestId
        );
        if (publishRequest) {
          return {
            key: publishRequestId,
            onClick: () => handleOnClick(publishRequestId),
            label: (
              <div style={{ margin: '5px 10px' }}>
                <Tooltip
                  placement="bottom"
                  title={`${t('publish-request')}: ${publishRequest.title}`}
                >
                  <div>{shortenTitle(publishRequest.title, 20)}</div>
                </Tooltip>
              </div>
            ),
          };
        } else {
          return null;
        }
      });
      return {
        items: items || []
      }
  };

  if (selectedTopic) {
    if (selectedTopic?.openPublishRequests?.split(',').length > 1) {
      return (
        <Dropdown.Button
          style={{ marginRight: 5 }}
          className={classes.dropDownButton}
          menu={renderPublishRequestsList()}
          placement="bottom"
          icon={<DownOutlined />}
        >
          <Tooltip placement="bottom" title={t<string>('publish-requests')}>
            <MdOutlinePublish style={{ fontSize: 16, minWidth: 16 }} />
          </Tooltip>
        </Dropdown.Button>
      );
    } else if (selectedTopic?.openPublishRequests?.split(',').length === 1) {
      const publishRequestId = selectedTopic?.openPublishRequests.split(',')[0];
      const publishRequest = publishRequests?.find(
        (pr) => pr.id === publishRequestId
      );
      if (publishRequest) {
        return (
          <Tooltip
            placement="bottom"
            title={`${t('publish-request')}: ${publishRequest.title}`}
          >
            <Button
              style={{
                marginRight: 5,
                display: 'flex',
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={classes.dropDownButton}
              onClick={() => handleOnClick(publishRequestId)}
            >
              <MdOutlinePublish style={{ fontSize: 16, minWidth: 16 }} />
            </Button>
          </Tooltip>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return (
      <Box className={classes.button} style={{ marginRight: 5 }}>
        <Skeleton width={64} height={32} />
      </Box>
    );
  }
};

export default PublishRequests;
