import React, { useEffect, useState } from 'react';
import { DocumentService } from '../../../../../services/document';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const DocumentTheme = (props) => {
  const [hasImage, setHasImage] = useState<boolean>(true);
  const documentService = new DocumentService();

  useEffect(() => {
    if (props.document) {
      documentService
        .getLogo(props.document.id, props.document.updateDateTime)
        .then((status) => {
          if (status === 200) setHasImage(true);
          else setHasImage(false);
        })
        .catch(() => {
          setHasImage(false);
        });
    }
    // eslint-disable-next-line
  }, [props.document]);

  return hasImage ? (
    <LazyLoadImage
      alt={props.document.title}
      effect="blur"
      style={{
        height: '169px',
        width: '169px',
        borderRadius: '4px',
      }}
      onError={() => {
        setHasImage(false);
      }}
      src={`${localStorage.webApiAddress}document/${props.document.id}/logo`}
    />
  ) : (
    <div
      style={{
        height: '169px',
        width: '169px',
        borderRadius: '4px',
      }}
    ></div>
  );
};

export default DocumentTheme;
