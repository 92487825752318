import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CopyTopicRequest } from '../../api-schemas/topic';

const copyTopicRequest = (data: CopyTopicRequest) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/Topic/Copy', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCopyTopic({
  documentVersionId,
  languageCode,
}: {
  documentVersionId: string;
  languageCode: string;
}) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: copyTopic,
    isPending: isCopyingTopic,
    isSuccess: isSuccessCopyingTopic,
  } = useMutation({
    mutationFn: copyTopicRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draftTopicTree', documentVersionId, languageCode],
      });
    },
  });

  return {
    copyTopic,
    isCopyingTopic,
    isSuccessCopyingTopic,
  };
}
