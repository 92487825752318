import React, { useState, useEffect } from 'react';
import { IconButton, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import firebaseApp from '../../helpers/firebase';
import NotificationsList from './list';
import useGetUserNotifications from '../../api-hooks/user/useGetUserNotifications';
import useResetUserNotificationsCount from '../../api-hooks/user/useResetUserNotificationsCount';

const useStyles: any = makeStyles(() => ({
  iconButton: {
    position: 'relative',
  },
  notificationCount: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: 'red',
    borderRadius: '50%',
    right: '8px',
    top: '8px',
    fontSize: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
}));

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [notificationCount, setNotificationCount] = useState<
    number | undefined
  >(undefined);
  const [notifications, setNotifications] = useState<any[] | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const { getNotifications: getNotificationsFromServer } =
  useGetUserNotifications(notifications?.at(-1)?.createDateTime ?? null);

  const { resetUserNotificationsCount } = useResetUserNotificationsCount();

  // eslint-disable-next-line
  let pageSize: number = 10;

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    if (notificationCount) resetUserNotificationsCount();

    setNotificationCount(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = (useHasMore: boolean = true) => {
    if ((hasMore && useHasMore) || !useHasMore) {
      getNotificationsFromServer()
        .then((response) => {
          if (!response.data || response.data.length < pageSize) {
            setHasMore(false);
          }

          response.data?.sort(
            (a, b) =>
              new Date(a.createDateTime).getTime() -
              new Date(b.createDateTime).getTime()
          );

          setNotifications(response.data || []);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const q = query(
          collection(db, 'Users'),
          where('FirebaseUserId', '==', user.uid)
        );
        onSnapshot(q, (querySnapshot) => {
          if (querySnapshot && querySnapshot.docs && querySnapshot.docs[0]) {
            getNotifications(false);
            const notificationCount =
              querySnapshot.docs[0].data().NotificationCount;
            setNotificationCount(notificationCount);
            document.title = notificationCount
              ? `(${notificationCount > 100 ? '+99' : notificationCount}) Sonat | User Document Editor`
              : 'Sonat | User Document Editor';
            const favicon = document.querySelector("link[rel*='icon']");
            if (favicon && notificationCount) {
              favicon.setAttribute(
                'href',
                process.env.PUBLIC_URL + '/favicon-red.ico'
              );
            } else if (favicon) {
              favicon.setAttribute(
                'href',
                process.env.PUBLIC_URL + '/favicon.ico'
              );
            }
          }
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginRight: 5 }}>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        className={classes.iconButton}
      >
        <NotificationsNoneIcon />
        {notificationCount ? (
          <span className={classes.notificationCount}>
            {notificationCount > 99 ? '+99' : notificationCount.toString()}
          </span>
        ) : null}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        <NotificationsList
          notifications={notifications}
          setNotifications={setNotifications}
          fullWidth={false}
          getNotifications={getNotifications}
          setAnchorEl={setAnchorEl}
        />
      </Menu>
    </div>
  );
};

export default Notification;
