import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { ComponentVersion } from '../../api-schemas/component';

const getComponentVersionRequest = (componentVersionId: string) => {
  return axios
    .get<ComponentVersion>(`/api/Component/Version/${componentVersionId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetComponentVersionWithId(
  componentVersionId: string
) {
  const {
    data,
    refetch: getComponentVersion,
    isFetching: isLoadingComponentVersion,
    isSuccess: isSuccessLoadingComponentVersion,
  } = useQuery<ComponentVersion>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['component-version-with-id', componentVersionId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(componentVersionId),
    queryFn: () => getComponentVersionRequest(componentVersionId),
  });

  const componentVersion = useMemo(() => data || {}, [data]);

  return {
    componentVersion,
    isLoadingComponentVersion,
    isSuccessLoadingComponentVersion,

    getComponentVersion,
  };
}
