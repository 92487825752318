import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { List, Tooltip, Card } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

import ManageVariableDialog from '../manage-variable-dialog';
import { showErrorMessage } from '../../../../helpers/common';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import useDeleteVariable from '../../../../api-hooks/variable/useDeleteVariable';
import useUpdateVariable from '../../../../api-hooks/variable/useUpdateVariable';
import {
  CreateVariableRequestType,
  Variable,
} from '../../../../api-schemas/variable';

const VariableItem = ({
  insertMode,
  accountId,
  variable,
  handleInsertVariable,
}: {
  insertMode?: boolean;
  accountId: string;
  variable: Variable;
  handleInsertVariable?: (variable: Variable) => void;
}) => {
  const [openEditVariableDialog, setOpenEditVariableDialog] =
    useState<boolean>(false);
  const [openDeleteVariableDialog, setOpenDeleteVariableDialog] =
    useState<boolean>(false);

  const variableName = variable.title || variable.slug;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { deleteVariable } = useDeleteVariable(accountId);
  const { updateVariable, isUpdatingVariable } = useUpdateVariable(accountId);

  const handleUpdateVariable = (updatedVariable: CreateVariableRequestType) => {
    updateVariable(updatedVariable)
      .then(() => {
        setOpenEditVariableDialog(false);
      })
      .catch(() => {
        showErrorMessage(enqueueSnackbar, t('error.updateVariable'));
      });
  };

  const handleDeleteVariable = () => {
    deleteVariable(variable.id).catch(() => {
      showErrorMessage(enqueueSnackbar, t('error.deleteVariable'));
    });
  };

  return (
    <List.Item>
      <Card
        title={variable.title || variable.slug}
        actions={
          insertMode
            ? [
                <Tooltip key={0} title={t('insert')} showArrow>
                  <DoneIcon
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    onClick={() => handleInsertVariable?.(variable)}
                  />
                </Tooltip>,
              ]
            : [
                <Tooltip key={0} title={t('edit')} showArrow>
                  <EditIcon
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    onClick={() => setOpenEditVariableDialog(true)}
                  />
                </Tooltip>,
                <Tooltip key={1} title={t('delete')} showArrow>
                  <DeleteIcon
                    style={{
                      cursor: 'pointer',
                      color: '#f41a1abf',
                      fontSize: 16,
                    }}
                    onClick={() => setOpenDeleteVariableDialog(true)}
                  />
                </Tooltip>,
              ]
        }
        size="small"
      >
        {variable.value}
      </Card>

      {openEditVariableDialog ? (
        <ManageVariableDialog
          accountId={accountId}
          onOk={handleUpdateVariable}
          handleClose={() => setOpenEditVariableDialog(false)}
          isOpen={openEditVariableDialog}
          existingVariable={variable}
          loading={isUpdatingVariable}
        />
      ) : openDeleteVariableDialog ? (
        <ConfirmationDialog
          isOpen={openDeleteVariableDialog}
          onDialogClosed={() => setOpenDeleteVariableDialog(false)}
          onDialogConfirmed={handleDeleteVariable}
          title={t<string>('deleteVariableTitle')}
          message={
            <Trans i18nKey="deleteVariableMessage">
              {' '}
              Are you sure you want to delete {{ variableName }} variable ?{' '}
            </Trans>
          }
        />
      ) : null}
    </List.Item>
  );
};

export default VariableItem;
