import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, List } from 'antd';

import VariableItem from './variable-item';
import ManageVariableDialog from './manage-variable-dialog';
import { showErrorMessage } from '../../../helpers/common';
import {
  CreateVariableRequestType,
  Variable,
} from '../../../api-schemas/variable';
import useCreateVariable from '../../../api-hooks/variable/useCreateVariable';
import useGetVariables from '../../../api-hooks/variable/useGetVariables';

const Variables = ({ accountId }: { accountId: string }) => {
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { createVariable, isCreatingVariable } = useCreateVariable(accountId);
  const { variables, isLoadingVariables } = useGetVariables(accountId);

  const handleAddVariable = (newVariable: CreateVariableRequestType) => {
    createVariable(newVariable)
      .then(() => {
        setOpenAddDialog(false);
      })
      .catch(() => {
        showErrorMessage(enqueueSnackbar, t('error.addVariable'));
      });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}
      >
        <span>{t('variables')}</span>

        <Button onClick={() => setOpenAddDialog(true)}>{t('add')}</Button>
      </div>

      <List
        loading={isLoadingVariables}
        grid={{ gutter: 16, column: 4 }}
        dataSource={variables}
        renderItem={(variable: Variable) => (
          <VariableItem
            accountId={accountId}
            key={variable.id}
            variable={variable}
          />
        )}
      />

      {openAddDialog ? (
        <ManageVariableDialog
          handleClose={() => setOpenAddDialog(false)}
          onOk={handleAddVariable}
          isOpen={openAddDialog}
          accountId={accountId}
          loading={isCreatingVariable}
        />
      ) : null}
    </div>
  );
};

export default Variables;
