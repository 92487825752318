


export function getNowDate(){
    const today = new Date();
    const now = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

    return now.toISOString().split('T')[0];
}

export function getLastWeekDate() {
    const today = new Date();
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);


    return lastWeekStart.toISOString().split('T')[0];
}


export function getLastMonthDate() {
    const today = new Date();
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);


    return lastMonthStart.toISOString().split('T')[0];
}


export function getLastYearDate() {
    const today = new Date();
    const lastYearStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 365);


    return lastYearStart.toISOString().split('T')[0];
}

export function formatDatePickerValue(inputDate){
    const date = new Date(inputDate)
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return formattedDate

}