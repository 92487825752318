import React from 'react';
import { Draggable as DndDraggable } from 'react-beautiful-dnd';

export function Draggable(props) {
  return (
    <DndDraggable draggableId={props.id} index={props.index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onMouseDown={() =>
              localStorage.setItem(
                'draggableComponent',
                JSON.stringify(props.data)
              )
            }
            style={{
              userSelect: 'none',
              padding: 0,
              margin: '0 8px 10px',
              width: 'calc(50% - 28px)',
              cursor: 'grab',
              minHeight: '50px',
              backgroundColor: snapshot.isDragging ? '#263B4A' : 'inherit',
              color: 'white',
              ...provided.draggableProps.style,
            }}
          >
            {props.children}
          </div>
        );
      }}
    </DndDraggable>
  );
}
