import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Select, Form, Tag, Col } from 'antd';
import { ProfileContext } from '../../../../../contexts/profile';

const { Option } = Select;

const ManageReviewersBox = ({
  firstStepApprovalUsers,
  mode,
  process,
  accesses,
  newReviewers,
  setNewReviewers,
  publish,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { profileData } = useContext(ProfileContext);

  const isUserInApprvoalPrincipals = (userId: string) => {

    let isIn = false;
    process?.activities?.map((activity) => {
      if (activity.type === 'APPROVAL') {
        const filteredPrincipals = activity.principals.filter(
          (principal) => principal.id === userId
        );
        if (
          filteredPrincipals.length &&
          ((mode === 'create' && activity.step === 0) || mode !== 'create')
        ) {
          isIn = true;
        }
      }
      return true;
    });
    return isIn;
  };

  const handleOnReviewersDataChange = (value, deleteSingleReviewer = false) => {
    
    const tempReviewers: any = [...newReviewers];
    if (deleteSingleReviewer) {
      const filteredReviewers = tempReviewers.filter(
        (review) => review.userId === value
      );
      if (filteredReviewers.length) {
        if (!isUserInApprvoalPrincipals(value)) {
          tempReviewers.splice(tempReviewers.indexOf(filteredReviewers[0]), 1);
        } else {
          enqueueSnackbar(t(`error-unassign-reviewer`), {
            variant: 'error',
          });
        }
      }
    } else {
      value.map((singleValue) => {
       
        const filteredReviewers = tempReviewers.filter(
          (review) => review.userId === (singleValue.userId || singleValue)
        );
        if (filteredReviewers.length === 0) {
          
          tempReviewers.push({ userId: singleValue.userId || singleValue });
        }

        return true;
      });
    }
    setNewReviewers(tempReviewers);
  };

  const tagRender = (props) => {
    const { label, value } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={(event) => {
          event.preventDefault();
          handleOnReviewersDataChange(value, true);
        }}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Col xs={24} style={{ margin: 10, marginTop: 0 }}>
      <Form layout="vertical">
        <Form.Item label={t('reviewers')}>
          <Select
            mode="multiple"
            onChange={(value) => handleOnReviewersDataChange(value)}
            value={newReviewers.map((reviewer) => reviewer.userId)}
            optionLabelProp="label"
            optionFilterProp="label"
            tagRender={tagRender}
          >
            {accesses
              ?.filter(
                (access) =>
                  access.principalId !==
                    (mode === 'create' ? profileData.id : publish.createdBy) ||
                  (access.principalId ===
                    (mode === 'create' ? profileData.id : publish.createdBy) &&
                    firstStepApprovalUsers.find(
                      (approvalUser) =>
                        approvalUser.userId === access.principalId
                    ))
              )
              .map((access) => (
                <Option
                  key={access.id}
                  value={access.principalId}
                  label={access.email}
                  style={
                    newReviewers.find(
                      (reviewer) => reviewer.userId === access.principalId
                    )
                      ? { color: '#1a73e8' }
                      : {}
                  }
                >
                  {access.email}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default ManageReviewersBox;
