export default {
  stripeKey: 'pk_test_aDuixjwmZpsfBBqM190d1JjD00rHYHvbVk',
  viewerURL: 'https://stage.sonat.com/@',
  errorSubscriptionStatuses: [
    'past_due',
    'unpaid',
    'canceled',
    'incomplete_expired',
    'trialing',
  ],
  CNAME: 'hosting-stage.sonat.com',
  environment: 'stage',
  editorURL: 'https://editor-stage.sonat.com',
  PUBLIC_VAPID_KEY:
    'BD5HwAQLqJsG23krvU_EImWPFpYn4jTI8TTuhCDxIKYSo9QAGZnovtCvFNzGveGHUNWqEd3Qbc6YhbXMpTzU1yM',
  firebaseConfig: {
    apiKey: 'AIzaSyAbKjZzW22PjjHw7Sk9CYbL-PMCTRhj1MU',
    authDomain: 'auth-stage.sonat.com',
    databaseURL: 'https://sonat-stage.firebaseio.com',
    projectId: 'sonat-stage',
    storageBucket: 'sonat-stage.appspot.com',
    messagingSenderId: '1035029100106',
    appId: '1:1035029100106:web:745081d97a9fc98846d8b3',
    measurementId: 'G-F3DEB9SJB1',
  },
  GTM_ID: 'GTM-P3WLMGM',
  facebookChatPageID: '',
  googleDocConfig: {
    CLIENT_ID:
      '1035029100106-hab3jtqfat5rn5ck1vcrds8mikfod9v6.apps.googleusercontent.com',
    REDIRECT_URI: 'https://editor-stage.sonat.com/driveoauthcallback',
    SCOPE:
      'https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.appfolder https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install',
    authEndpoint: 'https://accounts.google.com/o/oauth2/auth',
    tokenEndpoint: 'https://oauth2.googleapis.com/token',
  },
};
