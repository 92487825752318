import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { useQueryClient } from '@tanstack/react-query';

import { DocumentService } from '../../../services/document';
import { localizeKey } from '../../../helpers/localize-key';
import { DocumentContext } from '../../../contexts/document';
import ManageDocumentAccesses from './manage-accesses';
import { isInvitationEmailOk } from '../../../helpers/common';
import ViewerAccesses from './viewer-accesses';
import { DocumentRoles as ROLES } from '../../../helpers/roles';
import useGetAccesses from '../../../api-hooks/access/useGetAccesses';
import useUpdateAccess from '../../../api-hooks/access/useUpdateAccess';
import useCreateAccess from '../../../api-hooks/access/useCreateAccess';
import useDeleteAccess from '../../../api-hooks/access/useDeleteAccess';
import useInviteUser from '../../../api-hooks/access/useInviteUser';
import useGetCurrentPlan from '../../../api-hooks/plan/useGetCurrentPlan';
import { FolderContext } from '../../../contexts/folder';

const styles: any = (theme) => ({
  documentUserContainer: {
    width: 'auto',
    height: 'calc(100% - 40px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

const documentService = new DocumentService();

const DocumentAccesses = (props) => {
  const { classes } = props;

  const [accesses, setAccesses] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { accountId, documentId }: { accountId: string; documentId: string } =
    useParams();

  const queryClient = useQueryClient();

  const { selectedDoc } = useContext(DocumentContext);
  const { selectedFolderId } = useContext(FolderContext);

  const { getAccesses } = useGetAccesses(selectedDoc?.id);
  const { updateAccess } = useUpdateAccess(selectedDoc?.id);
  const { createAccess } = useCreateAccess(selectedDoc?.id);
  const { deleteAccess } = useDeleteAccess(selectedDoc?.id);
  const { inviteUser } = useInviteUser(selectedDoc?.id);

  const { currentPlan: plan } = useGetCurrentPlan(accountId);

  const settingsItem: string = window.location.pathname.split('/')[4];

  const isViewerAccess = settingsItem === 'private-document';

  useEffect(() => {
    if (selectedDoc) {
      i18n.changeLanguage(navigator.language);

      loadAccesses(selectedDoc.id);
    }
    // eslint-disable-next-line
  }, [selectedDoc]);

  const loadAccesses = async (docId: string | null) => {
    if (!docId) docId = `${documentId}`;

    if (docId) {
      return getAccesses()
        .then((result) => {
          const accesses = result.data;

          if (accesses) {
            if (isViewerAccess) {
              setAccesses(
                accesses.filter(
                  (access) => access.action === ROLES.VIEWER_READER
                )
              );
            } else {
              setAccesses(
                accesses.filter(
                  (access) => access.action !== ROLES.VIEWER_READER
                )
              );
            }
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            props.enqueueSnackbar(t('error.access-to-page'), {
              variant: 'error',
            });
            history.push('/');
          }
          throw error;
        });
    }
  };

  const handleAccessActionChanged = async (userId: string, newValue) => {
    const accessesList = accesses;
    const accessIndex = accessesList?.indexOf(
      accessesList?.find((access) => access.principalId === userId)
    );

    if (accessIndex === undefined || accessIndex < 0) return;

    const access = accessesList && accessesList[accessIndex];

    if (access.action === newValue) return;

    const newAccess = {
      entityId: documentId,
      entityType: 'Document',
      action: newValue,
      principalId: access.principalId,
      principalType: access.principalType,
    };

    return await updateAccess(newAccess);
  };

  const handleAddAccesses = async (
    newAccesses,
    newAccessesAction,
    setOpenUserLimitationDialog,
    isViewerAccess,
    type
  ) => {
    const allAccesses = accesses?.concat(newAccesses);
    const nonViewerAccesses = allAccesses?.filter(
      (account) =>
        account.action !== ROLES.VIEWER_READER &&
        (account.principalType === 'USER' || account.principalType === null)
    );

    if (
      nonViewerAccesses &&
      plan?.features.numberOfUsers &&
      plan.features.numberOfUsers !== -1 &&
      plan.features.numberOfUsers <= nonViewerAccesses.length
    ) {
      setOpenUserLimitationDialog(true);
      return false;
    }
    return await Promise.all(
      newAccesses.map(async (access) => {
        access.action = isViewerAccess
          ? ROLES.VIEWER_READER
          : newAccessesAction;
        const newAccess = {
          entityId: documentId,
          entityType: 'Document',
          action: isViewerAccess ? ROLES.VIEWER_READER : newAccessesAction,
          principalId: access.principalId,
          principalType: type || 'USER',
        };
        if (isInvitationEmailOk(newAccess.principalId)) {
          const email = newAccess.principalId;
          newAccess.principalId = null;
          return await inviteUser({ email, data: newAccess })
            .then((addedAccess) => {
              return addedAccess;
            })
            .catch(async (err) => {
              // eslint-disable-next-line
              throw { err: err, access: access };
            });
        } else {
          return await createAccess(newAccess);
        }
      })
    );
  };

  const handleRemoveAccessFromDocument = async (accessId) => {
    return deleteAccess(accessId)
      .then(() => {
        return true;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handlePrivateChanged = (value) => {
    const newModel = { ...selectedDoc };
    newModel.isPrivate = value === 0 ? null : value === 1;
    setLoading(true);
    documentService
      .putDocument(newModel)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['documents', true] });
        queryClient.invalidateQueries({
          queryKey: selectedFolderId
            ? ['documents', false, accountId, selectedFolderId]
            : ['documents', false, accountId],
        });
      })
      .catch((error) => {
        setLoading(false);
        props.enqueueSnackbar(
          error.statusText
            ? t(`error.${localizeKey(error.statusText)}`)
            : t('error.unknown'),
          {
            variant: 'error',
          }
        );
      });
  };

  return (
    <div className={classes.documentUserContainer}>
      {settingsItem === 'private-document' ? (
        <ViewerAccesses
          handlePrivateChanged={handlePrivateChanged}
          loading={loading}
          tokens={props.tokens}
          selectedDoc={selectedDoc}
          documentId={documentId}
          entityType="Document"
          accesses={accesses}
          plan={plan}
          handleAccessActionChanged={handleAccessActionChanged}
          ROLES={ROLES}
          handleAddAccesses={handleAddAccesses}
          handleRemoveAccess={handleRemoveAccessFromDocument}
          loadAccesses={loadAccesses}
          entityTitle={selectedDoc?.title}
          title={null}
        />
      ) : (
        <ManageDocumentAccesses
          tokens={props.tokens}
          selectedDoc={selectedDoc}
          documentId={documentId}
          entityType="Document"
          accesses={accesses}
          currentPlan={plan}
          handleAccessActionChanged={handleAccessActionChanged}
          ROLES={ROLES}
          handleAddAccesses={handleAddAccesses}
          handleRemoveAccess={handleRemoveAccessFromDocument}
          loadAccesses={loadAccesses}
          entityTitle={selectedDoc?.title}
          title={t<string>('manage-team-settings-title')}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(withSnackbar(DocumentAccesses));
