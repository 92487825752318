import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Input, Box, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { Button as AntButton, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MdOutlineDelete } from 'react-icons/md';

import { DocumentService } from '../../services/document';
import useGetAccountLogo from '../../api-hooks/account/useGetAccountLogo';

import './styles.css';

const { Text } = Typography;
const cropper: any = React.createRef();

const styles: any = () => ({
  root: {
    textAlign: 'center',
  },
  input: {
    height: '62px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    padding: 10,
    paddingBottom: 10,
    '&::before': {
      border: 'none',
    },
    '& input': {
      height: 'auto',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  finalImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    border: '1px solid gray',
  },
  button: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '45%',
    marginRight: '10px',
  },
  sizeError: {
    color: 'red',
  },
  cropBox: {
    maxWidth: '100%',
    maxHeight: '384px',
  },
});

//eslint-disable-next-line
const CropLogo = forwardRef<any, any>((props, ref) => {
  const [imageLogo, setImageLogo] = useState<string | ArrayBuffer | null>('');
  const [cropResult, setCropResult] = useState<string>('');
  const [showCroppableImage, setShowCroppableImage] = useState<string>('none');
  const [hasImageChanged, setHasImageChanged] = useState<boolean>(false);
  const [hasUploadedLogo, setHasUploadedLogo] = useState<boolean>(false);
  const [hasUploadedLogoBackUp, setHasUploadedLogoBckUp] =
    useState<boolean>(false);
  const [accountConfiguration, setAccountConfiguration] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sendUploadRequest, setSendUploadRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { getAccountLogo } = useGetAccountLogo({
    accountId: props.accountConfiguration?.id,
    updateDateTime: props.accountConfiguration?.updateDateTime,
  });

  const documentService = new DocumentService();

  const dataURItoBlob = (dataURI: string) => {
    let byteString: string;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const onChangeImage = (event: any, fileListArray: any[] | null = null) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const arrayFiles: any[] = fileListArray || event.target.files || [];

    (document.getElementById('error-file-size') as HTMLElement).innerHTML = '';
    (document.getElementById('error-image-type') as HTMLElement).innerHTML = '';
    const acceptedTypesPhoto = ['image/jpg', 'image/png', 'image/jpeg'];
    if (!arrayFiles.length) {
      return;
    }
    if (acceptedTypesPhoto.indexOf(arrayFiles[0].type) < 0) {
      (document.getElementById('error-image-type') as HTMLElement).innerHTML =
        t('error.imageType');
      event.target.value = '';
      return;
    }
    if (arrayFiles[0].size > 200000) {
      (document.getElementById('error-file-size') as HTMLElement).innerHTML =
        t('error.fileSize');
      event.target.value = '';
      return;
    }
    let files: any[];
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = arrayFiles;
    } else {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageLogo(reader.result);
      setShowCroppableImage('block');
      setShowModal(true);
    };
    try {
      reader.readAsDataURL(files[0]);
    } catch (error) {
      return;
    }
  };

  const handleCancleCrop = () => {
    setShowCroppableImage('none');
    setShowModal(false);
    setCropResult('');
    setHasImageChanged(false);
    (document.getElementById('imageLogo') as HTMLInputElement).value = '';
    if (hasUploadedLogoBackUp) setHasUploadedLogo(true);
  };

  const handleRemove = () => {
    setHasUploadedLogo(false);
    setHasImageChanged(true);
    if (props.setIsLogoDeleted) props.setIsLogoDeleted(true);
    if (props.handleEnableSaveButton) props.handleEnableSaveButton(true);
    if (props.handleRemoveLogo) props.handleRemoveLogo();
  };

  const cropImage = (event) => {
    event.preventDefault();
    if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
      props.enqueueSnackbar(t('error.crop'), {
        variant: 'error',
      });
      return;
    }
    setLoading(true);
    cropper.current.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append('upload', blob);
      fetch(
        'https://resources-cxbruehxeq-ue.a.run.app/api/image/convert-to-webp',
        {
          method: 'POST',
          body: formData,
        }
      ).then(async (response) => {
        if (response.status === 200) {
          let webpBase64 = await response.text();
          webpBase64 = `data:image/webp;base64,${webpBase64}`;
          setCropResult(webpBase64);
          setShowModal(false);
          setHasImageChanged(true);
          setHasUploadedLogoBckUp(hasUploadedLogo);
          setHasUploadedLogo(false);
          setLoading(false);
          setShowCroppableImage('none');
          if (props.handleEnableSaveButton)
            props.handleEnableSaveButton(undefined, undefined, true);
          if (
            props.accountConfiguration &&
            props.accountConfiguration.id &&
            props.handleLogoChanged
          ) {
            const logo = dataURItoBlob(webpBase64);
            if (logo.size > 1000000) {
              (
                document.getElementById('error-file-size') as HTMLElement
              ).innerHTML = t('error.fileSize');
              (document.getElementById('imageLogo') as HTMLInputElement).value =
                '';
              return;
            }
            setHasUploadedLogo(false);
            (
              document.getElementById('error-file-size') as HTMLElement
            ).innerHTML = '';
            props.handleLogoChanged(logo);
          }
          if (props.content && props.handleLogoChanged) {
            const logo = webpBase64;
            setHasUploadedLogo(false);
            props.handleLogoChanged(logo);
          }
          if (props.documentId) {
            const logo = dataURItoBlob(webpBase64);
            if (logo.size > 1000000) {
              (
                document.getElementById('error-file-size') as HTMLElement
              ).innerHTML = t('error.fileSize');
              (document.getElementById('imageLogo') as HTMLInputElement).value =
                '';
              setSendUploadRequest(false);
              setCropResult('');
              return;
            } else {
              setSendUploadRequest(true);
            }
          }
        }
      });
    });
  };

  useImperativeHandle(ref, () => ({
    async sendLogo(documentId) {
      if ((hasImageChanged || props.imageUrl) && sendUploadRequest) {
        const logo = dataURItoBlob(cropResult || props.imageUrl);
        const formData = new FormData();
        if (props.documentId) {
          formData.append('documentId', documentId);
          formData.append('logo', logo, `image.${logo.type.split('/')[1]}`);
          await documentService
            .uploadLogo(formData)
            .then(() => {})
            .catch(() => {
              props.enqueueSnackbar(t('error.uploadLogo'), {
                variant: 'error',
              });
            });
        }
      }
    },
  }));

  useEffect(() => {
    document
      .getElementById('imageLogo')
      ?.parentElement?.classList.add('imageLogoParent');
  }, []);

  useEffect(() => {
    if (document.getElementById('error-file-size')) {
      (document.getElementById('error-file-size') as HTMLElement).innerHTML =
        '';
    }
    if (props.documentId) {
      documentService
        .getLogo(props.documentId, props.updateDateTime, false)
        .then((status) => {
          if (status === 200) setHasUploadedLogo(true);
        });
    }
    if (
      props.accountConfiguration &&
      props.accountConfiguration.id &&
      accountConfiguration.id !== props.accountConfiguration.id
    ) {
      setHasUploadedLogo(false);
      setAccountConfiguration(props.accountConfiguration);
      getAccountLogo().then((result) => {
        if (result.status === 'success') setHasUploadedLogo(true);
      });
    }
    if (
      props.content &&
      props.content.imageId &&
      props.content.imageId !== cropResult
    ) {
      setHasUploadedLogo(true);
    }
    // eslint-disable-next-line
  }, [props.documentId, props.accountConfiguration, props.content]);

  const classes = props.classes;

  const openImageInput = () => {
    const imageInput = document.getElementById('imageLogo');
    if (imageInput) {
      imageInput.click();
    }
  };

  if (props.newStyle) {
    return (
      <div
        className={props.documentId ? classes.root : ''}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Input
          style={{ display: 'none' }}
          className={classes.input}
          type="file"
          id="imageLogo"
          name="imageLogo"
          onChange={(event) => onChangeImage(event)}
          inputProps={{ accept: '.jpg, .jpeg, .png' }}
        />
        {cropResult || props.imageUrl || hasUploadedLogo ? (
          <Box
            style={{
              ...{ textAlign: 'center' },
              ...(props.inputStyle ? props.inputStyle : { width: '100%' }),
              marginTop: 10,
              width: 150,
              position: 'relative',
              borderRadius: 5,
            }}
            className="image-preview-box"
          >
            {!props.documentId && (
              <div style={{ marginBottom: 20 }}>{t('logo')}</div>
            )}
            <img
              className={classes.finalImage}
              src={
                cropResult ||
                props.imageUrl ||
                props.logoSrc ||
                `/api/Account/${props.accountConfiguration.id}/Logo`
              }
              alt={t('company-logo')}
            />
            <div
              style={{ marginTop: 10 }}
              className="delete-image-icon delete-image-icon-display-none"
            >
              <MdOutlineDelete
                style={{
                  color: 'black',
                  fontSize: 30,
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 'calc(50% - 10px)',
                  left: 'calc(50% - 14px)',
                  opacity: '1 !important',
                }}
                onClick={cropResult ? handleCancleCrop : handleRemove}
              />
            </div>
          </Box>
        ) : (
          <AntButton
            style={{
              width: '150px',
              height: '150px',
              borderRadius: 15,
              border: '1px dashed #d9d9d9',
              textAlign: 'center',
              backgroundColor: '#fafafa',
            }}
            onClick={openImageInput}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PlusOutlined />
              <Text style={{ marginTop: 10 }}>
                {props.insideUploadText ? props.insideUploadText : 'Upload'}
              </Text>
            </div>
          </AntButton>
        )}

        <div id="error-file-size" className={classes.sizeError}></div>
        <div id="error-image-type" className={classes.sizeError}></div>
        <Modal
          title="Basic Modal"
          open={showModal}
          onOk={(event) => {
            cropImage(event);
          }}
          okButtonProps={{ disabled: loading, loading: loading }}
          onCancel={handleCancleCrop}
        >
          {loading ? (
            <div
              style={{
                width: '820px',
                height: '384px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress style={{ width: 40, height: 40 }} />
            </div>
          ) : imageLogo !== null && typeof imageLogo === 'string' ? (
            <Cropper
              ref={cropper}
              src={imageLogo}
              className={classes.cropBox}
              style={{
                ...{ display: showCroppableImage },
                ...(props.inputStyle || {}),
                marginTop: 10,
              }}
              aspectRatio={props.documentId ? 9 / 9 : NaN}
              background={false}
            />
          ) : null}
        </Modal>
      </div>
    );
  } else {
    return (
      <div
        className={
          props.documentId
            ? classes.root + ' settings-logo-container'
            : 'settings-logo-container'
        }
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Input
          style={props.inputStyle || {}}
          className={classes.input}
          type="file"
          id="imageLogo"
          name="imageLogo"
          onChange={(event) => onChangeImage(event)}
          inputProps={{ accept: '.jpg, .jpeg, .png' }}
        />
        {cropResult || props.imageUrl || hasUploadedLogo ? (
          <Box
            style={{
              ...{ textAlign: 'center' },
              ...(props.inputStyle ? props.inputStyle : { width: '100%' }),
              marginTop: 10,
              maxWidth: 200,
            }}
          >
            <div style={{ marginBottom: 20 }}>{t('logo')}</div>
            <img
              className={classes.finalImage}
              src={
                cropResult ||
                props.imageUrl ||
                props.logoSrc ||
                `/api/Account/${props.accountConfiguration.id}/Logo`
              }
              alt={t('company-logo')}
            />
            <div style={{ marginTop: 10 }} className="delete-image-icon">
              <MdOutlineDelete
                style={{ color: 'gray', fontSize: 30, cursor: 'pointer' }}
                onClick={cropResult ? handleCancleCrop : handleRemove}
              />
            </div>
          </Box>
        ) : (
          <AntButton
            style={{
              width: '150px',
              height: '150px',
              borderRadius: 15,
              border: '1px dashed #d9d9d9',
              textAlign: 'center',
              backgroundColor: '#fafafa',
            }}
            onClick={openImageInput}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PlusOutlined />
              <Text style={{ marginTop: 10 }}>
                {props.insideUploadText ? props.insideUploadText : 'Upload'}
              </Text>
            </div>
          </AntButton>
        )}

        <div id="error-file-size" className={classes.sizeError}></div>
        <div id="error-image-type" className={classes.sizeError}></div>
        <Modal
          title="Basic Modal"
          open={showModal}
          onOk={(event) => {
            cropImage(event);
          }}
          onCancel={handleCancleCrop}
        >
          {imageLogo !== null && typeof imageLogo === 'string' && (
            <Cropper
              ref={cropper}
              src={imageLogo}
              className={classes.cropBox}
              style={{
                ...{ display: showCroppableImage },
                ...(props.inputStyle || {}),
                marginTop: 10,
              }}
              aspectRatio={props.documentId ? 9 / 9 : NaN}
              background={false}
            />
          )}
        </Modal>
      </div>
    );
  }
});

// eslint-disable-next-line
// @ts-expect-error
export default withStyles(styles)(withSnackbar(CropLogo));
