import React from 'react';
import { Context } from '../../interfaces';

const actions = {
  SET_CONTEXT: 'SET_CONTEXT',
};

const initialState: StructuredDataContextType = {
  context: {},
  setContext: Function,
};

type StructuredDataContextType = {
  context: Context;
  setContext: (value: any) => void;
};

export const StructuredDataContext =
  React.createContext<StructuredDataContextType>(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CONTEXT':
      return {
        ...state,
        context: action.value,
      };
    default:
      throw new Error();
  }
};

export const StructuredDataContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = {
    context: state.context,
    setContext: (value) => {
      dispatch({ type: actions.SET_CONTEXT, value });
    },
  };

  return (
    <StructuredDataContext.Provider value={value}>
      {children}
    </StructuredDataContext.Provider>
  );
};
