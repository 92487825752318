import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress, Button as ButtonMaterial } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Typography,
  Divider,
  Col,
  Row,
  Skeleton,
  Button,
  Switch,
  Input,
  Tooltip,
} from 'antd';
import { CgStyle } from 'react-icons/cg';
import { MdOutlineClear } from 'react-icons/md';
import { IoMdText, IoMdDocument, IoLogoJavascript } from 'react-icons/io';
import { BsFileCodeFill } from 'react-icons/bs';
import { TbLetterS, TbLetterT } from 'react-icons/tb';
import { SketchPicker } from 'react-color';
import { CopyOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import TextInput from '../../../inputs/text-input';
import IconPicker from '../../../document/settings/style/icon-picker';
import SelectInput from '../../../inputs/select-input';
import config from 'react-global-configuration';
import { DocumentContext } from '../../../../contexts/document';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import RestrictedLabel from '../../../../helpers/base-components/restricted-label';
import useGetCurrentPlan from '../../../../api-hooks/plan/useGetCurrentPlan';
import useGetAccountWebAssistantSettings from '../../../../api-hooks/account/useGetAccountWebAssistantSettings';

import './styles.css';

const { Option } = Select;
const { Text, Title } = Typography;

const webAssistantDefaultSettings: any = {
  isEnabled: false,
  buttonText: 'Need Help?',
  backgroundColor: '#D4E6FA',
  buttonColor: '#3399ff',
  fontColor: '#ffffff',
  iconName: 'mdi-help-circle-outline',
  iconLibrary: 'mdi',
  panelType: 'popup',
  position: 'right',
  defaultDocumentId: null,
  buttonType: 'float',
  title: 'untitled-saas-assistant',
  id: uuidv4(),
  targetElementSelector: null,
};

const styles = (theme: any) => ({
  textField: {
    marginRight: '10px',
    marginBottom: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
});

function AccountWebAssistantSettings(props: any) {
  const classes = props.classes;

  const { t } = useTranslation();

  const [showSketchBackgroundColor, setShowSketchBackgroundColor] =
    useState<boolean>(false);
  const [showSketchFontColor, setShowSketchFontColor] =
    useState<boolean>(false);
  const [showSketchButtonColor, setShowSketchButtonColor] =
    useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [sliderOpen, setSliderOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);
  const [copyToolbarText, setCopyToolbarText] =
    useState<string>('Copy to clipboard');
  const [copyJSTriggerToolbarText, setJSTriggerCopyToolbarText] =
    useState<string>('Copy to clipboard');
  const [isSideWallOpen, setIsSideWallOpen] = useState<boolean>(false);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState<boolean>(false);
  const [isEmptySassList, setIsEmptySassList] = useState<boolean>(false);
  const [assistants, setAssistants] = useState<any>([]);
  const [filteredAssistants, setFilteredAssistants] = useState<any>([]);
  const [selectedAssistant, setSelectedAssitstant] = useState<any>();
  const [selectedAssistantPosition, setSelectedAssitstantPosition] =
    useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isTitleFocused, setIsTitleFocused] = useState<boolean>(false);
  const [isSassListChanged, setIsSassListChanged] = useState<boolean>(false);

  const { documents: allDocuments } = useContext(DocumentContext);

  const { currentPlan } = useGetCurrentPlan(props.accountConfiguration.id);
  const {
    accountWebAssistantSettings,
    isSuccessLoadingAccountWebAssistantSettings,
  } = useGetAccountWebAssistantSettings(props.accountConfiguration.id);

  const JSTriggerClassName = 'data-swa-paneljstrigger';
  const JSTriggerExample =
    '<button data-swa-paneljstrigger>Click me to open Web Assistant Panel!</button>';

  const handleWebAssistantSettingsChange = (
    key: string,
    value: string | boolean
  ) => {
    const oldWebAssistantConfiguration = [...props.webAssistantConfiguration];

    const newWebAssistantConfiguration = Object.assign(
      {},
      oldWebAssistantConfiguration[selectedAssistantPosition]
    );
    newWebAssistantConfiguration[key] = value;
    if (key === 'iconName') {
      newWebAssistantConfiguration['iconLibrary'] = 'mdi';
    }

    oldWebAssistantConfiguration[selectedAssistantPosition] =
      newWebAssistantConfiguration;

    setAssistants(oldWebAssistantConfiguration);
    props.setWebAssistantConfiguration(oldWebAssistantConfiguration);
  };

  const handleColorChange = (key: string, value: any) => {
    let colorStr = value.hex;
    if (value.rgb.a !== 1) {
      colorStr = `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`;
    }
    handleWebAssistantSettingsChange(key, colorStr);
  };

  const handleClickBackgroundColor = () => {
    setShowSketchBackgroundColor(!showSketchBackgroundColor);
    setShowSketchButtonColor(false);
    setShowSketchFontColor(false);
  };

  const handleClickFontColor = () => {
    setShowSketchBackgroundColor(false);
    setShowSketchButtonColor(false);
    setShowSketchFontColor(!showSketchFontColor);
  };

  const handleClickButtonColor = () => {
    setShowSketchBackgroundColor(false);
    setShowSketchButtonColor(!showSketchButtonColor);
    setShowSketchFontColor(false);
  };

  const handleSliderBody = () => {
    setSliderOpen(!sliderOpen);
  };

  const handleJsTriggerButtonSkeletonClicked = () => {
    if (
      props.webAssistantConfiguration[selectedAssistantPosition].panelType ===
        'slider' ||
      props.webAssistantConfiguration[selectedAssistantPosition].panelType ===
        'popup'
    ) {
      setSliderOpen(!sliderOpen);
    } else {
      if (
        props.webAssistantConfiguration[selectedAssistantPosition]
          ?.buttonType === 'side-wall'
      ) {
        setIsSideWallOpen(!isSideWallOpen);
      }
      setIsSideDrawerOpen(!isSideDrawerOpen);
      const helpDrawerElement = document.getElementById(
        'editor-sonat-web-assistant-help-drawer'
      );
      helpDrawerElement?.classList.toggle('open');
    }
  };

  const handleCloseJsTriggerSkeleton = () => {
    setIsSideDrawerOpen(false);
    if (
      props.webAssistantConfiguration[selectedAssistantPosition]?.buttonType ===
      'side-wall'
    ) {
      setIsSideWallOpen(false);
    }
    const helpDrawerElement = document.getElementById(
      'editor-sonat-web-assistant-help-drawer'
    );
    helpDrawerElement?.classList.remove('open');
  };

  const generateWebAssistantScript = () => {
    if (config.get('environment') === 'stage') {
      return `<script name="SonatWebAssistant" id="${props.webAssistantConfiguration[selectedAssistantPosition]?.id}" src="https://resources-stage.sonat.com/webAssistantSonat.min.js"></script>`;
    } else {
      return `<script name="SonatWebAssistant" id="${props.webAssistantConfiguration[selectedAssistantPosition]?.id}" src="https://resources.sonat.com/webAssistantSonat.min.js"></script>`;
    }
  };

  const handleCopyScriptClicked = () => {
    navigator.clipboard.writeText(generateWebAssistantScript());
    setCopyToolbarText('Copied!');
    setTimeout(() => setCopyToolbarText('Copy to clipboard'), 3000);
  };

  const handleCopyJSTriggerClassClicked = () => {
    navigator.clipboard.writeText(JSTriggerClassName);
    setJSTriggerCopyToolbarText('Copied!');
    setTimeout(() => setJSTriggerCopyToolbarText('Copy to clipboard'), 3000);
  };

  const handleButtonTypeWhenPanelChanged = () => {
    const panelType =
      props.webAssistantConfiguration[selectedAssistantPosition]?.panelType;
    const buttonType =
      props.webAssistantConfiguration[selectedAssistantPosition]?.buttonType;
    if (panelType === 'slider' && buttonType !== 'bottom-drawer') {
      handleWebAssistantSettingsChange('buttonType', 'bottom-drawer');
    } else if (
      panelType === 'popup' &&
      buttonType !== 'no-button' &&
      buttonType !== 'float'
    ) {
      handleWebAssistantSettingsChange('buttonType', 'float');
    } else if (
      panelType === 'side-drawer' &&
      buttonType !== 'no-button' &&
      buttonType !== 'float' &&
      buttonType !== 'side-wall'
    ) {
      handleWebAssistantSettingsChange('buttonType', 'float');
    } else {
      return buttonType;
    }
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleIsTitleFocused = () => {
    setIsTitleFocused(true);
  };

  const handleIsTitleBlured = () => {
    setIsTitleFocused(false);

    if (!props?.webAssistantConfiguration[selectedAssistantPosition]?.title) {
      handleWebAssistantSettingsChange('title', 'untitled-saas-assistant');
    }
  };

  useEffect(() => {
    if (
      isSuccessLoadingAccountWebAssistantSettings &&
      accountWebAssistantSettings
    ) {
      if (accountWebAssistantSettings.length === 0) {
        props.setWebAssistantConfiguration([
          {
            ...webAssistantDefaultSettings,
            accountId: props.accountConfiguration.id,
          },
        ]);

        props.webAssistantInitialSettings.current = accountWebAssistantSettings;

        setAssistants([webAssistantDefaultSettings]);
        setSelectedAssitstant(webAssistantDefaultSettings);
      } else {
        props.setWebAssistantConfiguration(accountWebAssistantSettings);
        props.webAssistantInitialSettings.current = accountWebAssistantSettings;

        setSelectedIcon(
          accountWebAssistantSettings[selectedAssistantPosition].iconName
        );
        setAssistants(accountWebAssistantSettings);
        setSelectedAssitstant(
          accountWebAssistantSettings[selectedAssistantPosition]
        );
      }
    }
    //eslint-disable-next-line
  }, [
    accountWebAssistantSettings,
    isSuccessLoadingAccountWebAssistantSettings,
  ]);

  useEffect(() => {
    if (selectedIcon)
      handleWebAssistantSettingsChange('iconName', selectedIcon);
    // eslint-disable-next-line
  }, [selectedIcon]);

  useEffect(() => {
    if (props.accountConfiguration && allDocuments) {
      const accountDocuments = allDocuments.filter(
        (doc) => doc.accountId === props.accountConfiguration.id
      );
      setDocuments(accountDocuments);
    }
    //eslint-disable-next-line
  }, [props.accountConfiguration, allDocuments]);

  useEffect(() => {
    if(isSassListChanged){
      setIsSassListChanged(false);

      if (filteredAssistants.length > 0) {

        setSelectedAssitstant(filteredAssistants[0]);
        setAssistants(filteredAssistants);

        props.webAssistantInitialSettings.current = filteredAssistants[0];

        props.handleSaveAccountSettings();
      } else if (isEmptySassList && selectedAssistant) {

        setSelectedAssitstant(null);
        setAssistants(filteredAssistants);

        props.webAssistantInitialSettings.current = [];

        props.handleSaveAccountSettings();
      }
  }
    // eslint-disable-next-line
  }, [props.webAssistantConfiguration]);

  useEffect(() => {
    if (props.webAssistantConfiguration?.[selectedAssistantPosition]) {
      if (
        documents &&
        documents.length > 0 &&
        !props.webAssistantConfiguration?.[selectedAssistantPosition]
          ?.defaultDocumentId
      ) {
        //handleWebAssistantSettingsChange("firstPage", documents[0].id);
        handleWebAssistantSettingsChange('defaultDocumentId', documents[0].id);
      }
    } else if (assistants.length !== 1 && isEmptySassList) {
      handleCreate();
    }
    // eslint-disable-next-line
  }, [documents, props.accountConfiguration, props.webAssistantConfiguration]);

  const handleChangeSelect = async (element: string, newValue: string) => {
    const index = assistants.find((it) => it.id === newValue);

    setSelectedAssitstantPosition(assistants.indexOf(index));
    setSelectedAssitstant(index);

    props.setWebAssistantConfiguration(assistants);
    props.webAssistantInitialSettings.current = index;
  };

  const renderSelect = (element: string, selectedElement, items: any[]) => {
    return items.length > 0 ? (
      <SelectInput
        field={element}
        showError={false}
        onChange={(value) => handleChangeSelect(element, value)}
        value={selectedElement ? selectedElement.id : items[0].id}
      >
        {items &&
          items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          ))}
      </SelectInput>
    ) : null;
  };

  const handleCreate = () => {
    const newWebAssistant = {
      id: uuidv4(),
      title: 'untitled-saas-assistant',
      buttonText: 'Need Help?',
      backgroundColor: '#D4E6FA',
      buttonColor: '#3399ff',
      fontColor: '#ffffff',
      iconName: 'mdi-help-circle-outline',
      iconLibrary: 'mdi',
      panelType: 'popup',
      targetElementSelector: null,
      position: 'right',
      buttonType: 'float',
      firstPage: null,
      defaultDocumentId: null,
      accountId: props.accountConfiguration.id,
      isEnabled: false,
      entity: null,
    };

    assistants.push(newWebAssistant);

    setFilteredAssistants([]);

    setSelectedAssitstant(newWebAssistant);
    setSelectedAssitstantPosition(assistants.length - 1);

    props.setWebAssistantConfiguration(assistants);
    props.webAssistantInitialSettings.current = newWebAssistant;
  };

  const handleDelete = () => {
    const temp = assistants.filter((it) => it.id !== selectedAssistant.id);

    setFilteredAssistants(temp);

    if (temp.length === 0) {
      setIsEmptySassList(true);
    }

    setIsSassListChanged(true);
    setSelectedAssitstantPosition(0);

    props.setWebAssistantConfiguration(temp);

    handleCloseDeleteDialog();
  };

  if (currentPlan === undefined) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress style={{ height: 25, width: 25 }} />
      </div>
    );
  } else {
    if (props.webAssistantConfiguration?.length > 0)
      if (!props.webAssistantConfiguration[selectedAssistantPosition])
        setSelectedAssitstantPosition(selectedAssistantPosition + 1);

    return (
      <div>
        {props.webAssistantConfiguration &&
          props.webAssistantConfiguration[selectedAssistantPosition] && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '12px',
                  marginBottom: '10px',
                }}
              >
                {assistants && !isEmptySassList && (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ width: '250px', minWidth: 250 }}>
                      {renderSelect(
                        'assistants',
                        selectedAssistant,
                        assistants
                      )}
                    </div>

                    {currentPlan.name === 'Enterprise' ||
                    currentPlan.name === 'Enterprise Plus' ||
                    currentPlan.name === 'Enterprise Old' ||
                    props.accountConfiguration.slug === 'sangal' ||
                    props.accountConfiguration.slug === 'manu-online' ? null : (
                      <RestrictedLabel
                        textContent={t('web-assistant-enterprise-text')}
                      />
                    )}
                  </div>
                )}

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  {!isEmptySassList && (
                    <Tooltip
                      title={t<string>('delete-web-assistant')}
                      style={{ margin: '5px 20px 0 0' }}
                    >
                      <ButtonMaterial
                        variant="contained"
                        style={{ height: '35px', marginRight: '10px' }}
                        color="error"
                        onClick={handleClickOpenDeleteDialog}
                        aria-label="Delete current document"
                      >
                        Delete Current
                      </ButtonMaterial>
                    </Tooltip>
                  )}

                  <Tooltip
                    title={t<string>('new-web-assistant')}
                    style={{ margin: '5px 20px 0 0' }}
                  >
                    <ButtonMaterial
                      variant="contained"
                      style={{ height: '35px' }}
                      color="primary"
                      onClick={() => {
                        if (isEmptySassList) setIsEmptySassList(false);
                        else {
                          handleCreate();
                        }
                      }}
                      aria-label="Create new document"
                    >
                      New
                    </ButtonMaterial>
                  </Tooltip>
                </div>
              </div>
              {!isEmptySassList && (
                <div>
                  <div
                    style={{
                      border: '1px solid #f0f0f0',
                      borderRadius: '5px',
                      padding: '10px',
                    }}
                  >
                    <div>
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div
                          className={
                            window.innerWidth > 525
                              ? 'settings-icon-container-show'
                              : 'settings-icon-container-hide'
                          }
                        >
                          <TbLetterT
                            style={{
                              fontSize: '36px',
                              color: '#1a73e8',
                              marginLeft: 6,
                              marginTop: '-5px',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: 10,
                          }}
                          className={
                            window.innerWidth > 525
                              ? 'settings-detail-container-show-partly'
                              : 'settings-detail-container-show-full'
                          }
                        >
                          <Title className="settings-item-title" level={4}>
                            {t('title')}
                          </Title>
                          <Text>{t('title-web-assistant')}</Text>
                          <TextInput
                            id="title"
                            field="Title"
                            style={{ width: '95%', marginTop: 10 }}
                            value={
                              props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ].title
                                ? props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ].title
                                : isTitleFocused
                                  ? props.webAssistantConfiguration[
                                      selectedAssistantPosition
                                    ].title
                                  : 'untitled-saas-assistant'
                            }
                            onFocus={handleIsTitleFocused}
                            onBlur={handleIsTitleBlured}
                            onChange={(event) =>
                              handleWebAssistantSettingsChange(
                                'title',
                                event.target.value
                              )
                            }
                            required={true}
                            showError={false}
                          />
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <BsFileCodeFill
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('activate-webassistant')}
                          </Title>
                          <Text>{t('activate-webassistant-help')}</Text>
                          <Switch
                            style={{ display: 'block', marginTop: 15 }}
                            onChange={(value) =>
                              handleWebAssistantSettingsChange(
                                'isEnabled',
                                value
                              )
                            }
                            checked={
                              props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.isEnabled === true
                            }
                          />
                          <Input.Group
                            compact
                            style={{ marginRight: 10, marginTop: 15 }}
                          >
                            <Input
                              disabled={
                                !props.webAssistantConfiguration[
                                  selectedAssistantPosition
                                ]?.isEnabled
                              }
                              style={{ width: 'calc(100% - 200px)' }}
                              onChange={() => {}}
                              value={generateWebAssistantScript()}
                            />
                            <Tooltip title={copyToolbarText}>
                              <Button
                                icon={<CopyOutlined />}
                                onClick={handleCopyScriptClicked}
                                disabled={
                                  !props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.isEnabled
                                }
                              />
                            </Tooltip>
                          </Input.Group>
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <IoMdText
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('button-text')}
                          </Title>
                          <Text>{t('button-text-help')}</Text>
                          <TextInput
                            style={{
                              width: 350,
                              marginRight: 10,
                              marginTop: 15,
                            }}
                            field="button-text"
                            required={true}
                            onChange={(event) =>
                              handleWebAssistantSettingsChange(
                                'buttonText',
                                event.target.value
                              )
                            }
                            value={
                              props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.buttonText
                            }
                            showError={false}
                          />
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <IoMdDocument
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('assistant-defult-document')}
                          </Title>
                          <Text>{t('assistant-defult-document-help')}</Text>
                          <SelectInput
                            field="defaultDocumentId"
                            style={{
                              marginTop: 10,
                              display: 'block',
                              width: 350,
                            }}
                            onChange={(value) =>
                              handleWebAssistantSettingsChange(
                                'defaultDocumentId',
                                String(value)
                              )
                            }
                            value={
                              props.webAssistantConfiguration &&
                              props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.defaultDocumentId
                                ? props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.defaultDocumentId
                                : documents && documents.length > 0
                                  ? documents[0].id
                                  : null
                            }
                          >
                            {documents &&
                              Array.isArray(documents) &&
                              documents.map((document) => (
                                <Option key={document.id} value={document.id}>
                                  {document.title}
                                </Option>
                              ))}
                          </SelectInput>
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <IoLogoJavascript
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('trigger-with-js')}
                          </Title>
                          <Text>{t('trigger-with-js-help')}</Text>
                          <Input.Group
                            compact
                            style={{ marginRight: 10, marginTop: 15 }}
                          >
                            <Input
                              style={{ width: 'calc(100% - 200px)' }}
                              onChange={() => {}}
                              value="data-swa-paneljstrigger"
                            />
                            <Tooltip title={copyJSTriggerToolbarText}>
                              <Button
                                icon={<CopyOutlined />}
                                onClick={handleCopyJSTriggerClassClicked}
                              />
                            </Tooltip>
                            <div style={{ marginTop: 10 }}>
                              <Text>{t('for-example:')}</Text>
                              <br />
                              <Text
                                code
                                style={{ color: 'black' }}
                                className="js-trigger-code"
                              >
                                {JSTriggerExample}
                              </Text>
                            </div>
                          </Input.Group>
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ width: '10%' }}>
                          <TbLetterS
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('target-element-selector')}
                          </Title>
                          <Text>{t('target-element-selector-help')}</Text>
                          <TextInput
                            style={{
                              width: 350,
                              marginRight: 10,
                              marginTop: 15,
                            }}
                            field="target-element-selector"
                            required={true}
                            onChange={(event) =>
                              handleWebAssistantSettingsChange(
                                'targetElementSelector',
                                event.target.value
                              )
                            }
                            value={
                              props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.targetElementSelector
                            }
                            showError={false}
                          />
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '10%' }}>
                          <CgStyle
                            style={{
                              fontSize: '30px',
                              color: '#1a73e8',
                              marginLeft: 10,
                            }}
                          />
                        </div>
                        <div style={{ width: '90%', display: 'grid' }}>
                          <Title className="settings-item-title" level={4}>
                            {t('styling')}
                          </Title>
                          <Text>{t('styling-help')}</Text>
                          <Row style={{ marginTop: 25 }} gutter={{ sm: 8 }}>
                            <Col span={16} md={24} lg={16}>
                              <Row style={{ justifyContent: 'space-between' }}>
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                  style={{ border: 'none' }}
                                >
                                  <div>
                                    <Text strong>Panel Type</Text>
                                    <Select
                                      defaultValue="slider"
                                      value={
                                        props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.panelType
                                      }
                                      style={{ width: '100%' }}
                                      onChange={(value) =>
                                        handleWebAssistantSettingsChange(
                                          'panelType',
                                          value
                                        )
                                      }
                                    >
                                      <Option value="slider">
                                        {t('slider')}
                                      </Option>
                                      <Option value="popup">
                                        {t('popup')}
                                      </Option>
                                      <Option value="side-drawer">
                                        {t('side-drawer')}
                                      </Option>
                                      <Option value="embed">
                                        {t('embed')}
                                      </Option>
                                    </Select>
                                  </div>
                                </Col>
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                  style={{ border: 'none' }}
                                >
                                  <div>
                                    <Text strong>Position</Text>
                                    <Select
                                      defaultValue="right"
                                      value={
                                        props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.position
                                      }
                                      style={{ width: '100%' }}
                                      onChange={(value) =>
                                        handleWebAssistantSettingsChange(
                                          'position',
                                          value
                                        )
                                      }
                                    >
                                      <Option value="right">
                                        {t('right')}
                                      </Option>
                                      <Option value="left">{t('left')}</Option>
                                    </Select>
                                  </div>
                                </Col>
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                  style={{ border: 'none' }}
                                >
                                  <div>
                                    <Text strong>Button</Text>
                                    <Select
                                      defaultValue={
                                        props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.panelType === 'slider'
                                          ? 'bottom-drawer'
                                          : props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.panelType === 'popup'
                                            ? 'float-button'
                                            : 'wall button'
                                      }
                                      value={handleButtonTypeWhenPanelChanged()}
                                      style={{ width: '100%' }}
                                      onChange={(value) =>
                                        handleWebAssistantSettingsChange(
                                          'buttonType',
                                          value
                                        )
                                      }
                                    >
                                      {props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.panelType === 'slider' && (
                                        <Option value="bottom-drawer">
                                          {t('bottom-drawer')}
                                        </Option>
                                      )}
                                      {props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.panelType === 'popup' && (
                                        <>
                                          <Option value="float">
                                            {t('float')}
                                          </Option>
                                          <Option value="no-button">
                                            {t('no-button')}
                                          </Option>
                                        </>
                                      )}
                                      {props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.panelType === 'side-drawer' && (
                                        <>
                                          <Option value="no-button">
                                            {t('no-button')}
                                          </Option>
                                          <Option value="float">
                                            {t('float')}
                                          </Option>
                                          <Option value="side-wall">
                                            {t('side-wall')}
                                          </Option>
                                        </>
                                      )}
                                    </Select>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 20,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                >
                                  <div
                                    className="web-assistant-input-field-span-8-div"
                                    onClick={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.panelType === 'side-drawer'
                                        ? () => {}
                                        : handleClickBackgroundColor
                                    }
                                    style={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.panelType === 'side-drawer'
                                        ? { opacity: '0.2', cursor: 'default' }
                                        : {}
                                    }
                                  >
                                    <div className="web-assistant-label-text">
                                      <Text strong>
                                        {t('background-color')}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.backgroundColor,
                                        height: 30,
                                        borderTop: '1px solid #d9d9d9',
                                        borderRadius: '0px 0px 3px 3px',
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="sketch-holder"
                                    style={{
                                      display: showSketchBackgroundColor
                                        ? 'flex'
                                        : 'none',
                                    }}
                                  >
                                    <div>
                                      <SketchPicker
                                        color={
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.backgroundColor
                                        }
                                        onChange={(color) =>
                                          handleColorChange(
                                            'backgroundColor',
                                            color
                                          )
                                        }
                                      />
                                    </div>
                                    <Button
                                      className="sketch-holder-button"
                                      type="primary"
                                      onClick={() => {
                                        setShowSketchBackgroundColor(false);
                                      }}
                                    >
                                      Select
                                    </Button>
                                  </div>
                                </Col>
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                >
                                  <div
                                    className="web-assistant-input-field-span-8-div"
                                    onClick={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.buttonType === 'no-button'
                                        ? () => {}
                                        : handleClickFontColor
                                    }
                                    style={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.buttonType === 'no-button'
                                        ? { opacity: '0.2', cursor: 'default' }
                                        : {}
                                    }
                                  >
                                    <div className="web-assistant-label-text">
                                      <Text strong>{t('font-color')}</Text>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.fontColor,
                                        height: 30,
                                        borderTop: '1px solid #d9d9d9',
                                        borderRadius: '0px 0px 3px 3px',
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="sketch-holder"
                                    style={{
                                      display: showSketchFontColor
                                        ? 'flex'
                                        : 'none',
                                    }}
                                  >
                                    <div>
                                      <SketchPicker
                                        color={
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.fontColor
                                        }
                                        onChange={(color) =>
                                          handleColorChange('fontColor', color)
                                        }
                                      />
                                    </div>
                                    <Button
                                      className="sketch-holder-button"
                                      type="primary"
                                      onClick={() => {
                                        setShowSketchFontColor(false);
                                      }}
                                    >
                                      Select
                                    </Button>
                                  </div>
                                </Col>
                                <Col
                                  span={8}
                                  className="remove-flex web-assistant-input-field-span-8"
                                >
                                  <div
                                    className="web-assistant-input-field-span-8-div"
                                    onClick={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.buttonType === 'no-button'
                                        ? () => {}
                                        : handleClickButtonColor
                                    }
                                    style={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.buttonType === 'no-button'
                                        ? { opacity: '0.2', cursor: 'default' }
                                        : {}
                                    }
                                  >
                                    <div className="web-assistant-label-text">
                                      <Text strong>{t('button-color')}</Text>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonColor,
                                        height: 30,
                                        borderTop: '1px solid #d9d9d9',
                                        borderRadius: '0px 0px 3px 3px',
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="sketch-holder"
                                    style={{
                                      display: showSketchButtonColor
                                        ? 'flex'
                                        : 'none',
                                    }}
                                  >
                                    <div>
                                      <SketchPicker
                                        color={
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonColor
                                        }
                                        onChange={(color) =>
                                          handleColorChange(
                                            'buttonColor',
                                            color
                                          )
                                        }
                                      />
                                    </div>
                                    <Button
                                      className="sketch-holder-button"
                                      type="primary"
                                      onClick={() => {
                                        setShowSketchButtonColor(false);
                                      }}
                                    >
                                      Select
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={8} md={7} lg={8}>
                              <Row>
                                <Col span={24} className="icon-picker">
                                  <IconPicker
                                    handleIconChange={(value) =>
                                      handleWebAssistantSettingsChange(
                                        'iconName',
                                        value
                                      )
                                    }
                                    model={{
                                      iconName:
                                        props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.iconName,
                                    }}
                                    setIcon={setSelectedIcon}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            className="skeleton-web-assistant"
                            style={{ maxWidth: '97%', marginTop: 30 }}
                          >
                            <Col span={24}>
                              {props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.panelType === 'embed' && (
                                <div
                                  id="editor-sonat-web-assistant-help-drawer"
                                  className={
                                    props.webAssistantConfiguration[
                                      selectedAssistantPosition
                                    ]?.position === 'right'
                                      ? 'editor-sonat-web-assistant-drawer-right open'
                                      : 'editor-sonat-web-assistant-drawer-left'
                                  }
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor: 'rgb(217,217,217)',
                                    }}
                                  >
                                    <Skeleton
                                      paragraph={{ rows: 8 }}
                                      style={{ padding: '5px', width: '80%' }}
                                    />
                                  </div>
                                </div>
                              )}
                              {props.webAssistantConfiguration[
                                selectedAssistantPosition
                              ]?.panelType === 'side-drawer' && (
                                <div>
                                  <div
                                    id="editor-sonat-web-assistant-help-drawer"
                                    className={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'right' &&
                                      isSideDrawerOpen
                                        ? 'editor-sonat-web-assistant-drawer-right open'
                                        : props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.position === 'right' &&
                                            !isSideDrawerOpen
                                          ? 'editor-sonat-web-assistant-drawer-right'
                                          : props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.position === 'left' &&
                                              isSideDrawerOpen
                                            ? 'editor-sonat-web-assistant-drawer-left open'
                                            : props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.position === 'left' &&
                                                !isSideDrawerOpen
                                              ? 'editor-sonat-web-assistant-drawer-left'
                                              : ''
                                    }
                                  >
                                    <svg
                                      onClick={handleCloseJsTriggerSkeleton}
                                      className={
                                        props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.position === 'right'
                                          ? 'editor-sonat-web-assistant-side-drawer-close-icon-right'
                                          : 'editor-sonat-web-assistant-side-drawer-close-icon-left'
                                      }
                                      width="10"
                                      height="10"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                                    </svg>
                                    <div
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgb(217,217,217)',
                                      }}
                                    >
                                      <Skeleton
                                        paragraph={{ rows: 8 }}
                                        style={{ padding: '5px', width: '80%' }}
                                      />
                                    </div>
                                  </div>
                                  {props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.buttonType === 'side-wall' &&
                                    (props.webAssistantConfiguration[
                                      selectedAssistantPosition
                                    ]?.position === 'right' ? (
                                      <div
                                        style={{
                                          backgroundColor:
                                            props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.buttonColor,
                                        }}
                                        className={
                                          isSideWallOpen
                                            ? 'editor-sonat-web-assistant-drawer-button-right open'
                                            : 'editor-sonat-web-assistant-drawer-button-right'
                                        }
                                        onClick={
                                          handleJsTriggerButtonSkeletonClicked
                                        }
                                      >
                                        <div>
                                          {props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.iconName && (
                                            <span
                                              className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName}`}
                                              style={{
                                                fontSize: '20px',
                                                color:
                                                  props
                                                    .webAssistantConfiguration[
                                                    selectedAssistantPosition
                                                  ]?.fontColor,
                                                display: 'inline-block',
                                                marginBottom: 3,
                                              }}
                                            ></span>
                                          )}
                                          <Text
                                            style={{
                                              color:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.fontColor,
                                            }}
                                          >
                                            {
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.buttonText
                                            }
                                          </Text>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor:
                                            props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.buttonColor,
                                        }}
                                        className={
                                          isSideWallOpen
                                            ? 'editor-sonat-web-assistant-drawer-button-left open'
                                            : 'editor-sonat-web-assistant-drawer-button-left'
                                        }
                                        onClick={
                                          handleJsTriggerButtonSkeletonClicked
                                        }
                                      >
                                        <div>
                                          <Text
                                            style={{
                                              color:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.fontColor,
                                              transform: 'rotate(180deg)',
                                              display: 'inline-block',
                                            }}
                                          >
                                            {
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.buttonText
                                            }
                                          </Text>
                                          {props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.iconName && (
                                            <span
                                              className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName}`}
                                              style={{
                                                fontSize: '20px',
                                                color:
                                                  props
                                                    .webAssistantConfiguration[
                                                    selectedAssistantPosition
                                                  ]?.fontColor,
                                                marginTop: 3,
                                                transform: 'none',
                                              }}
                                            ></span>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                              <Row>
                                <Col
                                  span={24}
                                  style={{
                                    borderBottom: '1px solid gray',
                                    height: 50,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div>
                                    <Skeleton.Avatar
                                      size="default"
                                      style={{ marginLeft: 10 }}
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      style={{ width: 180, marginLeft: 10 }}
                                    />
                                    <Button
                                      onClick={
                                        handleJsTriggerButtonSkeletonClicked
                                      }
                                      type="primary"
                                      style={{
                                        position: 'absolute',
                                        marginLeft: 20,
                                        width: 'fit-content',
                                      }}
                                    >
                                      Click to see JavaScript trigger feature!
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  height: 329,
                                  position: 'relative',
                                  width:
                                    props.webAssistantConfiguration[
                                      selectedAssistantPosition
                                    ]?.panelType === 'embed'
                                      ? '90%'
                                      : '100%',
                                }}
                              >
                                {props.webAssistantConfiguration[
                                  selectedAssistantPosition
                                ]?.panelType === 'slider' ? (
                                  <div
                                    className={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'right'
                                        ? 'slider make-slider-right'
                                        : 'slider make-slider-left'
                                    }
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonColor,
                                      }}
                                      className="slider-header"
                                      onClick={handleSliderBody}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {props.webAssistantConfiguration[
                                          selectedAssistantPosition
                                        ]?.iconName && (
                                          <span
                                            className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName}`}
                                            style={{
                                              fontSize: '25px',
                                              color:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.fontColor,
                                              marginRight: 8,
                                            }}
                                          ></span>
                                        )}
                                        <Text
                                          strong
                                          style={{
                                            color:
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.fontColor,
                                          }}
                                        >
                                          {
                                            props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.buttonText
                                          }
                                        </Text>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sliderOpen
                                          ? 'slider-body'
                                          : 'slider-body active-slider-body'
                                      }
                                      style={{
                                        height: sliderOpen ? '280px' : '0',
                                        display: 'flex',
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.backgroundColor,
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 160,
                                          height: 180,
                                          backgroundColor: 'white',
                                          borderRadius: 8,
                                          marginTop: 20,
                                          opacity: sliderOpen ? 1 : 0,
                                        }}
                                      >
                                        <Skeleton
                                          paragraph={{ rows: 3 }}
                                          className="skeleton-content-in-assistant"
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: 160,
                                          height: 60,
                                          backgroundColor: 'white',
                                          borderRadius: '8px 8px 0px 0px',
                                          marginTop: 20,
                                          opacity: sliderOpen ? 1 : 0,
                                          display: sliderOpen
                                            ? 'block'
                                            : 'none',
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                ) : props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.panelType === 'popup' ? (
                                  <div
                                    className={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'right'
                                        ? 'popup make-popup-right'
                                        : 'popup make-popup-left'
                                    }
                                  >
                                    {sliderOpen &&
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'right' && (
                                        <div
                                          className="popup-body-right"
                                          style={{
                                            backgroundColor:
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.backgroundColor,
                                            marginRight:
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.buttonType === 'no-button'
                                                ? 0
                                                : 20,
                                          }}
                                        >
                                          {props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonType === 'no-button' && (
                                            <svg
                                              onClick={
                                                handleJsTriggerButtonSkeletonClicked
                                              }
                                              className="editor-sonat-web-assistant-popup-close-icon-right"
                                              width="10"
                                              height="10"
                                              fill="currentColor"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                                            </svg>
                                          )}
                                          <div
                                            style={{
                                              backgroundColor:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.buttonColor,
                                            }}
                                            className="slider-header"
                                            onClick={handleSliderBody}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.iconName && (
                                                <span
                                                  className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName}`}
                                                  style={{
                                                    fontSize: '25px',
                                                    color:
                                                      props
                                                        .webAssistantConfiguration[
                                                        selectedAssistantPosition
                                                      ]?.fontColor,
                                                    marginRight: 8,
                                                  }}
                                                ></span>
                                              )}
                                              <Text
                                                style={{
                                                  color:
                                                    props
                                                      .webAssistantConfiguration[
                                                      selectedAssistantPosition
                                                    ]?.fontColor,
                                                }}
                                              >
                                                {
                                                  props
                                                    .webAssistantConfiguration[
                                                    selectedAssistantPosition
                                                  ]?.buttonText
                                                }
                                              </Text>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              width: 160,
                                              height: 180,
                                              backgroundColor: 'white',
                                              borderRadius: 8,
                                              marginTop: 20,
                                            }}
                                          >
                                            <Skeleton
                                              paragraph={{ rows: 3 }}
                                              className="skeleton-content-in-assistant"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              width: 160,
                                              height: 60,
                                              backgroundColor: 'white',
                                              borderRadius: '8px 8px 0px 0px',
                                              marginTop: 20,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                    {props.webAssistantConfiguration[
                                      selectedAssistantPosition
                                    ]?.buttonType === 'no-button' ? (
                                      <></>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor:
                                            props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.buttonColor,
                                        }}
                                        className={'popup-header'}
                                        onClick={handleSliderBody}
                                      >
                                        {sliderOpen ? (
                                          <MdOutlineClear
                                            style={{
                                              color:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.fontColor,
                                            }}
                                            className="icon-in-popup"
                                          />
                                        ) : (
                                          <span
                                            className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName} icon-in-popup`}
                                            style={{
                                              color:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.fontColor,
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                    )}
                                    {sliderOpen &&
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'left' && (
                                        <div
                                          className="popup-body-left"
                                          style={{
                                            backgroundColor:
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.backgroundColor,
                                            marginLeft:
                                              props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.buttonType === 'no-button'
                                                ? 0
                                                : 20,
                                          }}
                                        >
                                          {props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonType === 'no-button' && (
                                            <svg
                                              onClick={
                                                handleJsTriggerButtonSkeletonClicked
                                              }
                                              className="editor-sonat-web-assistant-popup-close-icon-left"
                                              width="10"
                                              height="10"
                                              fill="currentColor"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                                            </svg>
                                          )}
                                          <div
                                            style={{
                                              backgroundColor:
                                                props.webAssistantConfiguration[
                                                  selectedAssistantPosition
                                                ]?.buttonColor,
                                            }}
                                            className="slider-header"
                                            onClick={handleSliderBody}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {props.webAssistantConfiguration[
                                                selectedAssistantPosition
                                              ]?.iconName && (
                                                <span
                                                  className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName}`}
                                                  style={{
                                                    fontSize: '25px',
                                                    color:
                                                      props
                                                        .webAssistantConfiguration[
                                                        selectedAssistantPosition
                                                      ]?.fontColor,
                                                    marginRight: 8,
                                                  }}
                                                ></span>
                                              )}
                                              <Text
                                                strong
                                                style={{
                                                  color:
                                                    props
                                                      .webAssistantConfiguration[
                                                      selectedAssistantPosition
                                                    ]?.fontColor,
                                                }}
                                              >
                                                {
                                                  props
                                                    .webAssistantConfiguration[
                                                    selectedAssistantPosition
                                                  ]?.buttonText
                                                }
                                              </Text>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              width: 160,
                                              height: 180,
                                              backgroundColor: 'white',
                                              borderRadius: 8,
                                              marginTop: 20,
                                            }}
                                          >
                                            <Skeleton
                                              paragraph={{ rows: 3 }}
                                              className="skeleton-content-in-assistant"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              width: 160,
                                              height: 60,
                                              backgroundColor: 'white',
                                              borderRadius: '8px 8px 0px 0px',
                                              marginTop: 20,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                  </div>
                                ) : props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.panelType === 'side-drawer' &&
                                  props.webAssistantConfiguration[
                                    selectedAssistantPosition
                                  ]?.buttonType === 'float' ? (
                                  <div
                                    className={
                                      props.webAssistantConfiguration[
                                        selectedAssistantPosition
                                      ]?.position === 'right'
                                        ? 'popup make-popup-right'
                                        : 'popup make-popup-left'
                                    }
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          props.webAssistantConfiguration[
                                            selectedAssistantPosition
                                          ]?.buttonColor,
                                      }}
                                      className={'popup-header'}
                                      onClick={
                                        handleJsTriggerButtonSkeletonClicked
                                      }
                                    >
                                      <span
                                        className={`active mdi ${props.webAssistantConfiguration[selectedAssistantPosition]?.iconName} icon-in-popup`}
                                        style={{
                                          color:
                                            props.webAssistantConfiguration[
                                              selectedAssistantPosition
                                            ]?.fontColor,
                                        }}
                                      ></span>
                                    </div>
                                  </div>
                                ) : null}
                                <Col
                                  span={5}
                                  style={{ borderRight: '1px solid gray' }}
                                >
                                  <div>
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                    <Skeleton.Input
                                      size="default"
                                      className="skeleton-input-topic-tree"
                                    />
                                  </div>
                                </Col>
                                <Col span={15}>
                                  <Skeleton
                                    paragraph={{ rows: 6 }}
                                    className="skeleton-content"
                                  />
                                </Col>
                                <Col span={4}></Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.buttons}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={props.handleSaveAccountSettings}
                      className={classes.button}
                      disabled={props.loading || props.disableSaveButton}
                    >
                      {props.loading ? (
                        <CircularProgress
                          style={{ width: 25, height: 25, color: '#fff' }}
                        />
                      ) : (
                        t('save')
                      )}
                    </Button>
                  </div>
                </div>
              )}

              {isEmptySassList && (
                <div
                  style={{
                    border: '1px solid #f0f0f0',
                    borderRadius: '5px',
                    padding: '10px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Title level={4}>{t('no-active-saas-assistant')}</Title>
                </div>
              )}
            </div>
          )}
        {props.webAssistantConfiguration?.length > 0 ? (
          <ConfirmationDialog
            title={t<string>('rm-saas-assistant')}
            message={`Are you sure you want to remove ${props?.webAssistantConfiguration[selectedAssistantPosition]?.title || 'untitled-saas-assistant'}  SaaS assistant?`}
            isOpen={openDeleteDialog}
            onDialogClosed={handleCloseDeleteDialog}
            onDialogConfirmed={() => handleDelete()}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(AccountWebAssistantSettings);
