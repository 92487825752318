import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { User } from '../../api-schemas/user';
import { redirectToLoginPage } from '../../helpers/route/private';

const getProfileRequest = () => {
  return axios
    .get<User>(`/api/User/Profile`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        redirectToLoginPage();
      }

      throw error;
    });
};

export default function useGetProfile() {
  const {
    data: profile,
    refetch: getProfile,
    isFetching: isLoadingProfile,
    isSuccess: isSuccessLoadingProfile,
  } = useQuery<User>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['profile'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: getProfileRequest,
  });

  return {
    profile,
    isLoadingProfile,
    isSuccessLoadingProfile,

    getProfile,
  };
}
