import React, { useEffect } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import useUpdateUser from '../../../api-hooks/user/useUpdateUser';

const useStyles: any = makeStyles(() => ({
  title: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
}));

const ProfileNotificationConfigurationEditor = ({ profileData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { updateUser, isUpdatingUser, isErrorUpdatingUser } = useUpdateUser(
    profileData.email
  );

  const onNotificationSettingsChange = (checked: boolean) => {
    updateUser({ disableEmailNotifications: !checked });
  };

  useEffect(() => {
    if (isErrorUpdatingUser) {
      enqueueSnackbar(t('error.unknown'), {
        variant: 'error',
      });
    }
  }, [isErrorUpdatingUser]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className={classes.title}>{t('recieve-email-notification')}</div>
      <Switch
        loading={!profileData || isUpdatingUser}
        checked={!profileData?.disableEmailNotifications}
        onChange={onNotificationSettingsChange}
      />
    </div>
  );
};

export default ProfileNotificationConfigurationEditor;
