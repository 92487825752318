import config from 'react-global-configuration';

export const changeRouteBaseOnEditContentMenues = (
  menuItem: string | null,
  topicId: string,
  pathname: string | null = null
) => {
  const url: URL = new URL(window.location.href);
  const version: string | null = url.searchParams.get('ver');
  const language: string | null = url.searchParams.get('lang');
  const unwatchQuery = getUnwatchQuery();
  const path: string = pathname || window.location.pathname;
  const isDocumentPage = path.split('/').length === 6;

  if (menuItem) {
    return [
      true,
      path.at(-1) === '/' && isDocumentPage
        ? `${path}${menuItem !== 'Edit' ? menuItem : 'content'}?lang=${language}&ver=${version}`
        : path.at(-1) === '/'
          ? `${path
              .split('/')
              .slice(0, isDocumentPage ? 4 : 3)
              .join(
                '/'
              )}${topicId ? '/' + topicId : ''}${menuItem !== 'Edit' ? '/' + menuItem : '/content'}?lang=${language}${version ? `&ver=${version}` : ''}${unwatchQuery ? '&' + unwatchQuery : ''}`
          : `${path
              .split('/')
              .slice(0, isDocumentPage ? 4 : 3)
              .join(
                '/'
              )}${topicId ? '/' + topicId : ''}${menuItem !== 'Edit' ? '/' + menuItem : '/content'}?lang=${language}${version ? `&ver=${version}` : ''}${unwatchQuery ? '&' + unwatchQuery : ''}`,
    ];
  } else {
    const value: string | undefined =
      path.at(-1) === '/' ? path.split('/').at(-2) : path.split('/').at(-1);

    if (
      value === 'Settings' ||
      value === 'History' ||
      value === 'Feedback' ||
      value === 'RefLinks' ||
      value === 'Metrics'
    ) {
      return [value, true];
    } else {
      return ['Edit', false];
    }
  }
};

export const getEditContentUrl = (
  accountId: string,
  documentId: string | undefined,
  topicId: string
) => {
  const url: URL = new URL(window.location.href);
  const version: string | null = url.searchParams.get('ver');
  const language: string | null = url.searchParams.get('lang');

  if (documentId) {
    return `/document/${accountId}/${documentId}/${topicId}/content?lang=${language}&ver=${version}`;
  }

  return `/topic/${accountId}/${topicId}/content?lang=${language}`;
};

export const changeRouteBaseOnFeedbackAnalysisFilters = (
  history,
  accountId: string,
  documentId: string,
  versionSlug: string | null,
  languageCode: string | null
) => {
  history.push(
    `/document/${accountId}/${documentId}/feedback?lang=${languageCode}&ver=${versionSlug}`
  );
};

export const getVersionSlug = () => {
  const url: URL = new URL(window.location.href);
  return url.searchParams.get('ver');
};

export const getLanguageCode = () => {
  const url: URL = new URL(window.location.href);
  return url.searchParams.get('lang')!;
};

export const getCommentId = () => {
  const url: URL = new URL(window.location.href);
  return url.searchParams.get('commentId');
};

export const getUnwatchQuery = () => {
  let unwatchQuery: string | null = null;
  const url: URL = new URL(window.location.href);
  if (url.searchParams.get('unwatch')) {
    unwatchQuery = 'unwatch=true';
  }
  if (url.searchParams.get('unwatchDoc')) {
    unwatchQuery = 'unwatchDoc=true';
  }
  if (url.searchParams.get('unwatchTopic')) {
    unwatchQuery = 'unwatchTopic=true';
  }
  return unwatchQuery;
};

export const getQueries = (selectedVersion, selectedLanguage) => {
  let queries: string | null =
    selectedVersion || selectedLanguage
      ? selectedVersion && !selectedLanguage
        ? `?ver=${selectedVersion.slug}`
        : selectedLanguage && !selectedVersion
          ? `?lang=${selectedLanguage.code}`
          : `?lang=${selectedLanguage.code}&ver=${selectedVersion.slug}`
      : null;
  const commentId = getCommentId();
  if (commentId) {
    if (queries) {
      queries += `&commentId=${commentId}`;
    } else {
      queries = `?commentId=${commentId}`;
    }
  }
  const unwatchQuery = getUnwatchQuery();
  if (unwatchQuery) {
    if (queries) {
      queries += `&${unwatchQuery}`;
    } else {
      queries = `?${unwatchQuery}`;
    }
  }
  return queries;
};

export const changeRouteBasedOnTopic = (
  selectedVersion,
  selectedLanguage,
  history,
  newTopicId: string | null = null,
  selectedSidePart: string | null = null
) => {
  const topicId: string = newTopicId ?? window.location.pathname.split('/')[4];
  const documentId: string = window.location.pathname.split('/')[3];
  const accountId: string = window.location.pathname.split('/')[2];
  const queries = getQueries(selectedVersion, selectedLanguage);
  let newPath = '';
  if (topicId) {
    newPath = `/document/${accountId}/${documentId}/${topicId}/content${queries || ''}`;
  } else if (window.location.pathname.split('/').length === 5 || !topicId) {
    newPath = `/document/${accountId}/${documentId}/content${queries || ''}`;
  }
  if (selectedSidePart) {
    if (queries) {
      newPath += `&selectedSidePanel=${selectedSidePart}`;
    } else {
      newPath += `?selectedSidePanel=${selectedSidePart}`;
    }
  }
  if (window.location.pathname !== newPath) history.replace(newPath);
};

export const getDocLink = (
  document,
  versions,
  viewerToken: string | null = null,
  getPublishedLink: boolean = true
) => {
  const viewerURL = config.get('viewerURL');
  if (document.account.customDomain && getPublishedLink) {
    return `https://${document.account.customDomain}/${document.slug}${versions.length > 1 ? '/' + document.defaultDocumentVersionSlug : ''}${/*document.languages.length > 1 ? */ '?lang=' + document.defaultLanguageCode /* : ""*/}${viewerToken ? `&viewerToken=${viewerToken}` : ''}`;
  } else {
    return `${viewerURL}${document.account.slug}/${getPublishedLink ? document.slug : document.id}${versions.length > 1 ? '/' + document.defaultDocumentVersionSlug : ''}${/*document.languages.length > 1 ? */ '?lang=' + document.defaultLanguageCode /* : ""*/}${getPublishedLink ? '' : '&previewMode=true'}${viewerToken ? `&viewerToken=${viewerToken}` : ''}`;
  }
};

export const getAccountLink = (account, viewerToken: string | null = null) => {
  const viewerURL = config.get('viewerURL');
  if (account.customDomain) {
    return `https://${account.customDomain}${viewerToken ? `?viewerToken=${viewerToken}` : ''}`;
  } else {
    return `${viewerURL}${account.slug}${viewerToken ? `?viewerToken=${viewerToken}` : ''}`;
  }
};

export const isHomepage = () => window.location.pathname === '/';
