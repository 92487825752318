import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteVariableRequest = (variableId: string) => {
    return axios.delete(`/api/Variable/${variableId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useDeleteVariable(accountId: string) {
    const queryClient = useQueryClient();

    const {
        mutateAsync: deleteVariable,
        isPending: isDeletingVariable,
        isSuccess: isSuccessDeletingVariable
    } = useMutation({
        mutationFn: deleteVariableRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['variables', accountId] });
        }
    });

    return {
        deleteVariable,
        isDeletingVariable,
        isSuccessDeletingVariable
    }
}