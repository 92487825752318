import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Select, Tooltip } from 'antd';
import ISO6391 from 'iso-639-1';
import { CircularProgress, IconButton } from '@mui/material';
import { DoneOutline, DeleteOutlined } from '@mui/icons-material';

import SelectInput from '../../../inputs/select-input';
import { AccountContext } from '../../../../contexts/account';
import useUpdateAccountLanguages from '../../../../api-hooks/account/useUpdateAccountLanguages';
import useUpdateAccountDefaultLanguage from '../../../../api-hooks/account/useUpdateAccountDefaultLanguage';
import useGetAccounts from '../../../../api-hooks/account/useGetAccounts';

const { Option } = Select;

const ALL_AVAILABLE_LANGUAGES = ISO6391.getAllNames()
  .map((langName) => ({
    name: langName,
    languageCode: ISO6391.getCode(langName),
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

const ManageAccountLanguages = () => {
  const { t } = useTranslation();

  const { selectedAccount } = useContext(AccountContext);

  const [newLanguage, setNewLanguage] = useState<string | null>(null);
  const [isAddingLanguage, setIsAddingLanguage] = useState<boolean>(false);

  const {
    updateAccountLanguages,
    isUpdatingAccountLanguages,
    isSuccessUpdatingAccountLanguages,
  } = useUpdateAccountLanguages(selectedAccount?.id);
  const { updateAccountDefaultLanguage, isUpdatingAccountDefaultLanguage } =
    useUpdateAccountDefaultLanguage(selectedAccount?.id);
  const { isLoadingAccounts } = useGetAccounts();

  useEffect(() => {
    if (isSuccessUpdatingAccountLanguages) {
      handleCancelAddingLanguage();
    }
  }, [isSuccessUpdatingAccountLanguages]);

  const handleDeleteLanguage = (deletedLanguageCode: string) => {
    const newLanguages = [...selectedAccount!.languages];

    newLanguages.splice(
      newLanguages.findIndex(
        (language) => language.languageCode === deletedLanguageCode
      ),
      1
    );

    updateAccountLanguages({
      accountId: selectedAccount!.id,
      languages: newLanguages,
    });
  };

  const handleAddLanguage = () => {
    updateAccountLanguages({
      accountId: selectedAccount!.id,
      languages: [
        ...selectedAccount!.languages,
        { languageCode: newLanguage, hasPublishedTopic: false },
      ],
    });
  };

  const handleSetDefaultLanguage = (languageCode: string) => {
    updateAccountDefaultLanguage({
      accountId: selectedAccount!.id,
      defaultLanguageCode: languageCode,
    });
  };

  const handleCancelAddingLanguage = () => {
    setNewLanguage(null);
    setIsAddingLanguage(false);
  };

  return (
    <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
      <Col xs={12} style={{ padding: 16 }}>
        <Col
          xs={24}
          style={{
            borderBottom: '1px solid rgb(240, 240, 240)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 5,
          }}
        >
          <span style={{ fontWeight: 'bold' }}>{t('languages')}</span>

          <Button
            disabled={isAddingLanguage || isLoadingAccounts}
            onClick={() => setIsAddingLanguage(true)}
            type="primary"
          >
            {t('new')}
          </Button>
        </Col>

        {isUpdatingAccountLanguages || isLoadingAccounts ? (
          <Col
            xs={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 16,
            }}
          >
            <CircularProgress style={{ width: 24, height: 24 }} />
          </Col>
        ) : (
          <Col xs={24}>
            {selectedAccount?.languages.map(({ languageCode }, index) => {
              const languageName = ISO6391.getName(languageCode);
              const isNewLanguage = !selectedAccount.languages.find(
                (accountLanguage) =>
                  accountLanguage.languageCode === languageCode
              );
              const isDefaultLanguage =
                selectedAccount.defaultLanguageCode === languageCode;

              return (
                <Col
                  key={languageCode}
                  xs={24}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>
                    {index + 1}. {languageName}
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title={t('delete')}>
                      <DeleteOutlined
                        style={{
                          marginRight: 5,
                          color: '#ff0000cc',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDeleteLanguage(languageCode)}
                      />
                    </Tooltip>

                    {isDefaultLanguage ? (
                      <Tooltip
                        title={
                          <Trans i18nKey="language.default">
                            {' '}
                            {{ languageName }} is your default language{' '}
                          </Trans>
                        }
                      >
                        <IconButton
                          aria-label={`${languageName} is your default language`}
                        >
                          <DoneOutline style={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          <Trans i18nKey="language.setAsDefault">
                            {' '}
                            Set {{ languageName }} as your default{' '}
                          </Trans>
                        }
                      >
                        <span>
                          <IconButton
                            aria-label={`Set ${languageName} as default`}
                            onClick={() =>
                              handleSetDefaultLanguage(languageCode)
                            }
                            disabled={
                              isNewLanguage || isUpdatingAccountDefaultLanguage
                            }
                          >
                            <DoneOutline />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Col>
              );
            })}

            {isAddingLanguage && selectedAccount ? (
              <Col
                xs={24}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                }}
              >
                <SelectInput
                  id="new-language-select"
                  layout="horizontal"
                  style={{ width: '200px' }}
                  field={`${selectedAccount.languages.length + 1} `}
                  label={`${selectedAccount.languages.length + 1} `}
                  required={true}
                  showError={false}
                  onChange={(newValue) => setNewLanguage(newValue)}
                  showSearch={false}
                  value={newLanguage}
                >
                  {ALL_AVAILABLE_LANGUAGES.filter(
                    (language) =>
                      selectedAccount.languages.findIndex(
                        (accountLanguage) =>
                          accountLanguage.languageCode === language.languageCode
                      ) === -1
                  ).map((language) => {
                    return (
                      <Option
                        key={language.languageCode}
                        value={language.languageCode}
                      >
                        {`${language.name} (${language.languageCode})`}
                      </Option>
                    );
                  })}
                </SelectInput>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    disabled={isUpdatingAccountLanguages}
                    onClick={handleCancelAddingLanguage}
                    style={{ marginRight: 5 }}
                  >
                    {t('cancel')}
                  </Button>

                  <Button
                    disabled={!newLanguage || isUpdatingAccountLanguages}
                    onClick={handleAddLanguage}
                    type="primary"
                  >
                    {t('save')}
                  </Button>
                </div>
              </Col>
            ) : null}
          </Col>
        )}
      </Col>
    </Col>
  );
};

export default ManageAccountLanguages;
