import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import WatchSettings from '../../../watch/watch-settings';
import TranslationLanguages from './translation-languages';
import { DocumentContext } from '../../../../contexts/document';
import PublishRequests from './publish-requests';
import PreviewBox from './preview-box';
import NewTabBox from './new-tab-box';
import useGetPublishRequests from '../../../../api-hooks/publish-request/useGetPublishRequests';
import { TopicContext } from '../../../../contexts/topic';

const SecondaryButtons = ({ isLanguageEqual }) => {
  const [refreshWatchersList, setRefreshWatchersList] = useState<boolean>(true);

  const { documentId }: { documentId?: string | null } = useParams();

  const { selectedTopic } = useContext(TopicContext);

  const { publishRequests } = useGetPublishRequests({
    documentId,
    status: 'open',
  });

  const { selectedDoc, selectedLanguage, selectedVersion } =
    useContext(DocumentContext);

  const isDoclessTopic = useRef<boolean>(
    Boolean(selectedTopic && !selectedTopic.documentId)
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {((selectedLanguage && selectedVersion) || isDoclessTopic.current) &&
          selectedTopic &&
          parseFloat(selectedTopic.topicVersionNumber) >= 1.0 && <NewTabBox />}
        {((selectedTopic && selectedLanguage && selectedVersion) ||
          isDoclessTopic.current) && <PreviewBox />}
      </div>

      {documentId ? (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <PublishRequests publishRequests={publishRequests} />
        </div>
      ) : null}

      <div style={{ alignItems: 'center', display: 'flex' }}>
        <WatchSettings
          entityId={selectedTopic?.topicId}
          entityType="Topics"
          hasMargin={
            selectedTopic &&
            selectedDoc &&
            selectedTopic.languages?.length < selectedDoc.languages?.length
          }
          refreshWatchersList={refreshWatchersList}
          setRefreshWatchersList={setRefreshWatchersList}
        />
      </div>

      <TranslationLanguages isLanguageEqual={isLanguageEqual} />
    </div>
  );
};

export default SecondaryButtons;
