import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteProcessRequest = (processId: string) => {
  return axios
    .delete(`/api/process/${processId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useDeleteProcess(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteProcess,
    isPending: isDeletingProcess,
    isSuccess: isSuccessDeletingProcess,
  } = useMutation({
    mutationFn: deleteProcessRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['processes', accountId] });
    },
  });

  return {
    deleteProcess,
    isDeletingProcess,
    isSuccessDeletingProcess,
  };
}
