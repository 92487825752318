import { useContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { DraftTopicTree } from '../../api-schemas/topic';
import { emptyGuid } from '../../helpers/common';
import { createTopicTree } from '../../helpers/topic';
import { DocumentContext } from '../../contexts/document';

const getDraftTopicTreeRequest = ({
  documentVersionId,
  languageCode,
}: {
  documentVersionId: string;
  languageCode: string;
}) => {
  return axios
    .get<DraftTopicTree>(`/api/TopicTree/Draft`, {
      params: { documentVersionId, languageCode },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetDraftTopicTree({
  documentVersionId,
  languageCode,
}: {
  documentVersionId?: string;
  languageCode?: string;
}) {
  const { setDocumentTopics } = useContext(DocumentContext);

  const {
    data,
    refetch: getDraftTopicTree,
    isFetching: isLoadingDraftTopicTree,
    isRefetching: isRefetchingDraftTopicTree,
    isError: isErrorLoadingDraftTopicTree,
    isSuccess: isSuccessLoadingDraftTopicTree,
  } = useQuery<DraftTopicTree>({
    staleTime: 500,
    queryKey: ['draftTopicTree', documentVersionId, languageCode],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled:
      Boolean(documentVersionId && languageCode) &&
      documentVersionId !== emptyGuid(),
    queryFn: () =>
      documentVersionId && languageCode
        ? getDraftTopicTreeRequest({ documentVersionId, languageCode })
        : [],
  });

  const draftTopicTree = useMemo(() => {
    if (data) {
      return createTopicTree(data, null);
    }

    return [];
  }, [data]);

  const flatDraftTopicTree = useMemo(() => {
    return data || [];
  }, [data]);

  useEffect(() => {
    if (data) setDocumentTopics(data);
  }, [isSuccessLoadingDraftTopicTree]);

  return {
    draftTopicTree,
    flatDraftTopicTree,
    isLoadingDraftTopicTree,
    isRefetchingDraftTopicTree,
    isErrorLoadingDraftTopicTree,
    isSuccessLoadingDraftTopicTree,

    getDraftTopicTree,
  };
}
