export const localizeKey = (msg: string) => {
  if (msg) {
    return msg
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/&/g, '-')
      .replace(/\./g, '-')
      .replace(/[^\w-]+/g, '-');
  }
  return msg;
};

export const formError = (errors, errorType) => {
  const errCode = errors.filter((err) => err.field === errorType)[0]?.code;
  return localizeKey(errCode) + '.' + errorType.toLowerCase();
};
