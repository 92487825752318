import React, { useMemo } from 'react';
import config from 'react-global-configuration';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from '@tanstack/react-query';

import { Button } from '@mui/material';

export const QueryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const settingsUrl = `${config.get('editorURL')}/org/settings?selectedTab=google drive integration`;

  const queryClient = useMemo(() => {
    return new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          if ((error as AxiosError).response?.status === 403) {
            enqueueSnackbar(t('error-access-denied'), { variant: 'error' });
          } else if ((error as AxiosError).response?.status === 406) {
            enqueueSnackbar(t('google-drive-expired-token-error'), {
              variant: 'error',
              autoHideDuration: 15000,
              action: () => (
                <Button
                  onClick={() =>
                    window.open(settingsUrl, '_blank', 'noopener,noreferrer')
                  }
                  style={{ color: '#ccccff', textTransform: 'none' }}
                >
                  {t('navigate-to-org-settings')}
                </Button>
              ),
            });
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error) => {
          if ((error as AxiosError).response?.status === 403) {
            enqueueSnackbar(t('error-access-denied'), { variant: 'error' });
          } else if ((error as AxiosError).response?.status === 406) {
            enqueueSnackbar(t('google-drive-expired-token-error'), {
              variant: 'error',
              autoHideDuration: 15000,
              action: () => (
                <Button
                  onClick={() =>
                    window.open(settingsUrl, '_blank', 'noopener,noreferrer')
                  }
                  style={{ color: '#ccccff', textTransform: 'none' }}
                >
                  {t('navigate-to-org-settings')}
                </Button>
              ),
            });
          }
        },
      }),
    });
  }, [enqueueSnackbar, t]);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryProvider;
