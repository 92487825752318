import React, { useContext, useEffect, useState } from 'react';
import { Typography, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReadabilityAnalytics from './readability-analytics';
import SEOAnalytics from './seo-analytics';
import TopicTreeSelect from '../topic/tree/select';
import { DocumentContext } from '../../contexts/document';
import { topicService } from '../../services/topic';
import { getLanguageCode, getVersionSlug } from '../../helpers/route';
import { getDocumentMetrics } from '../../helpers/common';
import { useHistory, useLocation } from 'react-router-dom';
import data from './Metrics.json';
import { TopicContext } from '../../contexts/topic';

const seoData = data.seo;
const readabilityData = data.readability;

const useStyles: any = makeStyles(() => ({
  layout: {
    width: '100%',
    height: 'calc(100% - 40px)',
  },
  dialog: {
    '& .MuiPaper-root': {
      padding: '20px 30px 20px 20px',
      minWidth: 400,
    },
  },
}));

const MetricsAnalytics = () => {
  const [selectedMetric, setSelectedMetric] = useState<any>();
  const [selectedMetricCategory, setSelectedMetricCategory] =
    useState<string>('SEO');

  const {
    selectedLanguage,
    selectedDoc,
    selectedVersion,
    setSelectedVersion,
    documentTopics,
    setDocumentTopics,
    versions,
    setSelectedTopic,
  } = useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const classes = useStyles();

  const history = useHistory();
  const { hash: selectedSideSection } = useLocation();

  useEffect(() => {
    if (
      selectedDoc &&
      versions &&
      versions.length &&
      documentTopics &&
      documentTopics.length === 0 &&
      !selectedTopic
    ) {
      const topicAPI = new topicService();
      const versionSlug = getVersionSlug();
      const selectedVersion = versions.find(
        (version) => version.slug === versionSlug
      );
      setSelectedVersion(selectedVersion);
      const languageCode = getLanguageCode();
      if (selectedVersion) {
        topicAPI
          .getTopics(
            selectedVersion?.id,
            languageCode || selectedDoc.defaultLanguageCode
          )
          .then((result) => {
            setDocumentTopics(result);
          });
      }
    }
    // eslint-disable-next-line
  }, [selectedDoc, versions, documentTopics]);

  const groupDataByVariant = (data: any[]) => {
    const newData: any = {
      goodResults: [],
      improvements: [],
      errors: [],
    };

    const metrics = selectedTopic?.metrics
      ? selectedTopic?.metrics
      : selectedDoc?.metrics
        ? getDocumentMetrics(selectedDoc, getVersionSlug(), getLanguageCode())
        : null;

    data.forEach((metric) => {
      const value = metrics ? metrics[metric.field] : null;
      if (value !== null && value !== undefined) {
        const newMetric = { ...metric };
        newMetric.value = value;
        if (metric.type === 'Boolean') {
          if (typeof newMetric.value === 'number') {
            if (newMetric.value > 66) {
              newData.goodResults.push(newMetric);
            } else if (newMetric.value < 33) {
              newData.errors.push(newMetric);
            } else {
              newData.improvements.push(newMetric);
            }
          } else {
            if (Boolean(newMetric.value) === metric.successValue) {
              newData.goodResults.push(newMetric);
            } else {
              newData.errors.push(newMetric);
            }
          }
        } else if (metric.type === 'Number') {
          if (
            newMetric.value >= metric.successValueStartRange &&
            newMetric.value <= metric.successValueEndRange
          ) {
            newData.goodResults.push(newMetric);
          } else if (
            metric.warningValueStartRange !== undefined &&
            newMetric.value >= metric.warningValueStartRange &&
            newMetric.value <= metric.warningValueEndRange
          ) {
            newData.improvements.push(newMetric);
          } else {
            newData.errors.push(newMetric);
          }
        }
      }
    });
    return newData;
  };

  const handleChangeMetric = (newMetric, newMetricCategory) => {
    if (newMetric !== selectedMetric) setSelectedMetric(newMetric);
    if (newMetricCategory !== selectedMetricCategory)
      setSelectedMetricCategory(newMetricCategory);
  };

  const settingsItem = window.location.pathname.split('/')[5];

  return (
    <div
      className={classes.layout}
      style={
        selectedSideSection
          ? {
              marginRight: 10,
              marginTop: 10,
              border: '1px solid rgb(196, 196, 196)',
              padding: 10,
            }
          : {}
      }
    >
      <Typography
        style={{
          margin: '10px 0 15px 0',
          marginLeft: selectedSideSection ? 0 : 20,
        }}
        component="h1"
        variant="h6"
      >
        Metrics Analytics
      </Typography>
      <div
        style={
          selectedSideSection
            ? {
                maxHeight: 'calc(100vh - 220px)',
                overflowY: 'auto',
                display: 'flex',
              }
            : { display: 'flex' }
        }
      >
        {selectedSideSection !== '#readability' ||
        settingsItem === 'Metrics' ? (
          <SEOAnalytics
            data={groupDataByVariant(seoData)}
            handleChangeMetric={handleChangeMetric}
            selectedTopic={selectedTopic}
            selectedDoc={selectedDoc}
            selectedSidePart={selectedSideSection}
          />
        ) : null}
        {selectedSideSection !== '#SEO' || settingsItem === 'Metrics' ? (
          <ReadabilityAnalytics
            data={groupDataByVariant(readabilityData)}
            handleChangeMetric={handleChangeMetric}
            selectedTopic={selectedTopic}
            selectedSidePart={selectedSideSection}
          />
        ) : null}
      </div>
      {!!selectedMetric && !selectedTopic ? (
        <Dialog
          className={classes.dialog}
          onClose={() => setSelectedMetric(null)}
          open={!!selectedMetric}
        >
          <DialogTitle>{`${selectedMetric.title}`}</DialogTitle>
          <TopicTreeSelect
            topics={documentTopics}
            usedForCopy={false}
            languageCode={
              selectedLanguage ? selectedLanguage.code : getLanguageCode()
            }
            selectedVersion={selectedVersion}
            showHeadings={false}
            viewMode={true}
            showMetricData={true}
            history={history}
            metric={selectedMetric}
            selectedMetricCategory={selectedMetricCategory}
            setSelectedTopic={setSelectedTopic}
          />
        </Dialog>
      ) : null}
    </div>
  );
};

export default MetricsAnalytics;
