import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from 'antd';

const HelpSnippet = (props) => {
  const styles: any = {
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '5px',
    height: '19px',
  };
  return (
    <Tooltip title={props.title}>
      <HelpIcon style={{ ...props.style, ...styles }} />
    </Tooltip>
  );
};

export default HelpSnippet;
