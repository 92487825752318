import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdatePublishRequestReviewerRequest } from '../../api-schemas/publish-request';

const updatePublishRequestReviewerRequest = (
  publishRequestId: string,
  data: UpdatePublishRequestReviewerRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .put(`/api/PublishRequest/${publishRequestId}/Reviewer`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdatePublishRequestReviewer(
  publishRequestId: string
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updatePublishRequestReviewer,
    isPending: isUpdatingPublishRequestReviewer,
    isSuccess: isSuccessUpdatingPublishRequestReviewer,
  } = useMutation({
    mutationFn: (params: UpdatePublishRequestReviewerRequest) =>
      updatePublishRequestReviewerRequest(publishRequestId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['publishRequests'],
      });

      queryClient.invalidateQueries({
        queryKey: ['publishRequest', publishRequestId],
      });
    },
  });

  return {
    updatePublishRequestReviewer,
    isUpdatingPublishRequestReviewer,
    isSuccessUpdatingPublishRequestReviewer,
  };
}
