import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, TextareaAutosize, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProfileContext } from '../../../contexts/profile';
import CommentBox from '../comment-box';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { emptyGuid } from '../../../helpers/common';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from 'notistack';
import { UserOutlined } from '@ant-design/icons';
import './styles.css';
import useCreateThread from '../../../api-hooks/comment/useCreateThread';
import useCreateComment from '../../../api-hooks/comment/useCreateComment';

const useStyles: any = makeStyles(() => ({
  threadBox: {
    border: '1px solid rgb(196 196 196)',
    marginBottom: 20,
    borderRadius: 0,
  },
  textField: {
    border: 'none',
    fontSize: 13,
    width: '100%',
    background: 'none',
    '&:focus-visible': {
      border: 'none!important',
      outline: 'none!important',
    },
  },
  closeIcon: {
    fontSize: '20px !important',
    position: 'absolute',
    bottom: 5,
    right: 10,
    cursor: 'pointer',
    color: '#757575',
    width: '17px !important',
    height: '17px !important',
  },
  doneIcon: {
    fontSize: '20px !important',
    position: 'absolute',
    bottom: 5,
    right: 37,
    cursor: 'pointer',
    color: '#757575',
    width: '17px !important',
    height: '17px !important',
  },
}));

const ThreadBox = ({ fullWidth, thread, accesses, currentFilter }) => {
  const [emptyComment, setEmptyComment] = useState<any>({
    authorId: '',
    content: '',
  });
  const [moreCommentsDisplay, setMoreCommentsDisplay] = useState('none');

  const { createThread, isCreatingThread } = useCreateThread(thread.channelId);
  const { createComment, isCreatingComment } = useCreateComment({
    threadId: thread.id,
    channelId: thread.channelId,
  });

  const { t } = useTranslation();

  const { profileData } = useContext(ProfileContext);

  useEffect(() => {
    if (profileData) {
      setEmptyComment({ ...emptyComment, authorId: profileData.id });
    }
    // eslint-disable-next-line
  }, [profileData]);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveComment = () => {
    if (
      emptyComment.content !== null &&
      emptyComment.content.split(' ').join('').length === 0
    ) {
      enqueueSnackbar(t('error.empty-comment'), {
        variant: 'error',
      });
      return;
    }
    if (thread.id === emptyGuid()) {
      const data = { ...thread };

      delete data.id;
      data.comments = [emptyComment];

      createThread(data).then(async () => {
        setEmptyComment({ ...emptyComment, content: '' });
      });
    } else {
      createComment({ threadId: thread.id, data: emptyComment })
        .then(async () => {
          setEmptyComment({ ...emptyComment, content: '' });
        })
        .catch(() => {
          enqueueSnackbar(t('error.unknown'), {
            variant: 'error',
          });
        });
    }
  };

  const handleChangeCommentContent = (newValue: string) => {
    setEmptyComment({ ...emptyComment, content: newValue });
  };

  useEffect(() => {
    if (thread && thread.comments && thread.comments.length > 2) {
      const url = new URL(window.location.href);
      const commentId = url.searchParams.get('commentId');
      if (commentId) {
        const comments = thread.comments
          .slice(1, thread.comments.length - 1)
          .filter((comment) => comment.id === commentId);
        if (comments.length) {
          setMoreCommentsDisplay('block');
        }
      }
    }
  }, [thread]);

  return (
    <div
      className={classes.threadBox}
      style={{
        backgroundColor:
          currentFilter === 'all' && thread.resolved === true
            ? 'rgb(223 255 230)'
            : '#fff',
      }}
    >
      {thread.comments && thread.comments.length !== 0 && (
        <CommentBox
          key={thread.comments[0].id}
          showResolveButton={thread.resolved === false}
          thread={thread}
          fullWidth={fullWidth}
          incomingComment={thread.comments[0]}
          threadId={thread.id}
          accesses={accesses}
          isLast={thread.comments.length === 1}
          editable={
            profileData && profileData.id === thread.comments[0].authorId
          }
        />
      )}
      {thread.comments && thread.comments.length > 2 && (
        <div>
          <div
            style={{
              display: moreCommentsDisplay === 'none' ? 'flex' : 'none',
              cursor: 'pointer',
              marginLeft: 30,
              alignItems: 'center',
              color: '#1a73e8',
              fontSize: fullWidth ? 14 : 11,
            }}
            onClick={() => setMoreCommentsDisplay('block')}
          >
            <KeyboardArrowDownIcon />
            <div>{thread.comments.length - 2 + ' ' + t('more-replies')}</div>
          </div>
          <div style={{ display: moreCommentsDisplay }}>
            {thread.comments
              .slice(1, thread.comments.length - 1)
              .map((comment, index) => (
                <CommentBox
                  key={comment.id}
                  showResolveButton={false}
                  thread={thread}
                  fullWidth={fullWidth}
                  incomingComment={comment}
                  threadId={thread.id}
                  accesses={accesses}
                  isLast={thread.comments.length === index + 1}
                  editable={profileData && profileData.id === comment.authorId}
                />
              ))}
          </div>
        </div>
      )}
      {thread.comments && thread.comments.length >= 2 && (
        <CommentBox
          key={thread.comments[thread.comments.length - 1].id}
          showResolveButton={false}
          thread={thread}
          fullWidth={fullWidth}
          incomingComment={thread.comments[thread.comments.length - 1]}
          threadId={thread.id}
          accesses={accesses}
          isLast={true}
          editable={
            profileData &&
            profileData.id ===
              thread.comments[thread.comments.length - 1].authorId
          }
        />
      )}
      <div
        style={{
          position: 'relative',
          padding: 10,
          display: 'flex',
          backgroundColor: '#f3f7fb',
          paddingBottom: 35,
        }}
      >
        {!profileData || (profileData && profileData.photoUrl) ? (
          <Avatar
            src={profileData ? profileData.photoUrl : ''}
            style={{
              marginRight: fullWidth ? 10 : 5,
              width: fullWidth ? 32 : 25,
              height: fullWidth ? 32 : 25,
              minWidth: fullWidth ? 32 : 25,
            }}
          />
        ) : (
          <Avatar
            className="comment-box-avatar-human"
            icon={<UserOutlined />}
            style={{
              marginRight: fullWidth ? 10 : 5,
              width: fullWidth ? 32 : 25,
              height: fullWidth ? 32 : 25,
              minWidth: fullWidth ? 32 : 25,
            }}
          />
        )}
        <TextareaAutosize
          disabled={isCreatingThread || isCreatingComment}
          onChange={(event) => handleChangeCommentContent(event.target.value)}
          value={emptyComment.content}
          className={classes.textField}
          style={fullWidth ? { fontSize: 15 } : { width: '100%' }}
          placeholder={
            (thread.id === emptyGuid() ? t('write-comment') : t('reply')) +
            '...'
          }
          minRows={2}
        />
        <div>
          <Tooltip arrow title={t<string>('cancel')}>
            {emptyComment.content !== '' ? (
              <CloseIcon
                className={classes.closeIcon}
                style={fullWidth ? { width: 23, height: 23 } : {}}
                onClick={() => {
                  if (isCreatingThread || isCreatingComment) return true;
                  else setEmptyComment({ ...emptyComment, content: '' });
                }}
              />
            ) : (
              <CloseIcon
                className={classes.closeIcon}
                style={
                  fullWidth
                    ? {
                        width: 23,
                        height: 23,
                        color: '#c9c6c6',
                        cursor: 'auto',
                      }
                    : { color: '#c9c6c6', cursor: 'auto' }
                }
              />
            )}
          </Tooltip>
          <Tooltip arrow title={t<string>('save')}>
            {isCreatingThread || isCreatingComment ? (
              <CircularProgress
                className={classes.doneIcon}
                style={{ width: 13, height: 13, right: 39, top: 12 }}
              />
            ) : emptyComment.content !== '' ? (
              <DoneIcon
                className={classes.doneIcon}
                style={fullWidth ? { width: 23, height: 23, right: 43 } : {}}
                onClick={handleSaveComment}
              />
            ) : (
              <DoneIcon
                className={classes.doneIcon}
                style={
                  fullWidth
                    ? {
                        width: 23,
                        height: 23,
                        color: '#c9c6c6',
                        cursor: 'auto',
                      }
                    : { color: '#c9c6c6', cursor: 'auto' }
                }
              />
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ThreadBox;
