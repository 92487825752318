import React, { FC, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import DocumentSettings from '../settings';
import { FolderContext } from '../../../contexts/folder';

type NewDocumentDialogPropsType = {
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

const NewDocumentDialog: FC<NewDocumentDialogPropsType> = ({
  setOpenDialog,
}) => {
  const { t } = useTranslation();

  const { selectedFolderId } = useContext(FolderContext);

  return (
    <Modal title={t('new-document')} open={true} footer={null}>
      <DocumentSettings
        onCancel={() => setOpenDialog(false)}
        settingsItem="create"
        selectedFolderId={selectedFolderId}
      />
    </Modal>
  );
};

export default NewDocumentDialog;
