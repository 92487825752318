import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import { MdOutlinePublish, MdPublish } from 'react-icons/md';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { DocumentContext } from '../../../../../contexts/document';
import { emptyGuid, sleep } from '../../../../../helpers/common';
import { getQueries } from '../../../../../helpers/route';
import TopicSelectorDialog from '../../../tree/selector/dialog';
import usePublishTopic from '../../../../../api-hooks/topic/usePublishTopic';
import { DraftTopic, DraftTopicTree } from '../../../../../api-schemas/topic';
import { TopicContext } from '../../../../../contexts/topic';
import useGetDraftTopicSilently from '../../../../../api-hooks/topic/useGetDraftTopicSilently';
import useSyncGoogleDocTopic from '../../../../../api-hooks/topic/useSyncGoogleDocTopic';
import { AccountContext } from '../../../../../contexts/account';
import { FolderContext } from '../../../../../contexts/folder';

type PublishButtonsPropsType = {
  isAlreadyPublished: boolean;
  isInSaveState: boolean;
  flatDraftTopicTree?: DraftTopicTree;
  selectedTopic?: DraftTopic;
};

const PublishButtons = ({
  isAlreadyPublished,
  isInSaveState,
  flatDraftTopicTree,
  selectedTopic,
}: PublishButtonsPropsType) => {
  const [openPublish, setOpenPublish] = useState<boolean>(false);
  const [isRefreshingGoogleDocState, setIsRefreshingGoogleDocState] =
    useState<boolean>(false);

  const selectedTopicRef = useRef(selectedTopic);

  const { enqueueSnackbar } = useSnackbar();

  const {
    selectedDoc,
    selectedVersion,
    selectedLanguage: documentSelectedLanguage,
  } = useContext(DocumentContext);
  const {
    changeTopic,
    isUserModifyingTopic,
    setIsPublishingTopic,
    setSelectedTopic,
  } = useContext(TopicContext);
  const { selectedLanguage, selectedAccount } = useContext(AccountContext);
  const { selectedFolderId } = useContext(FolderContext);

  const { publishTopic, isPublishingTopic } = usePublishTopic({
    documentVersionId: selectedTopic?.documentVersionId,
    languageCode: selectedTopic?.languageCode,
    topicId: selectedTopic?.topicId,
  });

  const { getTopic } = useGetDraftTopicSilently({
    topicId: selectedTopic?.topicId,
    languageCode: selectedTopic?.languageCode || selectedLanguage,
  });

  const { syncGoogleDocTopic } = useSyncGoogleDocTopic();

  const disabled = useMemo(
    () =>
      (isPublishingTopic ||
        isInSaveState ||
        isAlreadyPublished ||
        isUserModifyingTopic) &&
      selectedTopic?.contentType != 'GoogleDoc',
    [isPublishingTopic, isInSaveState, isAlreadyPublished, isUserModifyingTopic]
  );

  const history = useHistory();
  const { t } = useTranslation();
  const { documentId }: { documentId: string } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isInSaveState && selectedTopic) {
      invalidateDraftTopicData();
    }
  }, [isInSaveState]);

  useEffect(() => {
    setIsPublishingTopic(isPublishingTopic);
  }, [isPublishingTopic]);

  useEffect(() => {
    selectedTopicRef.current = selectedTopic;
  }, [selectedTopic]);

  const invalidateDraftTopicData = async () => {
    await queryClient.invalidateQueries({
      queryKey: [
        'draftTopic',
        'silent',
        selectedTopic!.topicId,
        selectedTopic!.languageCode,
      ],
    });
  };

  const selectTopicAfterPublish = useCallback(
    (topicsToPublish?: DraftTopic[]) => {
      if (selectedTopic) {
        if (topicsToPublish && documentId && flatDraftTopicTree) {
          const selectedTopicInPublishedTopics = topicsToPublish.find(
            (topic) => topic.topicId === selectedTopic.topicId
          );

          if (
            selectedTopicInPublishedTopics &&
            selectedTopicInPublishedTopics.action === 'Deleted'
          ) {
            if (flatDraftTopicTree.length > 2) {
              const newTopicId = flatDraftTopicTree.filter(
                (topic) =>
                  topic.topicId !== selectedTopicInPublishedTopics.topicId &&
                  topic.parentTopicId
              )[0].topicId;

              changeTopic(newTopicId);
            } else {
              history.push('/');
            }
          }
        } else if (!documentId && selectedTopic.action === 'Deleted') {
          queryClient.removeQueries({
            queryKey: selectedFolderId
              ? ['topics', selectedAccount.id, selectedFolderId]
              : ['topics', selectedAccount.id],
          });
          history.push('/');
        }
      }
    },
    [selectedTopic, flatDraftTopicTree]
  );

  const handlePublishGoogleDocTopic = useCallback(
    (topicsToPublish: DraftTopic[] = [], publishTitle: string = '') => {
      if (selectedTopicRef.current) {
        setIsRefreshingGoogleDocState(true);

        syncGoogleDocTopic({
          accountId: selectedTopicRef.current.accountId,
          googleDriveIds: [selectedTopicRef.current.googleDriveId],
        })
          .then(() => {
            getTopic().then((topic) => {
              setIsRefreshingGoogleDocState(false);

              const modifiedTopic = {
                ...selectedTopicRef.current!,
                latestTopicVersionId:
                  topic.data?.latestTopicVersionId ||
                  selectedTopicRef.current!.latestTopicVersionId,
              };
              handlePublishTopic(
                [...topicsToPublish, modifiedTopic],
                publishTitle
              );
            });
          })
          .catch(() => {
            setIsRefreshingGoogleDocState(false);
            enqueueSnackbar(t(`error.syncingGoogleDocTopics`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
      }
    },
    [selectedTopic]
  );

  const handlePublishTopic = useCallback(
    async (topicsToPublish?: DraftTopic[], publishTitle: string = '') => {
      if (selectedTopicRef.current) {
        if (!topicsToPublish) {
          await invalidateDraftTopicData();
        }

        await sleep();

        await publishTopic({
          title: publishTitle,
          topicVersionIds: topicsToPublish
            ? topicsToPublish.map((topic) => topic.latestTopicVersionId)
            : [selectedTopicRef.current.latestTopicVersionId],
          languageCode: selectedTopicRef.current.languageCode,
        })
          .then((publishedTopics) => {
            selectTopicAfterPublish(topicsToPublish);
            invalidateDraftTopicData();

            const publishedToipc = publishedTopics.filter(
              (topic) => topic.topicId === selectedTopicRef.current?.topicId
            );

            if (publishedToipc.length > 0)
              setSelectedTopic((prev) => {
                if (!prev) return prev;
              
                return {
                  ...prev,
                  topicVersionNumber:
                    publishedToipc[0].number ?? prev.topicVersionNumber,
                };
              });
          })
          .catch(() => {
            enqueueSnackbar(t(`error.publish`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
      }
    },
    [selectedTopicRef]
  );

  const handlePublish = async (
    topicsToPublish?: DraftTopic[],
    publishTitle: string = ''
  ) => {
    if (selectedTopic?.contentType === 'GoogleDoc') {
      handlePublishGoogleDocTopic(topicsToPublish, publishTitle);
    } else {
      handlePublishTopic(topicsToPublish, publishTitle);
    }
  };

  const handlePublishRequest = useCallback(
    (accountSlugOrId: string, documentSlugOrId: string) => {
      if (selectedTopic?.contentType === 'GoogleDoc') {
        setIsRefreshingGoogleDocState(true);

        syncGoogleDocTopic({
          accountId: selectedTopic?.accountId || selectedDoc.account?.id,
          googleDriveIds: [selectedTopic?.googleDriveId],
        })
          .then(() => {
            setIsRefreshingGoogleDocState(false);

            history.push(
              `/document/${accountSlugOrId}/${documentSlugOrId}/publish-request/new${getQueries(selectedVersion, documentSelectedLanguage)}`,
              { topicId: selectedTopic?.topicId }
            );
          })
          .catch(() => {
            setIsRefreshingGoogleDocState(false);
            enqueueSnackbar(t(`error.syncingGoogleDocTopics`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
      } else {
        history.push(
          `/document/${selectedDoc.account.slug}/${selectedDoc.slug}/publish-request/new${getQueries(selectedVersion, documentSelectedLanguage)}`,
          { topicId: selectedTopic?.topicId }
        );
      }
    },
    [
      selectedDoc,
      selectedTopic,
      syncGoogleDocTopic,
      selectedVersion,
      documentSelectedLanguage,
      enqueueSnackbar,
      history,
      t,
    ]
  );

  const renderPublishButtons = useCallback(() => {
    const items = [
      {
        label: (
          <>
            <div style={{ margin: '5px 10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdOutlinePublish
                  style={{
                    width: '17.5px',
                    height: '18.5px',
                    color: '#1a73e8',
                  }}
                />
                <span
                  style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
                >
                  Publish
                </span>
              </div>
            </div>
          </>
        ),
        key: '1',
        className: 'publish-menu-item',
        disabled: disabled,
        onClick: () => {
          handlePublish();
        },
      },
      {
        label: (
          <>
            <div style={{ margin: '5px 10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/PublishRequest.svg'}
                  alt="Publish Topics"
                  style={{
                    width: '12.5px',
                    height: '13.5px',
                    color: '#1a73e8',
                    marginLeft: '3px',
                  }}
                />
                <span
                  style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
                >
                  Publish Request
                </span>
              </div>
            </div>
          </>
        ),
        key: '2',
        className: 'publish-menu-item',
        disabled: disabled,
        onClick: () => {
          handlePublishRequest(selectedDoc.account.id, selectedDoc.id);
        },
      },
      {
        label: (
          <>
            <div style={{ margin: '5px 10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdPublish
                  style={{
                    width: '15.5px',
                    height: '16.5px',
                    color: '#1a73e8',
                  }}
                />
                <span
                  style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
                >
                  Multiple Publish
                </span>
              </div>
            </div>
          </>
        ),
        key: '3',
        className: 'publish-menu-item',
        disabled: disabled,
        onClick: () => setOpenPublish(true),
      },
    ];

    return { items };
  }, [
    selectedDoc,
    selectedLanguage,
    selectedVersion,
    selectedTopic,
    documentSelectedLanguage,
    disabled,
  ]);

  const doesHaveProcess =
    selectedDoc &&
    !(
      selectedDoc.processId === emptyGuid() ||
      (selectedDoc.processId === null && selectedDoc.account.processId === null)
    );

  if (!selectedDoc && !selectedTopic) {
    return null;
  }

  if (!selectedDoc && selectedTopic) {
    return (
      <Button
        onClick={() => handlePublish()}
        disabled={disabled}
        style={{
          marginRight: 5,
          display: 'flex',
          border: '1px solid rgba(26, 115, 232, 0.5)',
          height: '35px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={
          'publish-buttons ' +
          (isAlreadyPublished && selectedTopic?.contentType != 'GoogleDoc'
            ? 'disable-publish'
            : '')
        }
      >
        {isPublishingTopic || isRefreshingGoogleDocState ? (
          <CircularProgress size={14} />
        ) : (
          <MdOutlinePublish
            style={{ width: '17.5px', height: '18.5px', color: '#1a73e8' }}
          />
        )}
        <span
          id="publish-topic-span"
          className="responsive-publish-text"
          style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
        >
          Publish
        </span>
      </Button>
    );
  }

  if (!doesHaveProcess) {
    return (
      <Fragment>
        <Dropdown.Button
          onClick={() => handlePublish()}
          disabled={disabled}
          style={{
            marginRight: 5,
            display: 'flex',
            border: '1px solid rgba(26, 115, 232, 0.5)',
            height: '35px',
          }}
          menu={renderPublishButtons()}
          placement="bottom"
          className={
            'publish-buttons ' +
            (isAlreadyPublished && selectedTopic?.contentType != 'GoogleDoc'
              ? 'disable-publish'
              : '')
          }
          icon={<DownOutlined style={{ color: 'rgb(26, 115, 232)' }} />}
        >
          {isPublishingTopic || isRefreshingGoogleDocState ? (
            <CircularProgress size={14} />
          ) : (
            <MdOutlinePublish
              style={{ width: '17.5px', height: '18.5px', color: '#1a73e8' }}
            />
          )}
          <span
            id="publish-topic-span"
            className="responsive-publish-text"
            style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
          >
            Publish
          </span>
        </Dropdown.Button>

        {openPublish && (
          <TopicSelectorDialog
            documentId={documentId}
            title={t<string>('publish-topics-modal-title')}
            topics={flatDraftTopicTree}
            openPublish={openPublish}
            onClose={() => setOpenPublish(false)}
            onPublishTopics={handlePublish}
            defaultSelectedTopics={isAlreadyPublished ? [] : [selectedTopic]}
            okTitle={'Publish'}
          />
        )}
      </Fragment>
    );
  } else {
    return (
      <Button
        onClick={() =>
          handlePublishRequest(selectedDoc.account.slug, selectedDoc.slug)
        }
        disabled={disabled}
        className="publish-buttons"
        style={{
          marginRight: 5,
          display: 'flex',
          height: 35,
          border: '1px solid rgba(26, 115, 232, 0.5)',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={process.env.PUBLIC_URL + '/PublishRequest.svg'}
          alt="Publish Topics"
          style={{ width: '12.5px', height: '13.5px', color: '#1a73e8' }}
        />
        <span
          className="responsive-publish-text"
          style={{ marginLeft: 10, color: '#1a73e8', fontSize: 12 }}
        >
          Publish Request
        </span>
      </Button>
    );
  }
};

export default PublishButtons;
