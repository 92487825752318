import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AddUserSubscriptionRequestType } from '../../api-schemas/user';

const removeUserSubscriptionRequest = (subscriptionId: string) => {
  return axios
    .delete(`/api/User/Subscription/${subscriptionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useRemoveUserSubscription({
  entityType,
  entityId,
}: AddUserSubscriptionRequestType) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: removeUserSubscription,
    isPending: isRemovingUserSubscription,
    isError: isErrorRemovingUserSubscription,
    isSuccess: isSuccessRemovingUserSubscription,
  } = useMutation({
    mutationFn: (subscriptionId: string) =>
      removeUserSubscriptionRequest(subscriptionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'subscriptions'] });
      queryClient.removeQueries({
        queryKey: ['watchers', entityId, entityType],
      });
    },
  });

  return {
    removeUserSubscription,
    isRemovingUserSubscription,
    isErrorRemovingUserSubscription,
    isSuccessRemovingUserSubscription,
  };
}
