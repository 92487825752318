import React from 'react';
import { Dropdown, Button } from 'antd';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const UncompletedTasks = ({ messages }) => {
  const { t } = useTranslation();
  const items = messages.map((message, index) => ({
    key: index,
    label: (
      <div style={{ display: 'flex' }}>
        {message.type === 'warning' ? (
          <ErrorIcon style={{ marginRight: 5, color: '#ffc107' }} />
        ) : (
          <CancelIcon style={{ marginRight: 5, color: 'red' }} />
        )}
        {message.text}
      </div>
    ),
    style:
      message.type === 'warning'
        ? {
            color: '#ffc107',
            borderBottom:
              index !== messages.length - 1
                ? '1px solid rgba(0, 0, 0, 0.12)'
                : 'none',
            padding: '10px 8px',
          }
        : {
            color: 'red',
            borderBottom:
              index !== messages.length - 1
                ? '1px solid rgba(0, 0, 0, 0.12)'
                : 'none',
            padding: '10px 8px',
          },
  }));

  const menu = { items };

  return (
    <Dropdown arrow menu={menu} placement="bottom">
      <Button style={{ marginRight: 5, display: 'flex' }}>
        <PlaylistAddCheckIcon style={{ marginRight: 5 }} />
        {messages.length + ' ' + t('uncompleted-tasks')}
      </Button>
    </Dropdown>
  );
};

export default UncompletedTasks;
