import React, { useState, Fragment } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { getLanguageCode } from '../../../helpers/route';

export function DeleteTopicConfirmation(props) {
  const [deleteAll, setDeleteAll] = useState<boolean>(false);
  function onClose() {
    if (props.onDialogClosed) {
      props.onDialogClosed();
    }
  }

  function onConfirm() {
    if (props.onDialogConfirmed) {
      props.onDialogConfirmed(deleteAll);
    }
  }

  const hasContentInThisLanguage =
    props.languages &&
    !(props.languages.length === 1 && props.languages[0] !== getLanguageCode());

  return (
    <Dialog
      open={props.isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Fragment>
          <DialogContentText>{props.message}</DialogContentText>

          <DialogContentText>
            {props.languages &&
              (props.languages.length > 1 || !hasContentInThisLanguage) &&
              !props.loadingLanguages && (
                <FormControlLabel
                  onChange={() => {
                    setDeleteAll(!deleteAll);
                  }}
                  onClick={() => setDeleteAll(!deleteAll)}
                  control={<Checkbox checked={deleteAll} value={deleteAll} />}
                  label={
                    'Delete topic in all languages ' +
                    '(' +
                    props.languages.join() +
                    ')'
                  }
                />
              )}
            {props.loadingLanguages && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress
                  style={{
                    width: '20px',
                    height: '20px',
                    color: 'primary',
                  }}
                />
              </div>
            )}
          </DialogContentText>
        </Fragment>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          No
        </Button>
        <Button
          variant="contained"
          disabled={
            props.loadingLanguages || (!hasContentInThisLanguage && !deleteAll)
          }
          onClick={onConfirm}
          color="primary"
        >
          {props.loading ? (
            <CircularProgress
              style={{ width: '20px', height: '20px', color: 'white' }}
            />
          ) : (
            'Yes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
