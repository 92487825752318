import React, { useMemo, useState, Key, Fragment, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tree, Input, TreeDataNode, TreeProps } from 'antd';
import Skeleton from '@mui/material/Skeleton';

import TreeItemTitle from './tree-item-title';
import { findPreviousTopic, findNextTopic, findNextTopicOnDropAfter } from '../../../helpers/common';
import useMoveTopic from '../../../api-hooks/topic/useMoveTopic';
import { DraftTopicTree, DraftTopicTreeItem } from '../../../api-schemas/topic';
import { Document } from '../../../api-schemas/document';
import { TopicContext } from '../../../contexts/topic';
import { DocumentContext } from '../../../contexts/document';
import { getLanguageCode } from '../../../helpers/route';
import AddTopicButtons from '../add-button';

const { Search } = Input;

type TopicTreePropsType = {
  document: Document;
  draftTopicTree: DraftTopicTree;
  flatDraftTopicTree: DraftTopicTree;
  isLoadingDraftTopicTree: boolean;
  isRefetchingDraftTopicTree: boolean;
  setIsSideSectionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const TopicTree = ({
  document,
  draftTopicTree,
  flatDraftTopicTree,
  isLoadingDraftTopicTree,
  isRefetchingDraftTopicTree,
  setIsSideSectionExpanded,
}: TopicTreePropsType) => {
  const [expandedTopicIds, setExpandedTopicIds] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);

  const { selectedTopic, isLoadingSelectedTopic, changeTopic } = useContext(TopicContext);
  const { selectedVersion } = useContext(DocumentContext);

  const selectedKeys = useMemo(() => (selectedTopic ? [selectedTopic.topicId] : []), [selectedTopic]);

  const { t } = useTranslation();

  const { moveTopic, isMovingTopic } = useMoveTopic({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
  });

  useEffect(() => {
    const isSelectedTopicExists = flatDraftTopicTree.find((topic) => topic.topicId === selectedTopic?.topicId);

    if (
      draftTopicTree &&
      !isSelectedTopicExists &&
      !isLoadingSelectedTopic &&
      !isLoadingDraftTopicTree &&
      selectedVersion
    ) {
      const firstTopic = draftTopicTree[0]?.children[0];

      if (firstTopic) {
        changeTopic(firstTopic.topicId);
      }
    }
  }, [draftTopicTree]);

  useEffect(() => {
    if (selectedTopic) handleExpandParentById(selectedTopic.topicId);
  }, [selectedTopic, draftTopicTree]);

  const createTopicTreeItem = useCallback(
    (topicTreeItem: DraftTopicTreeItem) => {
      return {
        title: (
          <TreeItemTitle
            draftTopicTree={draftTopicTree}
            flatDraftTopicTree={flatDraftTopicTree}
            draftTopicTreeItem={topicTreeItem}
            document={document}
            documentVersionId={selectedVersion?.id}
            searchValue={searchValue}
          />
        ),
        key: topicTreeItem.topicId,
        children: topicTreeItem.children
          .sort((a, b) => a.topicOrder - b.topicOrder)
          .map((childTopic) => createTopicTreeItem(childTopic)),
      };
    },
    [draftTopicTree, flatDraftTopicTree, document, selectedVersion, searchValue]
  );

  const treeData = useMemo<TreeDataNode[]>(() => {
    return draftTopicTree[0]?.children.sort((a, b) => a.topicOrder - b.topicOrder).map(createTopicTreeItem);
  }, [draftTopicTree, searchValue]);

  const handleExpand = useCallback((expandedKeys: Key[]) => {
    setAutoExpandParent(false);
    setExpandedTopicIds(expandedKeys);
  }, []);

  const handleDragEnter: TreeProps['onDragEnter'] = useCallback(
    ({ node: { key } }) => {
      const newExpandedTopicIds = [...expandedTopicIds];

      if (newExpandedTopicIds.indexOf(key) === -1) newExpandedTopicIds.push(key);

      setExpandedTopicIds(newExpandedTopicIds);
    },
    [expandedTopicIds]
  );

  const handleDragStart: TreeProps['onDragStart'] = useCallback(
    ({ node: { key } }) => {
      const newExpandedTopicIds = [...expandedTopicIds];
      const index = newExpandedTopicIds.indexOf(key);

      if (index !== -1) {
        newExpandedTopicIds.splice(index, 1);
      }

      setExpandedTopicIds(newExpandedTopicIds);
    },
    [expandedTopicIds]
  );

  const handleMoveTopic: TreeProps['onDrop'] = useCallback(
    async (moveInfo) => {
      const dropPosition = moveInfo.dropPosition - Number(moveInfo.node.pos.split('-').at(-1));

      const isDroppedBefore = dropPosition === -1 || (dropPosition === 0 && !moveInfo.dropToGap);

      const dropKey = moveInfo.node.key;
      const dragKey = moveInfo.dragNode.key;

      const dropNode = flatDraftTopicTree.find((topic) => dropKey === topic.topicId);
      const dragNode = flatDraftTopicTree.find((topic) => dragKey === topic.topicId);

      if (dropNode && dragNode) {
        dropNode.children = flatDraftTopicTree.filter((topic) => dropNode.topicId === topic.parentTopicId);
        dragNode.children = flatDraftTopicTree.filter((topic) => dragNode.topicId === topic.parentTopicId);

        const moveData = {
          topicId: dragNode.topicId,
          targetParentTopicId: moveInfo.dropToGap ? dropNode.parentTopicId : dropNode.topicId,
          minOrder: moveInfo.dropToGap
            ? isDroppedBefore
              ? findPreviousTopic(dropNode, draftTopicTree)?.topicOrder
              : dropNode.topicOrder
            : null,
          maxOrder: moveInfo.dropToGap
            ? isDroppedBefore
              ? dropNode.topicOrder
              : findNextTopicOnDropAfter(dropNode, draftTopicTree)?.topicOrder
            : findNextTopic(dragNode, draftTopicTree)?.topicOrder,
        };

        moveTopic(moveData);
      }
    },
    [flatDraftTopicTree, draftTopicTree]
  );

  const getParentKey = useCallback((key: React.Key, tree: TreeDataNode[]): React.Key => {
    let parentKey: React.Key;

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }

    return parentKey!;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newExpandedTopicIds = flatDraftTopicTree
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.topicId, treeData);
          }

          return null;
        })
        .filter((item): item is React.Key => Boolean(item));

      setExpandedTopicIds(newExpandedTopicIds);
      setSearchValue(value);
      setAutoExpandParent(true);
    },
    [flatDraftTopicTree]
  );

  const handleExpandParentById = useCallback(
    (selectedChildTopicId: string) => {
      const newExpandedTopicIds = flatDraftTopicTree
        .map((item) => {
          if (item.topicId === selectedChildTopicId) {
            return getParentKey(item.topicId, treeData);
          }

          return null;
        })
        .filter((item): item is React.Key => Boolean(item));

      setExpandedTopicIds(newExpandedTopicIds);
      setAutoExpandParent(true);
    },
    [flatDraftTopicTree, treeData]
  );

  const isSmallScreen = window.matchMedia('(max-width: 576px)').matches;

  if (isLoadingDraftTopicTree && !isRefetchingDraftTopicTree) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 10,
          paddingLeft: 10,
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" />
      </div>
    );
  }

  return (
    <Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <AddTopicButtons loading={isRefetchingDraftTopicTree} setIsSideSectionExpanded={setIsSideSectionExpanded} />
      </div>

      <Search style={{ padding: 16, background: 'white' }} placeholder={t('search')} onChange={handleSearch} />

      <Tree
        style={{
          ...(isSmallScreen ? {} : { height: 'calc(100vh - 200px)' }),
          overflowY: 'auto',
        }}
        draggable
        blockNode
        multiple={false}
        disabled={isMovingTopic || isRefetchingDraftTopicTree}
        treeData={treeData}
        selectedKeys={selectedKeys}
        onExpand={handleExpand}
        expandedKeys={expandedTopicIds}
        onDragEnter={handleDragEnter}
        onDragStart={handleDragStart}
        onDrop={handleMoveTopic}
        autoExpandParent={autoExpandParent}
      />
    </Fragment>
  );
};

export default TopicTree;
