import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Tooltip } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import config from 'react-global-configuration';

import { TopicContext } from '../../../contexts/topic';
import { DraftTopic } from '../../../api-schemas/topic';

type MetricsStatusesPropsType = {
  seoScore: number;
  readabilityScore: number;
  seoOnClick?: () => void;
  readabilityOnClick?: () => void;
  numberOfBrokenLinks: number;
  brokenLinksOnClick?: () => void;
  selectedTopic?: DraftTopic;
  isDocumentMetric?: boolean;
};

const MetricsStatuses = ({
  seoScore,
  readabilityScore,
  seoOnClick,
  readabilityOnClick,
  numberOfBrokenLinks,
  brokenLinksOnClick,
  selectedTopic = undefined,
  isDocumentMetric = false,
}: MetricsStatusesPropsType) => {
  const { selectedTopic: globalSelectedTopic } = useContext(TopicContext);

  const { t } = useTranslation();

  const topic = isDocumentMetric ? null : selectedTopic ?? globalSelectedTopic;

  const handleSEOOnclick = () => {
    if (seoOnClick) seoOnClick();
  };

  const handleReadabilityOnclick = () => {
    if (readabilityOnClick) readabilityOnClick();
  };

  const handleBrokenLinksOnClick = () => {
    if (brokenLinksOnClick) brokenLinksOnClick();
  };

  const renderMetricsStatus = () => {
    const metricStatusesDomContent: JSX.Element[] = [];

    if (config.get('environment') === 'stage' && numberOfBrokenLinks > 0) {
      metricStatusesDomContent.push(
        <Tooltip
          key={0}
          title={`${t<string>('broken-links-write-analytics')}: ${numberOfBrokenLinks}`}
          arrow
          placement="top"
        >
          <LinkOffIcon
            onClick={handleBrokenLinksOnClick}
            style={{
              color: '#dd1111',
              width: 15,
              marginRight: 10,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      );
    }

    metricStatusesDomContent.push(
      <Tooltip
        key={1}
        title={`${t<string>('seo')}: ${seoScore}%`}
        arrow
        placement="top"
      >
        <FiberManualRecordIcon
          onClick={handleSEOOnclick}
          style={{
            color:
              topic && topic.metrics && topic.metrics.keyphraseLength === 0
                ? '#5f6368'
                : seoScore > 60
                  ? '#28a745'
                  : seoScore > 30
                    ? '#ffc107'
                    : seoScore > 0
                      ? '#dc3545'
                      : '#5f6368',
            width: 15,
            marginRight: 10,
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    );

    metricStatusesDomContent.push(
      <Tooltip
        key={2}
        title={`${t<string>('readability')}: ${Math.round(readabilityScore)}%`}
        arrow
        placement="top"
      >
        <FiberManualRecordIcon
          onClick={handleReadabilityOnclick}
          style={{
            color:
              readabilityScore > 60
                ? '#28a745'
                : readabilityScore > 30
                  ? '#ffc107'
                  : readabilityScore > 0
                    ? '#dc3545'
                    : '#5f6368',
            width: 15,
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    );

    return metricStatusesDomContent;
  };

  return <Fragment>{renderMetricsStatus()}</Fragment>;
};

export default MetricsStatuses;
