import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Table, Tooltip } from 'antd';
import type { TableColumnsType } from 'antd';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import PublishIcon from '@mui/icons-material/Publish';
import {
  MdLockOpen,
  MdLockOutline,
  MdOpenInNew,
  MdPreview,
} from 'react-icons/md';
import { IoDocumentOutline } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';

import { Document } from '../../../api-schemas/document';
import MetricsStatuses from '../../../components/metrics-analytics/metrics-statuses';
import {
  calculateReadabilityScore,
  calculateSEOScore,
  getDocumentMetrics,
  getTopicLink,
} from '../../../helpers/common';
import WatchSettings from '../../../components/watch/watch-settings';
import DocumentLogo from '../../../components/document/logo';
import useGetDocuments from '../../../api-hooks/document/useGetDocuments';
import { AccountContext } from '../../../contexts/account';
import DocumentListMenu from '../../../components/document/list/menu';
import { DocumentContext } from '../../../contexts/document';
import { FolderContext } from '../../../contexts/folder';
import useGetItemType from '../../../api-hooks/folder/useGetItemType';
import { DocumentService } from '../../../services/document';
import { topicService } from '../../../services/topic';

let shouldRedirectToDocumentPage: boolean = true;

const DocumentList: FC = () => {
  const [refreshWatchersList, setRefreshWatchersList] = useState<boolean>(true);

  const { selectedFolderId } = useContext(FolderContext);
  const { selectedAccount, selectedLanguage } = useContext(AccountContext);
  const { setSelectedVersion, setSelectedDoc } = useContext(DocumentContext);

  const documentService = new DocumentService();
  const topicAPI = new topicService();

  const { getItemType } = useGetItemType('');
  const { documents, isLoadingDocuments } = useGetDocuments(
    selectedAccount?.id || '',
    selectedFolderId,
    false,
    Boolean(selectedAccount?.id)
  );

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setSelectedVersion(null);
    setSelectedDoc(null);
  }, []);

  const sortedDocuments = useMemo(() => {
    const documentsToShow = documents.sort(
      (a, b) =>
        new Date(b.createDateTime).getTime() -
        new Date(a.createDateTime).getTime()
    );

    if (
      documentsToShow.length === 1 &&
      documentsToShow[0].slug === 'demo-content' &&
      shouldRedirectToDocumentPage
    ) {
      shouldRedirectToDocumentPage = false;

      history.push(
        `/document/${documentsToShow[0].accountId}/${documentsToShow[0].id}/content?lang=${selectedLanguage}`
      );
    }

    return documentsToShow;
  }, [documents]);

  const columns: TableColumnsType<Document> = useMemo(
    () => [
      {
        title: 'Logo',
        key: 'logo',
        width: '5%',
        align: 'center',
        render: (_, record) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <DocumentLogo document={record} />
            </div>
          );
        },
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        width: '25%',
        align: 'center',
        render: (_, record) => {
          const hasContentInCurrentLanguage = Boolean(
            record.languages.find(
              (language) => language.languageCode === selectedLanguage
            )
          );
          const hasPublishedTopic =
            record.languages.find(
              (language) => language.languageCode === record.defaultLanguageCode
            )?.hasPublishedTopic === true;

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Link
                to={`/document/${record.accountId}/${record.id}/content?lang=${selectedLanguage}&ver=${record.defaultDocumentVersionSlug}`}
                style={{
                  marginRight: 5,
                  color: hasContentInCurrentLanguage ? '#000' : 'gray',
                }}
              >
                {' '}
                {record.title}{' '}
              </Link>

              <a
                style={{
                  color: 'grey',
                  marginLeft: '5px',
                }}
                onClick={() =>
                  handleGetDocUrl(record, record.defaultLanguageCode, false)
                }
                rel="noopener noreferrer"
              >
                <Tooltip placement="top" title={t('preview-tooltip')}>
                  <MdPreview
                    style={{
                      width: 15,
                      height: 15,
                      marginTop: '-2px',
                    }}
                    id="openInNewIcon"
                  />
                </Tooltip>
              </a>

              {hasPublishedTopic ? (
                <a
                  style={{
                    color: 'grey',
                    marginLeft: '5px',
                  }}
                  rel="noopener noreferrer"
                  onClick={() =>
                    handleGetDocUrl(record, record.defaultLanguageCode, true)
                  }
                >
                  <Tooltip placement="top" title={t('view-live-tooltip')}>
                    <MdOpenInNew
                      style={{
                        width: 15,
                        height: 15,
                        marginTop: '-2px',
                      }}
                      id="openInNewIcon"
                    />
                  </Tooltip>
                </a>
              ) : null}
            </div>
          );
        },
      },
      {
        title: 'Type',
        key: 'type',
        width: '5%',
        align: 'center',
        render: () => {
          return (
            <Tooltip title={t('document')}>
              <IoDocumentOutline
                style={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Metrics',
        key: 'metrics',
        width: '10%',
        align: 'center',
        render: (_, record) => {
          const metrics = getDocumentMetrics(
            record,
            record.defaultDocumentVersionSlug,
            record.defaultLanguageCode
          );

          if (metrics) {
            return (
              <MetricsStatuses
                seoScore={calculateSEOScore(metrics)}
                readabilityScore={calculateReadabilityScore(metrics)}
                numberOfBrokenLinks={0}
              />
            );
          }

          return <span>-</span>;
        },
      },
      {
        title: 'Languages',
        key: 'languages',
        width: '5%',
        align: 'center',
        render: (_, record) => {
          const languages = record.languages.map(
            (language) => language.languageCode
          );

          return (
            <Tooltip
              title={'Languages: ' + languages.join(', ')}
              placement="top"
            >
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <GTranslateIcon
                  style={{ marginRight: 5, width: 18, height: 18 }}
                />

                <span>{languages.length}</span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Publish Requests',
        key: 'publishRequests',
        width: '10%',
        align: 'center',
        render: (_, record) => {
          return (
            <Tooltip title={'Number of open publish requests'} placement="top">
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PublishIcon
                  style={{ marginRight: 5, width: 18, height: 18 }}
                />

                <span>{record.openPublishRequests}</span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Create date time',
        key: 'createDateTime',
        width: '15%',
        align: 'center',
        render: (_, record) => {
          return (
            <Tooltip
              title={new Date(record.createDateTime).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            >
              <span>
                {'Created ' +
                  moment(
                    new Date(record.createDateTime),
                    'YYYYMMDDLL'
                  ).fromNow()}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Privacy',
        key: 'privacy',
        width: '5%',
        align: 'center',
        render: (_, record) => {
          return record.isPrivate ||
            (record.isPrivate === null && record.account.isPrivate) ? (
            <Tooltip title={t('private')}>
              <MdLockOutline style={{ fontSize: 18 }} />
            </Tooltip>
          ) : (
            <Tooltip title={t('public')}>
              <MdLockOpen style={{ fontSize: 18 }} />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'actions',
        width: '15%',
        align: 'center',
        render: (_, record) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <WatchSettings
                entityId={record.id}
                entityType={'Documents'}
                hasMargin={false}
                refreshWatchersList={refreshWatchersList}
                setRefreshWatchersList={setRefreshWatchersList}
              />

              <DocumentListMenu doc={record} />
            </div>
          );
        },
      },
    ],
    [selectedLanguage]
  );

  const handleGetDocUrl = (doc, defaultLanguageCode, getPublishedUrl) => {
    documentService.getVersions(doc.id).then((versions) => {
      const newTab = window.open('', '_blank');

      topicAPI.getTopics(versions[0].id, defaultLanguageCode).then((topics) => {
        const firstTopicId = getPublishedUrl
          ? topics.filter((topic) => !!topic.publishedTopicVersionId)[0].id
          : topics[1].id;

        getItemType(firstTopicId).then((itemType) => {
          const publishLink = getTopicLink(
            doc,
            null,
            { id: 0, code: defaultLanguageCode },
            getPublishedUrl,
            false,
            selectedAccount,
            getPublishedUrl ? itemType?.path : itemType?.draftId
          );

          if (newTab) {
            newTab.location.href = publishLink;
          }
        });
      });
    });
  };

  return (
    <div style={{ width: '80%', marginBottom: 30 }}>
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #bebebe8a',
          marginTop: 10,
          marginBottom: 15,
          paddingBottom: 5,
          fontSize: 16,
        }}
      >
        {t('documents')}
      </div>

      <Table
        columns={columns}
        dataSource={sortedDocuments}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        style={{ width: '100%' }}
        loading={!selectedAccount || isLoadingDocuments}
      />
    </div>
  );
};

export default DocumentList;
