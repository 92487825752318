import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { UserSubscriptions } from '../../api-schemas/user';

const getSubscriptionsRequest = () => {
  return axios
    .get<UserSubscriptions>(`/api/User/Subscription`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetUserSubscriptions() {
  const {
    data,
    refetch: getSubscriptions,
    isFetching: isLoadingSubscriptions,
    isError: isErrorLoadingSubscriptions,
    isSuccess: isSuccessLoadingSubscriptions,
  } = useQuery<UserSubscriptions>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['user', 'subscriptions'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: getSubscriptionsRequest,
  });

  const subscriptions = useMemo(() => data || [], [data]);

  return {
    subscriptions,
    isLoadingSubscriptions,
    isErrorLoadingSubscriptions,
    isSuccessLoadingSubscriptions,

    getSubscriptions,
  };
}
