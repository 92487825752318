import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAccessRequestType } from '../../api-schemas/access';

const updateAccessRequest = (data: CreateAccessRequestType) => {
    const body = JSON.stringify(data);

    return axios.put('/api/Access', body, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useUpdateAccess(entityId: string) {
    const queryClient = useQueryClient()

    const {
        mutateAsync: updateAccess,
        isPending: isUpdatingAccess,
        isSuccess: isSuccessUpdatingAccess
    } = useMutation({
        mutationFn: updateAccessRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['accesses', entityId] });
        }
    });

    return {
        updateAccess,
        isUpdatingAccess,
        isSuccessUpdatingAccess
    }
}