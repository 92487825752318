import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Row, Dropdown, Button, Card, Typography, Col } from 'antd';
import type { MenuProps } from 'antd';
import { BsFileRichtext } from 'react-icons/bs';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import CreateIcon from '@mui/icons-material/Create';
import ISO6391 from 'iso-639-1';

import { DownOutlined, GoogleOutlined } from '@ant-design/icons';
import { DocumentContext } from '../../../../contexts/document';
import { AccountContext } from '../../../../contexts/account';
import useGetCurrentPlan from '../../../../api-hooks/plan/useGetCurrentPlan';
import { getLanguageCode } from '../../../../helpers/route';
import useGetGoogleDriveAccountToken from '../../../../api-hooks/account/useGetGoogleDriveAccountToken';
import { AccountRoles } from '../../../../helpers/roles';
import { createNewTopicContent } from '../../../../helpers/common';
import { handleGoogleDriveSignIn } from '../../../../helpers/google-drive-auth';
import useSaveTopic from '../../../../api-hooks/topic/useSaveTopic';
import PlanLimitationDialog from '../../../plan-limitation-dialog';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import { DraftTopic } from '../../../../api-schemas/topic';
import { TopicContext } from '../../../../contexts/topic';
import {
  MenuItem,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Button as MButton } from '@mui/material';
import useGetDraftTopicSilently from '../../../../api-hooks/topic/useGetDraftTopicSilently';
import useTranslateTopic from '../../../../api-hooks/topic/useTranslateTopic';
import { TranslateContext } from '../../../../contexts/translate';

const { Title } = Typography;

const styles: any = () => ({
  btnAddTopic: {
    minWidth: '40px',
  },
  btnMoreAddTopic: {
    minWidth: '40px',
    borderLeft: 'none',
  },
  menuItem: { fontSize: '0.9rem' },
  actionIcon: {
    fontSize: 'large',
    marginRight: '7px!important',
  },
});

const TranslationSelection = (selectedTopic: DraftTopic) => {
  const [openPlanLimitationDialog, setOpenPlanLimitationDialog] =
    useState<boolean>(false);
  const [restrictedUserDialog, setRestrictedUserDialog] =
    useState<boolean>(false);
  const [openAdminReqTokenDialog, setOpenAdminReqTokenDialog] =
    useState<boolean>(false);
  const [availableTranslateFromLanguages, setAvailableTranslateFromLanguages] =
    useState<any[]>([]);
  const [openTranslationModal, setOpenTranslationModal] =
    useState<boolean>(false);
  const [sourceTranslationLanguage, setSourceTranslationLanguage] =
    useState<string>();

  const { supportedTranslateLanguages } = useContext(TranslateContext);
  const { selectedVersion, selectedDoc } = useContext(DocumentContext);
  const { selectedAccount, userRole } = useContext(AccountContext);
  const { setSelectedTopic, setEditedTopic } = useContext(TopicContext);

  const { accountId }: { accountId: string } = useParams();

  const { t } = useTranslation();

  const { currentPlan } = useGetCurrentPlan(accountId);

  const { saveTopic, isSavingTopic } = useSaveTopic({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
    onErrorMessageCallback: (message) => {
      if (message.includes('MaximumNumberOfTopicsExceeded'))
        setOpenPlanLimitationDialog(true);
    },
  });
  const { translateTopic, isTranslatingTopic } = useTranslateTopic({
    topicId: selectedTopic?.topicId,
    sourceLanguageCode: sourceTranslationLanguage,
    targetLanguageCode: getLanguageCode(),
  });
  const { getTopic } = useGetDraftTopicSilently({
    topicId: selectedTopic?.topicId,
    languageCode: getLanguageCode(),
  });

  const { googleDriveAccountToken } = useGetGoogleDriveAccountToken(accountId);

  const userHasPrevisoulyAccess =
    (userRole === AccountRoles.READER || userRole === AccountRoles.WRITER) &&
    googleDriveAccountToken;

  const isGoogleDocOptionEnabled =
    userRole === AccountRoles.ADMIN ||
    userRole === AccountRoles.OWNER ||
    selectedAccount?.hasValidGoogleDriveToken ||
    userHasPrevisoulyAccess;

  const handleCloseAdminReqTokenDialog = () => {
    setOpenAdminReqTokenDialog(false);
  };

  const handleCloseRestrictedUserDialog = () => {
    setRestrictedUserDialog(false);
  };

  const handleGoogleDocScenarios = (topicContentType: string) => {
    if (
      topicContentType === 'GoogleDoc' &&
      !selectedAccount.hasValidGoogleDriveToken
    ) {
      if (googleDriveAccountToken) {
        if (
          userRole === AccountRoles.READER ||
          userRole === AccountRoles.WRITER
        )
          setRestrictedUserDialog(true);
        else setOpenAdminReqTokenDialog(true);

        return;
      } else {
        handleGoogleDriveSignIn();

        return;
      }
    }
  };

  useEffect(() => {
    if (
      supportedTranslateLanguages.length &&
      selectedDoc &&
      selectedTopic &&
      selectedTopic.languages
    ) {
      const filteredLanguages = supportedTranslateLanguages.filter(
        (language) => language.languageCode === selectedTopic.languageCode
      );
      if (
        (filteredLanguages.length && selectedTopic.title) ||
        !selectedTopic.title
      ) {
        const shouldBeTranslatedFromLanguages = selectedDoc.languages
          .filter(
            (language) =>
              selectedTopic.languages.indexOf(language.languageCode) > -1
          )
          .map((language) => language.languageCode);
        const availableTranslateFromLanguages =
          supportedTranslateLanguages.filter(
            (language) =>
              shouldBeTranslatedFromLanguages.indexOf(language.languageCode) >
              -1
          );

        if (availableTranslateFromLanguages.length) {
          setSourceTranslationLanguage(
            availableTranslateFromLanguages[0].languageCode
          );
        }

        setAvailableTranslateFromLanguages(availableTranslateFromLanguages);
      }
    }
  }, [supportedTranslateLanguages, selectedDoc, selectedTopic]);

  const handleTranslate = async () => {
    const response = await translateTopic();

    if (response.isSuccess) {
      const customTopic = { ...response.data };

      if (!customTopic.body.trim().startsWith('<h1>')) {
        customTopic.body = `<h1>${customTopic.title}</h1>` + customTopic.body;
      }

      const translatedTopic = { ...selectedTopic, ...customTopic };

      await saveTopic({
        ...translatedTopic,
        ...createNewTopicContent(
          selectedTopic?.parentTopicId,
          getLanguageCode(),
          { id: translatedTopic?.documentVersionId },
          translatedTopic
        ),
        isHidden: false,
      });

      const topicResponse = await getTopic();

      if (topicResponse.isSuccess) {
        setSelectedTopic(topicResponse.data);
        setEditedTopic(topicResponse.data);
      }

      setOpenTranslationModal(false);
    }
  };

  const handleAddTopic = async (topicContentType: string = 'CKEditor5') => {
    handleGoogleDocScenarios(topicContentType);

    const newContent = createNewTopicContent(
      selectedTopic.parentTopicId,
      getLanguageCode(),
      selectedVersion,
      selectedTopic,
      topicContentType
    );

    //eslint-disable-next-line
    //@ts-expect-error
    saveTopic(newContent).then((topic) => {
      setSelectedTopic(topic);
      setEditedTopic(topic);
    });
  };

  const menu = (isTranslate: boolean): MenuProps['items'] => [
    {
      key: '1',
      type: 'group',
      label: (
        <span style={{ fontSize: '14px', color: '#1a73e8' }}>
          Editor Selection:
        </span>
      ),
      children: [
        {
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BsFileRichtext
                style={{ fontSize: 16, marginLeft: 10 }}
                className="menu-item-for-new"
              />
              Rich Text
            </div>
          ),
          key: '2',
          onClick: () =>
            isTranslate
              ? setOpenTranslationModal(true)
              : handleAddTopic('CKEditor5'),
        },
        ...(isGoogleDocOptionEnabled
          ? [
              {
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <GoogleOutlined
                      style={{ fontSize: 16, marginLeft: 10 }}
                      className="menu-item-for-new"
                    />
                    {t('google-doc')}
                  </div>
                ),
                key: '4',
                disabled: isTranslate,
                onClick: () =>
                  isTranslate ? null : handleAddTopic('GoogleDoc'),
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <Fragment>
      <div style={{ width: '100%', height: '100%' }}>
        <Card
          type="inner"
          title={<Title level={4}>{t('translation-selection-title')}</Title>}
          style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
          bodyStyle={{ height: '400px' }}
        >
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexWrap: 'wrap',
              height: '75%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '6px',
                marginTop: '100px',
              }}
            >
              <Dropdown
                className="dropdown-new-btn"
                menu={{ items: menu(false) }}
              >
                <Button loading={isSavingTopic} disabled={isSavingTopic}>
                  <CreateIcon /> {t('translate-new')} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '6px',
                marginTop: '50px',
              }}
            >
              <Dropdown
                className="dropdown-new-btn"
                menu={{ items: menu(true) }}
              >
                <Button loading={isSavingTopic} disabled={isSavingTopic}>
                  <GTranslateIcon /> {t('translate-existing')}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Row>
        </Card>
      </div>

      <PlanLimitationDialog
        accountPlan={currentPlan?.name}
        textTitle={t('dialog-cant-add-topics')}
        open={openPlanLimitationDialog}
        textMessage={
          currentPlan?.name === 'Free'
            ? t('dialog-topic-limitation-free')
            : t('dialog-topic-limitation-business')
        }
        handleCloseLimitationDialog={() => {
          setOpenPlanLimitationDialog(false);
        }}
      />

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-access-reject-desc')}
        isOpen={restrictedUserDialog}
        onDialogConfirmed={handleCloseRestrictedUserDialog}
      />

      <ConfirmationDialog
        title={t('google-drive-access-reject-title')}
        message={t('google-drive-expired-token-desc')}
        isOpen={openAdminReqTokenDialog}
        onDialogClosed={handleCloseAdminReqTokenDialog}
        onDialogConfirmed={() => handleGoogleDriveSignIn()}
      />

      <Dialog
        disableEscapeKeyDown
        open={openTranslationModal}
        fullWidth
        maxWidth={'xs'}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">Translate</DialogTitle>

        <DialogContent>
          <Col xs={24} sm={24}>
            <InputLabel htmlFor="sourceVariant">Translate from</InputLabel>

            <Select
              value={sourceTranslationLanguage}
              fullWidth
              onChange={(event) => {
                setSourceTranslationLanguage(`${event.target.value}`);
              }}
            >
              {availableTranslateFromLanguages.map((language) => (
                <MenuItem
                  key={language.languageCode}
                  value={language.languageCode}
                >
                  {ISO6391.getName(language.languageCode)}
                </MenuItem>
              ))}
            </Select>
          </Col>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setSourceTranslationLanguage(undefined);
              setOpenTranslationModal(false);
            }}
            color="primary"
          >
            Cancel
          </Button>

          <MButton
            color="primary"
            variant="contained"
            aria-label={`Translate ${selectedTopic?.languageCode}`}
            disabled={isTranslatingTopic || isSavingTopic}
            onClick={() => handleTranslate()}
            autoFocus
          >
            {isTranslatingTopic || isSavingTopic ? (
              <CircularProgress
                style={{ color: '#fff', width: 15, height: 15 }}
              />
            ) : (
              t('translate')
            )}
          </MButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(TranslationSelection);
