import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddAccountPaymentMethodRequest } from '../../api-schemas/account';

const updateAccountPaymentMethodRequest = (
  data: AddAccountPaymentMethodRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Account/Subscription/PaymentMethod', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountPaymentMethod(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountPaymentMethod,
    isPending: isUpdatingAccountPaymentMethod,
    isSuccess: isSuccessUpdatingAccountPaymentMethod,
  } = useMutation({
    mutationFn: updateAccountPaymentMethodRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'paymentMethods', accountId],
      });
    },
  });

  return {
    updateAccountPaymentMethod,
    isUpdatingAccountPaymentMethod,
    isSuccessUpdatingAccountPaymentMethod,
  };
}
