import React from "react";
import { Chart } from "react-google-charts";

const GeoChart = ({ chartData, length }) => {
  const transformedData = [["Country", "View"]];

  if (chartData) {
    chartData.forEach((item) => {
      if (item.location !== "unknown") {
        transformedData.push([item.location, item.count]);
      }
    });
  }

  return (
    <div style={{ position: 'relative', width: '70%', height: '400px' }}>
      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              // const region = transformedData[selection[0].row + 1];
              // console.log("Selected : " + region);
            },
          },
        ]}
        chartType="GeoChart"
        width="100%"
        height="100%"
        data={transformedData}
      />
      {
        length ? null :
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          No Data to Display!
        </div>
      }
    </div>
  );
};

export default GeoChart;
