import React, { useContext, useRef } from 'react';
import { Box } from '@mui/material';
import { getTopicLink } from '../../../../../helpers/common';
import { useTranslation } from 'react-i18next';
import { DocumentContext } from '../../../../../contexts/document';
import { Button, Tooltip } from 'antd';
import { MdOpenInNew } from 'react-icons/md';
import { TopicContext } from '../../../../../contexts/topic';
import { AccountContext } from '../../../../../contexts/account';
import useGetItemType from '../../../../../api-hooks/folder/useGetItemType';

const NewTabBox = ({
  showText = true,
  showBorder = true,
  color = 'black',
}: {
  showText?: boolean;
  showBorder?: boolean;
  color?: string;
}) => {
  const { selectedAccount } = useContext(AccountContext);
  const { selectedLanguage, selectedDoc } =
    useContext(DocumentContext);
  const { selectedTopic } = useContext(TopicContext);

  const { getItemType } = useGetItemType(selectedTopic?.id);

  const handleGetPublishedUrl = () => {
    getItemType(selectedTopic?.topicId).then((itemType) => {
      
      const publishLink =
           getTopicLink(
              selectedDoc,
              selectedTopic,
              /*selectedDoc.languages.length > 1 ? */ selectedLanguage /* : null*/,
              true,
              isDoclessTopic.current,
              selectedAccount,
              itemType?.path
            );

      const newTab = window.open('', '_blank');

      if (newTab) {
        newTab.location.href = publishLink;
      }
    });
  };

  const { t } = useTranslation();

  const isDoclessTopic = useRef<boolean>(
    Boolean(selectedTopic && !selectedTopic.documentId)
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ marginRight: 5 }}
    >
      <Tooltip title={t('view-live-tooltip')}>
        <Button
          style={{
            fontSize: 12,
            height: 32,
            paddingTop: 2,
            border: showBorder ? '1px solid #e5e7eb' : 'none',
            color,
            display: 'flex',
            alignItems: 'center',
          }}
          icon={
            <MdOpenInNew
              id="openInNewIcon"
              style={{
                marginRight: 5,
                marginTop: '-2px',
                fontSize: 14,
              }}
            />
          }
          onClick={handleGetPublishedUrl}
          size="small"
        >
          {showText ? t<string>('view-live') : null}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default NewTabBox;
