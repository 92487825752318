import buildInfo from '../../buildInfo.json';
import config from 'react-global-configuration';

const sendAppDataToClarity = (userId: string) => {
  if (window.clarity) {
    window.clarity('set', 'APP_VERSION', buildInfo.version);

    window.clarity('set', 'USER_ID', userId);
  }
};

export const handleClarityEvents = (userId: string = 'guest-123') => {
  if (config.get('environment') === 'production') {
    if (!window.clarity) {
      document
        .getElementById('clarity-script')
        ?.addEventListener('load', () => sendAppDataToClarity(userId));
    } else {
      sendAppDataToClarity(userId);
    }
  }
};
