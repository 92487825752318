import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddAccountPaymentMethodRequest } from '../../api-schemas/account';

const addAccountPaymentMethodRequest = (
  data: AddAccountPaymentMethodRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Account/Subscription/PaymentMethod', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useAddAccountPaymentMethod(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: addAccountPaymentMethod,
    isPending: isAddingAccountPaymentMethod,
    isSuccess: isSuccessAddingAccountPaymentMethod,
  } = useMutation({
    mutationFn: addAccountPaymentMethodRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', 'paymentMethods', accountId],
      });
    },
  });

  return {
    addAccountPaymentMethod,
    isAddingAccountPaymentMethod,
    isSuccessAddingAccountPaymentMethod,
  };
}
