import React, { useState } from 'react';

import TopicContentEditorHandler from '../../components/topic/content-editor-handler';

const TopicContentEditorPage = () => {
  const [isSideSectionExpanded, setIsSideSectionExpanded] = useState<boolean>(false);

  return (
    <TopicContentEditorHandler
      isSideSectionExpanded={isSideSectionExpanded}
      setIsSideSectionExpanded={setIsSideSectionExpanded}
    />
  );
};

export default TopicContentEditorPage;
