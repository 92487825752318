import React, { useState, useRef, useContext, useEffect } from 'react';
import { Paper, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { withSnackbar } from 'notistack';
import { Button, Typography, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { DocumentService } from '../../../services/document';
import { DocumentContext } from '../../../contexts/document';
import { AccountContext } from '../../../contexts/account';
import { emptyGuid, showSuccessMessage, sleep } from '../../../helpers/common';
import GeneralSettings from './general';
import StyleSettings from './style';
import AdvancedSettings from './advanced';
import PlanLimitationDialog from '../../plan-limitation-dialog';
import { getLanguageCode, getVersionSlug } from '../../../helpers/route';
import useGetCurrentPlan from '../../../api-hooks/plan/useGetCurrentPlan';
import { FolderContext } from '../../../contexts/folder';
import useGetDocuments from '../../../api-hooks/document/useGetDocuments';

const { Title } = Typography;

const styles: any = (theme) => ({
  layout: {
    width: 'auto',
    height: 'calc(100% - 40px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(2)) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    boxShadow: 'none!important',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(700 + parseInt(theme.spacing(3)) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const DocumentSettings = (props) => {
  const { classes } = props;

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { accountId, documentId }: { accountId: string; documentId: string } = useParams();

  const { accountsData, selectedLanguage } = useContext(AccountContext);
  const { documents, setDocuments, selectedDoc, setSelectedDoc, setSelectedTopic } = useContext(DocumentContext);
  const { selectedFolderId } = useContext(FolderContext);

  const { documents: fetchedDoucuments } = useGetDocuments(accountId, selectedFolderId, false);

  const settingsItem: string =
    props.settingsItem ?? window.location.pathname === '/document/create'
      ? 'create'
      : props.match.params['settingsItem'] || 'edit';

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openTopicLimitDialog, setOpenTopicLimitDialog] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [model, setModel] = useState<any>(null);
  const [isLogoDeleted, setIsLogoDeleted] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string>(accountId);

  const cropLogoRef: any = useRef();

  const { currentPlan, getCurrentPlan } = useGetCurrentPlan(selectedAccountId, false);

  useEffect(
    () => {
      if (accountsData && (selectedDoc || selectedDoc === undefined)) {
        i18n.changeLanguage(navigator.language);
        if (documentId) {
          const docData = { ...selectedDoc };
          docData.accountRef = docData.account.id;
          setModel(docData);
        }
      }
    },
    // eslint-disable-next-line
    [accountsData, selectedDoc]
  );

  useEffect(() => {
    if (documents.length && documentId && accountId) {
      const selectedDoc = documents.find((doc) => doc.id === documentId && doc.account.id === accountId);
      if (selectedDoc) {
        setSelectedDoc(selectedDoc);
      } else {
        props.enqueueSnackbar(t('error.doc-not-found'), {
          variant: 'error',
        });
        history.push('/');
      }
    } else if (documents.length && !documentId) {
      setSelectedDoc(undefined);
    }
    // eslint-disable-next-line
  }, [documents]);

  const documentService = new DocumentService();

  const createDocument = async (model) => {
    let numberOfManuals = 0;

    const tempSelectedAccount = accountsData.find((account) => model.accountId === account.id);

    setSelectedAccountId(tempSelectedAccount.id);

    await sleep(500);

    getCurrentPlan()
      .then(({ data }) => {
        for (const document of fetchedDoucuments) {
          if (document.account.id === tempSelectedAccount.id) {
            numberOfManuals = numberOfManuals + 1;
          }
        }

        if (data && data.features.numberOfManuals !== -1 && numberOfManuals >= data.features.numberOfManuals) {
          setOpenDialog(true);
          return;
        } else {
          documentService
            .postDocument({ ...model, folderId: props.selectedFolderId })
            .then(async (document) => {
              const newDocument = document;
              const tempDocuments = documents;

              newDocument.languages = [
                {
                  languageCode: document.defaultLanguageCode,
                  order: 1,
                },
              ];
              newDocument.defaultDocumentVersionSlug = 'v1-0-0';
              newDocument.linkedToAccount = true;

              tempDocuments.unshift(newDocument);

              setDocuments(tempDocuments);
              setSelectedTopic(null);
              setSelectedDoc(null);

              showSuccessMessage(props.enqueueSnackbar, t);

              await queryClient.invalidateQueries({
                queryKey: ['documents', true],
              });
              await queryClient.invalidateQueries({
                queryKey: props.selectedFolderId
                  ? ['documents', false, accountId, props.selectedFolderId]
                  : ['documents', false, accountId],
              });
              await queryClient.invalidateQueries({
                queryKey: ['accounts'],
              });

              if (newDocument.account) {
                history.push(`/document/${newDocument.account.id}/${newDocument.id}/content`);

                setSaveLoading(false);
              } else {
                history.push(`/`);

                setSaveLoading(false);
              }
            })
            .catch(async (error) => {
              const status = error.status;
              const errorJson = await error.json();
              if (status === 403) {
                props.enqueueSnackbar(t('error.create-document-insufficient-permission'), {
                  variant: 'error',
                });
              } else {
                if (!!errorJson && errorJson.errors[0].fields === 'MaximumNumberOfTopicsExceeded') {
                  setOpenTopicLimitDialog(true);
                }
                props.enqueueSnackbar(
                  t(errorJson.errors ? `error.${errorJson.errors[0].field}` : 'error.save-document'),
                  {
                    variant: 'error',
                  }
                );
              }
              setSaveLoading(false);
              setErrors(errorJson.errors);
            });
        }
      })
      .catch(() => {
        props.enqueueSnackbar(t('error.cant-get-plan'), {
          variant: 'error',
        });
      });
  };

  const deleteLogo = () => {
    return documentService.removeLogo(model.id).catch(() => {
      props.enqueueSnackbar(t('error.delete-logo'), {
        variant: 'error',
      });
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setSaveLoading(true);
    if (model.id !== emptyGuid()) {
      const sendLogo = cropLogoRef.current ? cropLogoRef.current.sendLogo : null;
      documentService
        .putDocument(model)
        .then(async () => {
          if (sendLogo && !isLogoDeleted) await sendLogo(model.id);
          if (isLogoDeleted) await deleteLogo();
          let tempDocuments = documents;
          let index = -1;
          const currentDoc = tempDocuments.find((doc) => doc.id === model.id);
          if (currentDoc) {
            index = tempDocuments.indexOf(currentDoc);
          }
          tempDocuments = tempDocuments.filter((doc) => doc.id !== model.id);
          tempDocuments.splice(index, 0, {
            ...model,
            updateDateTime: new Date().toISOString(),
          });
          showSuccessMessage(props.enqueueSnackbar, t);
          if (settingsItem !== 'create') {
            setSelectedDoc(model);
            if (documentId !== model.id) {
              history.replace(
                `/document/${model.account.id}/${model.id}/settings?lang=${getLanguageCode()}&ver=${getVersionSlug()}`
              );
            }
            setDocuments(tempDocuments);
            setSaveLoading(false);
          } else {
            setSelectedDoc(null);
            setDocuments(tempDocuments);
            history.push('/');
          }
        })
        .catch(async (error) => {
          const errorJson = await error.json();
          setSaveLoading(false);
          setErrors(errorJson.errors);
          props.enqueueSnackbar(t('error.save-document'), {
            variant: 'error',
          });
        });
    } else if (model.accountId) {
      setSelectedAccountId(model.accountId);
      setSelectedDoc(null);

      await sleep(500);

      model.defaultLanguageCode = selectedLanguage;
      createDocument(model);
    } else {
      model.defaultLanguageCode = selectedLanguage;
      createDocument({ ...model, accountId: emptyGuid() });
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();

      return;
    }

    //eslint-disable-next-line
    //@ts-expect-error
    if (history.location.state && history.location.state.prevHostName) history.goBack();
    else history.push('/');
  };

  const actionsSection = () => {
    return (
      <div className={classes.buttons}>
        <Button size="large" onClick={handleCancel} className={classes.button}>
          {t('cancel')}
        </Button>
        <Button
          size="large"
          htmlType="submit"
          type="primary"
          className={classes.button}
          disabled={saveLoading}
          onClick={handleSave}
        >
          {saveLoading ? <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} /> : t('save')}
        </Button>
        <PlanLimitationDialog
          accountPlan={currentPlan?.name}
          open={openDialog}
          textMessage={t('dialog-doc-limitation')}
          textTitle={t('dialog-cant-add-docs')}
          handleCloseLimitationDialog={() => {
            setOpenDialog(false);
          }}
        />
        <PlanLimitationDialog
          accountPlan={currentPlan?.name}
          open={openTopicLimitDialog}
          textMessage={t('dialog-cant-add-doc-cause-topic-exceeds')}
          textTitle={t('dialog-cant-add-docs')}
          handleCloseLimitationDialog={() => {
            setOpenTopicLimitDialog(false);
          }}
        />
      </div>
    );
  };

  switch (settingsItem) {
    case 'create':
      return (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <GeneralSettings
              loading={loading}
              setLoading={setLoading}
              model={model}
              setModel={setModel}
              accountsData={accountsData}
              errors={errors}
              setErrors={setErrors}
              mode={settingsItem}
            />
            {actionsSection()}
          </Paper>
        </main>
      );
    default:
      return (
        <main className={classes.layout} style={{ marginTop: 50 }}>
          <Title level={2} style={{ fontWeight: 500, fontSize: 24 }}>
            {t('document-settings')}
          </Title>
          <Tabs
            style={{ marginTop: 10 }}
            defaultActiveKey="1"
            items={[
              {
                label: 'General',
                key: '1',
                children: (
                  <Paper className={classes.paper}>
                    <GeneralSettings
                      loading={loading}
                      setLoading={setLoading}
                      model={model}
                      setModel={setModel}
                      accountsData={accountsData}
                      selectedDoc={selectedDoc}
                      errors={errors}
                      setErrors={setErrors}
                    />
                    {actionsSection()}
                  </Paper>
                ),
              },
              {
                label: 'Style',
                key: '2',
                children: (
                  <Paper id="style-settings" className={classes.paper}>
                    <StyleSettings
                      cropLogoRef={cropLogoRef}
                      loading={loading}
                      setLoading={setLoading}
                      model={model}
                      setModel={setModel}
                      selectedDoc={selectedDoc}
                      setIsLogoDeleted={setIsLogoDeleted}
                      imageUrl={imageUrl}
                      setImageUrl={setImageUrl}
                    />
                    {actionsSection()}
                  </Paper>
                ),
              },
              {
                label: 'Advanced',
                key: '3',
                children: (
                  <Paper id="advanced-settings" className={classes.paper}>
                    <AdvancedSettings
                      loading={loading}
                      setLoading={setLoading}
                      model={model}
                      setModel={setModel}
                      accountsData={accountsData}
                      selectedDoc={selectedDoc}
                    />
                    {actionsSection()}
                  </Paper>
                ),
              },
            ]}
          />
        </main>
      );
  }
};

export default withStyles(styles)(withSnackbar(DocumentSettings));
