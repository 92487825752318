import { useContext, useEffect, useState, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { useQueryClient } from '@tanstack/react-query';

import { DraftTopicTree, FirebaseNodeTopicTree } from '../../api-schemas/topic';
import { auth, db } from '../../helpers/firebase';
import useGetDraftTopicTree from '../../api-hooks/topic/useGetDraftTopicTree';
import { getLanguageCode } from '../../helpers/route';
import { createTopicTree } from '../../helpers/topic';
import { Documentversion } from '../../api-schemas/document-version';
import { TopicContext } from '../../contexts/topic';

const useTopicTree = (selectedVersion: Documentversion) => {
  const queryClient = useQueryClient();
  const unsubscribeRef = useRef<(() => void) | null>(null);

  const { selectedTopic, isLoadingSelectedTopic } = useContext(TopicContext);

  const {
    draftTopicTree,
    flatDraftTopicTree,
    isLoadingDraftTopicTree,
    isRefetchingDraftTopicTree,
    isSuccessLoadingDraftTopicTree,
  } = useGetDraftTopicTree({
    documentVersionId: selectedVersion?.id,
    languageCode: getLanguageCode(),
  });

  const [flatDraftTopicTreeState, setFlatDraftTopicTreeState] =
    useState<DraftTopicTree>(flatDraftTopicTree);
  const [draftTopicTreeState, setDraftTopicTreeState] =
    useState<DraftTopicTree>(draftTopicTree);

  function updateTopic(
    treeData: DraftTopicTree,
    firebaseNodesData: FirebaseNodeTopicTree
  ): DraftTopicTree {
    return treeData
      .filter((treeItem) =>
        firebaseNodesData.some((item) => item.TopicId === treeItem.id)
      )
      .map((treeItem) => {
        const matchedNode = firebaseNodesData.find(
          (item) => item.TopicId === treeItem.id
        );

        return {
          ...treeItem,
          title: matchedNode!.TopicTitle,
          topicVersionNumber: matchedNode!.TopicVersionNumber,
          latestTopicVersionId: matchedNode!.LatestTopicVersionId,
          publishedTopicVersionId: matchedNode!.PublishedTopicVersionId || '',
        };
      });
  }

  function syncSelectedTopicData(treeData: FirebaseNodeTopicTree) {
    const topic = treeData.filter(
      (topic) => selectedTopic?.topicId === topic.TopicId
    )[0];

    if (
      topic &&
      selectedTopic?.topicId &&
      topic.PublishedTopicVersionId !== topic.LatestTopicVersionId &&
      !isLoadingSelectedTopic
    ) {
      queryClient.invalidateQueries({
        queryKey: [
          'draftTopic',
          selectedTopic?.topicId,
          selectedTopic?.languageCode,
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [
          'draftTopic',
          'silent',
          selectedTopic?.topicId,
          selectedTopic?.languageCode,
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [
          'publishTopic',
          selectedVersion?.id,
          selectedTopic?.languageCode,
          selectedTopic?.topicId,
        ],
      });
    }
  }

  useEffect(() => {
    if (!selectedVersion || isLoadingDraftTopicTree) return;

    if (unsubscribeRef.current) {
      unsubscribeRef.current(); 
      unsubscribeRef.current = null; 
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const draftTopicTreeQuery = query(
          collection(db, `DraftTopicTrees`),
          where('Id', '==', `${selectedVersion?.id}-${getLanguageCode()}`)
        );

        unsubscribeRef.current = onSnapshot(
          draftTopicTreeQuery,
          (draftTopicTreesSnapshot) => {
            if (draftTopicTreesSnapshot?.docs?.[0]) {
              const nodes = draftTopicTreesSnapshot.docs[0].data().Nodes;

              const updatedTopic = updateTopic(flatDraftTopicTree, nodes);
              const createdDraftTopicTree = createTopicTree(updatedTopic, null);

              syncSelectedTopicData(nodes);

                setFlatDraftTopicTreeState(updatedTopic);
                setDraftTopicTreeState(createdDraftTopicTree);
            }
          }
        );
      }
    });

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
    };
  }, [selectedVersion, isLoadingDraftTopicTree, getLanguageCode]);

  useEffect(() => {
    if(isSuccessLoadingDraftTopicTree) {
      setFlatDraftTopicTreeState(flatDraftTopicTree);
      setDraftTopicTreeState(draftTopicTree);
    }
  }, [isSuccessLoadingDraftTopicTree, draftTopicTree, flatDraftTopicTree]);

  return {
    flatDraftTopicTree: flatDraftTopicTreeState,
    draftTopicTree: draftTopicTreeState,
    isLoadingDraftTopicTree,
    isRefetchingDraftTopicTree,
    isSuccessLoadingDraftTopicTree,
  };
};

export default useTopicTree;
