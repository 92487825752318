import { AxiosResponse } from 'axios';
import { baseWebApi } from '../base-web-api';

export class topicService extends baseWebApi {
  // #region get data

  getTopics(documentVersionId: string, languageCode: string) {
    return this.sendRequest(
      'get',
      `topictree/draft?documentVersionId=${documentVersionId}&languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  }

  getTopicsWithCache(documentVersionId: string, languageCode: string) {
    return this.getWithCache(
      `topictree/draft?documentVersionId=${documentVersionId}&languageCode=${languageCode}`
    ).then((response: AxiosResponse) => response.data);
  }

  getVersions(
    topicId: string,
    languageCode: string,
    pageIndex: number,
    pageSize: number,
    onlyPublished: boolean
  ) {
    return this.sendRequest(
      'get',
      `topic/versions?topicId=${topicId}&languageCode=${languageCode}&pageIndex=${pageIndex}&pageSize=${pageSize}&onlyPublished=${onlyPublished}`
    ).then((response: Response) => response.json());
  }

  getDiff(oldTopicVersionId: string, newTopicVersionId: string) {
    const endpoint =
      oldTopicVersionId && oldTopicVersionId !== 'null'
        ? `topic/diff?newTopicVersionId=${newTopicVersionId}&oldTopicVersionId=${oldTopicVersionId}`
        : `topic/diff?newTopicVersionId=${newTopicVersionId}`;
    return this.getWithCache(endpoint).then(
      (response: AxiosResponse) => response.data
    );
  }

  getTopicContent(topicVersionId: string) {
    return this.sendRequest('get', `topic/TopicVersion/${topicVersionId}`).then(
      (response: Response) => response.json()
    );
  }

  getTopicContentByTopicId(languageCode: string, topicId: string) {
    return this.sendRequest(
      'get',
      `Topic/draft/${topicId}?languageCode=${languageCode}`
    ).then((response: Response) => response.json());
  }

  putDiscardTopic(topicId: string, languageCode: string) {
    const data = JSON.stringify({
      topicId: topicId,
      languageCode: languageCode,
    });
    return this.sendRequest('POST', `topic/discard`, data).then(
      (response: Response) => response.json()
    );
  }

  translate(
    topicId: string,
    sourceLanguageCode: string,
    targetLanguageCode: string
  ) {
    return this.sendRequest(
      'GET',
      `topic/${topicId}/translate/${sourceLanguageCode}/${targetLanguageCode}`
    ).then((response: Response) => response.json());
  }
}
