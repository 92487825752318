import React, { useContext, useRef } from 'react';
import { Col, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdPreview, MdCheck } from 'react-icons/md';

import Component from '../topic/component-editor/classes/component';
import { StructuredDataContext } from '../../contexts/structured-data-context';

const ComponentSelectorBox = ({
  component,
  setSelectedComponent,
  onSelectComponent,
}) => {
  const { t } = useTranslation();

  const { context } = useContext(StructuredDataContext);

  const calcComponentOrder = () => {
    const components = document.querySelectorAll(
      '#CKEditorWrapper section[data-component="true"]'
    );

    return components.length + 20;
  };

  const order = useRef(calcComponentOrder());

  const handleSelectComponent = () => {
    const componentInstance = new Component(
      {
        ...component.latestComponentVersion.data,
        content: {
          type: component.latestComponentVersion.data.content.type,
          content: component.latestComponentVersion.data.content.content
            .replace(/\|\+/g, '')
            .replace(/\\r/g, ''),
        },
      },
      order.current,
      context
    );

    const script = componentInstance.scriptCreator();
    const style = componentInstance.styleCreator();

    document.body.appendChild(script);
    document.body.appendChild(style);

    setTimeout(() => {
      const previewElement = document.getElementById(
        `preview-section-${order.current}`
      );

      if (previewElement) {
        const innerHTML = previewElement.innerHTML;

        onSelectComponent?.(innerHTML, component);
      }
    }, 500);
  };

  return (
    <Col className="droppable-element" style={{ padding: 0 }} span={24}>
      <Card bordered>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.54)' }}>
            <span style={{ fontSize: 14 }}>{component.title}</span>
          </div>
          <div>
            {component.iconName ? (
              <span
                className={`active mdi ${component.iconName}`}
                style={{ fontSize: '35px', color: 'rgba(0, 0, 0, 0.54)' }}
              ></span>
            ) : (
              <img
                alt={component.title}
                src={process.env.PUBLIC_URL + '/Logo-white.svg'}
                width={35}
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              />
            )}
          </div>
          <div className="component-help-box">
            <Tooltip
              className="component-selector-item-tooltip"
              title={t('preview')}
            >
              <MdPreview
                onClick={() => setSelectedComponent(component)}
                style={{ marginLeft: 5, fontSize: 16, cursor: 'pointer' }}
              />
            </Tooltip>

            <Tooltip
              className="component-selector-item-tooltip"
              title={t('select')}
            >
              <MdCheck
                onClick={handleSelectComponent}
                style={{ marginLeft: 5, fontSize: 16, cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        </div>
      </Card>

      <div
        id={`preview-section-${order.current}`}
        style={{ display: 'none', position: 'relative' }}
      ></div>
    </Col>
  );
};

export default ComponentSelectorBox;
