import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Threads } from '../../api-schemas/comment';

const getThreadsRequest = (channelId: string) => {
  return axios
    .get<Threads>(`/api/thread`, {
      params: { channelId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetThreads({
  channelId,
  enabled = true,
}: {
  channelId: string;
  enabled?: boolean;
}) {
  const {
    data,
    refetch: getThreads,
    isFetching: isLoadingThreads,
    isError: isErrorLoadingThreads,
    isSuccess: isSuccessLoadingThreads,
  } = useQuery<Threads>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['threads', channelId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(channelId) && enabled,
    queryFn: () => (channelId ? getThreadsRequest(channelId) : []),
  });

  const threads = useMemo(() => data || [], [data]);

  return {
    threads,
    isLoadingThreads,
    isErrorLoadingThreads,
    isSuccessLoadingThreads,

    getThreads,
  };
}
