import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertOutlined } from '@ant-design/icons';
import { Typography as AntTypography } from 'antd';
import { Tooltip } from 'antd';
import { Button as ButtonMaterial, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import config from 'react-global-configuration';

import { showSuccessMessage } from '../../../../helpers/common';
import { AccountContext } from '../../../../contexts/account';
import { AccountRoles } from '../../../../helpers/roles';
import { ConfirmationDialog } from '../../../confirmation-dialog';
import useDeleteAccount from '../../../../api-hooks/account/useDeleteAccount';
import useDeleteUser from '../../../../api-hooks/user/useDeleteUser';

import '../styles.css';
import useGetAccounts from '../../../../api-hooks/account/useGetAccounts';

const { Text, Title } = AntTypography;

function DangerZoneIntegrationSettings() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { selectedAccount, userRole, setSelectedAccount, accountsData } = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();

  const { deleteAccount } = useDeleteAccount();
  const { deleteUser } = useDeleteUser();
  const { getAccounts } = useGetAccounts();

  const { t } = useTranslation();
  const history = useHistory();

  const handleCloseRejectDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeletDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSignOut = () => {
    window
      .fetch(`${localStorage.webApiAddress}user/sessionLogout`, {
        method: 'POST',
      })
      .then(() => {
        localStorage.removeItem('auth_token');
        if (window.location.hostname === 'localhost') {
          window.location.assign(`/auth/login`);
        } else {
          window.location.assign(`${config.get('editorURL')}/auth/login`);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.errors ? t(`error.${error.errors[0].code}`) : t('error.unknown'), {
          variant: 'error',
        });
      });
  };

  const handleDeleteUser = () => {
    const userId = localStorage.getItem('userId');

    if (userId)
      deleteUser({ UserId: userId }).then(() => {
        setLoading(false);

        showSuccessMessage(enqueueSnackbar, t);

        handleSignOut();
      });
  };

  const handleDeleteAccount = () => {
    handleCloseDeletDialog();

    if (userRole === AccountRoles.OWNER) {
      setLoading(true);

      deleteAccount({ accountId: selectedAccount.id })
        .then(() => {
          if (accountsData && accountsData.length === 1) handleDeleteUser();
          else
            getAccounts().then((result) => {
              const accounts = result.data;

              const remainAccounts = accounts?.find((account) => account.id !== selectedAccount.id);

              if (remainAccounts) {
                setLoading(false);

                showSuccessMessage(enqueueSnackbar, t);

                setSelectedAccount(remainAccounts);

                history.push('/');
              } else handleDeleteUser();
            });
        })
        .catch((error) => {
          setLoading(false);

          const errorMsg =
            Array.isArray(error?.response?.data?.errors) && error.response.data.errors.length > 0
              ? error.response.data.errors[0].field
              : undefined;

          if (errorMsg === 'AccountHasDocuments') {
            enqueueSnackbar(t('error-failed-delete-account-has-docs'), {
              variant: 'error',
            });
          } else if (errorMsg === 'AccountHasTopics') {
            enqueueSnackbar(t('error-failed-delete-account-has-topics'), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(t('error-failed-delete-account'), {
              variant: 'error',
            });
          }
        });
    } else setOpenDialog(true);
  };

  return (
    <div>
      <div style={{ borderRadius: '5px', padding: '10px' }}>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div className={window.innerWidth > 675 ? 'settings-icon-container-show' : 'settings-icon-container-hide'}>
            <AlertOutlined style={{ fontSize: '30px', color: '#1a73e8', marginLeft: 10 }} />
          </div>

          <div
            className={
              window.innerWidth > 675 ? 'settings-detail-container-show-partly' : 'settings-detail-container-show-full'
            }
          >
            <Title className="settings-item-title" level={4}>
              {t('delete-account-tile')}
            </Title>
            <Text>{t('delete-account-description-setting')}</Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: '30px',
              }}
            >
              {
                <Tooltip title={t<string>('delete-account-help')} style={{ margin: '5px 20px 0 0' }}>
                  <ButtonMaterial
                    variant="contained"
                    style={{ height: '35px', marginRight: '10px' }}
                    color="error"
                    onClick={handleOpenDeleteDialog}
                    aria-label="Delete current document"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} />
                    ) : (
                      t('delete-account-tile')
                    )}
                  </ButtonMaterial>
                </Tooltip>
              }
            </div>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        title={t<string>('delete-account-reject-title')}
        message={t<string>('delete-account-reject-desc')}
        isOpen={openDialog}
        onDialogConfirmed={handleCloseRejectDialog}
      />

      <ConfirmationDialog
        title={t<string>('delete-account-wanrning-title')}
        message={t<string>('delete-account-wanrning-desc')}
        isOpen={openDeleteDialog}
        onDialogClosed={handleCloseDeletDialog}
        onDialogConfirmed={handleDeleteAccount}
      />
    </div>
  );
}

export default DangerZoneIntegrationSettings;
