import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateVariableRequestType } from '../../api-schemas/variable';

const createVariableRequest = (data: CreateVariableRequestType) => {
    const body = JSON.stringify(data);

    return axios.post('/api/Variable', body, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.auth_token,
        }
    }).then((response) => response.data);
}

export default function useCreateVariable(accountId: string) {
    const queryClient = useQueryClient();

    const {
        mutateAsync: createVariable,
        isPending: isCreatingVariable,
        isSuccess: isSuccessCreatingVariable
    } = useMutation({
        mutationFn: createVariableRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['variables', accountId] });
        }
    });

    return {
        createVariable,
        isCreatingVariable,
        isSuccessCreatingVariable
    }
}