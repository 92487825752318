import React from 'react';
import { Form, Input } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';

interface TextInputProps {
  field: string;
  label?: string;
  value: any;
  placeholder?: string;
  onChange: any;
  tooltip?: string;
  showError?: boolean;
  required?: boolean;
  getErrorMessage?: any;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  className?: string;
  layout?: FormLayout;
  disabled?: boolean;
  type?: string;
  suffix?: React.ReactNode;
  id?: string;
  onFocus?: any;
  onBlur?: any;
  max?: number;
  min?: number;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  suffix,
  type,
  layout,
  field,
  label,
  value,
  placeholder,
  onChange,
  tooltip,
  showError,
  required,
  getErrorMessage,
  style,
  inputStyle,
  className,
  disabled,
  onFocus,
  onBlur,
  max,
  min,
}) => {
  return (
    <Form
      style={style}
      layout={layout ? layout : 'vertical'}
      className={showError ? '' : 'hide-alert'}
    >
      <Form.Item
        required={required}
        className={className}
        tooltip={tooltip ? tooltip : null}
        label={label}
        validateStatus={
          showError && getErrorMessage(field) ? 'error' : 'success'
        }
        help={showError && getErrorMessage(field)}
        rules={[
          { required: required, message: showError && getErrorMessage(field) },
        ]}
      >
        <Input
          id={id}
          required={required}
          suffix={suffix}
          type={type || 'text'}
          disabled={disabled}
          style={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          max={max}
          min={min}
        />
      </Form.Item>
    </Form>
  );
};

export default TextInput;
