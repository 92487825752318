import React from 'react';
import { Col } from 'antd';

import YamlEditor from '../../yaml-editor';

const NavbarMenuBuilderEditor = ({ data, handleChangeData }) => {
  return (
    <Col
      xs={24}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '1px solid #f0f0f0',
        width: '100%',
      }}
    >
      <YamlEditor
        schemaUri="schema.json"
        data={data}
        handleChangeData={handleChangeData}
        style={{ width: '100%', height: 500 }}
      />
    </Col>
  );
};

export default NavbarMenuBuilderEditor;
