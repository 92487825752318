import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Process } from '../../api-schemas/process';

const getProcessRequest = (processId: string) => {
  return axios
    .get<Process>(`/api/process/${processId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetProcess({
  processId,
  enabled,
}: {
  processId: string;
  enabled: boolean;
}) {
  const {
    data,
    refetch: getProcess,
    isFetching: isLoadingProcess,
    isError: isErrorLoadingProcess,
    isSuccess: isSuccessLoadingProcess,
  } = useQuery<Process>({
    staleTime: 1000 * 60 * 5,
    queryKey: ['process', processId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
    queryFn: () => getProcessRequest(processId),
  });

  const process = useMemo(() => data, [data]);

  return {
    process,
    isLoadingProcess,
    isErrorLoadingProcess,
    isSuccessLoadingProcess,

    getProcess,
  };
}
