import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const validateCustomDomainCNameRequest = (customDomain: string) => {
  return axios
    .get(`https://${customDomain}/api/version`)
    .then((response) => response.data);
};

export default function useValidateCustomDomainCName(customDomain: string) {
  const {
    data,
    refetch: validateCName,
    isFetching: isValidatingCustomDomainCName,
    isError: isErrorValidatingCustomDomainCName,
    isSuccess: isSuccessValidatingCustomDomainCName,
  } = useQuery({
    staleTime: 0,
    queryKey: ['customDomain', 'CName'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () => validateCustomDomainCNameRequest(customDomain),
  });

  const response = useMemo(() => data, [data]);

  return {
    response,
    isValidatingCustomDomainCName,
    isErrorValidatingCustomDomainCName,
    isSuccessValidatingCustomDomainCName,

    validateCName,
  };
}
