import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { AccountImages } from '../../api-schemas/account';

const getAccountImagesRequest = (accountId?: string) => {
  return axios
    .get<AccountImages>(`/api/Account/Image/${accountId}`, {
      params: { accountId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetAccountImages(accountId: string) {
  const {
    data,
    refetch: getAccountImages,
    isFetching: isLoadingAccountImages,
    isSuccess: isSuccessLoadingAccountImages,
  } = useQuery<AccountImages>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['account', 'images', accountId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    queryFn: () => getAccountImagesRequest(accountId),
  });

  const accountImages = useMemo(() => data, [data]);

  return {
    accountImages,
    isLoadingAccountImages,
    isSuccessLoadingAccountImages,

    getAccountImages,
  };
}
