import React, { useContext, useEffect, useState } from 'react';
import { Select, Table, Typography, DatePicker, Divider } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import { withSnackbar } from 'notistack';

// 1.2 Relative component imports
import LineChart from './line-chart';
import GeoChart from './geo-chart';
import SelectInput from '../../inputs/select-input';

// 1.3 Relative js/ts imports
import { DocumentContext } from '../../../contexts/document';
import { AccountContext } from '../../../contexts/account';
import { topicService } from '../../../services/topic';
import { DocumentService } from '../../../services/document';
import { AnalyticsService } from '../../../services/analytics';
const { RangePicker } = DatePicker;
import {
  getLastYearDate,
  getLastMonthDate,
  getLastWeekDate,
  getNowDate,
  formatDatePickerValue,
} from '../../../helpers/date';

// 1.4 Relative css imports
import './styles.css';

const { Title } = Typography;
const { Option } = Select;

const DOCUMENT_SERVICE = new DocumentService();
const TOPIC_SERVICE = new topicService();
const ANALYTICS_SERVICE = new AnalyticsService();

const GEO_TABLE_COLUMNS = [
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Number of Views',
    dataIndex: 'count',
    key: 'count',
    sorter: (a: any, b: any) => a.count - b.count,
    sortDirections: ['descend', 'ascend'] as SortOrder[],
  },
];

const TOPIC_TABLE_COLUMNS = [
  {
    title: 'Topic',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Number of Views',
    dataIndex: 'count',
    key: 'count',
    sorter: (a: any, b: any) => a.count - b.count,
    sortDirections: ['descend', 'ascend'] as SortOrder[],
  },
];

const FORMAT_DATE = [
  {
    id: 0,
    title: 'Last Week',
  },
  {
    id: 1,
    title: 'Last Month',
  },
  {
    id: 2,
    title: 'Last Year',
  },
  {
    id: 3,
    title: 'Custom',
  },
];

const ViewAnalytics = (props) => {
  const [languages, setLanguages] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [lineChartData, setLineChartData] = useState<any>();
  const [selectedAccountDocuments, setSelectedAccountDocuments] = useState<any>();
  const [geoChartData, setGeoChartData] = useState<any>();
  const [geoTableData, setGeoTableData] = useState<any>();
  const [topTitleTableData, setTopTitleTableData] = useState<any>();
  const [selectedDateRangeData, setSelectedDateRange] = useState<string>();
  const [selectedDateRangeTitle, setSelectedDateTitle] = useState<any>();
  const [isCustomDatePicker, setIsCustomDatePicker] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lineChartLoading, setLineChartLoading] = useState<boolean>(true);
  const [geoChartLoading, setGeoChartLoading] = useState<boolean>(true);
  const [selectedDocument, setSelectedDocument] = useState<any>();

  const { documentId }: { documentId: string } = useParams();
  const isAccount = !documentId;

  const {
    versions,
    documents,
    selectedDoc,
    setSelectedDoc,
    setVersions,
    selectedVersion,
    setSelectedVersion,
    topicsPerLanguageVersion,
    setTopicsPerLanguageVersion,
    selectedTopic,
    setSelectedTopic,
  } = useContext(DocumentContext);

  const { selectedAccount } = useContext(AccountContext);

  const history = useHistory();

  const { t } = useTranslation();

  const geoTableDataHandler = (data) => {
    if (data) {
      const result = data.slice(0, 5).map((x) => ({
        country: x.location,
        count: x.count,
      }));

      setGeoTableData(result);
    }
  };

  useEffect(() => {
    if (((isAccount && selectedAccount) || (!isAccount && selectedDoc)) && documents) {
      const selectedAccountDocs = documents.filter((doc) => doc.accountId === selectedAccount.id);

      const docArrays = [{ id: 0, title: 'ALL' }];

      docArrays.push(...selectedAccountDocs);

      const documentLanguages = [{ id: 0, title: 'ALL' }];

      if (isAccount) {
        setSelectedDocument(docArrays[0]);
        setLoading(false);
      } else {
        const docLanguages = selectedDoc.languages.map((x) => {
          return { id: x.languageCode, title: x.languageCode };
        });

        documentLanguages.push(...docLanguages);

        setSelectedDocument(selectedDoc);
      }

      setSelectedAccountDocuments(docArrays);

      setLanguages(documentLanguages);
      setSelectedLanguage(documentLanguages[0]);
    }
  }, [selectedAccount, documents, selectedDoc]);

  useEffect(() => {
    if (selectedDocument && !isAccount && selectedDocument?.id != 0) {
      DOCUMENT_SERVICE.getVersions(selectedDocument.id)
        .then((result) => {
          setVersions(result);
          setSelectedVersion(result[0]);
        })
        .catch(() => {
          props.enqueueSnackbar(t(`error.getVersions`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });
        });
    }

    // eslint-disable-next-line
  }, [selectedDocument]);

  useEffect(() => {
    if (selectedDocument && selectedVersion && !isAccount) {
      TOPIC_SERVICE.getTopics(selectedVersion.id, selectedDocument.defaultLanguageCode).then((result) => {
        const documentTopics = [{ id: 0, title: 'ALL' }];

        documentTopics.push(...result);

        setTopicsPerLanguageVersion(documentTopics);
        setSelectedTopic(documentTopics[0]);

        setLoading(false);
      });
    }

    // eslint-disable-next-line
  }, [selectedVersion]);

  useEffect(() => {
    const now = getNowDate();
    setSelectedDateRange(`startDate=${getLastMonthDate()}&endDate=${now}`);
    setSelectedDateTitle(FORMAT_DATE[1]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAccount && selectedLanguage && selectedAccount) {
      setLineChartLoading(true);
      setGeoChartLoading(true);

      ANALYTICS_SERVICE.getAccountView(selectedAccount.id, selectedLanguage.title, selectedDateRangeData)
        .then((result) => {
          setLineChartData(result);

          setLineChartLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getAccountViewByLocation(selectedAccount.id, selectedLanguage.title, selectedDateRangeData)
        .then((result) => {
          setGeoChartData(result);

          geoTableDataHandler(result);

          setGeoChartLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });

      ANALYTICS_SERVICE.getTopTopicsByAccount(selectedAccount.id, selectedDateRangeData)
        .then((result) => {
          //const outputArray = result.map((it) => ({
          //  count: it.count,
          //  topic: topicsPerLanguageVersion.find((topic) => topic.id === it.topicId ).title
          //}))
          //setTopTitleTableData(outputArray);

          setTopTitleTableData(result);

          setGeoChartLoading(false);
        })
        .catch((error) => {
          if (Number(error.status) === 504)
            props.enqueueSnackbar(t(`error.gateway-timeout`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
        });
    } else {
      if (selectedVersion && selectedLanguage && selectedTopic) {
        setLineChartLoading(true);
        setGeoChartLoading(true);

        if (selectedTopic.id !== 0) {
          ANALYTICS_SERVICE.getTopicView(selectedTopic.id, selectedLanguage.title, selectedDateRangeData)
            .then((result) => {
              setLineChartData(result);

              setLineChartLoading(false);
            })
            .catch((error) => {
              if (Number(error.status) === 504)
                props.enqueueSnackbar(t(`error.gateway-timeout`), {
                  variant: 'error',
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
            });

          ANALYTICS_SERVICE.getTopicViewByLocation(selectedTopic.id, selectedLanguage.title, selectedDateRangeData)
            .then((result) => {
              setGeoChartData(result);

              geoTableDataHandler(result);

              setGeoChartLoading(false);
            })
            .catch((error) => {
              if (Number(error.status) === 504)
                props.enqueueSnackbar(t(`error.gateway-timeout`), {
                  variant: 'error',
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
            });
        } else if (selectedDocument && selectedVersion && selectedLanguage) {
          ANALYTICS_SERVICE.getDocumentView(
            selectedDocument.id,
            selectedVersion.id,
            selectedLanguage.title,
            selectedDateRangeData
          )
            .then((result) => {
              setLineChartData(result);

              setLineChartLoading(false);
            })
            .catch((error) => {
              if (Number(error.status) === 504)
                props.enqueueSnackbar(t(`error.gateway-timeout`), {
                  variant: 'error',
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
            });

          ANALYTICS_SERVICE.getDocumentViewByLocation(
            selectedDocument.id,
            selectedVersion.id,
            selectedLanguage.title,
            selectedDateRangeData
          )
            .then((result) => {
              setGeoChartData(result);

              geoTableDataHandler(result);

              setGeoChartLoading(false);
            })
            .catch((error) => {
              if (Number(error.status) === 504)
                props.enqueueSnackbar(t(`error.gateway-timeout`), {
                  variant: 'error',
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
            });

          ANALYTICS_SERVICE.getTopTopicsByDocument(
            selectedDocument.id,
            selectedVersion.id,
            selectedDateRangeData,
            selectedLanguage.title
          )
            .then((result) => {
              setTopTitleTableData(result);
              setGeoChartLoading(false);
            })
            .catch((error) => {
              if (Number(error.status) === 504)
                props.enqueueSnackbar(t(`error.gateway-timeout`), {
                  variant: 'error',
                  style: { whiteSpace: 'pre-line' },
                  preventDuplicate: true,
                });
            });
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedVersion, selectedLanguage, selectedTopic, selectedDateRangeData, selectedDocument]);

  const handleDateRenge = async (newValue: string) => {
    let tempSelectedDate = '';
    const now = getNowDate();

    switch (FORMAT_DATE.find((it) => it.id === Number(newValue))?.title) {
      case 'Last Week':
        tempSelectedDate = `startDate=${getLastWeekDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[0]);

        break;

      case 'Last Month':
        tempSelectedDate = `startDate=${getLastMonthDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[1]);

        break;
      case 'Last Year':
        tempSelectedDate = `startDate=${getLastYearDate()}&endDate=${now}`;
        setSelectedDateTitle(FORMAT_DATE[2]);

        break;
      case 'Custom':
        setIsCustomDatePicker(true);
        break;
    }

    return tempSelectedDate;
  };

  const handleSelectedDocument = async (newValue: string) => {
    let tempDoc = selectedAccountDocuments.filter((x) => x.id === newValue)[0];
    const documentLanguages = [{ id: 0, title: 'ALL' }];

    setSelectedVersion(null);
    setSelectedTopic(null);

    if (isAccount && Number(newValue) !== 0) {
      setSelectedDoc(tempDoc);

      const docLanguages = tempDoc.languages.map((x) => {
        return { id: x.languageCode, title: x.languageCode };
      });

      documentLanguages.push(...docLanguages);

      history.push(`/document/${selectedAccount.id}/${tempDoc.id}/view-analytics`);
    } else if (!isAccount && Number(newValue) === 0) {
      setSelectedDoc(selectedAccountDocuments[1]);
      tempDoc = selectedAccountDocuments[0];

      history.push('/org/view-analytics');
    } else {
      setSelectedDoc(tempDoc);
      const docLanguages = tempDoc.languages.map((x) => {
        return { id: x.languageCode, title: x.languageCode };
      });

      documentLanguages.push(...docLanguages);

      history.push(`/document/${selectedAccount.id}/${tempDoc.id}/view-analytics`);
    }

    setLanguages(documentLanguages);
    setSelectedLanguage(documentLanguages[0]);

    return tempDoc;
  };

  const handleCustomDate = (startDate, endDate) => {
    const formattedDateStart = formatDatePickerValue(startDate);
    const formattedDateEnd = formatDatePickerValue(endDate);
    setSelectedDateRange(`startDate=${formattedDateStart}&endDate=${formattedDateEnd}`);
  };

  const handleChangeSelect = async (element: string, newValue: string) => {
    setLineChartLoading(true);
    setGeoChartLoading(true);

    const actions = {
      'Document Version': () => setSelectedVersion(versions.filter((x) => x.id === newValue)[0]),
      Topic: () => setSelectedTopic(topicsPerLanguageVersion.filter((x) => x.id === newValue)[0]),
      Languages: () => setSelectedLanguage(languages.filter((x) => x.id === newValue)[0]),
      'Date Range': async () => setSelectedDateRange(await handleDateRenge(newValue)),
      Documents: async () => setSelectedDocument(await handleSelectedDocument(newValue)),
    };

    actions[element]?.();
  };

  const handleCloseCustomRange = () => {
    const now = getNowDate();
    setSelectedDateRange(`startDate=${getLastMonthDate()}&endDate=${now}`);
    setSelectedDateTitle(FORMAT_DATE[1]);

    setIsCustomDatePicker(false);
  };

  const renderSelect = (element: string, selectedElement, items: any[]) => {
    return items.length > 0 ? (
      <SelectInput
        field={element}
        label={t(element)}
        showError={false}
        onChange={(value) => handleChangeSelect(element, value)}
        value={selectedElement ? selectedElement.id : items[0].id}
        showSearch={true}
      >
        {items &&
          items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          ))}
      </SelectInput>
    ) : null;
  };

  return (
    <div
      style={{
        marginLeft: 80,
        marginRight: 80,
        marginTop: 30,
        marginBottom: 30,
      }}
    >
      {((selectedAccount && isAccount) || (!isAccount && selectedDocument && selectedLanguage && selectedVersion)) && (
        <Breadcrumbs style={{ marginBottom: 10 }}>
          <Link
            color={'textPrimary'}
            style={{ fontSize: 'small', cursor: 'pointer' }}
            to={{
              pathname: '/',
              state: {
                accountSlug: selectedAccount.slug,
              },
            }}
          >
            {' '}
            {selectedAccount.title}
          </Link>
          {!isAccount ? (
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedAccount.id}/${selectedDocument.id}/content`}
            >
              {selectedDocument.title}
            </Link>
          ) : (
            <Link color={'textPrimary'} style={{ fontSize: 'small', cursor: 'pointer' }} to={`/org/view-analytics`}>
              View Metrics
            </Link>
          )}
          {!isAccount ? (
            <Link
              color={'textPrimary'}
              style={{ fontSize: 'small', cursor: 'pointer' }}
              to={`/document/${selectedAccount.id}/${selectedDocument.id}/view-analytics`}
            >
              View Metrics
            </Link>
          ) : null}
        </Breadcrumbs>
      )}

      <h3>{isAccount ? t('org-analytics') : t('doc-analytics')}</h3>

      <span
        style={{
          color: 'balck',
          fontSize: '14px',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          marginLeft: '5px',
          marginBottom: '14px',
        }}
      >
        {t('notice-metrics')}
      </span>

      <div
        style={{
          display: 'flex',
          paddingRight: 10,
          justifyContent: 'center',
          flexDirection: 'row-reverse',
          marginBottom: '10px',
          gap: 10,
        }}
      >
        {selectedLanguage &&
          (isCustomDatePicker ? (
            <div
              className="container-box-shape-sm"
              style={{
                width: '500px',
                height: '80px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#eef6fc',
              }}
            >
              <RangePicker
                format="YYYY-MM-DD"
                onChange={(dates) => {
                  handleCustomDate(dates ? dates[0] : null, dates ? dates[1] : null);
                }}
              />

              <div style={{ height: '100%', display: 'flex', alignItems: 'start' }}>
                <CloseCircleOutlined style={{ width: '20px', color: 'red' }} onClick={() => handleCloseCustomRange()} />
              </div>
            </div>
          ) : (
            <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
              {renderSelect('Date Range', selectedDateRangeTitle, FORMAT_DATE)}
            </div>
          ))}

        {!isAccount && selectedTopic && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Topic', selectedTopic, topicsPerLanguageVersion)}
          </div>
        )}

        {selectedLanguage && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Languages', selectedLanguage, languages)}
          </div>
        )}

        {!isAccount && selectedVersion && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Document Version', selectedVersion, versions)}
          </div>
        )}

        {selectedDocument && (
          <div style={{ width: '250px', minWidth: 250, height: '100%' }}>
            {renderSelect('Documents', selectedDocument, selectedAccountDocuments)}
          </div>
        )}
      </div>
      {loading || lineChartLoading || geoChartLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: 30,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Title className="item-title" level={4}>
            {t('chart-table-analytics')}
          </Title>
          <LineChart chartData={lineChartData} />

          <Divider />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 5,
            }}
          >
            <Title className="item-title" level={4}>
              {t('country-table-analytics')}
            </Title>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginBottom: 5,
              }}
            >
              <GeoChart chartData={geoChartData} length={geoChartData?.length} />
              <Table
                style={{ width: '25%' }}
                pagination={geoTableData?.length > 5 ? { pageSize: 5 } : false}
                dataSource={geoTableData}
                columns={GEO_TABLE_COLUMNS}
              />
            </div>
          </div>

          <Divider />

          <div style={{ marginBottom: 3 }}>
            <Title className="item-title" level={4}>
              {t('topic-table-analytics')}
            </Title>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Table
                style={{ width: '40%' }}
                dataSource={topTitleTableData}
                columns={TOPIC_TABLE_COLUMNS}
                pagination={topTitleTableData?.length > 5 ? { pageSize: 5 } : false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(ViewAnalytics);
