import { z } from 'zod';

const TokenDataSchema = z.object({
  code: z.string(),
});


type TokenDataSchema = z.infer<typeof TokenDataSchema>;

export { TokenDataSchema };
