import React, { useEffect, useState, Key } from 'react';
import { Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useTranslation } from 'react-i18next';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';

export type TopicType = {
  topicId: string;
  topicSlug: string;
  topicTitle: string;
  publishedTopicVersionId: string;
  action: string;
  previousPublishedTopicVersionId: string;
};

export type SelectedTopicType = {
  topicId: string;
  topicSlug: string;
  publishedTopicVersionId: string;
  previousPublishedTopicVersionId: string;
};

type PublishRequestsHistoryTopicListPropsType = {
  topics: TopicType[];
  selectedTopic?: SelectedTopicType;
  getTopicDiff: (selectedTopic: TopicType) => void;
};

const PublishRequestsHistoryTopicList = ({
  topics,
  selectedTopic,
  getTopicDiff,
}: PublishRequestsHistoryTopicListPropsType) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedTopic) {
      setSelectedKeys([selectedTopic.topicId]);
    }
  }, [selectedTopic]);

  const renderTopicStatus = (topic: TopicType) => {
    if (!topic.previousPublishedTopicVersionId) {
      return (
        <IconButton aria-label="Added" size="small">
          <Tooltip title="Added" placement="top">
            <SvgIcon style={{ color: 'green', width: 20 }}>
              <path d="M9 9h6v6H9z"></path>
              <path d="M19 17V7c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2zM7 7h10l.002 10H7V7z"></path>
            </SvgIcon>
          </Tooltip>
        </IconButton>
      );
    }

    if (!topic.publishedTopicVersionId) {
      return (
        <IconButton aria-label="Deleted topic" size="small">
          <Tooltip title="Deleted" placement="top">
            <SvgIcon style={{ color: 'red', width: 20 }}>
              <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM6 7h4a1 1 0 0 1 .117 1.993L10 9H6a1 1 0 0 1-.117-1.993L6 7h4-4z"></path>
            </SvgIcon>
          </Tooltip>
        </IconButton>
      );
    }

    return (
      <IconButton aria-label="Modified" size="small">
        <Tooltip title="Modified" placement="top">
          <SvgIcon
            style={{
              color: '#fc9403',
              height: 25,
              width: 25,
              marginTop: 8,
              marginRight: '-6px',
            }}
          >
            <path d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 2H4v8h8V4zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path>
          </SvgIcon>
        </Tooltip>
      </IconButton>
    );
  };

  useEffect(() => {
    if (topics) {
      const BASE_TOPIC_TREE_ITEM_STYLES = {
        padding: '0 5px',
        borderBottom: '1px solid #cacaca',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };

      setTreeData(
        topics.map((topic) => ({
          title: (
            <div
              style={
                selectedKeys[0] === topic.topicId
                  ? {
                      ...BASE_TOPIC_TREE_ITEM_STYLES,
                      backgroundColor: '#1976d2',
                      color: '#fff',
                    }
                  : topic.topicTitle
                    ? BASE_TOPIC_TREE_ITEM_STYLES
                    : {
                        ...BASE_TOPIC_TREE_ITEM_STYLES,
                        color: 'red',
                      }
              }
            >
              {topic.topicTitle || t('deleted-topic')}

              {renderTopicStatus(topic)}
            </div>
          ),
          key: topic.topicId,
        }))
      );
    }
  }, [topics, selectedKeys]);

  const onSelect = (selectedKeysValue: Key[]) => {
    const selectedTopicId = selectedKeysValue[0] as string;
    const selectedTopic = topics.find(
      (topic) => topic.topicId === selectedTopicId
    );

    if (selectedTopic) getTopicDiff(selectedTopic);
  };

  if (treeData?.length) {
    return (
      <Tree
        defaultSelectedKeys={selectedKeys}
        onSelect={onSelect}
        treeData={treeData}
        blockNode
      />
    );
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
      }}
    >
      {t('noTopics')}
    </div>
  );
};

export default PublishRequestsHistoryTopicList;
