import { redirectToLoginPage } from '../../helpers/route/private';

import { setupCache } from 'axios-cache-adapter';
import { AxiosResponse, AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require('axios').default;

const cache = setupCache({
  maxAge: 20 * 60 * 1000,
  exclude: { query: false },
});

const api = axios.create({
  adapter: cache.adapter,
  headers: {
    Authorization: `Bearer ${localStorage.auth_token}`,
    'source-app': 'editor',
  },
});

export class baseWebApi {
  private headers: Record<string, string>;

  constructor() {
    this.headers = {
      Authorization: `Bearer ${localStorage.auth_token}`,
      'source-app': 'editor',
    };
  }

  fetchWithHandleErrors(
    method: string,
    path: string,
    data: FormData | string | undefined,
    header: string | undefined = undefined
  ) {
    let req;
    if (method === 'get' || method === 'delete')
      req = window.fetch(`${localStorage.webApiAddress}${path}`, {
        method: method,
        headers: this.headers,
      });
    else {
      if (header !== undefined) {
        req = window.fetch(`${localStorage.webApiAddress}${path}`, {
          method: method,
          body: data,
          headers: this.headers,
        });
      } else {
        req = window.fetch(`${localStorage.webApiAddress}${path}`, {
          method: method,
          body: data,
          headers: {
            'Content-Type': 'application/json',
            ...this.headers,
          },
        });
      }
    }
    return req
      .then((response: Response) => {
        if (response.ok) return response;
        else throw response;
      })
      .catch((error) => {
        throw error;
      });
  }

  sendRequest(
    method: string,
    path: string,
    data: FormData | string | undefined = undefined,
    header: string | undefined = undefined
  ) {
    return this.fetchWithHandleErrors(method, path, data, header)
      .then((res: Response) => {
        return res;
      })
      .catch(async (error) => {
        let errorCode: number = error.status;
        if (errorCode === 500) {
          error.headers.forEach(function (val, key) {
            if (key === 'log_id') console.log(key + ' -> ' + val);
          });
        }
        if (
          errorCode !== 401 &&
          errorCode >= 399 &&
          errorCode < 500 &&
          errorCode !== 404
        ) {
          errorCode = 400;
        }
        switch (errorCode) {
          case 401:
            redirectToLoginPage();
            break;
          default:
            throw error;
        }
      });
  }

  getWithCache(path: string) {
    return api
      .get(`${localStorage.webApiAddress}${path}`)
      .then(function (response: AxiosResponse) {
        return response;
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response?.status;
        if (errorCode === 500 && error.response?.headers) {
          for (const key in error.response?.headers) {
            if (key === 'log_id')
              console.log(key + ' -> ' + error.response?.headers[key]);
          }
        }
        // eslint-disable-next-line
        switch (errorCode) {
          case 401:
            redirectToLoginPage();
            break;
        }
        throw error;
      });
  }
}
