import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateAccountDefaultLanguageRequest } from '../../api-schemas/account';

const updateAccountDefaultLanguageRequest = (
  data: UpdateAccountDefaultLanguageRequest
) => {
  const body = JSON.stringify(data);

  return axios
    .put('/api/Account/DefaultLanguage', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useUpdateAccountDefaultLanguage(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAccountDefaultLanguage,
    isPending: isUpdatingAccountDefaultLanguage,
    isSuccess: isSuccessUpdatingAccountDefaultLanguage,
  } = useMutation({
    mutationFn: updateAccountDefaultLanguageRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['account', accountId],
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });
    },
  });

  return {
    updateAccountDefaultLanguage,
    isUpdatingAccountDefaultLanguage,
    isSuccessUpdatingAccountDefaultLanguage,
  };
}
