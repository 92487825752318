import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import ISO6391 from 'iso-639-1';
import { List, ListItem, ListItemText } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { useSnackbar } from 'notistack';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

import PublishRequestsHistorySkeletonLoading from './skeleton-loading';
import PublishRequestHistoryItem from './publish-request-history-item';
import { DocumentService } from '../../../../services/document';
import { DocumentContext } from '../../../../contexts/document';
import useGetPublishRequests from '../../../../api-hooks/publish-request/useGetPublishRequests';
import { sleep } from '../../../../helpers/common';

const documentService = new DocumentService();

const PAGE_SIZE: number = 15;

let hasMore: boolean = true;

const PublishRequestsHistoryList = ({ getPublishRequestsDiff }) => {
  const { documentId }: { documentId: string } = useParams();

  const { selectedDoc, setSelectedDoc } = useContext(DocumentContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const pageIndex = useRef<number>(0);

  const [selectedPublishRequest, setSelectedPublishRequest] = useState<any>();
  const [
    selectedPublishRequestsToCompare,
    setSelectedPublishRequestsToCompare,
  ] = useState<{
    sourcePublishRequest: any;
    targetPublishRequest: any;
  }>({
    sourcePublishRequest: null,
    targetPublishRequest: null,
  });
  const [publishedPublishRequests, setPublishedPublishRequests] = useState<
    any[]
  >([]);
  const [publishRequestIdsToShowTimeLine, setPublishRequestIdsToShowTimeLine] =
    useState<string[]>();
  const [hoveredPublishRequest, setHoveredPublishRequest] = useState<any>(null);
  const [loadingPublishRequests, setLoadingPublishRequests] =
    useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<{
    languageCode: string;
    label: string;
  }>();
  const [languageItems, setLanguageItems] = useState<MenuProps['items']>([]);
  const [status, setStatus] = useState<string>('Published');

  const { getPublishRequests } = useGetPublishRequests({
    documentId,
    status,
    pageIndex: pageIndex.current,
    pageSize: PAGE_SIZE,
    languageCode:
      selectedLanguage?.languageCode === 'all'
        ? null
        : selectedLanguage?.languageCode,
    enabled: false,
  });

  const getDocument = () => {
    setLoadingPublishRequests(true);

    documentService
      .getDocument(documentId)
      .then((doc) => {
        setSelectedDoc(doc);
        setLoadingPublishRequests(false);
      })
      .catch(() => {
        enqueueSnackbar(t(`error.getDocument`), {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          preventDuplicate: true,
        });
      });
  };

  const getPublishedPublishRequests = async (previousPublishedRequests) => {
    if (!loadingPublishRequests && hasMore) {
      setLoadingPublishRequests(true);
      setStatus('Published');

      await sleep(500);

      getPublishRequests()
        .then((result) => {
          const publishRequests = result.data;

          if (publishRequests) {
            hasMore = publishRequests.length >= PAGE_SIZE;

            setPublishedPublishRequests([
              ...previousPublishedRequests,
              ...publishRequests,
            ]);
            setLoadingPublishRequests(false);

            pageIndex.current++;
          }
        })
        .catch(() => {
          enqueueSnackbar(t(`error.getPublishRequests`), {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          });

          hasMore = false;

          setLoadingPublishRequests(false);
        });
    }
  };

  useEffect(() => {
    if (!selectedDoc) getDocument();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedDoc) {
      setSelectedLanguage({
        languageCode: selectedDoc.defaultLanguageCode,
        label: ISO6391.getName(selectedDoc.defaultLanguageCode),
      });

      setLanguageItems([
        ...selectedDoc.languages.map((lang) => ({
          label: ISO6391.getName(lang.languageCode),
          key: lang.languageCode,
        })),
        {
          label: 'All',
          key: 'all',
        },
      ]);
    }
  }, [selectedDoc]);

  useEffect(() => {
    if (selectedLanguage) {
      pageIndex.current = 0;
      hasMore = true;

      setPublishedPublishRequests([]);
      setLoadingPublishRequests(false);

      getPublishedPublishRequests([]);
    }
    // eslint-disable-next-line
  }, [selectedLanguage]);

  const handleSelectedPublishRequest = (publishRequest) => {
    setSelectedPublishRequest(publishRequest);

    setSelectedPublishRequestsToCompare({
      sourcePublishRequest: null,
      targetPublishRequest: publishRequest,
    });

    getPublishRequestsDiff(
      {
        sourcePublishRequest: publishRequest,
        targetPublishRequest: publishRequest,
      },
      selectedLanguage?.languageCode === 'all'
        ? null
        : selectedLanguage?.languageCode
    );
  };

  const handleSelectedPublishRequestsToCompare = (publishRequest) => {
    setSelectedPublishRequest(null);

    if (
      selectedPublishRequestsToCompare.sourcePublishRequest?.id ===
      publishRequest.id
    ) {
      setSelectedPublishRequestsToCompare({
        sourcePublishRequest: null,
        targetPublishRequest:
          selectedPublishRequestsToCompare.targetPublishRequest,
      });

      return;
    }

    if (
      selectedPublishRequestsToCompare.targetPublishRequest?.id ===
      publishRequest.id
    ) {
      setSelectedPublishRequestsToCompare({
        sourcePublishRequest:
          selectedPublishRequestsToCompare.sourcePublishRequest,
        targetPublishRequest: null,
      });

      return;
    }

    if (!selectedPublishRequestsToCompare.targetPublishRequest) {
      setSelectedPublishRequestsToCompare({
        sourcePublishRequest: null,
        targetPublishRequest: publishRequest,
      });

      return;
    }

    const targetPublishRequest =
      selectedPublishRequestsToCompare.targetPublishRequest;
    const isTargetPublishRequest = moment(
      publishRequest.statusChangedDateTime
    ).isAfter(targetPublishRequest.statusChangedDateTime);

    let publishRequestsToCompare: any = {};

    if (isTargetPublishRequest) {
      publishRequestsToCompare = {
        sourcePublishRequest: targetPublishRequest,
        targetPublishRequest: publishRequest,
      };
    } else {
      publishRequestsToCompare = {
        sourcePublishRequest: publishRequest,
        targetPublishRequest: targetPublishRequest,
      };
    }

    setSelectedPublishRequestsToCompare(publishRequestsToCompare);

    getPublishRequestsDiff(
      publishRequestsToCompare,
      selectedLanguage?.languageCode === 'all'
        ? null
        : selectedLanguage?.languageCode
    );
  };

  const handlePublishRequestTimeIconHover = (publishRequest) => {
    setHoveredPublishRequest(publishRequest);

    if (publishRequest === null) {
      setPublishRequestIdsToShowTimeLine([]);

      return;
    }

    const isTargetElement = moment(
      publishRequest.statusChangedDateTime
    ).isAfter(
      moment(
        selectedPublishRequestsToCompare.targetPublishRequest
          .statusChangedDateTime
      )
    );

    const publishRequestsToShowTimeLine = publishedPublishRequests.filter(
      (pr) =>
        (isTargetElement &&
          moment(pr.statusChangedDateTime).isBefore(
            moment(publishRequest.statusChangedDateTime)
          ) &&
          moment(pr.statusChangedDateTime).isAfter(
            moment(
              selectedPublishRequestsToCompare.targetPublishRequest
                .statusChangedDateTime
            )
          )) ||
        (!isTargetElement &&
          moment(pr.statusChangedDateTime).isAfter(
            moment(publishRequest.statusChangedDateTime)
          ) &&
          moment(pr.statusChangedDateTime).isBefore(
            moment(
              selectedPublishRequestsToCompare.targetPublishRequest
                .statusChangedDateTime
            )
          ))
    );

    setPublishRequestIdsToShowTimeLine(
      publishRequestsToShowTimeLine.map((pr) => pr.id)
    );
  };

  const isInCompareRange = (publishRequest) => {
    const sourcePublishRequestIndex = publishedPublishRequests.findIndex(
      (pr) =>
        pr.id === selectedPublishRequestsToCompare.sourcePublishRequest?.id
    );

    if (sourcePublishRequestIndex < 0) {
      return false;
    }

    const targetPublishRequestIndex = publishedPublishRequests.findIndex(
      (pr) =>
        pr.id === selectedPublishRequestsToCompare.targetPublishRequest?.id
    );

    if (targetPublishRequestIndex < 0) {
      return false;
    }

    const publishRequestIndex = publishedPublishRequests.findIndex(
      (pr) => pr.id === publishRequest.id
    );

    return (
      publishRequestIndex < sourcePublishRequestIndex &&
      publishRequestIndex > targetPublishRequestIndex
    );
  };

  const handleLanguageClick: MenuProps['onClick'] = (e) => {
    const languageCode = e.key;

    setSelectedLanguage({
      languageCode,
      label: languageCode === 'all' ? 'All' : ISO6391.getName(languageCode),
    });
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '2px solid #cacaca',
      }}
    >
      <div
        style={{
          margin: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{t('publishes')}</span>

        <Dropdown
          menu={{
            items: languageItems,
            selectable: true,
            defaultSelectedKeys: selectedLanguage
              ? [selectedLanguage.languageCode]
              : [],
            onClick: handleLanguageClick,
          }}
        >
          <Button
            style={{
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {selectedLanguage?.label ?? ''}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>

      <List style={{ paddingTop: 0 }}>
        {publishedPublishRequests && publishedPublishRequests.length > 0 ? (
          <div
            style={{
              height: 'calc(100vh - 120px)',
              overflow: 'auto',
              borderTop: '1px solid #cacaca',
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                getPublishedPublishRequests(publishedPublishRequests)
              }
              hasMore={hasMore}
              loader={
                <span style={{ width: '100%', height: '500px' }}>
                  <PublishRequestsHistorySkeletonLoading />
                </span>
              }
              useWindow={false}
            >
              {publishedPublishRequests?.map((publishRequest) => (
                <PublishRequestHistoryItem
                  key={publishRequest.id}
                  publishRequest={publishRequest}
                  isSelected={selectedPublishRequest?.id === publishRequest.id}
                  isInCompareRange={
                    isInCompareRange(publishRequest) ||
                    Boolean(
                      publishRequestIdsToShowTimeLine?.includes(
                        publishRequest.id
                      )
                    )
                  }
                  isAnyPublishRequestSelected={
                    !!selectedPublishRequestsToCompare.sourcePublishRequest ||
                    !!selectedPublishRequestsToCompare.targetPublishRequest
                  }
                  isSelectedInCompare={
                    publishRequest.id ===
                      selectedPublishRequestsToCompare.sourcePublishRequest
                        ?.id ||
                    publishRequest.id ===
                      selectedPublishRequestsToCompare.targetPublishRequest?.id
                  }
                  selectedPublishRequestsToCompare={
                    selectedPublishRequestsToCompare
                  }
                  hoveredPublishRequest={hoveredPublishRequest}
                  handleSelectedPublishRequest={handleSelectedPublishRequest}
                  handleSelectedPublishRequestsToCompare={
                    handleSelectedPublishRequestsToCompare
                  }
                  handlePublishRequestTimeIconHover={
                    handlePublishRequestTimeIconHover
                  }
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : loadingPublishRequests ? (
          <PublishRequestsHistorySkeletonLoading />
        ) : (
          <span style={{ marginTop: '15px' }}>
            <ListItem style={{ height: '70px' }}>
              <ListItemText primary={'No Published item found'} />
            </ListItem>
          </span>
        )}
      </List>
    </div>
  );
};

export default PublishRequestsHistoryList;
