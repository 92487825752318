import React from 'react';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseSquareOutlined,
  CheckSquareOutlined,
  FileTextOutlined,
  PictureOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import { TopicLinksData } from '../../../api-schemas/reference-links';

import './styles.css';

type TopicRefrenceProps = {
  data: TopicLinksData;
};

type DataSourceWithRow = TopicLinksData[0] & { key: number; Url: string };

const CrossTopicRefrence = ({ data }: TopicRefrenceProps) => {
  const { t } = useTranslation();
  const { hash: selectedSideSection } = useLocation();

  const TABLE_COLUMNS = [
    {
      title: t('title'),
      dataIndex: 'Url',
      key: 'Url',
    },
    {
      title: t('link-type'),
      dataIndex: 'TargetType',
      key: 'TargetType',
      render: (text) =>
        text == 'IMAGE' ? (
          <Tooltip
            title={t<string>('image')}
            style={{ margin: '5px 20px 0 0' }}
          >
            <PictureOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ) : text == 'URL' ? (
          <Tooltip title={t<string>('url')} style={{ margin: '5px 20px 0 0' }}>
            <LinkOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ) : (
          <Tooltip
            title={t<string>('topic-link')}
            style={{ margin: '5px 20px 0 0' }}
          >
            <FileTextOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
        ),
    },
    {
      title: t('status'),
      dataIndex: 'IsValid',
      key: 'IsValid',
      render: (text) =>
        text ? (
          <CheckSquareOutlined style={{ color: 'green', fontSize: '22px' }} />
        ) : (
          <CloseSquareOutlined style={{ color: 'red', fontSize: '22px' }} />
        ),
    },
  ];

  const filteredColumns = selectedSideSection
    ? TABLE_COLUMNS.filter((col) => col.dataIndex !== 'TargetType')
    : TABLE_COLUMNS;

  const dataSourceWithRow: DataSourceWithRow[] = data.map((item, index) => ({
    ...item,
    key: index,
    Url: item.TargetType === 'TOPIC_LINK' ? item.Header || '' : item.Url || '',
  }));

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 5px 15px 5px',
        }}
      >
        <Table
          style={{ width: '90%' }}
          dataSource={dataSourceWithRow}
          columns={filteredColumns}
          pagination={dataSourceWithRow.length > 5 ? { pageSize: 5 } : false}
        />
      </div>
    </>
  );
};

export default CrossTopicRefrence;
