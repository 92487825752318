import React, { useEffect, useState } from 'react';
import config from 'react-global-configuration';
import { ConfigProvider } from "antd";

import Pages from './pages';
import './config';
import './helpers/firebase';
import { handleClarityEvents } from './helpers/clarity';
import useGetProfile from './api-hooks/user/useGetUserProfile';

import './App.css';

declare global {
  interface Window {
    componentsData: any[];
    updateComponentDataFunctions: any[];
    clarity: (event: string, key: string, value: string) => void;
  }
}

function App() {
  const [isCrispReady, setIsCrispReady] = useState(false);

  const { profile } = useGetProfile();

  useEffect(() => {
    window.updateComponentDataFunctions = [];
    localStorage.environment = 'editor';

    // init tag manager
    const head = document.getElementsByTagName('head')[0];
    const headScript = document.createElement('script');
    headScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.get('GTM_ID')}');
    `;
    head.insertBefore(headScript, head.firstChild);
    const body = document.getElementsByTagName('body')[0];
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${config.get(
        'GTM_ID'
      )}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    body.insertBefore(bodyNoScript, body.firstChild);
  }, []);

  useEffect(() => {
    window.CRISP_READY_TRIGGER = () => {
      setIsCrispReady(true);
    };

    return () => {
      window.CRISP_READY_TRIGGER = undefined;
    };
  }, []);

  useEffect(() => {
    if (profile) {
      if (isCrispReady && profile.email && window.$crisp) {
        window.$crisp.push(['set', 'user:email', profile.email]);
        window.$crisp.push(['set', 'user:nickname', String(profile.id)]); 
      }
      handleClarityEvents(profile?.id);
    }
  }, [isCrispReady, profile]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeightStrong: 500
        }
      }}
    >
      <Pages />
    </ConfigProvider>
  );
}

export default App;
