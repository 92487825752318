import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Col, Radio, Row, Tooltip as AntdTooltip } from 'antd';
import CompareIcon from '@mui/icons-material/Compare';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import EditIcon from '@mui/icons-material/Edit';

import { DocumentContext } from '../../../../contexts/document';
import { useHistory, useLocation } from 'react-router-dom';
import CommentsBox from '../../../comment/comments-box';
import { topicService } from '../../../../services/topic';
import TopicTreeSelect from '../../../topic/tree/select';
import { htmlDecode } from '../../../../helpers/common';

function ChangesTab(props) {
  const defaultDiffInfo = {
    diffHtmlContent: '<p></p>',
    newTopicParentId: null,
    oldTopicParentId: null,
    oldTopicSlug: null,
    oldTopicId: null,
    newTopicSlug: null,
    newTopicId: null,
  };
  const isPublished: boolean = props.publish.status === 'Published';

  const [selectedTopicId, setSelectedTopicId] = useState<string | undefined>(
    undefined
  );
  const [selectedTopic, setSelectedTopic] = useState<any>(undefined);
  const [filteredTopics, setFilteredTopics] = useState<any[]>([]);
  const [checkedNodeIds, setCheckedNodeIds] = useState<string[]>([]);
  const [checkedTopics, setCheckedTopics] = useState<any[]>([]);
  const [diffInfo, setDiffInfo] = useState(defaultDiffInfo);
  const [originalContent, setOriginalContent] = useState<any>(null);
  const [loadingDiff, setLoadingDiff] = useState<boolean>(true);
  const [loadingOriginalContent, setLoadingOriginalContent] =
    useState<boolean>(false);
  const [mode, setMode] = useState<string>('diff');

  const { selectedDoc, versions } = useContext(DocumentContext);

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const topicAPI = new topicService();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (location.state?.topicId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      handleTopicChecked([location.state?.topicId]);
    }
    // eslint-disable-next-line
  }, [location.state]);

  useEffect(() => {
    if (selectedTopicId) {
      const selectedTopic = props.publish.topics.find(
        (item) => item.topicId === selectedTopicId
      );

      const selectedTopicWithDetail = props.topics.find(
        (topic) => topic.id === selectedTopicId
      );

      if (
        (!isPublished &&
          selectedTopicWithDetail &&
          selectedTopicWithDetail.latestTopicVersionId &&
          selectedTopicWithDetail.parentTopicId) ||
        (isPublished && selectedTopic && selectedTopic.publishedTopicVersionId)
      ) {
        setSelectedTopic(selectedTopic || selectedTopicWithDetail);
        setLoadingOriginalContent(true);
        topicAPI
          .getTopicContent(
            !isPublished
              ? selectedTopicWithDetail.latestTopicVersionId
              : selectedTopic.publishedTopicVersionId
          )
          .then((response) => {
            setLoadingOriginalContent(false);
            setOriginalContent(response);
            if (response.contentType !== 'Component') {
              setLoadingDiff(true);
              topicAPI
                .getDiff(
                  !isPublished
                    ? selectedTopicWithDetail.publishedTopicVersionId
                    : selectedTopic.previousPublishedTopicVersionId,
                  !isPublished
                    ? selectedTopicWithDetail.latestTopicVersionId
                    : selectedTopic.publishedTopicVersionId
                )
                .then((response) => {
                  setDiffInfo(response);
                })
                .catch(() => {
                  props.enqueueSnackbar(t(`error.getDiff`), {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' },
                    preventDuplicate: true,
                  });
                });
            }
          })
          .catch(() => {
            props.enqueueSnackbar(t(`error.unknown`), {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              preventDuplicate: true,
            });
          });
      }
    }
    // eslint-disable-next-line
  }, [selectedTopicId]);

  useEffect(() => {
    if (props.topics.length > 0) {
      const topicsOfPublishRequest: any[] = [];
      props.publish.topics.map((topic) => {
        const foundTopic = props.topics.find(
          (item) => item.topicId === topic.topicId
        );
        if (foundTopic) topicsOfPublishRequest.push(foundTopic);
        return true;
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!location.state?.topicId) {
        const filteredTopics = props.topics.filter(
          (topic) => topic.parentTopicId
        );
        setSelectedTopicId(
          props.publish.topics.length
            ? props.publish.topics[0].topicId
            : filteredTopics.length
              ? filteredTopics[0].topicId
              : null
        );
        setCheckedTopics(topicsOfPublishRequest);
        setCheckedNodeIds(props.publish.topics.map((topic) => topic.topicId));
      }
      filterTopics(topicsOfPublishRequest);
    }
    // eslint-disable-next-line
  }, [props.topics]);

  useEffect(() => {
    if (filteredTopics.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setSelectedTopicId(location.state?.topicId || filteredTopics[0].topicId);
    }
    // eslint-disable-next-line
  }, [filteredTopics]);

  useEffect(() => {
    setLoadingDiff(false);
    // eslint-disable-next-line
  }, [diffInfo]);

  useEffect(() => {
    calculateTopicsStatus();
    // eslint-disable-next-line
  }, [checkedTopics]);

  const handleTopicChecked = (checkedNodeIds: string[]) => {
    const isCheckedAll: string | undefined = checkedNodeIds.find(
      (nodeId) => nodeId === 'checkall'
    );
    let checkedTopics: any[] = [];

    if (isCheckedAll) {
      // --- check all publishable topics
      checkedTopics = props.topics.filter(
        (topic) => !checkPublishEnable(topic) && topic.parentTopicId
      );
      checkedNodeIds = checkedTopics.map((topic) => topic.id);
    } else {
      checkedNodeIds.map((topicId) => {
        const topic = props.topics.find((topic) => topic.id === topicId);
        if (topic) checkedTopics.push(topic);
        return true;
      });
    }
    props.setPublish({ ...props.publish, topicIds: checkedNodeIds });
    setCheckedTopics(checkedTopics);
  };

  const shortenTitle = (
    title: string,
    length: number,
    color: string | null
  ) => {
    let newTitle: string | null = '';
    if (title.length < length) newTitle = htmlDecode(title);
    else newTitle = htmlDecode(title.substring(0, length - 1) + '...');
    if (title.length < length)
      return (
        // <Tooltip title={title}>
        <span
          className={
            color
              ? color === 'green'
                ? 'diff-html-added'
                : 'diff-html-removed'
              : ''
          }
        >
          {newTitle}
        </span>
      );
    else
      return (
        <Tooltip title={title}>
          <span
            className={
              color
                ? color === 'green'
                  ? 'diff-html-added'
                  : 'diff-html-removed'
                : ''
            }
          >
            {newTitle}
          </span>
        </Tooltip>
      );
  };

  const calculateTopicsStatus = () => {
    setCheckedNodeIds(checkedTopics.map((topic) => topic.topicId));
    let deletedTopics, notPublishedYetTopics, modifiedTopics;
    if (isPublished) {
      deletedTopics = props.publish.topics.filter(
        (item) => item.action === 'Deleted'
      );
      notPublishedYetTopics = props.publish.topics.filter(
        (item) =>
          item.action === 'Created' &&
          item.previousPublishedTopicVersionId === null
      );
      modifiedTopics = props.publish.topics.filter(
        (item) =>
          item.action === 'Created' &&
          item.previousPublishedTopicVersionId !== null
      );
    } else {
      deletedTopics = checkedTopics.filter(
        (topic) => topic.action === 'Deleted'
      );
      notPublishedYetTopics = checkedTopics.filter(
        (topic) =>
          topic.topicVersionNumber < 1.0 && topic.parentTopicId !== null
      );
      modifiedTopics = checkedTopics.filter(
        (topic) =>
          topic.latestTopicVersionId !== topic.publishedTopicVersionId &&
          topic.action !== 'Deleted' &&
          topic.topicVersionNumber >= 1.0
      );
    }

    props.setDeletedTopics(deletedTopics);
    props.setNotPublishedYetTopics(notPublishedYetTopics);
    props.setModifiedTopics(modifiedTopics);
  };

  const filterTopics = async (topicList) => {
    const filteredTopics: any[] = [];
    const parentTopics: any[] = [...topicList];

    do {
      if (
        parentTopics[0] &&
        parentTopics[0].parentTopicId && // check if it is not home topic
        filteredTopics.indexOf(
          (topic) => topic.topicId === parentTopics[0].topicId
        ) === -1
      ) {
        const parent = props.topics.find(
          (item) => item.topicId === parentTopics[0].parentTopicId
        );

        filteredTopics.push(parentTopics[0]);
        if (parent) parentTopics.push(parent);
      }
      parentTopics.splice(0, 1);
    } while (parentTopics.length > 0);
    const homeTopic = props.topics.find(
      (topic) => topic.parentTopicId === null
    );
    filteredTopics.push(homeTopic);
    setFilteredTopics([...Array.from(new Set(filteredTopics))]);
  };

  const checkPublishEnable = (node) => {
    const isLanguageEqual: boolean = node.title !== null;
    const notPublishedYet: boolean = node.topicVersionNumber < 1.0;
    const isAlreadyPublished: boolean =
      node.latestTopicVersionId === node.publishedTopicVersionId &&
      isLanguageEqual;
    return !(
      (notPublishedYet || !isAlreadyPublished || node.action === 'Deleted') &&
      isLanguageEqual
    );
  };

  const handleChangeMode = (mode: string) => {
    setMode(mode);
  };

  const oldSlug: string | null = diffInfo.oldTopicSlug
    ? diffInfo.oldTopicSlug
    : '';
  const newSlug: string | null = diffInfo.newTopicSlug
    ? diffInfo.newTopicSlug
    : '';
  let oldParent: any = null;
  let newParent: any = null;
  let topicMoved: boolean = false;
  let isFirstVersion: boolean = false;
  if (
    props.topics.length > 0 &&
    (diffInfo.oldTopicParentId || diffInfo.newTopicParentId)
  ) {
    oldParent = props.topics.find(
      (topic) => topic.topicId === diffInfo.oldTopicParentId
    );
    newParent = props.topics.find(
      (topic) => topic.topicId === diffInfo.newTopicParentId
    );
    isFirstVersion = diffInfo.oldTopicParentId === null; // first version does not have oldTopicParentId
    topicMoved = !isFirstVersion
      ? diffInfo.oldTopicParentId !== diffInfo.newTopicParentId
      : false;
  }

  const oldParentTitle = oldParent
    ? oldParent.parentTopicId === null
      ? 'No parent(root)'
      : oldParent.title
    : 'Deleted topic';

  const newParentTitle = newParent
    ? newParent.parentTopicId === null
      ? 'No parent(root)'
      : newParent.title
    : 'Deleted topic';

  const slugChanged: boolean = oldSlug !== newSlug;

  const updatedByUser = props.accesses.find(
    (access) => access.principalId === props.publish.updatedBy
  );

  return (
    <div hidden={props.hidden}>
      <Row style={{ margin: '10px -8px -8px', padding: 8 }}>
        <Col xs={5}>
          {props.publish.status === 'Open' &&
            (props.mode === 'edit' || props.mode === 'create') &&
            props.topics.length > 0 &&
            props.publish &&
            filteredTopics.length && (
              <div
                style={{
                  maxHeight: window.innerHeight - 50,
                  overflow: 'auto',
                }}
              >
                <TopicTreeSelect
                  topics={props.topics}
                  usedForCopy={false}
                  languageCode={props.publish.languageCode}
                  onTopicCheck={handleTopicChecked}
                  selectedNodeId={selectedTopicId}
                  onTopicSelect={(node) => setSelectedTopicId(node.id)}
                  checkedKeys={checkedNodeIds}
                />
              </div>
            )}
          {(props.publish.status !== 'Open' || props.mode === 'view') &&
            props.topics.length > 0 &&
            props.publish &&
            filteredTopics.length && (
              <div
                style={{
                  maxHeight: window.innerHeight - 50,
                  overflow: 'auto',
                }}
              >
                <TopicTreeSelect
                  topics={filteredTopics}
                  usedForCopy={false}
                  viewMode
                  shouldRenderAlreadyPublished={true}
                  languageCode={props.publish.languageCode}
                  onTopicCheck={handleTopicChecked}
                  selectedNodeId={selectedTopicId}
                  selectedTopic={props.topics.find(
                    (topic) => topic.topicId === selectedTopicId
                  )}
                  onTopicSelect={(node) => setSelectedTopicId(node.id)}
                  checkedKeys={checkedNodeIds}
                  expandeNodes={true}
                />
              </div>
            )}
        </Col>
        <Col xs={15} sm={15}>
          <Fragment>
            {!loadingDiff ? (
              <Radio.Group
                style={{ display: 'flex', marginTop: 10 }}
                className="filter-section"
                defaultValue="diff"
                buttonStyle="solid"
                onChange={(event) => handleChangeMode(event.target.value)}
              >
                <AntdTooltip title={t<string>('diff')}>
                  <Radio.Button
                    value="diff"
                    style={{
                      borderBottom: 'none',
                      borderColor: 'rgb(196 196 196)',
                      display: 'flex',
                    }}
                  >
                    <CompareIcon style={{ width: 15 }} />
                  </Radio.Button>
                </AntdTooltip>
                <AntdTooltip title={t<string>('original-content')}>
                  <Radio.Button
                    value="original-content"
                    style={{
                      borderBottom: 'none',
                      borderColor: 'rgb(196 196 196)',
                      display: 'flex',
                    }}
                  >
                    <FormatAlignLeftIcon style={{ width: 15 }} />
                  </Radio.Button>
                </AntdTooltip>
                <AntdTooltip title={t<string>('edit')}>
                  <Radio.Button
                    onClick={() =>
                      history.push(
                        `/document/${selectedDoc.account.id}/${selectedDoc.id}/${diffInfo.newTopicId || originalContent.topicId}/content?lang=${props.publish.languageCode}&ver=${versions?.find((ver) => ver.id === props.publish.documentVersionId)?.slug}`
                      )
                    }
                    value="edit"
                    style={{
                      borderBottom: 'none',
                      borderColor: 'rgb(196 196 196)',
                      display: 'flex',
                    }}
                  >
                    <EditIcon style={{ width: 15 }} />
                  </Radio.Button>
                </AntdTooltip>
              </Radio.Group>
            ) : null}
            {!loadingDiff &&
            mode === 'diff' &&
            originalContent &&
            originalContent.contentType !== 'Component' ? (
              <Paper
                style={{
                  borderRadius: '4px',
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
                variant="outlined"
              >
                <Row style={{ padding: 8 }}>
                  <Col xs={12}>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>{'Slug : '}</span>
                      {slugChanged ? (
                        <Fragment>
                          {shortenTitle(`${oldSlug}`, 50, 'red')}{' '}
                          {shortenTitle(`${newSlug}`, 50, 'green')}
                        </Fragment>
                      ) : (
                        shortenTitle(`${newSlug}`, 40, null)
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>{'Parent : '}</span>
                      {topicMoved ? (
                        <Fragment>
                          {shortenTitle(`${oldParentTitle}`, 20, 'red')}{' '}
                          {shortenTitle(`${newParentTitle}`, 20, 'green')}
                        </Fragment>
                      ) : (
                        shortenTitle(`${newParentTitle}`, 40, null)
                      )}
                    </div>
                  </Col>
                </Row>

                {updatedByUser && (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      <ListItem
                        style={{ paddingTop: '0px', paddingLeft: '0px' }}
                      >
                        <ListItemAvatar style={{ minWidth: '25px' }}>
                          <Avatar
                            style={{
                              width: '25px',
                              height: '25px',
                              marginLeft: '0px',
                              marginRight: '3px',
                            }}
                            src={updatedByUser ? updatedByUser.photoUrl : ''}
                          >
                            {updatedByUser
                              ? updatedByUser.email[0].toUpperCase()
                              : 'U'}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: 'smaller' }}>
                              {updatedByUser
                                ? updatedByUser.email
                                : props.accesses.length > 0
                                  ? 'Deleted access'
                                  : 'Loading...'}
                            </span>
                          }
                        />
                      </ListItem>
                    </span>
                  </div>
                )}
              </Paper>
            ) : !loadingDiff &&
              !loadingOriginalContent &&
              originalContent &&
              originalContent.contentType === 'Component' ? null : (
              <Skeleton
                height={73}
                style={{ marginTop: '10px', padding: '0px' }}
                variant={'rectangular'}
              ></Skeleton>
            )}
            {((!loadingDiff && mode === 'diff') ||
              (!loadingOriginalContent && mode === 'original-content')) &&
            originalContent &&
            originalContent.contentType !== 'Component' ? (
              <div
                id="CKEditorWrapper"
                style={{
                  maxHeight: window.innerHeight - 200,
                  overflow: 'auto',
                  border:
                    mode === 'original-content'
                      ? '1px solid rgb(196 196 196)'
                      : 'none',
                }}
              >
                <p style={{ padding: '20px' }}>
                  {parse(
                    '' +
                      (mode === 'diff'
                        ? diffInfo.diffHtmlContent
                        : originalContent.body) +
                      ''
                  )}
                </p>
              </div>
            ) : originalContent &&
              originalContent.contentType === 'Component' ? (
              <h4 style={{ marginTop: 15 }}>
                Changes for structured data topics are not available yet.
              </h4>
            ) : (
              <Skeleton
                height={window.innerHeight - 100}
                style={{ marginTop: '8px', padding: '0px' }}
                variant={'rectangular'}
              ></Skeleton>
            )}
          </Fragment>
        </Col>
        <Col xs={4}>
          {selectedTopic && (
            <CommentsBox
              fullWidth={false}
              channelId={`${selectedTopic.id || selectedTopic.topicId}-${props.publish.languageCode}`}
              channelType="Topics"
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default withSnackbar(ChangesTab);
