import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Folders } from '../../api-schemas/folder';

const getFoldersRequest = (accountId: string, parentFolderId?: string) => {
  return axios
    .get<Folders>(`/api/Folder`, {
      params: { accountId, parentFolderId },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetFolders(
  accountId: string,
  parentFolderId?: string,
  enabled: boolean = true
) {
  const queryKey = ['folders', accountId];

  if (parentFolderId) {
    queryKey.push(parentFolderId);
  }

  const {
    data,
    refetch: getFolders,
    isFetching: isLoadingFolders,
    isError: isErrorLoadingFolders,
    isSuccess: isSuccessLoadingFolders,
  } = useQuery<Folders>({
    staleTime: 1000 * 60 * 60,
    queryKey,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
    queryFn: () => getFoldersRequest(accountId, parentFolderId),
  });

  const folders = useMemo(() => data || [], [data]);

  return {
    folders,
    isLoadingFolders,
    isErrorLoadingFolders,
    isSuccessLoadingFolders,

    getFolders,
  };
}
