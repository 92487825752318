import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, TextareaAutosize, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Skeleton from '@mui/material/Skeleton';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ConfirmationDialog } from '../../confirmation-dialog';
import useUpdateThread from '../../../api-hooks/comment/useUpdateThread';
import useUpdateComment from '../../../api-hooks/comment/useUpdateComment';
import useDeleteComment from '../../../api-hooks/comment/useDeleteComment';

import './styles.css';

const useStyles: any = makeStyles(() => ({
  deleteIcon: {
    position: 'absolute',
    bottom: 8,
    right: 10,
    cursor: 'pointer',
    color: '#757575',
    width: 17,
    height: 17,
    fontSize: '1.5em !important',
  },
  editIcon: {
    position: 'absolute',
    bottom: 8,
    right: 37,
    cursor: 'pointer',
    color: '#757575',
    width: 17,
    height: 17,
    fontSize: '1.5em !important',
  },
  resolveIcon: {
    position: 'absolute',
    bottom: 8,
    right: 64,
    cursor: 'pointer',
    color: '#757575',
    width: 17,
    height: 17,
    fontSize: '1.5em !important',
  },
  label: {
    fontSize: 12,
    color: '#757575',
    marginBottom: 5,
  },
  textField: {
    border: 'none',
    fontSize: 13,
    width: '100%',
    '&:focus-visible': {
      border: 'none!important',
      outline: 'none!important',
    },
  },
  line: {
    width: 3,
    height: '100%',
    position: 'absolute',
    left: 20.5,
    top: 25,
    backgroundColor: 'rgb(205, 222, 239)',
  },
}));

const CommentBox = ({
  fullWidth,
  showResolveButton,
  thread,
  incomingComment,
  threadId,
  accesses,
  isLast,
  editable,
}) => {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('view');
  const [comment, setComment] = useState<any>(incomingComment);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { updateThread, isUpdatingThread } = useUpdateThread(thread.channelId);
  const { updateComment, isUpdatingComment } = useUpdateComment({
    threadId: thread.id,
    channelId: thread.channelId,
  });
  const { deleteComment, isDeletingComment } = useDeleteComment({
    threadId: thread.id,
    channelId: thread.channelId,
  });

  const findUser = () => {
    const filteredAccesses = accesses.filter(
      (access) => access.principalId === comment.authorId
    );
    if (filteredAccesses.length) {
      setSelectedUser(filteredAccesses[0]);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const commentId = url.searchParams.get('commentId');
    if (commentId && comment && commentId === comment.id) {
      setIsSelected(true);
      (document.getElementById(comment.id) as HTMLElement).scrollIntoView();
      setTimeout(() => {
        setIsSelected(false);
      }, 5000);
    }
  }, [comment]);

  useEffect(() => {
    if (accesses && accesses.length && comment) findUser();
    // eslint-disable-next-line
  }, [accesses, comment]);

  const handledDeleteComment = () => {
    deleteComment({ threadId, commentId: comment.id })
      .then(async () => {
        setOpenDialog(false);
      })
      .catch(() => {
        enqueueSnackbar(t('error.unknown'), {
          variant: 'error',
        });
      });
  };

  const handleChangeCommentContent = (newValue: string) => {
    setComment({ ...comment, content: newValue });
  };

  const handleSaveComment = () => {
    if (
      comment.content !== null &&
      comment.content.split(' ').join('').length === 0
    ) {
      enqueueSnackbar(t('error.empty-comment'), {
        variant: 'error',
      });
      return;
    }
    updateComment({ threadId, data: comment })
      .then(() => {
        setMode('view');
      })
      .catch(() => {
        enqueueSnackbar(t('error.unknown'), {
          variant: 'error',
        });
      });
  };

  const handleResolveThread = () => {
    const newThread = { ...thread, resolved: true };

    updateThread(newThread)
      .then(() => {
        setMode('view');
      })
      .catch(() => {
        enqueueSnackbar(t('error.unknown'), {
          variant: 'error',
        });
      });
  };

  const getRelativeTime = (time) => {
    const createdDate = new Date(time);

    return moment(createdDate).fromNow();
  };

  const getMetadata = () => {
    const summaryText =
      (selectedUser?.displayName || selectedUser?.emailAddress) +
      '  ' +
      getRelativeTime(comment.createdDateTime);
    const detailedText =
      (selectedUser?.displayName || selectedUser?.emailAddress) +
      '  ' +
      new Date(comment.createdDateTime).toLocaleString();

    return (
      <Tooltip title={detailedText} arrow>
        <span>
          {summaryText.length > 42
            ? summaryText.substring(0, 39) + '...'
            : summaryText}
        </span>
      </Tooltip>
    );
  };

  return (
    <div
      style={{
        backgroundColor: isSelected ? 'rgb(234, 246, 255)' : 'inherit',
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <div
        id={comment.id}
        style={{
          position: 'relative',
          padding: 10,
          paddingTop: 0,
          marginTop: 10,
          paddingBottom: 30,
        }}
      >
        <div
          className={classes.label}
          style={
            fullWidth ? { fontSize: 13, display: 'flex' } : { display: 'flex' }
          }
        >
          {!selectedUser || (selectedUser && selectedUser.photoUrl) ? (
            <Avatar
              src={selectedUser ? selectedUser.photoUrl : ''}
              style={{
                marginRight: 5,
                width: fullWidth ? 32 : 25,
                height: fullWidth ? 32 : 25,
              }}
            />
          ) : (
            <Avatar
              className="comment-box-avatar-human"
              icon={<UserOutlined />}
              style={{
                marginRight: 5,
                width: fullWidth ? 32 : 25,
                height: fullWidth ? 32 : 25,
              }}
            />
          )}
          {selectedUser?.displayName || selectedUser?.email ? (
            getMetadata()
          ) : (
            <Skeleton width={30} />
          )}
        </div>
        <div>
          <div style={{ marginLeft: 30, fontSize: 13, whiteSpace: 'pre-line' }}>
            {mode === 'view' ? (
              comment.content
            ) : (
              <TextareaAutosize
                disabled={isUpdatingComment}
                onChange={(event) =>
                  handleChangeCommentContent(event.target.value)
                }
                value={comment.content}
                className={classes.textField}
                style={
                  fullWidth ? { fontSize: 15 } : { width: 'calc(100% - 75px)' }
                }
                id={threadId + comment.id}
              />
            )}
          </div>
          <div>
            {editable ? (
              mode === 'view' ? (
                <Tooltip arrow title={t<string>('delete')}>
                  <DeleteIcon
                    className={classes.deleteIcon}
                    style={
                      fullWidth
                        ? { width: 23, height: 23 }
                        : { width: 17, height: 17 }
                    }
                    onClick={() => {
                      if (isUpdatingComment) return true;
                      else setOpenDialog(true);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip arrow title={t<string>('cancel')}>
                  <CloseIcon
                    className={classes.deleteIcon}
                    style={
                      fullWidth
                        ? { width: 23, height: 23 }
                        : { width: 17, height: 17 }
                    }
                    onClick={() => {
                      if (isUpdatingComment) return true;
                      else setMode('view');
                    }}
                  />
                </Tooltip>
              )
            ) : null}

            {editable ? (
              mode === 'view' ? (
                <Tooltip arrow title={t<string>('edit')}>
                  <EditIcon
                    className={classes.editIcon}
                    style={
                      fullWidth
                        ? { width: 23, height: 23, right: 43 }
                        : { width: 17, height: 17 }
                    }
                    onClick={() => {
                      setMode('edit');
                      setTimeout(() => {
                        document.getElementById(threadId + comment.id)?.focus();
                      }, 200);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip arrow title={t<string>('save')}>
                  {isUpdatingComment ? (
                    <CircularProgress
                      className={classes.editIcon}
                      style={{ width: 13, height: 13, right: 39, top: 12 }}
                    />
                  ) : (
                    <DoneIcon
                      className={classes.editIcon}
                      style={
                        fullWidth
                          ? { width: 23, height: 23, right: 43 }
                          : { width: 17, height: 17 }
                      }
                      onClick={handleSaveComment}
                    />
                  )}
                </Tooltip>
              )
            ) : null}
            {mode === 'view' && showResolveButton ? (
              <Tooltip arrow title={t<string>('resolve')}>
                {isUpdatingThread ? (
                  <CircularProgress
                    className={classes.resolveIcon}
                    style={
                      fullWidth
                        ? { width: 23, height: 23, right: editable ? 75 : 10 }
                        : editable
                          ? { width: 13, height: 13, right: 63, top: 12 }
                          : { width: 13, height: 13, right: 10, top: 12 }
                    }
                  />
                ) : (
                  <DoneAllIcon
                    className={classes.resolveIcon}
                    style={
                      fullWidth
                        ? { width: 23, height: 23, right: editable ? 75 : 10 }
                        : !editable
                          ? { right: 10 }
                          : { width: 17, height: 17 }
                    }
                    onClick={handleResolveThread}
                  />
                )}
              </Tooltip>
            ) : null}
          </div>
        </div>
        {!isLast && (
          <div
            className={classes.line}
            style={fullWidth ? { width: 4, top: 32, left: 24.5 } : {}}
          ></div>
        )}
      </div>
      <ConfirmationDialog
        isOpen={openDialog}
        onDialogClosed={() => setOpenDialog(false)}
        title={t<string>('delete-comment')}
        message={t('delete-comment-message')}
        onDialogConfirmed={handledDeleteComment}
        loading={isDeletingComment}
      />
    </div>
  );
};

export default CommentBox;
