import React, { Fragment, useState } from 'react';
import { InputLabel, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import Preview from '../preview';
import { Button as AntButton, Col } from 'antd';

const styles: any = () => ({
  paper: {
    marginTop: '10px',
    boxShadow: 'none',
  },
  styleEditor: {
    height: 'calc(100% - 70px)',
  },
  noShadow: {
    boxShadow: 'none',
  },
  panel: {
    padding: '0 10px',
    margin: 0,
    border: '1px solid rgba(0, 0, 0, 0.42)',
  },
  summary: {
    display: 'initial',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  content: {
    borderColor: '#dfdfdf',
    border: '1px solid',
    borderRadius: '4px',
    textAlign: 'center!important',
  },
  colorInputs: {
    display: 'flex',
    flexDirection: 'column!important',
    alignItems: 'center!important',
    marginRight: '10px!important',
  },
  colorLabels: {
    marginBottom: 10,
  },
  buttons: {
    marginBottom: 5,
  },
});

const ColorPicker = (props) => {
  const classes = props.classes;
  const { t } = useTranslation();
  const [showNavbarColor, setShowNavbarColor] = useState<boolean>(false);
  const [showFontColor, setShowFontColor] = useState<boolean>(false);

  return (
    <Paper className={classes.paper}>
      <div className={classes.styleEditor}>
        <Fragment>
          <div className={classes.summary}>
            <div className={classes.content}>
              <div style={{ margin: '18px', display: 'flex' }}>
                <Col xs={12} className={classes.colorInputs}>
                  <InputLabel
                    htmlFor="navbarColor"
                    className={classes.colorLabels}
                  >
                    {t('navbar-color')}
                  </InputLabel>
                  <AntButton
                    id="navbar-color-button"
                    onClick={() => setShowNavbarColor(!showNavbarColor)}
                    type="primary"
                    className={classes.buttons}
                  >
                    Select
                  </AntButton>
                  {showNavbarColor && (
                    <SketchPicker
                      color={props.navbarColor}
                      onChange={props.handleNavbarColorChange}
                    />
                  )}
                </Col>
                <Col xs={12} className={classes.colorInputs}>
                  <InputLabel
                    htmlFor="fontColor"
                    className={classes.colorLabels}
                  >
                    {t('font-color')}
                  </InputLabel>
                  <AntButton
                    onClick={() => setShowFontColor(!showFontColor)}
                    type="primary"
                    className={classes.buttons}
                  >
                    Select
                  </AntButton>
                  {showFontColor && (
                    <SketchPicker
                      color={props.fontColor}
                      onChange={props.handleFontColorChange}
                    />
                  )}
                </Col>
              </div>
              <Preview
                navbarColor={props.navbarColor}
                fontColor={props.fontColor}
              />
              <AntButton
                onClick={props.resetToDefaultColors}
                type="primary"
                className={classes.buttons}
                style={{ marginTop: 10, marginBottom: 15 }}
              >
                Reset to default
              </AntButton>
            </div>
          </div>
        </Fragment>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(ColorPicker);
