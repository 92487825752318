import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { Components } from '../../api-schemas/component';

const getComponentsRequest = () => {
  return axios
    .get<Components>(`/api/Component`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useGetComponents() {
  const {
    data,
    refetch: getComponents,
    isFetching: isLoadingComponents,
    isSuccess: isSuccessLoadingComponents,
  } = useQuery<Components>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['components'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: getComponentsRequest,
  });

  const components = useMemo(() => data || [], [data]);

  return {
    components,
    isLoadingComponents,
    isSuccessLoadingComponents,

    getComponents,
  };
}
