import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Process } from '../../api-schemas/process';

const createProcessRequest = (data: Process) => {
  const body = JSON.stringify(data);

  return axios
    .post('/api/process', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data);
};

export default function useCreateProcess(accountId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createProcess,
    isPending: isCreatingProcess,
    isSuccess: isSuccessCreatingProcess,
  } = useMutation({
    mutationFn: createProcessRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['processes', accountId] });
    },
  });

  return {
    createProcess,
    isCreatingProcess,
    isSuccessCreatingProcess,
  };
}
