import React, { useEffect, useState } from 'react';
import { topicService } from '../../../../../../services/topic';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { convertToSlug } from '../../../../../../helpers/common';

const HeadingSelector = ({
  selectedTitle,
  selectedLanguageCode,
  selectedTopicId,
  editingTopicLinkData,
  setEditingTopicLinkData,
}) => {
  const [headings, setHeadings] = useState<any[] | undefined | null>();

  const { t } = useTranslation();
  const topicServices = new topicService();

  const extractHeadings = (content) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content.body;
    const headings: any[] = [];
    tempElement
      .querySelectorAll<HTMLElement>('h2')
      .forEach((headingElement) => {
        const title = (headingElement.firstChild as HTMLElement).innerText
          ? (headingElement.firstChild as HTMLElement).innerText
          : (headingElement.firstChild as HTMLElement).nodeValue ||
            headingElement.getElementsByTagName('strong')[0].innerText;
        if (title.replace(/ /g, '').length) {
          headings.push({
            headingId: convertToSlug(title),
            title: title,
          });
        }
      });
    setHeadings(headings);
  };

  const getContent = () => {
    if (selectedTopicId) {
      topicServices
        .getTopicContentByTopicId(selectedLanguageCode, selectedTopicId)
        .then((response) => {
          extractHeadings(response);
        })
        .catch(() => {
          setHeadings(null);
        });
    }
  };

  useEffect(() => {
    if (selectedTopicId) {
      setHeadings(undefined);
      getContent();
    }
    // eslint-disable-next-line
  }, [selectedTopicId]);

  const handleSelectHeading = (heading) => {
    setEditingTopicLinkData({
      ...editingTopicLinkData,
      title: selectedTitle || heading.title,
      headingId: heading.headingId,
      url: `${editingTopicLinkData.url}#${heading.headingId || ''}`,
    });
  };

  return (
    <div style={{ minWidth: 250 }}>
      {headings ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              paddingLeft: 8,
              display: 'flex',
              alignItems: 'center',
              height: 36,
              fontWeight: 'bold',
            }}
          >
            {t('headings')}
          </div>
          {headings?.map((heading, index) => (
            <Button
              key={index}
              style={{
                textTransform: 'none',
                justifyContent: 'start',
                paddingLeft: 10,
              }}
              color={
                editingTopicLinkData?.headingId === heading.headingId
                  ? 'primary'
                  : 'grey'
              }
              onClick={() => handleSelectHeading(heading)}
            >
              {heading.title}
            </Button>
          ))}
        </div>
      ) : headings === undefined ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
    </div>
  );
};

export default HeadingSelector;
