import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { User } from '../../api-schemas/user';

const getUserRequest = (email: string) => {
  return axios
    .get<User>(`/api/User`, {
      params: { email },
      headers: {
        Authorization: 'Bearer ' + localStorage.auth_token,
      },
    })
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      throw new Error(err.response?.status.toString());
    });
};

export default function useGetUser(email: string) {
  const {
    data: user,
    error: userError,
    refetch: getUser,
    isFetching: isLoadingUser,
    isSuccess: isSuccessLoadingUser,
  } = useQuery<User>({
    staleTime: 1000 * 60 * 60,
    queryKey: ['user', email],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(email),
    retry: false,
    queryFn: () => getUserRequest(email),
  });

  return {
    user,
    isLoadingUser,
    isSuccessLoadingUser,
    userError,

    getUser,
  };
}
