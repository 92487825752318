import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

import useMoveTopic from '../../../../../api-hooks/topic/useMoveTopic';
import {
  DraftTopicTree,
  DraftTopicTreeItem,
} from '../../../../../api-schemas/topic';
import {
  maxTopicOrderOfChild,
  findNextTopic,
  findPreviousTopic,
} from '../../../../../helpers/common';

type MoveButtonPropsType = {
  documentVersionId: string;
  draftTopicTreeItem: DraftTopicTreeItem;
  draftTopicTree: DraftTopicTree;
  flatDraftTopicTree: DraftTopicTree;
  type: 'up' | 'down' | 'indent' | 'outdent';
};

const MoveButton = ({
  documentVersionId,
  draftTopicTreeItem,
  draftTopicTree,
  flatDraftTopicTree,
  type,
}: MoveButtonPropsType) => {
  const isDeleted: boolean = draftTopicTreeItem.action === 'Deleted';

  const previousTopic = findPreviousTopic(draftTopicTreeItem, draftTopicTree);
  const nextTopic = findNextTopic(draftTopicTreeItem, draftTopicTree);
  const parentTopic = flatDraftTopicTree.find(
    (topic) => topic.topicId === draftTopicTreeItem.parentTopicId
  );
  const grandParentTopic = parentTopic
    ? flatDraftTopicTree.find(
        (topic) => topic.topicId === parentTopic.parentTopicId
      )
    : null;

  const { t } = useTranslation();

  const { moveTopic, isMovingTopic } = useMoveTopic({
    documentVersionId: documentVersionId,
    languageCode: draftTopicTreeItem.languageCode,
  });

  const handleMove = () => {
    const moveData = {
      up: {
        topicId: draftTopicTreeItem.topicId,
        targetParentTopicId: draftTopicTreeItem.parentTopicId,
        minOrder:
          findPreviousTopic(previousTopic, draftTopicTree)?.topicOrder ?? null,
        maxOrder: previousTopic?.topicOrder ?? null,
      },
      down: {
        topicId: draftTopicTreeItem.topicId,
        targetParentTopicId: draftTopicTreeItem.parentTopicId,
        minOrder: nextTopic?.topicOrder ?? null,
        maxOrder: findNextTopic(nextTopic, draftTopicTree)?.topicOrder ?? null,
      },
      indent: {
        topicId: draftTopicTreeItem.topicId,
        targetParentTopicId: previousTopic?.topicId,
        minOrder: maxTopicOrderOfChild(previousTopic) ?? null,
        maxOrder: null,
      },
      outdent: {
        topicId: draftTopicTreeItem.topicId,
        targetParentTopicId: grandParentTopic?.topicId ?? null,
        minOrder: parentTopic?.topicOrder ?? null,
        maxOrder:
          findNextTopic(parentTopic!, draftTopicTree)?.topicOrder ?? null,
      },
    }[type];

    moveTopic(moveData);
  };

  return (
    <Button
      onClick={handleMove}
      disabled={
        isDeleted ||
        isMovingTopic ||
        (type === 'up' && !previousTopic) ||
        (type === 'down' && !nextTopic) ||
        (type === 'outdent' && !parentTopic?.parentTopicId)
      }
      type="text"
      style={{ display: 'flex', width: '100%' }}
    >
      {type === 'up' ? (
        <ArrowUpwardIcon style={{ marginRight: 5 }} />
      ) : type === 'down' ? (
        <ArrowDownwardIcon style={{ marginRight: 5 }} />
      ) : type === 'indent' ? (
        <ArrowForwardIcon style={{ marginRight: 5 }} />
      ) : (
        <ArrowBackIcon style={{ marginRight: 5 }} />
      )}

      <Typography>{t(`move-topic-${type}`)}</Typography>
    </Button>
  );
};

export default MoveButton;
